import { AlterarPeriodoRedefinicaoSenha, AlterarPeriodoRedefinicaoSenhaVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const ALTERAR_PERIODO_REDEFINICAO_SENHA_MUTATION = gql`
  mutation AlterarPeriodoRedefinicaoSenha($periodo: Int) {
    alterarPeriodoRedefinicaoSenha(periodo: $periodo) {
      redefinicaoSenha
    }
  }
`

export function useAlterarPeriodoRedefinicaoSenha() {
  return useMutation<AlterarPeriodoRedefinicaoSenha, AlterarPeriodoRedefinicaoSenhaVariables>(
    ALTERAR_PERIODO_REDEFINICAO_SENHA_MUTATION
  )
}
