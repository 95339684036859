import { ButtonGroup, HFlow, VFlow } from 'bold-ui'
import { useExistemFichasParaProcessarQuery } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import * as React from 'react'
import { matchPath, Redirect } from 'react-router'

import { Box } from '../../../../components/Box'
import { ButtonLink, PecSwitch, PrivateRoute } from '../../../../components/route'
import Permissions from '../../../../types/Permissions'
import { TransmissaoRecebimentoCnesView } from './cnesTable/TransmissaoRecebimentoCnesView'
import { TransmissaoHorarioGeracao } from './components/TransmissaoHorarioGeracao'
import { TransmissaoProcessamentoFichasCmp } from './components/TransmissaoProcessamentoFichasCmp'
import { TransmissaoRecebimentoLoteBox } from './loteTable/TransmissaoRecebimentoLoteBox'

export interface TransmissaoConfiguracoesProps {}

export function TransmissaoRecebimentoGeralView() {
  const { match, history } = useRouter()

  const isLote = matchPath(history.location.pathname, `${match.url}/lote`)
  const isCnes = matchPath(history.location.pathname, `${match.url}/cnes`)
  const btnLinkStyles = {
    padding: '0.25rem 1rem',
  }

  const { data } = useExistemFichasParaProcessarQuery()

  return (
    <>
      <VFlow>
        <Box>
          <VFlow vSpacing={0.25}>
            <TransmissaoHorarioGeracao text='Fichas recebidas online ou importadas por arquivo são processadas automaticamente às' />
            <TransmissaoProcessamentoFichasCmp existemFichasParaProcessar={data.existemFichasParaProcessar} />
          </VFlow>
        </Box>
        <HFlow justifyContent='center'>
          <ButtonGroup>
            <ButtonLink
              to={`${match.url}/lote`}
              kind={isLote ? 'primary' : 'normal'}
              size='small'
              style={btnLinkStyles}
            >
              Lote
            </ButtonLink>
            <ButtonLink
              to={`${match.url}/cnes`}
              kind={isCnes ? 'primary' : 'normal'}
              size='small'
              style={btnLinkStyles}
            >
              CNES
            </ButtonLink>
          </ButtonGroup>
        </HFlow>
        <PecSwitch>
          <Redirect exact path={`${match.url}`} to={`${match.url}/lote`} />
          <PrivateRoute
            permission={Permissions.transmissaoDeDados.visualizarRecebimentoDeDados}
            exact
            path={`${match.url}/lote`}
            component={TransmissaoRecebimentoLoteBox}
          />
          <PrivateRoute
            permission={Permissions.transmissaoDeDados.visualizarRecebimentoDeDados}
            exact
            path={`${match.url}/cnes`}
            component={TransmissaoRecebimentoCnesView}
          />
        </PecSwitch>
      </VFlow>
    </>
  )
}
