import { Dropdown, DropdownDivider, DropdownItem, focusBoxShadow, Icon, Theme, useStyles } from 'bold-ui'
import useSession from 'components/auth/useSession'
import React, { CSSProperties, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { tipoAcesso } from 'types/enums'

export function UserMenu() {
  const { classes, css } = useStyles(createStyles)

  const targetRef = useRef<HTMLDivElement>()

  const session = useSession({ fetchPolicy: 'cache-only' })

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const handleClose = () => setDropdownOpen(false)
  const handleClick = () => setDropdownOpen(state => !state)
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      targetRef.current.click()
      e.preventDefault()
    }
  }
  const nomeUnidade = session.data.acesso.__typename === 'Lotacao' && session.data.acesso.unidadeSaude.nome
  const tipoDoAcesso = session.data.acesso.__typename !== 'Lotacao' && tipoAcesso[session.data.acesso.tipo]

  return (
    <>
      <div
        ref={targetRef}
        className={css(classes.user)}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        aria-label='Menu do usuário'
        role='button'
      >
        <div>
          <p className={css(classes.ellipsis)} title={session.data.profissional.nome}>
            {session.data.profissional.nome}
          </p>
          <p className={css(classes.ellipsis, classes.acesso)} title={nomeUnidade || tipoDoAcesso}>
            {nomeUnidade}
            {tipoDoAcesso}
          </p>
        </div>
        <span className={css(classes.indicator)}>
          {dropdownOpen ? <Icon icon='angleUp' /> : <Icon icon='angleDown' />}
        </span>
      </div>

      <Dropdown
        anchorRef={targetRef}
        open={dropdownOpen}
        onClose={handleClose}
        popperProps={{ placement: 'bottom-end' }}
        style={{ minWidth: targetRef.current && targetRef.current.clientWidth }}
      >
        {session.data.profissional.acessos && session.data.profissional.acessos.length > 1 && (
          <DropdownItem component={Link} to='/selecaoAcesso' data-testid='UserMenu.selecaoAcesso'>
            Trocar acesso
          </DropdownItem>
        )}

        <DropdownItem component={Link} to='/alterarSenha' data-testid='UserMenu.alterarSenha'>
          Alterar senha
        </DropdownItem>
        <DropdownItem component={Link} to='/linksUteis' data-testid='UserMenu.liksUteis'>
          Links úteis
        </DropdownItem>
        <DropdownItem component={Link} to='/novidades' data-testid='UserMenu.novidadesVersao'>
          Novidades da versão
        </DropdownItem>
        <DropdownItem component={Link} to='/sobre' data-testid='UserMenu.sobre'>
          Sobre
        </DropdownItem>
        <DropdownItem component={Link} to='/termosUso' data-testid='UserMenu.termoUso'>
          Termos de uso
        </DropdownItem>
        <DropdownItem component={Link} to='/escolhaModulo' data-testid='UserMenu.escolhaModulo'>
          Alterar módulo inicial
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem
          component={Link}
          to='/logout'
          data-testid='UserMenu.sair'
          style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem', display: 'flex', alignItems: 'center' }}
        >
          <Icon icon='openDoor' style={{ marginRight: '0.5rem' }} /> Sair
        </DropdownItem>
      </Dropdown>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem 2rem',
    transition: '.2s all',
    '&:hover': {
      background: theme.pallete.primary.c20,
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 0,
      borderRadius: theme.radius.button,
      boxShadow: focusBoxShadow(theme, 'primary', 'inset'),
    },
  } as CSSProperties,
  ellipsis: {
    overflow: 'hidden',
    maxWidth: 225,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.pallete.gray.c100,
  } as CSSProperties,
  acesso: {
    fontWeight: 'bold',
  } as CSSProperties,
  indicator: {
    marginLeft: '1rem',
    color: theme.pallete.gray.c100,
  } as CSSProperties,
})
