import { ExcluirConfiguracaoAgendaLotacaoMutation, ExcluirConfiguracaoAgendaLotacaoMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const EXCLUIR_CONFIGURACAO_AGENDA_LOTACAO_MUTATION = gql`
  mutation ExcluirConfiguracaoAgendaLotacaoMutation($id: ID!) {
    excluirConfiguracaoAgendaLotacao(id: $id)
  }
`

export function useExcluirConfiguracaoAgendaLotacao() {
  return useMutation<ExcluirConfiguracaoAgendaLotacaoMutation, ExcluirConfiguracaoAgendaLotacaoMutationVariables>(
    EXCLUIR_CONFIGURACAO_AGENDA_LOTACAO_MUTATION
  )
}
