import { MaskType } from 'bold-ui'

export const onlyNumbers = (value: string) => value && value.replace(/[^\d]/g, '')
export const onlyNumbersLetters = (value: string) => value && value.replace(/[^\d|\w]/g, '')

export const masks: Record<string, MaskType> = {
  telefone: rawValue => {
    if (!rawValue || rawValue.length < 11) {
      return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
    return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  time: [/\d/, /\d/, ':', /\d/, /\d/],
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  cns: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  // prettier-ignore
  chave:[
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/,
  ],
  // prettier-ignore
  chaveAgendaOnline:[
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/,
  ],
  // prettier-ignore
  contra_chave: [
  /\w/, /\w/, /\w/, /\w/, '-',
  /\w/, /\w/, /\w/, /\w/, '-',
  /\w/, /\w/, /\w/, /\w/, '-',
  /\w/, /\w/, /\w/, /\w/, '-',
  /\w/, /\w/, /\w/, /\w/, '-',
  /\w/, /\w/, /\w/, /\w/, '-',
  /\w/, /\w/, /\w/, /\w/, '-',
  /\w/, /\w/, /\w/, /\w/,
  ],
  numeroDeclaracaoObito: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
}
