import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import fragmentTypes from 'graphql/fragment-types.generated.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
})

const cache = new InMemoryCache({
  fragmentMatcher,
  cacheRedirects: {
    Query: {
      unidadeSaude: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'UnidadeSaude', id: args.id }),
      equipe: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Equipe', id: args.id }),
    },
  },
})

export default cache
