import { DefinirModuloInicial, DefinirModuloInicialVariables } from 'api/schema'
import { Heading, HeadingSection, HFlow, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { Form, SubmitButton } from 'components/form'
import { items, ModuloSelectField } from 'components/form/field/select/ModulosSelectField'
import { FormFooter } from 'components/form/FormFooter'
import { PageContent } from 'components/layout/PageContent'
import { analytics } from 'config/firebase'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'
import { SessaoDocument } from 'graphql/hooks.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router'
import { createValidator, required } from 'util/validation'

import { ButtonLink } from '../components/route'

interface EscolhaModuloAcessoRootViewProps {
  refresh(): void
}

const validator = createValidator({ moduloInicial: [required] })

export const EscolhaModuloAcessoRootView = ({ refresh }: EscolhaModuloAcessoRootViewProps) => {
  const user = useSession()
  const [mutate] = useMutation<DefinirModuloInicial, DefinirModuloInicialVariables>(DEFINIR_MODULO_INICIAL_MUTATION)
  const history = useHistory()

  const renderForm = ({ handleSubmit, initialValues }: FormRenderProps) => (
    <VFlow style={{ paddingTop: '1rem' }} vSpacing={38 / 16}>
      <Heading level={1}>Módulo inicial</Heading>
      <Breadcrumb title='Alteração de módulo inicial' />
      <HeadingSection title='Escolha o módulo que deseja ver após acessar o sistema:' level={2}>
        <HFlow>
          <ModuloSelectField name='moduloInicial' style={{ width: `${368 / 16}rem` }} />
          {initialValues.moduloInicial && (
            <SubmitButton handleSubmit={handleSubmit} data-testid='FormFooter.salvar' size='small'>
              Salvar
            </SubmitButton>
          )}
        </HFlow>
      </HeadingSection>
      {!initialValues.moduloInicial && (
        <FormFooter>
          <ButtonLink to='/logout' data-testid='FormFooter.sair'>
            Sair
          </ButtonLink>
          <SubmitButton handleSubmit={handleSubmit} data-testid='FormFooter.salvar'>
            Salvar
          </SubmitButton>
        </FormFooter>
      )}
    </VFlow>
  )

  const moduloInicial = user.data && user.data.acesso.moduloInicial

  const initialValue = items.find(menuItem => menuItem.to === moduloInicial)

  const save = values => {
    return mutate({
      variables: {
        input: {
          moduloInicial: values && values.moduloInicial && values.moduloInicial.to,
        },
      },
      refetchQueries: [{ query: SessaoDocument }],
    }).then(() => {
      alert('success', `Módulo inicial alterado com sucesso para ${values.moduloInicial.title}`)
      analytics.logEvent('modulo_inicial', { 'Módulo inicial': values.moduloInicial.title })
      history.push('/')
    })
  }

  return (
    <PageContent type='filled'>
      <Form
        onSubmit={save}
        render={renderForm}
        validate={validator}
        initialValues={{
          moduloInicial: initialValue,
        }}
      />
    </PageContent>
  )
}

const DEFINIR_MODULO_INICIAL_MUTATION = gql`
  mutation DefinirModuloInicial($input: SelecionarModuloInicialInput!) {
    definirModuloInicial(input: $input) {
      id
      moduloInicial
    }
  }
`
