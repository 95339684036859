import { HFlow, Omit, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { UnidadesSaudeSelectFieldDocument } from 'graphql/hooks.generated'
import { UnidadesSaudeSelectFieldQuery, UnidadesSaudeSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE } from '../AsyncQuerySelectField'
import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type UnidadeSaudeSelectModel = UnidadesSaudeSelectFieldQuery['unidadesSaude']['content'][0]

export interface UnidadeSaudeSelectFieldProps
  extends Omit<SelectFieldProps<UnidadeSaudeSelectModel>, 'items' | 'itemToString'> {
  disabled?: boolean
  somenteUnidadesPermitidas?: boolean
}

export function UnidadeSaudeSelectField(props: UnidadeSaudeSelectFieldProps) {
  const { somenteUnidadesPermitidas, ...rest } = props

  const asyncProps = useAsyncQuerySelect<
    UnidadeSaudeSelectModel,
    UnidadesSaudeSelectFieldQuery,
    UnidadesSaudeSelectFieldQueryVariables
  >({
    query: UnidadesSaudeSelectFieldDocument,
    extractItems: data => data && data.unidadesSaude && data.unidadesSaude.content,
    variables: inputQuery => ({
      input: {
        query: inputQuery,
        mostrarInativas: false,
        somenteUnidadesPermitidas: somenteUnidadesPermitidas,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (option: UnidadeSaudeSelectModel) => option && option.nome

  const renderItem = (item: UnidadeSaudeSelectModel) => (
    <>
      <Text fontWeight='bold'>{item.nome}</Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>CNES:</Text>
        {item.cnes}
      </HFlow>
    </>
  )

  return (
    <SelectField<UnidadeSaudeSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...asyncProps}
      {...rest}
    />
  )
}
