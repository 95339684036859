import { Button, FormControl, HeadingSection, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { InlineErrorList } from 'components/error/InlineErrorList'
import {
  ProfissionaisRelatorioInconsistenciaSelectField,
  ProfissionaisRelatorioInconsistenciaSelectModel,
} from 'components/form/field/select/ProfissionaisRelatorioInconsistenciaSelectField'
import { CheckboxField, DateField, Form } from 'components/form/final-form'
import moment from 'moment'
import * as React from 'react'
import { useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { beforeEqualToday, consistentPeriod, required } from 'util/validation'
import { createValidator } from 'util/validation/validator'

import { downloadRelatorioInconsistencia } from './downloadRelatorioInconsistencia'

interface FormModel {
  responsavel: ProfissionaisRelatorioInconsistenciaSelectModel
  dataInicio: string
  dataFim: string
  tipoRecebimento: number
}

export const validator = createValidator<FormModel>(
  {
    dataFim: [required, beforeEqualToday],
    dataInicio: [required, beforeEqualToday],
  },
  (values, errors) => {
    if (!(errors.dataInicio || errors.dataFim)) {
      const errorDatePeriodRule = consistentPeriod(values.dataInicio, values.dataFim)
      if (errorDatePeriodRule) {
        errors.dataInicio = errorDatePeriodRule
      }
    }

    return errors
  }
)

export const TransmissaoBtnGerarRelatorio = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [error, setError] = useState<any>()

  const toogleModal = () => setIsOpen(!isOpen)

  const today = moment().toDate()

  const handleSubmit = (values: FormModel) => {
    const formData = new FormData()
    values.dataInicio && formData.append('dataInicio', values.dataInicio)
    values.dataFim && formData.append('dataFim', values.dataFim)
    values.responsavel && formData.append('nomeResponsavel', values.responsavel.responsavel)
    values.tipoRecebimento && formData.append('tipoRecebimento', values.tipoRecebimento.toString())

    return downloadRelatorioInconsistencia(formData)
      .then(() => setIsOpen(false))
      .catch(setError)
  }

  const renderForm = (formProps: FormRenderProps<FormModel>) => {
    const closeAndClear = () => {
      setTimeout(formProps.form.reset)
      setError(null)
      toogleModal()
    }

    const { dataFim, dataInicio } = formProps.values

    return (
      <Modal style={{ overflow: 'visible' }} size='small' onClose={closeAndClear} open={isOpen}>
        <ModalBody>
          <HeadingSection vSpace={0.25} level={1} title='Gerar relatório de inconsistências'>
            <VFlow vSpacing={!error ? 2 : 1}>
              <Text>Selecione os filtros desejados</Text>
              <InlineErrorList error={error} />
              <VFlow>
                <HFlow>
                  <DateField name='dataInicio' label='Início do período' />
                  <DateField name='dataFim' label='Fim do período' />
                </HFlow>

                <ProfissionaisRelatorioInconsistenciaSelectField
                  disabled={!dataFim || !dataInicio}
                  dataInicio={dataInicio}
                  dataFim={dataFim}
                  name='responsavel'
                  label='Responsável'
                />

                <VFlow vSpacing={0.5}>
                  <FormControl label='Tipo de recebimento'>
                    <HFlow>
                      <CheckboxField name='tipoRecebimento' label='Arquivo' value='3' />
                      <CheckboxField name='tipoRecebimento' label='Online' value='2' />
                    </HFlow>
                  </FormControl>
                </VFlow>
              </VFlow>
            </VFlow>
          </HeadingSection>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={closeAndClear}>Cancelar</Button>
            <Button onClick={formProps.handleSubmit} kind='primary'>
              Gerar
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <>
      <Form<FormModel>
        onSubmit={handleSubmit}
        render={renderForm}
        validate={validator}
        initialValues={
          {
            dataInicio: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)).format(
              'YYYY-MM-DD'
            ),
            dataFim: moment(new Date()).format('YYYY-MM-DD'),
          } as any
        }
      />
      <Button kind='primary' onClick={toogleModal} style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Icon icon='fileWithItensOutline' style={{ marginRight: '0.5rem' }} />
        <Text color='inherit'>Gerar relatório de inconsistências</Text>
      </Button>
    </>
  )
}
