import { Cell, Grid, HeadingSection, InfoLabel } from 'bold-ui'
import { Cep } from 'components/label'
import React from 'react'

export interface EnderecoSectionModel {
  bairro?: string
  cep?: string
  complemento?: string
  logradouro?: string
  municipio?: {
    nome: string
  }
  numero?: string
  pontoReferencia?: string
  semNumero: boolean
  tipoLogradouro?: {
    nome: string
  }
  uf?: {
    sigla: string
  }
}

export interface EnderecoSectionProps {
  endereco: EnderecoSectionModel
  title: string
}

export const EnderecoSection = (props: EnderecoSectionProps) => (
  <HeadingSection level={2} title={props.title}>
    <Grid wrap>
      <Cell size={12} data-testid='EnderecoSection.cep'>
        <InfoLabel title='CEP'>
          <Cep value={props.endereco && props.endereco.cep} />
        </InfoLabel>
      </Cell>
      <Cell size={4} data-testid='EnderecoSection.estado'>
        <InfoLabel title='Estado'>{props.endereco && props.endereco.uf && props.endereco.uf.sigla}</InfoLabel>
      </Cell>
      <Cell size={4} data-testid='EnderecoSection.municipio'>
        <InfoLabel title='Município'>
          {props.endereco && props.endereco.municipio && props.endereco.municipio.nome}
        </InfoLabel>
      </Cell>
      <Cell size={4} data-testid='EnderecoSection.bairro'>
        <InfoLabel title='Bairro'>{props.endereco && props.endereco.bairro}</InfoLabel>
      </Cell>
      <Cell size={4} data-testid='EnderecoSection.tipoLogradouro'>
        <InfoLabel title='Tipo de logradouro'>
          {props.endereco && props.endereco.tipoLogradouro && props.endereco.tipoLogradouro.nome}
        </InfoLabel>
      </Cell>
      <Cell size={4} data-testid='EnderecoSection.logradouro'>
        <InfoLabel title='Logradouro'>{props.endereco && props.endereco.logradouro}</InfoLabel>
      </Cell>
      <Cell size={4} data-testid='EnderecoSection.numero'>
        <InfoLabel title='Número'>
          {props.endereco && props.endereco.semNumero ? 'S/N' : props.endereco && props.endereco.numero}
        </InfoLabel>
      </Cell>
      <Cell size={4} data-testid='EnderecoSection.complemento'>
        <InfoLabel title='Complemento'>{props.endereco && props.endereco.complemento}</InfoLabel>
      </Cell>
      <Cell size={4} data-testid='EnderecoSection.pontoReferencia'>
        <InfoLabel title='Ponto de referência'>{props.endereco && props.endereco.pontoReferencia}</InfoLabel>
      </Cell>
    </Grid>
  </HeadingSection>
)
