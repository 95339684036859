import { Tabs } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PecSwitch, PrivateRoute, TabLink } from 'components/route'
import {
  IdentidadeGeneroEnum,
  NacionalidadeEnum,
  OrientacaoSexualEnum,
  SexoEnum,
  TipoSanguineoEnum,
} from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { Redirect } from 'react-router'
import Permissions from 'types/Permissions'

import { CidadaoInformacoesView } from './CidadaoInformacoesView'
import { CidadaoUnificacoesView } from './CidadaoUnificacoesView'

export interface CidadaoTabSectionProps {
  cidadao: CidadaoTabSectionModel
}

export interface CidadaoTabSectionModel {
  id: ID
  cpf?: string
  cns?: string
  nomeSocial?: string
  nome: string
  dataNascimento: string
  sexo: SexoEnum
  racaCor: {
    id: string
    nome: string
  }
  etnia?: {
    id: string
    nome: string
  }
  nomeMae?: string
  nomePai?: string
  nacionalidade?: {
    id: string
    nacionalidadeDbEnum: NacionalidadeEnum
  }
  localidadeNascimento?: {
    id: string
    nome: string
    uf: {
      id: string
      sigla: string
    }
  }
  portariaNaturalizacao?: string
  dataNaturalizacao?: string
  paisNascimento?: {
    id: string
    nome: string
  }
  dataEntradaBrasil?: string
  faleceu?: boolean
  dataObito?: string
  numeroDocumentoObito?: string
  telefoneResidencial?: string
  telefoneCelular?: string
  telefoneContato?: string
  email?: string
  cidadaoVinculacaoEquipe?: {
    id?: string
    tpCdsOrigem?: string
    unidadeSaude?: {
      id: string
      nome?: string
    }
    equipe?: {
      id: string
      nome?: string
      ine: string
    }
  }
  localidadeExterior?: string
  paisExterior?: {
    id: string
    nome: string
  }
  endereco?: {
    cep?: string
    bairro?: string
    logradouro?: string
    numero?: string
    semNumero: boolean
    complemento?: string
    pontoReferencia?: string
    uf?: {
      id: string
      nome: string
    }
    municipio?: {
      id: string
      nome: string
    }
    tipoLogradouro?: {
      id: string
      nome: string
    }
  }
  area?: string
  microArea?: string
  nisPisPasep?: string
  estadoCivil?: {
    id: string
    nome: string
  }
  tipoSanguineo?: TipoSanguineoEnum
  escolaridade?: {
    id: string
    nome: string
  }
  orientacaoSexualDbEnum?: OrientacaoSexualEnum
  identidadeGeneroDbEnum?: IdentidadeGeneroEnum
  ativo?: boolean
  unificado?: boolean
  stCompartilhaProntuario?: boolean
  possuiAgendamento?: boolean
  cbo?: {
    id: string
    nome: string
  }
  dataAtualizado?: string
}

export const CidadaoTabSection = (props: CidadaoTabSectionProps) => {
  const { cidadao } = props
  const { match } = useRouter()
  const { hasAuthorization } = useSession()

  const renderInformacoes = () => <CidadaoInformacoesView cidadao={cidadao} mostrarProntuario={true} />

  const renderUnificacoes = () => <CidadaoUnificacoesView idCidadao={cidadao.id} temUnificacoes={cidadao.unificado} />

  return (
    <>
      <PageContainer>
        <Tabs style={{ borderBottom: '1px solid #d3d4dd', marginTop: '1rem' }}>
          <TabLink to={`${match.url}/informacoes`}>Informações</TabLink>
          <TabLink to={`${match.url}/unificacoes`}>Unificações</TabLink>
        </Tabs>
      </PageContainer>

      <PageContent type='transparent'>
        <PecSwitch>
          {hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao) && (
            <Redirect exact path={`${match.url}`} to={`${match.url}/informacoes`} />
          )}
          <PrivateRoute
            exact
            path={`${match.url}/informacoes`}
            render={renderInformacoes}
            permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao}
          />
          <PrivateRoute
            exact
            path={`${match.url}/unificacoes`}
            render={renderUnificacoes}
            permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao}
          />
        </PecSwitch>
      </PageContent>
    </>
  )
}
