import { useStyles } from 'bold-ui'
import React, { CSSProperties, useEffect } from 'react'
import { Helmet } from 'react-helmet'

/**
 * Barra brasil conforme definições de http://barra.brasil.gov.br/
 */
export function BarraBrasil() {
  const { classes } = useStyles(createStyles)
  useEffect(() => {
    document.querySelectorAll('#barra-brasil a').forEach(i => i.setAttribute('tabindex', '-1'))
    document.querySelector('div[vw]')?.remove()
  })

  return (
    <>
      <Helmet>
        <script src='/static/barra-governo.min.js' type='text/javascript' defer />
      </Helmet>

      <div className={classes.wrapper}>
        <div id='barra-brasil' />
      </div>
    </>
  )
}

const createStyles = () => ({
  wrapper: {
    position: 'relative',
    zIndex: 20, // previne a barra do governo de ficar abaixo do menu lateral, quando expandida
  } as CSSProperties,
})
