import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'
import ProfissionalListView from 'view/profissional/list/ProfissionalListView'

import { ConfiguracaoAgendaLotacaoView } from './agenda/ConfiguracaoAgendaLotacaoView'
import { FechamentoAgendaLotacaoView } from './agenda/fechamento/FechamentoAgendaLotacaoView'
import { ConfiguracaoAgendaOnlineLotacaoView } from './agendaonline/ConfiguracaoAgendaOnlineLotacaoView'
import { AcessoCreateView } from './detail/acesso/AcessoCreateView'
import { AcessoEditView } from './detail/acesso/AcessoEditView'
import { LotacaoAlterarView } from './detail/lotacao/LotacaoAlterarView'
import { LotacaoCreateView } from './detail/lotacao/LotacaoCreateView'
import { ProfissionalDetailView } from './detail/ProfissionalDetailView'
import { ProfissionalCreateView } from './ProfissionalCreateView'
import { ProfissionalEditView } from './ProfissionalEditView'

export function ProfissionalRootView() {
  const { match } = useRouter()

  return (
    <>
      <Breadcrumb title='Profissionais' />

      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={ProfissionalListView}
          permission={Permissions.visualizarProfissionais}
        />
        <PrivateRoute
          exact
          path={`${match.url}/create/`}
          component={ProfissionalCreateView}
          permission={Permissions.visualizarProfissionais.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:profissionalId(\\d+)/edit`}
          component={ProfissionalEditView}
          permission={Permissions.visualizarProfissionais.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:profissionalId(\\d+)/lotacoes/create`}
          component={LotacaoCreateView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:profissionalId(\\d+)/lotacoes/:lotacaoId(\\d+)/edit`}
          component={LotacaoAlterarView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:profissionalId(\\d+)/acessos/create`}
          component={AcessoCreateView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirAcesso}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:profissionalId(\\d+)/acessos/:acessoId(\\d+)/edit`}
          component={AcessoEditView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirAcesso}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:profissionalId(\\d+)/dados/configuracaoAgenda/:lotacaoId(\\d+)`}
          component={ConfiguracaoAgendaLotacaoView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.configurarAgenda}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:profissionalId(\\d+)/dados/configuracaoAgendaOnline/:lotacaoId(\\d+)`}
          component={ConfiguracaoAgendaOnlineLotacaoView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.configurarAgendaOnline}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:profissionalId(\\d+)/dados/acessos/fechamento/:lotacaoId(\\d+)`}
          component={FechamentoAgendaLotacaoView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.fecharAgenda}
        />
        <PrivateRoute
          path={`${match.url}/:profissionalId(\\d+)`}
          component={ProfissionalDetailView}
          permission={Permissions.visualizarProfissionais}
        />
      </PecSwitch>
    </>
  )
}
