import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

export interface MotivoFechamentoSelectFieldProps extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {}

export function MotivoFechamentoSelectField(props: MotivoFechamentoSelectFieldProps) {
  const itemToString = (item: MotivoFechamentoSelectModel) => item

  return <SelectField<MotivoFechamentoSelectModel> items={items} itemToString={itemToString} {...props} />
}

export type MotivoFechamentoSelectModel = string

const items = ['Atestado', 'Curso', 'Férias', 'Licença', 'Reunião', 'Outro']
