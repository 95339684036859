import { ProfissionaisLotacoesQuery } from 'api/schema'
import { Cell, Checkbox, Grid, Heading, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ButtonLink } from 'components/route'
import gql from 'graphql-tag'
import { useQuery } from 'graphql/hooks'
import React, { useState } from 'react'
import Permissions from 'types/Permissions'

import { LotacaoProfissional } from '../ProfissionalAcessosView'
import { LotacaoAcessoCard } from './LotacaoAcessoCard'

export interface ProfissionalOutrosAcessosSectionProps {
  possuiCns: boolean
  lotacoes: LotacaoProfissional[]
  refetch(): void
}

export const ProfissionalLotacoesSection = (props: ProfissionalOutrosAcessosSectionProps) => {
  const { lotacoes, possuiCns, refetch } = props

  const [mostrarInativos, setMostrarInativos] = useState(true)
  const { data } = useQuery<ProfissionaisLotacoesQuery>(PROFISSIONAIS_LOTACOES_QUERY)
  const handleChangeMostrarInativos = (e: React.ChangeEvent<HTMLInputElement>) => setMostrarInativos(e.target.checked)

  const items = lotacoes.filter((item: any) => (mostrarInativos ? true : item.ativo !== false))

  const agendaOnlineAtiva = data && data.conexao && data.conexao.agendaOnline.ativa

  return (
    <VFlow>
      <HFlow alignItems='center' justifyContent='space-between'>
        <HFlow alignItems='center'>
          <Heading level={3}>Lotações</Heading>
          <Checkbox label='Mostrar inativas' checked={mostrarInativos} onChange={handleChangeMostrarInativos} />
        </HFlow>

        <CheckPermission
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao}
        >
          <Tooltip text={!possuiCns && 'Profissional sem CNS não pode ter lotação'}>
            <ButtonLink kind='primary' size='small' to='lotacoes/create' disabled={!possuiCns}>
              <Icon icon='plus' style={{ marginRight: '0.5rem' }} />
              Cadastrar lotação
            </ButtonLink>
          </Tooltip>
        </CheckPermission>
      </HFlow>

      {items.length === 0 && <Text fontStyle='italic'>Nenhuma lotação cadastrada</Text>}

      {items.length > 0 && (
        <Grid wrap>
          {items.map(lotacao => (
            <Cell key={lotacao.id} xs={12} md={6}>
              <LotacaoAcessoCard
                lotacao={lotacao}
                refetch={refetch}
                agendaOnlineAtiva={agendaOnlineAtiva}
                possuiCns={possuiCns}
              />
            </Cell>
          ))}
        </Grid>
      )}
    </VFlow>
  )
}

const PROFISSIONAIS_LOTACOES_QUERY = gql`
  query ProfissionaisLotacoesQuery {
    conexao {
      agendaOnline {
        ativa
      }
    }
  }
`
