import React from 'react'
import { useFormState } from 'react-final-form'
import { Prompt } from 'react-router'

export function FormPrompt() {
  const { pristine, submitSucceeded, dirtySinceLastSubmit } = useFormState({
    subscription: {
      pristine: true,
      submitSucceeded: true,
      dirtySinceLastSubmit: true,
    },
  })

  return <Prompt when={!pristine && (!submitSucceeded || dirtySinceLastSubmit)} message='' />
}
