import { Button, ButtonProps } from 'bold-ui'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

export interface ButtonLinkProps extends ButtonProps, Pick<LinkProps, 'to' | 'replace'> {}

export function ButtonLink(props: ButtonLinkProps) {
  const { to, replace, style, ...rest } = props

  const linkStyle = {
    textDecoration: 'none',
  }

  const LinkComponent = linkProps => {
    const {
      innerRef,
      type, // remove 'type="button"'
      ...other
    } = linkProps

    if (other.disabled) {
      // Render as a button if disabled because '<a>' does not have a disabled state natively
      return <button ref={innerRef} type={type} {...other} />
    } else {
      return <Link {...other} to={to} replace={replace} />
    }
  }

  return <Button {...rest} style={[linkStyle, style]} component={LinkComponent} />
}
