import React from 'react'
import Highlighter from 'react-highlight-words'

interface NormalizedHighlighterProps {
  textToHighlight: string
  searchWords: string
}

export const NormalizedHighlighter = (props: NormalizedHighlighterProps) => (
  <Highlighter
    searchWords={props.searchWords.split(/\s/)}
    textToHighlight={props.textToHighlight}
    sanitize={val => val.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}
  />
)
