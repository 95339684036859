import { useAlert } from 'components/alert'
import { useAtualizarPerfilMutation } from 'graphql/hooks.generated'
import { usePerfilEditQuery } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

import { PerfilFormModel } from '../components/PerfilForm'
import { PerfilFormView } from '../PerfilFormView'

interface UrlParams {
  id: string
}

export default function PerfilEditView() {
  const { match, history } = useRouter<UrlParams>()
  const perfilId = match.params.id
  const alert = useAlert()

  const [save] = useAtualizarPerfilMutation()

  const {
    data: { perfil },
  } = usePerfilEditQuery({ variables: { perfilId } })

  const handleSubmit = (values: PerfilFormModel) => save({ variables: { input: values } })
  const handllSubmitSucceeded = () => {
    history.push(`/perfil`)
    alert('success', 'Perfil salvo com sucesso')
  }

  const initialValues: PerfilFormModel = perfil && {
    id: perfil.id,
    nome: perfil.nome,
    recursos: perfil.recursos,
    tipoPerfil: perfil.tipoPerfil,
  }

  return (
    <PerfilFormView
      breadcrumb='Edição'
      title='Edição de perfil'
      onSubmit={handleSubmit}
      onSubmitSucceeded={handllSubmitSucceeded}
      initialValues={initialValues}
      disableNome={perfil && !!perfil.perfilPadrao}
      disableTipoAcesso
      hasVisualizacaoBreadcrumb={true}
    />
  )
}
