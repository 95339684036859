import { DataTable, DataTableProps, HFlow, Icon, Omit, Text } from 'bold-ui'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table/TableBox'
import { NodoLoteEnvio } from 'graphql/types.generated'
import * as React from 'react'
import { ReactNode } from 'react'

type Item = NodoLoteEnvio

export interface TransmissaoEnvioItensDetailTableProps extends Omit<DataTableProps<Item>, 'columns'> {}

export const TransmissaoEnvioItensDetailTable = (props: TransmissaoEnvioItensDetailTableProps) => {
  const renderNodeName: (row: Item) => ReactNode = row => row.nodeNome

  const renderStatusEnvio: (row: Item) => ReactNode = row => (
    <Text color={row.statusEnvio === 'TOTALMENTE_ENVIADO' ? 'success' : 'danger'}>
      <HFlow alignItems='center'>
        <Icon size={1} icon={row.statusEnvio === 'TOTALMENTE_ENVIADO' ? 'checkCircleOutline' : 'banOutline'} />
        <Text>{row.statusEnvio === 'TOTALMENTE_ENVIADO' ? 'Enviado' : 'Não enviado'}</Text>
      </HFlow>
    </Text>
  )
  const renderDataEnvio: (row: Item) => ReactNode = row => <DateTime value={row.dataEnvio} />

  return (
    <TableBox>
      <DataTable<Item>
        columns={[
          { name: 'nodeName', header: 'Instalação de destino', render: renderNodeName },
          { name: 'status', header: 'Status de envio', render: renderStatusEnvio },
          { name: 'dataEnvio', header: 'Data de envio', render: renderDataEnvio },
        ]}
        {...props}
      />
    </TableBox>
  )
}
