import CheckPermission from 'components/auth/CheckPermission'
import { PrivateRoute } from 'components/route'
import { useGrupoExameTableQuery } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React, { useState } from 'react'
import Permissions from 'types/Permissions'

import { CreateGrupoExame, EditGrupoExame } from './detail/CreateGrupoExame'
import GrupoExameTable from './list/GrupoExameTable'

export function GrupoExameRootView() {
  const [params, setParams] = useState({ pageParams: { size: 30, sort: ['nome'] } })
  const { match } = useRouter()

  const { data, refetch, loading } = useGrupoExameTableQuery({ variables: { input: params } })

  const handleSortChange = (sort: string[]) => {
    setParams(state => ({
      ...state,
      pageParams: { ...state.pageParams, sort },
    }))
  }

  const renderCreate = () => {
    return <CreateGrupoExame refetch={refetch} />
  }

  const renderEdit = () => {
    return <EditGrupoExame refetch={refetch} />
  }

  return (
    <>
      <CheckPermission permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames}>
        <GrupoExameTable
          data={data}
          loading={loading}
          refetch={refetch}
          sort={params.pageParams.sort}
          onSortChange={handleSortChange}
        />
        <PrivateRoute
          exact
          path={`${match.url}/create`}
          render={renderCreate}
          permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames}
        />
        <PrivateRoute
          exact
          path={`${match.url}/edit/:grupoExameId(\\d+)`}
          render={renderEdit}
          permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames}
        />
      </CheckPermission>
    </>
  )
}
