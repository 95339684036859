import { Button, Icon, Theme, useStyles } from 'bold-ui'
import { BreadcrumbNav } from 'components/breadcrumb'
import { UserHeader } from 'components/user/UserHeader'
import React, { CSSProperties } from 'react'

export interface MainHeaderProps {
  hasBreadcrumbs: boolean
  onModulosClick(e: React.MouseEvent): void
}

/**
 * Header da aplicação, contendo a logo do e-sus, menu do usuário e breadcrumbs.
 */
export const MainHeader = (props: MainHeaderProps) => {
  const { hasBreadcrumbs, onModulosClick } = props
  const { classes, css } = useStyles(createStyles)

  return (
    <div className={classes.wrapper}>
      <UserHeader />

      {hasBreadcrumbs && (
        <div className={classes.container}>
          <div className={css(classes.breadcrumbs)} data-testid='AppHeader.breadcrumbs'>
            <span className={classes.modulos}>
              <Button size='small' skin='ghost' kind='primary' onClick={onModulosClick}>
                <Icon icon='module' style={{ marginRight: '0.5rem' }} />
                Módulos
              </Button>
            </span>

            <BreadcrumbNav />
          </div>
        </div>
      )}
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 12,
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.04)',
  } as CSSProperties,
  container: {
    borderBottom: '1px solid ' + theme.pallete.divider,
    background: theme.pallete.surface.main,
  },
  breadcrumbs: {
    width: '1280px',
    margin: '0 auto',
    padding: '0 4rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      width: '1024px',
    },
  },
  modulos: {
    marginRight: '0.5rem',
    paddingRight: '0.5rem',
    borderRight: `1px solid ${theme.pallete.divider}`,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
})
