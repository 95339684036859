import { Theme, useStyles } from 'bold-ui'
import { PageHeader, PageHeaderProps } from 'components/PageHeader'
import React from 'react'

import { PageContainer } from './layout/PageContainer'

export interface PageHeaderSectionProps extends PageHeaderProps {
  children?: React.ReactNode
}

export function PageHeaderSection(props: PageHeaderSectionProps) {
  const { children, ...rest } = props
  const { classes } = useStyles(createStyles, props)

  return (
    <div className={classes.wrapper}>
      <PageContainer>
        <PageHeader style={classes.pageHeader} {...rest} />

        {children}
      </PageContainer>
    </div>
  )
}

const createStyles = (theme: Theme, { children }: PageHeaderSectionProps) => ({
  wrapper: {
    background: theme.pallete.surface.main,
  },
  pageHeader: {
    paddingTop: '2rem',
    paddingBottom: children ? '1rem' : '2rem',
  },
})
