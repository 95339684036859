import { CheckboxProps, colors, focusBoxShadow, Theme, useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

import { CheckboxField } from '../final-form'

export function CheckBoxButtonField(props: CheckboxProps) {
  const { classes } = useStyles(createStyles)

  return <CheckboxField name={props.name} label={props.label} onChange={props.onChange} style={classes.container} />
}

const createStyles = (theme: Theme) => ({
  container: {
    background: theme.pallete.surface.main,
    border: 'solid 1px',
    borderRadius: 2,
    borderColor: theme.pallete.gray.c60,
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    transition: 'box-shadow .2s ease',
    '&:focus-within': {
      boxShadow: focusBoxShadow(theme, 'primary'),
    },
    'input:focus + span': {
      boxShadow: 'none !important',
    },
    'input:checked': {
      borderColor: colors.blue.c40,
    },
  } as CSSProperties,
})
