import { DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { useDisponibilizarCboMutation } from 'graphql/hooks.generated'
import React from 'react'

import { CboTableItemDto } from './CboTable'

export interface CboTableDropdownProps {
  row: CboTableItemDto

  afterMutate(): void
}

export const CboTableDropdown = (props: CboTableDropdownProps) => {
  const { row, afterMutate } = props
  const alert = useAlert()
  const [disponibilizarCbo] = useDisponibilizarCboMutation()

  const handleIndisponibilizar = () => {
    return disponibilizarCbo({
      variables: {
        cboDisponibilizarForm: {
          id: row.cbo.id,
          disponivelLotacao: false,
        },
      },
    }).then(() => {
      alert('success', 'CBO indisponibilizada com sucesso')
      afterMutate()
    })
  }

  const handleDisponibilizar = () => {
    return disponibilizarCbo({
      variables: {
        cboDisponibilizarForm: {
          id: row.cbo.id,
          disponivelLotacao: true,
        },
      },
    }).then(() => {
      alert('success', 'CBO disponibilizada com sucesso')
      afterMutate()
    })
  }

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton data-testid='MaisOpcoesButton'>
        {row.disponivelLotacao && (
          <Tooltip text={row.actions.inativar.hint}>
            <DropdownItem
              onClick={handleIndisponibilizar}
              disabled={!row.actions.inativar.enabled}
              data-testid='IndisponibilizarButton'
            >
              Indisponibilizar para lotação
            </DropdownItem>
          </Tooltip>
        )}

        {!row.disponivelLotacao && (
          <DropdownItem onClick={handleDisponibilizar} data-testid='DisponibilizarButton'>
            Disponibilizar para lotação
          </DropdownItem>
        )}
      </DropdownButton>
    </Tooltip>
  )
}
