import { AuditoriaInput } from 'api/schema'

import AuditoriaFormModel from './AuditoriaFormModel'

export default (model: AuditoriaFormModel): AuditoriaInput => ({
  ...model,
  tiposEvento: model.tiposEvento && model.tiposEvento.map(value => value.id),
  usuariosGerador: model.usuariosGerador && model.usuariosGerador.map(value => value.id),
  tiposRegistroAfetado: model.tiposRegistroAfetado && model.tiposRegistroAfetado.map(value => value.id),
})
