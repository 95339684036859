import { Cnes } from 'api/Cnes'
import { Transmissao } from 'api/Transmissao'

import { AcompanhamentoSaudeRelatorio } from './AcompanhamentoSaudeRelatorio'

export default {
  cnes: new Cnes(),
  transmissao: new Transmissao(),
  acompanhamentoSaude: new AcompanhamentoSaudeRelatorio(),
}
