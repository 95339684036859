import { Button, HFlow, Icon, Text, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Box } from 'components/Box'
import { CheckboxField, Form, TextField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { PopperButton, PopperControls } from 'components/popper'
import { FormApi, FormState } from 'final-form'
import { useInserirTransmissaoLinkMutation } from 'graphql/hooks.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, maxLength, required } from 'util/validation'

import { TransmissaoLinksDestinoQueryInput, TransmissaoLinksItem } from './TransmissaoLinksDestinoTable'

interface TransmissaoLinksDestinoFilterProps {
  initialValues?: TransmissaoLinksDestinoQueryInput
  load(values: TransmissaoLinksDestinoQueryInput): void
  refetch(): Promise<any>
}

const validator = createValidator<TransmissaoLinksItem>({
  name: [required, maxLength(255)],
  hostname: [required],
})

export const TransmissaoLinksDestinoFilter = (props: TransmissaoLinksDestinoFilterProps) => {
  const [inserirTransmissaoLinkMutation] = useInserirTransmissaoLinkMutation()

  const handleChange = (formState: FormState<TransmissaoLinksDestinoQueryInput>) => {
    props.load(formState.values)
  }

  const filterForm = () => {
    return (
      <HFlow justifyContent='space-between'>
        <HFlow alignItems='center'>
          <FormDebouncedValueSpy loadOnMount={false} onChange={handleChange} />
          <TextField
            name='query'
            icon='zoomOutline'
            style={{ width: 304 }}
            placeholder='Pesquise pelo nome da instalação'
          />
          <CheckboxField name='mostrarInativos' label='Mostrar instalações inativas' />
        </HFlow>
        <div style={{ marginLeft: 'auto', order: 2 }}>
          <PopperButton size='small' skin='outline' placement='bottom-end' renderPopper={renderPopper}>
            <Icon icon='plus' />
            <Text color='inherit'>Adicionar instalação</Text>
          </PopperButton>
        </div>
      </HFlow>
    )
  }

  const renderPopper = (controls: PopperControls) => (
    <Form onSubmit={submitForm(controls)} render={renderForm(controls)} validate={validator} />
  )

  const submitForm = ({ close }) => {
    return (values, formApi: FormApi) => {
      return inserirTransmissaoLinkMutation({
        variables: { form: { name: values.name, hostname: values.hostname } },
      }).then(sucess => {
        setTimeout(formApi.reset)
        close()
        alert('success', `Instalação de destino ${values.name} adicionada com sucesso`)
        props.refetch()
      })
    }
  }

  const handleCancel = (controller: PopperControls, formProps: FormRenderProps) => e => {
    controller.close()
    setTimeout(formProps.form.reset)
  }

  const renderForm = (controller: PopperControls) => (formProps: FormRenderProps) => (
    <Box style={{ width: 430 }}>
      <VFlow vSpacing={1}>
        <TextField name='name' label='Nome da instalação' required />
        <TextField name='hostname' label='Endereço do servidor' required />
        <HFlow justifyContent='flex-end'>
          <Button skin='outline' size='small' onClick={handleCancel(controller, formProps)}>
            Cancelar
          </Button>
          <Button kind='primary' skin='default' size='small' onClick={formProps.handleSubmit}>
            Adicionar
          </Button>
        </HFlow>
      </VFlow>
    </Box>
  )

  return (
    <Form<TransmissaoLinksDestinoQueryInput>
      onSubmit={props.load}
      initialValues={props.initialValues}
      render={filterForm}
    />
  )
}
