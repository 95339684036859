import { Button, Cell, Grid, VFlow } from 'bold-ui'
import { Form, PasswordField, SubmitButton } from 'components/form'
import { DefaultFormFooter, FormFooter } from 'components/form/FormFooter'
import { FormPrompt } from 'components/form/FormPrompt'
import { FormApi } from 'final-form'
import { AlterarSenhaInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, required, senha } from 'util/validation'

export interface AlterarSenhaFormProps {
  initialValues?: AlterarSenhaInput
  pedirSenhaAtual?: boolean

  onSair(): void

  onSubmit(values: AlterarSenhaInput, form?: FormApi): Promise<any>
}

export const validator = (options: { pedirSenhaAtual: boolean }) =>
  createValidator<AlterarSenhaInput>(
    {
      senhaAtual: options.pedirSenhaAtual && [required],
      novaSenha: [required, senha],
      confirmacaoSenha: [required, senha],
    },
    (values: AlterarSenhaInput, errors) => {
      if (!errors.novaSenha && !errors.confirmacaoSenha && values.confirmacaoSenha !== values.novaSenha) {
        return {
          confirmacaoSenha: 'A confirmação da senha deve ser igual à nova senha.',
        }
      }
      if (!errors.novaSenha && !errors.senhaAtual && values.novaSenha === values.senhaAtual) {
        return {
          novaSenha: 'A nova senha não pode ser igual à senha anterior',
        }
      }
      return {}
    }
  )

export class AlterarSenhaForm extends React.Component<AlterarSenhaFormProps> {
  static defaultProps: Partial<AlterarSenhaFormProps> = {
    pedirSenhaAtual: true,
  }

  render() {
    return (
      <Form
        render={this.formRenderer}
        onSubmit={this.props.onSubmit}
        validate={validator({ pedirSenhaAtual: this.props.pedirSenhaAtual })}
        initialValues={this.props.initialValues}
      />
    )
  }

  private formRenderer = (formProps: FormRenderProps) => {
    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <FormPrompt />
        <VFlow vSpacing={6}>
          <Grid wrap style={{ width: 'calc(50% + 2rem)' }}>
            {this.props.pedirSenhaAtual && (
              <>
                <Cell size={6}>
                  <PasswordField
                    name='senhaAtual'
                    label='Senha atual'
                    placeholder='Senha'
                    required
                    autoFocus
                    clearable={false}
                    maxLength={20}
                  />
                </Cell>
                <Cell size={6}>
                  <span />
                </Cell>
              </>
            )}
            <Cell size={6}>
              <PasswordField
                name='novaSenha'
                label='Nova senha'
                placeholder='Senha'
                required
                autoFocus={!this.props.pedirSenhaAtual}
                clearable={false}
                maxLength={20}
              />
            </Cell>
            <Cell size={6}>
              <PasswordField
                name='confirmacaoSenha'
                label='Confirmação de senha'
                placeholder='Senha'
                required
                clearable={false}
                maxLength={20}
              />
            </Cell>
          </Grid>
          {this.props.pedirSenhaAtual && <DefaultFormFooter handleSubmit={formProps.handleSubmit} />}
          {!this.props.pedirSenhaAtual && (
            <FormFooter>
              <Button onClick={this.props.onSair}>Sair</Button>
              <SubmitButton handleSubmit={formProps.handleSubmit}>Avançar</SubmitButton>
            </FormFooter>
          )}
        </VFlow>
      </form>
    )
  }
}
