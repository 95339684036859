import { Alert, Button, Cell, Grid, HeadingSection, Link, VFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { CpfField, Form, FormRenderProps, SubmitButton } from 'components/form'
import { ChaveField } from 'components/form/field/ChaveField'
import { ContraChaveField } from 'components/form/field/ContraChaveField'
import { FormFooter } from 'components/form/FormFooter'
import { onlyNumbers } from 'components/label/masks'
import { FormState } from 'final-form'
import { useApolloClient } from 'graphql/hooks'
import { ChaveByCpfDocument } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { cpf, createValidator, required } from 'util/validation'

export interface ContraChaveFormModel {
  cpf: string
  chave?: string
  contraChave?: string
}

const validator = createValidator<ContraChaveFormModel>({
  cpf: [cpf, required],
  contraChave: required,
})

const path = metaPath<ContraChaveFormModel>()

export interface ContraChaveFormProps {
  isEditAdmin: boolean
  onSubmit(values: ContraChaveFormModel): void
  onSubmitSucceeded(formState: FormState<ContraChaveFormModel>): void
}

export default function ContraChaveForm(props: ContraChaveFormProps) {
  const { isEditAdmin, onSubmit, onSubmitSucceeded } = props
  const client = useApolloClient()
  const handleRejection = useErrorHandler()
  const history = useHistory()

  const onCancelarClicked = () => history.push('/')

  const onVoltarClicked = () => history.push('/reset')

  const renderForm = (formProps: FormRenderProps<ContraChaveFormModel>) => {
    const onCpfBlur = e => {
      if (!formProps.errors.cpf) {
        client
          .query({
            query: ChaveByCpfDocument,
            variables: { cpf: onlyNumbers(e.target.value) },
          })
          .then(response => formProps.form.change('chave', response.data.chaveByCpf))
          .catch(handleRejection)
      }
    }

    return (
      <>
        <form noValidate>
          <VFlow vSpacing={2}>
            <HeadingSection level={2} title='Insira a contra-chave da instalação' vSpace={0.5}>
              <Alert inline type='info' style={{ marginBottom: '1rem' }}>
                A contra-chave pode ser gerada na{' '}
                <Link href='http://sisab.saude.gov.br' target='_blank'>
                  plataforma do SISAB
                </Link>{' '}
                ou pode ser solicitada no Disque Saúde através do telefone 136 na opção 8.
              </Alert>
              <Grid>
                <Cell size={3}>
                  <CpfField
                    name={path.cpf}
                    label={isEditAdmin ? 'CPF do instalador' : 'CPF do novo instalador'}
                    onBlur={onCpfBlur}
                    required
                  />
                </Cell>

                <Cell size={9} />

                <Cell size={3}>
                  <ChaveField name={path.chave} label='Chave' defaultValue='chave' disabled />
                </Cell>

                <Cell size={3}>
                  <ContraChaveField name={path.contraChave} label='Contra-chave' required />
                </Cell>
              </Grid>

              <FormFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Button onClick={onVoltarClicked} data-testid='FormFooter.voltar'>
                    Voltar
                  </Button>
                </div>
                <div>
                  <Button onClick={onCancelarClicked} style={{ marginRight: '1rem' }} data-testid='FormFooter.cancelar'>
                    Cancelar
                  </Button>
                  <SubmitButton handleSubmit={formProps.handleSubmit} data-testid='FormFooter.salvar'>
                    {isEditAdmin ? 'Salvar' : 'Continuar'}
                  </SubmitButton>
                </div>
              </FormFooter>
            </HeadingSection>
          </VFlow>
        </form>
      </>
    )
  }
  return (
    <Form<ContraChaveFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      validate={validator}
      onSubmitSucceeded={onSubmitSucceeded}
    />
  )
}
