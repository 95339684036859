import { alert } from 'components/alert'
import { useCriarPerfilMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'

import { PerfilFormModel } from './components/PerfilForm'
import { PerfilFormView } from './PerfilFormView'

export default function PerfilCreateView() {
  const history = useHistory()
  const [criarPerfilMutation] = useCriarPerfilMutation()

  const onSubmit = (values: PerfilFormModel) => criarPerfilMutation({ variables: { input: values } })

  const onSubmitSucceeded = () => {
    history.push(`/perfil`)
    alert('success', 'Perfil salvo com sucesso')
  }

  return (
    <PerfilFormView
      breadcrumb='Cadastro'
      title='Cadastro de perfil'
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
    />
  )
}
