import { Tabs } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { TabLink } from 'components/route'
import { PecSwitch } from 'components/route/PecSwitch'
import { PrivateRoute } from 'components/route/PrivateRoute'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { Redirect } from 'react-router'
import Permissions from 'types/Permissions'

import { ConfiguracaoAgendaMunicipioRootView } from './configuracaoPadrao/ConfiguracaoAgendaMunicipioRootView'
import { GrupoExameRootView } from './gruposExames/GrupoExameRootView'
import { CompartilhamentoProntuarioViewBox } from './prontuario/CompartilhamentoProntuarioViewBox'
import { TipoServicoView } from './tipoServico/TipoServicoView'

export function GestaoMunicipalRootView() {
  const { match } = useRouter()
  const { hasAuthorization } = useSession()

  return (
    <>
      <Breadcrumb title='Gestão municipal' />
      <PageHeaderSection title='Gestão municipal'>
        <Tabs>
          <CheckPermission permission={Permissions.configuracaoMunicipal.configurarCompartilhamentoDeProntuarios}>
            <TabLink to={`${match.url}/compartpront`}>Compartilhamento de prontuário</TabLink>
          </CheckPermission>
          <CheckPermission permission={Permissions.configuracaoMunicipal.configurarAgendaPadrao}>
            <TabLink to={`${match.url}/cfgagendapadrao`}>Configuração da agenda padrão</TabLink>
          </CheckPermission>
          <CheckPermission permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames}>
            <TabLink to={`${match.url}/gpexames`}>Grupos de exames</TabLink>
          </CheckPermission>
          <CheckPermission permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirTipoDeServico}>
            <TabLink to={`${match.url}/tpservico`}>Tipos de serviço</TabLink>
          </CheckPermission>
        </Tabs>
      </PageHeaderSection>
      <PageContent>
        <PecSwitch>
          {hasAuthorization(Permissions.configuracaoMunicipal.configurarCompartilhamentoDeProntuarios) && (
            <Redirect exact from={`${match.url}`} to={`${match.url}/compartpront`} />
          )}
          {hasAuthorization(Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames) && (
            <Redirect exact from={`${match.url}`} to={`${match.url}/gpexames`} />
          )}
          {hasAuthorization(Permissions.configuracaoMunicipal.cadastrarEditarEExcluirTipoDeServico) && (
            <Redirect exact from={`${match.url}`} to={`${match.url}/tpservico`} />
          )}
          <PrivateRoute
            path={`${match.url}/compartpront`}
            component={CompartilhamentoProntuarioViewBox}
            permission={Permissions.configuracaoMunicipal.configurarCompartilhamentoDeProntuarios}
          />
          <PrivateRoute
            path={`${match.url}/cfgagendapadrao`}
            component={ConfiguracaoAgendaMunicipioRootView}
            permission={Permissions.configuracaoMunicipal.configurarAgendaPadrao}
          />
          <PrivateRoute
            path={`${match.url}/tpservico`}
            component={TipoServicoView}
            permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirTipoDeServico}
          />
          <PrivateRoute
            path={`${match.url}/gpexames`}
            component={GrupoExameRootView}
            permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames}
          />
        </PecSwitch>
      </PageContent>
    </>
  )
}
