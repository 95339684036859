import gql from 'graphql-tag'

export const EQUIPE_DETAIL_QUERY = gql`
  query EquipeDetailQuery($id: ID!) {
    equipe(id: $id) {
      id
      nome
      ine
      area
      ativo
      tipoEquipe {
        nome
        sigla
      }
    }
  }
`
