import { AgeRangeType } from 'components/date/AgeRange'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'
import moment from 'moment'
import { idGrupoCondicao } from 'types/enums'

import AcompanhamentoCondicaoSaudeFormModel, { AgeRangeFilter } from './AcompanhamentoCondicaoSaudeFormModel'

export default (model: AcompanhamentoCondicaoSaudeFormModel): AcompanhamentoCondicaoSaudeQueryInput => ({
  ciaps: model?.problemasCondicoes?.filter(item => 'Ciap' === item.__typename).map(item => item.codigo),
  cid10: model?.problemasCondicoes?.filter(item => 'Cid10' === item.__typename).map(item => item.codigo),
  grupoCondicao: model?.grupoCondicao?.map(item => idGrupoCondicao[item.grupoCondicao]),
  somenteProblemasCondicoesAtivas: model?.ativoListaProblema ? model.ativoListaProblema : false,
  unidadeResponsavelId: model?.unidadeResponsavelId,
  equipeResponsavelId: model.isCoordenador ? model?.equipeResponsavel?.id : model?.equipeResponsavelId,
  sexo: model?.sexo,
  identidadeGenero: model?.identidadeGenero,
  faixaEtariaInicio: AgeRangeFilter.TODAS_FAIXAS !== model?.faixaEtariaFilter ? mountFaixaEtariaInicio(model) : null,
  faixaEtariaFim: AgeRangeFilter.TODAS_FAIXAS !== model?.faixaEtariaFilter ? mountFaixaEtariaFim(model) : null,
  periodoUltimoAtendimento: model?.periodoUltimoAtendimento,
})

function mountFaixaEtariaInicio(model: AcompanhamentoCondicaoSaudeFormModel) {
  const { faixaEtariaFilter, faixaEtaria } = model
  if (faixaEtariaFilter !== AgeRangeFilter.OUTRA) {
    if (AgeRangeFilter.CRIANCA === faixaEtariaFilter) {
      return null
    } else if (AgeRangeFilter.ADOLESCENTE === faixaEtariaFilter) {
      return moment().subtract('year', 11)
    } else if (AgeRangeFilter.ADULTO === faixaEtariaFilter) {
      return moment().subtract('year', 20)
    } else if (AgeRangeFilter.IDOSO === faixaEtariaFilter) {
      return moment().subtract('year', 60)
    }
  } else if (faixaEtariaFilter === AgeRangeFilter.OUTRA) {
    if (faixaEtaria.firstValue) {
      if (AgeRangeType.ANOS === faixaEtaria.periodo) {
        return moment().subtract('year', faixaEtaria.firstValue)
      } else if (AgeRangeType.MESES === faixaEtaria.periodo) {
        return moment().subtract('month', faixaEtaria.firstValue)
      } else {
        return moment().subtract('day', faixaEtaria.firstValue)
      }
    } else {
      return null
    }
  }
}

function mountFaixaEtariaFim(model: AcompanhamentoCondicaoSaudeFormModel) {
  const { faixaEtariaFilter, faixaEtaria } = model
  if (faixaEtariaFilter !== AgeRangeFilter.OUTRA) {
    if (AgeRangeFilter.CRIANCA === faixaEtariaFilter) {
      return moment()
        .subtract('year', 10)
        .subtract('year', 1)
        .add('day', 1)
    } else if (AgeRangeFilter.ADOLESCENTE === faixaEtariaFilter) {
      return moment()
        .subtract('year', 19)
        .subtract('year', 1)
        .add('day', 1)
    } else if (AgeRangeFilter.ADULTO === faixaEtariaFilter) {
      return moment()
        .subtract('year', 59)
        .subtract('year', 1)
        .add('day', 1)
    } else if (AgeRangeFilter.IDOSO === faixaEtariaFilter) {
      return null
    }
  } else if (faixaEtariaFilter === AgeRangeFilter.OUTRA) {
    if (faixaEtaria.secondValue) {
      if (AgeRangeType.ANOS === faixaEtaria.periodo) {
        return moment()
          .subtract('year', faixaEtaria.secondValue)
          .subtract('year', 1)
          .add('day', 1)
      } else if (AgeRangeType.MESES === faixaEtaria.periodo) {
        return moment()
          .subtract('month', faixaEtaria.secondValue)
          .subtract('month', 1)
          .add('day', 1)
      } else {
        return moment().subtract('day', faixaEtaria.secondValue)
      }
    } else {
      return null
    }
  }
}
