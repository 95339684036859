import { HFlow, Icon, PagedTable, Tag, Text, Tooltip } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Ellipsis } from 'components/Ellipsis'
import { Cpf } from 'components/label'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useProfissionalTableQuery } from 'graphql/hooks.generated'
import { ProfissionaisQueryInput, ProfissionalTableQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import Permissions from 'types/Permissions'
import { ProfissionalTableFilter } from 'view/profissional/list/ProfissionalTableFilter'

import { ProfissionalTableDropdown } from './ProfissionalTableDropdown'

export type ItemType = ProfissionalTableQuery['profissionais']['content'][0]

export interface ProfissionalTableProps {
  initialFilter?: ProfissionaisQueryInput
}

export function ProfissionalTable(props: ProfissionalTableProps) {
  const { initialFilter } = props
  const { hasAuthorization } = useSession()
  const [filter, setFilter] = useState<ProfissionaisQueryInput>({
    pageParams: {
      sort: ['nome'],
    },
    ...initialFilter,
  })

  const { data, loading, refetch } = useProfissionalTableQuery({
    variables: { input: filter },
  })

  const profissionais = data && data.profissionais

  const renderNome = (row: ItemType) => {
    return (
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text>{row.nome}</Text>
        {row.usuario && row.usuario.bloqueado && <Tag type='danger'>Login bloqueado</Tag>}
        {naoTemLotacao(row) && <Tag>Sem lotação</Tag>}
        {naoTemLotacaoAtiva(row) && <Tag>Sem lotação ativa</Tag>}
      </HFlow>
    )
  }

  const renderCpf = (row: ItemType) => <Cpf value={row.cpf} />
  const renderCns = (row: ItemType) => <Text>{row.cns}</Text>
  const renderCbo = (row: ItemType) =>
    extractCbos(row).map((c, index) => (
      <Ellipsis key={index} style={{ lineHeight: '1rem' }}>
        {c}
      </Ellipsis>
    ))
  const renderButtons = (row: ItemType) => (
    <>
      <Tooltip text='Visualizar'>
        <ButtonLink size='small' skin='ghost' to={`/profissional/${row.id}`}>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </Tooltip>

      {(hasAuthorization(Permissions.visualizarProfissionais.cadastrarEditarEExcluir) ||
        hasAuthorization(Permissions.visualizarProfissionais.redefinirSenha)) && (
        <ProfissionalTableDropdown profissional={row} refetch={refetch} />
      )}
    </>
  )

  const naoTemLotacao = (row: ItemType) => !row.lotacoes || row.lotacoes.length === 0
  const naoTemLotacaoAtiva = (row: ItemType) =>
    row.lotacoes && row.lotacoes.filter(lotacao => lotacao.ativo).length === 0
  const extractCbos = (row: ItemType) =>
    row.lotacoes ? Array.from(new Set(row.lotacoes.map(lotacao => lotacao.cbo.nome).sort())) : []

  const tableProps = usePagedTableProps({
    loading,
    result: profissionais,
    onChange: setFilter,
  })

  return (
    <TableBox header={<ProfissionalTableFilter onChange={setFilter} initialValues={filter} />}>
      <PagedTable<ItemType>
        {...tableProps}
        data-testid='ProfissionalTable'
        columns={[
          {
            name: 'nome',
            header: 'Profissional',
            render: renderNome,
            sortable: true,
          },
          {
            name: 'cns',
            header: 'CNS',
            render: renderCns,
            sortable: true,
            style: { whiteSpace: 'nowrap' },
          },
          {
            name: 'cpf',
            header: 'CPF',
            render: renderCpf,
            sortable: true,
            style: { whiteSpace: 'nowrap' },
          },
          {
            name: 'cbo',
            header: 'CBO',
            render: renderCbo,
            style: { maxWidth: '200px' },
          },
          {
            name: 'buttons',
            render: renderButtons,
            style: { textAlign: 'right', whiteSpace: 'nowrap' },
          },
        ]}
      />
    </TableBox>
  )
}
