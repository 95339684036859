/* eslint-disable no-console */
import { Cell, FormControl, Grid, HFlow, Text, VFlow } from 'bold-ui'
import {
  CheckboxField,
  CnsField,
  CpfField,
  DateField,
  FormRenderProps,
  MunicipioSelectField,
  RadioField,
  SexoSelectField,
  TextField,
} from 'components/form'
import { DeclaracaoObitoField } from 'components/form/field/DeclaracaoObitoField'
import { EtniaSelectField } from 'components/form/field/select/EtniaSelectField'
import { PaisSelectField } from 'components/form/field/select/PaisSelectField'
import { RacaCorSelectField } from 'components/form/field/select/RacaCorSelectField'
import { TransformCaseTextField } from 'components/form/final-form/TextField/TransformCaseTextField'
import { onlyNumbers } from 'components/label/masks'
import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { MetaPath, MetaRoot } from 'util/metaPath'
import {
  beforeEqualToday,
  cns,
  consistentPeriod,
  cpf,
  createValidator,
  dataNasc,
  ErrorObject,
  length,
  maxLength,
  minLength,
  nome,
  required,
  validate,
} from 'util/validation'

import CidadaoFormModel from './CidadaoFormModel'

export const validator = createValidator<CidadaoFormModel>(
  {
    cpf: [cpf],
    cns: [cns],
    nome: [required, nome, minLength[5], maxLength[100]],
    nomeSocial: [minLength[5], maxLength[100]],
    dataNascimento: [required, dataNasc],
    sexo: [required],
    racaCor: [required],
    numeroDeclaracaoObito: [length(9)],
  },

  (values: CidadaoFormModel, errors: ErrorObject<CidadaoFormModel>) => {
    if (values == null) {
      return errors
    }
    if (values.racaCor?.value === RacaCorDbEnum.INDIGENA) {
      errors.etnia = required(values.etnia)
    }
    if (!values.desconheceNomeMae) {
      errors.nomeMae = validate(values.nomeMae, [required, nome])
    }
    if (!values.desconheceNomePai) {
      errors.nomePai = validate(values.nomePai, [required, nome])
    }

    if (NacionalidadeEnum.BRASILEIRA === values.nacionalidade) {
      errors.municipioNascimento = required(values.municipioNascimento)
    } else if (NacionalidadeEnum.NATURALIZADA === values.nacionalidade) {
      errors.portariaNaturalizacao = validate(values.portariaNaturalizacao, [required, maxLength[16]])
      errors.dataNaturalizacao = validate(values.dataNaturalizacao, [required, beforeEqualToday])
    } else if (NacionalidadeEnum.ESTRANGEIRA === values.nacionalidade) {
      errors.paisNascimento = required(values.paisNascimento)
      errors.dataEntradaPais = validate(values.dataEntradaPais, [required, beforeEqualToday])
    }

    if (values.cidadaoFaleceu) {
      errors.dataObito = validate(values.dataObito, [required, beforeEqualToday])
    }

    const errorDataNascimentoEntradaPais = consistentPeriod(
      values.dataNascimento,
      values.dataEntradaPais,
      'Data de nascimento',
      'Data de entrada no Brasil'
    )
    if (errorDataNascimentoEntradaPais) {
      errors.dataNascimento = errorDataNascimentoEntradaPais
      errors.dataEntradaPais = errorDataNascimentoEntradaPais
    }
    const errorDataNascimentoNaturalizacao = consistentPeriod(
      values.dataNascimento,
      values.dataNaturalizacao,
      'Data de nascimento',
      'Data de naturalização'
    )
    if (errorDataNascimentoNaturalizacao) {
      errors.dataNascimento = errorDataNascimentoNaturalizacao
      errors.dataNaturalizacao = errorDataNascimentoNaturalizacao
    }
    const errorDataNascimentoObito = consistentPeriod(
      values.dataNascimento,
      values.dataObito,
      'Data de nascimento',
      'Data de óbito'
    )

    if (errorDataNascimentoObito) {
      errors.dataNascimento = errorDataNascimentoObito
      errors.dataObito = errorDataNascimentoObito
    }
    const errorDataEntradaPaisObito = consistentPeriod(
      values.dataEntradaPais,
      values.dataObito,
      'Data de entrada no Brasil',
      'Data de óbito'
    )
    if (errorDataEntradaPaisObito) {
      errors.dataEntradaPais = errorDataEntradaPaisObito
      errors.dataObito = errorDataEntradaPaisObito
    }
    const errorDataNaturalizacaoObito = consistentPeriod(
      values.dataNaturalizacao,
      values.dataObito,
      'Data de naturalização',
      'Data de óbito'
    )
    if (errorDataNaturalizacaoObito) {
      errors.dataNaturalizacao = errorDataNaturalizacaoObito
      errors.dataObito = errorDataNaturalizacaoObito
    }

    return errors
  }
)

export interface CidadaoDadosFieldGroupProps {
  name: MetaPath<CidadaoFormModel> | MetaRoot<CidadaoFormModel>
  formProps: FormRenderProps<any>
}

export function CidadaoDadosFieldGroup(props: CidadaoDadosFieldGroupProps) {
  const { name } = props

  return (
    <>
      <Grid wrap>
        <Cell size={3}>
          <CpfField name={name.cpf} label='CPF' />
        </Cell>
        <Cell size={3}>
          <CnsField name={name.cns} label='CNS' disabled={props.formProps?.initialValues?.cns} />
        </Cell>
        <Cell size={3} />
        <Cell size={6}>
          <TransformCaseTextField case='upper' name={name.nome} label='Nome completo' maxLength={70} required />
        </Cell>
        <Cell size={6}>
          <TransformCaseTextField case='upper' name={name.nomeSocial} label='Nome social' maxLength={70} />
        </Cell>
        <Cell size={3}>
          <DateField name={name.dataNascimento} label='Data de nascimento' required />
        </Cell>
        <Cell size={3}>
          <SexoSelectField name={name.sexo} label='Sexo' required />
        </Cell>
        <Cell size={6} />
        <Cell size={6}>
          <RacaCorSelectField name={name.racaCor} label='Raça/Cor' required />
        </Cell>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <EtniaSelectField
                name={name.etnia}
                label='Etnia'
                disabled={RacaCorDbEnum.INDIGENA !== values.racaCor?.value}
                required={RacaCorDbEnum.INDIGENA === values.racaCor?.value}
              />
            )}
          </FormSpy>
        </Cell>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) =>
              !values.desconheceNomeMae ? (
                <TransformCaseTextField case='upper' name={name.nomeMae} label='Nome da mãe' maxLength={70} required />
              ) : (
                <TextField name='nomeMaeDummy' label='Nome da mãe' disabled />
              )
            }
          </FormSpy>
        </Cell>
        <Cell size={6}>
          <FormControl label={<span>&nbsp;</span>}>
            <CheckboxField
              name={name.desconheceNomeMae}
              label='Desconhece essa informação'
              style={{ marginTop: '0.25rem' }}
            />
          </FormControl>
        </Cell>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) =>
              !values.desconheceNomePai ? (
                <TransformCaseTextField case='upper' name={name.nomePai} label='Nome do pai' maxLength={70} required />
              ) : (
                <TextField name='nomePaiDummy' label='Nome do pai' disabled />
              )
            }
          </FormSpy>
        </Cell>
        <Cell size={6}>
          <FormControl label={<span>&nbsp;</span>}>
            <CheckboxField
              name={name.desconheceNomePai}
              label='Desconhece essa informação'
              style={{ marginTop: '0.25rem' }}
            />
          </FormControl>
        </Cell>
        <Cell size={12}>
          <VFlow vSpacing={0.8}>
            <Text fontWeight='bold'>Nacionalidade</Text>
            <HFlow>
              <RadioField name={name.nacionalidade} label='Brasileira' value={NacionalidadeEnum.BRASILEIRA} />
              <RadioField name={name.nacionalidade} label='Naturalizado' value={NacionalidadeEnum.NATURALIZADA} />
              <RadioField name={name.nacionalidade} label='Estrangeiro' value={NacionalidadeEnum.ESTRANGEIRA} />
            </HFlow>
          </VFlow>
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              <Cell size={6}>
                {values.nacionalidade === NacionalidadeEnum.ESTRANGEIRA && (
                  <PaisSelectField name={name.paisNascimento} label='País de nascimento' required />
                )}
                {values.nacionalidade === NacionalidadeEnum.NATURALIZADA && (
                  <TextField
                    name={name.portariaNaturalizacao}
                    label='Portaria de naturalização'
                    maxLength={16}
                    parse={onlyNumbers}
                    required
                  />
                )}
                {values.nacionalidade === NacionalidadeEnum.BRASILEIRA && (
                  <MunicipioSelectField name={name.municipioNascimento} label='Município de nascimento' required />
                )}
              </Cell>
              <Cell size={3}>
                {values.nacionalidade === NacionalidadeEnum.ESTRANGEIRA && (
                  <DateField name={name.dataEntradaPais} label='Data de entrada no Brasil' required />
                )}
                {values.nacionalidade === NacionalidadeEnum.NATURALIZADA && (
                  <DateField name={name.dataNaturalizacao} label='Data de naturalização' required />
                )}
              </Cell>
            </>
          )}
        </FormSpy>
        <Cell size={12}>
          <CheckboxField name={name.cidadaoFaleceu} label='Cidadão faleceu' />
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {values.cidadaoFaleceu && (
                <>
                  <Cell size={3}>
                    <DateField name={name.dataObito} label='Data de óbito' required />
                  </Cell>
                  <Cell size={3}>
                    <DeclaracaoObitoField name={name.numeroDeclaracaoObito} label='Número da declaração de óbito' />
                  </Cell>
                </>
              )}
            </>
          )}
        </FormSpy>
      </Grid>
    </>
  )
}
