import { HFlow, Text } from 'bold-ui'
import { AsyncQuerySelectField, AsyncSelectQueryFieldProps } from 'components/form/field'
import { Cpf } from 'components/label'
import { ProfissionalSelectDocument } from 'graphql/hooks.generated'
import { ProfissionalSelectQuery, ProfissionalSelectQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE } from '../AsyncQuerySelectField'

export type ProfissionalSelectModel = ProfissionalSelectQuery['profissionais']['content'][0]

export interface ProfissionalSelectFieldProps
  extends AsyncSelectQueryFieldProps<
    ProfissionalSelectModel,
    ProfissionalSelectQuery,
    ProfissionalSelectQueryVariables
  > {
  mostrarSemLotacaoAtiva?: boolean
}

export function ProfissionalSelectField(props: ProfissionalSelectFieldProps) {
  const { mostrarSemLotacaoAtiva } = props

  const variables = (inputQuery: string): ProfissionalSelectQueryVariables => ({
    input: {
      query: inputQuery,
      mostrarSemLotacaoAtiva: mostrarSemLotacaoAtiva,
      pageParams: {
        size: DEFAULT_SELECT_SIZE,
        sort: ['nome'],
      },
    },
  })

  const extractItems = (data: ProfissionalSelectQuery) => data && data.profissionais && data.profissionais.content

  const itemToString = (item: ProfissionalSelectModel) => item && item.nome

  const renderItem = (item: ProfissionalSelectModel) => {
    return (
      <>
        <Text component='p' fontWeight='bold'>
          {item.nome}
        </Text>
        <HFlow hSpacing={0.25}>
          <Text fontWeight='bold'>CPF</Text>
          <Cpf value={item.cpf} />
        </HFlow>
      </>
    )
  }

  return (
    <AsyncQuerySelectField<ProfissionalSelectModel, ProfissionalSelectQuery, ProfissionalSelectQueryVariables>
      query={ProfissionalSelectDocument}
      variables={variables}
      extractItems={extractItems}
      itemToString={itemToString}
      renderItem={renderItem}
      {...props}
    />
  )
}
