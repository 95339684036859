import { HFlow } from 'bold-ui'
import { Form, TextField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { FormState } from 'final-form'
import { TransmissaoRecebimentoLoteFiltro } from 'graphql/types.generated'
import React from 'react'

interface TransmissaoRecebimentoLoteFilterProps {
  initialValues: TransmissaoRecebimentoLoteFiltro
  setFilter(values: TransmissaoRecebimentoLoteFiltro): void
  refetch(): Promise<any>
}

export function TransmissaoRecebimentoLoteFilter(props: TransmissaoRecebimentoLoteFilterProps) {
  const handleChange = (formState: FormState<TransmissaoRecebimentoLoteFiltro>) => {
    props.setFilter(formState.values)
  }
  const renderFilter = () => (
    <HFlow justifyContent='space-between'>
      <FormDebouncedValueSpy onChange={handleChange} />
      <TextField
        name='query'
        icon='zoomOutline'
        style={{ width: '19rem' }}
        placeholder='Pesquise por lote ou responsável'
      />
    </HFlow>
  )
  return <Form initialValues={props.initialValues} render={renderFilter} />
}
