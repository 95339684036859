import { ApolloQueryResult } from 'apollo-client'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { useTableSort } from 'components/table/useTableSort'
import { useGrupoExamesLazyQuery } from 'graphql/hooks.generated'
import { GrupoExameTableQuery } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'

import { GrupoExameTableHeader } from './GrupoExameTableHeader'
import { GrupoExameTableRow } from './GrupoExameTableRow'

export type GrupoExameRowItem = GrupoExameTableQuery['gruposExame']['content'][0]

interface GrupoExameTableProps {
  data: GrupoExameTableQuery
  loading: boolean
  sort: string[]
  onSortChange(sort: string[]): void
  refetch(): Promise<ApolloQueryResult<GrupoExameTableQuery>>
}

export default function GrupoExameTable(props: GrupoExameTableProps) {
  const { refetch, data, sort, onSortChange } = props
  const { getSortProps } = useTableSort({ sort, onSortChange })
  const [expandedRow, setExpandedRow] = useState<string>()

  const [execute, { data: grupoExameData }] = useGrupoExamesLazyQuery()

  useEffect(() => {
    if (expandedRow) {
      execute({ variables: { id: expandedRow } })
    }
  }, [expandedRow, execute])

  const handleClick = (row: GrupoExameRowItem) => () => {
    setExpandedRow(expandedRow === row.id ? null : row.id)
  }

  if (!data || !data.gruposExame || !data.gruposExame.content) {
    return null
  }

  return (
    <TableBox header={<GrupoExameTableHeader quantidade={data.gruposExame.content.length} />}>
      <Table hovered>
        <TableHead>
          <TableRow>
            <TableHeader {...getSortProps('nome')} style={{ width: '50%' }}>
              Grupo de exames
            </TableHeader>
            <TableHeader>Quantidade de exames</TableHeader>
            <TableHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.gruposExame.content.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} style={{ textAlign: 'center', color: '#696979', fontStyle: 'italic' }}>
                Nenhum registro encontrado
              </TableCell>
            </TableRow>
          ) : (
            data.gruposExame.content.map(row => {
              return (
                <GrupoExameTableRow
                  key={row.id}
                  grupoExame={row}
                  refetch={refetch}
                  procedimentos={grupoExameData && grupoExameData.grupoExame && grupoExameData.grupoExame.procedimentos}
                  expandedRow={expandedRow}
                  handleClick={handleClick}
                />
              )
            })
          )}
        </TableBody>
      </Table>
      <TableFooter
        style={{ borderTopWidth: '0px' }}
        totalElements={data.gruposExame.pageInfo.totalElements}
        sizeOptions={[]}
      />
    </TableBox>
  )
}
