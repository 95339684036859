import React from 'react'
import { Route, Switch } from 'react-router'
import { InitialContainer } from 'view/login/InitialContainer'
import { InitialContent } from 'view/login/InitialContent'
import { LoginView } from 'view/login/LoginView'
import { RecuperarSenhaView } from 'view/recuperarSenha/RecuperarSenhaView'
import { SolicitarRecuperarSenhaView } from 'view/recuperarSenha/SolicitarRecuperarSenhaView'
import ResetRootView from 'view/reset/ResetRootView'

export const PublicRootView = () => {
  return (
    <Switch>
      <Route component={ResetRootView} path='/reset' />
      <Route component={RecuperarSenhaView} path='/recuperarSenha/:token' exact />
      <Route component={InitialView} />
    </Switch>
  )
}

const InitialView = () => (
  <InitialContainer>
    <InitialContent>
      <Switch>
        <Route component={SolicitarRecuperarSenhaView} path='/recuperarSenha' exact />
        <Route component={LoginView} path='/' />
      </Switch>
    </InitialContent>
  </InitialContainer>
)
