import { Heading, Theme, useStyles } from 'bold-ui'
import { Box } from 'components/Box'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { useConfiguracaoAgendaLotacaoQuery, useProfissionalDetailQuery } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React, { CSSProperties } from 'react'
import { ProfissionalHeaderIdentifier } from 'view/profissional/components/ProfissionalHeaderIdentifier'
import ProfissionalHeaderIdentifierDto from 'view/profissional/components/ProfissionalHeaderIdentifierDto'

import FechamentoForm from './FechamentoForm'
import FechamentoTable from './FechamentoTable'
import { useFechamentoTableQuery } from './FechamentoTableQuery'

interface UrlParams {
  lotacaoId: string
  profissionalId: string
}

export function FechamentoAgendaLotacaoView() {
  const { match } = useRouter<UrlParams>()
  const { classes } = useStyles(createStyles)

  const cfgAgendaLotacaoQuery = useConfiguracaoAgendaLotacaoQuery({
    variables: { lotacaoId: match.params.lotacaoId },
    fetchPolicy: 'network-only',
  })

  // TODO proveniente do ConfiguracaoAgendaLotacaoView
  // TODO utilizar o profissional da query da lotação, mas lá não foi implementado ainda
  const { data } = useProfissionalDetailQuery({
    variables: { id: match.params.profissionalId },
  })

  const {
    data: { fechamentos },
    refetch,
  } = useFechamentoTableQuery(match.params.lotacaoId)

  if (!cfgAgendaLotacaoQuery.data?.lotacao) {
    return null
  }

  const { lotacao } = cfgAgendaLotacaoQuery.data
  const nomeProfissional = data.profissional?.nome
  const profissionalHeaderIdentifierDto = {
    nomeProfissional,
    lotacao,
  } as ProfissionalHeaderIdentifierDto

  return (
    <PageContent type='filled' style={classes.container}>
      <Breadcrumb title='Visualização' to={`/profissional/${match.params.profissionalId}`} />
      <Breadcrumb title='Configuração de fechamentos da agenda' />

      <Heading level={1} style={classes.heading}>
        Configuração de fechamentos da agenda
      </Heading>
      <ProfissionalHeaderIdentifier profissional={profissionalHeaderIdentifierDto} />
      <Box style={classes.form}>
        <FechamentoForm onUpdated={refetch} idLotacao={match.params.lotacaoId} />
      </Box>
      <FechamentoTable data={fechamentos ? fechamentos : []} onUpdated={refetch} />
    </PageContent>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    backgroundColor: theme.pallete.surface.main,
  } as CSSProperties,
  heading: {
    marginBottom: '2rem',
  } as CSSProperties,
  form: {
    marginBottom: '1rem',
    marginTop: '1.75rem',
  } as CSSProperties,
})
