import { Alert, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { useCompartilhamentoProntuarioQuery } from 'graphql/hooks.generated'
import React from 'react'

import { CompartilhamentoProntuarioForm } from './CompartilhamentoProntuarioForm'

export interface CompartilhamentoProntuarioEditComponentProps {}

export const CompartilhamentoProntuarioEditComponent = (props: CompartilhamentoProntuarioEditComponentProps) => {
  const { data, refetch } = useCompartilhamentoProntuarioQuery()

  const ativo = data?.compartilhamentoProntuario?.ativo

  return (
    <VFlow>
      <CompartilhamentoProntuarioForm reload={refetch} initialValue={{ ativo }} />
      {ativo && (
        <Alert type='info' inline>
          Ao desabilitar o compartilhamento não será possível visualizar informações de atendimentos realizados em
          outros municípios da mesma instalação.
        </Alert>
      )}
      {!ativo && data?.compartilhamentoProntuario != null && (
        <Text style={{ float: 'right' }}>
          Desabilitado por {data.compartilhamentoProntuario.profissional.nome} em{' '}
          <DateTime value={data.compartilhamentoProntuario.dataUltimaAlteracao} />
        </Text>
      )}
    </VFlow>
  )
}
