import { TipoAcesso } from 'api/schema'
import { Checkbox, Highlight, Spinner } from 'bold-ui'
import { useRecursosQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'
import { createTree, RecursoNode } from 'view/perfil/components/RecursoNode'

import { RecursoTree } from './RecursoTree'

interface RecursoTreeFieldProps {
  value: string[]
  tipoPerfil: TipoAcesso

  onChange(value: string[]): any
}

export const RecursoTreeField = (props: RecursoTreeFieldProps) => {
  const {
    data: { recursos },
    loading,
  } = useRecursosQuery({
    fetchPolicy: 'cache-first',
    variables: { tipoAcesso: props.tipoPerfil },
  })

  const [root, setRoot] = useState<RecursoNode>(null)

  useEffect(() => {
    const newRoot = createTree(recursos ? recursos : [])
    newRoot.setTreeValue(props.value)
    setRoot(newRoot)
  }, [props.value, recursos])

  useEffect(() => {
    if (root) {
      root.setTreeValue(props.value)
    }
  }, [props.value, root])

  const handleChange = (node: RecursoNode) => e => {
    node.changeValue(e.target.checked)
    const newValue = node.getTreeValue()
    props.onChange(newValue)
  }

  const renderNode = (node: RecursoNode, search: string) => {
    return (
      <Checkbox
        value={node.state.uri}
        checked={node.state.checked}
        indeterminate={node.state.checked && node.checkCount() !== node.totalCount()}
        label={<Highlight text={node.state.label} words={[search]} />}
        onChange={handleChange(node)}
      />
    )
  }

  if (!root) {
    return null
  }

  if (loading) {
    return <Spinner />
  }

  return <RecursoTree root={root} renderNode={renderNode} />
}
