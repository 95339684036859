import { Omit, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { LogradouroSelectFieldDocument } from 'graphql/hooks.generated'
import { LogradouroSelectFieldQuery, LogradouroSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type LogradouroSelectModel = Partial<LogradouroSelectFieldQuery['logradouros']['content'][0]>

export interface LogradouroSelectFieldProps
  extends Omit<SelectFieldProps<LogradouroSelectModel>, 'items' | 'itemToString'> {
  bairroId?: ID
  tipoLogradouroId?: ID
  municipioId?: ID
}

export function LogradouroSelectField(props: LogradouroSelectFieldProps) {
  const { bairroId, tipoLogradouroId, municipioId, ...rest } = props

  const { loading, ...asyncProps } = useAsyncQuerySelect<
    LogradouroSelectModel,
    LogradouroSelectFieldQuery,
    LogradouroSelectFieldQueryVariables
  >({
    query: LogradouroSelectFieldDocument,
    extractItems: data => data && data.logradouros && data.logradouros.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        municipioId,
        bairroId,
        tipoLogradouroId,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: LogradouroSelectModel) => item && item.nome
  const createNewItem = (text: string) => ({ nome: text })
  const renderItem = (item: LogradouroSelectModel) => (
    <>
      <Text component='p' fontWeight='bold'>
        {item.nomeExibicao}
      </Text>
      <Text component='p'>
        {item.bairro.nome} | {item.bairro.municipio.nome} - {item.bairro.municipio.uf.sigla}
      </Text>
    </>
  )

  return (
    <SelectField<LogradouroSelectModel>
      renderItem={renderItem}
      itemToString={itemToString}
      createNewItem={createNewItem}
      icon={null}
      {...asyncProps}
      {...rest}
    />
  )
}
