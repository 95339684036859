import { ButtonProps, IconProps, modal } from 'bold-ui'
import { ConfirmModalBody } from 'components/confirm/ConfirmModalBody'
import React from 'react'

export interface ConfirmConfig {
  title: React.ReactNode
  body?: React.ReactNode
  confirmLabel?: string
  cancelLabel?: string
  type?: ButtonProps['kind']
  onCancel?(e: React.SyntheticEvent): any
  onClose?(): any
  onConfirm(e: React.SyntheticEvent): any
}

const defaultConfig: ConfirmConfig = {
  body: null,
  title: 'Confirmação',
  cancelLabel: 'Cancelar',
  confirmLabel: 'Confirmar',
  type: 'primary',
  onCancel: () => null,
  onConfirm: () => null,
}

const icons: { [key in ButtonProps['kind']]: IconProps['icon'] } = {
  normal: 'questionMarkOutline',
  primary: 'questionMarkOutline',
  danger: 'exclamationTriangleOutline',
}

export const confirm = (config: ConfirmConfig) => {
  const { title, body, onCancel, onConfirm, confirmLabel, cancelLabel, type, onClose } = { ...defaultConfig, ...config }

  return modal({
    size: 'small',
    onClose,
    render: () => (
      <ConfirmModalBody title={title} icon={icons[type]} type={type}>
        {body}
      </ConfirmModalBody>
    ),
    actions: [
      { label: cancelLabel, onClick: onCancel },
      { label: confirmLabel, kind: type, onClick: onConfirm },
    ],
  })
}
