import { DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { confirm } from 'components/confirm'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { useExcluirLoteImunobiologicoMutation, useSetAtivadoLoteImunobiologicoMutation } from 'graphql/hooks.generated'
import { LoteImunobiologico } from 'graphql/types.generated'
import React from 'react'

interface LoteImunobiologicoTableDropDownProps {
  loteImunoItem: LoteImunobiologico
  reload(): Promise<any>
}

export function LoteImunobiologicoTableDropDown(props: LoteImunobiologicoTableDropDownProps) {
  const { loteImunoItem, reload } = props
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const [excluir] = useExcluirLoteImunobiologicoMutation({ variables: { idLote: loteImunoItem.id } })
  const [ativarInativar] = useSetAtivadoLoteImunobiologicoMutation({ variables: { idLote: loteImunoItem.id } })

  const handleExcluirClick = excluirLote => {
    return confirm({
      title: `Deseja excluir o lote de imunobiológico ${loteImunoItem.lote}?`,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () =>
        excluirLote()
          .then(() => {
            alert('success', `Lote de imunobiológico ${loteImunoItem.lote} excluído com sucesso.`)
            reload()
          })
          .catch(handleRejection),
    })
  }

  const handleAtivarClick = () => {
    confirm({
      confirmLabel: 'Ativar',
      type: 'primary',
      title: `Deseja ativar o lote de imunobiológico ${loteImunoItem.lote}?`,
      onConfirm: () =>
        ativarInativar({ variables: { idLote: loteImunoItem.id, ativado: true } }).then(() => {
          alert('success', `Lote de imunobiológico ${loteImunoItem.lote} ativado com sucesso`)
          reload()
        }),
    })()
  }

  const handleInativarClick = () => {
    confirm({
      confirmLabel: 'Inativar',
      type: 'primary',
      title: `Deseja inativar o lote de imunobiológico ${loteImunoItem.lote}?`,
      onConfirm: () =>
        ativarInativar({ variables: { idLote: loteImunoItem.id, ativado: false } }).then(() => {
          alert('success', `Lote de imunobiológico ${loteImunoItem.lote} inativado com sucesso`)
          reload()
        }),
    })()
  }

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton>
        {loteImunoItem.ativo ? (
          <DropdownItem onClick={handleInativarClick}>Inativar</DropdownItem>
        ) : (
          <DropdownItem onClick={handleAtivarClick}>Ativar</DropdownItem>
        )}
        <DropdownItem type='danger' onClick={handleExcluirClick(excluir)}>
          Excluir
        </DropdownItem>
      </DropdownButton>
    </Tooltip>
  )
}
