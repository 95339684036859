import { Cell, Grid } from 'bold-ui'
import { CheckboxField, Form, TextField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { FormState } from 'final-form'
import { CbosMunicipioQueryInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

export interface CboTableFilterProps {
  initialValues?: CbosMunicipioQueryInput
  onChange(values: CbosMunicipioQueryInput): void
}

export class CboTableFilter extends React.Component<CboTableFilterProps> {
  render() {
    return (
      <Form<CbosMunicipioQueryInput>
        onSubmit={this.props.onChange}
        render={this.renderForm}
        initialValues={this.props.initialValues}
      />
    )
  }

  private handleChange = (formState: FormState<CbosMunicipioQueryInput>) => {
    this.props.onChange(formState.values)
  }

  private renderForm = (props: FormRenderProps<CbosMunicipioQueryInput>) => {
    return (
      <>
        <FormDebouncedValueSpy loadOnMount={false} onChange={this.handleChange} />

        <Grid alignItems='center' gap={1}>
          <Cell size={4} data-testid={CboTableFilter.name + '.campoPesquisa'}>
            <TextField name='query' placeholder='Pesquise pelo nome ou código' icon='zoomOutline' />
          </Cell>
          <Cell data-testid={CboTableFilter.name + '.mostrarIndisponivelLotacao'}>
            <CheckboxField name='mostrarIndisponivelLotacao' label='Mostrar CBOs indisponíveis para lotação' />
          </Cell>
        </Grid>
      </>
    )
  }
}
