import { colors, Tag } from 'bold-ui'
import { AsyncSelectQueryFieldProps } from 'components/form/field'
import { SelectField } from 'components/form/final-form'
import { CiapCid10SelectFieldDocument } from 'graphql/hooks.generated'
import { CiapCid10SelectFieldQuery, CiapCid10SelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface CiapCidSelectModel {
  __typename?: 'Cid10' | 'Ciap'
  id: ID
  codigo: string
  nome: string
}

export interface CiapCidSelectFieldProps
  extends AsyncSelectQueryFieldProps<
    CiapCidSelectModel,
    CiapCid10SelectFieldQuery,
    CiapCid10SelectFieldQueryVariables
  > {}

export function CiapCidSelectField(props: CiapCidSelectFieldProps) {
  const extractItems = (data: CiapCid10SelectFieldQuery) => {
    return data && orderItemsCombo(data.ciaps.content, data.cids.content)
  }

  const itemToString = (item: CiapCidSelectModel) =>
    item && `${'Ciap' === item.__typename ? 'CIAP2' : 'CID10'} - ${item.nome} - ${item.codigo}`

  const orderItemsCombo = (
    ciaps: CiapCid10SelectFieldQuery['ciaps']['content'],
    cids: CiapCid10SelectFieldQuery['cids']['content']
  ) => {
    const items: CiapCidSelectModel[] = [...ciaps, ...cids]

    items.sort((a, b) => {
      return a.nome.localeCompare(b.nome)
    })

    return items
  }

  const renderItem = (item: CiapCidSelectModel) => {
    return (
      <>
        <Tag
          style={
            'Ciap' === item.__typename
              ? { backgroundColor: colors.turquoise.c80 }
              : { backgroundColor: colors.pink.c80 }
          }
        >
          {'Ciap' === item.__typename ? 'CIAP2' : 'CID10'}
        </Tag>
        <span style={{ marginLeft: '8px', fontWeight: 'bold' }}>
          {item.nome} - {item.codigo}
        </span>
      </>
    )
  }

  const variables = (inputQuery: string): CiapCid10SelectFieldQueryVariables => ({
    inputCiap: {
      query: inputQuery,
      pageParams: {
        size: 25,
      },
    },
    inputCid10: {
      query: inputQuery,
      pageParams: {
        size: 25,
      },
    },
  })

  const { ...asyncProps } = useAsyncQuerySelect<
    CiapCidSelectModel,
    CiapCid10SelectFieldQuery,
    CiapCid10SelectFieldQueryVariables
  >({
    query: CiapCid10SelectFieldDocument,
    extractItems,
    variables,
  })

  return (
    <SelectField<CiapCidSelectModel> itemToString={itemToString} renderItem={renderItem} {...asyncProps} {...props} />
  )
}
