import {
  SegurancaQuery_seguranca_loginNumeroTentativas,
  SegurancaQuery_seguranca_periodoEmMesesParaRedefinir,
  SegurancaQuery_seguranca_periodoEmMinutosInatividade,
} from 'api/schema'
import { BoxGroup } from 'components/BoxGroup'
import React from 'react'

import { ConfiguracoesSegurancaBox } from './ConfiguracoesSegurancaBox'

interface SegurancaBoxProps {
  periodoEmMesesParaRedefinir: SegurancaQuery_seguranca_periodoEmMesesParaRedefinir
  periodoEmMinutosInatividade: SegurancaQuery_seguranca_periodoEmMinutosInatividade
  loginNumeroTentativas: SegurancaQuery_seguranca_loginNumeroTentativas
  load(): void
}

export function SegurancaBox(props: SegurancaBoxProps) {
  return (
    <BoxGroup>
      <ConfiguracoesSegurancaBox
        loginNumeroTentativas={props.loginNumeroTentativas.tentativasLogin}
        periodoEmMesesParaRedefinir={props.periodoEmMesesParaRedefinir.redefinicaoSenha}
        periodoEmMinutosInatividade={props.periodoEmMinutosInatividade.periodoInatividade}
        refetch={props.load}
      />
    </BoxGroup>
  )
}
