import gql from 'graphql-tag'

export const CONEXAO_QUERY = gql`
  query ConexaoQuery {
    conexao {
      internet {
        habilitado
        dataUltimoTeste
        resultadoUltimoTeste
      }
      cadsus {
        id
        habilitado
        desabilitadoAteData
        intervalo
      }
      horus {
        id
        habilitado
        desabilitadoAteData
        intervalo
      }
      atestadoDigital {
        id
        habilitado
        desabilitadoAteData
        intervalo
      }
      agendaOnline {
        ativa
        detalhesAtivacao {
          profissional {
            nome
          }
          dataEvento
        }
        testeConexao {
          sucesso
          dataEvento
        }
      }
    }
  }
`
