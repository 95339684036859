const excecoes: string[] = [
  'de',
  'di',
  'do',
  'da',
  'dos',
  'das',
  'dello',
  'della',
  'dalla',
  'dal',
  'del',
  'e',
  'em',
  'na',
  'no',
  'nas',
  'nos',
  'van',
  'von',
  'às',
  'as',
]

export const captalizeNames = (name: string) => {
  if (name === null) {
    return null
  }

  let formattedName = ''
  const words: string[] = name.toLowerCase().split(/\s+/)

  words.forEach(i => {
    if (excecoes.some(j => j === i)) {
      formattedName = formattedName.concat(`${i} `)
    } else {
      const format = `${i.charAt(0).toUpperCase()}${i.substring(1)}`
      formattedName = formattedName.concat(`${format} `)
    }
  })
  return formattedName.trim()
}
