import { HFlow, Icon, VFlow } from 'bold-ui'
import { Form, NumberField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { PopperButton, PopperControls } from 'components/popper'
import { LotesEnvioFiltro } from 'graphql/types.generated'
import * as React from 'react'
import { FormRenderProps } from 'react-final-form'
import { TransmissaoEnvioPopper } from 'view/transmissao/components/envio/table/TransmissaoEnvioPopper'

import { TransmissaoEnvioButton } from './TransmissaoEnvioButton'
import { TransmissaoEnvioTableFilterAux } from './TransmissaoEnvioTableFilterAux'

export interface TransmissaoEnvioTableFilterProps {
  filter: LotesEnvioFiltro

  onChange(values: LotesEnvioFiltro): void
}

export const TransmissaoEnvioTableFilter = ({ onChange, filter }: TransmissaoEnvioTableFilterProps) => {
  const renderPopper = ({ close }: PopperControls) => (
    <TransmissaoEnvioPopper filter={filter} onChange={onChange} onClose={close} />
  )
  const renderInput = ({ values: { idLote } }: FormRenderProps<FormDto>) => {
    const handleApplyChange = () => onChange({ ...filter, idLote })
    return (
      <>
        <FormDebouncedValueSpy onChange={handleApplyChange} />
        <NumberField
          name='idLote'
          placeholder='Pesquise por lote'
          icon='zoomOutline'
          style={{ width: `${288 / 16}rem` }}
        />
      </>
    )
  }
  const showFilterTags = filter.statusEnvio.length > 0 || (filter.dataCriacaoFim && filter.dataCriacaoInicio)
  return (
    <VFlow vSpacing={0.5}>
      <HFlow justifyContent='space-between'>
        <Form<FormDto> render={renderInput} />
        <HFlow>
          <TransmissaoEnvioButton />
          <PopperButton kind='primary' size='small' placement='bottom-end' renderPopper={renderPopper}>
            <Icon icon='filterOutline' />
            Filtros
          </PopperButton>
        </HFlow>
      </HFlow>
      {showFilterTags && <hr style={{ margin: '0 -1rem' }} />}
      {showFilterTags && <TransmissaoEnvioTableFilterAux filter={filter} onChange={onChange} />}
    </VFlow>
  )
}

interface FormDto {
  idLote: string
}
