import { ApolloQueryResult } from 'apollo-client'
import { DocumentNode } from 'graphql'
import { useApolloClient } from 'graphql/hooks'
import debounce from 'lodash/debounce'
import { useCallback, useMemo, useState } from 'react'

export const DEFAULT_SELECT_SIZE = 50

interface UseAsyncQuerySelectProps<OptionType, TData, TVariables> {
  query: DocumentNode
  variables(inputQuery: string): TVariables
  extractItems(data: TData): OptionType[]
}

export function useAsyncQuerySelect<TOption, TData, TVariables>(
  props: UseAsyncQuerySelectProps<TOption, TData, TVariables>
) {
  const { query, variables, extractItems } = props

  const apollo = useApolloClient()
  const [data, setData] = useState<TData>(null)
  const [isLoading, setIsLoading] = useState(false)
  const items = useMemo(() => extractItems(data), [data, extractItems])

  const refetch = useCallback(
    (queryVariables: TVariables) => {
      setIsLoading(true)
      return apollo
        .query<TData, TVariables>({
          query,
          variables: queryVariables,
          fetchPolicy: 'network-only',
        })
        .then((res: ApolloQueryResult<TData>) => {
          setIsLoading(false)
          setData(res.data)
          return res
        })
        .catch(err => {
          setIsLoading(false)
          throw err
        })
    },
    [apollo, query]
  )

  const debouncedRefetch = useMemo(() => debounce(refetch, 350), [refetch])

  const handleFilterChange = useCallback(
    (inputString: string) => {
      setIsLoading(true)

      if (inputString) {
        return debouncedRefetch(variables(inputString))
      } else {
        // Carrega imediatamente (sem debounce) caso filtro seja vazio
        return refetch(variables(inputString))
      }
    },
    [debouncedRefetch, refetch, variables]
  )

  return {
    items,
    onFilterChange: handleFilterChange,
    loading: isLoading,
  }
}
