import { DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { useAtivarTipoServicoMutation, useDesativarTipoServicoMutation } from 'graphql/hooks.generated'
import React from 'react'
import { PermissionToEditHorus } from 'view/horus/HorusTable'

import { UnidadeSaudeTipoServicoItem } from './TipoServicoTable'

interface TipoServicoTableDropdownProps {
  unidadeSaudeId: ID
  row: UnidadeSaudeTipoServicoItem
  permissionToEditTipoServico?: PermissionToEditHorus
  onUpdated(): any
}

export default function TipoServicoTableDropdown(props: TipoServicoTableDropdownProps) {
  const { onUpdated, row, unidadeSaudeId, permissionToEditTipoServico } = props
  const alert = useAlert()

  const [ativar] = useAtivarTipoServicoMutation({
    variables: {
      unidadeSaudeTipoServicoFormInput: {
        unidadeSaudeId,
        tipoServicoId: row.tipoServico.id,
      },
    },
  })

  const [inativar] = useDesativarTipoServicoMutation({
    variables: {
      unidadeSaudeTipoServicoFormInput: {
        unidadeSaudeId,
        tipoServicoId: row.tipoServico.id,
      },
    },
  })

  const handleAtivarClick = () => {
    return ativar({
      variables: {
        unidadeSaudeTipoServicoFormInput: {
          unidadeSaudeId,
          tipoServicoId: row.tipoServico.id,
        },
      },
    }).then(() => {
      alert('success', `Tipo de serviço ${row.tipoServico.nome} ativado com sucesso`)
      onUpdated()
    })
  }

  const handleInativarClick = () => {
    return inativar({
      variables: {
        unidadeSaudeTipoServicoFormInput: {
          unidadeSaudeId,
          tipoServicoId: row.tipoServico.id,
        },
      },
    }).then(() => {
      alert('success', `Tipo de serviço ${row.tipoServico.nome} inativado com sucesso`)
      onUpdated()
    })
  }

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton data-testid='DropdownButton.dots'>
        <Tooltip text={permissionToEditTipoServico.hint}>
          {row.ativo ? (
            <DropdownItem onClick={handleInativarClick} disabled={!permissionToEditTipoServico.enabled}>
              Inativar
            </DropdownItem>
          ) : (
            <DropdownItem onClick={handleAtivarClick} disabled={!permissionToEditTipoServico.enabled}>
              Ativar
            </DropdownItem>
          )}
        </Tooltip>
      </DropdownButton>
    </Tooltip>
  )
}
