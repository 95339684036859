import gql from 'graphql-tag'

export default gql`
  subscription onImportacaoCnesUpdated($municipio: Int!) {
    importacaoCnesUpdated(municipio: $municipio) {
      id
      dataImportacao
      equipesNovas
      equipesAtualizadas
      lotacoesNovas
      lotacoesAtualizadas
      processo {
        id
        status
      }
      profissional {
        id
        nome
      }
      profissionaisNovos
      profissionaisAtualizados
      unidadesSaudeNovas
      unidadesSaudeAtualizadas
    }
  }
`
