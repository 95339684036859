import { ButtonProps, Heading, Icon, IconProps, useStyles, VFlow } from 'bold-ui'
import React from 'react'

export interface ConfirmModalBodyProps {
  title: React.ReactNode
  type: ButtonProps['kind']
  icon?: IconProps['icon']
  children?: React.ReactNode
}

export function ConfirmModalBody(props: ConfirmModalBodyProps) {
  const { title, type, icon, children } = props

  const { classes } = useStyles(createStyles)

  return (
    <VFlow>
      <Heading level={3}>
        <div className={classes.titleWrapper}>
          <Icon icon={icon} fill={type} size={3} style={{ marginRight: '1rem' }} />
          {title}
        </div>
      </Heading>

      {children}
    </VFlow>
  )
}

ConfirmModalBody.defaultProps = {
  icon: 'questionMarkOutline',
} as ConfirmModalBodyProps

const createStyles = () => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
})
