import { ApolloQueryResult } from 'apollo-client'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { SubmitButton } from 'components/form/final-form/SubmitButton'
import { TextField } from 'components/form/final-form/TextField'
import { FormPrompt } from 'components/form/FormPrompt'
import { analytics } from 'config/firebase'
import { GrupoExameTableQuery } from 'graphql/types.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength, required } from 'util/validation'

import { GrupoExameItem, Procedimento } from './CreateGrupoExame'
import { GrupoExameSelectTable } from './GrupoExameSelectTable'
import { useSalvarGrupoExame } from './SalvarGrupoExameMutation'

const validate = createValidator<GrupoExameItem>({
  nome: [required, maxLength(30)],
  procedimentos: [required],
})

export interface GrupoExameFormModel {
  id: ID
  nome: string
  procedimentos: Procedimento[]
}

interface GrupoExamesModalFormProps {
  initialValues: GrupoExameItem
  refetch(): Promise<ApolloQueryResult<GrupoExameTableQuery>>
}

const path = metaPath<GrupoExameItem>()

export const GrupoExamesModalForm = (props: GrupoExamesModalFormProps) => {
  const { refetch } = props
  const isEdit = props.initialValues.id !== null
  const history = useHistory()
  const alert = useAlert()
  const [salvar] = useSalvarGrupoExame()

  const goBack = () => {
    history.push('/configuracoes/gestaomunicipal/gpexames')
  }

  const handleSubmit = values => {
    return salvar({
      variables: {
        input: {
          id: values.id,
          nome: values.nome,
          procedimentos: values.procedimentos.map(value => value.id),
        },
      },
    }).then(() => {
      const mensagem = isEdit ? 'editado' : 'adicionado'
      if (!isEdit) analytics.logEvent('add_grupo_exames')
      alert('success', `Grupo de exames ${mensagem} com sucesso.`)
      return refetch()
    })
  }

  const renderForm = (formProps: FormRenderProps<GrupoExameItem>) => {
    const title = isEdit ? 'Editar grupo de exames' : 'Adicionar grupo de exames'
    const buttonLabel = isEdit ? 'Salvar' : 'Adicionar'

    return (
      <Modal open={true} onClose={goBack} size='auto' closeOnBackdropClick={false} style={{ width: '832px' }}>
        <ModalBody>
          <FormPrompt />
          <Grid alignItems='flex-start'>
            <Cell size={12}>
              <Heading level={1}>{title}</Heading>
            </Cell>
            <Cell size={6}>
              <TextField name={path.nome} label='Nome do grupo' maxLength={30} required />
            </Cell>
            <Cell size={12}>
              <GrupoExameSelectTable name={path.procedimentos} label='Exames' maxItems={16} required />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack}>Cancelar</Button>
            <SubmitButton kind='primary' handleSubmit={formProps.handleSubmit}>
              {buttonLabel}
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<GrupoExameItem>
      initialValues={props.initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
      validate={validate}
      onSubmitSucceeded={goBack}
      onSubmitFailed={null}
    />
  )
}
