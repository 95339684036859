import { QueryResult } from '@apollo/react-common'
import { HFlow, Icon, PagedTable, Text, Tooltip } from 'bold-ui'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import theme from 'config/theme'
import * as Types from 'graphql/types.generated'
import { TransmissaoRecebimentoCnesFiltro, TransmissaoRecebimentoCnesPayload } from 'graphql/types.generated'
import React, { Dispatch, SetStateAction } from 'react'

import { TransmissaoRecebimentoCnesFilter } from './TransmissaoRecebimentoCnesFilter'

export type TransmissaoRecebimentoCnesDtoItem = TransmissaoRecebimentoCnesPayload['content'][0]

interface TransmissaoRecebimentoCnesTableProps {
  queryResult: QueryResult<Types.TransmissaoRecebimentoCnesQuery, Types.TransmissaoRecebimentoCnesQueryVariables>
  filter: TransmissaoRecebimentoCnesFiltro
  setFilter: Dispatch<SetStateAction<TransmissaoRecebimentoCnesFiltro>>
}

export function TransmissaoRecebimentoCnesTable(props: TransmissaoRecebimentoCnesTableProps) {
  const {
    filter,
    setFilter,
    queryResult: {
      data: { transmissaoRecebimentoCnes },
      refetch,
      loading,
    },
  } = props
  const renderCnes = (row: TransmissaoRecebimentoCnesDtoItem) => <Text>{row.numCnes}</Text>

  const renderMesRecebimento = (row: TransmissaoRecebimentoCnesDtoItem) => (
    <Text>
      {row.mesRecebimento < 10 ? '0' + row.mesRecebimento : row.mesRecebimento}/{row.anoRecebimento}
    </Text>
  )
  const renderFichasProcessadas = (row: TransmissaoRecebimentoCnesDtoItem) => <Text>{row.fichasProcessadas}</Text>

  const renderbtn = ({ numCnes, mesRecebimento, anoRecebimento }: TransmissaoRecebimentoCnesDtoItem) => {
    return (
      <ButtonLink
        size='small'
        to={`/transmissao/detail/recebimento/${numCnes}/${anoRecebimento}/${mesRecebimento}`}
        skin='ghost'
      >
        <Icon icon='zoomOutline' />
      </ButtonLink>
    )
  }
  const renderEstabelecimentoOrigem = (row: TransmissaoRecebimentoCnesDtoItem) => {
    if (!row.estabelecimentoOrigem) {
      return <Text>Desconhecido</Text>
    }
    return <Text>{row.estabelecimentoOrigem}</Text>
  }

  const renderCountFichasHeader = () => (
    <HFlow hSpacing={0.25} alignItems='center'>
      Fichas processadas
      <Tooltip style={{ fontSize: '0.825rem' }} text='Somente fichas que já foram processadas no CDS.'>
        <Icon
          icon='infoCircleFilled'
          size={1}
          style={{
            marginTop: '5px',
            '&:hover': {
              color: theme.pallete.primary.main,
            },
          }}
        />
      </Tooltip>
    </HFlow>
  )

  const tableProps = usePagedTableProps({
    result: transmissaoRecebimentoCnes,
    loading,
    onChange: setFilter,
  })

  return (
    <TableBox
      header={<TransmissaoRecebimentoCnesFilter initialValues={filter} refetch={refetch} onChange={setFilter} />}
    >
      {transmissaoRecebimentoCnes && (
        <PagedTable<TransmissaoRecebimentoCnesDtoItem>
          {...tableProps}
          columns={[
            { name: 'numCnes', header: 'CNES', render: renderCnes, sortable: true },
            {
              name: 'estabelecimentoOrigem',
              header: 'Estabelecimento de origem',
              render: renderEstabelecimentoOrigem,
              sortable: true,
            },
            { name: 'competencia', header: 'Competência', render: renderMesRecebimento },
            {
              name: 'fichasProcessadas',
              header: renderCountFichasHeader(),
              render: renderFichasProcessadas,
              sortable: true,
            },
            { name: 'btn', render: renderbtn },
          ]}
        />
      )}
    </TableBox>
  )
}
