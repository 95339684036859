import { ApolloQueryResult } from 'apollo-client'
import { Button, Icon, Tooltip } from 'bold-ui'
import { alert } from 'components/alert'
import { confirm } from 'components/confirm'
import { GrupoExameTableQuery } from 'graphql/types.generated'
import React, { SyntheticEvent } from 'react'

import { useExcluirGrupoExame } from '../detail/ExcluirGrupoExameMutation'
import { GrupoExameRowItem } from './GrupoExameTable'

interface GrupoExameExcludeActionProps {
  grupoExame: GrupoExameRowItem
  refetch(): Promise<ApolloQueryResult<GrupoExameTableQuery>>
}

export function GrupoExameExcludeAction(props: GrupoExameExcludeActionProps) {
  const { id } = props.grupoExame
  const { refetch } = props
  const [excluirGrupoExame] = useExcluirGrupoExame()

  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    confirm({
      title: 'Deseja excluir o grupo de exames?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () =>
        excluirGrupoExame({ variables: { id: id.toString() } }).then(() => {
          alert('success', 'Grupo de exames excluído com sucesso.')
          refetch()
        }),
    })()
  }

  return (
    <Tooltip text='Excluir'>
      <Button size='small' skin='ghost' onClick={onClick}>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )
}
