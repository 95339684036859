import { TestarConexaoInternetMutation } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const useTestarConexaoInternetMutation = () => {
  return useMutation<TestarConexaoInternetMutation>(TESTAR_CONEXAO_INTERNET_MUTATION)
}

const TESTAR_CONEXAO_INTERNET_MUTATION = gql`
  mutation TestarConexaoInternetMutation {
    testarConexaoInternet {
      habilitado
      dataUltimoTeste
      resultadoUltimoTeste
    }
  }
`
