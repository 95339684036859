import { AgendaFechamentoInput } from 'api/schema'
import { Cell, FormControl, Grid } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateField, Form, SubmitButton, TextField } from 'components/form'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import moment from 'moment'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, required } from 'util/validation'

import { afterToday } from '../../../../util/validation/rules/afterToday'
import { consistentPeriod } from '../../../../util/validation/rules/consistentPeriod'
import { useSalvarFechamentoMutation } from './FechamentoSalvarMutation'
import { MotivoFechamentoSelectField } from './MotivoFechamentoSelectField'

const validate = createValidator<FechamentoFormView>(
  {
    dataInicial: [required, afterToday],
    dataFinal: [required, afterToday],
    motivo: [required],
  },
  (values, errors) => {
    if (!(errors.dataInicial || errors.dataFinal)) {
      const errorDatePeriodRule = consistentPeriod(values.dataInicial, values.dataFinal)
      if (errorDatePeriodRule) {
        errors.dataInicial = errorDatePeriodRule
      }
    }

    if (values && values.motivo && values.motivo === 'Outro') {
      errors.especifique = required(values.especifique)
    }
    return errors
  }
)

interface FechamentoFormView {
  dataInicial: LocalDate
  dataFinal: LocalDate
  motivo: string
  especifique: string
}

interface FechamentoFormProps {
  idLotacao: string
  onUpdated(): void
}

export default function FechamentoForm(props: FechamentoFormProps) {
  const [mutationSalvarFechamento] = useSalvarFechamentoMutation()
  const alert = useAlert()
  const handleSubmit = (result: FechamentoFormView, form: FormApi) => {
    const fechamento: AgendaFechamentoInput = {
      dataInicial: result.dataInicial,
      dataFinal: result.dataFinal,
      idLotacao: props.idLotacao,
      motivo: result.motivo,
      especifique: result.especifique,
    }

    return mutationSalvarFechamento({ variables: { input: fechamento } }).then(ret => {
      alert('success', 'Fechamento foi salvo com sucesso')
      setTimeout(form.reset)
      props.onUpdated()
    })
  }

  const motivoDecorator = createDecorator({
    field: 'motivo',
    updates: {
      especifique: (motivoValue: string, allValues: FechamentoFormView) => {
        return motivoValue !== 'Outro' ? undefined : allValues.especifique
      },
    },
  })

  const renderForm = (formProps: FormRenderProps<FechamentoFormView>) => {
    const outroSelected = formProps.values.motivo && formProps.values.motivo === 'Outro'
    const dataAtual = moment().toDate()

    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <Grid wrap gap={1}>
          <Cell size={2}>
            <DateField name='dataInicial' label='Data inicial' required minDate={dataAtual} />
          </Cell>

          <Cell size={2}>
            <DateField name='dataFinal' label='Data final' required minDate={dataAtual} />
          </Cell>

          <Cell size={3}>
            <MotivoFechamentoSelectField name='motivo' label='Motivo' required />
          </Cell>

          <Cell size={3}>
            <TextField
              name='especifique'
              label='Especifique'
              required={outroSelected}
              disabled={!outroSelected}
              maxLength={300}
            />
          </Cell>

          <Cell size={2}>
            <FormControl label={<span>&nbsp;</span>}>
              <SubmitButton handleSubmit={formProps.handleSubmit} size='small'>
                Adicionar
              </SubmitButton>
            </FormControl>
          </Cell>
        </Grid>
      </form>
    )
  }

  return (
    <Form<FechamentoFormView>
      onSubmit={handleSubmit}
      validate={validate}
      render={renderForm}
      decorators={[motivoDecorator]}
    />
  )
}
