import { QueryLazyOptions } from '@apollo/react-hooks'
import { Button, Cell, Grid, HFlow, Icon, VFlow } from 'bold-ui'
import { DateField, Form, FormRenderProps, MunicipioSelectField, SubmitButton } from 'components/form'
import { TransformCaseTextField } from 'components/form/final-form/TextField/TransformCaseTextField'
import { CidadaoListingQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, maxLength } from 'util/validation'

import CidadaoPesquisarFormModel from './CidadaoPesquisarFormModel'
const path = metaPath<CidadaoPesquisarFormModel>()

export const validate = createValidator<CidadaoPesquisarFormModel>(
  {
    nomeCpfCns: maxLength[100],
    nomeMae: maxLength[100],
  },
  values => {
    const errors: ErrorObject<CidadaoPesquisarFormModel> = {}

    if (values && values.nomeCpfCns && Number(values.nomeCpfCns)) {
      if (values.nomeCpfCns.length !== 11 && values.nomeCpfCns.length !== 15) {
        return { nomeCpfCns: 'Informe um CPF ou CNS válido para realizar a busca' }
      }
    }

    return errors
  }
)

interface CidadaoPesquisarProps {
  executeQuery(variables: QueryLazyOptions<CidadaoListingQueryVariables>): void
  handleSubmitPesquisa(form: CidadaoPesquisarFormModel): void
}

export function CidadaoPesquisarForm(props: CidadaoPesquisarProps) {
  const renderForm = (formProps: FormRenderProps<CidadaoPesquisarFormModel>) => {
    const clearFields = () => {
      formProps.form.change(path.nomeCpfCns.absolutePath(), null)
      formProps.form.change(path.dataNascimento.absolutePath(), null)
      formProps.form.change(path.nomeMae.absolutePath(), null)
      formProps.form.change(path.municipio.absolutePath(), null)
    }

    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <VFlow>
          <Grid>
            <Cell xs={12}>
              <TransformCaseTextField
                case='upper'
                name={path.nomeCpfCns}
                label='Nome/CNS/CPF do cidadão'
                maxLength={200}
                style={{ fontSize: '1.5rem' }}
              />
            </Cell>
            <Cell sm={2} xs={12}>
              <DateField name={path.dataNascimento} label='Data de nascimento' />
            </Cell>
            <Cell sm={5} xs={12}>
              <TransformCaseTextField case='upper' name={path.nomeMae} label='Nome da mãe' />
            </Cell>
            <Cell sm={5} xs={12}>
              <MunicipioSelectField name={path.municipio} label='Município de nascimento' />
            </Cell>
          </Grid>
          <HFlow justifyContent='flex-end' style={{ paddingBottom: '15px' }}>
            <Button kind='normal' skin='outline' size='medium' onClick={clearFields}>
              Limpar filtros
            </Button>
            <SubmitButton
              kind='primary'
              skin='default'
              size='medium'
              handleSubmit={formProps.handleSubmit}
              data-testid='BuscarCidadaoButton'
            >
              <Icon icon='zoomOutline' style={{ marginRight: '0.5rem' }} />
              Buscar cidadão
            </SubmitButton>
          </HFlow>
        </VFlow>
        <input type='submit' hidden />
      </form>
    )
  }

  return (
    <Form<CidadaoPesquisarFormModel> render={renderForm} validate={validate} onSubmit={props.handleSubmitPesquisa} />
  )
}
