import { Alert, Button, HFlow, Icon, Text, useStyles, VFlow } from 'bold-ui'
import { CIDADAO_NOVO_ENABLED } from 'config/flags'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { useHistory } from 'react-router'

import { CidadaoCadsusListingItem } from './CidadaoCadsusListItem'
import { CidadaoListingItem } from './CidadaoListingItem'
import { CidadaoCadsusItemType } from './CidadaoListView'
import { CidadaoListingFormModel } from './CidadaoPesquisarFormModel'

interface CidadaoListingViewProps {
  result: CidadaoListingFormModel[]
  resultCadsus: CidadaoCadsusItemType[]
  loadingCadsus: boolean
  hasCadsusCalled: boolean
  onBuscarClicked(): void
  refetchLocal(): void
  hasMoreResults: boolean
  hasInternetConnection: boolean
  hasCadsusConnection: boolean
}

export function CidadaoListItemView(props: CidadaoListingViewProps) {
  const { resultCadsus, result, hasMoreResults } = props
  const { match } = useRouter()
  const history = useHistory()
  const totalElements = result?.length
  const totalElementsCadsus = props.resultCadsus?.length
  const isOnline = props.hasInternetConnection && props.hasCadsusConnection
  const hasCadsusConnection = props.hasCadsusConnection
  const hasInternetConnection = props.hasInternetConnection
  const { theme } = useStyles()

  const onClickAdicionarCidadao = () => {
    history.push(`${match.url}/create`)
  }

  return (
    <>
      {result != null ? (
        <VFlow vSpacing={0.5}>
          <HFlow alignItems='baseline'>
            <Text fontWeight='bold' fontSize={1.5}>
              Cadastros na base local
            </Text>
            {totalElements > 0 ? (
              <Text fontSize={1}>
                {totalElements === 1 ? totalElements + ' cidadão encontrado' : totalElements + ' cidadãos encontrados'}
              </Text>
            ) : null}
          </HFlow>
          {totalElements > 0 &&
            result.map(result => (
              <CidadaoListingItem data={result} key={result.id} refetchLocal={props.refetchLocal} />
            ))}
          {!totalElements && !hasMoreResults && <Text>Nenhum resultado encontrado.</Text>}
          {hasMoreResults && <Text>Refine a busca incluindo mais informações.</Text>}

          <HFlow alignItems='baseline' style={{ marginTop: '1rem' }}>
            <Text fontWeight='bold' fontSize={1.5}>
              Cadastros na base nacional
            </Text>

            {totalElementsCadsus > 0 ? (
              <Text fontSize={1}>
                {totalElementsCadsus === 1
                  ? totalElementsCadsus + ' cidadão encontrado'
                  : totalElementsCadsus + ' cidadãos encontrados'}
              </Text>
            ) : null}
          </HFlow>

          {!hasInternetConnection ? (
            <Alert type='warning'>
              Não foi possível buscar na base nacional, pois a instalação está offline. Entre em contato com o
              responsável pela instalação para mais informações.
            </Alert>
          ) : (
            !hasCadsusConnection && (
              <Alert type='warning'>
                Não foi possível buscar na base nacional, pois a conexão com o serviço está desabilitada. Entre em
                contato com o responsável pela instalação para mais informações.
              </Alert>
            )
          )}

          {isOnline && props.result && !props.resultCadsus && (
            <Button style={{ height: 72 }} block loading={props.loadingCadsus} onClick={props.onBuscarClicked}>
              <Icon icon='zoomOutline' style={{ marginRight: '0.5rem' }} />
              Pesquisar na base nacional
            </Button>
          )}
          {props.resultCadsus?.map(result => (
            <CidadaoCadsusListingItem data={result} key={result.cns} />
          ))}
          {resultCadsus != null && !resultCadsus?.length && <Text>Nenhum resultado encontrado.</Text>}
          {((isOnline && props.hasCadsusCalled) || !isOnline) && (
            <>
              <div
                style={{ borderTop: `1px solid ${theme.pallete.divider}`, marginTop: '1.6rem', marginBottom: '1rem' }}
              />
              <HFlow justifyContent='flex-end'>
                <Button kind='primary' skin='default' size='medium' onClick={onClickAdicionarCidadao}>
                  Adicionar cidadão
                </Button>
              </HFlow>
            </>
          )}
        </VFlow>
      ) : (
        <VFlow style={{ marginTop: '1rem' }}>
          <Text fontWeight='bold' fontSize={1}>
            Busque um cidadão pelo seu nome, CPF, CNS ou demais informações.
          </Text>
          {CIDADAO_NOVO_ENABLED && (
            <Button kind='primary' skin='default' size='medium' onClick={onClickAdicionarCidadao}>
              Adicionar cidadão
            </Button>
          )}
        </VFlow>
      )}
    </>
  )
}
