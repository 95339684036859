import { Cell, Grid } from 'bold-ui'
import { CheckboxField, Form, TextField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { FormState } from 'final-form'
import { ProfissionaisQueryInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

export interface ProfissionalTableFilterProps {
  initialValues?: ProfissionaisQueryInput
  onChange(values: ProfissionaisQueryInput): void
}

export class ProfissionalTableFilter extends React.Component<ProfissionalTableFilterProps> {
  render() {
    return (
      <Form<ProfissionaisQueryInput>
        onSubmit={this.props.onChange}
        render={this.renderForm}
        initialValues={this.props.initialValues}
      />
    )
  }

  private handleChange = (formState: FormState<ProfissionaisQueryInput>) => {
    this.props.onChange(formState.values)
  }

  private renderForm = (props: FormRenderProps) => {
    return (
      <>
        <FormDebouncedValueSpy onChange={this.handleChange} loadOnMount={false} />

        <Grid alignItems='center' gap={1}>
          <Cell size={4}>
            <TextField name='query' placeholder='Pesquise pelo nome, CNS, CPF ou CBO' icon='zoomOutline' />
          </Cell>
          <Cell>
            <CheckboxField name='mostrarSemLotacaoAtiva' label='Mostrar profissionais sem lotação ativa' />
          </Cell>
        </Grid>
      </>
    )
  }
}
