import { OutroAcessoInput } from 'api/schema'

import { AcessoFormModel } from './AcessoForm'

export default (model: AcessoFormModel): OutroAcessoInput => ({
  ...model,
  perfis: model.perfis && model.perfis.map(p => p.id),
  uf: model.uf && model.uf.id,
  municipio: model.municipio && model.municipio.id,
})
