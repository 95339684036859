import { ExcluirMunicipioResponsavel, ExcluirMunicipioResponsavelVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const EXCLUIR_MUNICIPIO_RESPONSAVEL = gql`
  mutation ExcluirMunicipioResponsavel($id: ID!) {
    excluirMunicipioResponsavel(id: $id)
  }
`

export function useExcluirMunicipioResponsaval() {
  return useMutation<ExcluirMunicipioResponsavel, ExcluirMunicipioResponsavelVariables>(EXCLUIR_MUNICIPIO_RESPONSAVEL)
}
