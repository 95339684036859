import { Cell, Grid } from 'bold-ui'
import { Form, TextField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { FormState } from 'final-form'
import { ImportacoesCnesQueryInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

export interface ImportacaoCnesTableFilterProps {
  initialValues: ImportacoesCnesQueryInput
  onChange(values: ImportacoesCnesQueryInput): void
}

export class ImportacaoCnesTableFilter extends React.Component<ImportacaoCnesTableFilterProps> {
  render() {
    return (
      <Form<ImportacoesCnesQueryInput>
        onSubmit={this.props.onChange}
        initialValues={this.props.initialValues}
        render={this.renderForm}
      />
    )
  }

  private handleChange = (formState: FormState<ImportacoesCnesQueryInput>) => {
    this.props.onChange(formState.values)
  }

  private renderForm = (props: FormRenderProps) => {
    return (
      <>
        <FormDebouncedValueSpy onChange={this.handleChange} />

        <Grid alignItems='center'>
          <Cell size={4}>
            <TextField name='query' placeholder='Pesquise pelo nome do responsável' icon='zoomOutline' />
          </Cell>
        </Grid>
      </>
    )
  }
}
