import { Theme, useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

export interface PageContainerProps {
  children?: React.ReactNode
}

export function PageContainer(props: PageContainerProps) {
  const { children } = props
  const { classes } = useStyles(createStyles)

  return <div className={classes.container}>{children}</div>
}

export const createStyles = (theme: Theme) => ({
  container: {
    margin: '0 auto',
    padding: '0 2rem',
    width: 'calc(1120px + 4rem)',

    [theme.breakpoints.down('lg')]: {
      width: 'calc(880px + 4rem)',
    },
  } as CSSProperties,
})
