import { Theme, useStyles } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { LoadingIndicator } from 'components/loading'
import React, { CSSProperties, useEffect } from 'react'
import { useHistory } from 'react-router'

export function LogoutView() {
  const handleRejection = useErrorHandler()
  const { logout } = useSession()
  const history = useHistory()
  const { classes } = useStyles(createStyles)

  useEffect(() => {
    logout()
      .then(() => history.replace('/'))
      .catch(handleRejection)
  }, [history, logout, handleRejection])

  return (
    <div className={classes.root}>
      <LoadingIndicator message='Efetuando logout...' />
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '90vh',
  } as CSSProperties,
})
