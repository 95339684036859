import { Heading, HFlow, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { Cpf } from 'components/label'
import { useUnificacoesCidadaoQuery } from 'graphql/hooks.generated'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import React from 'react'
import { identidadeGenero, sexo } from 'types/enums'

export interface CidadaoUnificacoesViewProps {
  unificacoes?: UnificacaoViewModel
}

export interface CidadaoUnificacaoProps {
  cidadao: CidadaosUnificacoesModel
}

export interface UnificacaoViewModel {
  id: string
  nomeProfissional?: string
  dataUnificacao?: string
  cidadaos?: [CidadaosUnificacoesModel][0][]
}

export interface CidadaosUnificacoesModel {
  id: string
  cpf?: string
  cns?: string
  nome: string
  nomeSocial?: string
  dataNascimento?: string
  sexo: SexoEnum
  identidadeGeneroDbEnum?: IdentidadeGeneroEnum
  nomeMae?: string
  localidadeNascimento?: {
    id: string
    nome: string
    uf: {
      id: string
      sigla: string
    }
  }
}

export const CidadaoUnificacoesView = (props: { idCidadao; temUnificacoes }) => {
  return (
    <div>
      {props.temUnificacoes && <CidadaoUnificacoesViewComDados idCidadao={props.idCidadao} />}
      {!props.temUnificacoes && <CidadaoUnificacoesViewSemDados />}
    </div>
  )
}

const CidadaoUnificacoesViewSemDados = () => {
  return (
    <div style={{ marginTop: '1rem', textAlign: 'center' }}>
      <Text>O cidadão não possui unificações de cadastro.</Text>
    </div>
  )
}

const CidadaoUnificacoesViewComDados = (props: { idCidadao }) => {
  const { data } = useUnificacoesCidadaoQuery({ variables: { id: props.idCidadao } })
  return (
    data && (
      <VFlow>
        {data.unificacoesCidadao?.map(result => (
          <CidadaoUnificacoesViewItem unificacoes={result} />
        ))}
      </VFlow>
    )
  )
}

const CidadaoUnificacoesViewItem = (props: CidadaoUnificacoesViewProps) => {
  const unificacoes = props.unificacoes
  return (
    <>
      {unificacoes && (
        <VFlow vSpacing={0.5}>
          <HFlow>
            <Heading level={3}>
              <DateTime format='DD/MM/YYYY HH:mm' value={unificacoes.dataUnificacao} />
              {props.unificacoes.nomeProfissional && ' | Por ' + props.unificacoes.nomeProfissional}
            </Heading>
          </HFlow>
          {unificacoes.cidadaos?.map(result => (
            <CidadaoUnificacoesViewItemCard cidadao={result} />
          ))}
        </VFlow>
      )}
    </>
  )
}

const CidadaoUnificacoesViewItemCard = (props: CidadaoUnificacaoProps) => {
  const cidadao = props.cidadao
  return (
    <Box style={{ minHeight: 112, textAlign: 'left' }}>
      <HFlow justifyContent='space-between'>
        <VFlow vSpacing={0}>
          <HFlow hSpacing={0.5} alignItems='baseline'>
            <Heading level={3}>{cidadao.nomeSocial ? cidadao.nomeSocial : cidadao.nome}</Heading>
            {cidadao.nomeSocial && <Text fontWeight='bold'>(Nome social)</Text>}
            {cidadao.nomeSocial && <Text>{'| ' + cidadao.nome}</Text>}
          </HFlow>
          <HFlow hSpacing={6}>
            <VFlow vSpacing={0} style={{ width: 350 }}>
              <HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>CPF</Text>
                  {cidadao.cpf ? <Cpf value={cidadao.cpf} /> : '-'}
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>CNS</Text>
                  {cidadao.cns ? <Text>{cidadao.cns}</Text> : '-'}
                </HFlow>
              </HFlow>
              <HFlow hSpacing={0.5}>
                <Text fontWeight='bold'>Data de nascimento</Text>
                <DateTime format='DD/MM/YYYY' value={cidadao.dataNascimento} />
                (<Age dateOfBirth={cidadao.dataNascimento ? cidadao.dataNascimento : '-'} />)
              </HFlow>
              <HFlow hSpacing={0.5}>
                <Text fontWeight='bold'>Sexo</Text>
                <Text>{sexo[cidadao.sexo]}</Text>
                <Text fontWeight='bold'>{cidadao.identidadeGeneroDbEnum ? 'Id. de gênero' : null}</Text>
                <Text>{cidadao.identidadeGeneroDbEnum && identidadeGenero[cidadao.identidadeGeneroDbEnum]}</Text>
              </HFlow>
            </VFlow>
            <VFlow vSpacing={0}>
              <HFlow hSpacing={0.5}>
                <Text fontWeight='bold'>Nome da mãe</Text>
                <Text>{cidadao.nomeMae ? cidadao.nomeMae : '-'}</Text>
              </HFlow>
              <HFlow hSpacing={0.5}>
                <Text fontWeight='bold'>Município de nascimento</Text>
                <Text>
                  {' '}
                  {cidadao.localidadeNascimento
                    ? cidadao.localidadeNascimento?.nome + ' - ' + cidadao.localidadeNascimento?.uf?.sigla
                    : '-'}
                </Text>
              </HFlow>
            </VFlow>
          </HFlow>
        </VFlow>
      </HFlow>
    </Box>
  )
}
