import { ApolloError } from 'apollo-client'
import { Heading, VFlow } from 'bold-ui'
import { InlineErrorList } from 'components/error'
import { useSobreQuery } from 'graphql/hooks.generated'
import React, { useState } from 'react'

import { SolicitarRecuperarSenhaForm } from './SolicitarRecuperarSenhaForm'
import { useSolicitarRedefinirSenhaMutation } from './useSolicitarRedefinirSenhaMutation'

export const SolicitarRecuperarSenhaView = () => {
  const [solicitarRecuperarSenha] = useSolicitarRedefinirSenhaMutation()
  const [error, setError] = useState<ApolloError>()

  const {
    data: { info },
  } = useSobreQuery()

  if (!info) {
    return null
  }

  const doRedefinirSenha = (form: SolicitarRecuperarSenhaForm) => {
    return solicitarRecuperarSenha({ variables: { input: { ...form } } }).catch(setError)
  }

  return (
    <VFlow>
      {error && <InlineErrorList error={error} />}
      <Heading level={5}>Recuperação de senha</Heading>
      <p>Informe seu usuário para receber um e-mail com instruções para recuperar a sua senha do e-SUS APS PEC.</p>
      <SolicitarRecuperarSenhaForm onRequestRedefinicaoSenha={doRedefinirSenha} />
    </VFlow>
  )
}
