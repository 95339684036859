import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { useGerarLotesMutation } from 'graphql/hooks.generated'
import * as React from 'react'
import { useState } from 'react'

export const TransmissaoEnvioButton = () => {
  const [gerarLote] = useGerarLotesMutation()

  const [state, setState] = useState(false)
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const handleButtonClick = () => setState(true)
  const handleModalClose = () => setState(false)
  const handleModalConfirm = () => {
    return gerarLote()
      .then(() => {
        setState(false)
        alert('info', 'Geração de novo lote foi iniciada')
      })
      .catch(handleRejection)
  }

  return (
    <>
      <Button kind='primary' size='small' onClick={handleButtonClick}>
        <Icon icon='plus' />
        Gerar novo lote
      </Button>

      <Modal size='small' onClose={handleModalClose} open={state}>
        <ModalBody>
          <HFlow alignItems='center'>
            <Icon icon='questionMarkOutline' style={{ marginRight: '0.5rem' }} size={3} fill='info' />
            <Heading level={1}>Deseja gerar novos lotes de fichas para envio?</Heading>
          </HFlow>
          <br />
          <p>
            Após gerar os lotes, as fichas não estarão mais disponíveis para edição e exclusão.
            <br />
            Lotes gerados poderão ser salvos em arquivos e serão enviados automaticamente no próximo envio, caso existam
            nós cadastrados.
          </p>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleModalClose}>Cancelar</Button>
            <Button kind='primary' onClick={handleModalConfirm}>
              Gerar lotes
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
    </>
  )
}
