import { DropdownDivider, DropdownItem, Heading, HFlow, Icon, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { confirm } from 'components/confirm'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { Cpf, Telefone } from 'components/label'
import { ButtonLink } from 'components/route'
import { useExcluirCidadaoMutation } from 'graphql/hooks.generated'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { identidadeGenero, sexo } from 'types/enums'
import Permissions from 'types/Permissions'

export interface CidadaoCabecalhoSectionProps {
  cidadao: CidadaoCabecalhoModel
}

export interface CidadaoCabecalhoModel {
  id: ID
  cpf?: string
  cns?: string
  nomeSocial?: string
  nome: string
  dataNascimento: string
  sexo: SexoEnum
  identidadeGeneroDbEnum?: IdentidadeGeneroEnum
  ativo?: boolean
  unificado?: boolean
  nomeMae?: string
  telefoneResidencial?: string
  telefoneCelular?: string
  possuiAgendamento?: boolean
  prontuario?: any
  faleceu?: boolean
  dataObito?: string
  cidadaoVinculacaoEquipe?: {
    id?: string
    unidadeSaude?: {
      id: string
      nome?: string
    }
  }
  dataAtualizado?: string
}

export const CidadaoCabecalhoSection = (props: CidadaoCabecalhoSectionProps) => {
  return (
    <HFlow alignItems='flex-start' hSpacing={0.5} justifyContent='space-between' style={{ marginTop: '1rem' }}>
      <InformacoesCadastrais cidadao={props.cidadao} />
      <Acoes cidadao={props.cidadao} />
    </HFlow>
  )
}

export const CidadaoNomeSection = (props: CidadaoCabecalhoSectionProps) => {
  const { cidadao } = props

  return cidadao?.nomeSocial ? (
    <Heading data-testid='CidadaoCabecalho.nomeSocial' level={1}>
      {cidadao?.nomeSocial}
      <span style={{ fontSize: '16px' }}> (Nome social) </span>
      <span style={{ fontSize: '16px', fontWeight: 'normal' }} data-testid='CidadaoCabecalho.nome'>
        | {cidadao?.nome}
      </span>
      {cidadao?.faleceu && (
        <Tag style={{ marginLeft: '0.5rem' }} data-testid='CidadaoCabecalho.marcacaoObito'>
          Óbito
        </Tag>
      )}
      {!cidadao?.ativo && (
        <Tag style={{ marginLeft: '0.5rem' }} data-testid='CidadaoCabecalho.marcacaoInativo'>
          Inativo
        </Tag>
      )}
      {cidadao?.unificado && (
        <Tag style={{ marginLeft: '0.5rem' }} data-testid='CidadaoCabecalho.marcacaoCadastroUnificado'>
          Cadastro unificado
        </Tag>
      )}
    </Heading>
  ) : (
    <HFlow alignItems='baseline' hSpacing={0.5}>
      <Heading data-testid='CidadaoCabecalho.nome' level={1}>
        {cidadao?.nome}
      </Heading>
      {cidadao?.faleceu && <Tag data-testid='CidadaoCabecalho.marcacaoObito'>Óbito</Tag>}
      {!cidadao?.ativo && <Tag data-testid='CidadaoCabecalho.marcacaoInativo'>Inativo</Tag>}
      {cidadao?.unificado && <Tag data-testid='CidadaoCabecalho.marcacaoCadastroUnificado'>Cadastro unificado</Tag>}
    </HFlow>
  )
}

const InformacoesCadastrais = (props: CidadaoCabecalhoSectionProps) => {
  const { cidadao } = props
  const telefone = cidadao?.telefoneCelular || cidadao?.telefoneResidencial

  return (
    <VFlow vSpacing={0}>
      <CidadaoNomeSection cidadao={cidadao} />

      <HFlow hSpacing={4}>
        <VFlow vSpacing={0}>
          <HFlow hSpacing={1}>
            <HFlow alignItems='center' hSpacing={0.5} data-testid='CidadaoCabecalho.cpf'>
              <Text fontWeight='bold'>CPF</Text>
              {cidadao?.cpf ? <Cpf value={cidadao?.cpf} /> : <Text>-</Text>}
            </HFlow>
            <HFlow hSpacing={0.5} data-testid='CidadaoCabecalho.cns'>
              <Text fontWeight='bold'>CNS</Text>
              {cidadao?.cns ? <Text>{cidadao?.cns}</Text> : <Text>-</Text>}
            </HFlow>
          </HFlow>
          <HFlow alignItems='center' hSpacing={0.5} data-testid='CidadaoCabecalho.dataNascimento'>
            <Text fontWeight='bold'>Data de nascimento</Text>
            <DateTime format='DD/MM/YYYY' value={cidadao?.dataNascimento} />
            (<Age dateOfBirth={cidadao?.dataNascimento} />)
          </HFlow>
          {cidadao?.identidadeGeneroDbEnum ? (
            <HFlow hSpacing={1}>
              <HFlow hSpacing={0.5}>
                <Text fontWeight='bold'>Sexo</Text>
                <Text data-testid='CidadaoCabecalho.sexo'>{sexo[cidadao?.sexo]}</Text>
              </HFlow>
              <HFlow hSpacing={0.5}>
                <Text fontWeight='bold'>Id. de gênero</Text>
                <Text data-testid='CidadaoCabecalho.identidadeGenero'>
                  {identidadeGenero[cidadao?.identidadeGeneroDbEnum]}
                </Text>
              </HFlow>
            </HFlow>
          ) : (
            <HFlow hSpacing={0.5}>
              <Text fontWeight='bold'>Sexo</Text>
              <Text data-testid='CidadaoCabecalho.sexo'>{sexo[cidadao?.sexo]}</Text>
            </HFlow>
          )}
        </VFlow>

        <VFlow vSpacing={0}>
          <HFlow hSpacing={0.5} data-testid='CidadaoCabecalho.nomeMae'>
            <Text fontWeight='bold'>Nome da mãe</Text>
            {cidadao?.nomeMae ? <Text>{cidadao?.nomeMae}</Text> : <Text>-</Text>}
          </HFlow>
          <HFlow alignItems='center' hSpacing={0.5} data-testid='CidadaoCabecalho.telefone'>
            <Text fontWeight='bold'>Telefone</Text>
            {telefone ? <Telefone value={telefone}></Telefone> : <Text>-</Text>}
          </HFlow>
          <HFlow hSpacing={0.5} data-testid='CidadaoCabecalho.unidadeResponsavel'>
            <Text fontWeight='bold'>Unidade responsável</Text>
            <Text>
              {cidadao?.cidadaoVinculacaoEquipe?.unidadeSaude
                ? cidadao?.cidadaoVinculacaoEquipe.unidadeSaude.nome
                : '-'}
            </Text>
          </HFlow>
        </VFlow>
      </HFlow>
    </VFlow>
  )
}

const Acoes = (props: CidadaoCabecalhoSectionProps) => {
  const { cidadao } = props
  const { hasAuthorization } = useSession()

  const cadastrarEEditar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar)
  const excluir = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.excluir)
  const visualizarProntuario = hasAuthorization(Permissions.visualizarProntuario)
  const visualizarAgenda = hasAuthorization(Permissions.visualizarAgenda)

  const allPermissions = excluir || visualizarProntuario || visualizarAgenda

  const [excluirCidadao] = useExcluirCidadaoMutation()
  const handleRejection = useErrorHandler()
  const alert = useAlert()
  const history = useHistory()

  const handleExcluir = () => {
    confirm({
      title: `Deseja excluir o cadastro do cidadão?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluirCidadao({ variables: { cidadaoId: cidadao?.id } })
          .then(() => {
            alert('success', 'Cadastro do cidadão excluído')
            history.goBack()
          })
          .catch(handleRejection)
      },
    })()
  }

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={0.5} justifyContent='flex-end'>
        {cadastrarEEditar && (
          <ButtonLink
            kind='primary'
            onClick={function noRefCheck() {}}
            size='small'
            skin='default'
            style={{ width: '9.625rem', height: '3rem' }}
            to={`/cidadao/${cidadao?.id}/edit`}
          >
            Atualizar cadastro
          </ButtonLink>
        )}
        {allPermissions && (
          <DropdownButton kind='normal' size='small' style={{ width: '3.4375rem', height: '3rem' }} skin='outline'>
            {visualizarProntuario && !cidadao?.prontuario && (
              <Tooltip text='Cadastro não possui prontuário'>
                <DropdownItem disabled data-testid='CidadaoCabecalho.botaoVerProntuario'>
                  Ver prontuário
                </DropdownItem>
              </Tooltip>
            )}
            {visualizarProntuario && cidadao?.prontuario && (
              <DropdownItem data-testid='CidadaoCabecalho.botaoVerProntuario'>Ver prontuário</DropdownItem>
            )}

            {visualizarAgenda && !cidadao?.possuiAgendamento && (
              <Tooltip text='Não há agendamentos registrados para o cidadão'>
                <DropdownItem disabled data-testid='CidadaoCabecalho.botaoVerAgendamentos'>
                  Ver agendamentos
                </DropdownItem>
              </Tooltip>
            )}
            {visualizarAgenda && cidadao?.possuiAgendamento && (
              <DropdownItem data-testid='CidadaoCabecalho.botaoVerAgendamentos'>Ver agendamentos</DropdownItem>
            )}

            {excluir && (
              <>
                <DropdownDivider />
                <DropdownItem
                  type='danger'
                  style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem', display: 'flex', alignItems: 'center' }}
                  onClick={handleExcluir}
                  data-testid='CidadaoCabecalho.botaoExcluir'
                >
                  <Icon icon='trashOutline' style={{ marginRight: '0.5rem' }} /> Excluir
                </DropdownItem>
              </>
            )}
          </DropdownButton>
        )}
      </HFlow>
      <HFlow hSpacing={0.2} alignItems='center'>
        <Text>Última atualização em</Text>
        <DateTime format='DD/MM/YYYY' value={cidadao?.dataAtualizado} />
      </HFlow>
    </VFlow>
  )
}
