import {
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  Icon,
  InfoLabel,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  Text,
  useTheme,
  VFlow,
} from 'bold-ui'
import { CreateCidadaoCadsusMutation, SincronizacaoCadsusResultEnum } from 'graphql/types.generated'
import React from 'react'
import { useHistory } from 'react-router'

type resultSincCadsusType = CreateCidadaoCadsusMutation['createCidadaoCadsus']

interface ModalStatusSincronizacaoCadsus {
  loading: boolean
  result: resultSincCadsusType
  isOpen: boolean
  isEdit: boolean
  sincronizarCadsus(cidadaoId: string): void
}

interface AguardandoSincronizacaoBodyProps {
  loading: boolean
}

interface SucessoSincronizacaoBodyProps {
  result: resultSincCadsusType
  isEdit: boolean
}

interface ErroValidacaoCadsusBodyProps {
  result: resultSincCadsusType
  isEdit: boolean
}

interface ErroCadsusDesbilitadoBodyProps {
  status: SincronizacaoCadsusResultEnum
}

export function ModalStatusSincronizacaoCadsus(props: ModalStatusSincronizacaoCadsus) {
  const history = useHistory()

  const onConcluir = () => {
    history.push('/cidadao')
  }

  const onTentarNovamente = () => {
    props.sincronizarCadsus(props.result.cidadaoLocal.id)
  }

  const onEditar = () => {
    history.push(`${props.result.cidadaoLocal.id}/edit`)
  }

  return (
    <Modal open={props.isOpen} onClose={onConcluir} size='small'>
      {props.loading && <AguardandoSincronizacaoBody loading={props.loading} />}
      {props.result?.result === SincronizacaoCadsusResultEnum.SUCESSO && (
        <SucessoSincronizacaoBody result={props.result} isEdit={props.isEdit} />
      )}
      {props.result?.result === SincronizacaoCadsusResultEnum.ERRO && <ErroFalhaConexaoCadsus />}

      {(props.result?.result === SincronizacaoCadsusResultEnum.ERRO_CADSUS_DESABILITADO ||
        props.result?.result === SincronizacaoCadsusResultEnum.ERRO_SEM_CONEXAO) && (
        <ErroCadsusDesbilitadoBody status={props.result.result} />
      )}

      {(props.result?.result === SincronizacaoCadsusResultEnum.VALIDACAO_COM_CNS ||
        props.result?.result === SincronizacaoCadsusResultEnum.VALIDACAO_SEM_CNS) && (
        <ErroValidacaoCadsusBody result={props.result} isEdit={props.isEdit} />
      )}
      {props.result?.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_DADOS_ATIVOS && (
        <SucessoParcialSincronizacaoBody />
      )}
      {!props.loading && (
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            {(props.result?.result === SincronizacaoCadsusResultEnum.VALIDACAO_COM_CNS ||
              props.result?.result === SincronizacaoCadsusResultEnum.VALIDACAO_SEM_CNS) && (
              <Button onClick={onEditar}>Editar</Button>
            )}
            {props.result?.result === SincronizacaoCadsusResultEnum.ERRO && (
              <Button onClick={onTentarNovamente}>Tentar novamente</Button>
            )}
            <Button onClick={onConcluir} kind='primary'>
              Concluir
            </Button>
          </HFlow>
        </ModalFooter>
      )}
    </Modal>
  )
}

function AguardandoSincronizacaoBody(props: AguardandoSincronizacaoBodyProps) {
  const theme = useTheme()
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Heading level={2}>
              {props.loading && (
                <Spinner size={2} borderWidth={3} style={{ color: theme.pallete.primary.main, marginRight: 6 }} />
              )}
              Atualizando dados do cidadão
            </Heading>
          </HFlow>
          <Text>Aguarde um instante, estamos atualizando os dados do cidadão na base nacional (CADWEB)</Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function SucessoSincronizacaoBody(props: SucessoSincronizacaoBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='infoCircleOutline' fill='info' size={4} />
            <Heading level={2}>
              {props.isEdit ? 'CNS gerado com sucesso' : 'Cidadão atualizado na base nacional (CADSUS).'}
            </Heading>
          </HFlow>
          <HFlow>
            <InfoLabel title='CPF'>{props.result.cidadaoLocal.cpf}</InfoLabel>
            <InfoLabel title='CNS'>{props.result.cidadaoLocal.cns}</InfoLabel>
          </HFlow>
          {props.result.camposAlterados && (
            <Text fontWeight='bolder'>
              Os seguintes campos foram sobrescritos, por estarem mais atualizados na base nacional:
            </Text>
          )}
          <Grid wrap>
            {props.result?.camposAlterados?.map(item => (
              <Cell size={6}>
                <InfoLabel title={item.campo}>{item.conteudo}</InfoLabel>
              </Cell>
            ))}
          </Grid>
        </VFlow>
      </ModalBody>
    </>
  )
}

function SucessoParcialSincronizacaoBody() {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='infoCircleOutline' fill='info' size={4} />
            <Heading level={2}>CNS atualizado parcialmente</Heading>
          </HFlow>
          <Text>
            O Cidadao já possui um número higienizado na base nacional do CADSUS. Nâo foi possível substituir o CNS pelo
            definitivo pois esse cadastro já possui registros de atendimentos ou já existe outro cadastro na base de
            dados local com o mesmo CNS.
          </Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function ErroCadsusDesbilitadoBody(props: ErroCadsusDesbilitadoBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            <Heading level={2}>Não foi possível enviar o cadastro ao CADSUS</Heading>
          </HFlow>
          <Text>
            O cidadão foi salvo na base local, mas não foi possível salvar o cadastro na base nacional,{' '}
            {props.status === SincronizacaoCadsusResultEnum.ERRO_SEM_CONEXAO
              ? 'pois a instalação está offline.'
              : 'pois a conexão com o serviço está desabilitada.'}
            Entre em contato com o responsável pela instalação para mais informações.
          </Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function ErroFalhaConexaoCadsus() {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            <Heading level={2}>Não foi possível estabelecer a conexão com o CADSUS</Heading>
          </HFlow>
          <Text>Ocorreu um erro na comunicação com a base nacional (CADSUS)</Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function ErroValidacaoCadsusBody(props: ErroValidacaoCadsusBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            <Heading level={2}>
              {props.isEdit ? 'O cidadão foi editado' : 'O cadastro foi salvo'}, mas não foi possível atualizar os dados
              na base nacional (CADSUS)
            </Heading>
          </HFlow>
          <ul>
            {props.result.errors.map(erro => (
              <li>{erro}</li>
            ))}
          </ul>
        </VFlow>
      </ModalBody>
    </>
  )
}
