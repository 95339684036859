import gql from 'graphql-tag'

export const ACESSO_CREATE_QUERY = gql`
  query AcessoCreate($profissionalId: ID!) {
    profissional(id: $profissionalId) {
      id
      nome
    }
  }
`
