import { ConfiguracaoPeriodoInput } from 'api/schema'
import { createValidator, ErrorObject } from 'util/validation'

export const cfgPeriodoValidator = createValidator({}, (value: ConfiguracaoPeriodoInput) => {
  const errors: ErrorObject<ConfiguracaoPeriodoInput> = {}

  if (value) {
    if (value.horarioInicial && !value.horarioFinal) {
      errors.horarioFinal = 'Preenchimento obrigatório'
    }
    if (value.horarioFinal && !value.horarioInicial) {
      errors.horarioInicial = 'Preenchimento obrigatório'
    }
    if (value.horarioInicial && value.horarioFinal && value.horarioInicial.localeCompare(value.horarioFinal) >= 0) {
      errors.horarioFinal = 'Deve ser posterior à Entrada'
    }
  }

  return errors
})
