import { FormState } from 'final-form'
import { debounce, isEqual } from 'lodash'
import React from 'react'
import { FormSpy } from 'react-final-form'

export interface FormDebouncedValueSpyProps {
  loadOnMount?: boolean
  wait?: number
  onChange(formState: FormState<any>): any
}

export interface FormDebouncedValueSpyState {
  values?: any
}

export class FormDebouncedValueSpy extends React.Component<FormDebouncedValueSpyProps, FormDebouncedValueSpyState> {
  static defaultProps: Partial<FormDebouncedValueSpyProps> = {
    wait: 500,
    loadOnMount: true,
  }

  private onChangeDebounced = debounce((formState: FormState<any>) => {
    this.props.onChange(formState)
  }, this.props.wait)

  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  render() {
    return <FormSpy onChange={this.handleChange} subscription={{ values: true, initialValues: true }} />
  }

  private handleChange = (formState: FormState<any>) => {
    if (!this.state.values) {
      this.setState({ values: formState.values })
      if (this.props.loadOnMount) {
        // Primeira vez que entra na tela, chama onChange sem debounce
        this.props.onChange(formState)
      }
    } else if (!isEqual(this.state.values, formState.values)) {
      this.setState({ values: formState.values })
      this.onChangeDebounced(formState)
    }
  }
}
