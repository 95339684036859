import { Alert, Button, Cell, Grid, HeadingSection, TextField as BoldTextField, VFlow } from 'bold-ui'
import { EnderecoFieldGroup, FormFooter, FormRenderProps, PasswordField } from 'components/form'
import React from 'react'
import { ProfissionalDadosFieldGroup } from 'view/profissional/components/ProfissionalDadosFieldGroup'

import { InstalacaoFormModel, meta } from './InstalacaoRootView'

interface CadastroInstaladorViewProps {
  formProps: FormRenderProps<InstalacaoFormModel>
  onVoltar(): void
  onAvancar(): void
}

export const CadastroInstaladorView = (props: CadastroInstaladorViewProps) => {
  const { formProps, onVoltar, onAvancar } = props

  return (
    <VFlow>
      <HeadingSection level={2} title='Dados pessoais'>
        <ProfissionalDadosFieldGroup name={meta.step2} formProps={formProps} />
      </HeadingSection>

      <HeadingSection level={2} title='Endereço residencial'>
        <EnderecoFieldGroup name={meta.step2.endereco} formProps={formProps} />
      </HeadingSection>

      <HeadingSection level={2} title='Dados de autenticação'>
        <VFlow vSpacing={1}>
          <Alert type='info' style={{ maxWidth: '23.5rem' }}>
            <p>Use pelo menos uma letra e um número.</p>
            <p>Mínimo de 8 e máximo de 20 caracteres.</p>
            <p>Use somente letras e números.</p>
            <p>O sistema diferencia letras maiúsculas e minúsculas.</p>
          </Alert>
          <Grid>
            <Cell size={3}>
              <BoldTextField
                name='usuario'
                disabled={true}
                label='Usuário'
                value={formProps.values.step2 ? formProps.values.step2.cpf || '' : ''}
              />
            </Cell>
          </Grid>
          <Grid>
            <Cell xs={12} sm={3}>
              <PasswordField label='Senha' required name={meta.step2.senha} maxLength={20} />
            </Cell>
            <Cell xs={12} sm={3}>
              <PasswordField label='Confirmação de senha' required name={meta.step2.confirmacaoSenha} maxLength={20} />
            </Cell>
          </Grid>
        </VFlow>
      </HeadingSection>

      <FormFooter style={{ marginBottom: '1rem' }}>
        <Button onClick={onVoltar}>Voltar</Button>
        <Button kind='primary' onClick={onAvancar}>
          Avançar
        </Button>
      </FormFooter>
    </VFlow>
  )
}
