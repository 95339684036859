import { ApolloQueryResult } from 'apollo-client'
import { GrupoExameTableQuery } from 'graphql/types.generated'
import React from 'react'

import { GrupoExameItem } from './CreateGrupoExame'
import { GrupoExamesModalForm } from './GrupoExamesModalForm'

interface GrupoExameProps {
  data: GrupoExameItem
  refetch(): Promise<ApolloQueryResult<GrupoExameTableQuery>>
}

export const GrupoExamesModal = (props: GrupoExameProps) => {
  const { data, refetch } = props

  return <GrupoExamesModalForm initialValues={data} refetch={refetch} />
}
