import { AlternarConexaoInternetMutation, AlternarConexaoInternetMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const useAlternarConexaoMutation = () => {
  return useMutation<AlternarConexaoInternetMutation, AlternarConexaoInternetMutationVariables>(
    ALTERNAR_CONEXAO_INTERNET_MUTATION
  )
}

const ALTERNAR_CONEXAO_INTERNET_MUTATION = gql`
  mutation AlternarConexaoInternetMutation($habilitado: Boolean) {
    alternarInternet(habilitado: $habilitado) {
      habilitado
    }
  }
`
