import { ApolloQueryResult } from 'apollo-client'
import { useGrupoExamesQuery } from 'graphql/hooks.generated'
import { GrupoExamesQuery, GrupoExameTableQuery } from 'graphql/types.generated'
import React from 'react'
import { useParams } from 'react-router'

import { GrupoExamesModal } from './GrupoExameModal'

export type Procedimento = GrupoExamesQuery['grupoExame']['procedimentos'][0]
export type GrupoExameItem = GrupoExamesQuery['grupoExame']

interface CreateGrupoExameProps {
  refetch(): Promise<ApolloQueryResult<GrupoExameTableQuery>>
}

export function CreateGrupoExame(props: CreateGrupoExameProps) {
  const data = {
    id: null,
    nome: '',
    procedimentos: [] as Procedimento[],
  } as GrupoExameItem

  return <GrupoExamesModal data={data} refetch={props.refetch} />
}

interface UrlParams {
  grupoExameId: string
}

export function EditGrupoExame(props: CreateGrupoExameProps) {
  const params = useParams<UrlParams>()

  const { data } = useGrupoExamesQuery({ variables: { id: params.grupoExameId } })
  if (!data || !data.grupoExame) {
    return null
  }

  return <GrupoExamesModal data={data.grupoExame} refetch={props.refetch} />
}
