import { ConexaoQuery_conexao_atestadoDigital } from 'api/schema'
import { Button, Cell, Grid, Heading, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { confirm } from 'components/confirm'
import { DateTime } from 'components/date'
import { Form } from 'components/form'
import { analytics } from 'config/firebase'
import { useAlterarAtestadoDigitalMutation } from 'graphql/hooks.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

import {
  IntervaloAtestadoDigitalSelectField,
  IntervaloAtestadoDigitalSelectModel,
  items,
} from './IntervaloAtestadoDigitalSelectField'

interface ConfiguracoesAtestadoDigitalBoxProps {
  atestadoDigital: ConexaoQuery_conexao_atestadoDigital
  internetHabilitada: boolean

  load(): void
}

interface FormType {
  intervalo: IntervaloAtestadoDigitalSelectModel
}

export function ConfiguracoesAtestadoDigitalBox(props: ConfiguracoesAtestadoDigitalBoxProps) {
  const { atestadoDigital, internetHabilitada } = props
  const alert = useAlert()
  const [alterarAtestadoDigital] = useAlterarAtestadoDigitalMutation()

  const handleSubmit = (values: FormType) => {
    if (atestadoDigital.habilitado) {
      confirm({
        title: 'Deseja desabilitar a conexão com o Sistema VIO?',
        body:
          'A conexão com o Sistema VIO deve ser desabilitada somente em casos de instabilidade ' +
          'da conexão com a Internet ou do serviço do Sistema VIO.',
        confirmLabel: 'Desabilitar',
        onConfirm: () => {
          analytics.logEvent('desabilita_atestado_digital')
          submitIntervalo(values, 'Conexão com o Sistema VIO foi desabilitada com sucesso')
        },
      })()
    } else {
      analytics.logEvent('habilita_atestado_digital')
      submitIntervalo(values, 'Conexão com o Sistema VIO foi habilitada com sucesso')
    }
  }

  const submitIntervalo = (values: FormType, msg: string) => {
    return alterarAtestadoDigital({
      variables: {
        alterarAtestadoDigitalInput: {
          id: '1',
          habilitado: !atestadoDigital.habilitado,
          intervalo: values.intervalo && values.intervalo.value,
        },
      },
    }).then(() => {
      alert('success', msg)
    })
  }

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <Box style={{ height: '100%' }}>
        <Grid>
          <Cell flexGrow={1}>
            <VFlow>
              <Heading level={3} style={{ display: 'inline' }}>
                Sistema VIO
              </Heading>

              {!atestadoDigital.habilitado ? (
                <Tooltip text={!internetHabilitada && 'Não pode ser habilitada sem conexão com a internet'}>
                  <Button
                    kind='primary'
                    size='small'
                    onClick={formProps.handleSubmit}
                    disabled={!internetHabilitada}
                    style={{ marginTop: '1.5rem' }}
                  >
                    Habilitar
                  </Button>
                </Tooltip>
              ) : (
                <Grid gap={1}>
                  <Cell flexGrow={1}>
                    <IntervaloAtestadoDigitalSelectField name='intervalo' />
                  </Cell>
                  <Cell>
                    <Button
                      kind='primary'
                      size='small'
                      onClick={formProps.handleSubmit}
                      style={{ marginTop: '1.5rem' }}
                    >
                      Desabilitar
                    </Button>
                  </Cell>
                </Grid>
              )}
            </VFlow>
          </Cell>
          <Cell style={{ textAlign: 'right' }}>
            <VFlow>
              {atestadoDigital.habilitado ? (
                <Tag type='success' icon='checkCircleFilled'>
                  Habilitado
                </Tag>
              ) : (
                <Tag type='normal' icon='banFilled'>
                  Desabilitado
                </Tag>
              )}

              {atestadoDigital.desabilitadoAteData && (
                <Text>
                  Desabilitado até <DateTime value={atestadoDigital.desabilitadoAteData} />.
                </Text>
              )}

              {!atestadoDigital.habilitado && !atestadoDigital.desabilitadoAteData && (
                <Text>Desabilitado por tempo indeterminado.</Text>
              )}
            </VFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }

  const intervaloValue = atestadoDigital.habilitado ? items[1] : undefined
  const initialValues = { ...atestadoDigital, intervalo: intervaloValue }

  return <Form onSubmit={handleSubmit} initialValues={initialValues} render={renderForm} />
}
