import { Alert, Button, HFlow, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { CheckboxField } from 'components/form'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import {
  ConfiguracaoAgendaFormType,
  ConfiguracaoAgendaPanel,
  meta,
} from 'view/configuracaoAgenda/component/ConfiguracaoAgendaPanel'
import {
  clearHorariosFinalDeSemana,
  hasFinalDeSemanaPreenchidoChecker,
} from 'view/configuracaoAgenda/validator/base/ConfiguracaoHorarioAgendaHelper'

export interface ConfiguracaoAgendaLotacaoGroupProps {
  formProps: FormRenderProps<ConfiguracaoAgendaFormType>
  submitFailedStatus: boolean
  configuracaoAgendaLotacao: ConfiguracaoAgendaFormType
  configuracaoAgendaMunicipio: ConfiguracaoAgendaFormType
}

export const clearAllFields = (form: FormRenderProps<ConfiguracaoAgendaFormType>['form']) => {
  form.reset()

  const diaArray = [0, 1, 2, 3, 4, 5, 6]
  const periodoArray = [0, 1, 2, 3]

  diaArray.forEach(diaIndex => {
    periodoArray.forEach(periodoIndex => {
      const innerPath = meta.configuracaoHorarioAgenda.configuracaoDiasSemana.get(diaIndex).periodos.get(periodoIndex)
      form.change(innerPath.horarioInicial.absolutePath(), null)
      form.change(innerPath.horarioFinal.absolutePath(), null)
    })
  })
}

export function ConfiguracaoAgendaLotacaoPanel(props: ConfiguracaoAgendaLotacaoGroupProps) {
  const { formProps } = props
  const { form } = formProps

  const loadDefault = () => {
    props.configuracaoAgendaMunicipio.configuracaoHorarioAgenda.configuracaoDiasSemana.forEach(
      (diaSemanaPadraoInput, diaIndex) => {
        diaSemanaPadraoInput.periodos.forEach((periodoPadraoInput, periodoIndex) => {
          const innerPath = meta.configuracaoHorarioAgenda.configuracaoDiasSemana
            .get(diaIndex)
            .periodos.get(periodoIndex)

          form.change(innerPath.horarioInicial.absolutePath(), periodoPadraoInput.horarioInicial)
          form.change(innerPath.horarioFinal.absolutePath(), periodoPadraoInput.horarioFinal)
        })
      }
    )
    form.change(
      meta.mostrarFinalDeSemana.absolutePath(),
      hasFinalDeSemanaPreenchidoChecker({
        configuracaoDiasSemana: props.configuracaoAgendaMunicipio.configuracaoHorarioAgenda.configuracaoDiasSemana,
      })
    )
  }

  const handleMostrarFinalDeSemanaChanged = (formRenderProps: FormRenderProps<ConfiguracaoAgendaFormType>) => e => {
    clearHorariosFinalDeSemana(formRenderProps, e.target.checked)
  }

  const showAlert = (formRenderProps: FormRenderProps<ConfiguracaoAgendaFormType>) => {
    if ((formRenderProps.hasValidationErrors || formRenderProps.hasSubmitErrors) && props.submitFailedStatus) {
      if (
        !formRenderProps.hasValidationErrors &&
        formRenderProps.submitErrors &&
        (formRenderProps.submitErrors.configuracaoHorarioAgenda ||
          formRenderProps.submitErrors.configuracaoAgendaConflitoError)
      ) {
        if ('configuracaoHorarioAgendaTitleErrorField' in formRenderProps.submitErrors.configuracaoHorarioAgenda) {
          return (
            <Alert type='warning'>
              {formRenderProps.submitErrors.configuracaoHorarioAgenda.configuracaoHorarioAgendaTitleErrorField}
            </Alert>
          )
        } else if (formRenderProps.submitErrors.configuracaoAgendaConflitoError) {
          return <Alert type='warning'>{formRenderProps.submitErrors.configuracaoAgendaConflitoError}</Alert>
        }
      } else {
        return <Alert type='warning'>Existem campos preenchidos de forma incorreta</Alert>
      }
    }
  }

  return (
    <>
      <Box style={{ borderBottom: 'none', paddingTop: '0.75rem', paddingBottom: '0.75rem;' }}>
        <VFlow>
          <HFlow justifyContent='space-between' alignItems='center'>
            <HFlow>
              <Text fontWeight='bold'>Horários de trabalho</Text>
              <CheckboxField
                name='mostrarFinalDeSemana'
                label='Mostrar fim de semana'
                onChange={handleMostrarFinalDeSemanaChanged(formProps)}
              />
            </HFlow>
            <HFlow>
              <Button size='small' kind='primary' skin='outline' onClick={loadDefault}>
                Horário padrão
              </Button>
              <Button size='small' kind='normal' skin='outline' onClick={() => clearAllFields(formProps.form)}>
                Limpar todos os campos
              </Button>
            </HFlow>
          </HFlow>
          {showAlert(formProps)}
        </VFlow>
      </Box>
      <ConfiguracaoAgendaPanel configuracaoSemana={props.configuracaoAgendaLotacao} formProps={formProps} />
    </>
  )
}
