import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import React from 'react'

import { NewsInternalPanelV4_10 as NewsInternalPanelVQuatroUm } from './NewsInternalPanelV4_10'

export function NewsView() {
  return (
    <>
      <PageHeaderSection title='Novidades da versão' />
      <PageContent style={{ backgroundColor: 'white' }}>
        <Breadcrumb title='Novidades da versão' />
        <NewsInternalPanelVQuatroUm />
      </PageContent>
    </>
  )
}
