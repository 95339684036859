import configApp from 'config'
import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './App'
import { unregister } from './registerServiceWorker'

configApp()

ReactDOM.render(<App />, document.getElementById('root'))

unregister()
