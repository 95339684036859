import {
  Button,
  ExternalStyles,
  HFlow,
  Icon,
  TableCell,
  TableRow,
  Tag,
  Text,
  TextColor,
  Tooltip,
  useStyles,
} from 'bold-ui'
import { DateTime } from 'components/date'
import { LoteImunobiologico } from 'graphql/types.generated'
import moment from 'moment'
import React, { ReactNode } from 'react'

import { LoteImunobiologicoTableDropDown } from './LoteImunobiologicoTableDropdown'

interface LoteImunobiologicoStaticRowProps {
  row: LoteImunobiologico
  reload(): Promise<any>
  onEditClick(): void
  style?: ExternalStyles
}

export default function LoteImunobiologicoStaticRow(props: LoteImunobiologicoStaticRowProps) {
  const { row, reload, onEditClick, style } = props

  const { classes } = useStyles(theme => ({
    successIcon: {
      fontSize: '1rem',
      color: theme.pallete.status.success.main,
    },
    errorIcon: {
      fontSize: '1rem',
      color: theme.pallete.status.danger.main,
    },
  }))

  const renderLote = (
    <HFlow hSpacing={0.5} alignItems='center'>
      <Text>{row.lote}</Text>
      {!row.ativo && <Tag>Inativo</Tag>}
    </HFlow>
  )

  const renderFabricante = <Text>{row.fabricante.nome}</Text>
  const renderImunobiologico = (
    <Text>
      {row.imunobiologico.sigla} - {row.imunobiologico.nome}
    </Text>
  )

  const renderValidade = () => {
    let icon: ReactNode
    let color: TextColor

    if (moment().isSameOrBefore(row.validade, 'date')) {
      icon = <Icon style={classes.successIcon} icon='checkCircleOutline' />
      color = 'success'
    } else {
      icon = <Icon style={classes.errorIcon} icon='banOutline' />
      color = 'danger'
    }

    return (
      <HFlow hSpacing={0.2} alignItems='center'>
        {icon}
        <Text color={color}>
          <DateTime format='DD/MM/YYYY' value={row.validade} />
        </Text>
      </HFlow>
    )
  }

  const renderButtons = (
    <>
      <Tooltip text='Editar'>
        <Button size='small' skin='ghost' title='Editar' onClick={onEditClick}>
          <Icon icon='penOutline' />
        </Button>
      </Tooltip>
      <LoteImunobiologicoTableDropDown loteImunoItem={row} reload={reload} />
    </>
  )

  return (
    <TableRow style={style}>
      <TableCell>{renderLote}</TableCell>
      <TableCell>{renderFabricante}</TableCell>
      <TableCell>{renderImunobiologico}</TableCell>
      <TableCell>{renderValidade()}</TableCell>
      <TableCell style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{renderButtons}</TableCell>
    </TableRow>
  )
}
