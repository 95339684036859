import * as Sentry from '@sentry/browser'

import { METRICS_ENABLED } from './util'

if (METRICS_ENABLED) {
  Sentry.init({
    dsn: 'https://5f8563fe6dcc4621896ea2db0e18273f@sentry.ctn.bridge.ufsc.br/2',
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_SPRING_PROFILES_ACTIVE,
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onunhandledrejection: true,
        onerror: true,
      }),
    ],
  })
}
