import gql from 'graphql-tag'

export const CONFIGURACAO_AGENDA_ONLINE_LOTACAO_QUERY = gql`
  query ConfiguracaoAgendaOnlineLotacaoQuery($lotacaoId: ID!) {
    lotacao(id: $lotacaoId) {
      id
      hasConfiguracaoAgendaOnline
      cbo {
        id
        nome
        cbo2002
      }
      equipe {
        id
        ine
        nome
      }
      unidadeSaude {
        id
        nome
      }
      profissional {
        nome
      }
      gradeConfiguracaoAgendaOnline {
        horariosConsolidados
        configuracoesSemana {
          diaSemana
          configuracoes {
            horario
            ativo
          }
        }
      }
    }
  }
`
