import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { OperationDefinitionNode } from 'graphql'
import cache from 'graphql/cache'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { RequestListenerLink } from './RequestListenerLink'

const options: HttpLink.Options = {
  uri: '/api/graphql',
  credentials: 'same-origin', // send auth cookie with every request
}

export const wsClient = new SubscriptionClient(
  process.env.NODE_ENV === 'development'
    ? `ws://localhost:8080/api/subscriptions`
    : `${window.location.protocol === 'https' ? 'wss' : 'ws'}://${window.location.host}/api/subscriptions`,
  {
    reconnect: true,
    lazy: true,
  }
)

export const wsLink = new WebSocketLink(wsClient)

export const requestListener = new RequestListenerLink()

// Send data to each link depending on what kind of operation is being sent
const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  new BatchHttpLink(options)
)

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([requestListener, link]),
  name: 'PEC Web',
  version: process.env.REACT_APP_VERSION,
})

export default client
