import { ConexaoQuery_conexao_agendaOnline } from 'api/schema'
import { Alert, Cell, Grid, Heading, HFlow, Icon, Tag, Tooltip, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import theme from 'config/theme'
import React from 'react'

import { ConfiguracaoAgendaOnlineDesabilitadoContent } from './ConfiguracaoAgendaOnlineDesabilitadoContent'
import { ConfiguracaoAgendaOnlineHabilitadoContent } from './ConfiguracaoAgendaOnlineHabilitadoContent'

export type ConfiguracaoAgendaOnline = ConexaoQuery_conexao_agendaOnline

export interface ConfiguracaoAgendaOnlineBoxProps {
  agendaOnline: ConfiguracaoAgendaOnline
  internetHabilitada: boolean
  refetch(): void
}

interface StateType {
  showForm: boolean
  chave: string
}

export class ConfiguracaoAgendaOnlineBox extends React.Component<ConfiguracaoAgendaOnlineBoxProps, StateType> {
  state: StateType = {
    showForm: false,
    chave: null,
  }

  render() {
    const { agendaOnline, internetHabilitada, refetch } = this.props
    const iconStyle = {
      '&:hover': {
        color: theme.pallete.primary.main,
      },
    }
    return (
      <Box>
        <VFlow>
          <Grid>
            <Cell flexGrow={1}>
              <Heading level={3}>
                <HFlow hSpacing={0.5} alignItems='center'>
                  Agenda online
                  <Tooltip
                    style={{ maxWidth: '595px' }}
                    text={
                      'Ao ativar a agenda online, os cidadãos irão' +
                      ' receber, no aplicativo Meu digiSUS,' +
                      ' notificações sobre o agendamento e cancelamento' +
                      ' de consultas realizadas no PEC. Para' +
                      ' que os cidadãos possam realizar agendamentos' +
                      ' pelo aplicativo, o coordenador da UBS deve' +
                      ' configurar a agenda online da lotação do profissional' +
                      ' que irá realizar os atendimentos.'
                    }
                  >
                    <Icon icon='infoCircleFilled' size={1} style={iconStyle} />
                  </Tooltip>
                </HFlow>
              </Heading>
            </Cell>
            <Cell>
              {agendaOnline.ativa ? (
                <Tag type='success' icon='checkCircleFilled'>
                  Habilitada
                </Tag>
              ) : (
                <Tag type='normal' icon='banFilled'>
                  Desabilitada
                </Tag>
              )}
            </Cell>
          </Grid>

          {internetHabilitada ? (
            <>
              {agendaOnline.ativa ? (
                <ConfiguracaoAgendaOnlineHabilitadoContent agendaOnline={agendaOnline} refetch={refetch} />
              ) : (
                <ConfiguracaoAgendaOnlineDesabilitadoContent agendaOnline={agendaOnline} refetch={refetch} />
              )}
            </>
          ) : (
            <>
              {agendaOnline.ativa ? (
                <Alert type='info' inline>
                  Não pode ser desabilitada sem conexão com a internet.
                </Alert>
              ) : (
                <Alert type='info' inline>
                  Não pode ser habilitada sem conexão com a internet.
                </Alert>
              )}
            </>
          )}
        </VFlow>
      </Box>
    )
  }
}
