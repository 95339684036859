import { Text } from 'bold-ui'
import { DateTime } from 'components/date'
import { useLoadHorarioGeracaoLotesProcessamentoFichasQuery } from 'graphql/hooks.generated'
import moment from 'moment'
import * as React from 'react'

export interface TransmissaoGeracaoFichasCmpProps {
  text: string
}
export const TransmissaoHorarioGeracao = ({ text }: TransmissaoGeracaoFichasCmpProps) => {
  const { data } = useLoadHorarioGeracaoLotesProcessamentoFichasQuery()
  let elem = <i>Horário não definido</i>
  if (data && data.loadHorarioGeracaoLotesProcessamentoFichas) {
    elem = <DateTime value={moment(data.loadHorarioGeracaoLotesProcessamentoFichas, 'H:mm:ss')} format='H[h]mm[min]' />
  }
  return (
    <Text>
      {text} {elem}.
    </Text>
  )
}
