import 'config/firebase'

import { ApolloProvider } from '@apollo/react-hooks'
import { LocaleContext, ThemeProvider } from 'bold-ui'
import ptBr from 'bold-ui/lib/i18n/locales/pt-BR'
import { AlertContext, defaultAlertStore } from 'components/alert'
import { BreadcrumbContext, defaultBreadcrumbStore } from 'components/breadcrumb'
import { handleError, PromiseErrorBoundary } from 'components/error'
import { defaultEsusViewStore, EsusViewContext } from 'components/esus/EsusViewContext'
import { history } from 'config'
import theme from 'config/theme'
import apolloClient from 'graphql/client'
import React from 'react'
import { Router } from 'react-router'
import { InitialView } from 'view/InitialView'

export const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <PromiseErrorBoundary onError={handleError}>
        <ThemeProvider theme={theme}>
          <LocaleContext.Provider value={ptBr}>
            <AlertContext.Provider value={defaultAlertStore}>
              <BreadcrumbContext.Provider value={defaultBreadcrumbStore}>
                <EsusViewContext.Provider value={defaultEsusViewStore}>
                  <Router history={history}>
                    <InitialView />
                  </Router>
                </EsusViewContext.Provider>
              </BreadcrumbContext.Provider>
            </AlertContext.Provider>
          </LocaleContext.Provider>
        </ThemeProvider>
      </PromiseErrorBoundary>
    </ApolloProvider>
  )
}
