import { ModalMountTarget, StickyContainer } from 'bold-ui'
import { AlertContainer } from 'components/AlertContainer'
import useSession from 'components/auth/useSession'
import { BarraBrasil } from 'components/layout/BarraBrasil'
import { SiteWrapper } from 'components/layout/SiteWrapper'
import { useInitialViewQuery } from 'graphql/hooks.generated'
import React from 'react'

import { AuthPortal } from './auth/AuthPortal'
import { InstalacaoRootView } from './instalacao/InstalacaoRootView'

export function InitialView() {
  const session = useSession()
  const {
    data: { info },
  } = useInitialViewQuery()

  return (
    <SiteWrapper>
      <BarraBrasil />

      <StickyContainer
        top={session && session.data && session.data.acesso && session.data.acesso.moduloInicial ? 138 : 42}
      >
        <AlertContainer />
      </StickyContainer>

      {info && !info.ativado ? <InstalacaoRootView /> : <AuthPortal />}

      <ModalMountTarget />
    </SiteWrapper>
  )
}
