import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { confirm } from 'components/confirm'
import { useErrorHandler } from 'components/error'
import { DateField, Form, FormRenderProps, TextField } from 'components/form'
import { FormApi } from 'final-form'
import { useSalvarLoteImunobiologicoMutation } from 'graphql/hooks.generated'
import moment from 'moment'
import React from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, maxLength, required } from 'util/validation'
import { loteImuno } from 'util/validation/rules/loteImuno'

import convertModelToInput from '../LoteImunoModelnputConverter'
import { ImunobiologicoSelectField, ImunobiologicoSelectModel } from './ImunobiologicoSelectField'
import {
  LoteImunobiologicoFabricanteSelectField,
  LoteImunobiologicoFabricanteSelectFieldModel,
} from './LoteImunobiologicoFabricanteSelectField'

export interface LoteImunobiologicoFormModel {
  id?: ID
  lote?: string
  fabricante?: LoteImunobiologicoFabricanteSelectFieldModel
  imunobiologico?: ImunobiologicoSelectModel
  validade?: LocalDate
}

export interface LoteImunobiologicoTooltipAddFormProps {
  onClose(): void
  onCancel(): void
  onSuccess(): void
}

export const validate = createValidator<LoteImunobiologicoFormModel>(
  {
    lote: [required, maxLength(30), loteImuno],
    fabricante: [required, maxLength(60)],
    imunobiologico: [required],
    validade: [required],
  },
  (values: LoteImunobiologicoFormModel, errors: ErrorObject<LoteImunobiologicoFormModel>) => {
    if (!errors.fabricante && values?.fabricante?.nome) {
      const errorFabricanteImunoRule = loteImuno(values.fabricante.nome)
      if (errorFabricanteImunoRule) {
        errors.fabricante = errorFabricanteImunoRule
      }
    }

    return errors
  }
)

export default function LoteImunobiologicoTooltipAddForm(props: LoteImunobiologicoTooltipAddFormProps) {
  const { onCancel, onClose, onSuccess } = props
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const [salvar] = useSalvarLoteImunobiologicoMutation()

  const path = metaPath<LoteImunobiologicoFormModel>()

  const renderForm = (formProps: FormRenderProps<LoteImunobiologicoFormModel>) => {
    return (
      <>
        <Grid wrap gap={1}>
          <Cell xs={12} md={5}>
            <TextField name={path.lote} label='Lote' required maxLength={30} />
          </Cell>
          <Cell xs={12} md={7}>
            <LoteImunobiologicoFabricanteSelectField
              name={path.fabricante}
              label='Fabricante'
              required
              maxLength={60}
            />
          </Cell>
          <Cell xs={12} md={7}>
            <ImunobiologicoSelectField name={path.imunobiologico} label='Imunobiológico' required />
          </Cell>
          <Cell xs={12} md={5}>
            <DateField name={path.validade} label='Data de validade' required />
          </Cell>
        </Grid>
        <HFlow justifyContent='flex-end' style={{ marginTop: '1rem' }}>
          <Button size='small' onClick={handleClose(formProps)}>
            Fechar
          </Button>
          <Button size='small' kind='primary' onClick={handleSubmit(formProps)}>
            Adicionar
          </Button>
        </HFlow>
      </>
    )
  }

  const handleClose = (formProps: FormRenderProps) => e => {
    onClose()
    setTimeout(formProps.form.reset)
  }

  const validadeVencida = (formProps: FormRenderProps<LoteImunobiologicoFormModel>) => {
    if (
      formProps?.values?.fabricante?.nome &&
      formProps?.values?.validade &&
      moment().isAfter(formProps.values.validade, 'date')
    ) {
      confirm({
        title: 'Deseja confirmar o cadastro deste lote de imunobiológico?',
        body: 'A data de validade do lote de imunobiológico está vencida.',
        cancelLabel: 'Cancelar',
        confirmLabel: 'Confirmar',
        onConfirm: () => {
          formProps.handleSubmit().catch(handleRejection)
        },
        onCancel: () => {
          onCancel()
        },
      })()
    } else {
      formProps.handleSubmit()
    }
  }

  const handleSubmit = (form: FormRenderProps<LoteImunobiologicoFormModel>) => () => {
    validadeVencida(form)
  }

  const submitForm = (form: LoteImunobiologicoFormModel, formApi: FormApi) => {
    return salvar({ variables: { input: convertModelToInput(form) } }).then(success => {
      alert('success', 'Lote de imunobiológico foi salvo com sucesso')
      setTimeout(formApi.reset)
      onSuccess()
    })
  }

  return <Form<LoteImunobiologicoFormModel> render={renderForm} onSubmit={submitForm} validate={validate} />
}
