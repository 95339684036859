import { Button, DropdownDivider, DropdownItem, Heading, HFlow, Icon, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { Box } from 'components/Box'
import { confirm } from 'components/confirm'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { DropdownButton, DropdownLink } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { Cpf, Telefone } from 'components/label'
import { useExcluirCidadaoMutation, useReativarCidadaoMutation } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { useHistory } from 'react-router'
import { identidadeGenero, sexo } from 'types/enums'
import Permissions from 'types/Permissions'

import { CidadaoListingFormModel } from './CidadaoPesquisarFormModel'

interface CidadaoListingItemProps {
  data: CidadaoListingFormModel
  refetchLocal(): void
}

export const CidadaoListingItem = (props: CidadaoListingItemProps) => {
  const { data } = props
  const { hasAuthorization } = useSession()
  const [excluirCidadao] = useExcluirCidadaoMutation()
  const [reativarCidadao] = useReativarCidadaoMutation()

  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const history = useHistory()
  const { match } = useRouter()
  const telefone = data.telefoneCelular
    ? data.telefoneCelular
    : data.telefoneResidencial
    ? data.telefoneResidencial
    : null

  const cadastrarEEditar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar)
  const excluir = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.excluir)
  const inativar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.inativar)
  const visualizarProntuario = hasAuthorization(Permissions.visualizarProntuario)
  const visualizarAgenda = hasAuthorization(Permissions.visualizarAgenda)

  const allPermissions = cadastrarEEditar || excluir || inativar || visualizarProntuario || visualizarAgenda

  const handleExcluir = () => {
    confirm({
      title: `Deseja excluir o cadastro do cidadão?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluirCidadao({ variables: { cidadaoId: data.id } })
          .then(() => {
            alert('success', 'Cadastro do cidadão excluído')
            props.refetchLocal()
          })
          .catch(handleRejection)
      },
    })()
  }

  const navigateVisualizar = () => history.push(`${match.url}/${data.id}`)
  const handleReativar = () => {
    confirm({
      title: `Deseja reativar o cadastro do cidadão?`,
      confirmLabel: 'Reativar',
      type: 'primary',
      onConfirm: () => {
        return reativarCidadao({ variables: { cidadaoId: data.id } })
          .then(() => {
            alert('success', 'Cadastro do cidadão reativado')
            props.refetchLocal()
          })
          .catch(handleRejection)
      },
    })()
  }

  return (
    <>
      <Box style={{ minHeight: 136, textAlign: 'left' }}>
        <HFlow justifyContent='space-between'>
          <VFlow vSpacing={0}>
            <HFlow hSpacing={0.5} alignItems='baseline'>
              <Heading level={3}>{data.nomeSocial ? data.nomeSocial : data.nome}</Heading>
              {data.nomeSocial && <Text fontWeight='bold'>(Nome social)</Text>}
              {data.nomeSocial && <Text>{'| ' + data.nome}</Text>}
              {data.faleceu && <Tag>Óbito</Tag>}
              {!data.ativo && <Tag>Inativo</Tag>}
              {data.unificado && <Tag>Cadastro unificado</Tag>}
            </HFlow>
            <HFlow hSpacing={2}>
              <VFlow vSpacing={0} style={{ width: 350 }}>
                <HFlow>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Text fontWeight='bold'>CPF</Text>
                    {data.cpf ? <Cpf value={data.cpf} /> : '-'}
                  </HFlow>
                  <HFlow hSpacing={0.5}>
                    <Text fontWeight='bold'>CNS</Text>
                    {data.cns ? <Text>{data.cns}</Text> : '-'}
                  </HFlow>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Data de nascimento</Text>
                  <DateTime format='DD/MM/YYYY' value={data.dataNascimento} />
                  (<Age dateOfBirth={data.dataNascimento ? data.dataNascimento : '-'} />)
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Sexo</Text>
                  <Text>{sexo[data.sexo]}</Text>
                  <Text fontWeight='bold'>{data.identidadeGeneroDbEnum ? 'Id. de gênero' : null}</Text>
                  <Text>{data.identidadeGeneroDbEnum && identidadeGenero[data.identidadeGeneroDbEnum]}</Text>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Última atualização</Text>
                  <DateTime format='DD/MM/YYYY' value={data.dataAtualizado ? data.dataAtualizado : '-'} />
                </HFlow>
              </VFlow>
              <VFlow vSpacing={0}>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Nome da mãe</Text>
                  <Text>{data.nomeMae ? data.nomeMae : '-'}</Text>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Telefone</Text>
                  {telefone ? <Telefone value={telefone}></Telefone> : <Text>-</Text>}
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Unidade responsável</Text>
                  <Text>{data.cidadaoVinculacaoEquipe?.unidadeSaude?.nome || '-'}</Text>
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Município de nascimento</Text>
                  <Text>
                    {data.localidadeNascimento
                      ? data.localidadeNascimento?.nome + ' - ' + data.localidadeNascimento?.uf?.sigla
                      : '-'}
                  </Text>
                </HFlow>
              </VFlow>
            </HFlow>
          </VFlow>
          <HFlow alignItems='center' hSpacing={0.5}>
            <Button
              kind='normal'
              skin='outline'
              size='small'
              style={{ height: 32, width: 114 }}
              onClick={navigateVisualizar}
            >
              <Icon icon='zoomOutline' style={{ marginRight: '0.5rem' }} />
              Visualizar
            </Button>
            {allPermissions && (
              <DropdownButton>
                {cadastrarEEditar && <DropdownLink to={`/cidadao/${data.id}/edit`}>Atualizar cadastro</DropdownLink>}
                {!data.ativo && inativar && <DropdownItem onClick={handleReativar}>Reativar cadastro</DropdownItem>}
                {visualizarProntuario && !data.prontuario && (
                  <Tooltip text='Cadastro não possui prontuário'>
                    <DropdownItem disabled>Ver prontuário</DropdownItem>
                  </Tooltip>
                )}
                {visualizarProntuario && data.prontuario && <DropdownItem>Ver prontuário</DropdownItem>}
                {visualizarAgenda && data.possuiAgendamento && <DropdownItem>Ver agendamentos</DropdownItem>}
                {visualizarAgenda && !data.possuiAgendamento && (
                  <Tooltip text='Não há agendamentos registrados para o cidadão'>
                    <DropdownItem disabled>Ver agendamentos</DropdownItem>
                  </Tooltip>
                )}
                {excluir && (
                  <>
                    <DropdownDivider />
                    <DropdownItem
                      type='danger'
                      style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem', display: 'flex', alignItems: 'center' }}
                      onClick={handleExcluir}
                    >
                      <Icon icon='trashOutline' style={{ marginRight: '0.5rem' }} /> Excluir
                    </DropdownItem>
                  </>
                )}
              </DropdownButton>
            )}
          </HFlow>
        </HFlow>
      </Box>
    </>
  )
}
