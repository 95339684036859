import { Button, HeadingSection, Icon, Link, Text, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { FormFooter } from 'components/form/FormFooter'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { useMutation } from 'graphql/hooks'
import { useSobreQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'

import ACEITAR_TERMOS_USO_MUTATION from './AceitarTermosUsoMutation'

export const TermosView = () => {
  const session = useSession({ fetchPolicy: 'cache-only' })
  const history = useHistory()

  const onSair = () => history.push('/logout')

  const faltaAceitarTermos = session.data && !session.data.profissional.usuario.aceitouTermosUso

  const [aceitarTermos] = useMutation(ACEITAR_TERMOS_USO_MUTATION)

  const handleAceitarTermos = () => aceitarTermos()

  const {
    data: { info },
  } = useSobreQuery()

  if (!info) {
    return null
  }

  return (
    <PageContent type='filled' style={{ marginTop: '2rem' }}>
      <Breadcrumb title='Termos de uso' />
      <VFlow vSpacing={2} style={{ width: 560, textAlign: 'left', fontSize: '0.875rem' }}>
        <PageHeader title='Termos de uso e condições gerais' subtitle={'Versão ' + info.versao} />
        <HeadingSection level={2} color='primary' title=''>
          <p>
            Este documento busca esclarecer os direitos e obrigações do fornecedor e do usuário do Sistema e-SUS Atenção
            Primária (e-SUS APS), bem como as condições da correta utilização do referido sistema.
          </p>
          <br />
          <p>
            Ao utilizar o e-SUS APS você se torna um <b>USUÁRIO</b>, e isso implica a aceitação plena e sem reserva de
            todos os itens do presente <b>TERMO</b>.
            {faltaAceitarTermos &&
              ` Para aceitar o termo, ao final, clique em “Declaro que li e concordo com os termos e condições
                apresentados acima”. Caso não concorde com estes termos, clique em "Sair".`}
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='Como funciona?'>
          <p>
            O Sistema e-SUS APS é um software público que atende as necessidades de modernização do cuidado em saúde nas
            unidades de Atenção Primária à Saúde (APS). O Prontuário Eletrônico do Cidadão (PEC) é compartilhado sem
            ônus pelo portal da APS (
            <Link href='http://aps.saude.gov.br/ape/esus' target='_blank'>
              http://aps.saude.gov.br/ape/esus
            </Link>
            ) do Ministério da Saúde (MS). O software é de responsabilidade do Departamento de Saúde da Família (DESF) e
            da Secretaria de Atenção Primária à Saúde (SAPS) e o desenvolvimento é realizado em cooperação com a
            Universidade Federal de Santa Catarina (UFSC).
          </p>
          <br />
          <p>
            O Sistema e-SUS APS com Prontuário Eletrônico do Cidadão (PEC) é um sistema de software que auxilia, entre
            outras coisas, nas ações de:
          </p>
          <ul>
            <li>Gerenciamento de Unidades de Atenção Primária à Saúde;</li>
            <li>Organização da agenda e processo de trabalho;</li>
            <li>Registro e organização, em formato de prontuário eletrônico, para os profissionais de saúde;</li>
            <li>
              Registro e organização, em formato de Coleta de Dados Simplificada (módulo CDS), por meio de fichas e
              processo de digitação destas;
            </li>
            <li>Monitoramento e avaliação das ações de saúde no território. </li>
          </ul>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='Cadastro de estabelecimentos e profissionais'>
          <p>
            O cadastramento de estabelecimentos de saúde, bem como o cadastro dos profissionais lotados nestes
            estabelecimentos, é feito por meio do Sistema de Cadastro Nacional de Estabelecimentos de Saúde (SCNES). A
            responsabilidade de alimentação e atualização do SCNES é da gestão no nível municipal. Portanto, qualquer
            informação relacionada a estabelecimentos de saúde e/ou profissionais que esteja em desacordo com a
            realidade deverá ser adequada no SCNES.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='Acessando o sistema'>
          <p>
            O acesso ao sistema se dá única e exclusivamente por meio de LOGIN e SENHA. Estes são de seu uso pessoal e
            intransferível, devendo, portanto, o USUÁRIO do sistema tomar todas as medidas necessárias para manter em
            sigilo as referidas informações.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='Envio de informações ao SISAB'>
          <p>
            O Sistema e-SUS APS está totalmente integrado ao Sistema de Informação em Saúde para a Atenção Básica
            (SISAB), sistema oficial de monitoramento das ações da Atenção Primária à Saúde em nível nacional. A
            depender do cenário de implantação local, os dados serão enviados de forma automática ou manual ao SISAB.
          </p>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='Proteção dos dados'>
          <p>
            Considerando o modelo de atenção preconizado pela Política Nacional de Atenção Básica (PNAB), o Sistema
            e-SUS APS facilita a comunicação entre os profissionais de saúde da mesma equipe, bem como com os
            profissionais da mesma unidade de saúde, compartilhando informações por meio do sistema. Para isso, será
            necessário o aceite do presente TERMO e o esclarecimento do cidadão em relação à sua própria privacidade,
            informando o por que e para que o seu dado está sendo coletado. O modelo adotado pelo Sistema e-SUS APS,
            para garantir o consentimento do cidadão, é o opt-out, ou seja, todo cidadão terá seus dados compartilhados
            dentro do serviço de Atenção Primária do município, alinhado ao modelo de atenção preconizado.
          </p>
          <br />
          <p>
            Entretanto, caso o cidadão opte por não compartilhar os seus dados de atendimento, basta que ele solicite o
            bloqueio do compartilhamento por meio da atualização do seu cadastro (no módulo Cidadão). Nesses casos, o
            profissional, sempre que possível, deve esclarecer os benefícios de compartilhar os dados de atendimentos
            por meio do Sistema e-SUS APS.
          </p>
          <br />
          <p>
            As informações a serem compartilhadas referem-se às registradas durante o atendimento a um cidadão,
            incluindo, tanto as informações do USUÁRIO do sistema, que se remetem ao contexto do atendimento, quanto a
            qualquer outra que seja disponibilizada de acordo com as ferramentas oferecidas pelo Sistema e-SUS APS.
          </p>
          <br />
          <p>
            O Sistema e-SUS APS mantém conduta de respeito à privacidade e, sobretudo, CONFIDENCIALIDADE dos dados dos
            profissionais e cidadãos, nos termos dos códigos de éticas que regulamentam as profissões de saúde, da Lei
            Geral de Proteção de Dados, da Constituição Federal, do Código Penal e de todas as normas brasileiras
            aplicáveis nesse contexto. Recomendamos que o USUÁRIO do sistema, ao acessá-lo, mantenha a conduta dentro
            dos parâmetros legais e éticos, mantendo o máximo de cuidado na proteção dos dados coletados, assim como, na
            privacidade e CONFIDENCIALIDADE dos dados dos cidadãos e dos profissionais aos quais ele tenha acesso.
          </p>
          <br />
          <p>
            <Icon icon='exclamationTriangleFilled' fill='danger' size={1.2} style={{ verticalAlign: 'text-bottom' }} />
            <Text color='alert' fontWeight='bold' style={{ margin: 5 }}>
              ATENÇÃO!
            </Text>
            Lembre-se de que o prontuário pertence ao cidadão. O USUÁRIO do sistema, desde já, fica ciente de que toda a
            informação contida no prontuário eletrônico poderá ser compartilhada nas Redes de Atenção à Saúde que fazem
            o cuidado do cidadão, em especial com a{' '}
            <b>
              <u>equipe de saúde de referência</u>¹
            </b>{' '}
            para a Atenção Primária, por meio do Sistema e-SUS APS, ou ainda via impresso, quando solicitado pelo
            cidadão.
          </p>
          <VFlow style={{ fontSize: '12px' }}>
            <p>
              <br />
              <b>
                <i>
                  1 - Entende-se aqui como equipe de saúde de referência a equipe responsável pela área/território ao
                  qual o cidadão está adstrito, como preconizado pela Política Nacional de Atenção Básica.
                </i>
              </b>
            </p>
          </VFlow>
        </HeadingSection>
        <HeadingSection level={2} color='primary' title='Infraestrutura tecnológica'>
          <p>
            A responsabilidade pela certificação da configuração adequada dos equipamentos, em pleno acordo com
            requisitos mínimos necessários para uso do Sistema e-SUS APS é da gestão no nível municipal. Portanto, não
            cabe ao Ministério da Saúde a responsabilidade pelo funcionamento e desempenho inadequado do Sistema e-SUS
            APS, caso as configurações mínimas recomendadas não sejam atendidas. Para obter mais informações sobre os
            requisitos mínimos, acesse:
            <br />
            <Link target='_blank' href='http://aps.saude.gov.br/ape/esus/download'>
              http://aps.saude.gov.br/ape/esus/download
            </Link>
          </p>
          <br />
          <p>
            <Icon icon='exclamationTriangleFilled' fill='danger' size={1.2} style={{ verticalAlign: 'text-bottom' }} />
            <Text color='alert' fontWeight='bold' style={{ margin: 5 }}>
              ATENÇÃO!
            </Text>
            A integridade da base de dados é de responsabilidade do secretário municipal responsável pela instalação do
            sistema. Para garantir a consistência dos dados e o correto funcionamento do sistema, as informações devem
            ser inseridas ou importadas através do e-SUS APS PEC. A realização de uma cópia de segurança dos dados evita
            a perda completa das informações armazenadas, no caso de qualquer eventualidade, e deve ser realizada
            periodicamente.
            <br />
          </p>
        </HeadingSection>
        <HeadingSection
          level={2}
          color='primary'
          title='Responsabilidade do usuário do sistema e do secretário municipal de saúde'
        >
          <p>Após o LOGIN, todo registro feito pelo usuário do sistema será de sua responsabilidade:</p>
          <br />
          <ul>
            <li>
              O registro e envio dos dados ao SISAB é de responsabilidade do USUÁRIO do sistema. É importante que o
              registro seja fidedigno em relação às ações de saúde executadas, a fim de garantir a qualidade dos dados
              do sistema de informação em saúde;{' '}
            </li>
            <li>
              O USUÁRIO do sistema é responsável pelos resultados obtidos por meio do uso de qualquer ferramenta deste
              software, inclusive aqueles decorrentes do uso indevido e da não execução dos processos complementares que
              garantam a segurança dos registros;
            </li>
            <li>
              Realizar a guarda, proteção e o tratamento dos dados do prontuário eletrônico, seguindo as disposições
              presentes na Lei no. 13.709/2018;
            </li>
            <li>
              Não disponibilizar, emprestar ou permitir a pessoas ou instituições não autorizadas pelo secretário
              municipal de saúde o acesso à base de dados do PEC;
            </li>
            <li>
              Realizar a anonimização e desidentificação dos dados quando estes forem extraídos com a finalidade de
              gerar relatórios gerenciais;
            </li>
            <li>
              Garantir, para fins de estudos por órgãos de pesquisas, a anonimização e desidentificação dos dados
              pessoais, sempre que possível, assim como assegurar os devidos padrões éticos relacionados a estudos e
              pesquisas.
            </li>
          </ul>
        </HeadingSection>

        {faltaAceitarTermos && (
          <Button kind='primary' onClick={handleAceitarTermos}>
            Declaro que li e concordo com os termos de uso
          </Button>
        )}
      </VFlow>
      {faltaAceitarTermos && (
        <FormFooter>
          <Button onClick={onSair}>Sair</Button>
        </FormFooter>
      )}
    </PageContent>
  )
}
