import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import theme from 'config/theme'
import { useCidadaoListingLazyQuery } from 'graphql/hooks.generated'
import React, { useState } from 'react'
import { CidadaoPesquisarForm } from 'view/cidadao/CidadaoPesquisarForm'
import CidadaoPesquisarFormModel, { CidadaoListingFormModel } from 'view/cidadao/CidadaoPesquisarFormModel'

import { UnificacaoCadastroListing } from './UnificacaoCadastroListing'

export function UnificacaoCadastroView() {
  const alert = useAlert()
  const [cidadaosEncontrados, setCidadaosEncontrados] = useState<CidadaoListingFormModel[]>()

  const [executeQuery, { data }] = useCidadaoListingLazyQuery({
    onCompleted: dataCidadaos => {
      if (!dataCidadaos?.cidadaos?.content?.length && !dataCidadaos?.cidadaos?.hasMoreResults) {
        alert('warning', 'Nenhum resultado encontrado')
      }
      if (dataCidadaos.cidadaos.hasMoreResults) {
        alert(
          'info',
          'Foram encontrados muitos cadastros com os dados informados. Refine a busca incluindo mais informações.'
        )
      }
      setCidadaosEncontrados(dataCidadaos?.cidadaos?.content)
    },
  })

  const handleSubmitPesquisa = async (form: CidadaoPesquisarFormModel) => {
    if (!form.nomeCpfCns && !form.dataNascimento && !form.nomeMae && !form.municipio) {
      alert('info', 'Insira algum filtro para realizar a busca')
    } else {
      executeQuery({
        variables: {
          filtro: {
            pageParams: {},
            nomeCnsCpf: form.nomeCpfCns,
            dataNascimento: form.dataNascimento,
            nomeMae: form.nomeMae,
            municipioNascimento: form.municipio?.id,
          },
        },
      })
    }
  }

  const handleClearCidadaosEncontrados = () => {
    setCidadaosEncontrados(null)
  }

  return (
    <>
      <PageHeaderSection title='Unificação de cadastros' />
      <VFlow style={{ borderBottom: `1px solid ${theme.pallete.divider}` }}>
        <PageContainer>
          <CidadaoPesquisarForm executeQuery={executeQuery} handleSubmitPesquisa={handleSubmitPesquisa} />
        </PageContainer>
      </VFlow>
      <PageContent type='transparent' style={{ textAlign: 'center' }}>
        <UnificacaoCadastroListing
          cadastrosEncontrados={cidadaosEncontrados}
          hasMoreResults={data?.cidadaos?.hasMoreResults}
          clearCidadaosEncontrados={handleClearCidadaosEncontrados}
        />
      </PageContent>
    </>
  )
}
