import { DateRangeField as BoldDateRangeField, DateRangeFieldProps as BoldDateRangeFieldProps } from 'bold-ui'
import { Period } from 'graphql/types.generated'
import React from 'react'

import { UseFieldProps, usePecField } from '../final-form/useField'
import { getFieldError } from '../final-form/util'

export type DateRangeFieldProps = UseFieldProps<Period> & Omit<BoldDateRangeFieldProps, 'name'>

export default function DateRangeField(props: DateRangeFieldProps) {
  const { input, meta, ...rest } = usePecField(props)
  const { onFocus, onBlur, ...restInput } = input

  return <BoldDateRangeField error={getFieldError(meta)} {...restInput} {...rest} />
}
