import { AcessoCreate, AcessoCreateVariables, SalvarAcesso, SalvarAcessoVariables } from 'api/schema'
import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { analytics } from 'config/firebase'
import { useMutation, useQuery } from 'graphql/hooks'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

import { ACESSO_CREATE_QUERY } from './AcessoCreateQuery'
import { AcessoForm, AcessoFormModel } from './AcessoForm'
import convertModelToInput from './convertModelToInput'
import { SALVAR_ACESSO_MUTATION } from './SalvarAcessoMutation'

interface UrlParams {
  profissionalId: string
}

export function AcessoCreateView() {
  const alert = useAlert()
  const { match, history } = useRouter<UrlParams>()

  const {
    data: { profissional },
  } = useQuery<AcessoCreate, AcessoCreateVariables>(ACESSO_CREATE_QUERY, {
    variables: { profissionalId: match.params.profissionalId },
  })
  const { data: sessao } = useSession()

  const [salvar] = useMutation<SalvarAcesso, SalvarAcessoVariables>(SALVAR_ACESSO_MUTATION)

  if (!profissional) {
    return null
  }

  const handleSubmit = (values: AcessoFormModel) => {
    const input = convertModelToInput(values)
    const perfis = values.perfis.map(p => p.nome).join(', ')
    analytics.logEvent('cadastro_acesso', { 'Perfis adicionados': perfis })

    return salvar({ variables: { input } })
  }

  const handleSubmitSuccess = () => {
    alert('success', 'Acesso cadastrado com sucesso.')
    history.push(`/profissional/${profissional.id}/dados`)
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${profissional.id}/dados`} />
      <Breadcrumb title='Cadastro de acesso' />

      <VFlow vSpacing={1.5}>
        <PageHeader title='Cadastro de acesso' subtitle={profissional.nome} />
        <AcessoForm
          initialValues={{ profissional: profissional.id, municipio: sessao.acesso.municipio } as any}
          onSubmit={handleSubmit}
          onSubmitSucceeded={handleSubmitSuccess}
        />
      </VFlow>
    </PageContent>
  )
}
