import { Text, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form'
import React from 'react'
import { MetaPath, MetaRoot } from 'util/metaPath'

import CidadaoFormModel from './CidadaoFormModel'

export interface CidadaoDadosFieldGroupProps {
  name: MetaPath<CidadaoFormModel> | MetaRoot<CidadaoFormModel>
}

export function CompartilhamentoProntuarioFieldGroup(props: CidadaoDadosFieldGroupProps) {
  return (
    <VFlow>
      <div style={{ width: 700 }}>
        <Text>
          Por padrão, o prontuário do cidadão é visível por todas as unidades de saúde de uma mesma instalação do e-SUS
          APS, para agilizar o atendimento em toda a rede. Se o cidadão não concorda com essa condição, marque a opção
          abaixo para desativar o compartilhamento de prontuário.
        </Text>
      </div>
      <CheckboxField
        name={props.name.desativarCompartilhamentoProntuario}
        label='Desativar compartilhamento de prontuário para este cidadão'
        style={{ marginTop: '0.25rem' }}
      />
    </VFlow>
  )
}
