import { Theme, useStyles } from 'bold-ui'
import { useScrollPosition } from 'bold-ui/lib/hooks'
import React, { CSSProperties, Ref, useEffect, useRef, useState } from 'react'

import { SideMenu } from './SideMenu'
import { SIDE_MENU_ITEMS } from './SiteMenuItems'

export const SIDE_MENU_WIDTH = 85
export const SIDE_MENU_EXPANDED_WIDTH = 320

export interface SideMenuNavProps {
  open: boolean
  searchInputRef?: Ref<HTMLInputElement>
  onClose(): void
}

export function SideMenuNav(props: SideMenuNavProps) {
  const { open, searchInputRef, onClose } = props
  const [mouseIn, setMouseIn] = useState(false)
  const handleMouseEnter = () => setMouseIn(true)
  const handleMouseLeave = () => setMouseIn(false)

  const [focusIn, setFocusIn] = useState(false)
  const handleFocus = () => setFocusIn(true)
  const handleBlur = () => setFocusIn(false)

  const [expanded, setExpanded] = useState<boolean>()
  useEffect(() => setExpanded(mouseIn || focusIn), [mouseIn, focusIn])

  const handleNavigate = () => onClose()

  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const targetNode = e.target as Node
      if (open && !ref.current.contains(targetNode)) {
        onClose()
      }
    }

    window.addEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [open, onClose])

  const scroll = useScrollPosition()
  const { classes } = useStyles(createStyles, expanded, open)

  return (
    <div
      ref={ref}
      className={classes.wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{ top: Math.max(137 - scroll.scrollY, 0) }}
    >
      <SideMenu
        items={SIDE_MENU_ITEMS}
        expanded={expanded || open}
        searchInputRef={searchInputRef}
        onNavigate={handleNavigate}
      />
    </div>
  )
}

const createStyles = (theme: Theme, expanded: boolean, open: boolean) => ({
  wrapper: {
    position: 'fixed',
    zIndex: 10,
    bottom: 0,
    overflow: 'auto',
    boxShadow: (expanded || open) && theme.shadows.outer[40],

    nav: {
      width: !open && 0,
      [theme.breakpoints.up('lg')]: {
        width: expanded ? SIDE_MENU_EXPANDED_WIDTH : SIDE_MENU_WIDTH,
      },
    },
  } as CSSProperties,
})
