import { Cell, Grid } from 'bold-ui'
import { CheckboxField, TextField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { FormState } from 'final-form'
import { UnidadeSaudeTipoServicoQueryInput } from 'graphql/types.generated'
import React from 'react'
import { Form } from 'react-final-form'

export interface TipoServicoTableFilterProps {
  initialValues?: UnidadeSaudeTipoServicoQueryInput
  onChange(values: UnidadeSaudeTipoServicoQueryInput): void
}

export default function TipoServicoTableFilter(props: TipoServicoTableFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<UnidadeSaudeTipoServicoQueryInput>) => onChange(formState.values)

  const renderForm = () => {
    return (
      <>
        <FormDebouncedValueSpy onChange={handleChange} loadOnMount={false} />

        <Grid alignItems='center' gap={1}>
          <Cell size={4}>
            <TextField name='query' placeholder='Pesquise por tipo de serviço' icon='zoomOutline' />
          </Cell>
          <Cell>
            <CheckboxField name='mostrarInativos' label='Mostrar tipos de serviço inativos' />
          </Cell>
        </Grid>
      </>
    )
  }

  return (
    <Form<UnidadeSaudeTipoServicoQueryInput> onSubmit={onChange} render={renderForm} initialValues={initialValues} />
  )
}
