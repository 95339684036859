import gql from 'graphql-tag'

export const PERFIL_DETAIL_QUERY = gql`
  query PerfilDetailQuery($perfilId: ID!) {
    perfil(id: $perfilId) {
      id
      nome
      tipoPerfil
      recursos
      perfilPadrao
    }
  }
`
