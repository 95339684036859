import { Permission } from 'types/Permissions'

import useSession from './useSession'

interface CheckPermissionProps {
  permission: Permission
}

const CheckPermission = props => {
  const { hasAuthorization } = useSession({ fetchPolicy: 'cache-only' })

  if (hasAuthorization(props.permission)) {
    return props.children
  }

  return null
}

export default CheckPermission
