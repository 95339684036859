import { Heading, HFlow, Icon, Tag, Text, Tooltip } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Box } from 'components/Box'
import LabelItemInativo from 'components/LabelItemInativo'
import { ButtonLink } from 'components/route'
import React from 'react'
import Permissions from 'types/Permissions'

import { LotacaoProfissional } from '../ProfissionalAcessosView'
import { LotacaoAcessoCardDropdown } from './LotacaoAcessoCardDropdown'

export interface LotacaoAcessoCardProps {
  agendaOnlineAtiva: boolean
  lotacao: LotacaoProfissional
  possuiCns: boolean
  refetch(): void
}

export function LotacaoAcessoCard(props: LotacaoAcessoCardProps) {
  const { agendaOnlineAtiva, lotacao, possuiCns, refetch } = props
  const permissaoEdicao = lotacao.actions.permissaoEdicao

  return (
    <Box style={{ height: '100%' }}>
      <div style={{ float: 'right' }}>
        <CheckPermission
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao}
        >
          <Tooltip text={permissaoEdicao.enabled ? 'Editar' : permissaoEdicao.hint}>
            <ButtonLink
              size='small'
              skin='ghost'
              to={`lotacoes/${lotacao.id}/edit`}
              disabled={!permissaoEdicao.enabled}
            >
              <Icon icon='penOutline' />
            </ButtonLink>
          </Tooltip>
        </CheckPermission>
        <LotacaoAcessoCardDropdown
          lotacao={lotacao}
          refetch={refetch}
          agendaOnlineAtiva={agendaOnlineAtiva}
          possuiCns={possuiCns}
        />
      </div>

      <HFlow hSpacing={0.5}>
        <Heading level={3} style={{ marginBottom: '0.5rem' }}>
          {lotacao.unidadeSaude.nome}
        </Heading>
        {lotacao.ativo === false && <Tag>Inativo</Tag>}
      </HFlow>

      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>CBO</Text>
        <Text>
          {lotacao.cbo.nome} - {lotacao.cbo.cbo2002}
        </Text>
      </HFlow>

      {lotacao.equipe && (
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>Equipe</Text>
          <Text>
            {lotacao.equipe.nome} - {lotacao.equipe.ine}
          </Text>
        </HFlow>
      )}

      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>Perfis</Text>
        <div>
          {lotacao.perfis.map((p, index) => (
            <Text key={p.id}>
              {index === 0 ? '' : '; '}
              {p.nome}
              <LabelItemInativo ativo={p.ativo} />
            </Text>
          ))}
        </div>
      </HFlow>
    </Box>
  )
}
