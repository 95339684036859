import {
  DesativarConfiguracaoAgendaOnlineLotacaoMutation,
  DesativarConfiguracaoAgendaOnlineLotacaoMutationVariables,
} from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const DESATIVAR_CONFIGURACAO_AGENDA_ONLINE_LOTACAO_MUTATION = gql`
  mutation DesativarConfiguracaoAgendaOnlineLotacaoMutation($id: ID!) {
    desativarConfiguracaoAgendaOnlineLotacao(id: $id)
  }
`

export function useDesativarConfiguracaoAgendaOnlineLotacao() {
  return useMutation<
    DesativarConfiguracaoAgendaOnlineLotacaoMutation,
    DesativarConfiguracaoAgendaOnlineLotacaoMutationVariables
  >(DESATIVAR_CONFIGURACAO_AGENDA_ONLINE_LOTACAO_MUTATION)
}
