import { TipoRegistroAfetadoComboQuery, TipoRegistroAfetadoComboQuery_auditoriaTipoRegistroAfetado } from 'api/schema'
import { Omit, Text } from 'bold-ui'
import gql from 'graphql-tag'
import { useQuery } from 'graphql/hooks'
import React from 'react'

import { SelectField, SelectFieldProps } from '../../final-form'

export type TipoRegistroSelectModel = TipoRegistroAfetadoComboQuery_auditoriaTipoRegistroAfetado

export interface TipoRegistroProps extends Omit<SelectFieldProps<TipoRegistroSelectModel>, 'items' | 'itemToString'> {}

export interface TipoRegistroAfetadoVariables {}

export function TipoRegistroAfetadoSelectField(props: TipoRegistroProps) {
  const { data } = useQuery<TipoRegistroAfetadoComboQuery>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  if (!data) {
    return null
  }

  const itemToString = (item: TipoRegistroSelectModel) => item && item.nome

  const renderItem = (item: TipoRegistroSelectModel) => {
    return <Text>{item.nome}</Text>
  }
  return (
    <SelectField<TipoRegistroSelectModel>
      items={data.auditoriaTipoRegistroAfetado}
      itemToString={itemToString}
      renderItem={renderItem}
      {...props}
    />
  )
}

const QUERY = gql`
  query TipoRegistroAfetadoComboQuery {
    auditoriaTipoRegistroAfetado {
      id
      nome
    }
  }
`
