import { masks, onlyNumbers } from 'components/label/masks'
import React from 'react'

import { MaskedField, MaskedFieldProps } from '../final-form/MaskedField'

export interface CepFieldProps extends MaskedFieldProps {}

export function CepField(props: CepFieldProps) {
  return <MaskedField mask={masks.cep} placeholder='00000-000' parse={onlyNumbers} {...props} />
}
