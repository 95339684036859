import { Button, ButtonProps, Theme, useStyles } from 'bold-ui'
import { PopperHookOptions, useClickOutside, usePopper } from 'bold-ui/lib/hooks'
import React, { CSSProperties, useCallback, useRef, useState } from 'react'

export interface PopperButtonProps extends ButtonProps {
  placement?: PopperHookOptions['placement']
  renderPopper: (controls: PopperControls) => React.ReactNode
  zIndex?: number
}

export interface PopperControls {
  open(): void
  close(): void
  toggle(): void
}

export function PopperButton(props: PopperButtonProps) {
  const { placement, renderPopper, zIndex, ...buttonProps } = props

  const [isOpen, setOpen] = useState(false)
  const open = useCallback(() => setOpen(true), [])
  const close = useCallback(() => setOpen(false), [])
  const toggle = useCallback(() => setOpen(state => !state), [])

  const { css, classes } = useStyles(createStyles, { isOpen, zIndex })

  const rootRef = useRef<HTMLDivElement>()
  const buttonRef = useRef<HTMLButtonElement>()
  const popperRef = useRef<HTMLDivElement>()

  useClickOutside(rootRef, () => setOpen(false))

  const { style: popperStyle } = usePopper(
    {
      anchorRef: buttonRef,
      popperRef,
      placement,
    },
    [isOpen]
  )

  return (
    <div ref={rootRef}>
      <Button innerRef={buttonRef} style={classes.button} onClick={toggle} {...buttonProps} />

      <div
        ref={popperRef}
        className={css(classes.popper, popperStyle)}
        data-placement={placement}
        data-visible={isOpen}
      >
        {renderPopper({ open, close, toggle })}
      </div>
    </div>
  )
}

PopperButton.defaultProps = {
  placement: 'bottom',
} as PopperButtonProps

const createStyles = (theme: Theme, { isOpen, zIndex }) => ({
  popper: {
    zIndex: zIndex ? zIndex : theme.zIndex.popper,
    visibility: isOpen ? 'visible' : 'hidden',
    transition: 'opacity .2s ease',
    opacity: isOpen ? 1 : 0,
  } as CSSProperties,
  button: {} as CSSProperties,
})
