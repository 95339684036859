import {
  EquipesConnectionInput,
  EquipeTableQuery,
  EquipeTableQuery_unidadeSaude_equipes_content,
  EquipeTableQueryVariables,
} from 'api/schema'
import { HFlow, Icon, PagedTable, Tag, Text, Tooltip } from 'bold-ui'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useQuery } from 'graphql/hooks'
import { useRouter } from 'hooks/useRouter'
import React, { useState } from 'react'
import { EquipeTableFilter } from 'view/equipe/list/EquipeTableFilter'

import { EQUIPE_TABLE_QUERY } from './EquipeTableQuery'

export type ItemType = EquipeTableQuery_unidadeSaude_equipes_content

export interface EquipeTableProps {
  unidadeSaudeId: ID
}

export function EquipeTable(props: EquipeTableProps) {
  const { unidadeSaudeId } = props
  const { match } = useRouter()

  const [filter, setFilter] = useState<EquipesConnectionInput>({
    mostrarInativas: false,
    pageParams: {
      sort: ['nome'],
    },
  })

  const {
    data: { unidadeSaude },
    loading,
  } = useQuery<EquipeTableQuery, EquipeTableQueryVariables>(EQUIPE_TABLE_QUERY, {
    variables: { unidadeSaudeId, input: filter },
  })

  const renderNome = (row: ItemType) => {
    return (
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text>{row.nome}</Text>
        {!row.ativo && <Tag>Inativa</Tag>}
      </HFlow>
    )
  }

  const renderIne = (row: ItemType) => {
    return <Text>{row.ine}</Text>
  }

  const renderArea = (row: ItemType) => {
    return <Text>{row.area}</Text>
  }

  const renderTipoEquipe = (row: ItemType) => {
    return <Text>{row.tipoEquipe.sigla + ' - ' + row.tipoEquipe.nome}</Text>
  }

  const renderButtons = (row: ItemType) => {
    return (
      <Tooltip text='Visualizar'>
        <ButtonLink to={`${match.url}/${row.id}`} size='small' skin='ghost'>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </Tooltip>
    )
  }

  const tableProps = usePagedTableProps({
    loading,
    onChange: setFilter,
    result: unidadeSaude && unidadeSaude.equipes,
  })

  return (
    <TableBox header={<EquipeTableFilter initialFilter={filter} onChange={setFilter} />}>
      <PagedTable<ItemType>
        {...tableProps}
        columns={[
          { name: 'nome', header: 'Nome', render: renderNome, sortable: true },
          { name: 'ine', header: 'INE', render: renderIne },
          { name: 'area', header: 'Área', render: renderArea },
          { name: 'tipoEquipe', header: 'Tipo de equipe', render: renderTipoEquipe },
          { name: 'buttons', render: renderButtons, style: { textAlign: 'right' } },
        ]}
      />
    </TableBox>
  )
}
