import * as Types from 'graphql/types.generated'

import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as ApolloReactHooks from 'graphql/hooks'

export const AcessoAdministradorMunicipalFragmentDoc = gql`
  fragment AcessoAdministradorMunicipal on AdministradorMunicipal {
    municipio {
      id
      nome
    }
  }
`
export const AcessoGestorEstadualFragmentDoc = gql`
  fragment AcessoGestorEstadual on GestorEstadual {
    uf {
      id
      nome
    }
  }
`
export const AcessoGestorMunicipalFragmentDoc = gql`
  fragment AcessoGestorMunicipal on GestorMunicipal {
    municipio {
      id
      nome
    }
  }
`
export const AcessoLotacaoFragmentDoc = gql`
  fragment AcessoLotacao on Lotacao {
    cbo {
      id
      nome
      cbo2002
    }
    equipe {
      id
      nome
      ine
    }
    unidadeSaude {
      id
      nome
    }
  }
`
export const SessaoDataFragmentDoc = gql`
  fragment SessaoData on Sessao {
    id
    timeout
    recursos
    acesso {
      id
      tipo
      moduloInicial
      municipio {
        id
        nome
      }
      perfis {
        id
        nome
      }
      ... on AdministradorMunicipal {
        autorizado
        habilitado
      }
      ... on Lotacao {
        unidadeSaude {
          id
          nome
        }
        equipe {
          id
          nome
          ine
        }
        cbo {
          id
          nome
        }
      }
    }
    profissional {
      id
      cns
      nome
      usuario {
        id
        aceitouTermosUso
        trocarSenha
        visualizouNovidades
        mostrarPesquisaSatisfacao
        hashId
      }
      acessos {
        id
        tipo
        ... on AdministradorMunicipal {
          ...AcessoAdministradorMunicipal
        }
        ... on GestorEstadual {
          ...AcessoGestorEstadual
        }
        ... on GestorMunicipal {
          ...AcessoGestorMunicipal
        }
        ... on Lotacao {
          ...AcessoLotacao
        }
      }
    }
  }
  ${AcessoAdministradorMunicipalFragmentDoc}
  ${AcessoGestorEstadualFragmentDoc}
  ${AcessoGestorMunicipalFragmentDoc}
  ${AcessoLotacaoFragmentDoc}
`
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    number
    size
    totalPages
    totalElements
    sort
    first
    last
    numberOfElements
  }
`
export const ProfissionalDetailFragmentDoc = gql`
  fragment ProfissionalDetail on Profissional {
    cns
    conselhoClasse {
      id
      descricao
      sigla
    }
    cpf
    dataNascimento
    email
    endereco {
      bairro
      cep
      complemento
      logradouro
      numero
      pontoReferencia
      municipio {
        id
        ibge
        nome
        uf {
          id
          nome
          sigla
        }
      }
      semNumero
      tipoLogradouro {
        id
        nome
        numeroDne
      }
      uf {
        id
        nome
        sigla
      }
    }
    id
    nome
    numeroConselhoClasse
    sexo
    telefone
    ufEmissoraConselhoClasse {
      id
      nome
      sigla
    }
  }
`
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      ...SessaoData
    }
  }
  ${SessaoDataFragmentDoc}
`
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<Types.LogoutMutation, Types.LogoutMutationVariables>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.LogoutMutation, Types.LogoutMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.LogoutMutation, Types.LogoutMutationVariables>(LogoutDocument, baseOptions)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = ApolloReactCommon.MutationResult<Types.LogoutMutation>
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.LogoutMutation,
  Types.LogoutMutationVariables
>
export const SessaoDocument = gql`
  query Sessao {
    sessao {
      ...SessaoData
    }
  }
  ${SessaoDataFragmentDoc}
`

/**
 * __useSessaoQuery__
 *
 * To run a query within a React component, call `useSessaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessaoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.SessaoQuery, Types.SessaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.SessaoQuery, Types.SessaoQueryVariables>(SessaoDocument, baseOptions)
}
export function useSessaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.SessaoQuery, Types.SessaoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.SessaoQuery, Types.SessaoQueryVariables>(SessaoDocument, baseOptions)
}
export type SessaoQueryHookResult = ReturnType<typeof useSessaoQuery>
export type SessaoLazyQueryHookResult = ReturnType<typeof useSessaoLazyQuery>
export type SessaoQueryResult = ApolloReactCommon.QueryResult<Types.SessaoQuery, Types.SessaoQueryVariables>
export const EsusViewMountTargetDocument = gql`
  query EsusViewMountTarget {
    info {
      oldPecConnected
    }
  }
`

/**
 * __useEsusViewMountTargetQuery__
 *
 * To run a query within a React component, call `useEsusViewMountTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsusViewMountTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsusViewMountTargetQuery({
 *   variables: {
 *   },
 * });
 */
export function useEsusViewMountTargetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.EsusViewMountTargetQuery,
    Types.EsusViewMountTargetQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.EsusViewMountTargetQuery, Types.EsusViewMountTargetQueryVariables>(
    EsusViewMountTargetDocument,
    baseOptions
  )
}
export function useEsusViewMountTargetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EsusViewMountTargetQuery,
    Types.EsusViewMountTargetQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EsusViewMountTargetQuery, Types.EsusViewMountTargetQueryVariables>(
    EsusViewMountTargetDocument,
    baseOptions
  )
}
export type EsusViewMountTargetQueryHookResult = ReturnType<typeof useEsusViewMountTargetQuery>
export type EsusViewMountTargetLazyQueryHookResult = ReturnType<typeof useEsusViewMountTargetLazyQuery>
export type EsusViewMountTargetQueryResult = ApolloReactCommon.QueryResult<
  Types.EsusViewMountTargetQuery,
  Types.EsusViewMountTargetQueryVariables
>
export const EnderecoDocument = gql`
  query Endereco($cep: String!) {
    endereco(cep: $cep) {
      municipio {
        id
        nome
      }
      uf {
        id
        nome
        sigla
      }
      logradouro
      tipoLogradouro {
        id
        nome
      }
      bairro {
        id
        nome
      }
    }
  }
`

/**
 * __useEnderecoQuery__
 *
 * To run a query within a React component, call `useEnderecoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnderecoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnderecoQuery({
 *   variables: {
 *      cep: // value for 'cep'
 *   },
 * });
 */
export function useEnderecoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EnderecoQuery, Types.EnderecoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EnderecoQuery, Types.EnderecoQueryVariables>(EnderecoDocument, baseOptions)
}
export function useEnderecoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EnderecoQuery, Types.EnderecoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EnderecoQuery, Types.EnderecoQueryVariables>(EnderecoDocument, baseOptions)
}
export type EnderecoQueryHookResult = ReturnType<typeof useEnderecoQuery>
export type EnderecoLazyQueryHookResult = ReturnType<typeof useEnderecoLazyQuery>
export type EnderecoQueryResult = ApolloReactCommon.QueryResult<Types.EnderecoQuery, Types.EnderecoQueryVariables>
export const BairroSelectFieldDocument = gql`
  query BairroSelectField($input: BairroInput!) {
    bairros(input: $input) {
      content {
        id
        nome
        municipio {
          id
          nome
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useBairroSelectFieldQuery__
 *
 * To run a query within a React component, call `useBairroSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useBairroSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBairroSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBairroSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BairroSelectFieldQuery, Types.BairroSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BairroSelectFieldQuery, Types.BairroSelectFieldQueryVariables>(
    BairroSelectFieldDocument,
    baseOptions
  )
}
export function useBairroSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BairroSelectFieldQuery,
    Types.BairroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BairroSelectFieldQuery, Types.BairroSelectFieldQueryVariables>(
    BairroSelectFieldDocument,
    baseOptions
  )
}
export type BairroSelectFieldQueryHookResult = ReturnType<typeof useBairroSelectFieldQuery>
export type BairroSelectFieldLazyQueryHookResult = ReturnType<typeof useBairroSelectFieldLazyQuery>
export type BairroSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.BairroSelectFieldQuery,
  Types.BairroSelectFieldQueryVariables
>
export const CboSelectFieldDocument = gql`
  query CboSelectField($input: CbosMunicipioQueryInput!) {
    cbos(input: $input) {
      content {
        cbo {
          id
          nome
          cbo2002
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCboSelectFieldQuery__
 *
 * To run a query within a React component, call `useCboSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCboSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCboSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCboSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CboSelectFieldQuery, Types.CboSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CboSelectFieldQuery, Types.CboSelectFieldQueryVariables>(
    CboSelectFieldDocument,
    baseOptions
  )
}
export function useCboSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CboSelectFieldQuery, Types.CboSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CboSelectFieldQuery, Types.CboSelectFieldQueryVariables>(
    CboSelectFieldDocument,
    baseOptions
  )
}
export type CboSelectFieldQueryHookResult = ReturnType<typeof useCboSelectFieldQuery>
export type CboSelectFieldLazyQueryHookResult = ReturnType<typeof useCboSelectFieldLazyQuery>
export type CboSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CboSelectFieldQuery,
  Types.CboSelectFieldQueryVariables
>
export const CiapCid10SelectFieldDocument = gql`
  query CiapCid10SelectField($inputCiap: CiapQueryInput!, $inputCid10: Cid10QueryInput!) {
    ciaps(input: $inputCiap) {
      content {
        id
        codigo
        nome: descricao
      }
      pageInfo {
        ...PageInfo
      }
    }
    cids(input: $inputCid10) {
      content {
        id
        codigo
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCiapCid10SelectFieldQuery__
 *
 * To run a query within a React component, call `useCiapCid10SelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCiapCid10SelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCiapCid10SelectFieldQuery({
 *   variables: {
 *      inputCiap: // value for 'inputCiap'
 *      inputCid10: // value for 'inputCid10'
 *   },
 * });
 */
export function useCiapCid10SelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CiapCid10SelectFieldQuery,
    Types.CiapCid10SelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.CiapCid10SelectFieldQuery, Types.CiapCid10SelectFieldQueryVariables>(
    CiapCid10SelectFieldDocument,
    baseOptions
  )
}
export function useCiapCid10SelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CiapCid10SelectFieldQuery,
    Types.CiapCid10SelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.CiapCid10SelectFieldQuery, Types.CiapCid10SelectFieldQueryVariables>(
    CiapCid10SelectFieldDocument,
    baseOptions
  )
}
export type CiapCid10SelectFieldQueryHookResult = ReturnType<typeof useCiapCid10SelectFieldQuery>
export type CiapCid10SelectFieldLazyQueryHookResult = ReturnType<typeof useCiapCid10SelectFieldLazyQuery>
export type CiapCid10SelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.CiapCid10SelectFieldQuery,
  Types.CiapCid10SelectFieldQueryVariables
>
export const ConselhoClasseSelectDocument = gql`
  query ConselhoClasseSelect($input: ConselhoClasseQueryInput!) {
    conselhosClasse(input: $input) {
      content {
        id
        descricao
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useConselhoClasseSelectQuery__
 *
 * To run a query within a React component, call `useConselhoClasseSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useConselhoClasseSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConselhoClasseSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConselhoClasseSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConselhoClasseSelectQuery,
    Types.ConselhoClasseSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConselhoClasseSelectQuery, Types.ConselhoClasseSelectQueryVariables>(
    ConselhoClasseSelectDocument,
    baseOptions
  )
}
export function useConselhoClasseSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConselhoClasseSelectQuery,
    Types.ConselhoClasseSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConselhoClasseSelectQuery, Types.ConselhoClasseSelectQueryVariables>(
    ConselhoClasseSelectDocument,
    baseOptions
  )
}
export type ConselhoClasseSelectQueryHookResult = ReturnType<typeof useConselhoClasseSelectQuery>
export type ConselhoClasseSelectLazyQueryHookResult = ReturnType<typeof useConselhoClasseSelectLazyQuery>
export type ConselhoClasseSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.ConselhoClasseSelectQuery,
  Types.ConselhoClasseSelectQueryVariables
>
export const EquipeSelectFieldDocument = gql`
  query EquipeSelectField($unidadeSaudeId: ID!, $input: EquipesConnectionInput!) {
    unidadeSaude(id: $unidadeSaudeId) {
      id
      equipes(input: $input) {
        content {
          id
          nome
          ine
          tipoEquipe {
            id
            nome
            sigla
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEquipeSelectFieldQuery__
 *
 * To run a query within a React component, call `useEquipeSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipeSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipeSelectFieldQuery({
 *   variables: {
 *      unidadeSaudeId: // value for 'unidadeSaudeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipeSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipeSelectFieldQuery, Types.EquipeSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EquipeSelectFieldQuery, Types.EquipeSelectFieldQueryVariables>(
    EquipeSelectFieldDocument,
    baseOptions
  )
}
export function useEquipeSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.EquipeSelectFieldQuery,
    Types.EquipeSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipeSelectFieldQuery, Types.EquipeSelectFieldQueryVariables>(
    EquipeSelectFieldDocument,
    baseOptions
  )
}
export type EquipeSelectFieldQueryHookResult = ReturnType<typeof useEquipeSelectFieldQuery>
export type EquipeSelectFieldLazyQueryHookResult = ReturnType<typeof useEquipeSelectFieldLazyQuery>
export type EquipeSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EquipeSelectFieldQuery,
  Types.EquipeSelectFieldQueryVariables
>
export const EtniaSelectFieldDocument = gql`
  query EtniaSelectField($input: EtniaQueryInput!) {
    etnias(input: $input) {
      content {
        id
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEtniaSelectFieldQuery__
 *
 * To run a query within a React component, call `useEtniaSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useEtniaSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEtniaSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEtniaSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EtniaSelectFieldQuery, Types.EtniaSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EtniaSelectFieldQuery, Types.EtniaSelectFieldQueryVariables>(
    EtniaSelectFieldDocument,
    baseOptions
  )
}
export function useEtniaSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EtniaSelectFieldQuery, Types.EtniaSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EtniaSelectFieldQuery, Types.EtniaSelectFieldQueryVariables>(
    EtniaSelectFieldDocument,
    baseOptions
  )
}
export type EtniaSelectFieldQueryHookResult = ReturnType<typeof useEtniaSelectFieldQuery>
export type EtniaSelectFieldLazyQueryHookResult = ReturnType<typeof useEtniaSelectFieldLazyQuery>
export type EtniaSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.EtniaSelectFieldQuery,
  Types.EtniaSelectFieldQueryVariables
>
export const GrupoCondicaoSelectFieldDocument = gql`
  query GrupoCondicaoSelectField($inputGrupo: GrupoCondicaoQueryInput!) {
    grupos(input: $inputGrupo) {
      content {
        id
        grupoCondicao
        ciaps {
          id
          codigo
          nome: descricao
        }
        cids {
          id
          codigo
          nome
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useGrupoCondicaoSelectFieldQuery__
 *
 * To run a query within a React component, call `useGrupoCondicaoSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrupoCondicaoSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrupoCondicaoSelectFieldQuery({
 *   variables: {
 *      inputGrupo: // value for 'inputGrupo'
 *   },
 * });
 */
export function useGrupoCondicaoSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GrupoCondicaoSelectFieldQuery,
    Types.GrupoCondicaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.GrupoCondicaoSelectFieldQuery, Types.GrupoCondicaoSelectFieldQueryVariables>(
    GrupoCondicaoSelectFieldDocument,
    baseOptions
  )
}
export function useGrupoCondicaoSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GrupoCondicaoSelectFieldQuery,
    Types.GrupoCondicaoSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.GrupoCondicaoSelectFieldQuery,
    Types.GrupoCondicaoSelectFieldQueryVariables
  >(GrupoCondicaoSelectFieldDocument, baseOptions)
}
export type GrupoCondicaoSelectFieldQueryHookResult = ReturnType<typeof useGrupoCondicaoSelectFieldQuery>
export type GrupoCondicaoSelectFieldLazyQueryHookResult = ReturnType<typeof useGrupoCondicaoSelectFieldLazyQuery>
export type GrupoCondicaoSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.GrupoCondicaoSelectFieldQuery,
  Types.GrupoCondicaoSelectFieldQueryVariables
>
export const LogradouroSelectFieldDocument = gql`
  query LogradouroSelectField($input: LogradouroInput!) {
    logradouros(input: $input) {
      content {
        id
        nome
        nomeExibicao
        cep
        bairro {
          id
          nome
          municipio {
            id
            nome
            uf {
              id
              sigla
            }
          }
        }
        tipoLogradouro {
          id
          nome
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLogradouroSelectFieldQuery__
 *
 * To run a query within a React component, call `useLogradouroSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogradouroSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogradouroSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogradouroSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LogradouroSelectFieldQuery,
    Types.LogradouroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.LogradouroSelectFieldQuery, Types.LogradouroSelectFieldQueryVariables>(
    LogradouroSelectFieldDocument,
    baseOptions
  )
}
export function useLogradouroSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LogradouroSelectFieldQuery,
    Types.LogradouroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.LogradouroSelectFieldQuery, Types.LogradouroSelectFieldQueryVariables>(
    LogradouroSelectFieldDocument,
    baseOptions
  )
}
export type LogradouroSelectFieldQueryHookResult = ReturnType<typeof useLogradouroSelectFieldQuery>
export type LogradouroSelectFieldLazyQueryHookResult = ReturnType<typeof useLogradouroSelectFieldLazyQuery>
export type LogradouroSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.LogradouroSelectFieldQuery,
  Types.LogradouroSelectFieldQueryVariables
>
export const LotacaoProfissionalSelectDocument = gql`
  query LotacaoProfissionalSelect($input: LotacoesInput!) {
    lotacoes(input: $input) {
      content {
        id
        cbo {
          id
          nome
          cbo2002
        }
        equipe {
          id
          nome
          ine
        }
        unidadeSaude {
          id
          nome
          cnes
        }
        profissional {
          id
          nome
          cns
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLotacaoProfissionalSelectQuery__
 *
 * To run a query within a React component, call `useLotacaoProfissionalSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacaoProfissionalSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacaoProfissionalSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLotacaoProfissionalSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LotacaoProfissionalSelectQuery,
    Types.LotacaoProfissionalSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.LotacaoProfissionalSelectQuery, Types.LotacaoProfissionalSelectQueryVariables>(
    LotacaoProfissionalSelectDocument,
    baseOptions
  )
}
export function useLotacaoProfissionalSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LotacaoProfissionalSelectQuery,
    Types.LotacaoProfissionalSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LotacaoProfissionalSelectQuery,
    Types.LotacaoProfissionalSelectQueryVariables
  >(LotacaoProfissionalSelectDocument, baseOptions)
}
export type LotacaoProfissionalSelectQueryHookResult = ReturnType<typeof useLotacaoProfissionalSelectQuery>
export type LotacaoProfissionalSelectLazyQueryHookResult = ReturnType<typeof useLotacaoProfissionalSelectLazyQuery>
export type LotacaoProfissionalSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacaoProfissionalSelectQuery,
  Types.LotacaoProfissionalSelectQueryVariables
>
export const MunicipioSelectFieldDocument = gql`
  query MunicipioSelectField($input: MunicipiosQueryInput!) {
    municipios(input: $input) {
      content {
        id
        nome
        uf {
          id
          nome
          sigla
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMunicipioSelectFieldQuery__
 *
 * To run a query within a React component, call `useMunicipioSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMunicipioSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMunicipioSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMunicipioSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MunicipioSelectFieldQuery,
    Types.MunicipioSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.MunicipioSelectFieldQuery, Types.MunicipioSelectFieldQueryVariables>(
    MunicipioSelectFieldDocument,
    baseOptions
  )
}
export function useMunicipioSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MunicipioSelectFieldQuery,
    Types.MunicipioSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.MunicipioSelectFieldQuery, Types.MunicipioSelectFieldQueryVariables>(
    MunicipioSelectFieldDocument,
    baseOptions
  )
}
export type MunicipioSelectFieldQueryHookResult = ReturnType<typeof useMunicipioSelectFieldQuery>
export type MunicipioSelectFieldLazyQueryHookResult = ReturnType<typeof useMunicipioSelectFieldLazyQuery>
export type MunicipioSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.MunicipioSelectFieldQuery,
  Types.MunicipioSelectFieldQueryVariables
>
export const PaisSelectFieldDocument = gql`
  query PaisSelectField($input: PaisQueryInput!) {
    paises(input: $input) {
      content {
        id
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __usePaisSelectFieldQuery__
 *
 * To run a query within a React component, call `usePaisSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaisSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaisSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaisSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PaisSelectFieldQuery, Types.PaisSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PaisSelectFieldQuery, Types.PaisSelectFieldQueryVariables>(
    PaisSelectFieldDocument,
    baseOptions
  )
}
export function usePaisSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PaisSelectFieldQuery, Types.PaisSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PaisSelectFieldQuery, Types.PaisSelectFieldQueryVariables>(
    PaisSelectFieldDocument,
    baseOptions
  )
}
export type PaisSelectFieldQueryHookResult = ReturnType<typeof usePaisSelectFieldQuery>
export type PaisSelectFieldLazyQueryHookResult = ReturnType<typeof usePaisSelectFieldLazyQuery>
export type PaisSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.PaisSelectFieldQuery,
  Types.PaisSelectFieldQueryVariables
>
export const PerfilSelectFieldDocument = gql`
  query PerfilSelectField($input: PerfisQueryInput!) {
    perfis(input: $input) {
      content {
        id
        nome
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __usePerfilSelectFieldQuery__
 *
 * To run a query within a React component, call `usePerfilSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerfilSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerfilSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerfilSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PerfilSelectFieldQuery, Types.PerfilSelectFieldQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PerfilSelectFieldQuery, Types.PerfilSelectFieldQueryVariables>(
    PerfilSelectFieldDocument,
    baseOptions
  )
}
export function usePerfilSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.PerfilSelectFieldQuery,
    Types.PerfilSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.PerfilSelectFieldQuery, Types.PerfilSelectFieldQueryVariables>(
    PerfilSelectFieldDocument,
    baseOptions
  )
}
export type PerfilSelectFieldQueryHookResult = ReturnType<typeof usePerfilSelectFieldQuery>
export type PerfilSelectFieldLazyQueryHookResult = ReturnType<typeof usePerfilSelectFieldLazyQuery>
export type PerfilSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.PerfilSelectFieldQuery,
  Types.PerfilSelectFieldQueryVariables
>
export const ProfissionaisRelatorioInconsistenciaDocument = gql`
  query ProfissionaisRelatorioInconsistencia($filtro: ProfissionaisRelatorioInconsistenciaQueryInput!) {
    profissionaisRelatorioInconsistencia(filtro: $filtro) {
      content {
        responsavel
        cpfResponsavel
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useProfissionaisRelatorioInconsistenciaQuery__
 *
 * To run a query within a React component, call `useProfissionaisRelatorioInconsistenciaQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionaisRelatorioInconsistenciaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionaisRelatorioInconsistenciaQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useProfissionaisRelatorioInconsistenciaQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionaisRelatorioInconsistenciaQuery,
    Types.ProfissionaisRelatorioInconsistenciaQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ProfissionaisRelatorioInconsistenciaQuery,
    Types.ProfissionaisRelatorioInconsistenciaQueryVariables
  >(ProfissionaisRelatorioInconsistenciaDocument, baseOptions)
}
export function useProfissionaisRelatorioInconsistenciaLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionaisRelatorioInconsistenciaQuery,
    Types.ProfissionaisRelatorioInconsistenciaQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ProfissionaisRelatorioInconsistenciaQuery,
    Types.ProfissionaisRelatorioInconsistenciaQueryVariables
  >(ProfissionaisRelatorioInconsistenciaDocument, baseOptions)
}
export type ProfissionaisRelatorioInconsistenciaQueryHookResult = ReturnType<
  typeof useProfissionaisRelatorioInconsistenciaQuery
>
export type ProfissionaisRelatorioInconsistenciaLazyQueryHookResult = ReturnType<
  typeof useProfissionaisRelatorioInconsistenciaLazyQuery
>
export type ProfissionaisRelatorioInconsistenciaQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionaisRelatorioInconsistenciaQuery,
  Types.ProfissionaisRelatorioInconsistenciaQueryVariables
>
export const ProfissionalSelectDocument = gql`
  query ProfissionalSelect($input: ProfissionaisQueryInput!) {
    profissionais(input: $input) {
      content {
        id
        nome
        cpf
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useProfissionalSelectQuery__
 *
 * To run a query within a React component, call `useProfissionalSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfissionalSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfissionalSelectQuery, Types.ProfissionalSelectQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalSelectQuery, Types.ProfissionalSelectQueryVariables>(
    ProfissionalSelectDocument,
    baseOptions
  )
}
export function useProfissionalSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalSelectQuery,
    Types.ProfissionalSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalSelectQuery, Types.ProfissionalSelectQueryVariables>(
    ProfissionalSelectDocument,
    baseOptions
  )
}
export type ProfissionalSelectQueryHookResult = ReturnType<typeof useProfissionalSelectQuery>
export type ProfissionalSelectLazyQueryHookResult = ReturnType<typeof useProfissionalSelectLazyQuery>
export type ProfissionalSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalSelectQuery,
  Types.ProfissionalSelectQueryVariables
>
export const TipoLogradouroSelectFieldDocument = gql`
  query TipoLogradouroSelectField($input: TipoLogradouroInput!) {
    tiposLogradouro(input: $input) {
      content {
        id
        nome
        numeroDne
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTipoLogradouroSelectFieldQuery__
 *
 * To run a query within a React component, call `useTipoLogradouroSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoLogradouroSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoLogradouroSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTipoLogradouroSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TipoLogradouroSelectFieldQuery,
    Types.TipoLogradouroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.TipoLogradouroSelectFieldQuery, Types.TipoLogradouroSelectFieldQueryVariables>(
    TipoLogradouroSelectFieldDocument,
    baseOptions
  )
}
export function useTipoLogradouroSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TipoLogradouroSelectFieldQuery,
    Types.TipoLogradouroSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TipoLogradouroSelectFieldQuery,
    Types.TipoLogradouroSelectFieldQueryVariables
  >(TipoLogradouroSelectFieldDocument, baseOptions)
}
export type TipoLogradouroSelectFieldQueryHookResult = ReturnType<typeof useTipoLogradouroSelectFieldQuery>
export type TipoLogradouroSelectFieldLazyQueryHookResult = ReturnType<typeof useTipoLogradouroSelectFieldLazyQuery>
export type TipoLogradouroSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoLogradouroSelectFieldQuery,
  Types.TipoLogradouroSelectFieldQueryVariables
>
export const UnidadesSaudeSelectFieldDocument = gql`
  query UnidadesSaudeSelectField($input: UnidadesSaudeQueryInput!) {
    unidadesSaude(input: $input) {
      content {
        id
        cnes
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useUnidadesSaudeSelectFieldQuery__
 *
 * To run a query within a React component, call `useUnidadesSaudeSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadesSaudeSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadesSaudeSelectFieldQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadesSaudeSelectFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadesSaudeSelectFieldQuery,
    Types.UnidadesSaudeSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UnidadesSaudeSelectFieldQuery, Types.UnidadesSaudeSelectFieldQueryVariables>(
    UnidadesSaudeSelectFieldDocument,
    baseOptions
  )
}
export function useUnidadesSaudeSelectFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadesSaudeSelectFieldQuery,
    Types.UnidadesSaudeSelectFieldQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.UnidadesSaudeSelectFieldQuery,
    Types.UnidadesSaudeSelectFieldQueryVariables
  >(UnidadesSaudeSelectFieldDocument, baseOptions)
}
export type UnidadesSaudeSelectFieldQueryHookResult = ReturnType<typeof useUnidadesSaudeSelectFieldQuery>
export type UnidadesSaudeSelectFieldLazyQueryHookResult = ReturnType<typeof useUnidadesSaudeSelectFieldLazyQuery>
export type UnidadesSaudeSelectFieldQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadesSaudeSelectFieldQuery,
  Types.UnidadesSaudeSelectFieldQueryVariables
>
export const DimensaoEquipeSelectDocument = gql`
  query DimensaoEquipeSelect($input: DimensaoEquipeQueryInput!) {
    dimensaoEquipe(input: $input) {
      content {
        id
        nome
        ine
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useDimensaoEquipeSelectQuery__
 *
 * To run a query within a React component, call `useDimensaoEquipeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDimensaoEquipeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDimensaoEquipeSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDimensaoEquipeSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DimensaoEquipeSelectQuery,
    Types.DimensaoEquipeSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.DimensaoEquipeSelectQuery, Types.DimensaoEquipeSelectQueryVariables>(
    DimensaoEquipeSelectDocument,
    baseOptions
  )
}
export function useDimensaoEquipeSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DimensaoEquipeSelectQuery,
    Types.DimensaoEquipeSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.DimensaoEquipeSelectQuery, Types.DimensaoEquipeSelectQueryVariables>(
    DimensaoEquipeSelectDocument,
    baseOptions
  )
}
export type DimensaoEquipeSelectQueryHookResult = ReturnType<typeof useDimensaoEquipeSelectQuery>
export type DimensaoEquipeSelectLazyQueryHookResult = ReturnType<typeof useDimensaoEquipeSelectLazyQuery>
export type DimensaoEquipeSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.DimensaoEquipeSelectQuery,
  Types.DimensaoEquipeSelectQueryVariables
>
export const DimensaoLotacaoProfissionalSelectDocument = gql`
  query DimensaoLotacaoProfissionalSelect($input: DimensaoAgrupadorFiltroQueryInput!) {
    dimensaoAgrupadorFiltro(input: $input) {
      content {
        id
        dimensaoCbo {
          id
          nome
          cbo
        }
        dimensaoEquipe {
          id
          nome
          ine
        }
        dimensaoUnidadeSaude {
          id
          nome
          cnes
        }
        dimensaoProfissional {
          id
          nome
          cns
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useDimensaoLotacaoProfissionalSelectQuery__
 *
 * To run a query within a React component, call `useDimensaoLotacaoProfissionalSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDimensaoLotacaoProfissionalSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDimensaoLotacaoProfissionalSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDimensaoLotacaoProfissionalSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DimensaoLotacaoProfissionalSelectQuery,
    Types.DimensaoLotacaoProfissionalSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.DimensaoLotacaoProfissionalSelectQuery,
    Types.DimensaoLotacaoProfissionalSelectQueryVariables
  >(DimensaoLotacaoProfissionalSelectDocument, baseOptions)
}
export function useDimensaoLotacaoProfissionalSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DimensaoLotacaoProfissionalSelectQuery,
    Types.DimensaoLotacaoProfissionalSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DimensaoLotacaoProfissionalSelectQuery,
    Types.DimensaoLotacaoProfissionalSelectQueryVariables
  >(DimensaoLotacaoProfissionalSelectDocument, baseOptions)
}
export type DimensaoLotacaoProfissionalSelectQueryHookResult = ReturnType<
  typeof useDimensaoLotacaoProfissionalSelectQuery
>
export type DimensaoLotacaoProfissionalSelectLazyQueryHookResult = ReturnType<
  typeof useDimensaoLotacaoProfissionalSelectLazyQuery
>
export type DimensaoLotacaoProfissionalSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.DimensaoLotacaoProfissionalSelectQuery,
  Types.DimensaoLotacaoProfissionalSelectQueryVariables
>
export const DimensaoMunicipioSelectDocument = gql`
  query DimensaoMunicipioSelect($input: DimensaoMunicipiosQueryInput!) {
    dimensaoMunicipios(input: $input) {
      content {
        id
        nome
        uf {
          id
          nome
          sigla
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useDimensaoMunicipioSelectQuery__
 *
 * To run a query within a React component, call `useDimensaoMunicipioSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDimensaoMunicipioSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDimensaoMunicipioSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDimensaoMunicipioSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DimensaoMunicipioSelectQuery,
    Types.DimensaoMunicipioSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.DimensaoMunicipioSelectQuery, Types.DimensaoMunicipioSelectQueryVariables>(
    DimensaoMunicipioSelectDocument,
    baseOptions
  )
}
export function useDimensaoMunicipioSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DimensaoMunicipioSelectQuery,
    Types.DimensaoMunicipioSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.DimensaoMunicipioSelectQuery, Types.DimensaoMunicipioSelectQueryVariables>(
    DimensaoMunicipioSelectDocument,
    baseOptions
  )
}
export type DimensaoMunicipioSelectQueryHookResult = ReturnType<typeof useDimensaoMunicipioSelectQuery>
export type DimensaoMunicipioSelectLazyQueryHookResult = ReturnType<typeof useDimensaoMunicipioSelectLazyQuery>
export type DimensaoMunicipioSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.DimensaoMunicipioSelectQuery,
  Types.DimensaoMunicipioSelectQueryVariables
>
export const DimensaoUnidadeSaudeSelectDocument = gql`
  query DimensaoUnidadeSaudeSelect($input: DimensaoUnidadeSaudeQueryInput!) {
    dimensaoUnidadeSaude(input: $input) {
      content {
        id
        nome
        cnes
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useDimensaoUnidadeSaudeSelectQuery__
 *
 * To run a query within a React component, call `useDimensaoUnidadeSaudeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDimensaoUnidadeSaudeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDimensaoUnidadeSaudeSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDimensaoUnidadeSaudeSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.DimensaoUnidadeSaudeSelectQuery,
    Types.DimensaoUnidadeSaudeSelectQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.DimensaoUnidadeSaudeSelectQuery,
    Types.DimensaoUnidadeSaudeSelectQueryVariables
  >(DimensaoUnidadeSaudeSelectDocument, baseOptions)
}
export function useDimensaoUnidadeSaudeSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.DimensaoUnidadeSaudeSelectQuery,
    Types.DimensaoUnidadeSaudeSelectQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.DimensaoUnidadeSaudeSelectQuery,
    Types.DimensaoUnidadeSaudeSelectQueryVariables
  >(DimensaoUnidadeSaudeSelectDocument, baseOptions)
}
export type DimensaoUnidadeSaudeSelectQueryHookResult = ReturnType<typeof useDimensaoUnidadeSaudeSelectQuery>
export type DimensaoUnidadeSaudeSelectLazyQueryHookResult = ReturnType<typeof useDimensaoUnidadeSaudeSelectLazyQuery>
export type DimensaoUnidadeSaudeSelectQueryResult = ApolloReactCommon.QueryResult<
  Types.DimensaoUnidadeSaudeSelectQuery,
  Types.DimensaoUnidadeSaudeSelectQueryVariables
>
export const InstalacaoHeaderDocument = gql`
  query InstalacaoHeader {
    info {
      treinamento
      versao
    }
  }
`

/**
 * __useInstalacaoHeaderQuery__
 *
 * To run a query within a React component, call `useInstalacaoHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstalacaoHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstalacaoHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstalacaoHeaderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.InstalacaoHeaderQuery, Types.InstalacaoHeaderQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.InstalacaoHeaderQuery, Types.InstalacaoHeaderQueryVariables>(
    InstalacaoHeaderDocument,
    baseOptions
  )
}
export function useInstalacaoHeaderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.InstalacaoHeaderQuery, Types.InstalacaoHeaderQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.InstalacaoHeaderQuery, Types.InstalacaoHeaderQueryVariables>(
    InstalacaoHeaderDocument,
    baseOptions
  )
}
export type InstalacaoHeaderQueryHookResult = ReturnType<typeof useInstalacaoHeaderQuery>
export type InstalacaoHeaderLazyQueryHookResult = ReturnType<typeof useInstalacaoHeaderLazyQuery>
export type InstalacaoHeaderQueryResult = ApolloReactCommon.QueryResult<
  Types.InstalacaoHeaderQuery,
  Types.InstalacaoHeaderQueryVariables
>
export const InitialViewDocument = gql`
  query InitialView {
    info {
      ativado
    }
  }
`

/**
 * __useInitialViewQuery__
 *
 * To run a query within a React component, call `useInitialViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitialViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.InitialViewQuery, Types.InitialViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.InitialViewQuery, Types.InitialViewQueryVariables>(
    InitialViewDocument,
    baseOptions
  )
}
export function useInitialViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.InitialViewQuery, Types.InitialViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.InitialViewQuery, Types.InitialViewQueryVariables>(
    InitialViewDocument,
    baseOptions
  )
}
export type InitialViewQueryHookResult = ReturnType<typeof useInitialViewQuery>
export type InitialViewLazyQueryHookResult = ReturnType<typeof useInitialViewLazyQuery>
export type InitialViewQueryResult = ApolloReactCommon.QueryResult<
  Types.InitialViewQuery,
  Types.InitialViewQueryVariables
>
export const BuscaCidadaoListagemDocument = gql`
  query BuscaCidadaoListagem($input: AcompanhamentoCondicaoSaudeQueryInput!) {
    acompanhamentoCondicaoSaude(input: $input) {
      content {
        id
        nome
        nomeSocial
        cpf
        telefoneResidencial
        telefoneCelular
        telefoneContato
        dataUltimoAtendimento
        endereco {
          logradouro
          numero
          complemento
          bairro
          municipio {
            nome
          }
          tipoLogradouro {
            nome
          }
          uf {
            sigla
          }
          cep
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useBuscaCidadaoListagemQuery__
 *
 * To run a query within a React component, call `useBuscaCidadaoListagemQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaCidadaoListagemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaCidadaoListagemQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuscaCidadaoListagemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BuscaCidadaoListagemQuery,
    Types.BuscaCidadaoListagemQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.BuscaCidadaoListagemQuery, Types.BuscaCidadaoListagemQueryVariables>(
    BuscaCidadaoListagemDocument,
    baseOptions
  )
}
export function useBuscaCidadaoListagemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaCidadaoListagemQuery,
    Types.BuscaCidadaoListagemQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BuscaCidadaoListagemQuery, Types.BuscaCidadaoListagemQueryVariables>(
    BuscaCidadaoListagemDocument,
    baseOptions
  )
}
export type BuscaCidadaoListagemQueryHookResult = ReturnType<typeof useBuscaCidadaoListagemQuery>
export type BuscaCidadaoListagemLazyQueryHookResult = ReturnType<typeof useBuscaCidadaoListagemLazyQuery>
export type BuscaCidadaoListagemQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaCidadaoListagemQuery,
  Types.BuscaCidadaoListagemQueryVariables
>
export const BuscaCidadaoRelatoriosOperacionaisDocument = gql`
  query BuscaCidadaoRelatoriosOperacionais($input: AcompanhamentoCondicaoSaudeQueryInput!) {
    acompanhamentoCondicaoSaude(input: $input) {
      content {
        id
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useBuscaCidadaoRelatoriosOperacionaisQuery__
 *
 * To run a query within a React component, call `useBuscaCidadaoRelatoriosOperacionaisQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaCidadaoRelatoriosOperacionaisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaCidadaoRelatoriosOperacionaisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuscaCidadaoRelatoriosOperacionaisQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.BuscaCidadaoRelatoriosOperacionaisQuery,
    Types.BuscaCidadaoRelatoriosOperacionaisQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.BuscaCidadaoRelatoriosOperacionaisQuery,
    Types.BuscaCidadaoRelatoriosOperacionaisQueryVariables
  >(BuscaCidadaoRelatoriosOperacionaisDocument, baseOptions)
}
export function useBuscaCidadaoRelatoriosOperacionaisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaCidadaoRelatoriosOperacionaisQuery,
    Types.BuscaCidadaoRelatoriosOperacionaisQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.BuscaCidadaoRelatoriosOperacionaisQuery,
    Types.BuscaCidadaoRelatoriosOperacionaisQueryVariables
  >(BuscaCidadaoRelatoriosOperacionaisDocument, baseOptions)
}
export type BuscaCidadaoRelatoriosOperacionaisQueryHookResult = ReturnType<
  typeof useBuscaCidadaoRelatoriosOperacionaisQuery
>
export type BuscaCidadaoRelatoriosOperacionaisLazyQueryHookResult = ReturnType<
  typeof useBuscaCidadaoRelatoriosOperacionaisLazyQuery
>
export type BuscaCidadaoRelatoriosOperacionaisQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaCidadaoRelatoriosOperacionaisQuery,
  Types.BuscaCidadaoRelatoriosOperacionaisQueryVariables
>
export const SalvarApoioEmapEmadDocument = gql`
  mutation SalvarApoioEmapEmad($input: ConfigAtendimentoDomInput!) {
    salvarConfigAtendimentoDomTipoApoio(input: $input) {
      id
    }
  }
`
export type SalvarApoioEmapEmadMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarApoioEmapEmadMutation,
  Types.SalvarApoioEmapEmadMutationVariables
>

/**
 * __useSalvarApoioEmapEmadMutation__
 *
 * To run a mutation, you first call `useSalvarApoioEmapEmadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarApoioEmapEmadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarApoioEmapEmadMutation, { data, loading, error }] = useSalvarApoioEmapEmadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarApoioEmapEmadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarApoioEmapEmadMutation,
    Types.SalvarApoioEmapEmadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SalvarApoioEmapEmadMutation, Types.SalvarApoioEmapEmadMutationVariables>(
    SalvarApoioEmapEmadDocument,
    baseOptions
  )
}
export type SalvarApoioEmapEmadMutationHookResult = ReturnType<typeof useSalvarApoioEmapEmadMutation>
export type SalvarApoioEmapEmadMutationResult = ApolloReactCommon.MutationResult<Types.SalvarApoioEmapEmadMutation>
export type SalvarApoioEmapEmadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarApoioEmapEmadMutation,
  Types.SalvarApoioEmapEmadMutationVariables
>
export const SalvarPodeAtenderEmadDocument = gql`
  mutation SalvarPodeAtenderEmad($input: ConfigAtendimentoDomInput!) {
    salvarConfigAtendimentoDomTipoPodeAtender(input: $input) {
      id
    }
  }
`
export type SalvarPodeAtenderEmadMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarPodeAtenderEmadMutation,
  Types.SalvarPodeAtenderEmadMutationVariables
>

/**
 * __useSalvarPodeAtenderEmadMutation__
 *
 * To run a mutation, you first call `useSalvarPodeAtenderEmadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarPodeAtenderEmadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarPodeAtenderEmadMutation, { data, loading, error }] = useSalvarPodeAtenderEmadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarPodeAtenderEmadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarPodeAtenderEmadMutation,
    Types.SalvarPodeAtenderEmadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarPodeAtenderEmadMutation,
    Types.SalvarPodeAtenderEmadMutationVariables
  >(SalvarPodeAtenderEmadDocument, baseOptions)
}
export type SalvarPodeAtenderEmadMutationHookResult = ReturnType<typeof useSalvarPodeAtenderEmadMutation>
export type SalvarPodeAtenderEmadMutationResult = ApolloReactCommon.MutationResult<Types.SalvarPodeAtenderEmadMutation>
export type SalvarPodeAtenderEmadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarPodeAtenderEmadMutation,
  Types.SalvarPodeAtenderEmadMutationVariables
>
export const ConfiguracoesAtencaoDomDocument = gql`
  query ConfiguracoesAtencaoDom($input: ConfiguracoesAtendimentosDomInput) {
    configuracoesAtencaoDom {
      duracao
      emadsAtendemEmads(input: $input) {
        content {
          id
          identificadorNacionalEquipePai {
            id
            nome
            ine
          }
          identificadorNacionalEquipeFilho {
            id
            nome
            ine
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
      emapsApoiamEmads(input: $input) {
        content {
          id
          identificadorNacionalEquipePai {
            id
            nome
            ine
          }
          identificadorNacionalEquipeFilho {
            id
            nome
            ine
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useConfiguracoesAtencaoDomQuery__
 *
 * To run a query within a React component, call `useConfiguracoesAtencaoDomQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracoesAtencaoDomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracoesAtencaoDomQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfiguracoesAtencaoDomQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracoesAtencaoDomQuery,
    Types.ConfiguracoesAtencaoDomQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracoesAtencaoDomQuery, Types.ConfiguracoesAtencaoDomQueryVariables>(
    ConfiguracoesAtencaoDomDocument,
    baseOptions
  )
}
export function useConfiguracoesAtencaoDomLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracoesAtencaoDomQuery,
    Types.ConfiguracoesAtencaoDomQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracoesAtencaoDomQuery, Types.ConfiguracoesAtencaoDomQueryVariables>(
    ConfiguracoesAtencaoDomDocument,
    baseOptions
  )
}
export type ConfiguracoesAtencaoDomQueryHookResult = ReturnType<typeof useConfiguracoesAtencaoDomQuery>
export type ConfiguracoesAtencaoDomLazyQueryHookResult = ReturnType<typeof useConfiguracoesAtencaoDomLazyQuery>
export type ConfiguracoesAtencaoDomQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracoesAtencaoDomQuery,
  Types.ConfiguracoesAtencaoDomQueryVariables
>
export const EquipesAdDocument = gql`
  query EquipesAD($input: EquipeADInput) {
    equipesAD(input: $input) {
      content {
        id
        nome
        ine
        tipoEquipe {
          id
          nome
          sigla
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useEquipesAdQuery__
 *
 * To run a query within a React component, call `useEquipesAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipesAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipesAdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEquipesAdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipesAdQuery, Types.EquipesAdQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.EquipesAdQuery, Types.EquipesAdQueryVariables>(EquipesAdDocument, baseOptions)
}
export function useEquipesAdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EquipesAdQuery, Types.EquipesAdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.EquipesAdQuery, Types.EquipesAdQueryVariables>(
    EquipesAdDocument,
    baseOptions
  )
}
export type EquipesAdQueryHookResult = ReturnType<typeof useEquipesAdQuery>
export type EquipesAdLazyQueryHookResult = ReturnType<typeof useEquipesAdLazyQuery>
export type EquipesAdQueryResult = ApolloReactCommon.QueryResult<Types.EquipesAdQuery, Types.EquipesAdQueryVariables>
export const ExcluirConfigAtendimentoDomDocument = gql`
  mutation ExcluirConfigAtendimentoDom($idConfig: ID!) {
    excluirConfigAtendimentoDom(idConfig: $idConfig)
  }
`
export type ExcluirConfigAtendimentoDomMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirConfigAtendimentoDomMutation,
  Types.ExcluirConfigAtendimentoDomMutationVariables
>

/**
 * __useExcluirConfigAtendimentoDomMutation__
 *
 * To run a mutation, you first call `useExcluirConfigAtendimentoDomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirConfigAtendimentoDomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirConfigAtendimentoDomMutation, { data, loading, error }] = useExcluirConfigAtendimentoDomMutation({
 *   variables: {
 *      idConfig: // value for 'idConfig'
 *   },
 * });
 */
export function useExcluirConfigAtendimentoDomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirConfigAtendimentoDomMutation,
    Types.ExcluirConfigAtendimentoDomMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirConfigAtendimentoDomMutation,
    Types.ExcluirConfigAtendimentoDomMutationVariables
  >(ExcluirConfigAtendimentoDomDocument, baseOptions)
}
export type ExcluirConfigAtendimentoDomMutationHookResult = ReturnType<typeof useExcluirConfigAtendimentoDomMutation>
export type ExcluirConfigAtendimentoDomMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirConfigAtendimentoDomMutation
>
export type ExcluirConfigAtendimentoDomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirConfigAtendimentoDomMutation,
  Types.ExcluirConfigAtendimentoDomMutationVariables
>
export const SalvarDuracaoAgendamentoAdDocument = gql`
  mutation SalvarDuracaoAgendamentoAD($input: DuracaoAgendamentoAtencaoDomInput!) {
    salvarDuracaoAgendamentoAtencaoDom(input: $input) {
      duracaoAgendamento
    }
  }
`
export type SalvarDuracaoAgendamentoAdMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarDuracaoAgendamentoAdMutation,
  Types.SalvarDuracaoAgendamentoAdMutationVariables
>

/**
 * __useSalvarDuracaoAgendamentoAdMutation__
 *
 * To run a mutation, you first call `useSalvarDuracaoAgendamentoAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarDuracaoAgendamentoAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarDuracaoAgendamentoAdMutation, { data, loading, error }] = useSalvarDuracaoAgendamentoAdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarDuracaoAgendamentoAdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarDuracaoAgendamentoAdMutation,
    Types.SalvarDuracaoAgendamentoAdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarDuracaoAgendamentoAdMutation,
    Types.SalvarDuracaoAgendamentoAdMutationVariables
  >(SalvarDuracaoAgendamentoAdDocument, baseOptions)
}
export type SalvarDuracaoAgendamentoAdMutationHookResult = ReturnType<typeof useSalvarDuracaoAgendamentoAdMutation>
export type SalvarDuracaoAgendamentoAdMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarDuracaoAgendamentoAdMutation
>
export type SalvarDuracaoAgendamentoAdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarDuracaoAgendamentoAdMutation,
  Types.SalvarDuracaoAgendamentoAdMutationVariables
>
export const AlterarSenhaDocument = gql`
  mutation AlterarSenha($input: AlterarSenhaInput!) {
    alterarSenha(input: $input) {
      id
      trocarSenha
    }
  }
`
export type AlterarSenhaMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarSenhaMutation,
  Types.AlterarSenhaMutationVariables
>

/**
 * __useAlterarSenhaMutation__
 *
 * To run a mutation, you first call `useAlterarSenhaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarSenhaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarSenhaMutation, { data, loading, error }] = useAlterarSenhaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarSenhaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AlterarSenhaMutation, Types.AlterarSenhaMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AlterarSenhaMutation, Types.AlterarSenhaMutationVariables>(
    AlterarSenhaDocument,
    baseOptions
  )
}
export type AlterarSenhaMutationHookResult = ReturnType<typeof useAlterarSenhaMutation>
export type AlterarSenhaMutationResult = ApolloReactCommon.MutationResult<Types.AlterarSenhaMutation>
export type AlterarSenhaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarSenhaMutation,
  Types.AlterarSenhaMutationVariables
>
export const AuditoriaHistoricoTableDocument = gql`
  query AuditoriaHistoricoTable($input: AuditoriaHistoricoQueryInput!) {
    auditoriaHistorico(input: $input) {
      content {
        id
        dataInicio
        dataFim
        dataSolicitacao
        status
        csv
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useAuditoriaHistoricoTableQuery__
 *
 * To run a query within a React component, call `useAuditoriaHistoricoTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditoriaHistoricoTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditoriaHistoricoTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuditoriaHistoricoTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AuditoriaHistoricoTableQuery,
    Types.AuditoriaHistoricoTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.AuditoriaHistoricoTableQuery, Types.AuditoriaHistoricoTableQueryVariables>(
    AuditoriaHistoricoTableDocument,
    baseOptions
  )
}
export function useAuditoriaHistoricoTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AuditoriaHistoricoTableQuery,
    Types.AuditoriaHistoricoTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AuditoriaHistoricoTableQuery, Types.AuditoriaHistoricoTableQueryVariables>(
    AuditoriaHistoricoTableDocument,
    baseOptions
  )
}
export type AuditoriaHistoricoTableQueryHookResult = ReturnType<typeof useAuditoriaHistoricoTableQuery>
export type AuditoriaHistoricoTableLazyQueryHookResult = ReturnType<typeof useAuditoriaHistoricoTableLazyQuery>
export type AuditoriaHistoricoTableQueryResult = ApolloReactCommon.QueryResult<
  Types.AuditoriaHistoricoTableQuery,
  Types.AuditoriaHistoricoTableQueryVariables
>
export const OnSessionEventDocument = gql`
  subscription onSessionEvent {
    sessionEvent
  }
`

/**
 * __useOnSessionEventSubscription__
 *
 * To run a query within a React component, call `useOnSessionEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSessionEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSessionEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSessionEventSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    Types.OnSessionEventSubscription,
    Types.OnSessionEventSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<Types.OnSessionEventSubscription, Types.OnSessionEventSubscriptionVariables>(
    OnSessionEventDocument,
    baseOptions
  )
}
export type OnSessionEventSubscriptionHookResult = ReturnType<typeof useOnSessionEventSubscription>
export type OnSessionEventSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.OnSessionEventSubscription>
export const AutorizarMunicipioConfigAgendaPadraoDocument = gql`
  query AutorizarMunicipioConfigAgendaPadrao {
    duracaoAtendimentoPadraoMunicipio {
      duracaoAtendimentoPadraoMunicipio
    }
    configuracaoHorariosMunicipio {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`

/**
 * __useAutorizarMunicipioConfigAgendaPadraoQuery__
 *
 * To run a query within a React component, call `useAutorizarMunicipioConfigAgendaPadraoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutorizarMunicipioConfigAgendaPadraoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutorizarMunicipioConfigAgendaPadraoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutorizarMunicipioConfigAgendaPadraoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.AutorizarMunicipioConfigAgendaPadraoQuery,
    Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.AutorizarMunicipioConfigAgendaPadraoQuery,
    Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
  >(AutorizarMunicipioConfigAgendaPadraoDocument, baseOptions)
}
export function useAutorizarMunicipioConfigAgendaPadraoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AutorizarMunicipioConfigAgendaPadraoQuery,
    Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.AutorizarMunicipioConfigAgendaPadraoQuery,
    Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
  >(AutorizarMunicipioConfigAgendaPadraoDocument, baseOptions)
}
export type AutorizarMunicipioConfigAgendaPadraoQueryHookResult = ReturnType<
  typeof useAutorizarMunicipioConfigAgendaPadraoQuery
>
export type AutorizarMunicipioConfigAgendaPadraoLazyQueryHookResult = ReturnType<
  typeof useAutorizarMunicipioConfigAgendaPadraoLazyQuery
>
export type AutorizarMunicipioConfigAgendaPadraoQueryResult = ApolloReactCommon.QueryResult<
  Types.AutorizarMunicipioConfigAgendaPadraoQuery,
  Types.AutorizarMunicipioConfigAgendaPadraoQueryVariables
>
export const SalvaAutorizarMunicipioConfiguracaoAgendaPadraoDocument = gql`
  mutation SalvaAutorizarMunicipioConfiguracaoAgendaPadrao(
    $duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput!
    $configuracaoAgendaMunicipalInput: ConfiguracaoAgendaMunicipalInput
  ) {
    alterarDuracaoPadraoAtendimento(duracaoAtendimentoPadraoMunicipioInput: $duracaoAtendimentoPadraoMunicipioInput) {
      duracaoAtendimentoPadraoMunicipio
    }
    salvarConfiguracaoHorariosMunicipio(configuracaoAgendaMunicipalInput: $configuracaoAgendaMunicipalInput) {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`
export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables
>

/**
 * __useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation__
 *
 * To run a mutation, you first call `useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation, { data, loading, error }] = useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation({
 *   variables: {
 *      duracaoAtendimentoPadraoMunicipioInput: // value for 'duracaoAtendimentoPadraoMunicipioInput'
 *      configuracaoAgendaMunicipalInput: // value for 'configuracaoAgendaMunicipalInput'
 *   },
 * });
 */
export function useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
    Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
    Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables
  >(SalvaAutorizarMunicipioConfiguracaoAgendaPadraoDocument, baseOptions)
}
export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationHookResult = ReturnType<
  typeof useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation
>
export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation
>
export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
  Types.SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables
>
export const SaveAutorizarMunicipioDocument = gql`
  mutation SaveAutorizarMunicipio($input: AutorizarMunicipioInput!) {
    autorizarMunicipio(input: $input) {
      id
      autorizado
    }
  }
`
export type SaveAutorizarMunicipioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SaveAutorizarMunicipioMutation,
  Types.SaveAutorizarMunicipioMutationVariables
>

/**
 * __useSaveAutorizarMunicipioMutation__
 *
 * To run a mutation, you first call `useSaveAutorizarMunicipioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAutorizarMunicipioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAutorizarMunicipioMutation, { data, loading, error }] = useSaveAutorizarMunicipioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAutorizarMunicipioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SaveAutorizarMunicipioMutation,
    Types.SaveAutorizarMunicipioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SaveAutorizarMunicipioMutation,
    Types.SaveAutorizarMunicipioMutationVariables
  >(SaveAutorizarMunicipioDocument, baseOptions)
}
export type SaveAutorizarMunicipioMutationHookResult = ReturnType<typeof useSaveAutorizarMunicipioMutation>
export type SaveAutorizarMunicipioMutationResult = ApolloReactCommon.MutationResult<
  Types.SaveAutorizarMunicipioMutation
>
export type SaveAutorizarMunicipioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SaveAutorizarMunicipioMutation,
  Types.SaveAutorizarMunicipioMutationVariables
>
export const AutorizarMunicipioDocument = gql`
  query AutorizarMunicipio($acessoId: ID!) {
    info {
      treinamento
      tipoInstalacao
    }
    acesso(id: $acessoId) {
      __typename
      id
      profissional {
        id
        nome
        cpf
      }
      municipio {
        id
        nome
        uf {
          id
          nome
          sigla
        }
      }
      ... on AdministradorMunicipal {
        autorizado
        chaveMunicipio
      }
    }
  }
`

/**
 * __useAutorizarMunicipioQuery__
 *
 * To run a query within a React component, call `useAutorizarMunicipioQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutorizarMunicipioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutorizarMunicipioQuery({
 *   variables: {
 *      acessoId: // value for 'acessoId'
 *   },
 * });
 */
export function useAutorizarMunicipioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AutorizarMunicipioQuery, Types.AutorizarMunicipioQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AutorizarMunicipioQuery, Types.AutorizarMunicipioQueryVariables>(
    AutorizarMunicipioDocument,
    baseOptions
  )
}
export function useAutorizarMunicipioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.AutorizarMunicipioQuery,
    Types.AutorizarMunicipioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.AutorizarMunicipioQuery, Types.AutorizarMunicipioQueryVariables>(
    AutorizarMunicipioDocument,
    baseOptions
  )
}
export type AutorizarMunicipioQueryHookResult = ReturnType<typeof useAutorizarMunicipioQuery>
export type AutorizarMunicipioLazyQueryHookResult = ReturnType<typeof useAutorizarMunicipioLazyQuery>
export type AutorizarMunicipioQueryResult = ApolloReactCommon.QueryResult<
  Types.AutorizarMunicipioQuery,
  Types.AutorizarMunicipioQueryVariables
>
export const ConcluirConfiguracaoMunicipioDocument = gql`
  mutation ConcluirConfiguracaoMunicipio {
    concluirConfiguracaoMunicipio {
      id
      habilitado
    }
  }
`
export type ConcluirConfiguracaoMunicipioMutationFn = ApolloReactCommon.MutationFunction<
  Types.ConcluirConfiguracaoMunicipioMutation,
  Types.ConcluirConfiguracaoMunicipioMutationVariables
>

/**
 * __useConcluirConfiguracaoMunicipioMutation__
 *
 * To run a mutation, you first call `useConcluirConfiguracaoMunicipioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConcluirConfiguracaoMunicipioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [concluirConfiguracaoMunicipioMutation, { data, loading, error }] = useConcluirConfiguracaoMunicipioMutation({
 *   variables: {
 *   },
 * });
 */
export function useConcluirConfiguracaoMunicipioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ConcluirConfiguracaoMunicipioMutation,
    Types.ConcluirConfiguracaoMunicipioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ConcluirConfiguracaoMunicipioMutation,
    Types.ConcluirConfiguracaoMunicipioMutationVariables
  >(ConcluirConfiguracaoMunicipioDocument, baseOptions)
}
export type ConcluirConfiguracaoMunicipioMutationHookResult = ReturnType<
  typeof useConcluirConfiguracaoMunicipioMutation
>
export type ConcluirConfiguracaoMunicipioMutationResult = ApolloReactCommon.MutationResult<
  Types.ConcluirConfiguracaoMunicipioMutation
>
export type ConcluirConfiguracaoMunicipioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ConcluirConfiguracaoMunicipioMutation,
  Types.ConcluirConfiguracaoMunicipioMutationVariables
>
export const AlterarDuracaoCboDocument = gql`
  mutation AlterarDuracaoCbo($cboDuracaoForm: CboDuracaoFormInput!) {
    alterarDuracaoCbo(input: $cboDuracaoForm) {
      cbo {
        id
      }
    }
  }
`
export type AlterarDuracaoCboMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarDuracaoCboMutation,
  Types.AlterarDuracaoCboMutationVariables
>

/**
 * __useAlterarDuracaoCboMutation__
 *
 * To run a mutation, you first call `useAlterarDuracaoCboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarDuracaoCboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarDuracaoCboMutation, { data, loading, error }] = useAlterarDuracaoCboMutation({
 *   variables: {
 *      cboDuracaoForm: // value for 'cboDuracaoForm'
 *   },
 * });
 */
export function useAlterarDuracaoCboMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarDuracaoCboMutation,
    Types.AlterarDuracaoCboMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AlterarDuracaoCboMutation, Types.AlterarDuracaoCboMutationVariables>(
    AlterarDuracaoCboDocument,
    baseOptions
  )
}
export type AlterarDuracaoCboMutationHookResult = ReturnType<typeof useAlterarDuracaoCboMutation>
export type AlterarDuracaoCboMutationResult = ApolloReactCommon.MutationResult<Types.AlterarDuracaoCboMutation>
export type AlterarDuracaoCboMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarDuracaoCboMutation,
  Types.AlterarDuracaoCboMutationVariables
>
export const DisponibilizarCboDocument = gql`
  mutation DisponibilizarCbo($cboDisponibilizarForm: CboDisponibilizarFormInput!) {
    disponibilizarCbo(input: $cboDisponibilizarForm) {
      cbo {
        id
      }
    }
  }
`
export type DisponibilizarCboMutationFn = ApolloReactCommon.MutationFunction<
  Types.DisponibilizarCboMutation,
  Types.DisponibilizarCboMutationVariables
>

/**
 * __useDisponibilizarCboMutation__
 *
 * To run a mutation, you first call `useDisponibilizarCboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisponibilizarCboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disponibilizarCboMutation, { data, loading, error }] = useDisponibilizarCboMutation({
 *   variables: {
 *      cboDisponibilizarForm: // value for 'cboDisponibilizarForm'
 *   },
 * });
 */
export function useDisponibilizarCboMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DisponibilizarCboMutation,
    Types.DisponibilizarCboMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.DisponibilizarCboMutation, Types.DisponibilizarCboMutationVariables>(
    DisponibilizarCboDocument,
    baseOptions
  )
}
export type DisponibilizarCboMutationHookResult = ReturnType<typeof useDisponibilizarCboMutation>
export type DisponibilizarCboMutationResult = ApolloReactCommon.MutationResult<Types.DisponibilizarCboMutation>
export type DisponibilizarCboMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DisponibilizarCboMutation,
  Types.DisponibilizarCboMutationVariables
>
export const CboTableDocument = gql`
  query CboTable($input: CbosMunicipioQueryInput!) {
    cbos(input: $input) {
      content {
        disponivelLotacao
        duracao
        duracaoPadrao
        cbo {
          id
          nome
          cbo2002
        }
        possuiLotacaoComAgendaOnline
        actions {
          inativar {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useCboTableQuery__
 *
 * To run a query within a React component, call `useCboTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCboTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCboTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCboTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CboTableQuery, Types.CboTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CboTableQuery, Types.CboTableQueryVariables>(CboTableDocument, baseOptions)
}
export function useCboTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CboTableQuery, Types.CboTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CboTableQuery, Types.CboTableQueryVariables>(CboTableDocument, baseOptions)
}
export type CboTableQueryHookResult = ReturnType<typeof useCboTableQuery>
export type CboTableLazyQueryHookResult = ReturnType<typeof useCboTableLazyQuery>
export type CboTableQueryResult = ApolloReactCommon.QueryResult<Types.CboTableQuery, Types.CboTableQueryVariables>
export const CidadaoListingDocument = gql`
  query CidadaoListing($filtro: CidadaosQueryInput!) {
    cidadaos(filtro: $filtro) {
      content {
        id
        nome
        nomeSocial
        cpf
        cns
        nomeMae
        dataNascimento
        telefoneCelular
        telefoneResidencial
        sexo
        identidadeGeneroDbEnum
        dataAtualizado
        ativo
        unificado
        faleceu
        prontuario {
          id
        }
        possuiAgendamento
        localidadeNascimento {
          id
          nome
          uf {
            id
            nome
            sigla
          }
        }
        cidadaoVinculacaoEquipe {
          id
          dataAtualizacaoCadastro
          unidadeSaude {
            id
            nome
          }
          equipe {
            id
            nome
            ine
          }
        }
        uuidUltimaFicha
      }
      hasMoreResults
    }
  }
`

/**
 * __useCidadaoListingQuery__
 *
 * To run a query within a React component, call `useCidadaoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoListingQuery({
 *   variables: {
 *      filtro: // value for 'filtro'
 *   },
 * });
 */
export function useCidadaoListingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidadaoListingQuery, Types.CidadaoListingQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidadaoListingQuery, Types.CidadaoListingQueryVariables>(
    CidadaoListingDocument,
    baseOptions
  )
}
export function useCidadaoListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CidadaoListingQuery, Types.CidadaoListingQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoListingQuery, Types.CidadaoListingQueryVariables>(
    CidadaoListingDocument,
    baseOptions
  )
}
export type CidadaoListingQueryHookResult = ReturnType<typeof useCidadaoListingQuery>
export type CidadaoListingLazyQueryHookResult = ReturnType<typeof useCidadaoListingLazyQuery>
export type CidadaoListingQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoListingQuery,
  Types.CidadaoListingQueryVariables
>
export const ExcluirCidadaoDocument = gql`
  mutation ExcluirCidadao($cidadaoId: ID!) {
    excluirCidadao(cidadaoId: $cidadaoId)
  }
`
export type ExcluirCidadaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirCidadaoMutation,
  Types.ExcluirCidadaoMutationVariables
>

/**
 * __useExcluirCidadaoMutation__
 *
 * To run a mutation, you first call `useExcluirCidadaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirCidadaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirCidadaoMutation, { data, loading, error }] = useExcluirCidadaoMutation({
 *   variables: {
 *      cidadaoId: // value for 'cidadaoId'
 *   },
 * });
 */
export function useExcluirCidadaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirCidadaoMutation,
    Types.ExcluirCidadaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ExcluirCidadaoMutation, Types.ExcluirCidadaoMutationVariables>(
    ExcluirCidadaoDocument,
    baseOptions
  )
}
export type ExcluirCidadaoMutationHookResult = ReturnType<typeof useExcluirCidadaoMutation>
export type ExcluirCidadaoMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirCidadaoMutation>
export type ExcluirCidadaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirCidadaoMutation,
  Types.ExcluirCidadaoMutationVariables
>
export const ReativarCidadaoDocument = gql`
  mutation ReativarCidadao($cidadaoId: ID!) {
    reativarCidadao(cidadaoId: $cidadaoId) {
      id
    }
  }
`
export type ReativarCidadaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ReativarCidadaoMutation,
  Types.ReativarCidadaoMutationVariables
>

/**
 * __useReativarCidadaoMutation__
 *
 * To run a mutation, you first call `useReativarCidadaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReativarCidadaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reativarCidadaoMutation, { data, loading, error }] = useReativarCidadaoMutation({
 *   variables: {
 *      cidadaoId: // value for 'cidadaoId'
 *   },
 * });
 */
export function useReativarCidadaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ReativarCidadaoMutation,
    Types.ReativarCidadaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ReativarCidadaoMutation, Types.ReativarCidadaoMutationVariables>(
    ReativarCidadaoDocument,
    baseOptions
  )
}
export type ReativarCidadaoMutationHookResult = ReturnType<typeof useReativarCidadaoMutation>
export type ReativarCidadaoMutationResult = ApolloReactCommon.MutationResult<Types.ReativarCidadaoMutation>
export type ReativarCidadaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ReativarCidadaoMutation,
  Types.ReativarCidadaoMutationVariables
>
export const VinculacaoFichaCadastroIndividualDocument = gql`
  query VinculacaoFichaCadastroIndividual($input: CidadaoVinculacaoFichaQueryInput!) {
    vinculacaoFichaCadastroIndividual(input: $input) {
      perguntaSaidaCadastroEnum
      unidadeSaude {
        id
        nome
      }
      equipe {
        id
        ine
        nome
        tipoEquipe {
          id
          nome
          sigla
        }
      }
    }
  }
`

/**
 * __useVinculacaoFichaCadastroIndividualQuery__
 *
 * To run a query within a React component, call `useVinculacaoFichaCadastroIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useVinculacaoFichaCadastroIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVinculacaoFichaCadastroIndividualQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVinculacaoFichaCadastroIndividualQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.VinculacaoFichaCadastroIndividualQuery,
    Types.VinculacaoFichaCadastroIndividualQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.VinculacaoFichaCadastroIndividualQuery,
    Types.VinculacaoFichaCadastroIndividualQueryVariables
  >(VinculacaoFichaCadastroIndividualDocument, baseOptions)
}
export function useVinculacaoFichaCadastroIndividualLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VinculacaoFichaCadastroIndividualQuery,
    Types.VinculacaoFichaCadastroIndividualQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.VinculacaoFichaCadastroIndividualQuery,
    Types.VinculacaoFichaCadastroIndividualQueryVariables
  >(VinculacaoFichaCadastroIndividualDocument, baseOptions)
}
export type VinculacaoFichaCadastroIndividualQueryHookResult = ReturnType<
  typeof useVinculacaoFichaCadastroIndividualQuery
>
export type VinculacaoFichaCadastroIndividualLazyQueryHookResult = ReturnType<
  typeof useVinculacaoFichaCadastroIndividualLazyQuery
>
export type VinculacaoFichaCadastroIndividualQueryResult = ApolloReactCommon.QueryResult<
  Types.VinculacaoFichaCadastroIndividualQuery,
  Types.VinculacaoFichaCadastroIndividualQueryVariables
>
export const BuscaCidadaoCadsusDocument = gql`
  query BuscaCidadaoCadsus($filter: CidadaosQueryInput!) {
    cidadaosCadsus(filtro: $filter) {
      nome
      nomeSocial
      cpf
      cns
      nomeMae
      dataNascimento
      telefoneCelular
      telefoneResidencial
      sexo
      municipioNascimento {
        id
        nome
        ibge
        uf {
          id
          nome
          sigla
        }
      }
    }
  }
`

/**
 * __useBuscaCidadaoCadsusQuery__
 *
 * To run a query within a React component, call `useBuscaCidadaoCadsusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaCidadaoCadsusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaCidadaoCadsusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBuscaCidadaoCadsusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BuscaCidadaoCadsusQuery, Types.BuscaCidadaoCadsusQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BuscaCidadaoCadsusQuery, Types.BuscaCidadaoCadsusQueryVariables>(
    BuscaCidadaoCadsusDocument,
    baseOptions
  )
}
export function useBuscaCidadaoCadsusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaCidadaoCadsusQuery,
    Types.BuscaCidadaoCadsusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BuscaCidadaoCadsusQuery, Types.BuscaCidadaoCadsusQueryVariables>(
    BuscaCidadaoCadsusDocument,
    baseOptions
  )
}
export type BuscaCidadaoCadsusQueryHookResult = ReturnType<typeof useBuscaCidadaoCadsusQuery>
export type BuscaCidadaoCadsusLazyQueryHookResult = ReturnType<typeof useBuscaCidadaoCadsusLazyQuery>
export type BuscaCidadaoCadsusQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaCidadaoCadsusQuery,
  Types.BuscaCidadaoCadsusQueryVariables
>
export const ConfiguracaoCadsusDocument = gql`
  query configuracaoCadsus {
    conexao {
      internet {
        habilitado
      }
      cadsus {
        habilitado
      }
    }
  }
`

/**
 * __useConfiguracaoCadsusQuery__
 *
 * To run a query within a React component, call `useConfiguracaoCadsusQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoCadsusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoCadsusQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoCadsusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ConfiguracaoCadsusQuery, Types.ConfiguracaoCadsusQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracaoCadsusQuery, Types.ConfiguracaoCadsusQueryVariables>(
    ConfiguracaoCadsusDocument,
    baseOptions
  )
}
export function useConfiguracaoCadsusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoCadsusQuery,
    Types.ConfiguracaoCadsusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ConfiguracaoCadsusQuery, Types.ConfiguracaoCadsusQueryVariables>(
    ConfiguracaoCadsusDocument,
    baseOptions
  )
}
export type ConfiguracaoCadsusQueryHookResult = ReturnType<typeof useConfiguracaoCadsusQuery>
export type ConfiguracaoCadsusLazyQueryHookResult = ReturnType<typeof useConfiguracaoCadsusLazyQuery>
export type ConfiguracaoCadsusQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoCadsusQuery,
  Types.ConfiguracaoCadsusQueryVariables
>
export const CreateCidadaoCadsusDocument = gql`
  mutation CreateCidadaoCadsus($cidadaoId: ID!) {
    createCidadaoCadsus(cidadaoId: $cidadaoId) {
      camposAlterados {
        campo
        conteudo
      }
      errors
      cidadaoLocal {
        id
        cpf
        cns
      }
      cidadaosDuplicados {
        id
        nome
        cpf
        cns
      }
      result
    }
  }
`
export type CreateCidadaoCadsusMutationFn = ApolloReactCommon.MutationFunction<
  Types.CreateCidadaoCadsusMutation,
  Types.CreateCidadaoCadsusMutationVariables
>

/**
 * __useCreateCidadaoCadsusMutation__
 *
 * To run a mutation, you first call `useCreateCidadaoCadsusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCidadaoCadsusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCidadaoCadsusMutation, { data, loading, error }] = useCreateCidadaoCadsusMutation({
 *   variables: {
 *      cidadaoId: // value for 'cidadaoId'
 *   },
 * });
 */
export function useCreateCidadaoCadsusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CreateCidadaoCadsusMutation,
    Types.CreateCidadaoCadsusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.CreateCidadaoCadsusMutation, Types.CreateCidadaoCadsusMutationVariables>(
    CreateCidadaoCadsusDocument,
    baseOptions
  )
}
export type CreateCidadaoCadsusMutationHookResult = ReturnType<typeof useCreateCidadaoCadsusMutation>
export type CreateCidadaoCadsusMutationResult = ApolloReactCommon.MutationResult<Types.CreateCidadaoCadsusMutation>
export type CreateCidadaoCadsusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CreateCidadaoCadsusMutation,
  Types.CreateCidadaoCadsusMutationVariables
>
export const BuscaDetailCidadaoDocument = gql`
  query BuscaDetailCidadao($id: ID!) {
    cidadao(cidadaoId: $id) {
      id
      cpf
      cns
      nisPisPasep
      nome
      nomeSocial
      dataNascimento
      dataAtualizado
      dataObito
      numeroDocumentoObito
      sexo
      nomeMae
      nomePai
      telefoneResidencial
      telefoneCelular
      telefoneContato
      email
      area
      microArea
      endereco {
        cep
        uf {
          id
          nome
        }
        municipio {
          id
          nome
        }
        bairro
        tipoLogradouro {
          id
          nome
        }
        logradouro
        numero
        semNumero
        complemento
        pontoReferencia
      }
      localidadeExterior
      identidadeGeneroDbEnum
      etnia {
        id
        nome
      }
      racaCor {
        id
        nome
      }
      area
      microArea
      cbo {
        id
        nome
      }
      escolaridade {
        id
        nome
      }
      ativo
      localidadeNascimento {
        id
        nome
        uf {
          id
          sigla
        }
      }
      faleceu
      possuiAgendamento
      prontuario {
        id
      }
      unificado
      cidadaoVinculacaoEquipe {
        id
        tpCdsOrigem
        unidadeSaude {
          id
          nome
        }
        equipe {
          id
          nome
          ine
        }
      }
      tipoSanguineo
      orientacaoSexualDbEnum
      estadoCivil {
        id
        nome
      }
      paisExterior {
        id
        nome
      }
      localidadeExterior
      nacionalidade {
        id
        nacionalidadeDbEnum
      }
      portariaNaturalizacao
      dataNaturalizacao
      paisNascimento {
        id
        nome
      }
      dataEntradaBrasil
      stCompartilhaProntuario
    }
  }
`

/**
 * __useBuscaDetailCidadaoQuery__
 *
 * To run a query within a React component, call `useBuscaDetailCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuscaDetailCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuscaDetailCidadaoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuscaDetailCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.BuscaDetailCidadaoQuery, Types.BuscaDetailCidadaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.BuscaDetailCidadaoQuery, Types.BuscaDetailCidadaoQueryVariables>(
    BuscaDetailCidadaoDocument,
    baseOptions
  )
}
export function useBuscaDetailCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.BuscaDetailCidadaoQuery,
    Types.BuscaDetailCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.BuscaDetailCidadaoQuery, Types.BuscaDetailCidadaoQueryVariables>(
    BuscaDetailCidadaoDocument,
    baseOptions
  )
}
export type BuscaDetailCidadaoQueryHookResult = ReturnType<typeof useBuscaDetailCidadaoQuery>
export type BuscaDetailCidadaoLazyQueryHookResult = ReturnType<typeof useBuscaDetailCidadaoLazyQuery>
export type BuscaDetailCidadaoQueryResult = ApolloReactCommon.QueryResult<
  Types.BuscaDetailCidadaoQuery,
  Types.BuscaDetailCidadaoQueryVariables
>
export const CidadaoCompartilhamentoProntuarioDocument = gql`
  query CidadaoCompartilhamentoProntuario {
    compartilhamentoProntuario {
      id
      ativo
    }
  }
`

/**
 * __useCidadaoCompartilhamentoProntuarioQuery__
 *
 * To run a query within a React component, call `useCidadaoCompartilhamentoProntuarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoCompartilhamentoProntuarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoCompartilhamentoProntuarioQuery({
 *   variables: {
 *   },
 * });
 */
export function useCidadaoCompartilhamentoProntuarioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CidadaoCompartilhamentoProntuarioQuery,
    Types.CidadaoCompartilhamentoProntuarioQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CidadaoCompartilhamentoProntuarioQuery,
    Types.CidadaoCompartilhamentoProntuarioQueryVariables
  >(CidadaoCompartilhamentoProntuarioDocument, baseOptions)
}
export function useCidadaoCompartilhamentoProntuarioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CidadaoCompartilhamentoProntuarioQuery,
    Types.CidadaoCompartilhamentoProntuarioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CidadaoCompartilhamentoProntuarioQuery,
    Types.CidadaoCompartilhamentoProntuarioQueryVariables
  >(CidadaoCompartilhamentoProntuarioDocument, baseOptions)
}
export type CidadaoCompartilhamentoProntuarioQueryHookResult = ReturnType<
  typeof useCidadaoCompartilhamentoProntuarioQuery
>
export type CidadaoCompartilhamentoProntuarioLazyQueryHookResult = ReturnType<
  typeof useCidadaoCompartilhamentoProntuarioLazyQuery
>
export type CidadaoCompartilhamentoProntuarioQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoCompartilhamentoProntuarioQuery,
  Types.CidadaoCompartilhamentoProntuarioQueryVariables
>
export const CidadaoEditDocument = gql`
  query CidadaoEdit($id: ID!) {
    cidadao(cidadaoId: $id) {
      id
      cpf
      cns
      nisPisPasep
      nome
      nomeSocial
      dataNascimento
      dataObito
      numeroDocumentoObito
      sexo
      desconheceNomeMae
      nomeMae
      desconheceNomePai
      nomePai
      telefoneResidencial
      telefoneCelular
      telefoneContato
      email
      area
      microArea
      statusForaArea
      endereco {
        bairro
        cep
        complemento
        logradouro
        numero
        pontoReferencia
        municipio {
          id
          ibge
          nome
          uf {
            id
            nome
            sigla
          }
        }
        semNumero
        tipoLogradouro {
          id
          nome
          numeroDne
        }
        uf {
          id
          nome
          sigla
        }
      }
      etnia {
        id
        nome
      }
      racaCor {
        id
        nome
        racaCorDbEnum
      }
      cbo {
        id
        nome
        cbo2002
      }
      escolaridade {
        id
        nome
      }
      localidadeNascimento {
        uf {
          id
          nome
          sigla
        }
        id
        nome
      }
      faleceu
      tipoSanguineo
      desejaInformarIdentidadeGenero
      identidadeGeneroDbEnum
      desejaInformarOrientacaoSexual
      orientacaoSexualDbEnum
      estadoCivil {
        id
        nome
      }
      paisExterior {
        id
        nome
      }
      localidadeExterior
      nacionalidade {
        id
        nacionalidadeDbEnum
      }
      portariaNaturalizacao
      dataNaturalizacao
      paisNascimento {
        id
        nome
      }
      dataEntradaBrasil
      stCompartilhaProntuario
      uuidUltimaFicha
      cidadaoVinculacaoEquipe {
        id
        utilizarCadastroIndividual
        unidadeSaude {
          id
          nome
        }
        equipe {
          id
          nome
          ine
          tipoEquipe {
            id
            nome
            sigla
          }
        }
      }
    }
  }
`

/**
 * __useCidadaoEditQuery__
 *
 * To run a query within a React component, call `useCidadaoEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCidadaoEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCidadaoEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCidadaoEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CidadaoEditQuery, Types.CidadaoEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.CidadaoEditQuery, Types.CidadaoEditQueryVariables>(
    CidadaoEditDocument,
    baseOptions
  )
}
export function useCidadaoEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CidadaoEditQuery, Types.CidadaoEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.CidadaoEditQuery, Types.CidadaoEditQueryVariables>(
    CidadaoEditDocument,
    baseOptions
  )
}
export type CidadaoEditQueryHookResult = ReturnType<typeof useCidadaoEditQuery>
export type CidadaoEditLazyQueryHookResult = ReturnType<typeof useCidadaoEditLazyQuery>
export type CidadaoEditQueryResult = ApolloReactCommon.QueryResult<
  Types.CidadaoEditQuery,
  Types.CidadaoEditQueryVariables
>
export const HasCidadaoAgendamentoFuturoDocument = gql`
  query HasCidadaoAgendamentoFuturo($input: CidadaoPossuiAgendamentoFuturoQueryInput!) {
    hasAgendamentoFuturo(input: $input)
  }
`

/**
 * __useHasCidadaoAgendamentoFuturoQuery__
 *
 * To run a query within a React component, call `useHasCidadaoAgendamentoFuturoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasCidadaoAgendamentoFuturoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasCidadaoAgendamentoFuturoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHasCidadaoAgendamentoFuturoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.HasCidadaoAgendamentoFuturoQuery,
    Types.HasCidadaoAgendamentoFuturoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.HasCidadaoAgendamentoFuturoQuery,
    Types.HasCidadaoAgendamentoFuturoQueryVariables
  >(HasCidadaoAgendamentoFuturoDocument, baseOptions)
}
export function useHasCidadaoAgendamentoFuturoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.HasCidadaoAgendamentoFuturoQuery,
    Types.HasCidadaoAgendamentoFuturoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.HasCidadaoAgendamentoFuturoQuery,
    Types.HasCidadaoAgendamentoFuturoQueryVariables
  >(HasCidadaoAgendamentoFuturoDocument, baseOptions)
}
export type HasCidadaoAgendamentoFuturoQueryHookResult = ReturnType<typeof useHasCidadaoAgendamentoFuturoQuery>
export type HasCidadaoAgendamentoFuturoLazyQueryHookResult = ReturnType<typeof useHasCidadaoAgendamentoFuturoLazyQuery>
export type HasCidadaoAgendamentoFuturoQueryResult = ApolloReactCommon.QueryResult<
  Types.HasCidadaoAgendamentoFuturoQuery,
  Types.HasCidadaoAgendamentoFuturoQueryVariables
>
export const SalvarCidadaoDocument = gql`
  mutation SalvarCidadao($input: CidadaoInput!) {
    salvarCidadao(input: $input) {
      id
      nome
    }
  }
`
export type SalvarCidadaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarCidadaoMutation,
  Types.SalvarCidadaoMutationVariables
>

/**
 * __useSalvarCidadaoMutation__
 *
 * To run a mutation, you first call `useSalvarCidadaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarCidadaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarCidadaoMutation, { data, loading, error }] = useSalvarCidadaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarCidadaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SalvarCidadaoMutation, Types.SalvarCidadaoMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.SalvarCidadaoMutation, Types.SalvarCidadaoMutationVariables>(
    SalvarCidadaoDocument,
    baseOptions
  )
}
export type SalvarCidadaoMutationHookResult = ReturnType<typeof useSalvarCidadaoMutation>
export type SalvarCidadaoMutationResult = ApolloReactCommon.MutationResult<Types.SalvarCidadaoMutation>
export type SalvarCidadaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarCidadaoMutation,
  Types.SalvarCidadaoMutationVariables
>
export const UnificacoesCidadaoDocument = gql`
  query UnificacoesCidadao($id: ID!) {
    unificacoesCidadao(cidadaoId: $id) {
      id
      nomeProfissional
      dataUnificacao
      cidadaos {
        id
        cpf
        cns
        nome
        nomeSocial
        dataNascimento
        identidadeGeneroDbEnum
        sexo
        nomeMae
        localidadeNascimento {
          id
          nome
          uf {
            id
            sigla
          }
        }
      }
    }
  }
`

/**
 * __useUnificacoesCidadaoQuery__
 *
 * To run a query within a React component, call `useUnificacoesCidadaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnificacoesCidadaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnificacoesCidadaoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnificacoesCidadaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.UnificacoesCidadaoQuery, Types.UnificacoesCidadaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.UnificacoesCidadaoQuery, Types.UnificacoesCidadaoQueryVariables>(
    UnificacoesCidadaoDocument,
    baseOptions
  )
}
export function useUnificacoesCidadaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnificacoesCidadaoQuery,
    Types.UnificacoesCidadaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnificacoesCidadaoQuery, Types.UnificacoesCidadaoQueryVariables>(
    UnificacoesCidadaoDocument,
    baseOptions
  )
}
export type UnificacoesCidadaoQueryHookResult = ReturnType<typeof useUnificacoesCidadaoQuery>
export type UnificacoesCidadaoLazyQueryHookResult = ReturnType<typeof useUnificacoesCidadaoLazyQuery>
export type UnificacoesCidadaoQueryResult = ApolloReactCommon.QueryResult<
  Types.UnificacoesCidadaoQuery,
  Types.UnificacoesCidadaoQueryVariables
>
export const ImportacoesCnesDocument = gql`
  query ImportacoesCnes($input: ImportacoesCnesQueryInput!) {
    importacoesCnes(input: $input) {
      content {
        id
        dataImportacao
        equipesNovas
        equipesAtualizadas
        lotacoesNovas
        lotacoesAtualizadas
        processo {
          id
          status
        }
        profissional {
          id
          nome
        }
        profissionaisNovos
        profissionaisAtualizados
        unidadesSaudeNovas
        unidadesSaudeAtualizadas
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useImportacoesCnesQuery__
 *
 * To run a query within a React component, call `useImportacoesCnesQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportacoesCnesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportacoesCnesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportacoesCnesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ImportacoesCnesQuery, Types.ImportacoesCnesQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ImportacoesCnesQuery, Types.ImportacoesCnesQueryVariables>(
    ImportacoesCnesDocument,
    baseOptions
  )
}
export function useImportacoesCnesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ImportacoesCnesQuery, Types.ImportacoesCnesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ImportacoesCnesQuery, Types.ImportacoesCnesQueryVariables>(
    ImportacoesCnesDocument,
    baseOptions
  )
}
export type ImportacoesCnesQueryHookResult = ReturnType<typeof useImportacoesCnesQuery>
export type ImportacoesCnesLazyQueryHookResult = ReturnType<typeof useImportacoesCnesLazyQuery>
export type ImportacoesCnesQueryResult = ApolloReactCommon.QueryResult<
  Types.ImportacoesCnesQuery,
  Types.ImportacoesCnesQueryVariables
>
export const AlterarQtdRequisicoesDocument = gql`
  mutation AlterarQtdRequisicoes($qtdRequisicoesSimultaneas: Int) {
    alterarQtdRequisicoes(qtdRequisicoesSimultaneas: $qtdRequisicoesSimultaneas) {
      qtdRequisicoesSimultaneas
    }
  }
`
export type AlterarQtdRequisicoesMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarQtdRequisicoesMutation,
  Types.AlterarQtdRequisicoesMutationVariables
>

/**
 * __useAlterarQtdRequisicoesMutation__
 *
 * To run a mutation, you first call `useAlterarQtdRequisicoesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarQtdRequisicoesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarQtdRequisicoesMutation, { data, loading, error }] = useAlterarQtdRequisicoesMutation({
 *   variables: {
 *      qtdRequisicoesSimultaneas: // value for 'qtdRequisicoesSimultaneas'
 *   },
 * });
 */
export function useAlterarQtdRequisicoesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarQtdRequisicoesMutation,
    Types.AlterarQtdRequisicoesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarQtdRequisicoesMutation,
    Types.AlterarQtdRequisicoesMutationVariables
  >(AlterarQtdRequisicoesDocument, baseOptions)
}
export type AlterarQtdRequisicoesMutationHookResult = ReturnType<typeof useAlterarQtdRequisicoesMutation>
export type AlterarQtdRequisicoesMutationResult = ApolloReactCommon.MutationResult<Types.AlterarQtdRequisicoesMutation>
export type AlterarQtdRequisicoesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarQtdRequisicoesMutation,
  Types.AlterarQtdRequisicoesMutationVariables
>
export const QuantidadeRequisicoesDocument = gql`
  query QuantidadeRequisicoes {
    avancada {
      performance {
        qtdRequisicoesSimultaneas
      }
    }
  }
`

/**
 * __useQuantidadeRequisicoesQuery__
 *
 * To run a query within a React component, call `useQuantidadeRequisicoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuantidadeRequisicoesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuantidadeRequisicoesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuantidadeRequisicoesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.QuantidadeRequisicoesQuery,
    Types.QuantidadeRequisicoesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.QuantidadeRequisicoesQuery, Types.QuantidadeRequisicoesQueryVariables>(
    QuantidadeRequisicoesDocument,
    baseOptions
  )
}
export function useQuantidadeRequisicoesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.QuantidadeRequisicoesQuery,
    Types.QuantidadeRequisicoesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.QuantidadeRequisicoesQuery, Types.QuantidadeRequisicoesQueryVariables>(
    QuantidadeRequisicoesDocument,
    baseOptions
  )
}
export type QuantidadeRequisicoesQueryHookResult = ReturnType<typeof useQuantidadeRequisicoesQuery>
export type QuantidadeRequisicoesLazyQueryHookResult = ReturnType<typeof useQuantidadeRequisicoesLazyQuery>
export type QuantidadeRequisicoesQueryResult = ApolloReactCommon.QueryResult<
  Types.QuantidadeRequisicoesQuery,
  Types.QuantidadeRequisicoesQueryVariables
>
export const GerarChaveAtivacaoAgendaOnlineDocument = gql`
  mutation GerarChaveAtivacaoAgendaOnline {
    gerarChaveAtivacaoAgendaOnline
  }
`
export type GerarChaveAtivacaoAgendaOnlineMutationFn = ApolloReactCommon.MutationFunction<
  Types.GerarChaveAtivacaoAgendaOnlineMutation,
  Types.GerarChaveAtivacaoAgendaOnlineMutationVariables
>

/**
 * __useGerarChaveAtivacaoAgendaOnlineMutation__
 *
 * To run a mutation, you first call `useGerarChaveAtivacaoAgendaOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGerarChaveAtivacaoAgendaOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gerarChaveAtivacaoAgendaOnlineMutation, { data, loading, error }] = useGerarChaveAtivacaoAgendaOnlineMutation({
 *   variables: {
 *   },
 * });
 */
export function useGerarChaveAtivacaoAgendaOnlineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.GerarChaveAtivacaoAgendaOnlineMutation,
    Types.GerarChaveAtivacaoAgendaOnlineMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.GerarChaveAtivacaoAgendaOnlineMutation,
    Types.GerarChaveAtivacaoAgendaOnlineMutationVariables
  >(GerarChaveAtivacaoAgendaOnlineDocument, baseOptions)
}
export type GerarChaveAtivacaoAgendaOnlineMutationHookResult = ReturnType<
  typeof useGerarChaveAtivacaoAgendaOnlineMutation
>
export type GerarChaveAtivacaoAgendaOnlineMutationResult = ApolloReactCommon.MutationResult<
  Types.GerarChaveAtivacaoAgendaOnlineMutation
>
export type GerarChaveAtivacaoAgendaOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.GerarChaveAtivacaoAgendaOnlineMutation,
  Types.GerarChaveAtivacaoAgendaOnlineMutationVariables
>
export const AtivarAgendaOnlineDocument = gql`
  mutation AtivarAgendaOnline($ativacaoAgendaOnlineInput: AtivacaoAgendaOnlineInput!) {
    ativarAgendaOnline(ativacaoAgendaOnlineInput: $ativacaoAgendaOnlineInput) {
      ativa
      detalhesAtivacao {
        profissional {
          nome
        }
        dataEvento
      }
    }
  }
`
export type AtivarAgendaOnlineMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtivarAgendaOnlineMutation,
  Types.AtivarAgendaOnlineMutationVariables
>

/**
 * __useAtivarAgendaOnlineMutation__
 *
 * To run a mutation, you first call `useAtivarAgendaOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtivarAgendaOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ativarAgendaOnlineMutation, { data, loading, error }] = useAtivarAgendaOnlineMutation({
 *   variables: {
 *      ativacaoAgendaOnlineInput: // value for 'ativacaoAgendaOnlineInput'
 *   },
 * });
 */
export function useAtivarAgendaOnlineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtivarAgendaOnlineMutation,
    Types.AtivarAgendaOnlineMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtivarAgendaOnlineMutation, Types.AtivarAgendaOnlineMutationVariables>(
    AtivarAgendaOnlineDocument,
    baseOptions
  )
}
export type AtivarAgendaOnlineMutationHookResult = ReturnType<typeof useAtivarAgendaOnlineMutation>
export type AtivarAgendaOnlineMutationResult = ApolloReactCommon.MutationResult<Types.AtivarAgendaOnlineMutation>
export type AtivarAgendaOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtivarAgendaOnlineMutation,
  Types.AtivarAgendaOnlineMutationVariables
>
export const TestarConexaoAgendaOnlineDocument = gql`
  mutation TestarConexaoAgendaOnline {
    testarConexaoAgendaOnline {
      sucesso
      dataEvento
    }
  }
`
export type TestarConexaoAgendaOnlineMutationFn = ApolloReactCommon.MutationFunction<
  Types.TestarConexaoAgendaOnlineMutation,
  Types.TestarConexaoAgendaOnlineMutationVariables
>

/**
 * __useTestarConexaoAgendaOnlineMutation__
 *
 * To run a mutation, you first call `useTestarConexaoAgendaOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestarConexaoAgendaOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testarConexaoAgendaOnlineMutation, { data, loading, error }] = useTestarConexaoAgendaOnlineMutation({
 *   variables: {
 *   },
 * });
 */
export function useTestarConexaoAgendaOnlineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.TestarConexaoAgendaOnlineMutation,
    Types.TestarConexaoAgendaOnlineMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.TestarConexaoAgendaOnlineMutation,
    Types.TestarConexaoAgendaOnlineMutationVariables
  >(TestarConexaoAgendaOnlineDocument, baseOptions)
}
export type TestarConexaoAgendaOnlineMutationHookResult = ReturnType<typeof useTestarConexaoAgendaOnlineMutation>
export type TestarConexaoAgendaOnlineMutationResult = ApolloReactCommon.MutationResult<
  Types.TestarConexaoAgendaOnlineMutation
>
export type TestarConexaoAgendaOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.TestarConexaoAgendaOnlineMutation,
  Types.TestarConexaoAgendaOnlineMutationVariables
>
export const DesativarAgendaOnlineDocument = gql`
  mutation DesativarAgendaOnline {
    desativarAgendaOnline {
      ativa
      detalhesAtivacao {
        profissional {
          nome
        }
        dataEvento
      }
    }
  }
`
export type DesativarAgendaOnlineMutationFn = ApolloReactCommon.MutationFunction<
  Types.DesativarAgendaOnlineMutation,
  Types.DesativarAgendaOnlineMutationVariables
>

/**
 * __useDesativarAgendaOnlineMutation__
 *
 * To run a mutation, you first call `useDesativarAgendaOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesativarAgendaOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [desativarAgendaOnlineMutation, { data, loading, error }] = useDesativarAgendaOnlineMutation({
 *   variables: {
 *   },
 * });
 */
export function useDesativarAgendaOnlineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DesativarAgendaOnlineMutation,
    Types.DesativarAgendaOnlineMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.DesativarAgendaOnlineMutation,
    Types.DesativarAgendaOnlineMutationVariables
  >(DesativarAgendaOnlineDocument, baseOptions)
}
export type DesativarAgendaOnlineMutationHookResult = ReturnType<typeof useDesativarAgendaOnlineMutation>
export type DesativarAgendaOnlineMutationResult = ApolloReactCommon.MutationResult<Types.DesativarAgendaOnlineMutation>
export type DesativarAgendaOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DesativarAgendaOnlineMutation,
  Types.DesativarAgendaOnlineMutationVariables
>
export const AlterarAtestadoDigitalDocument = gql`
  mutation AlterarAtestadoDigital($alterarAtestadoDigitalInput: AlterarAtestadoDigitalInput!) {
    alterarAtestadoDigital(alterarAtestadoDigitalInput: $alterarAtestadoDigitalInput) {
      id
      habilitado
      intervalo
      desabilitadoAteData
    }
  }
`
export type AlterarAtestadoDigitalMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarAtestadoDigitalMutation,
  Types.AlterarAtestadoDigitalMutationVariables
>

/**
 * __useAlterarAtestadoDigitalMutation__
 *
 * To run a mutation, you first call `useAlterarAtestadoDigitalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarAtestadoDigitalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarAtestadoDigitalMutation, { data, loading, error }] = useAlterarAtestadoDigitalMutation({
 *   variables: {
 *      alterarAtestadoDigitalInput: // value for 'alterarAtestadoDigitalInput'
 *   },
 * });
 */
export function useAlterarAtestadoDigitalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarAtestadoDigitalMutation,
    Types.AlterarAtestadoDigitalMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarAtestadoDigitalMutation,
    Types.AlterarAtestadoDigitalMutationVariables
  >(AlterarAtestadoDigitalDocument, baseOptions)
}
export type AlterarAtestadoDigitalMutationHookResult = ReturnType<typeof useAlterarAtestadoDigitalMutation>
export type AlterarAtestadoDigitalMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarAtestadoDigitalMutation
>
export type AlterarAtestadoDigitalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarAtestadoDigitalMutation,
  Types.AlterarAtestadoDigitalMutationVariables
>
export const AlterarCadsusDocument = gql`
  mutation AlterarCadsus($alterarCadsusInput: AlterarCadsusInput!) {
    alterarCadsus(alterarCadsusInput: $alterarCadsusInput) {
      id
      habilitado
      intervalo
      desabilitadoAteData
    }
  }
`
export type AlterarCadsusMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarCadsusMutation,
  Types.AlterarCadsusMutationVariables
>

/**
 * __useAlterarCadsusMutation__
 *
 * To run a mutation, you first call `useAlterarCadsusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarCadsusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarCadsusMutation, { data, loading, error }] = useAlterarCadsusMutation({
 *   variables: {
 *      alterarCadsusInput: // value for 'alterarCadsusInput'
 *   },
 * });
 */
export function useAlterarCadsusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AlterarCadsusMutation, Types.AlterarCadsusMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AlterarCadsusMutation, Types.AlterarCadsusMutationVariables>(
    AlterarCadsusDocument,
    baseOptions
  )
}
export type AlterarCadsusMutationHookResult = ReturnType<typeof useAlterarCadsusMutation>
export type AlterarCadsusMutationResult = ApolloReactCommon.MutationResult<Types.AlterarCadsusMutation>
export type AlterarCadsusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarCadsusMutation,
  Types.AlterarCadsusMutationVariables
>
export const AlterarHorusDocument = gql`
  mutation AlterarHorus($alterarHorusInput: AlterarHorusInput!) {
    alterarHorus(alterarHorusInput: $alterarHorusInput) {
      id
      habilitado
      intervalo
      desabilitadoAteData
    }
  }
`
export type AlterarHorusMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarHorusMutation,
  Types.AlterarHorusMutationVariables
>

/**
 * __useAlterarHorusMutation__
 *
 * To run a mutation, you first call `useAlterarHorusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarHorusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarHorusMutation, { data, loading, error }] = useAlterarHorusMutation({
 *   variables: {
 *      alterarHorusInput: // value for 'alterarHorusInput'
 *   },
 * });
 */
export function useAlterarHorusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AlterarHorusMutation, Types.AlterarHorusMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AlterarHorusMutation, Types.AlterarHorusMutationVariables>(
    AlterarHorusDocument,
    baseOptions
  )
}
export type AlterarHorusMutationHookResult = ReturnType<typeof useAlterarHorusMutation>
export type AlterarHorusMutationResult = ApolloReactCommon.MutationResult<Types.AlterarHorusMutation>
export type AlterarHorusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarHorusMutation,
  Types.AlterarHorusMutationVariables
>
export const MunicipiosResponsaveisDocument = gql`
  query MunicipiosResponsaveis($input: MunicipioResponsavelFiltroInput!) {
    municipiosResponsaveis(input: $input) {
      content {
        id
        profissional {
          id
          nome
        }
        municipio {
          id
          nome
          uf {
            id
            sigla
          }
        }
        habilitado
        ativo
        dataAdicao
        dataInativacao
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useMunicipiosResponsaveisQuery__
 *
 * To run a query within a React component, call `useMunicipiosResponsaveisQuery` and pass it any options that fit your needs.
 * When your component renders, `useMunicipiosResponsaveisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMunicipiosResponsaveisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMunicipiosResponsaveisQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.MunicipiosResponsaveisQuery,
    Types.MunicipiosResponsaveisQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.MunicipiosResponsaveisQuery, Types.MunicipiosResponsaveisQueryVariables>(
    MunicipiosResponsaveisDocument,
    baseOptions
  )
}
export function useMunicipiosResponsaveisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.MunicipiosResponsaveisQuery,
    Types.MunicipiosResponsaveisQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.MunicipiosResponsaveisQuery, Types.MunicipiosResponsaveisQueryVariables>(
    MunicipiosResponsaveisDocument,
    baseOptions
  )
}
export type MunicipiosResponsaveisQueryHookResult = ReturnType<typeof useMunicipiosResponsaveisQuery>
export type MunicipiosResponsaveisLazyQueryHookResult = ReturnType<typeof useMunicipiosResponsaveisLazyQuery>
export type MunicipiosResponsaveisQueryResult = ApolloReactCommon.QueryResult<
  Types.MunicipiosResponsaveisQuery,
  Types.MunicipiosResponsaveisQueryVariables
>
export const ForcarRedefinirSenhasDocument = gql`
  mutation ForcarRedefinirSenhas {
    forcarRedefinirSenhas {
      redefinicaoSenha
    }
  }
`
export type ForcarRedefinirSenhasMutationFn = ApolloReactCommon.MutationFunction<
  Types.ForcarRedefinirSenhasMutation,
  Types.ForcarRedefinirSenhasMutationVariables
>

/**
 * __useForcarRedefinirSenhasMutation__
 *
 * To run a mutation, you first call `useForcarRedefinirSenhasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForcarRedefinirSenhasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forcarRedefinirSenhasMutation, { data, loading, error }] = useForcarRedefinirSenhasMutation({
 *   variables: {
 *   },
 * });
 */
export function useForcarRedefinirSenhasMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ForcarRedefinirSenhasMutation,
    Types.ForcarRedefinirSenhasMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ForcarRedefinirSenhasMutation,
    Types.ForcarRedefinirSenhasMutationVariables
  >(ForcarRedefinirSenhasDocument, baseOptions)
}
export type ForcarRedefinirSenhasMutationHookResult = ReturnType<typeof useForcarRedefinirSenhasMutation>
export type ForcarRedefinirSenhasMutationResult = ApolloReactCommon.MutationResult<Types.ForcarRedefinirSenhasMutation>
export type ForcarRedefinirSenhasMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ForcarRedefinirSenhasMutation,
  Types.ForcarRedefinirSenhasMutationVariables
>
export const AlterarDadosServidorInstalacaoDocument = gql`
  mutation AlterarDadosServidorInstalacao($input: ConfiguracaoDadosServidorInput!) {
    alterarDadosServidor(input: $input) {
      nomeInstalacao
      linkInstalacao
    }
  }
`
export type AlterarDadosServidorInstalacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarDadosServidorInstalacaoMutation,
  Types.AlterarDadosServidorInstalacaoMutationVariables
>

/**
 * __useAlterarDadosServidorInstalacaoMutation__
 *
 * To run a mutation, you first call `useAlterarDadosServidorInstalacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarDadosServidorInstalacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarDadosServidorInstalacaoMutation, { data, loading, error }] = useAlterarDadosServidorInstalacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarDadosServidorInstalacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarDadosServidorInstalacaoMutation,
    Types.AlterarDadosServidorInstalacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarDadosServidorInstalacaoMutation,
    Types.AlterarDadosServidorInstalacaoMutationVariables
  >(AlterarDadosServidorInstalacaoDocument, baseOptions)
}
export type AlterarDadosServidorInstalacaoMutationHookResult = ReturnType<
  typeof useAlterarDadosServidorInstalacaoMutation
>
export type AlterarDadosServidorInstalacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarDadosServidorInstalacaoMutation
>
export type AlterarDadosServidorInstalacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarDadosServidorInstalacaoMutation,
  Types.AlterarDadosServidorInstalacaoMutationVariables
>
export const AlterarServidorSmtpDocument = gql`
  mutation AlterarServidorSMTP($input: ConfiguracaoSMTPInput!) {
    alterarServidorSMTP(input: $input) {
      email
      senha
      usuario
      endereco
      porta
    }
  }
`
export type AlterarServidorSmtpMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarServidorSmtpMutation,
  Types.AlterarServidorSmtpMutationVariables
>

/**
 * __useAlterarServidorSmtpMutation__
 *
 * To run a mutation, you first call `useAlterarServidorSmtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarServidorSmtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarServidorSmtpMutation, { data, loading, error }] = useAlterarServidorSmtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarServidorSmtpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarServidorSmtpMutation,
    Types.AlterarServidorSmtpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AlterarServidorSmtpMutation, Types.AlterarServidorSmtpMutationVariables>(
    AlterarServidorSmtpDocument,
    baseOptions
  )
}
export type AlterarServidorSmtpMutationHookResult = ReturnType<typeof useAlterarServidorSmtpMutation>
export type AlterarServidorSmtpMutationResult = ApolloReactCommon.MutationResult<Types.AlterarServidorSmtpMutation>
export type AlterarServidorSmtpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarServidorSmtpMutation,
  Types.AlterarServidorSmtpMutationVariables
>
export const DesabilitarConexaoSmtpDocument = gql`
  mutation DesabilitarConexaoSMTP {
    desabilitarConexaoSMTP
  }
`
export type DesabilitarConexaoSmtpMutationFn = ApolloReactCommon.MutationFunction<
  Types.DesabilitarConexaoSmtpMutation,
  Types.DesabilitarConexaoSmtpMutationVariables
>

/**
 * __useDesabilitarConexaoSmtpMutation__
 *
 * To run a mutation, you first call `useDesabilitarConexaoSmtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesabilitarConexaoSmtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [desabilitarConexaoSmtpMutation, { data, loading, error }] = useDesabilitarConexaoSmtpMutation({
 *   variables: {
 *   },
 * });
 */
export function useDesabilitarConexaoSmtpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DesabilitarConexaoSmtpMutation,
    Types.DesabilitarConexaoSmtpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.DesabilitarConexaoSmtpMutation,
    Types.DesabilitarConexaoSmtpMutationVariables
  >(DesabilitarConexaoSmtpDocument, baseOptions)
}
export type DesabilitarConexaoSmtpMutationHookResult = ReturnType<typeof useDesabilitarConexaoSmtpMutation>
export type DesabilitarConexaoSmtpMutationResult = ApolloReactCommon.MutationResult<
  Types.DesabilitarConexaoSmtpMutation
>
export type DesabilitarConexaoSmtpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DesabilitarConexaoSmtpMutation,
  Types.DesabilitarConexaoSmtpMutationVariables
>
export const DadosServidoresDocument = gql`
  query DadosServidores {
    servidores {
      internet {
        habilitado
      }
      dadosServidor {
        nomeInstalacao
        linkInstalacao
      }
      servidorSMTP {
        email
        senha
        usuario
        endereco
        porta
        ativo
        dataRegistro
      }
    }
  }
`

/**
 * __useDadosServidoresQuery__
 *
 * To run a query within a React component, call `useDadosServidoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useDadosServidoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDadosServidoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useDadosServidoresQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DadosServidoresQuery, Types.DadosServidoresQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.DadosServidoresQuery, Types.DadosServidoresQueryVariables>(
    DadosServidoresDocument,
    baseOptions
  )
}
export function useDadosServidoresLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DadosServidoresQuery, Types.DadosServidoresQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.DadosServidoresQuery, Types.DadosServidoresQueryVariables>(
    DadosServidoresDocument,
    baseOptions
  )
}
export type DadosServidoresQueryHookResult = ReturnType<typeof useDadosServidoresQuery>
export type DadosServidoresLazyQueryHookResult = ReturnType<typeof useDadosServidoresLazyQuery>
export type DadosServidoresQueryResult = ApolloReactCommon.QueryResult<
  Types.DadosServidoresQuery,
  Types.DadosServidoresQueryVariables
>
export const ConfiguracaoAgendaMunicipioDocument = gql`
  query ConfiguracaoAgendaMunicipio {
    duracaoAtendimentoPadraoMunicipio {
      duracaoAtendimentoPadraoMunicipio
    }
    configuracaoHorariosMunicipio {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`

/**
 * __useConfiguracaoAgendaMunicipioQuery__
 *
 * To run a query within a React component, call `useConfiguracaoAgendaMunicipioQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoAgendaMunicipioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoAgendaMunicipioQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfiguracaoAgendaMunicipioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoAgendaMunicipioQuery,
    Types.ConfiguracaoAgendaMunicipioQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ConfiguracaoAgendaMunicipioQuery,
    Types.ConfiguracaoAgendaMunicipioQueryVariables
  >(ConfiguracaoAgendaMunicipioDocument, baseOptions)
}
export function useConfiguracaoAgendaMunicipioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoAgendaMunicipioQuery,
    Types.ConfiguracaoAgendaMunicipioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ConfiguracaoAgendaMunicipioQuery,
    Types.ConfiguracaoAgendaMunicipioQueryVariables
  >(ConfiguracaoAgendaMunicipioDocument, baseOptions)
}
export type ConfiguracaoAgendaMunicipioQueryHookResult = ReturnType<typeof useConfiguracaoAgendaMunicipioQuery>
export type ConfiguracaoAgendaMunicipioLazyQueryHookResult = ReturnType<typeof useConfiguracaoAgendaMunicipioLazyQuery>
export type ConfiguracaoAgendaMunicipioQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoAgendaMunicipioQuery,
  Types.ConfiguracaoAgendaMunicipioQueryVariables
>
export const SalvarConfiguracaoHorariosMunicipioDocument = gql`
  mutation SalvarConfiguracaoHorariosMunicipio($configuracaoAgendaMunicipalInput: ConfiguracaoAgendaMunicipalInput) {
    salvarConfiguracaoHorariosMunicipio(configuracaoAgendaMunicipalInput: $configuracaoAgendaMunicipalInput) {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`
export type SalvarConfiguracaoHorariosMunicipioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarConfiguracaoHorariosMunicipioMutation,
  Types.SalvarConfiguracaoHorariosMunicipioMutationVariables
>

/**
 * __useSalvarConfiguracaoHorariosMunicipioMutation__
 *
 * To run a mutation, you first call `useSalvarConfiguracaoHorariosMunicipioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarConfiguracaoHorariosMunicipioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarConfiguracaoHorariosMunicipioMutation, { data, loading, error }] = useSalvarConfiguracaoHorariosMunicipioMutation({
 *   variables: {
 *      configuracaoAgendaMunicipalInput: // value for 'configuracaoAgendaMunicipalInput'
 *   },
 * });
 */
export function useSalvarConfiguracaoHorariosMunicipioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarConfiguracaoHorariosMunicipioMutation,
    Types.SalvarConfiguracaoHorariosMunicipioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarConfiguracaoHorariosMunicipioMutation,
    Types.SalvarConfiguracaoHorariosMunicipioMutationVariables
  >(SalvarConfiguracaoHorariosMunicipioDocument, baseOptions)
}
export type SalvarConfiguracaoHorariosMunicipioMutationHookResult = ReturnType<
  typeof useSalvarConfiguracaoHorariosMunicipioMutation
>
export type SalvarConfiguracaoHorariosMunicipioMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarConfiguracaoHorariosMunicipioMutation
>
export type SalvarConfiguracaoHorariosMunicipioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarConfiguracaoHorariosMunicipioMutation,
  Types.SalvarConfiguracaoHorariosMunicipioMutationVariables
>
export const SalvarConfiguracaoDuracaoAtendimentoMunicipioDocument = gql`
  mutation SalvarConfiguracaoDuracaoAtendimentoMunicipio(
    $duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput!
  ) {
    alterarDuracaoPadraoAtendimento(duracaoAtendimentoPadraoMunicipioInput: $duracaoAtendimentoPadraoMunicipioInput) {
      duracaoAtendimentoPadraoMunicipio
    }
  }
`
export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables
>

/**
 * __useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation__
 *
 * To run a mutation, you first call `useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarConfiguracaoDuracaoAtendimentoMunicipioMutation, { data, loading, error }] = useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation({
 *   variables: {
 *      duracaoAtendimentoPadraoMunicipioInput: // value for 'duracaoAtendimentoPadraoMunicipioInput'
 *   },
 * });
 */
export function useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
    Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
    Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables
  >(SalvarConfiguracaoDuracaoAtendimentoMunicipioDocument, baseOptions)
}
export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationHookResult = ReturnType<
  typeof useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation
>
export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation
>
export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
  Types.SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables
>
export const GrupoExamesDocument = gql`
  query GrupoExames($id: ID!) {
    grupoExame(id: $id) {
      id
      nome
      procedimentos {
        id
        descricao
        codigo
        descricaoReferencia
        codigoReferencia
      }
    }
  }
`

/**
 * __useGrupoExamesQuery__
 *
 * To run a query within a React component, call `useGrupoExamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrupoExamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrupoExamesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGrupoExamesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GrupoExamesQuery, Types.GrupoExamesQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.GrupoExamesQuery, Types.GrupoExamesQueryVariables>(
    GrupoExamesDocument,
    baseOptions
  )
}
export function useGrupoExamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GrupoExamesQuery, Types.GrupoExamesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.GrupoExamesQuery, Types.GrupoExamesQueryVariables>(
    GrupoExamesDocument,
    baseOptions
  )
}
export type GrupoExamesQueryHookResult = ReturnType<typeof useGrupoExamesQuery>
export type GrupoExamesLazyQueryHookResult = ReturnType<typeof useGrupoExamesLazyQuery>
export type GrupoExamesQueryResult = ApolloReactCommon.QueryResult<
  Types.GrupoExamesQuery,
  Types.GrupoExamesQueryVariables
>
export const GrupoExameSelectTableDocument = gql`
  query GrupoExameSelectTable($input: ProcedimentoQueryInput!) {
    procedimentosGrupoExame(input: $input) {
      content {
        id
        descricao
        codigo
        descricaoReferencia
        codigoReferencia
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useGrupoExameSelectTableQuery__
 *
 * To run a query within a React component, call `useGrupoExameSelectTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrupoExameSelectTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrupoExameSelectTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrupoExameSelectTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.GrupoExameSelectTableQuery,
    Types.GrupoExameSelectTableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.GrupoExameSelectTableQuery, Types.GrupoExameSelectTableQueryVariables>(
    GrupoExameSelectTableDocument,
    baseOptions
  )
}
export function useGrupoExameSelectTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.GrupoExameSelectTableQuery,
    Types.GrupoExameSelectTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.GrupoExameSelectTableQuery, Types.GrupoExameSelectTableQueryVariables>(
    GrupoExameSelectTableDocument,
    baseOptions
  )
}
export type GrupoExameSelectTableQueryHookResult = ReturnType<typeof useGrupoExameSelectTableQuery>
export type GrupoExameSelectTableLazyQueryHookResult = ReturnType<typeof useGrupoExameSelectTableLazyQuery>
export type GrupoExameSelectTableQueryResult = ApolloReactCommon.QueryResult<
  Types.GrupoExameSelectTableQuery,
  Types.GrupoExameSelectTableQueryVariables
>
export const GrupoExameTableDocument = gql`
  query GrupoExameTable($input: GrupoExameQueryInput!) {
    gruposExame(input: $input) {
      content {
        id
        nome
        quantidadeProcedimentos
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useGrupoExameTableQuery__
 *
 * To run a query within a React component, call `useGrupoExameTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrupoExameTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrupoExameTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrupoExameTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.GrupoExameTableQuery, Types.GrupoExameTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.GrupoExameTableQuery, Types.GrupoExameTableQueryVariables>(
    GrupoExameTableDocument,
    baseOptions
  )
}
export function useGrupoExameTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GrupoExameTableQuery, Types.GrupoExameTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.GrupoExameTableQuery, Types.GrupoExameTableQueryVariables>(
    GrupoExameTableDocument,
    baseOptions
  )
}
export type GrupoExameTableQueryHookResult = ReturnType<typeof useGrupoExameTableQuery>
export type GrupoExameTableLazyQueryHookResult = ReturnType<typeof useGrupoExameTableLazyQuery>
export type GrupoExameTableQueryResult = ApolloReactCommon.QueryResult<
  Types.GrupoExameTableQuery,
  Types.GrupoExameTableQueryVariables
>
export const CompartilhamentoProntuarioDocument = gql`
  query CompartilhamentoProntuario {
    compartilhamentoProntuario {
      id
      profissional {
        id
        nome
      }
      ativo
      dataUltimaAlteracao
    }
  }
`

/**
 * __useCompartilhamentoProntuarioQuery__
 *
 * To run a query within a React component, call `useCompartilhamentoProntuarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompartilhamentoProntuarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompartilhamentoProntuarioQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompartilhamentoProntuarioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.CompartilhamentoProntuarioQuery,
    Types.CompartilhamentoProntuarioQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.CompartilhamentoProntuarioQuery,
    Types.CompartilhamentoProntuarioQueryVariables
  >(CompartilhamentoProntuarioDocument, baseOptions)
}
export function useCompartilhamentoProntuarioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.CompartilhamentoProntuarioQuery,
    Types.CompartilhamentoProntuarioQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.CompartilhamentoProntuarioQuery,
    Types.CompartilhamentoProntuarioQueryVariables
  >(CompartilhamentoProntuarioDocument, baseOptions)
}
export type CompartilhamentoProntuarioQueryHookResult = ReturnType<typeof useCompartilhamentoProntuarioQuery>
export type CompartilhamentoProntuarioLazyQueryHookResult = ReturnType<typeof useCompartilhamentoProntuarioLazyQuery>
export type CompartilhamentoProntuarioQueryResult = ApolloReactCommon.QueryResult<
  Types.CompartilhamentoProntuarioQuery,
  Types.CompartilhamentoProntuarioQueryVariables
>
export const SaveCompartilhamentoProntuarioDocument = gql`
  mutation SaveCompartilhamentoProntuario($compartilhamentoProntuarioInput: CompartilhamentoProntuarioInput!) {
    saveCompartilhamentoProntuario(compartilhamentoProntuarioInput: $compartilhamentoProntuarioInput) {
      id
      profissional {
        id
        nome
      }
      ativo
      dataUltimaAlteracao
    }
  }
`
export type SaveCompartilhamentoProntuarioMutationFn = ApolloReactCommon.MutationFunction<
  Types.SaveCompartilhamentoProntuarioMutation,
  Types.SaveCompartilhamentoProntuarioMutationVariables
>

/**
 * __useSaveCompartilhamentoProntuarioMutation__
 *
 * To run a mutation, you first call `useSaveCompartilhamentoProntuarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCompartilhamentoProntuarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCompartilhamentoProntuarioMutation, { data, loading, error }] = useSaveCompartilhamentoProntuarioMutation({
 *   variables: {
 *      compartilhamentoProntuarioInput: // value for 'compartilhamentoProntuarioInput'
 *   },
 * });
 */
export function useSaveCompartilhamentoProntuarioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SaveCompartilhamentoProntuarioMutation,
    Types.SaveCompartilhamentoProntuarioMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SaveCompartilhamentoProntuarioMutation,
    Types.SaveCompartilhamentoProntuarioMutationVariables
  >(SaveCompartilhamentoProntuarioDocument, baseOptions)
}
export type SaveCompartilhamentoProntuarioMutationHookResult = ReturnType<
  typeof useSaveCompartilhamentoProntuarioMutation
>
export type SaveCompartilhamentoProntuarioMutationResult = ApolloReactCommon.MutationResult<
  Types.SaveCompartilhamentoProntuarioMutation
>
export type SaveCompartilhamentoProntuarioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SaveCompartilhamentoProntuarioMutation,
  Types.SaveCompartilhamentoProntuarioMutationVariables
>
export const TipoServicoTableDocument = gql`
  query TipoServicoTable($input: TipoServicoQueryInput!) {
    tiposServico(input: $input) {
      content {
        id
        nome
        municipio {
          id
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTipoServicoTableQuery__
 *
 * To run a query within a React component, call `useTipoServicoTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipoServicoTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipoServicoTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTipoServicoTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.TipoServicoTableQuery, Types.TipoServicoTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.TipoServicoTableQuery, Types.TipoServicoTableQueryVariables>(
    TipoServicoTableDocument,
    baseOptions
  )
}
export function useTipoServicoTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.TipoServicoTableQuery, Types.TipoServicoTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.TipoServicoTableQuery, Types.TipoServicoTableQueryVariables>(
    TipoServicoTableDocument,
    baseOptions
  )
}
export type TipoServicoTableQueryHookResult = ReturnType<typeof useTipoServicoTableQuery>
export type TipoServicoTableLazyQueryHookResult = ReturnType<typeof useTipoServicoTableLazyQuery>
export type TipoServicoTableQueryResult = ApolloReactCommon.QueryResult<
  Types.TipoServicoTableQuery,
  Types.TipoServicoTableQueryVariables
>
export const AlterarUnidadePadraoHorusDocument = gql`
  mutation AlterarUnidadePadraoHorus($input: UnidadeConsultaHorusIds!) {
    alterarUnidadePadraoHorus(input: $input) {
      id
      nome
    }
  }
`
export type AlterarUnidadePadraoHorusMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarUnidadePadraoHorusMutation,
  Types.AlterarUnidadePadraoHorusMutationVariables
>

/**
 * __useAlterarUnidadePadraoHorusMutation__
 *
 * To run a mutation, you first call `useAlterarUnidadePadraoHorusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarUnidadePadraoHorusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarUnidadePadraoHorusMutation, { data, loading, error }] = useAlterarUnidadePadraoHorusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarUnidadePadraoHorusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarUnidadePadraoHorusMutation,
    Types.AlterarUnidadePadraoHorusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarUnidadePadraoHorusMutation,
    Types.AlterarUnidadePadraoHorusMutationVariables
  >(AlterarUnidadePadraoHorusDocument, baseOptions)
}
export type AlterarUnidadePadraoHorusMutationHookResult = ReturnType<typeof useAlterarUnidadePadraoHorusMutation>
export type AlterarUnidadePadraoHorusMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarUnidadePadraoHorusMutation
>
export type AlterarUnidadePadraoHorusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarUnidadePadraoHorusMutation,
  Types.AlterarUnidadePadraoHorusMutationVariables
>
export const CadastroUnidadeConsultaHorusDocument = gql`
  mutation CadastroUnidadeConsultaHorus($input: UnidadeConsultaHorusIds!) {
    adicionarUnidadeConsultaHorus(input: $input) {
      id
      nome
    }
  }
`
export type CadastroUnidadeConsultaHorusMutationFn = ApolloReactCommon.MutationFunction<
  Types.CadastroUnidadeConsultaHorusMutation,
  Types.CadastroUnidadeConsultaHorusMutationVariables
>

/**
 * __useCadastroUnidadeConsultaHorusMutation__
 *
 * To run a mutation, you first call `useCadastroUnidadeConsultaHorusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCadastroUnidadeConsultaHorusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cadastroUnidadeConsultaHorusMutation, { data, loading, error }] = useCadastroUnidadeConsultaHorusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCadastroUnidadeConsultaHorusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.CadastroUnidadeConsultaHorusMutation,
    Types.CadastroUnidadeConsultaHorusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.CadastroUnidadeConsultaHorusMutation,
    Types.CadastroUnidadeConsultaHorusMutationVariables
  >(CadastroUnidadeConsultaHorusDocument, baseOptions)
}
export type CadastroUnidadeConsultaHorusMutationHookResult = ReturnType<typeof useCadastroUnidadeConsultaHorusMutation>
export type CadastroUnidadeConsultaHorusMutationResult = ApolloReactCommon.MutationResult<
  Types.CadastroUnidadeConsultaHorusMutation
>
export type CadastroUnidadeConsultaHorusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CadastroUnidadeConsultaHorusMutation,
  Types.CadastroUnidadeConsultaHorusMutationVariables
>
export const HorusListViewDocument = gql`
  query HorusListView($unidadeSaudeId: ID!) {
    unidadesConsultaHorus(id: $unidadeSaudeId) {
      unidadeSaude {
        id
        nome
        cnes
      }
      unidadePadrao
    }
    unidadeSaude(id: $unidadeSaudeId) {
      id
      actions {
        permissaoConexaoHorus {
          enabled
          hint
        }
      }
    }
    conexao {
      horus {
        id
        habilitado
      }
    }
  }
`

/**
 * __useHorusListViewQuery__
 *
 * To run a query within a React component, call `useHorusListViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useHorusListViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHorusListViewQuery({
 *   variables: {
 *      unidadeSaudeId: // value for 'unidadeSaudeId'
 *   },
 * });
 */
export function useHorusListViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.HorusListViewQuery, Types.HorusListViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.HorusListViewQuery, Types.HorusListViewQueryVariables>(
    HorusListViewDocument,
    baseOptions
  )
}
export function useHorusListViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.HorusListViewQuery, Types.HorusListViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.HorusListViewQuery, Types.HorusListViewQueryVariables>(
    HorusListViewDocument,
    baseOptions
  )
}
export type HorusListViewQueryHookResult = ReturnType<typeof useHorusListViewQuery>
export type HorusListViewLazyQueryHookResult = ReturnType<typeof useHorusListViewLazyQuery>
export type HorusListViewQueryResult = ApolloReactCommon.QueryResult<
  Types.HorusListViewQuery,
  Types.HorusListViewQueryVariables
>
export const RemoverUnidadeConsultaHorusDocument = gql`
  mutation RemoverUnidadeConsultaHorus($input: UnidadeConsultaHorusIds!) {
    removerUnidadeConsultaHorus(input: $input) {
      id
      nome
    }
  }
`
export type RemoverUnidadeConsultaHorusMutationFn = ApolloReactCommon.MutationFunction<
  Types.RemoverUnidadeConsultaHorusMutation,
  Types.RemoverUnidadeConsultaHorusMutationVariables
>

/**
 * __useRemoverUnidadeConsultaHorusMutation__
 *
 * To run a mutation, you first call `useRemoverUnidadeConsultaHorusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoverUnidadeConsultaHorusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removerUnidadeConsultaHorusMutation, { data, loading, error }] = useRemoverUnidadeConsultaHorusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoverUnidadeConsultaHorusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.RemoverUnidadeConsultaHorusMutation,
    Types.RemoverUnidadeConsultaHorusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.RemoverUnidadeConsultaHorusMutation,
    Types.RemoverUnidadeConsultaHorusMutationVariables
  >(RemoverUnidadeConsultaHorusDocument, baseOptions)
}
export type RemoverUnidadeConsultaHorusMutationHookResult = ReturnType<typeof useRemoverUnidadeConsultaHorusMutation>
export type RemoverUnidadeConsultaHorusMutationResult = ApolloReactCommon.MutationResult<
  Types.RemoverUnidadeConsultaHorusMutation
>
export type RemoverUnidadeConsultaHorusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.RemoverUnidadeConsultaHorusMutation,
  Types.RemoverUnidadeConsultaHorusMutationVariables
>
export const InstalarDocument = gql`
  mutation Instalar($input: InstalacaoInput!) {
    instalar(input: $input)
  }
`
export type InstalarMutationFn = ApolloReactCommon.MutationFunction<
  Types.InstalarMutation,
  Types.InstalarMutationVariables
>

/**
 * __useInstalarMutation__
 *
 * To run a mutation, you first call `useInstalarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstalarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [instalarMutation, { data, loading, error }] = useInstalarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstalarMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.InstalarMutation, Types.InstalarMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.InstalarMutation, Types.InstalarMutationVariables>(
    InstalarDocument,
    baseOptions
  )
}
export type InstalarMutationHookResult = ReturnType<typeof useInstalarMutation>
export type InstalarMutationResult = ApolloReactCommon.MutationResult<Types.InstalarMutation>
export type InstalarMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InstalarMutation,
  Types.InstalarMutationVariables
>
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input)
  }
`
export type LoginMutationFn = ApolloReactCommon.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, baseOptions)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = ApolloReactCommon.MutationResult<Types.LoginMutation>
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.LoginMutation,
  Types.LoginMutationVariables
>
export const ImunobiologicoComboDocument = gql`
  query ImunobiologicoCombo($input: ImunobiologicoQueryInput!) {
    imunobiologicos(input: $input) {
      content {
        id
        sigla
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useImunobiologicoComboQuery__
 *
 * To run a query within a React component, call `useImunobiologicoComboQuery` and pass it any options that fit your needs.
 * When your component renders, `useImunobiologicoComboQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImunobiologicoComboQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImunobiologicoComboQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ImunobiologicoComboQuery,
    Types.ImunobiologicoComboQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ImunobiologicoComboQuery, Types.ImunobiologicoComboQueryVariables>(
    ImunobiologicoComboDocument,
    baseOptions
  )
}
export function useImunobiologicoComboLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ImunobiologicoComboQuery,
    Types.ImunobiologicoComboQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ImunobiologicoComboQuery, Types.ImunobiologicoComboQueryVariables>(
    ImunobiologicoComboDocument,
    baseOptions
  )
}
export type ImunobiologicoComboQueryHookResult = ReturnType<typeof useImunobiologicoComboQuery>
export type ImunobiologicoComboLazyQueryHookResult = ReturnType<typeof useImunobiologicoComboLazyQuery>
export type ImunobiologicoComboQueryResult = ApolloReactCommon.QueryResult<
  Types.ImunobiologicoComboQuery,
  Types.ImunobiologicoComboQueryVariables
>
export const ExcluirLoteImunobiologicoDocument = gql`
  mutation excluirLoteImunobiologico($idLote: ID!) {
    excluirLoteImunobiologico(idLote: $idLote)
  }
`
export type ExcluirLoteImunobiologicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirLoteImunobiologicoMutation,
  Types.ExcluirLoteImunobiologicoMutationVariables
>

/**
 * __useExcluirLoteImunobiologicoMutation__
 *
 * To run a mutation, you first call `useExcluirLoteImunobiologicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirLoteImunobiologicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirLoteImunobiologicoMutation, { data, loading, error }] = useExcluirLoteImunobiologicoMutation({
 *   variables: {
 *      idLote: // value for 'idLote'
 *   },
 * });
 */
export function useExcluirLoteImunobiologicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirLoteImunobiologicoMutation,
    Types.ExcluirLoteImunobiologicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirLoteImunobiologicoMutation,
    Types.ExcluirLoteImunobiologicoMutationVariables
  >(ExcluirLoteImunobiologicoDocument, baseOptions)
}
export type ExcluirLoteImunobiologicoMutationHookResult = ReturnType<typeof useExcluirLoteImunobiologicoMutation>
export type ExcluirLoteImunobiologicoMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirLoteImunobiologicoMutation
>
export type ExcluirLoteImunobiologicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirLoteImunobiologicoMutation,
  Types.ExcluirLoteImunobiologicoMutationVariables
>
export const LoteImunobiologicoFabricanteDocument = gql`
  query LoteImunobiologicoFabricante($input: ImunobiologicoFabricanteQueryInput!) {
    fabricantesImunobiologico(input: $input) {
      content {
        id
        nome
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLoteImunobiologicoFabricanteQuery__
 *
 * To run a query within a React component, call `useLoteImunobiologicoFabricanteQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoteImunobiologicoFabricanteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoteImunobiologicoFabricanteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoteImunobiologicoFabricanteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LoteImunobiologicoFabricanteQuery,
    Types.LoteImunobiologicoFabricanteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.LoteImunobiologicoFabricanteQuery,
    Types.LoteImunobiologicoFabricanteQueryVariables
  >(LoteImunobiologicoFabricanteDocument, baseOptions)
}
export function useLoteImunobiologicoFabricanteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LoteImunobiologicoFabricanteQuery,
    Types.LoteImunobiologicoFabricanteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LoteImunobiologicoFabricanteQuery,
    Types.LoteImunobiologicoFabricanteQueryVariables
  >(LoteImunobiologicoFabricanteDocument, baseOptions)
}
export type LoteImunobiologicoFabricanteQueryHookResult = ReturnType<typeof useLoteImunobiologicoFabricanteQuery>
export type LoteImunobiologicoFabricanteLazyQueryHookResult = ReturnType<
  typeof useLoteImunobiologicoFabricanteLazyQuery
>
export type LoteImunobiologicoFabricanteQueryResult = ApolloReactCommon.QueryResult<
  Types.LoteImunobiologicoFabricanteQuery,
  Types.LoteImunobiologicoFabricanteQueryVariables
>
export const LoteImunobiologicoDocument = gql`
  query LoteImunobiologico($input: LoteImunobiologicoQueryInput!) {
    lotesImunobiologico(input: $input) {
      content {
        id
        lote
        fabricante {
          id
          nome
        }
        imunobiologico {
          id
          nome
          sigla
        }
        validade
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLoteImunobiologicoQuery__
 *
 * To run a query within a React component, call `useLoteImunobiologicoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoteImunobiologicoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoteImunobiologicoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoteImunobiologicoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoteImunobiologicoQuery, Types.LoteImunobiologicoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoteImunobiologicoQuery, Types.LoteImunobiologicoQueryVariables>(
    LoteImunobiologicoDocument,
    baseOptions
  )
}
export function useLoteImunobiologicoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LoteImunobiologicoQuery,
    Types.LoteImunobiologicoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.LoteImunobiologicoQuery, Types.LoteImunobiologicoQueryVariables>(
    LoteImunobiologicoDocument,
    baseOptions
  )
}
export type LoteImunobiologicoQueryHookResult = ReturnType<typeof useLoteImunobiologicoQuery>
export type LoteImunobiologicoLazyQueryHookResult = ReturnType<typeof useLoteImunobiologicoLazyQuery>
export type LoteImunobiologicoQueryResult = ApolloReactCommon.QueryResult<
  Types.LoteImunobiologicoQuery,
  Types.LoteImunobiologicoQueryVariables
>
export const SetAtivadoLoteImunobiologicoDocument = gql`
  mutation setAtivadoLoteImunobiologico($idLote: ID!, $ativado: Boolean) {
    setAtivadoLoteImunobiologico(idLote: $idLote, ativado: $ativado)
  }
`
export type SetAtivadoLoteImunobiologicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SetAtivadoLoteImunobiologicoMutation,
  Types.SetAtivadoLoteImunobiologicoMutationVariables
>

/**
 * __useSetAtivadoLoteImunobiologicoMutation__
 *
 * To run a mutation, you first call `useSetAtivadoLoteImunobiologicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAtivadoLoteImunobiologicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAtivadoLoteImunobiologicoMutation, { data, loading, error }] = useSetAtivadoLoteImunobiologicoMutation({
 *   variables: {
 *      idLote: // value for 'idLote'
 *      ativado: // value for 'ativado'
 *   },
 * });
 */
export function useSetAtivadoLoteImunobiologicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SetAtivadoLoteImunobiologicoMutation,
    Types.SetAtivadoLoteImunobiologicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SetAtivadoLoteImunobiologicoMutation,
    Types.SetAtivadoLoteImunobiologicoMutationVariables
  >(SetAtivadoLoteImunobiologicoDocument, baseOptions)
}
export type SetAtivadoLoteImunobiologicoMutationHookResult = ReturnType<typeof useSetAtivadoLoteImunobiologicoMutation>
export type SetAtivadoLoteImunobiologicoMutationResult = ApolloReactCommon.MutationResult<
  Types.SetAtivadoLoteImunobiologicoMutation
>
export type SetAtivadoLoteImunobiologicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SetAtivadoLoteImunobiologicoMutation,
  Types.SetAtivadoLoteImunobiologicoMutationVariables
>
export const SalvarLoteImunobiologicoDocument = gql`
  mutation SalvarLoteImunobiologico($input: LoteImunobiologicoInput!) {
    salvarLoteImunobiologico(input: $input) {
      id
    }
  }
`
export type SalvarLoteImunobiologicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarLoteImunobiologicoMutation,
  Types.SalvarLoteImunobiologicoMutationVariables
>

/**
 * __useSalvarLoteImunobiologicoMutation__
 *
 * To run a mutation, you first call `useSalvarLoteImunobiologicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarLoteImunobiologicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarLoteImunobiologicoMutation, { data, loading, error }] = useSalvarLoteImunobiologicoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarLoteImunobiologicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarLoteImunobiologicoMutation,
    Types.SalvarLoteImunobiologicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SalvarLoteImunobiologicoMutation,
    Types.SalvarLoteImunobiologicoMutationVariables
  >(SalvarLoteImunobiologicoDocument, baseOptions)
}
export type SalvarLoteImunobiologicoMutationHookResult = ReturnType<typeof useSalvarLoteImunobiologicoMutation>
export type SalvarLoteImunobiologicoMutationResult = ApolloReactCommon.MutationResult<
  Types.SalvarLoteImunobiologicoMutation
>
export type SalvarLoteImunobiologicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarLoteImunobiologicoMutation,
  Types.SalvarLoteImunobiologicoMutationVariables
>
export const VisualizouNovidadesDocument = gql`
  mutation VisualizouNovidades {
    visualizarNovidades
  }
`
export type VisualizouNovidadesMutationFn = ApolloReactCommon.MutationFunction<
  Types.VisualizouNovidadesMutation,
  Types.VisualizouNovidadesMutationVariables
>

/**
 * __useVisualizouNovidadesMutation__
 *
 * To run a mutation, you first call `useVisualizouNovidadesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisualizouNovidadesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visualizouNovidadesMutation, { data, loading, error }] = useVisualizouNovidadesMutation({
 *   variables: {
 *   },
 * });
 */
export function useVisualizouNovidadesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.VisualizouNovidadesMutation,
    Types.VisualizouNovidadesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.VisualizouNovidadesMutation, Types.VisualizouNovidadesMutationVariables>(
    VisualizouNovidadesDocument,
    baseOptions
  )
}
export type VisualizouNovidadesMutationHookResult = ReturnType<typeof useVisualizouNovidadesMutation>
export type VisualizouNovidadesMutationResult = ApolloReactCommon.MutationResult<Types.VisualizouNovidadesMutation>
export type VisualizouNovidadesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.VisualizouNovidadesMutation,
  Types.VisualizouNovidadesMutationVariables
>
export const VisualizarNovidadesDocument = gql`
  query VisualizarNovidades {
    sessao {
      id
      profissional {
        id
        usuario {
          id
          visualizouNovidades
        }
      }
    }
  }
`

/**
 * __useVisualizarNovidadesQuery__
 *
 * To run a query within a React component, call `useVisualizarNovidadesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualizarNovidadesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualizarNovidadesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisualizarNovidadesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.VisualizarNovidadesQuery,
    Types.VisualizarNovidadesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.VisualizarNovidadesQuery, Types.VisualizarNovidadesQueryVariables>(
    VisualizarNovidadesDocument,
    baseOptions
  )
}
export function useVisualizarNovidadesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.VisualizarNovidadesQuery,
    Types.VisualizarNovidadesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.VisualizarNovidadesQuery, Types.VisualizarNovidadesQueryVariables>(
    VisualizarNovidadesDocument,
    baseOptions
  )
}
export type VisualizarNovidadesQueryHookResult = ReturnType<typeof useVisualizarNovidadesQuery>
export type VisualizarNovidadesLazyQueryHookResult = ReturnType<typeof useVisualizarNovidadesLazyQuery>
export type VisualizarNovidadesQueryResult = ApolloReactCommon.QueryResult<
  Types.VisualizarNovidadesQuery,
  Types.VisualizarNovidadesQueryVariables
>
export const AtivarPerfilDocument = gql`
  mutation AtivarPerfil($id: ID!) {
    ativarPerfil(id: $id) {
      id
      ativo
    }
  }
`
export type AtivarPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtivarPerfilMutation,
  Types.AtivarPerfilMutationVariables
>

/**
 * __useAtivarPerfilMutation__
 *
 * To run a mutation, you first call `useAtivarPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtivarPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ativarPerfilMutation, { data, loading, error }] = useAtivarPerfilMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAtivarPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AtivarPerfilMutation, Types.AtivarPerfilMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AtivarPerfilMutation, Types.AtivarPerfilMutationVariables>(
    AtivarPerfilDocument,
    baseOptions
  )
}
export type AtivarPerfilMutationHookResult = ReturnType<typeof useAtivarPerfilMutation>
export type AtivarPerfilMutationResult = ApolloReactCommon.MutationResult<Types.AtivarPerfilMutation>
export type AtivarPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtivarPerfilMutation,
  Types.AtivarPerfilMutationVariables
>
export const CriarPerfilDocument = gql`
  mutation CriarPerfil($input: PerfilInput!) {
    criarPerfil(input: $input) {
      id
      nome
      ativo
    }
  }
`
export type CriarPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.CriarPerfilMutation,
  Types.CriarPerfilMutationVariables
>

/**
 * __useCriarPerfilMutation__
 *
 * To run a mutation, you first call `useCriarPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCriarPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [criarPerfilMutation, { data, loading, error }] = useCriarPerfilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCriarPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CriarPerfilMutation, Types.CriarPerfilMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.CriarPerfilMutation, Types.CriarPerfilMutationVariables>(
    CriarPerfilDocument,
    baseOptions
  )
}
export type CriarPerfilMutationHookResult = ReturnType<typeof useCriarPerfilMutation>
export type CriarPerfilMutationResult = ApolloReactCommon.MutationResult<Types.CriarPerfilMutation>
export type CriarPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CriarPerfilMutation,
  Types.CriarPerfilMutationVariables
>
export const ExcluirPerfilDocument = gql`
  mutation ExcluirPerfil($id: ID!) {
    excluirPerfil(id: $id) {
      id
    }
  }
`
export type ExcluirPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirPerfilMutation,
  Types.ExcluirPerfilMutationVariables
>

/**
 * __useExcluirPerfilMutation__
 *
 * To run a mutation, you first call `useExcluirPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirPerfilMutation, { data, loading, error }] = useExcluirPerfilMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ExcluirPerfilMutation, Types.ExcluirPerfilMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.ExcluirPerfilMutation, Types.ExcluirPerfilMutationVariables>(
    ExcluirPerfilDocument,
    baseOptions
  )
}
export type ExcluirPerfilMutationHookResult = ReturnType<typeof useExcluirPerfilMutation>
export type ExcluirPerfilMutationResult = ApolloReactCommon.MutationResult<Types.ExcluirPerfilMutation>
export type ExcluirPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirPerfilMutation,
  Types.ExcluirPerfilMutationVariables
>
export const InativarPerfilDocument = gql`
  mutation InativarPerfil($id: ID!) {
    inativarPerfil(id: $id) {
      id
      ativo
    }
  }
`
export type InativarPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.InativarPerfilMutation,
  Types.InativarPerfilMutationVariables
>

/**
 * __useInativarPerfilMutation__
 *
 * To run a mutation, you first call `useInativarPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInativarPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inativarPerfilMutation, { data, loading, error }] = useInativarPerfilMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInativarPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InativarPerfilMutation,
    Types.InativarPerfilMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.InativarPerfilMutation, Types.InativarPerfilMutationVariables>(
    InativarPerfilDocument,
    baseOptions
  )
}
export type InativarPerfilMutationHookResult = ReturnType<typeof useInativarPerfilMutation>
export type InativarPerfilMutationResult = ApolloReactCommon.MutationResult<Types.InativarPerfilMutation>
export type InativarPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InativarPerfilMutation,
  Types.InativarPerfilMutationVariables
>
export const RecursosDocument = gql`
  query Recursos($tipoAcesso: TipoAcesso!) {
    recursos(tipoAcesso: $tipoAcesso)
  }
`

/**
 * __useRecursosQuery__
 *
 * To run a query within a React component, call `useRecursosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecursosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecursosQuery({
 *   variables: {
 *      tipoAcesso: // value for 'tipoAcesso'
 *   },
 * });
 */
export function useRecursosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.RecursosQuery, Types.RecursosQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.RecursosQuery, Types.RecursosQueryVariables>(RecursosDocument, baseOptions)
}
export function useRecursosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.RecursosQuery, Types.RecursosQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.RecursosQuery, Types.RecursosQueryVariables>(RecursosDocument, baseOptions)
}
export type RecursosQueryHookResult = ReturnType<typeof useRecursosQuery>
export type RecursosLazyQueryHookResult = ReturnType<typeof useRecursosLazyQuery>
export type RecursosQueryResult = ApolloReactCommon.QueryResult<Types.RecursosQuery, Types.RecursosQueryVariables>
export const AtualizarPerfilDocument = gql`
  mutation AtualizarPerfil($input: PerfilInput!) {
    atualizarPerfil(input: $input) {
      id
      nome
      ativo
    }
  }
`
export type AtualizarPerfilMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtualizarPerfilMutation,
  Types.AtualizarPerfilMutationVariables
>

/**
 * __useAtualizarPerfilMutation__
 *
 * To run a mutation, you first call `useAtualizarPerfilMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtualizarPerfilMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atualizarPerfilMutation, { data, loading, error }] = useAtualizarPerfilMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtualizarPerfilMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtualizarPerfilMutation,
    Types.AtualizarPerfilMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtualizarPerfilMutation, Types.AtualizarPerfilMutationVariables>(
    AtualizarPerfilDocument,
    baseOptions
  )
}
export type AtualizarPerfilMutationHookResult = ReturnType<typeof useAtualizarPerfilMutation>
export type AtualizarPerfilMutationResult = ApolloReactCommon.MutationResult<Types.AtualizarPerfilMutation>
export type AtualizarPerfilMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtualizarPerfilMutation,
  Types.AtualizarPerfilMutationVariables
>
export const PerfilEditDocument = gql`
  query PerfilEdit($perfilId: ID!) {
    perfil(id: $perfilId) {
      id
      nome
      tipoPerfil
      recursos
      perfilPadrao
    }
  }
`

/**
 * __usePerfilEditQuery__
 *
 * To run a query within a React component, call `usePerfilEditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerfilEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerfilEditQuery({
 *   variables: {
 *      perfilId: // value for 'perfilId'
 *   },
 * });
 */
export function usePerfilEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>(
    PerfilEditDocument,
    baseOptions
  )
}
export function usePerfilEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>(
    PerfilEditDocument,
    baseOptions
  )
}
export type PerfilEditQueryHookResult = ReturnType<typeof usePerfilEditQuery>
export type PerfilEditLazyQueryHookResult = ReturnType<typeof usePerfilEditLazyQuery>
export type PerfilEditQueryResult = ApolloReactCommon.QueryResult<Types.PerfilEditQuery, Types.PerfilEditQueryVariables>
export const PerfilTableDocument = gql`
  query PerfilTable($input: PerfisQueryInput!) {
    perfis(input: $input) {
      content {
        id
        nome
        ativo
        tipoPerfil
        perfilPadrao
        actions {
          excluir {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __usePerfilTableQuery__
 *
 * To run a query within a React component, call `usePerfilTableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerfilTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerfilTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerfilTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.PerfilTableQuery, Types.PerfilTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.PerfilTableQuery, Types.PerfilTableQueryVariables>(
    PerfilTableDocument,
    baseOptions
  )
}
export function usePerfilTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.PerfilTableQuery, Types.PerfilTableQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.PerfilTableQuery, Types.PerfilTableQueryVariables>(
    PerfilTableDocument,
    baseOptions
  )
}
export type PerfilTableQueryHookResult = ReturnType<typeof usePerfilTableQuery>
export type PerfilTableLazyQueryHookResult = ReturnType<typeof usePerfilTableLazyQuery>
export type PerfilTableQueryResult = ApolloReactCommon.QueryResult<
  Types.PerfilTableQuery,
  Types.PerfilTableQueryVariables
>
export const ResponderPesquisaSatisfacaoDocument = gql`
  mutation responderPesquisaSatisfacao($input: RespostaPesquisa!) {
    responderPesquisaSatisfacao(input: $input) {
      id
      mostrarPesquisaSatisfacao
    }
  }
`
export type ResponderPesquisaSatisfacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.ResponderPesquisaSatisfacaoMutation,
  Types.ResponderPesquisaSatisfacaoMutationVariables
>

/**
 * __useResponderPesquisaSatisfacaoMutation__
 *
 * To run a mutation, you first call `useResponderPesquisaSatisfacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResponderPesquisaSatisfacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [responderPesquisaSatisfacaoMutation, { data, loading, error }] = useResponderPesquisaSatisfacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResponderPesquisaSatisfacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ResponderPesquisaSatisfacaoMutation,
    Types.ResponderPesquisaSatisfacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ResponderPesquisaSatisfacaoMutation,
    Types.ResponderPesquisaSatisfacaoMutationVariables
  >(ResponderPesquisaSatisfacaoDocument, baseOptions)
}
export type ResponderPesquisaSatisfacaoMutationHookResult = ReturnType<typeof useResponderPesquisaSatisfacaoMutation>
export type ResponderPesquisaSatisfacaoMutationResult = ApolloReactCommon.MutationResult<
  Types.ResponderPesquisaSatisfacaoMutation
>
export type ResponderPesquisaSatisfacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ResponderPesquisaSatisfacaoMutation,
  Types.ResponderPesquisaSatisfacaoMutationVariables
>
export const ProfissionalEditDocument = gql`
  query ProfissionalEdit($id: ID!) {
    profissional(id: $id) {
      id
      nome
      numeroConselhoClasse
      sexo
      telefone
      cns
      cpf
      dataNascimento
      email
      conselhoClasse {
        id
        descricao
        sigla
      }
      ufEmissoraConselhoClasse {
        id
        nome
        sigla
      }
      endereco {
        bairro
        cep
        complemento
        logradouro
        numero
        pontoReferencia
        municipio {
          id
          ibge
          nome
          uf {
            id
            nome
            sigla
          }
        }
        semNumero
        tipoLogradouro {
          id
          nome
          numeroDne
        }
        uf {
          id
          nome
          sigla
        }
      }
      lotacoes {
        id
        ativo
      }
    }
  }
`

/**
 * __useProfissionalEditQuery__
 *
 * To run a query within a React component, call `useProfissionalEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfissionalEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfissionalEditQuery, Types.ProfissionalEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalEditQuery, Types.ProfissionalEditQueryVariables>(
    ProfissionalEditDocument,
    baseOptions
  )
}
export function useProfissionalEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProfissionalEditQuery, Types.ProfissionalEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalEditQuery, Types.ProfissionalEditQueryVariables>(
    ProfissionalEditDocument,
    baseOptions
  )
}
export type ProfissionalEditQueryHookResult = ReturnType<typeof useProfissionalEditQuery>
export type ProfissionalEditLazyQueryHookResult = ReturnType<typeof useProfissionalEditLazyQuery>
export type ProfissionalEditQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalEditQuery,
  Types.ProfissionalEditQueryVariables
>
export const SalvarProfissionalDocument = gql`
  mutation SalvarProfissional($input: ProfissionalInput!) {
    salvarProfissional(input: $input) {
      id
      nome
    }
  }
`
export type SalvarProfissionalMutationFn = ApolloReactCommon.MutationFunction<
  Types.SalvarProfissionalMutation,
  Types.SalvarProfissionalMutationVariables
>

/**
 * __useSalvarProfissionalMutation__
 *
 * To run a mutation, you first call `useSalvarProfissionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalvarProfissionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salvarProfissionalMutation, { data, loading, error }] = useSalvarProfissionalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalvarProfissionalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SalvarProfissionalMutation,
    Types.SalvarProfissionalMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.SalvarProfissionalMutation, Types.SalvarProfissionalMutationVariables>(
    SalvarProfissionalDocument,
    baseOptions
  )
}
export type SalvarProfissionalMutationHookResult = ReturnType<typeof useSalvarProfissionalMutation>
export type SalvarProfissionalMutationResult = ApolloReactCommon.MutationResult<Types.SalvarProfissionalMutation>
export type SalvarProfissionalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SalvarProfissionalMutation,
  Types.SalvarProfissionalMutationVariables
>
export const ConfiguracaoAgendaLotacaoDocument = gql`
  query ConfiguracaoAgendaLotacao($lotacaoId: ID!) {
    lotacao(id: $lotacaoId) {
      id
      hasConfiguracaoAgenda
      hasConfiguracaoAgendaOnline
      hasAgendamentoFuturo
      cbo {
        id
        nome
        cbo2002
      }
      equipe {
        id
        ine
        nome
      }
      unidadeSaude {
        id
        nome
        cnes
      }
      profissional {
        nome
      }
      configuracaoAgenda {
        configuracaoHorarioAgenda {
          configuracaoDiasSemana {
            diaSemana
            periodos {
              periodo
              horarioInicial
              horarioFinal
            }
          }
        }
      }
    }
    conexao {
      agendaOnline {
        ativa
      }
    }
    duracaoAtendimentoPadraoMunicipio {
      duracaoAtendimentoPadraoMunicipio
    }
    configuracaoHorariosMunicipio {
      id
      configuracaoHorarioAgenda {
        configuracaoDiasSemana {
          diaSemana
          periodos {
            periodo
            horarioInicial
            horarioFinal
          }
        }
      }
    }
  }
`

/**
 * __useConfiguracaoAgendaLotacaoQuery__
 *
 * To run a query within a React component, call `useConfiguracaoAgendaLotacaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguracaoAgendaLotacaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguracaoAgendaLotacaoQuery({
 *   variables: {
 *      lotacaoId: // value for 'lotacaoId'
 *   },
 * });
 */
export function useConfiguracaoAgendaLotacaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ConfiguracaoAgendaLotacaoQuery,
    Types.ConfiguracaoAgendaLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ConfiguracaoAgendaLotacaoQuery, Types.ConfiguracaoAgendaLotacaoQueryVariables>(
    ConfiguracaoAgendaLotacaoDocument,
    baseOptions
  )
}
export function useConfiguracaoAgendaLotacaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ConfiguracaoAgendaLotacaoQuery,
    Types.ConfiguracaoAgendaLotacaoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ConfiguracaoAgendaLotacaoQuery,
    Types.ConfiguracaoAgendaLotacaoQueryVariables
  >(ConfiguracaoAgendaLotacaoDocument, baseOptions)
}
export type ConfiguracaoAgendaLotacaoQueryHookResult = ReturnType<typeof useConfiguracaoAgendaLotacaoQuery>
export type ConfiguracaoAgendaLotacaoLazyQueryHookResult = ReturnType<typeof useConfiguracaoAgendaLotacaoLazyQuery>
export type ConfiguracaoAgendaLotacaoQueryResult = ApolloReactCommon.QueryResult<
  Types.ConfiguracaoAgendaLotacaoQuery,
  Types.ConfiguracaoAgendaLotacaoQueryVariables
>
export const ProfissionalAcessosDocument = gql`
  query ProfissionalAcessos($profissionalId: ID!) {
    profissional(id: $profissionalId) {
      id
      cns
      acessos(input: { mostrarInativos: true }) {
        id
        tipo
        ativo
        perfis {
          id
          nome
          ativo
        }
        actions {
          permissaoEdicao {
            enabled
            hint
          }
          permissaoExclusao {
            enabled
            hint
          }
          permissaoAtivacaoInativacao {
            enabled
            hint
          }
          ... on LotacaoActions {
            permissaoConfigurarAgenda {
              enabled
              hint
            }
            permissaoConfigurarAgendaOnline {
              enabled
              hint
            }
            permissaoFecharAgenda {
              enabled
              hint
            }
          }
        }
        ... on AdministradorMunicipal {
          municipio {
            id
            nome
          }
        }
        ... on GestorEstadual {
          uf {
            id
            nome
          }
        }
        ... on GestorMunicipal {
          municipio {
            id
            nome
          }
        }
        ... on Lotacao {
          importada
          hasConfiguracaoAgenda
          hasConfiguracaoAgendaOnline
          hasAgendamentoFuturo
          cbo {
            id
            nome
            cbo2002
          }
          equipe {
            id
            nome
            ine
            tipoEquipe {
              id
              descricao
            }
          }
          unidadeSaude {
            id
            nome
          }
        }
      }
    }
  }
`

/**
 * __useProfissionalAcessosQuery__
 *
 * To run a query within a React component, call `useProfissionalAcessosQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalAcessosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalAcessosQuery({
 *   variables: {
 *      profissionalId: // value for 'profissionalId'
 *   },
 * });
 */
export function useProfissionalAcessosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionalAcessosQuery,
    Types.ProfissionalAcessosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalAcessosQuery, Types.ProfissionalAcessosQueryVariables>(
    ProfissionalAcessosDocument,
    baseOptions
  )
}
export function useProfissionalAcessosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalAcessosQuery,
    Types.ProfissionalAcessosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalAcessosQuery, Types.ProfissionalAcessosQueryVariables>(
    ProfissionalAcessosDocument,
    baseOptions
  )
}
export type ProfissionalAcessosQueryHookResult = ReturnType<typeof useProfissionalAcessosQuery>
export type ProfissionalAcessosLazyQueryHookResult = ReturnType<typeof useProfissionalAcessosLazyQuery>
export type ProfissionalAcessosQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalAcessosQuery,
  Types.ProfissionalAcessosQueryVariables
>
export const ProfissionalDetailDocument = gql`
  query profissionalDetail($id: ID!) {
    profissional(id: $id) {
      lotacoes {
        ativo
        cbo {
          id
          nome
          cbo2002
        }
        equipe {
          id
          ine
          nome
          tipoEquipe {
            id
            nome
            sigla
          }
        }
        id
        unidadeSaude {
          id
          nome
          cnes
        }
      }
      ...ProfissionalDetail
    }
  }
  ${ProfissionalDetailFragmentDoc}
`

/**
 * __useProfissionalDetailQuery__
 *
 * To run a query within a React component, call `useProfissionalDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfissionalDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfissionalDetailQuery, Types.ProfissionalDetailQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalDetailQuery, Types.ProfissionalDetailQueryVariables>(
    ProfissionalDetailDocument,
    baseOptions
  )
}
export function useProfissionalDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalDetailQuery,
    Types.ProfissionalDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalDetailQuery, Types.ProfissionalDetailQueryVariables>(
    ProfissionalDetailDocument,
    baseOptions
  )
}
export type ProfissionalDetailQueryHookResult = ReturnType<typeof useProfissionalDetailQuery>
export type ProfissionalDetailLazyQueryHookResult = ReturnType<typeof useProfissionalDetailLazyQuery>
export type ProfissionalDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalDetailQuery,
  Types.ProfissionalDetailQueryVariables
>
export const AgendaEmConflitoDocument = gql`
  query AgendaEmConflito($id: ID!) {
    agendaEmConflito(idLotacao: $id)
  }
`

/**
 * __useAgendaEmConflitoQuery__
 *
 * To run a query within a React component, call `useAgendaEmConflitoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendaEmConflitoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendaEmConflitoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgendaEmConflitoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AgendaEmConflitoQuery, Types.AgendaEmConflitoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AgendaEmConflitoQuery, Types.AgendaEmConflitoQueryVariables>(
    AgendaEmConflitoDocument,
    baseOptions
  )
}
export function useAgendaEmConflitoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AgendaEmConflitoQuery, Types.AgendaEmConflitoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AgendaEmConflitoQuery, Types.AgendaEmConflitoQueryVariables>(
    AgendaEmConflitoDocument,
    baseOptions
  )
}
export type AgendaEmConflitoQueryHookResult = ReturnType<typeof useAgendaEmConflitoQuery>
export type AgendaEmConflitoLazyQueryHookResult = ReturnType<typeof useAgendaEmConflitoLazyQuery>
export type AgendaEmConflitoQueryResult = ApolloReactCommon.QueryResult<
  Types.AgendaEmConflitoQuery,
  Types.AgendaEmConflitoQueryVariables
>
export const AcessoEditDocument = gql`
  query AcessoEdit($acessoId: ID!, $profissionalId: ID!) {
    acesso(id: $acessoId) {
      tipo
      actions {
        permissaoEdicao {
          enabled
        }
      }
      perfis {
        id
        nome
        ativo
      }
      ... on GestorEstadual {
        uf {
          id
          nome
          sigla
        }
      }
      ... on GestorMunicipal {
        municipio {
          id
          nome
          uf {
            id
            nome
            sigla
          }
        }
      }
    }
    profissional(id: $profissionalId) {
      id
      nome
    }
  }
`

/**
 * __useAcessoEditQuery__
 *
 * To run a query within a React component, call `useAcessoEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcessoEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcessoEditQuery({
 *   variables: {
 *      acessoId: // value for 'acessoId'
 *      profissionalId: // value for 'profissionalId'
 *   },
 * });
 */
export function useAcessoEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>(
    AcessoEditDocument,
    baseOptions
  )
}
export function useAcessoEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>(
    AcessoEditDocument,
    baseOptions
  )
}
export type AcessoEditQueryHookResult = ReturnType<typeof useAcessoEditQuery>
export type AcessoEditLazyQueryHookResult = ReturnType<typeof useAcessoEditLazyQuery>
export type AcessoEditQueryResult = ApolloReactCommon.QueryResult<Types.AcessoEditQuery, Types.AcessoEditQueryVariables>
export const AlterarLotacaoDocument = gql`
  mutation AlterarLotacao($input: AlterarLotacaoInput!) {
    alterarLotacao(input: $input) {
      id
    }
  }
`
export type AlterarLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarLotacaoMutation,
  Types.AlterarLotacaoMutationVariables
>

/**
 * __useAlterarLotacaoMutation__
 *
 * To run a mutation, you first call `useAlterarLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarLotacaoMutation, { data, loading, error }] = useAlterarLotacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlterarLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarLotacaoMutation,
    Types.AlterarLotacaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AlterarLotacaoMutation, Types.AlterarLotacaoMutationVariables>(
    AlterarLotacaoDocument,
    baseOptions
  )
}
export type AlterarLotacaoMutationHookResult = ReturnType<typeof useAlterarLotacaoMutation>
export type AlterarLotacaoMutationResult = ApolloReactCommon.MutationResult<Types.AlterarLotacaoMutation>
export type AlterarLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarLotacaoMutation,
  Types.AlterarLotacaoMutationVariables
>
export const CriarLotacaoDocument = gql`
  mutation CriarLotacao($input: LotacaoInput!) {
    criarLotacao(input: $input) {
      id
    }
  }
`
export type CriarLotacaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.CriarLotacaoMutation,
  Types.CriarLotacaoMutationVariables
>

/**
 * __useCriarLotacaoMutation__
 *
 * To run a mutation, you first call `useCriarLotacaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCriarLotacaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [criarLotacaoMutation, { data, loading, error }] = useCriarLotacaoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCriarLotacaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CriarLotacaoMutation, Types.CriarLotacaoMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.CriarLotacaoMutation, Types.CriarLotacaoMutationVariables>(
    CriarLotacaoDocument,
    baseOptions
  )
}
export type CriarLotacaoMutationHookResult = ReturnType<typeof useCriarLotacaoMutation>
export type CriarLotacaoMutationResult = ApolloReactCommon.MutationResult<Types.CriarLotacaoMutation>
export type CriarLotacaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.CriarLotacaoMutation,
  Types.CriarLotacaoMutationVariables
>
export const LotacaoEditViewDocument = gql`
  query LotacaoEditView($id: ID!) {
    lotacao(id: $id) {
      id
      importada
      hasConfiguracaoAgenda
      atualizaPerfil
      ativo
      profissional {
        nome
      }
      cbo {
        id
        nome
        cbo2002
      }
      equipe {
        id
        nome
        ine
        tipoEquipe {
          id
          nome
          sigla
        }
      }
      perfis {
        id
        nome
        ativo
      }
      unidadeSaude {
        id
        nome
        cnes
      }
    }
  }
`

/**
 * __useLotacaoEditViewQuery__
 *
 * To run a query within a React component, call `useLotacaoEditViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotacaoEditViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotacaoEditViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLotacaoEditViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LotacaoEditViewQuery, Types.LotacaoEditViewQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LotacaoEditViewQuery, Types.LotacaoEditViewQueryVariables>(
    LotacaoEditViewDocument,
    baseOptions
  )
}
export function useLotacaoEditViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LotacaoEditViewQuery, Types.LotacaoEditViewQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LotacaoEditViewQuery, Types.LotacaoEditViewQueryVariables>(
    LotacaoEditViewDocument,
    baseOptions
  )
}
export type LotacaoEditViewQueryHookResult = ReturnType<typeof useLotacaoEditViewQuery>
export type LotacaoEditViewLazyQueryHookResult = ReturnType<typeof useLotacaoEditViewLazyQuery>
export type LotacaoEditViewQueryResult = ApolloReactCommon.QueryResult<
  Types.LotacaoEditViewQuery,
  Types.LotacaoEditViewQueryVariables
>
export const ProfissionalTableDocument = gql`
  query ProfissionalTable($input: ProfissionaisQueryInput!) {
    profissionais(input: $input) {
      content {
        id
        nome
        cpf
        cns
        lotacoes {
          id
          ativo
          cbo {
            id
            nome
          }
        }
        usuario {
          id
          bloqueado
        }
        actions {
          redefinirSenha {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useProfissionalTableQuery__
 *
 * To run a query within a React component, call `useProfissionalTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfissionalTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfissionalTableQuery, Types.ProfissionalTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalTableQuery, Types.ProfissionalTableQueryVariables>(
    ProfissionalTableDocument,
    baseOptions
  )
}
export function useProfissionalTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalTableQuery,
    Types.ProfissionalTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalTableQuery, Types.ProfissionalTableQueryVariables>(
    ProfissionalTableDocument,
    baseOptions
  )
}
export type ProfissionalTableQueryHookResult = ReturnType<typeof useProfissionalTableQuery>
export type ProfissionalTableLazyQueryHookResult = ReturnType<typeof useProfissionalTableLazyQuery>
export type ProfissionalTableQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalTableQuery,
  Types.ProfissionalTableQueryVariables
>
export const InfoProfissionalCpfDocument = gql`
  query InfoProfissionalCpf($cpf: String!) {
    profissionalByCpf(cpf: $cpf) {
      id
    }
  }
`

/**
 * __useInfoProfissionalCpfQuery__
 *
 * To run a query within a React component, call `useInfoProfissionalCpfQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoProfissionalCpfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoProfissionalCpfQuery({
 *   variables: {
 *      cpf: // value for 'cpf'
 *   },
 * });
 */
export function useInfoProfissionalCpfQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.InfoProfissionalCpfQuery,
    Types.InfoProfissionalCpfQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.InfoProfissionalCpfQuery, Types.InfoProfissionalCpfQueryVariables>(
    InfoProfissionalCpfDocument,
    baseOptions
  )
}
export function useInfoProfissionalCpfLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.InfoProfissionalCpfQuery,
    Types.InfoProfissionalCpfQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.InfoProfissionalCpfQuery, Types.InfoProfissionalCpfQueryVariables>(
    InfoProfissionalCpfDocument,
    baseOptions
  )
}
export type InfoProfissionalCpfQueryHookResult = ReturnType<typeof useInfoProfissionalCpfQuery>
export type InfoProfissionalCpfLazyQueryHookResult = ReturnType<typeof useInfoProfissionalCpfLazyQuery>
export type InfoProfissionalCpfQueryResult = ApolloReactCommon.QueryResult<
  Types.InfoProfissionalCpfQuery,
  Types.InfoProfissionalCpfQueryVariables
>
export const ResetSenhaAdminDocument = gql`
  mutation ResetSenhaAdmin($input: ResetSenhaAdminInput!) {
    resetSenhaAdministrador(input: $input)
  }
`
export type ResetSenhaAdminMutationFn = ApolloReactCommon.MutationFunction<
  Types.ResetSenhaAdminMutation,
  Types.ResetSenhaAdminMutationVariables
>

/**
 * __useResetSenhaAdminMutation__
 *
 * To run a mutation, you first call `useResetSenhaAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSenhaAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSenhaAdminMutation, { data, loading, error }] = useResetSenhaAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetSenhaAdminMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ResetSenhaAdminMutation,
    Types.ResetSenhaAdminMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.ResetSenhaAdminMutation, Types.ResetSenhaAdminMutationVariables>(
    ResetSenhaAdminDocument,
    baseOptions
  )
}
export type ResetSenhaAdminMutationHookResult = ReturnType<typeof useResetSenhaAdminMutation>
export type ResetSenhaAdminMutationResult = ApolloReactCommon.MutationResult<Types.ResetSenhaAdminMutation>
export type ResetSenhaAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ResetSenhaAdminMutation,
  Types.ResetSenhaAdminMutationVariables
>
export const SaveAdminProfissionalDocument = gql`
  mutation SaveAdminProfissional($input: ProfissionalContraChaveInput!) {
    salvarProfissionalContraChave(input: $input) {
      id
    }
  }
`
export type SaveAdminProfissionalMutationFn = ApolloReactCommon.MutationFunction<
  Types.SaveAdminProfissionalMutation,
  Types.SaveAdminProfissionalMutationVariables
>

/**
 * __useSaveAdminProfissionalMutation__
 *
 * To run a mutation, you first call `useSaveAdminProfissionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAdminProfissionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAdminProfissionalMutation, { data, loading, error }] = useSaveAdminProfissionalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAdminProfissionalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.SaveAdminProfissionalMutation,
    Types.SaveAdminProfissionalMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.SaveAdminProfissionalMutation,
    Types.SaveAdminProfissionalMutationVariables
  >(SaveAdminProfissionalDocument, baseOptions)
}
export type SaveAdminProfissionalMutationHookResult = ReturnType<typeof useSaveAdminProfissionalMutation>
export type SaveAdminProfissionalMutationResult = ApolloReactCommon.MutationResult<Types.SaveAdminProfissionalMutation>
export type SaveAdminProfissionalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.SaveAdminProfissionalMutation,
  Types.SaveAdminProfissionalMutationVariables
>
export const ValidateNovoAdminDocument = gql`
  query validateNovoAdmin($input: ValidateNovoAdminInput!) {
    validatePermissaoNovoAdmin(input: $input)
  }
`

/**
 * __useValidateNovoAdminQuery__
 *
 * To run a query within a React component, call `useValidateNovoAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateNovoAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateNovoAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateNovoAdminQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ValidateNovoAdminQuery, Types.ValidateNovoAdminQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ValidateNovoAdminQuery, Types.ValidateNovoAdminQueryVariables>(
    ValidateNovoAdminDocument,
    baseOptions
  )
}
export function useValidateNovoAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ValidateNovoAdminQuery,
    Types.ValidateNovoAdminQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ValidateNovoAdminQuery, Types.ValidateNovoAdminQueryVariables>(
    ValidateNovoAdminDocument,
    baseOptions
  )
}
export type ValidateNovoAdminQueryHookResult = ReturnType<typeof useValidateNovoAdminQuery>
export type ValidateNovoAdminLazyQueryHookResult = ReturnType<typeof useValidateNovoAdminLazyQuery>
export type ValidateNovoAdminQueryResult = ApolloReactCommon.QueryResult<
  Types.ValidateNovoAdminQuery,
  Types.ValidateNovoAdminQueryVariables
>
export const ValidateResetAdminDocument = gql`
  query ValidateResetAdmin($input: ResetSenhaAdminInput!) {
    validateResetAdmin(input: $input) {
      possuiCns
    }
  }
`

/**
 * __useValidateResetAdminQuery__
 *
 * To run a query within a React component, call `useValidateResetAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateResetAdminQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ValidateResetAdminQuery, Types.ValidateResetAdminQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ValidateResetAdminQuery, Types.ValidateResetAdminQueryVariables>(
    ValidateResetAdminDocument,
    baseOptions
  )
}
export function useValidateResetAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ValidateResetAdminQuery,
    Types.ValidateResetAdminQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ValidateResetAdminQuery, Types.ValidateResetAdminQueryVariables>(
    ValidateResetAdminDocument,
    baseOptions
  )
}
export type ValidateResetAdminQueryHookResult = ReturnType<typeof useValidateResetAdminQuery>
export type ValidateResetAdminLazyQueryHookResult = ReturnType<typeof useValidateResetAdminLazyQuery>
export type ValidateResetAdminQueryResult = ApolloReactCommon.QueryResult<
  Types.ValidateResetAdminQuery,
  Types.ValidateResetAdminQueryVariables
>
export const AlteraAdminDocument = gql`
  mutation AlteraAdmin($input: ValidateNovoAdminInput!) {
    denifinirNovoAdministrador(input: $input)
  }
`
export type AlteraAdminMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlteraAdminMutation,
  Types.AlteraAdminMutationVariables
>

/**
 * __useAlteraAdminMutation__
 *
 * To run a mutation, you first call `useAlteraAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlteraAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alteraAdminMutation, { data, loading, error }] = useAlteraAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlteraAdminMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AlteraAdminMutation, Types.AlteraAdminMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.AlteraAdminMutation, Types.AlteraAdminMutationVariables>(
    AlteraAdminDocument,
    baseOptions
  )
}
export type AlteraAdminMutationHookResult = ReturnType<typeof useAlteraAdminMutation>
export type AlteraAdminMutationResult = ApolloReactCommon.MutationResult<Types.AlteraAdminMutation>
export type AlteraAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlteraAdminMutation,
  Types.AlteraAdminMutationVariables
>
export const ChaveByCpfDocument = gql`
  query chaveByCpf($cpf: String!) {
    chaveByCpf(cpf: $cpf)
  }
`

/**
 * __useChaveByCpfQuery__
 *
 * To run a query within a React component, call `useChaveByCpfQuery` and pass it any options that fit your needs.
 * When your component renders, `useChaveByCpfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChaveByCpfQuery({
 *   variables: {
 *      cpf: // value for 'cpf'
 *   },
 * });
 */
export function useChaveByCpfQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>(
    ChaveByCpfDocument,
    baseOptions
  )
}
export function useChaveByCpfLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>(
    ChaveByCpfDocument,
    baseOptions
  )
}
export type ChaveByCpfQueryHookResult = ReturnType<typeof useChaveByCpfQuery>
export type ChaveByCpfLazyQueryHookResult = ReturnType<typeof useChaveByCpfLazyQuery>
export type ChaveByCpfQueryResult = ApolloReactCommon.QueryResult<Types.ChaveByCpfQuery, Types.ChaveByCpfQueryVariables>
export const ProfissionalAdminDetailDocument = gql`
  query profissionalAdminDetail($cpf: String!) {
    profissionalByCpf(cpf: $cpf) {
      ...ProfissionalDetail
      usuario {
        id
      }
    }
  }
  ${ProfissionalDetailFragmentDoc}
`

/**
 * __useProfissionalAdminDetailQuery__
 *
 * To run a query within a React component, call `useProfissionalAdminDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfissionalAdminDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfissionalAdminDetailQuery({
 *   variables: {
 *      cpf: // value for 'cpf'
 *   },
 * });
 */
export function useProfissionalAdminDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ProfissionalAdminDetailQuery,
    Types.ProfissionalAdminDetailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.ProfissionalAdminDetailQuery, Types.ProfissionalAdminDetailQueryVariables>(
    ProfissionalAdminDetailDocument,
    baseOptions
  )
}
export function useProfissionalAdminDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ProfissionalAdminDetailQuery,
    Types.ProfissionalAdminDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.ProfissionalAdminDetailQuery, Types.ProfissionalAdminDetailQueryVariables>(
    ProfissionalAdminDetailDocument,
    baseOptions
  )
}
export type ProfissionalAdminDetailQueryHookResult = ReturnType<typeof useProfissionalAdminDetailQuery>
export type ProfissionalAdminDetailLazyQueryHookResult = ReturnType<typeof useProfissionalAdminDetailLazyQuery>
export type ProfissionalAdminDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.ProfissionalAdminDetailQuery,
  Types.ProfissionalAdminDetailQueryVariables
>
export const SobreDocument = gql`
  query Sobre {
    info {
      commit
      competenciaSigtap
      databaseVendor
      databaseSchemaVersion
      recursosDisponiveis
      versao
      internetHabilitada
      smtpConfigurado
      linkInstalacaoConfigurado
    }
  }
`

/**
 * __useSobreQuery__
 *
 * To run a query within a React component, call `useSobreQuery` and pass it any options that fit your needs.
 * When your component renders, `useSobreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSobreQuery({
 *   variables: {
 *   },
 * });
 */
export function useSobreQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.SobreQuery, Types.SobreQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.SobreQuery, Types.SobreQueryVariables>(SobreDocument, baseOptions)
}
export function useSobreLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.SobreQuery, Types.SobreQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.SobreQuery, Types.SobreQueryVariables>(SobreDocument, baseOptions)
}
export type SobreQueryHookResult = ReturnType<typeof useSobreQuery>
export type SobreLazyQueryHookResult = ReturnType<typeof useSobreLazyQuery>
export type SobreQueryResult = ApolloReactCommon.QueryResult<Types.SobreQuery, Types.SobreQueryVariables>
export const AlternarTransmissaoLinkDocument = gql`
  mutation AlternarTransmissaoLink($id: ID!) {
    alternarTransmissaoLink(id: $id)
  }
`
export type AlternarTransmissaoLinkMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlternarTransmissaoLinkMutation,
  Types.AlternarTransmissaoLinkMutationVariables
>

/**
 * __useAlternarTransmissaoLinkMutation__
 *
 * To run a mutation, you first call `useAlternarTransmissaoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlternarTransmissaoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alternarTransmissaoLinkMutation, { data, loading, error }] = useAlternarTransmissaoLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAlternarTransmissaoLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlternarTransmissaoLinkMutation,
    Types.AlternarTransmissaoLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlternarTransmissaoLinkMutation,
    Types.AlternarTransmissaoLinkMutationVariables
  >(AlternarTransmissaoLinkDocument, baseOptions)
}
export type AlternarTransmissaoLinkMutationHookResult = ReturnType<typeof useAlternarTransmissaoLinkMutation>
export type AlternarTransmissaoLinkMutationResult = ApolloReactCommon.MutationResult<
  Types.AlternarTransmissaoLinkMutation
>
export type AlternarTransmissaoLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlternarTransmissaoLinkMutation,
  Types.AlternarTransmissaoLinkMutationVariables
>
export const ExcluirTransmissaoLinkDocument = gql`
  mutation ExcluirTransmissaoLink($id: ID!) {
    excluirTransmissaoLink(id: $id)
  }
`
export type ExcluirTransmissaoLinkMutationFn = ApolloReactCommon.MutationFunction<
  Types.ExcluirTransmissaoLinkMutation,
  Types.ExcluirTransmissaoLinkMutationVariables
>

/**
 * __useExcluirTransmissaoLinkMutation__
 *
 * To run a mutation, you first call `useExcluirTransmissaoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcluirTransmissaoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excluirTransmissaoLinkMutation, { data, loading, error }] = useExcluirTransmissaoLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcluirTransmissaoLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.ExcluirTransmissaoLinkMutation,
    Types.ExcluirTransmissaoLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.ExcluirTransmissaoLinkMutation,
    Types.ExcluirTransmissaoLinkMutationVariables
  >(ExcluirTransmissaoLinkDocument, baseOptions)
}
export type ExcluirTransmissaoLinkMutationHookResult = ReturnType<typeof useExcluirTransmissaoLinkMutation>
export type ExcluirTransmissaoLinkMutationResult = ApolloReactCommon.MutationResult<
  Types.ExcluirTransmissaoLinkMutation
>
export type ExcluirTransmissaoLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.ExcluirTransmissaoLinkMutation,
  Types.ExcluirTransmissaoLinkMutationVariables
>
export const InserirTransmissaoLinkDocument = gql`
  mutation InserirTransmissaoLink($form: TransmissaoLinkInsertDto!) {
    inserirTransmissaoLink(form: $form)
  }
`
export type InserirTransmissaoLinkMutationFn = ApolloReactCommon.MutationFunction<
  Types.InserirTransmissaoLinkMutation,
  Types.InserirTransmissaoLinkMutationVariables
>

/**
 * __useInserirTransmissaoLinkMutation__
 *
 * To run a mutation, you first call `useInserirTransmissaoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInserirTransmissaoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inserirTransmissaoLinkMutation, { data, loading, error }] = useInserirTransmissaoLinkMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useInserirTransmissaoLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.InserirTransmissaoLinkMutation,
    Types.InserirTransmissaoLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.InserirTransmissaoLinkMutation,
    Types.InserirTransmissaoLinkMutationVariables
  >(InserirTransmissaoLinkDocument, baseOptions)
}
export type InserirTransmissaoLinkMutationHookResult = ReturnType<typeof useInserirTransmissaoLinkMutation>
export type InserirTransmissaoLinkMutationResult = ApolloReactCommon.MutationResult<
  Types.InserirTransmissaoLinkMutation
>
export type InserirTransmissaoLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.InserirTransmissaoLinkMutation,
  Types.InserirTransmissaoLinkMutationVariables
>
export const TransmissaoLinkDocument = gql`
  query TransmissaoLink($input: TransmissaoLinkPagingQueryFiltroDto!) {
    transmissaoLink(input: $input) {
      content {
        id
        hostname
        name
        ativo
        statusConexao
        dataTesteConexao
        actions {
          alternarAtivacao {
            enabled
            hint
          }
          excluir {
            enabled
            hint
          }
          editar {
            enabled
            hint
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTransmissaoLinkQuery__
 *
 * To run a query within a React component, call `useTransmissaoLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransmissaoLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.TransmissaoLinkQuery, Types.TransmissaoLinkQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.TransmissaoLinkQuery, Types.TransmissaoLinkQueryVariables>(
    TransmissaoLinkDocument,
    baseOptions
  )
}
export function useTransmissaoLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.TransmissaoLinkQuery, Types.TransmissaoLinkQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.TransmissaoLinkQuery, Types.TransmissaoLinkQueryVariables>(
    TransmissaoLinkDocument,
    baseOptions
  )
}
export type TransmissaoLinkQueryHookResult = ReturnType<typeof useTransmissaoLinkQuery>
export type TransmissaoLinkLazyQueryHookResult = ReturnType<typeof useTransmissaoLinkLazyQuery>
export type TransmissaoLinkQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoLinkQuery,
  Types.TransmissaoLinkQueryVariables
>
export const TestarConexaoLinkTransmissaoDocument = gql`
  mutation TestarConexaoLinkTransmissao($id: ID!) {
    testarConexaoLinkTransmissao(id: $id) {
      id
      hostname
      name
      ativo
      statusConexao
      dataTesteConexao
    }
  }
`
export type TestarConexaoLinkTransmissaoMutationFn = ApolloReactCommon.MutationFunction<
  Types.TestarConexaoLinkTransmissaoMutation,
  Types.TestarConexaoLinkTransmissaoMutationVariables
>

/**
 * __useTestarConexaoLinkTransmissaoMutation__
 *
 * To run a mutation, you first call `useTestarConexaoLinkTransmissaoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestarConexaoLinkTransmissaoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testarConexaoLinkTransmissaoMutation, { data, loading, error }] = useTestarConexaoLinkTransmissaoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestarConexaoLinkTransmissaoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.TestarConexaoLinkTransmissaoMutation,
    Types.TestarConexaoLinkTransmissaoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.TestarConexaoLinkTransmissaoMutation,
    Types.TestarConexaoLinkTransmissaoMutationVariables
  >(TestarConexaoLinkTransmissaoDocument, baseOptions)
}
export type TestarConexaoLinkTransmissaoMutationHookResult = ReturnType<typeof useTestarConexaoLinkTransmissaoMutation>
export type TestarConexaoLinkTransmissaoMutationResult = ApolloReactCommon.MutationResult<
  Types.TestarConexaoLinkTransmissaoMutation
>
export type TestarConexaoLinkTransmissaoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.TestarConexaoLinkTransmissaoMutation,
  Types.TestarConexaoLinkTransmissaoMutationVariables
>
export const UpdateTransmissaoLinkDocument = gql`
  mutation UpdateTransmissaoLink($form: TransmissaoLinkUpdateDto!) {
    updateTransmissaoLink(form: $form)
  }
`
export type UpdateTransmissaoLinkMutationFn = ApolloReactCommon.MutationFunction<
  Types.UpdateTransmissaoLinkMutation,
  Types.UpdateTransmissaoLinkMutationVariables
>

/**
 * __useUpdateTransmissaoLinkMutation__
 *
 * To run a mutation, you first call `useUpdateTransmissaoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransmissaoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransmissaoLinkMutation, { data, loading, error }] = useUpdateTransmissaoLinkMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useUpdateTransmissaoLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UpdateTransmissaoLinkMutation,
    Types.UpdateTransmissaoLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.UpdateTransmissaoLinkMutation,
    Types.UpdateTransmissaoLinkMutationVariables
  >(UpdateTransmissaoLinkDocument, baseOptions)
}
export type UpdateTransmissaoLinkMutationHookResult = ReturnType<typeof useUpdateTransmissaoLinkMutation>
export type UpdateTransmissaoLinkMutationResult = ApolloReactCommon.MutationResult<Types.UpdateTransmissaoLinkMutation>
export type UpdateTransmissaoLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UpdateTransmissaoLinkMutation,
  Types.UpdateTransmissaoLinkMutationVariables
>
export const TransmissaoRecebimentoLotesDocument = gql`
  query TransmissaoRecebimentoLotes($input: TransmissaoRecebimentoLoteFiltro!) {
    transmissaoRecebimentoLotes(input: $input) {
      content {
        id
        numeroLote
        responsavel
        processado
        data
        counts {
          fichasValidas
          fichasInvalidas
          totalFichas
          fichasRepetidas
        }
        possuiInconsistencia
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTransmissaoRecebimentoLotesQuery__
 *
 * To run a query within a React component, call `useTransmissaoRecebimentoLotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoRecebimentoLotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoRecebimentoLotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransmissaoRecebimentoLotesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TransmissaoRecebimentoLotesQuery,
    Types.TransmissaoRecebimentoLotesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TransmissaoRecebimentoLotesQuery,
    Types.TransmissaoRecebimentoLotesQueryVariables
  >(TransmissaoRecebimentoLotesDocument, baseOptions)
}
export function useTransmissaoRecebimentoLotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TransmissaoRecebimentoLotesQuery,
    Types.TransmissaoRecebimentoLotesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TransmissaoRecebimentoLotesQuery,
    Types.TransmissaoRecebimentoLotesQueryVariables
  >(TransmissaoRecebimentoLotesDocument, baseOptions)
}
export type TransmissaoRecebimentoLotesQueryHookResult = ReturnType<typeof useTransmissaoRecebimentoLotesQuery>
export type TransmissaoRecebimentoLotesLazyQueryHookResult = ReturnType<typeof useTransmissaoRecebimentoLotesLazyQuery>
export type TransmissaoRecebimentoLotesQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoRecebimentoLotesQuery,
  Types.TransmissaoRecebimentoLotesQueryVariables
>
export const AlterarHorarioGeracaoLotesProcessamentoFichasDocument = gql`
  mutation AlterarHorarioGeracaoLotesProcessamentoFichas($horario: LocalTime!) {
    alterarHorarioGeracaoLotesProcessamentoFichas(horario: $horario)
  }
`
export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationFn = ApolloReactCommon.MutationFunction<
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation,
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables
>

/**
 * __useAlterarHorarioGeracaoLotesProcessamentoFichasMutation__
 *
 * To run a mutation, you first call `useAlterarHorarioGeracaoLotesProcessamentoFichasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAlterarHorarioGeracaoLotesProcessamentoFichasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [alterarHorarioGeracaoLotesProcessamentoFichasMutation, { data, loading, error }] = useAlterarHorarioGeracaoLotesProcessamentoFichasMutation({
 *   variables: {
 *      horario: // value for 'horario'
 *   },
 * });
 */
export function useAlterarHorarioGeracaoLotesProcessamentoFichasMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation,
    Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation,
    Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables
  >(AlterarHorarioGeracaoLotesProcessamentoFichasDocument, baseOptions)
}
export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationHookResult = ReturnType<
  typeof useAlterarHorarioGeracaoLotesProcessamentoFichasMutation
>
export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationResult = ApolloReactCommon.MutationResult<
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation
>
export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutation,
  Types.AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables
>
export const LoadHorarioGeracaoLotesProcessamentoFichasDocument = gql`
  query LoadHorarioGeracaoLotesProcessamentoFichas {
    loadHorarioGeracaoLotesProcessamentoFichas
  }
`

/**
 * __useLoadHorarioGeracaoLotesProcessamentoFichasQuery__
 *
 * To run a query within a React component, call `useLoadHorarioGeracaoLotesProcessamentoFichasQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadHorarioGeracaoLotesProcessamentoFichasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadHorarioGeracaoLotesProcessamentoFichasQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadHorarioGeracaoLotesProcessamentoFichasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
  >(LoadHorarioGeracaoLotesProcessamentoFichasDocument, baseOptions)
}
export function useLoadHorarioGeracaoLotesProcessamentoFichasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
    Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
  >(LoadHorarioGeracaoLotesProcessamentoFichasDocument, baseOptions)
}
export type LoadHorarioGeracaoLotesProcessamentoFichasQueryHookResult = ReturnType<
  typeof useLoadHorarioGeracaoLotesProcessamentoFichasQuery
>
export type LoadHorarioGeracaoLotesProcessamentoFichasLazyQueryHookResult = ReturnType<
  typeof useLoadHorarioGeracaoLotesProcessamentoFichasLazyQuery
>
export type LoadHorarioGeracaoLotesProcessamentoFichasQueryResult = ApolloReactCommon.QueryResult<
  Types.LoadHorarioGeracaoLotesProcessamentoFichasQuery,
  Types.LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables
>
export const LoteEnvioDocument = gql`
  query LoteEnvio($idLote: ID!, $filtroHistorico: HistoricoExportacaoFiltro!) {
    loteEnvio(idLote: $idLote) {
      id
      dataCriacao
      quantidadeFichas
      nodos {
        id
        dataEnvio
        nodeNome
        statusEnvio
      }
      historicoExportacao(filtro: $filtroHistorico) {
        content {
          id
          nomeProfissional
          dataExportacao
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLoteEnvioQuery__
 *
 * To run a query within a React component, call `useLoteEnvioQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoteEnvioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoteEnvioQuery({
 *   variables: {
 *      idLote: // value for 'idLote'
 *      filtroHistorico: // value for 'filtroHistorico'
 *   },
 * });
 */
export function useLoteEnvioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>(LoteEnvioDocument, baseOptions)
}
export function useLoteEnvioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>(
    LoteEnvioDocument,
    baseOptions
  )
}
export type LoteEnvioQueryHookResult = ReturnType<typeof useLoteEnvioQuery>
export type LoteEnvioLazyQueryHookResult = ReturnType<typeof useLoteEnvioLazyQuery>
export type LoteEnvioQueryResult = ApolloReactCommon.QueryResult<Types.LoteEnvioQuery, Types.LoteEnvioQueryVariables>
export const GerarLotesDocument = gql`
  mutation GerarLotes {
    gerarLote
  }
`
export type GerarLotesMutationFn = ApolloReactCommon.MutationFunction<
  Types.GerarLotesMutation,
  Types.GerarLotesMutationVariables
>

/**
 * __useGerarLotesMutation__
 *
 * To run a mutation, you first call `useGerarLotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGerarLotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gerarLotesMutation, { data, loading, error }] = useGerarLotesMutation({
 *   variables: {
 *   },
 * });
 */
export function useGerarLotesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<Types.GerarLotesMutation, Types.GerarLotesMutationVariables>
) {
  return ApolloReactHooks.useMutation<Types.GerarLotesMutation, Types.GerarLotesMutationVariables>(
    GerarLotesDocument,
    baseOptions
  )
}
export type GerarLotesMutationHookResult = ReturnType<typeof useGerarLotesMutation>
export type GerarLotesMutationResult = ApolloReactCommon.MutationResult<Types.GerarLotesMutation>
export type GerarLotesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.GerarLotesMutation,
  Types.GerarLotesMutationVariables
>
export const OnLoteGeradoDocument = gql`
  subscription onLoteGerado {
    onLoteGerado {
      message
    }
  }
`

/**
 * __useOnLoteGeradoSubscription__
 *
 * To run a query within a React component, call `useOnLoteGeradoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnLoteGeradoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnLoteGeradoSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnLoteGeradoSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    Types.OnLoteGeradoSubscription,
    Types.OnLoteGeradoSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<Types.OnLoteGeradoSubscription, Types.OnLoteGeradoSubscriptionVariables>(
    OnLoteGeradoDocument,
    baseOptions
  )
}
export type OnLoteGeradoSubscriptionHookResult = ReturnType<typeof useOnLoteGeradoSubscription>
export type OnLoteGeradoSubscriptionResult = ApolloReactCommon.SubscriptionResult<Types.OnLoteGeradoSubscription>
export const LotesEnvioDocument = gql`
  query LotesEnvio($input: LotesEnvioFiltro!) {
    lotesEnvio(input: $input) {
      content {
        id
        statusEnvio
        dataCriacao
        quantidadeFichas
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLotesEnvioQuery__
 *
 * To run a query within a React component, call `useLotesEnvioQuery` and pass it any options that fit your needs.
 * When your component renders, `useLotesEnvioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLotesEnvioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLotesEnvioQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>(
    LotesEnvioDocument,
    baseOptions
  )
}
export function useLotesEnvioLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>(
    LotesEnvioDocument,
    baseOptions
  )
}
export type LotesEnvioQueryHookResult = ReturnType<typeof useLotesEnvioQuery>
export type LotesEnvioLazyQueryHookResult = ReturnType<typeof useLotesEnvioLazyQuery>
export type LotesEnvioQueryResult = ApolloReactCommon.QueryResult<Types.LotesEnvioQuery, Types.LotesEnvioQueryVariables>
export const TransmissaoRecebimentoCnesDetailItemsDocument = gql`
  query TransmissaoRecebimentoCnesDetailItems($input: TransmissaoRecebimentoCnesDetailFiltro!) {
    transmissaoRecebimentoCnesDetailItems(input: $input) {
      nomeEstabelecimento
      tipoFicha
      quantidade
    }
  }
`

/**
 * __useTransmissaoRecebimentoCnesDetailItemsQuery__
 *
 * To run a query within a React component, call `useTransmissaoRecebimentoCnesDetailItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoRecebimentoCnesDetailItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoRecebimentoCnesDetailItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransmissaoRecebimentoCnesDetailItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TransmissaoRecebimentoCnesDetailItemsQuery,
    Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TransmissaoRecebimentoCnesDetailItemsQuery,
    Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
  >(TransmissaoRecebimentoCnesDetailItemsDocument, baseOptions)
}
export function useTransmissaoRecebimentoCnesDetailItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TransmissaoRecebimentoCnesDetailItemsQuery,
    Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TransmissaoRecebimentoCnesDetailItemsQuery,
    Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
  >(TransmissaoRecebimentoCnesDetailItemsDocument, baseOptions)
}
export type TransmissaoRecebimentoCnesDetailItemsQueryHookResult = ReturnType<
  typeof useTransmissaoRecebimentoCnesDetailItemsQuery
>
export type TransmissaoRecebimentoCnesDetailItemsLazyQueryHookResult = ReturnType<
  typeof useTransmissaoRecebimentoCnesDetailItemsLazyQuery
>
export type TransmissaoRecebimentoCnesDetailItemsQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoRecebimentoCnesDetailItemsQuery,
  Types.TransmissaoRecebimentoCnesDetailItemsQueryVariables
>
export const TransmissaoRecebimentoCnesDocument = gql`
  query TransmissaoRecebimentoCnes($input: TransmissaoRecebimentoCnesFiltro!) {
    transmissaoRecebimentoCnes(input: $input) {
      content {
        numCnes
        mesRecebimento
        anoRecebimento
        fichasProcessadas
        estabelecimentoOrigem
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useTransmissaoRecebimentoCnesQuery__
 *
 * To run a query within a React component, call `useTransmissaoRecebimentoCnesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoRecebimentoCnesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoRecebimentoCnesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransmissaoRecebimentoCnesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.TransmissaoRecebimentoCnesQuery,
    Types.TransmissaoRecebimentoCnesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.TransmissaoRecebimentoCnesQuery,
    Types.TransmissaoRecebimentoCnesQueryVariables
  >(TransmissaoRecebimentoCnesDocument, baseOptions)
}
export function useTransmissaoRecebimentoCnesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.TransmissaoRecebimentoCnesQuery,
    Types.TransmissaoRecebimentoCnesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.TransmissaoRecebimentoCnesQuery,
    Types.TransmissaoRecebimentoCnesQueryVariables
  >(TransmissaoRecebimentoCnesDocument, baseOptions)
}
export type TransmissaoRecebimentoCnesQueryHookResult = ReturnType<typeof useTransmissaoRecebimentoCnesQuery>
export type TransmissaoRecebimentoCnesLazyQueryHookResult = ReturnType<typeof useTransmissaoRecebimentoCnesLazyQuery>
export type TransmissaoRecebimentoCnesQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoRecebimentoCnesQuery,
  Types.TransmissaoRecebimentoCnesQueryVariables
>
export const ExistemFichasParaProcessarDocument = gql`
  query ExistemFichasParaProcessar {
    existemFichasParaProcessar
  }
`

/**
 * __useExistemFichasParaProcessarQuery__
 *
 * To run a query within a React component, call `useExistemFichasParaProcessarQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistemFichasParaProcessarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistemFichasParaProcessarQuery({
 *   variables: {
 *   },
 * });
 */
export function useExistemFichasParaProcessarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.ExistemFichasParaProcessarQuery,
    Types.ExistemFichasParaProcessarQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Types.ExistemFichasParaProcessarQuery,
    Types.ExistemFichasParaProcessarQueryVariables
  >(ExistemFichasParaProcessarDocument, baseOptions)
}
export function useExistemFichasParaProcessarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.ExistemFichasParaProcessarQuery,
    Types.ExistemFichasParaProcessarQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Types.ExistemFichasParaProcessarQuery,
    Types.ExistemFichasParaProcessarQueryVariables
  >(ExistemFichasParaProcessarDocument, baseOptions)
}
export type ExistemFichasParaProcessarQueryHookResult = ReturnType<typeof useExistemFichasParaProcessarQuery>
export type ExistemFichasParaProcessarLazyQueryHookResult = ReturnType<typeof useExistemFichasParaProcessarLazyQuery>
export type ExistemFichasParaProcessarQueryResult = ApolloReactCommon.QueryResult<
  Types.ExistemFichasParaProcessarQuery,
  Types.ExistemFichasParaProcessarQueryVariables
>
export const OnTransmissaoUpdatedDocument = gql`
  subscription onTransmissaoUpdated {
    transmissaoUpdated {
      id
      importacaoArquivo {
        dataEvento
        status
        message
      }
    }
  }
`

/**
 * __useOnTransmissaoUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnTransmissaoUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTransmissaoUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTransmissaoUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnTransmissaoUpdatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    Types.OnTransmissaoUpdatedSubscription,
    Types.OnTransmissaoUpdatedSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    Types.OnTransmissaoUpdatedSubscription,
    Types.OnTransmissaoUpdatedSubscriptionVariables
  >(OnTransmissaoUpdatedDocument, baseOptions)
}
export type OnTransmissaoUpdatedSubscriptionHookResult = ReturnType<typeof useOnTransmissaoUpdatedSubscription>
export type OnTransmissaoUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  Types.OnTransmissaoUpdatedSubscription
>
export const TransmissaoDocument = gql`
  query Transmissao {
    transmissao {
      id
      importacaoArquivo {
        dataEvento
        status
        message
      }
    }
  }
`

/**
 * __useTransmissaoQuery__
 *
 * To run a query within a React component, call `useTransmissaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissaoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissaoQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransmissaoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.TransmissaoQuery, Types.TransmissaoQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.TransmissaoQuery, Types.TransmissaoQueryVariables>(
    TransmissaoDocument,
    baseOptions
  )
}
export function useTransmissaoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.TransmissaoQuery, Types.TransmissaoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<Types.TransmissaoQuery, Types.TransmissaoQueryVariables>(
    TransmissaoDocument,
    baseOptions
  )
}
export type TransmissaoQueryHookResult = ReturnType<typeof useTransmissaoQuery>
export type TransmissaoLazyQueryHookResult = ReturnType<typeof useTransmissaoLazyQuery>
export type TransmissaoQueryResult = ApolloReactCommon.QueryResult<
  Types.TransmissaoQuery,
  Types.TransmissaoQueryVariables
>
export const AtivarTipoServicoDocument = gql`
  mutation AtivarTipoServico($unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput!) {
    ativarTipoServico(unidadeSaudeTipoServicoFormInput: $unidadeSaudeTipoServicoFormInput) {
      unidadeSaudeId
      tipoServicoId
    }
  }
`
export type AtivarTipoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.AtivarTipoServicoMutation,
  Types.AtivarTipoServicoMutationVariables
>

/**
 * __useAtivarTipoServicoMutation__
 *
 * To run a mutation, you first call `useAtivarTipoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtivarTipoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ativarTipoServicoMutation, { data, loading, error }] = useAtivarTipoServicoMutation({
 *   variables: {
 *      unidadeSaudeTipoServicoFormInput: // value for 'unidadeSaudeTipoServicoFormInput'
 *   },
 * });
 */
export function useAtivarTipoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.AtivarTipoServicoMutation,
    Types.AtivarTipoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.AtivarTipoServicoMutation, Types.AtivarTipoServicoMutationVariables>(
    AtivarTipoServicoDocument,
    baseOptions
  )
}
export type AtivarTipoServicoMutationHookResult = ReturnType<typeof useAtivarTipoServicoMutation>
export type AtivarTipoServicoMutationResult = ApolloReactCommon.MutationResult<Types.AtivarTipoServicoMutation>
export type AtivarTipoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.AtivarTipoServicoMutation,
  Types.AtivarTipoServicoMutationVariables
>
export const DesativarTipoServicoDocument = gql`
  mutation DesativarTipoServico($unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput!) {
    desativarTipoServico(unidadeSaudeTipoServicoFormInput: $unidadeSaudeTipoServicoFormInput) {
      unidadeSaudeId
      tipoServicoId
    }
  }
`
export type DesativarTipoServicoMutationFn = ApolloReactCommon.MutationFunction<
  Types.DesativarTipoServicoMutation,
  Types.DesativarTipoServicoMutationVariables
>

/**
 * __useDesativarTipoServicoMutation__
 *
 * To run a mutation, you first call `useDesativarTipoServicoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesativarTipoServicoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [desativarTipoServicoMutation, { data, loading, error }] = useDesativarTipoServicoMutation({
 *   variables: {
 *      unidadeSaudeTipoServicoFormInput: // value for 'unidadeSaudeTipoServicoFormInput'
 *   },
 * });
 */
export function useDesativarTipoServicoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.DesativarTipoServicoMutation,
    Types.DesativarTipoServicoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.DesativarTipoServicoMutation, Types.DesativarTipoServicoMutationVariables>(
    DesativarTipoServicoDocument,
    baseOptions
  )
}
export type DesativarTipoServicoMutationHookResult = ReturnType<typeof useDesativarTipoServicoMutation>
export type DesativarTipoServicoMutationResult = ApolloReactCommon.MutationResult<Types.DesativarTipoServicoMutation>
export type DesativarTipoServicoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.DesativarTipoServicoMutation,
  Types.DesativarTipoServicoMutationVariables
>
export const UnidadeSaudeDetailDocument = gql`
  query UnidadeSaudeDetail($id: ID!) {
    unidadeSaude(id: $id) {
      id
      nome
      cnes
      cnpj
      email
      telefone1
      telefone2
      fax
      tipo {
        id
        descricao
      }
      complexidades {
        id
        nome
      }
      ativo
      endereco {
        cep
        municipio {
          nome
        }
        uf {
          sigla
        }
        tipoLogradouro {
          nome
        }
        logradouro
        numero
        semNumero
        complemento
        pontoReferencia
        bairro
      }
      actions {
        permissaoAtivarInativarTipoServico {
          enabled
          hint
        }
      }
    }
  }
`

/**
 * __useUnidadeSaudeDetailQuery__
 *
 * To run a query within a React component, call `useUnidadeSaudeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeSaudeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeSaudeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnidadeSaudeDetailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.UnidadeSaudeDetailQuery, Types.UnidadeSaudeDetailQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.UnidadeSaudeDetailQuery, Types.UnidadeSaudeDetailQueryVariables>(
    UnidadeSaudeDetailDocument,
    baseOptions
  )
}
export function useUnidadeSaudeDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeSaudeDetailQuery,
    Types.UnidadeSaudeDetailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnidadeSaudeDetailQuery, Types.UnidadeSaudeDetailQueryVariables>(
    UnidadeSaudeDetailDocument,
    baseOptions
  )
}
export type UnidadeSaudeDetailQueryHookResult = ReturnType<typeof useUnidadeSaudeDetailQuery>
export type UnidadeSaudeDetailLazyQueryHookResult = ReturnType<typeof useUnidadeSaudeDetailLazyQuery>
export type UnidadeSaudeDetailQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeSaudeDetailQuery,
  Types.UnidadeSaudeDetailQueryVariables
>
export const UnidadeSaudeTipoServicoDocument = gql`
  query UnidadeSaudeTipoServico($input: UnidadeSaudeTipoServicoQueryInput!) {
    unidadeSaudeTiposServico(input: $input) {
      content {
        tipoServico {
          id
          nome
        }
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useUnidadeSaudeTipoServicoQuery__
 *
 * To run a query within a React component, call `useUnidadeSaudeTipoServicoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeSaudeTipoServicoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeSaudeTipoServicoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadeSaudeTipoServicoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Types.UnidadeSaudeTipoServicoQuery,
    Types.UnidadeSaudeTipoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<Types.UnidadeSaudeTipoServicoQuery, Types.UnidadeSaudeTipoServicoQueryVariables>(
    UnidadeSaudeTipoServicoDocument,
    baseOptions
  )
}
export function useUnidadeSaudeTipoServicoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeSaudeTipoServicoQuery,
    Types.UnidadeSaudeTipoServicoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnidadeSaudeTipoServicoQuery, Types.UnidadeSaudeTipoServicoQueryVariables>(
    UnidadeSaudeTipoServicoDocument,
    baseOptions
  )
}
export type UnidadeSaudeTipoServicoQueryHookResult = ReturnType<typeof useUnidadeSaudeTipoServicoQuery>
export type UnidadeSaudeTipoServicoLazyQueryHookResult = ReturnType<typeof useUnidadeSaudeTipoServicoLazyQuery>
export type UnidadeSaudeTipoServicoQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeSaudeTipoServicoQuery,
  Types.UnidadeSaudeTipoServicoQueryVariables
>
export const UnidadeSaudeTableDocument = gql`
  query UnidadeSaudeTable($input: UnidadesSaudeQueryInput!) {
    unidadesSaude(input: $input) {
      content {
        id
        cnes
        nome
        telefone1
        telefone2
        endereco {
          bairro
        }
        ativo
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useUnidadeSaudeTableQuery__
 *
 * To run a query within a React component, call `useUnidadeSaudeTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnidadeSaudeTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnidadeSaudeTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnidadeSaudeTableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<Types.UnidadeSaudeTableQuery, Types.UnidadeSaudeTableQueryVariables>
) {
  return ApolloReactHooks.useQuery<Types.UnidadeSaudeTableQuery, Types.UnidadeSaudeTableQueryVariables>(
    UnidadeSaudeTableDocument,
    baseOptions
  )
}
export function useUnidadeSaudeTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Types.UnidadeSaudeTableQuery,
    Types.UnidadeSaudeTableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<Types.UnidadeSaudeTableQuery, Types.UnidadeSaudeTableQueryVariables>(
    UnidadeSaudeTableDocument,
    baseOptions
  )
}
export type UnidadeSaudeTableQueryHookResult = ReturnType<typeof useUnidadeSaudeTableQuery>
export type UnidadeSaudeTableLazyQueryHookResult = ReturnType<typeof useUnidadeSaudeTableLazyQuery>
export type UnidadeSaudeTableQueryResult = ApolloReactCommon.QueryResult<
  Types.UnidadeSaudeTableQuery,
  Types.UnidadeSaudeTableQueryVariables
>
export const UnificaCadastrosDocument = gql`
  mutation UnificaCadastros($input: UnificacaoCadastroInput!) {
    unificaCadastros(input: $input)
  }
`
export type UnificaCadastrosMutationFn = ApolloReactCommon.MutationFunction<
  Types.UnificaCadastrosMutation,
  Types.UnificaCadastrosMutationVariables
>

/**
 * __useUnificaCadastrosMutation__
 *
 * To run a mutation, you first call `useUnificaCadastrosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnificaCadastrosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unificaCadastrosMutation, { data, loading, error }] = useUnificaCadastrosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnificaCadastrosMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Types.UnificaCadastrosMutation,
    Types.UnificaCadastrosMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<Types.UnificaCadastrosMutation, Types.UnificaCadastrosMutationVariables>(
    UnificaCadastrosDocument,
    baseOptions
  )
}
export type UnificaCadastrosMutationHookResult = ReturnType<typeof useUnificaCadastrosMutation>
export type UnificaCadastrosMutationResult = ApolloReactCommon.MutationResult<Types.UnificaCadastrosMutation>
export type UnificaCadastrosMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Types.UnificaCadastrosMutation,
  Types.UnificaCadastrosMutationVariables
>
