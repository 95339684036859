import { AlterarPeriodoInatividade, AlterarPeriodoInatividadeVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const ALTERAR_PERIODO_INATIVIDADE = gql`
  mutation AlterarPeriodoInatividade($periodo: Int) {
    alterarPeriodoInatividade(periodo: $periodo) {
      periodoInatividade
    }
  }
`

export function useAlterarPeriodoInatividade() {
  return useMutation<AlterarPeriodoInatividade, AlterarPeriodoInatividadeVariables>(ALTERAR_PERIODO_INATIVIDADE)
}
