import { Heading, useStyles } from 'bold-ui'
import { alert } from 'components/alert'
import { Form, SwitchField } from 'components/form'
import { useSaveCompartilhamentoProntuarioMutation } from 'graphql/hooks.generated'
import { CompartilhamentoProntuarioInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

export interface CompartilhamentoProntuarioFormProps {
  initialValue: CompartilhamentoProntuarioInput
  reload(): any
}

export const CompartilhamentoProntuarioForm = (props: CompartilhamentoProntuarioFormProps) => {
  const { initialValue, reload } = props
  const { css } = useStyles()

  const [saveCompartilhamento] = useSaveCompartilhamentoProntuarioMutation()

  const renderForm = (formProps: FormRenderProps) => {
    const label = initialValue.ativo ? 'Habilitado' : 'Desabilitado'
    return (
      <>
        <Heading level={3} style={{ display: 'inline' }}>
          Compartilhamento de prontuário com todos os municípios da instalação
        </Heading>

        <div className={css({ float: 'right', display: 'inline' })}>
          <SwitchField name='ativo' label={label} onChange={handleSwitchChange(formProps)} />
        </div>
      </>
    )
  }

  const handleSwitchChange = (formProps: FormRenderProps) => e => {
    formProps.handleSubmit()
    reload()
  }

  const handleSubmit = (values: CompartilhamentoProntuarioInput) => {
    return saveCompartilhamento({ variables: { compartilhamentoProntuarioInput: values } }).then(success => {
      const acao = values.ativo ? 'habitado' : 'desabilitado'
      alert('success', `Compartilhamento de prontuário foi ${acao} com sucesso.`)
    })
  }

  return <Form render={renderForm} onSubmit={handleSubmit} initialValues={initialValue} />
}
