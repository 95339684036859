import { Link, Theme, useStyles } from 'bold-ui'
import AlertTreinamento from 'components/AlertTreinamento'
import useSession from 'components/auth/useSession'
import { UserMenu } from 'components/user/UserMenu'
import logo from 'images/esus_white.svg'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const UserHeader = () => {
  const { classes } = useStyles(createStyles)

  const { data } = useSession({ fetchPolicy: 'cache-only' })

  if (!data || !data.acesso) {
    return null
  }

  const { acesso } = data

  return (
    <header>
      <div className={classes.container}>
        <div className={classes.content}>
          <Link component={RouterLink} to={acesso.moduloInicial}>
            <img className={classes.image} src={logo} alt='Logo e-SUS Atenção Primária' />
          </Link>
          <div style={{ flexGrow: 1.0 }} />
          <AlertTreinamento showProducao={false} />
          {acesso && (
            <div className={classes.rightContent}>
              <UserMenu />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    borderBottom: '1px solid ' + theme.pallete.divider,
    background: theme.pallete.primary.c30,
  },
  content: {
    width: '1280px',
    margin: '0 auto',
    padding: '0 4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      width: '1024px',
    },
  },
  image: {
    height: '35px',
  },
  rightContent: {
    display: 'flex',
  },
})
