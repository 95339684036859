import { Icon } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { ButtonLink, PecSwitch, PrivateRoute } from 'components/route'
import { useProfissionalDetailQuery } from 'graphql/hooks.generated'
import React from 'react'
import { Redirect, useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { ProfissionalDadosView } from './ProfissionalDadosView'

interface UrlParams {
  profissionalId: string
}

export function ProfissionalDetailView() {
  const match = useRouteMatch<UrlParams>()
  const { hasAuthorization } = useSession()

  const { data } = useProfissionalDetailQuery({ variables: { id: match.params.profissionalId } })

  if (!data || !data.profissional) {
    return null
  }

  const { profissional } = data

  const renderDados = () => {
    return <ProfissionalDadosView profissional={profissional} />
  }

  return (
    <>
      <Breadcrumb title='Visualização' />

      <PageHeaderSection
        icon='userFilled'
        title={profissional?.nome}
        subtitle={profissional?.cns && `CNS ${profissional.cns}`}
        action={
          <CheckPermission permission={Permissions.visualizarProfissionais.cadastrarEditarEExcluir}>
            <ButtonLink to={`${match.url}/edit`} size='medium' kind='primary'>
              <Icon icon='penOutline' style={{ marginRight: '0.5rem' }} />
              Editar cadastro
            </ButtonLink>
          </CheckPermission>
        }
      />

      <PageContent>
        <PecSwitch>
          {hasAuthorization(Permissions.visualizarProfissionais) && (
            <Redirect exact path={`${match.url}`} to={`${match.url}/dados`} />
          )}
          <PrivateRoute
            exact
            path={`${match.url}/dados`}
            render={renderDados}
            permission={Permissions.visualizarProfissionais}
          />
        </PecSwitch>
      </PageContent>
    </>
  )
}
