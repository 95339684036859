import { CfgLotacaoAgendaOnlineQuery } from 'api/schema'
import { Alert, Button, Cell, FormControl, Grid, Link, VFlow } from 'bold-ui'
import { confirm } from 'components/confirm'
import { Form } from 'components/form'
import { ChaveAgendaOnlineField } from 'components/form/field/ChaveAgendaOnlineField'
import { ContraChaveField } from 'components/form/field/ContraChaveField'
import { useQuery } from 'graphql/hooks'
import { AtivacaoAgendaOnlineInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, required } from 'util/validation'

import { CFG_LOTACAO_AGENDA_ONLINE_QUERY } from './HasLotacoesAgendaOnlineQuery'

export interface ConfiguracaoAgendaOnlineChaveFormProps {
  initialValues?: AtivacaoAgendaOnlineInput
  onSubmit(values: AtivacaoAgendaOnlineInput): void
}

const validator = createValidator<AtivacaoAgendaOnlineInput>({
  contraChave: [required],
})

export function ConfiguracaoAgendaOnlineChaveForm(props: ConfiguracaoAgendaOnlineChaveFormProps) {
  const { initialValues, onSubmit } = props
  const { data } = useQuery<CfgLotacaoAgendaOnlineQuery>(CFG_LOTACAO_AGENDA_ONLINE_QUERY)

  if (!data) {
    return null
  }

  const handleOnClick = (formProps: FormRenderProps<AtivacaoAgendaOnlineInput>) => e => {
    if (data.conexao.hasLotacoesConfiguradasOnline) {
      confirm({
        title: `Deseja disponibilizar os horários configurados para agendamentos online?`,
        body:
          `Existem horários configurados para o agendamento online. Ao habilitar esse serviço, ` +
          `eles serão disponibilizados para agendamentos via aplicativo. Os horários online de ` +
          `profissionais que tiveram sua configuração de agenda alterada deverão ser reconfigurados.`,
        confirmLabel: 'Sim',
        cancelLabel: 'Não',
        type: 'primary',
        onConfirm: () => {
          return formProps.form.submit()
        },
      })()
    } else {
      return formProps.form.submit()
    }
  }

  const renderForm = (formProps: FormRenderProps<AtivacaoAgendaOnlineInput>) => (
    <VFlow>
      <Alert type='info' inline>
        Para gerar a contra-chave, acesse o{' '}
        <Link target='_blank' href='https://egestorab.saude.gov.br/paginas/login.xhtml'>
          Portal do Gestor.
        </Link>
      </Alert>
      <Grid gap={1}>
        <Cell size={3}>
          <ChaveAgendaOnlineField label='Chave' name='chave' disabled />
        </Cell>
        <Cell size={5}>
          <ContraChaveField label='Contra-chave' name='contraChave' parse={c => c} />
        </Cell>
        <Cell size={4} alignSelf='flex-start'>
          <FormControl label={<span>&nbsp;</span>}>
            <Button kind='primary' size='small' onClick={handleOnClick(formProps)}>
              Habilitar
            </Button>
          </FormControl>
        </Cell>
      </Grid>
    </VFlow>
  )

  return (
    <Form<AtivacaoAgendaOnlineInput>
      onSubmit={onSubmit}
      render={renderForm}
      initialValues={initialValues}
      validate={validator}
    />
  )
}
