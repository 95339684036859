import { Button, ButtonProps } from 'bold-ui'
import React from 'react'
import { useFormState } from 'react-final-form'

export interface SubmitButtonProps extends ButtonProps {
  handleSubmit: (event?: React.SyntheticEvent<HTMLFormElement>) => void
}

export function SubmitButton(props: SubmitButtonProps) {
  const { handleSubmit, ...rest } = props
  const { submitting } = useFormState({ subscription: { submitting: true } })

  return <Button kind='primary' type='submit' loading={submitting} onClick={handleSubmit} {...rest} />
}
