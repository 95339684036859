import { Button, Icon, Theme, useStyles } from 'bold-ui'
import { blue, gray } from 'bold-ui/lib/styles/colors'
import React, { CSSProperties } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

interface ConfiguracaoAgendaOnlineToggleButtonProps {
  name: string
  disabled?: boolean
}

export function ConfiguracaoAgendaOnlineToggleButton(props: ConfiguracaoAgendaOnlineToggleButtonProps) {
  const { classes, css } = useStyles(createStyles)

  const render = (fieldProps: FieldRenderProps<any, any>) => {
    const {
      input: { value },
    } = fieldProps

    const handleClicked = target => {
      if (!props.disabled) {
        fieldProps.input.onChange({ ...value, ativo: !value.ativo })
      }
    }

    const title = props.disabled
      ? 'Fora do período de trabalho do profissional'
      : value.ativo
      ? 'Remover horário'
      : 'Disponibilizar horário'

    return (
      <Button
        style={css(classes.button, value.ativo && classes.active, props.disabled && classes.disabled)}
        disabled={props.disabled}
        title={title}
        onClick={handleClicked}
      >
        {!props.disabled && <Icon fill='info' icon='checkDefault' />}
      </Button>
    )
  }

  return <Field render={render} {...props} />
}

const createStyles = (theme: Theme) => ({
  button: {
    borderRadius: 0,
    border: 0,
    borderRight: '1px solid' + gray.c80,
    borderBottom: '1px solid' + gray.c80,
    ':last-child': {
      borderBottom: 0,
    },
    ':not(:disabled):hover': {
      background: gray.c90,

      svg: {
        fill: theme.pallete.text.disabled,
      },
    },
    ':focus': {
      zIndex: 1,
    },
    svg: {
      fill: 'transparent',
    },
  } as CSSProperties,

  active: {
    border: '0.5px solid ' + blue.c40,
    ':last-child': {
      borderBottom: '0.5px solid ' + blue.c40,
    },
    borderRadius: '2px',
    background: blue.c90,
    ':not(:disabled):hover': {
      background: blue.c80,
      svg: {
        fill: theme.pallete.status.info.main,
      },
    },
    svg: {
      fill: theme.pallete.status.info.main,
    },
  } as CSSProperties,

  disabled: {
    opacity: 1,
    background:
      'linear-gradient(45deg, #d3d4dd 12.50%, #f0f0f5 12.50%, #f0f0f5 50%, ' +
      '#d3d4dd 50%, #d3d4dd 62.50%, #f0f0f5 62.50%, #f0f0f5 100%);' +
      'background-size: 8.00px 8.00px;',
    ':not(:enabled):hover': {
      cursor: 'not-allowed',
    },
  } as CSSProperties,
})
