import { Alert, Button, Heading, HFlow, Icon, merge, Modal, ModalBody, ModalFooter, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormFooter, RadioField, SubmitButton } from 'components/form'
import { FormApi } from 'final-form'
import { useBuscaCidadaoRelatoriosOperacionaisLazyQuery } from 'graphql/hooks.generated'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { downloadRelatorio } from '../downloadRelatorio'

interface AcompanhamentoSaudeRelatorioModalProps {
  disableGerarRelatorio: boolean
  filter: AcompanhamentoCondicaoSaudeQueryInput
}
export interface AcompanhamentoSaudeRelatorioModel {
  tipoRelatorio: TipoRelatorio
}

export enum TipoRelatorio {
  CRIANCA = 'CRIANCA',
  GESTANTE = 'GESTANTE',
  RISCO_CARDIOVASCULAR = 'RISCO_CARDIOVASCULAR',
}

export function AcompanhamentoSaudeRelatorioModal(props: AcompanhamentoSaudeRelatorioModalProps) {
  const path = metaPath<AcompanhamentoSaudeRelatorioModel>()
  const [isOpen, setOpen] = useState(false)
  const [executeQuery, { loading, data }] = useBuscaCidadaoRelatoriosOperacionaisLazyQuery()
  const alert = useAlert()

  useEffect(() => {
    if (data?.acompanhamentoCondicaoSaude) {
      setOpen(true)
    }
  }, [data])

  const searchIdCidadaos = () => {
    if (!props.disableGerarRelatorio) {
      executeQuery({ variables: { input: merge({}, props.filter, { pageParams: { page: 0, size: 500 } }) } })
    }
  }

  const getIdCidadaosToGenerateReport = () => {
    const cidadaos = data?.acompanhamentoCondicaoSaude
    const idCidadaos =
      cidadaos?.content &&
      cidadaos.content.map(cidadao => {
        return cidadao.id
      })

    return idCidadaos
  }

  const renderForm = (props: FormRenderProps<AcompanhamentoSaudeRelatorioModel>) => {
    const handleCancelar = () => {
      setOpen(false)
      props.form.reset()
    }

    return (
      <Modal size='small' open={isOpen} onClose={handleCancelar} closeOnBackdropClick={false}>
        <ModalBody>
          <Heading level={1}>Gerar relatório operacional</Heading>
          <Alert type='info' style={{ padding: '0.5rem ', margin: '1rem 0' }}>
            Serão mostrados no relatório somente os cidadãos com núcleo familiar formado e que satisfaçam as demais
            regras do respectivo relatório.
          </Alert>
          <p>Selecione qual relatório deseja gerar:</p>
          <VFlow vSpacing={0.5} style={{ marginTop: '1.5rem' }}>
            <RadioField
              name={path.tipoRelatorio}
              value={TipoRelatorio.CRIANCA}
              label='Relatório operacional de Crianças menores de 5 anos'
            />
            <RadioField
              name={path.tipoRelatorio}
              value={TipoRelatorio.GESTANTE}
              label='Relatório operacional de Gestante/Puérpera'
            />
            <RadioField
              name={path.tipoRelatorio}
              value={TipoRelatorio.RISCO_CARDIOVASCULAR}
              label='Relatório operacional de Risco cardiovascular'
            />
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleCancelar}>Cancelar</Button>
            <Tooltip text={!props.values?.tipoRelatorio && 'Selecione o tipo de relatório'}>
              <SubmitButton kind='primary' disabled={!props.values?.tipoRelatorio} handleSubmit={props.handleSubmit}>
                Gerar relatório
              </SubmitButton>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const handleSubmit = (
    values: AcompanhamentoSaudeRelatorioModel,
    formApi: FormApi<AcompanhamentoSaudeRelatorioModel>
  ) => {
    return downloadRelatorio(values.tipoRelatorio, getIdCidadaosToGenerateReport())
      .then(() => {
        alert('success', 'Relatório operacional gerado com sucesso')
        setOpen(false)
        setTimeout(() => formApi.reset())
      })
      .catch(errorMessage => {
        alert('warning', errorMessage)
        setOpen(false)
        setTimeout(() => formApi.reset())
      })
  }

  const hintBtnGerarRelatorio =
    props.disableGerarRelatorio && props.filter?.pageParams?.size > 500
      ? 'A busca de cidadãos retornou mais de 500 resultados. Refine melhor a busca para poder gerar relatórios operacionais'
      : 'A busca de cidadãos não encontrou nenhum resultado com os filtros aplicados'

  return (
    <>
      <FormFooter>
        <Tooltip text={props.disableGerarRelatorio && hintBtnGerarRelatorio}>
          <Button
            kind='primary'
            style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
            loading={loading}
            disabled={props.disableGerarRelatorio}
            onClick={() => searchIdCidadaos()}
          >
            <Icon icon='fileWithItensOutline' style={{ marginRight: '1rem' }} />
            <span>Gerar relatório operacional</span>
          </Button>
        </Tooltip>
      </FormFooter>
      <Form<AcompanhamentoSaudeRelatorioModel> render={renderForm} onSubmit={handleSubmit} />
    </>
  )
}
