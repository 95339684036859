import { Alert, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

export interface ConfiguracaoAgendaOnlineHeaderSectionProps {
  formProps: FormRenderProps
  submitFailedStatus: boolean
}

export function ConfiguracaoAgendaOnlineHeaderSection(props: ConfiguracaoAgendaOnlineHeaderSectionProps) {
  const { formProps } = props

  return (
    <VFlow vSpacing={1}>
      <Alert type='info' inline>
        Os horários reservados para agendamento online serão disponibilizados no aplicativo Meu digiSUS para que
        cidadãos com CNS válido e que possuem cadastro individual na equipe do profissional possam agendar consultas.
      </Alert>

      <HFlow hSpacing={0}>
        <Text fontWeight='bold'>
          Selecione os horários que serão disponibilizados para agendamento via aplicativo Meu digiSUS
        </Text>
        <Tooltip
          text={
            'Os horários disponibilizados para agenda online podem sempre receber Reservas na agenda do PEC.' +
            ' Os cidadãos poderão agendar esses horários para os próximos 30 dias, não sendo possível agendar' +
            ' com menos de 2 dias de antecedência. Horários do dia atual e do dia seguinte que não tiverem' +
            ' agendamentos realizados serão liberados para agendamentos via PEC.'
          }
        >
          <Icon icon='infoCircleFilled' size={1} style={{ marginLeft: '4px' }} />
        </Tooltip>
      </HFlow>
      {(formProps.hasValidationErrors || formProps.hasSubmitErrors) &&
        props.submitFailedStatus &&
        (!formProps.hasValidationErrors &&
        formProps.submitErrors &&
        'configuracaoHorarioAgendaOnlineTitleErrorField' in formProps.submitErrors ? (
          <Alert type='warning'>É obrigatório selecionar pelo menos um horário para a agenda online.</Alert>
        ) : (
          <Alert type='warning'>Existem campos preenchidos de forma incorreta</Alert>
        ))}
    </VFlow>
  )
}
