import { HeadingSection } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import clearTypename from 'graphql/clearTypename'
import { useCidadaoEditQuery, useCreateCidadaoCadsusMutation } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React, { useMemo, useState } from 'react'

import { CidadaoDadosForm } from '../CidadaoDadosForm'
import { convertToForm } from './convertModelToInput'
import { ModalStatusSincronizacaoCadsus } from './ModalStatusSincronizacaoCadsus'

interface UrlParams {
  cidadaoId: string
}

export function CidadaoEditView() {
  const { match } = useRouter<UrlParams>()
  const cidadaoId = match.params.cidadaoId
  const [sincCadsusState, setSincCadsusState] = useState<boolean>(false)
  const [saveCadsus, { loading, data: resultCadsus }] = useCreateCidadaoCadsusMutation()
  const {
    data: { cidadao },
  } = useCidadaoEditQuery({ variables: { id: cidadaoId } })

  const initialValues = useMemo(() => cidadao && convertToForm(clearTypename(cidadao)), [cidadao])

  const syncCadsus = (id: string) => {
    saveCadsus({ variables: { cidadaoId: id } })
    setSincCadsusState(true)
  }

  if (!cidadao) {
    return null
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/cidadao/${cidadao.id}`} />
      <Breadcrumb title='Edição' />
      <HeadingSection level={1} title='Cadastro do cidadão'>
        <CidadaoDadosForm initialValues={initialValues} sincronizarCadsus={syncCadsus} />
        <ModalStatusSincronizacaoCadsus
          loading={loading}
          result={resultCadsus?.createCidadaoCadsus}
          isOpen={sincCadsusState}
          isEdit={false}
          sincronizarCadsus={syncCadsus}
        />
      </HeadingSection>
    </PageContent>
  )
}
