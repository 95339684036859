import { AlterarNumeroTentativasLogin, AlterarNumeroTentativasLoginVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const ALTERAR_NUMERO_TENTATIVAS_LOGIN = gql`
  mutation AlterarNumeroTentativasLogin($tentativas: Int) {
    alterarNumeroTentativasLogin(tentativas: $tentativas) {
      tentativasLogin
    }
  }
`

export function useAlterarNumeroTentativasLogin() {
  return useMutation<AlterarNumeroTentativasLogin, AlterarNumeroTentativasLoginVariables>(
    ALTERAR_NUMERO_TENTATIVAS_LOGIN
  )
}
