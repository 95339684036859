import { HeadingSection } from 'bold-ui'
import { useInfoProfissionalCpfQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useRouteMatch } from 'react-router'

import { ResetInstaladorNovoProfissionalComponent } from './components/ResetInstaladorNovoProfissionalComponent'
import ResetInstaladorProfissionalExistenteComponent from './components/ResetInstaladorProfissionalExistenteComponent'

interface UrlParams {
  cpfAdmin: string
}

export default function ResetInstaladorView() {
  const { params } = useRouteMatch<UrlParams>()
  const contraChave = window.sessionStorage.getItem('contra-chave')

  const { data, loading } = useInfoProfissionalCpfQuery({
    variables: {
      cpf: params.cpfAdmin,
    },
  })

  if (loading) {
    return null
  }

  return (
    <HeadingSection
      level={1}
      title={!!data.profissionalByCpf?.id ? 'Reset do instalador' : 'Cadastrar novo instalador'}
    >
      {!!data.profissionalByCpf?.id && (
        <ResetInstaladorProfissionalExistenteComponent contraChave={contraChave} cpf={params.cpfAdmin} />
      )}

      {!data?.profissionalByCpf?.id && (
        <ResetInstaladorNovoProfissionalComponent cpf={params.cpfAdmin} contraChave={contraChave} />
      )}
    </HeadingSection>
  )
}
