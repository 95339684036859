import { ExcluirGrupoExame, ExcluirGrupoExameVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export function useExcluirGrupoExame() {
  return useMutation<ExcluirGrupoExame, ExcluirGrupoExameVariables>(EXCLUIR_GRUPOS_EXAMES_MUTATION)
}

const EXCLUIR_GRUPOS_EXAMES_MUTATION = gql`
  mutation ExcluirGrupoExame($id: ID!) {
    excluirGrupoExame(id: $id)
  }
`
