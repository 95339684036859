import { QueryHookOptions, useApolloClient } from '@apollo/react-hooks'
import { wsClient } from 'graphql/client'
import { SessaoDocument, useLogoutMutation, useSessaoQuery } from 'graphql/hooks.generated'
import { SessaoQuery, SessaoQueryVariables } from 'graphql/types.generated'
import { useCallback } from 'react'
import { Permission } from 'types/Permissions'
import { notify } from 'util/multitab'

export default (options: QueryHookOptions<SessaoQuery, SessaoQueryVariables> = {}) => {
  const client = useApolloClient()

  const { data, refetch, loading } = useSessaoQuery({
    fetchPolicy: 'cache-first',
    ...options,
  })

  const [logoutMutation] = useLogoutMutation({
    refetchQueries: [{ query: SessaoDocument }],
    awaitRefetchQueries: true,
  })

  const afterLogout = useCallback(async () => {
    await client.resetStore()
    wsClient.close(true)
    window.localStorage.removeItem('LOGIN')
    window.localStorage.removeItem('LOGOUT')
    window.localStorage.removeItem('PERFIL_SELECIONADO')
    window.localStorage.removeItem('RESET_SESSION')
  }, [client])

  const logout = useCallback(async () => {
    await logoutMutation()
    notify('LOGOUT')
    afterLogout()
  }, [logoutMutation, afterLogout])

  const hasAuthorization = useCallback(
    (permission: Permission) => {
      if (data && data.sessao && data.sessao.recursos) {
        return data.sessao.recursos.indexOf(permission.uri) !== -1
      } else {
        return false
      }
    },
    [data]
  )
  return {
    loading,
    data: data && data.sessao,
    logout,
    afterLogout,
    refresh: () => refetch(),
    hasAuthorization,
  }
}
