import { FechamentoTableQuery_fechamentos } from 'api/schema'
import { Button, DataTable, Icon, Text, Theme, useStyles } from 'bold-ui'
import { useAlert } from 'components/alert'
import { confirm } from 'components/confirm'
import { useErrorHandler } from 'components/error'
import moment from 'moment'
import React, { CSSProperties } from 'react'

import { useFechamentoDeleteMutation } from './FechamentoDeleteMutation'

interface FechamentoTableProps {
  data: FechamentoTableQuery_fechamentos[]
  onUpdated(): void
}

export default function FechamentoTable(props: FechamentoTableProps) {
  const { classes } = useStyles(createStyles)
  const alert = useAlert()

  const handleRejection = useErrorHandler()
  const [mutationExcluirFechamento] = useFechamentoDeleteMutation()

  const handleExludeRow = (row: FechamentoTableQuery_fechamentos) => {
    confirm({
      title: 'Deseja excluir o fechamento?',
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return mutationExcluirFechamento({ variables: { id: row.id } })
          .then(result => {
            alert('success', 'Fechamento excluído')
            props.onUpdated()
          })
          .catch(handleRejection)
      },
    })()
  }

  const renderPeriodo = (row: FechamentoTableQuery_fechamentos) => {
    const dateFormat = 'DD/MM/YYYY'
    const dtInicialFormat = moment(row.dataInicial).format(dateFormat)
    const dtFinalFormat = moment(row.dataFinal).format(dateFormat)
    return (
      <Text>
        {dtInicialFormat}&nbsp;-&nbsp;{dtFinalFormat}
      </Text>
    )
  }

  const renderMotivo = (row: FechamentoTableQuery_fechamentos) => {
    const motivo = row.motivo === 'Outro' ? `${row.especifique} - ${row.motivo}` : row.motivo
    return <Text className={classes.textColumn}>{motivo}</Text>
  }

  const renderBotoes = (row: FechamentoTableQuery_fechamentos) => {
    const excludeRow = () => {
      handleExludeRow(row)
    }

    return (
      <Button size='small' skin='ghost' onClick={excludeRow}>
        <Icon icon='trashOutline' />
      </Button>
    )
  }

  const asFormat = val => moment(val).format('YYYY-MM-DD')
  props.data.sort((a, b) => {
    return asFormat(a.dataInicial) < asFormat(b.dataInicial) ? 1 : -1
  })

  return (
    <DataTable<FechamentoTableQuery_fechamentos>
      columns={[
        {
          name: 'period',
          header: 'Período',
          sortable: false,
          render: renderPeriodo,
          style: { width: '20%' },
        },
        {
          name: 'motivo',
          header: 'Motivo',
          sortable: false,
          render: renderMotivo,
          style: { width: '75%' },
        },
        { name: 'actions', render: renderBotoes, style: { textAlign: 'right', width: '5%' } },
      ]}
      rows={props.data}
    />
  )
}

const createStyles = (theme: Theme) => ({
  textColumn: {
    wordBreak: 'break-word',
  } as CSSProperties,
})
