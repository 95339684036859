import { VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { TitleGroup } from 'components/TitleGroup'
import { analytics } from 'config/firebase'
import clearTypename from 'graphql/clearTypename'
import { useProfissionalEditQuery, useSalvarProfissionalMutation } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React, { useMemo } from 'react'

import { ProfissionalDadosForm } from './components/ProfissionalDadosForm'
import ProfissionalFormModel from './components/ProfissionalFormModel'
import convertModelToInput, { convertToForm } from './convertModelToInput'

interface UrlParams {
  profissionalId: string
}

export function ProfissionalEditView() {
  const { match, history } = useRouter<UrlParams>()

  const [save] = useSalvarProfissionalMutation()
  const {
    data: { profissional },
  } = useProfissionalEditQuery({
    variables: { id: match.params.profissionalId },
  })

  const handleSubmit = (values: ProfissionalFormModel) => {
    return save({ variables: { input: convertModelToInput(values) } })
  }

  const handleSubmitSuccess = () => {
    analytics.logEvent('edita_profissional')
    alert('success', 'Profissional foi salvo com sucesso')
    history.push(`/profissional/${profissional.id}`)
  }

  const initialValues = useMemo(() => profissional && convertToForm(clearTypename(profissional)), [profissional])

  if (!profissional) {
    return null
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${profissional.id}`} />
      <Breadcrumb title='Edição' />

      <VFlow>
        <TitleGroup title='Edição de profissional' subtitle={profissional.nome} />
        <ProfissionalDadosForm
          onSubmit={handleSubmit}
          onSubmitSucceeded={handleSubmitSuccess}
          initialValues={initialValues}
          edit={true}
          possuiLotacaoAtiva={profissional.lotacoes?.filter(lotacao => lotacao.ativo).length > 0}
        />
      </VFlow>
    </PageContent>
  )
}
