import { ApolloQueryResult } from 'apollo-client'
import { Button, Heading, HeadingSection, HFlow, Icon, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { FormState } from 'final-form'
import { useApolloClient } from 'graphql/hooks'
import {
  useResetSenhaAdminMutation,
  ValidateNovoAdminDocument,
  ValidateResetAdminDocument,
} from 'graphql/hooks.generated'
import { ValidateResetAdminQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import ContraChaveForm, { ContraChaveFormModel } from './components/ResetInstaladorContraChaveForm'

interface UrlParams {
  isEditAdmin: string
}

interface ModalResetSenhaInstaladorProps {
  isModalOpen: boolean
  possuiCns: boolean
  onButtonClicked(confirm: boolean): void
}

export default function ResetInstaladorContraChaveView() {
  const client = useApolloClient()
  const { params, url } = useRouteMatch<UrlParams>()
  const history = useHistory()
  const [modalVisible, setModalVisible] = useState(false)
  const [possuiCns, setPossuiCns] = useState(false)
  const isEdit = params.isEditAdmin === '1'
  const [formState, setFormState] = useState<ContraChaveFormModel>()
  const handleRejection = useErrorHandler()
  const alert = useAlert()

  const [resetSenhaMutation] = useResetSenhaAdminMutation()

  const handleSubmit = (values: ContraChaveFormModel) => {
    setFormState(values)
    return client
      .query({
        query: isEdit ? ValidateResetAdminDocument : ValidateNovoAdminDocument,
        variables: {
          input: {
            cpf: values.cpf,
            contraChave: values.contraChave,
          },
        },
      })
      .then((response: ApolloQueryResult<ValidateResetAdminQuery>) => {
        if (response.data) {
          if (isEdit) {
            setPossuiCns(response.data.validateResetAdmin.possuiCns)
            setModalVisible(true)
          } else {
            sessionStorage.setItem('contra-chave', values.contraChave)
          }
        }
      })
  }

  const onSubmitSucceeded = (form: FormState<ContraChaveFormModel>) => {
    if (!isEdit) {
      history.push(`${url}/profissional/${form.values.cpf}`)
    }
  }

  const onModalButtonClicked = (isConfirm: boolean) => {
    if (isConfirm) {
      resetSenhaMutation({
        variables: {
          input: {
            cpf: formState.cpf,
            contraChave: formState.contraChave,
          },
        },
      })
        .then(() => {
          alert('success', 'Administrador da instalação salvo com sucesso')
          sessionStorage.removeItem('contra-chave')
          history.push(`/`)
        })
        .catch(handleRejection)
    } else {
      setModalVisible(false)
    }
  }

  return (
    <>
      <HeadingSection level={1} title={isEdit ? 'Redefinição da senha do instalador' : 'Definir novo instalador'} />
      <ContraChaveForm onSubmit={handleSubmit} isEditAdmin={isEdit} onSubmitSucceeded={onSubmitSucceeded} />
      <ModalResetSenhaInstalador
        isModalOpen={modalVisible}
        onButtonClicked={onModalButtonClicked}
        possuiCns={possuiCns}
      />
    </>
  )
}

function ModalResetSenhaInstalador(props: ModalResetSenhaInstaladorProps) {
  const { isModalOpen, onButtonClicked, possuiCns } = props

  const senhaPadrao = possuiCns ? '(CNS + esus)' : '(CPF + esus)'

  return (
    <Modal size='small' onClose={() => onButtonClicked(false)} open={isModalOpen}>
      <ModalBody>
        <HFlow alignItems='center'>
          <Icon icon='questionMarkOutline' style={{ marginRight: '0.5rem' }} size={3} fill='info' />
          <Heading level={1}>Deseja confirmar a alteração de senha do instalador?</Heading>
        </HFlow>
        <br />
        <p>
          {`Ao confirmar a senha será definida para a padrão ${senhaPadrao}. Ao acessar o sistema, será solicitada a
          definição de uma nova senha.`}
        </p>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button onClick={() => onButtonClicked(false)}>Cancelar</Button>
          <Button kind='primary' onClick={() => onButtonClicked(true)}>
            Confirmar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
