import { onImportacaoCnesAdded, onImportacaoCnesUpdated } from 'api/schema'
import { useSubscription } from 'graphql/hooks'

import ImportacaoCnesAddedSubscription from './ImportacaoCnesAddedSubscription'
import ImportacaoCnesUpdatedSubscription from './ImportacaoCnesUpdatedSubscription'

interface ImportacaoCnesListenerProps {
  municipio: ID
  onImportacaoAdded(): void
}

export const ImportacaoCnesListener = (props: ImportacaoCnesListenerProps) => {
  const { municipio, onImportacaoAdded } = props
  useSubscription<onImportacaoCnesUpdated>(ImportacaoCnesUpdatedSubscription, { variables: { municipio } })
  useSubscription<onImportacaoCnesAdded>(ImportacaoCnesAddedSubscription, {
    variables: { municipio },
    onSubscriptionData: () => onImportacaoAdded(),
  })
  return null
}
