import { Theme, useStyles } from 'bold-ui'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { PesquisaSatisfacao } from 'view/pesquisaSatisfacao/PesquisaSatisfacao'

import { MainHeader } from './MainHeader'
import { SIDE_MENU_WIDTH, SideMenuNav } from './SideMenu'

export interface MainLayoutProps {
  hasBreadcrumbs: boolean
  children?: React.ReactNode
}

export const MainLayout = (props: MainLayoutProps) => {
  const { hasBreadcrumbs, children } = props
  const { classes } = useStyles(createStyles)

  const searchInputRef = useRef<HTMLInputElement>()
  const [modulosOpen, setModulosOpen] = useState(false)
  const handleModulosClick = () => setModulosOpen(state => !state)
  const handleModulosClose = () => setModulosOpen(false)

  useEffect(() => {
    modulosOpen && searchInputRef.current.focus()
  }, [modulosOpen])

  return (
    <div className={classes.wrapper}>
      <MainHeader hasBreadcrumbs={hasBreadcrumbs} onModulosClick={handleModulosClick} />

      <SideMenuNav searchInputRef={searchInputRef} open={modulosOpen} onClose={handleModulosClose} />

      <PesquisaSatisfacao />

      <main className={classes.main}>{children}</main>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } as CSSProperties,
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      marginLeft: SIDE_MENU_WIDTH,
    },
  } as CSSProperties,
})
