import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import { CIDADAO_NOVO_ENABLED } from 'config/flags'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'

import CidadaoEsusView from './cidadaoEsus/CidadaoEsusView'
import { CidadaoListView } from './CidadaoListView'
import { CidadaoCreateView } from './create/CidadaoCreateView'
import { CidadaoEditView } from './create/CidadaoEditView'
import { CidadaoDadosView } from './visualizacao/CidadaoDadosView'

export function CidadaoRootView() {
  const { match } = useRouter()

  return (
    <>
      {CIDADAO_NOVO_ENABLED && <Breadcrumb title='Cidadão' />}

      <PecSwitch>
        {CIDADAO_NOVO_ENABLED && (
          <PrivateRoute
            path={`${match.url}/create/`}
            component={CidadaoCreateView}
            permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}
          />
        )}
        <PrivateRoute
          exact
          path={`${match.url}/:cidadaoId(\\d+)/edit`}
          component={CidadaoEditView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}
        />
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={CIDADAO_NOVO_ENABLED ? CidadaoListView : CidadaoEsusView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao}
        />
        <PrivateRoute
          path={`${match.url}/:cidadaoId(\\d+)`}
          component={CidadaoDadosView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao}
        />
      </PecSwitch>
    </>
  )
}
