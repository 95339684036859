import { Omit } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { SideMenuItemConfig } from 'components/layout/SideMenu/SideMenu'
import { SIDE_MENU_ITEMS } from 'components/layout/SideMenu/SiteMenuItems'
import flatMap from 'lodash/flatMap'
import React from 'react'

import { SelectField, SelectFieldProps } from '../../final-form/SelectField'

type ItemType = Pick<SideMenuItemConfig, 'title' | 'to' | 'permission'>

export interface ModuloSelectFieldProps extends Omit<SelectFieldProps<ItemType>, 'items' | 'itemToString'> {}

export const ModuloSelectField = (props: ModuloSelectFieldProps) => {
  const { hasAuthorization } = useSession()
  const authorizedItems: ItemType[] = items.filter(item => hasAuthorization(item.permission))

  const itemToString = (item: ItemType) => item && item.title

  return <SelectField<ItemType> clearable={false} items={authorizedItems} itemToString={itemToString} {...props} />
}

export const createItems = (menuItems: SideMenuItemConfig[]): ItemType[] => {
  const createdItems: ItemType[] = []

  menuItems
    .filter(item => !item.children)
    .forEach(item => {
      createdItems.push({ title: item.title, permission: item.permission, to: item.to })
    })

  flatMap(menuItems.filter(item => !!item.children), item =>
    flatMap(item.children, c => c.items).forEach(c => {
      createdItems.push({ title: `${item.title}/${c.title}`, permission: c.permission, to: c.to })
    })
  )

  return createdItems
}

export const items = createItems(SIDE_MENU_ITEMS)
