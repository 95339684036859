import { TipoAcesso } from 'api/schema'
import { Cell, Grid } from 'bold-ui'
import {
  CboSelectField,
  CboSelectModel,
  CheckboxField,
  EquipeSelectField,
  EquipeSelectModel,
  Form,
  FormPrompt,
  FormProps,
  PerfilSelectField,
  PerfilSelectModel,
  UnidadeSaudeSelectField,
  UnidadeSaudeSelectModel,
} from 'components/form'
import { DefaultFormFooter } from 'components/form/FormFooter'
import createDecorator from 'final-form-calculate'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

const validator = createValidator<LotacaoFormModel>({
  unidadeSaude: [required],
  cbo: [required],
  perfis: [required],
})

export interface LotacaoFormProps extends FormProps<LotacaoFormModel> {
  disableFields?: boolean
  importada?: boolean
}

export interface LotacaoFormModel {
  ativo: boolean
  cbo: CboSelectModel
  equipe: EquipeSelectModel
  unidadeSaude: UnidadeSaudeSelectModel
  perfis: PerfilSelectModel[]
  profissional: ID
  atualizaPerfil: boolean
}

const path = metaPath<LotacaoFormModel>()

const calculator = createDecorator({
  field: path.unidadeSaude.absolutePath(),
  updates: {
    [path.equipe.absolutePath()]: (unidadeSaude, allValues, prevValues) => {
      if (Object.entries(prevValues).length === 0) {
        return allValues[path.equipe.absolutePath()]
      }
      return undefined
    },
  },
})

export function LotacaoForm(props: LotacaoFormProps) {
  const { onSubmit, onSubmitSucceeded, initialValues, disableFields, importada } = props

  const renderForm = (formProps: FormRenderProps) => {
    const value = formProps.values as LotacaoFormModel
    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <FormPrompt />
        <Grid wrap>
          <Cell xs={12} md={6}>
            <UnidadeSaudeSelectField
              disabled={disableFields}
              label='Unidade de Saúde'
              name={path.unidadeSaude.absolutePath()}
              required
              somenteUnidadesPermitidas={true}
            />
          </Cell>
          <Cell xs={12} md={6}>
            <CboSelectField
              label='CBO'
              name={path.cbo.absolutePath()}
              mostrarIndisponivelLotacao={false}
              disabled={disableFields}
              required
            />
          </Cell>
          <Cell xs={12} md={6}>
            <EquipeSelectField
              label='Equipe'
              name={path.equipe.absolutePath()}
              disabled={disableFields || !value.unidadeSaude}
              unidadeSaudeId={value.unidadeSaude && value.unidadeSaude.id}
            />
          </Cell>
          <Cell xs={12} md={6}>
            <PerfilSelectField
              tipoAcesso={TipoAcesso.LOTACAO}
              label='Perfis'
              name={path.perfis}
              multiple
              disabled={importada}
              required
            />
          </Cell>
          <Cell>
            <CheckboxField label='Atualizar perfis ao importar CNES' name={path.atualizaPerfil} />
          </Cell>
        </Grid>

        <DefaultFormFooter handleSubmit={formProps.handleSubmit} />
      </form>
    )
  }

  return (
    <Form
      decorators={[calculator]}
      render={renderForm}
      validate={validator}
      onSubmit={onSubmit}
      onSubmitSucceeded={onSubmitSucceeded}
      initialValues={initialValues}
    />
  )
}
