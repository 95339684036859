import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useQuery } from 'graphql/hooks'
import React from 'react'
import Permissions from 'types/Permissions'
import { SegurancaBox } from 'view/configuracoes/instalacao/seguranca/SegurancaBox'

import { SEGURANCA_QUERY } from './SegurancaQuery'

export const SegurancaView = () => {
  const { data, refetch } = useQuery(SEGURANCA_QUERY)

  return (
    <>
      {data && data.seguranca && (
        <VFlow vSpacing={2}>
          <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarSeguranca}>
            <SegurancaBox
              loginNumeroTentativas={data.seguranca.loginNumeroTentativas}
              periodoEmMesesParaRedefinir={data.seguranca.periodoEmMesesParaRedefinir}
              periodoEmMinutosInatividade={data.seguranca.periodoEmMinutosInatividade}
              load={refetch}
            />
          </CheckPermission>
        </VFlow>
      )}
    </>
  )
}
