import moment from 'moment'
import React, { useMemo } from 'react'

export interface AgeProps {
  dateOfBirth: moment.Moment | string | number | Date
  dateToBeCompared?: moment.Moment | string | number | Date
}

export function Age(props: AgeProps) {
  return useMemo(() => calcAge(props), [props])
}

function calcAge(props: AgeProps) {
  const { dateOfBirth, dateToBeCompared } = props

  const momentDateOfBirth = moment(dateOfBirth)
  const momentDateToBeCompared = moment(dateToBeCompared)

  if (!momentDateOfBirth.isValid() || !momentDateToBeCompared.isValid()) {
    return null
  }

  var completedYears = momentDateToBeCompared.diff(momentDateOfBirth, 'years', false)
  var years = momentDateToBeCompared.diff(momentDateOfBirth, 'years', true)

  var remainderYears = years - Math.floor(years)
  var fullMonths = Math.floor(12 * remainderYears)

  var ageString = ''
  if (completedYears > 0) {
    ageString = completedYears > 1 ? completedYears + ' anos' : completedYears + ' ano'
  }
  if (completedYears > 0 && fullMonths > 0) {
    ageString += ' e '
  }
  if (fullMonths > 0) {
    ageString += fullMonths > 1 ? fullMonths + ' meses' : fullMonths + ' mês'
  }
  if (completedYears === 0 && fullMonths === 0) {
    var days = momentDateToBeCompared.diff(momentDateOfBirth, 'days', false)
    ageString += days > 1 ? days + ' dias' : days + ' dia'
  }

  return <>{ageString}</>
}

Age.defaultProps = {
  dateToBeCompared: moment(),
} as Partial<AgeProps>
