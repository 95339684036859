import useSession from 'components/auth/useSession'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { Permission } from 'types/Permissions'

export interface PrivateRouteProps extends RouteProps {
  permission: Permission
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { permission, ...rest } = props

  const { hasAuthorization } = useSession({ fetchPolicy: 'cache-only' })

  if (hasAuthorization(props.permission)) {
    return <Route {...rest} />
  }

  return <Redirect to='/403' />
}
