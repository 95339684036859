import { SelectField, SelectFieldProps } from 'components/form'
import { EtniaSelectFieldDocument } from 'graphql/hooks.generated'
import { EtniaSelectFieldQuery, EtniaSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type EtniaSelectFieldModel = Partial<EtniaSelectFieldQuery['etnias']['content'][0]>

export function EtniaSelectField(props: Omit<SelectFieldProps<EtniaSelectFieldModel>, 'items' | 'itemToString'>) {
  const asyncProps = useAsyncQuerySelect<EtniaSelectFieldModel, EtniaSelectFieldQuery, EtniaSelectFieldQueryVariables>({
    query: EtniaSelectFieldDocument,
    extractItems: data => data && data.etnias && data.etnias.content,
    variables: inputQuery => ({
      input: {
        query: inputQuery,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: EtniaSelectFieldModel) => item && item.nome

  return <SelectField<EtniaSelectFieldModel> itemToString={itemToString} {...asyncProps} {...props} />
}
