import React from 'react'
import { conformToMask, MaskedInputProps } from 'react-text-mask'

import { masks } from './masks'

type MaskType = MaskedInputProps['mask']

export interface GenericMaskedLabelProps {
  value: string
}

function createMaskedLabel(displayName: string, mask: MaskType) {
  return class extends React.PureComponent<GenericMaskedLabelProps> {
    static displayName = displayName

    render() {
      const { value } = this.props

      if (!value || value.trim() === '') {
        return null
      }

      const maskedResult = conformToMask(value, mask as any, { guide: false })
      return maskedResult.conformedValue
    }
  }
}

export const Telefone = createMaskedLabel('Telefone', masks.telefone)
export const Cpf = createMaskedLabel('Cpf', masks.cpf)
export const Cep = createMaskedLabel('Cep', masks.cep)
export const Cnpj = createMaskedLabel('Cnpj', masks.cnpj)
export const NumeroDeclaracaoObito = createMaskedLabel('NumeroDeclaracaoObito', masks.numeroDeclaracaoObito)
