import axios, { AxiosPromise } from 'axios'

export class AcompanhamentoSaudeRelatorio {
  downloadRelatorioOperacionalCrianca(idCidadaos: string[]): AxiosPromise {
    return axios.post('/api/acompanhamentoSaude/relatorioOperacionalCrianca', idCidadaos, { responseType: 'blob' })
  }

  downloadRelatorioOperacionalGestante(idCidadaos: string[]): AxiosPromise {
    return axios.post('/api/acompanhamentoSaude/relatorioOperacionalGestante', idCidadaos, { responseType: 'blob' })
  }

  downloadRelatorioOperacionalRiscoCardiovascular(idCidadaos: string[]): AxiosPromise {
    return axios.post('/api/acompanhamentoSaude/relatorioOperacionalRiscoCardiovascular', idCidadaos, {
      responseType: 'blob',
    })
  }
}
