import { ExternalStyles, Heading, merge, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb'
import { Form } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { TitleGroup } from 'components/TitleGroup'
import { useBuscaCidadaoListagemLazyQuery } from 'graphql/hooks.generated'
import React, { useState } from 'react'

import AcompanhamentoCondicaoSaudeForm, { validate as validateForm } from './AcompanhamentoCondicaoSaudeForm'
import AcompanhamentoCondicaoSaudeFormModel, { AgeRangeFilter } from './AcompanhamentoCondicaoSaudeFormModel'
import AcompanhamentoCondicaoSaudeListing from './AcompanhamentoCondicaoSaudeListing'
import { AcompanhamentoSaudeRelatorioModal } from './components/AcompanhamentoSaudeRelatorioModal'
import convertModelToInput from './convertModelToInput'

export default function AcompanhamentoCondicaoSaudeView() {
  const [currentFilter, setCurrentFilter] = useState({})
  const [executeQuery, { loading, data }] = useBuscaCidadaoListagemLazyQuery()

  const handleSubmit = (form: AcompanhamentoCondicaoSaudeFormModel) => {
    const input = convertModelToInput(form)
    executeQuery({ variables: { input } })
    setCurrentFilter(input)
  }

  const onChangeFilter = filter => {
    const input = filter(currentFilter)
    executeQuery({ variables: { input } })
    setCurrentFilter(input)
  }

  const cidadaos = data?.acompanhamentoCondicaoSaude

  return (
    <>
      <Breadcrumb title='Acompanhamento de condições de saúde' />
      <VFlow style={{ borderBottom: '1px solid lightgray' }}>
        <PageContent type='filled'>
          <TitleGroup
            title='Acompanhamento de condições de saúde'
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
          />
          <Form<AcompanhamentoCondicaoSaudeFormModel>
            render={AcompanhamentoCondicaoSaudeForm}
            onSubmit={handleSubmit}
            validate={validateForm}
            initialValues={{ faixaEtariaFilter: AgeRangeFilter.TODAS_FAIXAS }}
          />
        </PageContent>
      </VFlow>
      <PageContent>
        <VFlow>
          {cidadaos || loading ? (
            <>
              <TitleGroup title='Cidadãos encontrados' style={{ marginBottom: '1rem' }} />
              <AcompanhamentoCondicaoSaudeListing
                onChange={onChangeFilter}
                resultCidadaos={cidadaos}
                loading={loading}
              />
              <AcompanhamentoSaudeRelatorioModal
                filter={merge({}, currentFilter, { pageParams: { page: 0, size: cidadaos?.pageInfo.totalElements } })}
                disableGerarRelatorio={
                  loading || cidadaos?.pageInfo.totalElements > 500 || cidadaos?.pageInfo.totalElements === 0
                }
              />
            </>
          ) : (
            <DoSearchPanel />
          )}
        </VFlow>
      </PageContent>
    </>
  )
}

export function DoSearchPanel() {
  const styles = {
    div: {
      textAlign: 'center',
      marginTop: '1rem',
    } as React.CSSProperties,
    secondHeading: {
      fontWeight: 'lighter',
      marginTop: '1rem',
    } as ExternalStyles,
  }

  return (
    <div style={styles.div}>
      <Heading level={2}>
        Realize uma busca por cidadãos que tenham algum problema ou <br /> condição ativo ou avaliado.
      </Heading>
      <Heading level={3} style={styles.secondHeading}>
        É possível buscar uma lista de cidadãos a partir do preenchimento de qualquer informação, <br /> não esqueça de
        preencher os filtros obrigatórios.
      </Heading>
    </div>
  )
}
