import gql from 'graphql-tag'

export const SELECIONAR_ACESSO_MUTATION = gql`
  mutation SelecionarAcessoMutation($input: SelecionarAcessoInput!) {
    selecionarAcesso(input: $input) {
      id
      profissional {
        id
        nome
      }
      acesso {
        id
        moduloInicial
      }
      recursos
    }
  }
`
