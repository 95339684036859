import { HFlow, Icon, PagedTable, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { Telefone } from 'components/label'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useUnidadeSaudeTableQuery } from 'graphql/hooks.generated'
import { UnidadeSaudeTableQuery, UnidadesSaudeQueryInput } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React, { useState } from 'react'
import { UnidadeSaudeTableFilter } from 'view/unidadeSaude/list/UnidadeSaudeTableFilter'

export type UnidadeSaudeItem = UnidadeSaudeTableQuery['unidadesSaude']['content'][0]

export function UnidadeSaudeTable() {
  const { match } = useRouter()

  const [filter, setFilter] = useState<UnidadesSaudeQueryInput>({
    mostrarInativas: false,
    pageParams: {
      sort: ['nome'],
    },
  })

  const {
    data: { unidadesSaude },
    loading,
  } = useUnidadeSaudeTableQuery({ variables: { input: filter } })

  const renderNome = (row: UnidadeSaudeItem) => {
    return (
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text>{row.nome}</Text>
        {!row.ativo && <Tag>Inativa</Tag>}
      </HFlow>
    )
  }

  const renderCnes = (row: UnidadeSaudeItem) => {
    return <Text>{row.cnes}</Text>
  }

  const renderTelefones = (row: UnidadeSaudeItem) => {
    return (
      <VFlow vSpacing={0.1}>
        <Telefone value={row.telefone1} />
        <Telefone value={row.telefone2} />
      </VFlow>
    )
  }

  const renderBairro = (row: UnidadeSaudeItem) => {
    return <Text>{row.endereco.bairro}</Text>
  }

  const renderButtons = (row: UnidadeSaudeItem) => {
    return (
      <Tooltip text='Visualizar'>
        <ButtonLink size='small' skin='ghost' to={`${match.url}/${row.id}`} data-testid='visualizar'>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </Tooltip>
    )
  }

  const tableProps = usePagedTableProps({
    loading,
    onChange: setFilter,
    result: unidadesSaude,
  })

  return (
    <TableBox header={<UnidadeSaudeTableFilter initialValues={filter} onChange={setFilter} />}>
      <PagedTable<UnidadeSaudeItem>
        {...tableProps}
        columns={[
          { name: 'nome', header: 'Unidade de saúde', render: renderNome, sortable: true },
          { name: 'cnes', header: 'CNES', render: renderCnes },
          { name: 'telefone', header: 'Telefone', render: renderTelefones },
          { name: 'bairro', header: 'Bairro', render: renderBairro },
          { name: 'actions', render: renderButtons, style: { textAlign: 'right' } },
        ]}
        data-testid='UnidadeSaudeTable'
      />
    </TableBox>
  )
}
