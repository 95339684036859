import { SalvarTipoServicoMutation, SalvarTipoServicoMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const SALVAR_TIPO_SERVICO = gql`
  mutation SalvarTipoServicoMutation($input: TipoServicoInput!) {
    salvarTipoServico(input: $input) {
      id
      nome
    }
  }
`

export function useSalvarTipoServico() {
  return useMutation<SalvarTipoServicoMutation, SalvarTipoServicoMutationVariables>(SALVAR_TIPO_SERVICO)
}
