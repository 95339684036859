import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { Cpf } from 'components/label'
import React from 'react'

import { UnificacaoCadastroErrorModel } from '../UnificacaoCadastroErrorHandler'

interface UnificacaoErrorModalProps {
  error: UnificacaoCadastroErrorModel
  onClose(): void
}

export default function UnificacaoErrorModal({ error, onClose }: UnificacaoErrorModalProps) {
  return (
    <Modal open={!!error} onClose={onClose} style={{ width: '40rem' }}>
      <ModalBody>
        <VFlow>
          <HFlow style={{ alignItems: 'center' }}>
            <Icon icon='infoCircleOutline' style={{ marginRight: '0.5rem' }} size={3} fill='primary' />
            <Heading level={1}>Não é possível unificar cadastros</Heading>
          </HFlow>
          <Text>{error.message}</Text>
          {error.cidadaoInfoList?.map(i => (
            <HFlow hSpacing={4}>
              <VFlow vSpacing={0} style={{ width: '12rem' }}>
                <Text fontWeight='bold'>Nome</Text>
                <Text>{i.nomeCompleto}</Text>
              </VFlow>
              {i.cpf && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>CPF</Text>
                  <Cpf value={i.cpf} />
                </VFlow>
              )}
              {i.cns && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>CNS</Text>
                  <Text>{i.cns}</Text>
                </VFlow>
              )}
            </HFlow>
          ))}
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button skin='default' kind='primary' size='medium' onClick={onClose}>
            Entendi
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
