import { RecuperarSenhaInput } from 'api/schema'
import { Alert, AlertType, Button, Cell, Grid, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import { FormFooter } from 'components/form/FormFooter'
import React from 'react'
import { useHistory } from 'react-router'
import { createValidator, required, senha } from 'util/validation'

const validator = createValidator<RecuperarSenhaForm>(
  {
    novaSenha: [required, senha],
    confirmacaoSenha: [required, senha],
  },
  (values: RecuperarSenhaInput) => {
    if (values.confirmacaoSenha !== values.novaSenha) {
      return {
        confirmacaoSenha: 'A confirmação da senha deve ser igual à nova senha.',
      }
    }
  }
)

export interface RecuperarSenhaForm {
  token: string

  novaSenha: string
  confirmacaoSenha: string
}

export interface RecuperarSenhaFormProps {
  onSubmit(values: RecuperarSenhaForm): any
  onSair(): any
}

export const RecuperarSenhaForm = (props: RecuperarSenhaFormProps) => {
  const alert = useAlert()
  const history = useHistory()

  const renderAlerta = (type: AlertType, message: string) =>
    message && (
      <Alert type={type} inline={true} style={{ marginBottom: '1rem' }}>
        {message}
      </Alert>
    )

  const handleSubmitSuccess = () => {
    alert('success', 'Senha recuperada com sucesso')
    history.push('/')
  }

  const formRenderer = (formProps: FormRenderProps) => {
    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        {formProps.submitFailed && formProps.submitErrors ? renderAlerta('danger', formProps.submitErrors.token) : null}
        <VFlow vSpacing={6}>
          <Grid wrap style={{ width: 'calc(50% + 2rem)' }}>
            <Cell size={6}>
              <TextField
                name='novaSenha'
                label='Nova senha'
                placeholder='Senha'
                type='password'
                maxLength={20}
                required
              />
            </Cell>
            <Cell size={6}>
              <TextField
                name='confirmacaoSenha'
                label='Confirmação de senha'
                placeholder='Senha'
                type='password'
                maxLength={20}
                required
              />
            </Cell>
          </Grid>
          <FormFooter>
            <Button onClick={props.onSair}>Sair</Button>
            <SubmitButton handleSubmit={formProps.handleSubmit}>Salvar</SubmitButton>
          </FormFooter>
        </VFlow>
      </form>
    )
  }

  return (
    <Form
      render={formRenderer}
      onSubmit={props.onSubmit}
      onSubmitSucceeded={handleSubmitSuccess}
      validate={validator}
    />
  )
}
