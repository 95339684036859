/* eslint-disable no-console */
import useSession from 'components/auth/useSession'
import { PecSwitch } from 'components/route'
import { analytics } from 'config/firebase'
import React, { useEffect } from 'react'
import { Route } from 'react-router'
import { ForcarAlterarSenhaView } from 'view/alterarSenha/AlterarSenhaView'
import { AutorizarMunicipioRootView } from 'view/autorizacao/AutorizarMunicipioRootView'
import { EscolhaModuloAcessoRootView } from 'view/EscolhaModuloAcessoRootView'
import { SelecaoAcessoLoginView } from 'view/login/SelecaoAcessoView/SelecaoAcessoView'
import { TermosView } from 'view/termosUso/TermosView'

import { LoggedRootView } from '../LoggedRootView'
import { LogoutView } from '../login/LogoutView'

export function PrivateRootView() {
  const { data, refresh } = useSession()
  const escolhaModulo = () => <EscolhaModuloAcessoRootView refresh={refresh} />
  const autorizarMunicipioView = () => <AutorizarMunicipioRootView acessoId={data.acesso && data.acesso.id} />

  useEffect(() => {
    if (data && data.acesso) {
      analytics.setUserId(data.profissional.usuario.hashId)
      analytics.setUserProperties({
        Acesso: data.acesso.tipo,
        Perfis: data.acesso.perfis.map(p => p.nome).join(', '),
        CBO: data.acesso.__typename === 'Lotacao' && data.acesso.cbo.nome,
        Versão: process.env.REACT_APP_VERSION,
        Município: data.acesso.municipio?.nome,
      })
    }
  }, [data, data.acesso])

  return (
    <PecSwitch>
      <Route path='/logout' component={LogoutView} />

      {!data.profissional.usuario.aceitouTermosUso && (
        // Usuário precisa aceitar os termos de uso
        <Route component={TermosView} />
      )}

      {data.profissional.usuario.trocarSenha && (
        // Usuário precisa alterar senha
        <Route component={ForcarAlterarSenhaView} />
      )}

      {!data.acesso && (
        // Usuário precisa selecionar acesso
        <Route component={SelecaoAcessoLoginView} />
      )}

      {data.acesso &&
        data.acesso.__typename === 'AdministradorMunicipal' &&
        (!data.acesso.autorizado || !data.acesso.habilitado) && <Route component={autorizarMunicipioView} />}

      {data.acesso && !data.acesso.moduloInicial && (
        // Usuário precisa escolher seu modulo Inicial
        <Route component={escolhaModulo} />
      )}

      <Route component={LoggedRootView} />
    </PecSwitch>
  )
}
