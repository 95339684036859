import api from 'api'
import { AxiosRequestConfig } from 'axios'
import { Link, Text, Tooltip, useStyles, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { FileUploaderComponent } from 'components/upload/FileUploaderComponent'
import { analytics } from 'config/firebase'
import { useImportacoesCnesLazyQuery } from 'graphql/hooks.generated'
import { ImportacoesCnesQueryInput } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { ImportacaoCnesTable } from 'view/cnes/ImportacaoCnesTable'

import { ImportacaoCnesHeaderForm } from './ImportacaoCnesHeaderForm'
import { ImportacaoCnesListener } from './ImportacaoCnesListener'

interface MunicipioModel {
  id: ID
  nome: string
}

export function ImportacaoCnesView() {
  const { data: session } = useSession()
  const [municipio, setMunicipio] = useState<MunicipioModel>(session.acesso.municipio)
  const [filter, setFilter] = useState<ImportacoesCnesQueryInput>({
    pageParams: {
      page: 0,
      sort: ['-data'],
    },
  })

  const [executeQuery, { data, loading, refetch }] = useImportacoesCnesLazyQuery()

  useEffect(() => {
    municipio &&
      executeQuery({
        variables: {
          input: {
            ...filter,
            municipioId: municipio.id,
          },
        },
      })
  }, [municipio, filter, executeQuery])

  const importacoesCnes = data && data.importacoesCnes

  const tableProps = usePagedTableProps({
    loading,
    onChange: setFilter,
    result: municipio && importacoesCnes,
  })

  const upload = (formData: FormData, config?: AxiosRequestConfig) => {
    return api.cnes.importar(formData, municipio && municipio.id, config).catch(error => {
      analytics.logEvent('importa_cnes')
      if (error.response.data.message) {
        alert('danger', error.response.data.message)
      } else {
        alert('danger', error.response.data || 'Falha na importação do arquivo.')
      }
      throw error
    })
  }

  return (
    <>
      {municipio && <ImportacaoCnesListener onImportacaoAdded={refetch} municipio={municipio.id} />}

      <PageHeaderSection title='Importação de CNES'>
        {!session.acesso.municipio && <ImportacaoCnesHeaderForm onChange={setMunicipio} />}
      </PageHeaderSection>
      <PageContent>
        <VFlow>
          <VFlow vSpacing={0}>
            <Tooltip text={municipio ? '' : 'Selecione um município para importar o CNES'} placement='auto'>
              <div>
                <FileUploaderComponent uploadRequest={upload} disabled={!municipio} />
              </div>
            </Tooltip>
            <DownloadInfo />
          </VFlow>
          <ImportacaoCnesTable {...tableProps} filter={filter} onFilterChange={setFilter} />
        </VFlow>
      </PageContent>
    </>
  )
}

export const DownloadInfo = (props: { text?: React.ReactElement }) => {
  const { classes } = useStyles(theme => ({
    divStyle: {
      padding: '0 0.5rem',
      border: `1px solid ${theme.pallete.divider}`,
      borderTop: 0,
      backgroundColor: theme.pallete.gray.c90,
      borderRadius: `${theme.radius.paper}px`,
    },
  }))
  return (
    <div className={classes.divStyle}>
      {!props.text ? (
        <Text>
          Importe o arquivo XML versão 2.1 a ser obtido no{' '}
          <Link href='http://cnes.datasus.gov.br/' target='_blank'>
            Portal do CNES
          </Link>
          {'. '}
          Para saber como obter o arquivo compatível com essa versão, acesse o{' '}
          <Link target='_blank' href='http://aps.saude.gov.br/ape/esus'>
            Portal da SAPS
          </Link>
          .
        </Text>
      ) : (
        props.text
      )}
    </div>
  )
}
