import gql from 'graphql-tag'

export const EQUIPE_TABLE_QUERY = gql`
  query EquipeTableQuery($unidadeSaudeId: ID!, $input: EquipesConnectionInput!) {
    unidadeSaude(id: $unidadeSaudeId) {
      id
      equipes(input: $input) {
        content {
          id
          nome
          ine
          area
          tipoEquipe {
            nome
            sigla
          }
          ativo
        }
        pageInfo {
          first
          number
          size
          sort
          totalPages
          totalElements
          first
          last
          numberOfElements
        }
      }
    }
  }
`
