import { Button, HeadingSection, VFlow } from 'bold-ui'
import {
  EnderecoFieldGroup,
  EnderecoFieldGroupModel,
  Form,
  FormProps,
  FormRenderProps,
  SubmitButton,
  validate as validateEndereco,
} from 'components/form'
import { resolveValue } from 'components/form/final-form/useField'
import { FormFooter } from 'components/form/FormFooter'
import { FormPrompt } from 'components/form/FormPrompt'
import createCalculator from 'final-form-calculate'
import React from 'react'
import { useHistory } from 'react-router'
import { MetaPath, metaPath } from 'util/metaPath'
import { createValidator } from 'util/validation'
import {
  createDadosValidator,
  ProfissionalDadosFieldGroup,
} from 'view/profissional/components/ProfissionalDadosFieldGroup'
import ProfissionalFormModel from 'view/profissional/components/ProfissionalFormModel'

const validate = createValidator<ProfissionalFormModel>(
  {
    endereco: validateEndereco,
  },
  createDadosValidator(false)
)

const createEnderecoCalculator = (meta: MetaPath<EnderecoFieldGroupModel>) =>
  createCalculator({
    field: meta.semNumero.absolutePath(),
    updates: {
      [meta.numero.absolutePath()]: (value: boolean, values: any) => {
        const endereco = resolveValue(values, meta)
        return value ? undefined : endereco.numero
      },
    },
  })

const path = metaPath<ProfissionalFormModel>()

const enderecoCalculator = createEnderecoCalculator(path.endereco)

export function ResetInstaladorDadosForm(props: FormProps<ProfissionalFormModel>) {
  const history = useHistory()

  const onCancelarClicked = () => history.push('/')

  const onVoltarClicked = () => history.push('/reset/chave/0')

  const renderForm = (formProps: FormRenderProps<ProfissionalFormModel>) => {
    return (
      <form noValidate>
        <FormPrompt />
        <VFlow vSpacing={2}>
          <HeadingSection level={2} title='Dados pessoais'>
            <ProfissionalDadosFieldGroup name={path} formProps={formProps} edit={true} />
          </HeadingSection>

          <HeadingSection level={2} title='Endereço residencial'>
            <EnderecoFieldGroup name={path.endereco} formProps={formProps} />
          </HeadingSection>

          <FormFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button onClick={onVoltarClicked} data-testid='FormFooter.voltar'>
                Voltar
              </Button>
            </div>
            <div>
              <Button onClick={onCancelarClicked} data-testid='FormFooter.cancelar' style={{ marginRight: '1rem' }}>
                Cancelar
              </Button>
              <SubmitButton handleSubmit={formProps.handleSubmit} data-testid='FormFooter.salvar'>
                {'Salvar'}
              </SubmitButton>
            </div>
          </FormFooter>
        </VFlow>
      </form>
    )
  }

  return (
    <Form<ProfissionalFormModel>
      render={renderForm}
      validate={validate}
      decorators={[enderecoCalculator]}
      validateOnBlur={false}
      {...props}
    />
  )
}
