/**
 * Habilita todos as flags experimentais
 */
export const EXPERIMENTAL = process.env.REACT_APP_EXPERIMENTAL

/**
 * Especifica se as telas novas de atendimento devem ser mostradas
 */
export const ATENDIMENTO_NOVO_ENABLED = process.env.REACT_APP_ATENDIMENTO_NOVO_ENABLED ?? EXPERIMENTAL

export const CIDADAO_NOVO_ENABLED = process.env.REACT_APP_CIDADAO_NOVO_ENABLED ?? EXPERIMENTAL
