import { DefinirSenhaPadrao, DefinirSenhaPadraoVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const DEFINIR_SENHA_PADRAO_MUTATION = gql`
  mutation DefinirSenhaPadrao($usuarioId: ID!) {
    definirSenhaPadrao(usuarioId: $usuarioId)
  }
`

export function useDefinirSenhaPadrao() {
  return useMutation<DefinirSenhaPadrao, DefinirSenhaPadraoVariables>(DEFINIR_SENHA_PADRAO_MUTATION)
}
