import { Omit, Radio, RadioProps } from 'bold-ui'
import React from 'react'

import { UseFieldProps, usePecField } from '../useField'

export type RadioFieldProps = UseFieldProps<any> & Omit<RadioProps, 'name'>

export function RadioField(props: RadioFieldProps) {
  const { input, meta, ...rest } = usePecField({ type: 'radio', ...props })

  return <Radio {...input} {...rest} />
}
