import { AlterarAtivacaoLotacao, AlterarAtivacaoLotacaoVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const ALTERAR_ATIVACAO_LOTACAO_MUTATION = gql`
  mutation AlterarAtivacaoLotacao($lotacaoId: ID!) {
    alterarAtivacaoLotacao(lotacaoId: $lotacaoId)
  }
`

export function useAlterarAtivacaoLotacao() {
  return useMutation<AlterarAtivacaoLotacao, AlterarAtivacaoLotacaoVariables>(ALTERAR_ATIVACAO_LOTACAO_MUTATION)
}
