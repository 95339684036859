import { SelecionarAcessoMutation, SelecionarAcessoMutationVariables } from 'api/schema'
import { Cell, Grid, Heading, Text, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { FormFooter } from 'components/form/FormFooter'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { ButtonLink, RouterLink } from 'components/route'
import { useMutation } from 'graphql/hooks'
import { SessaoDocument } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { notify } from 'util/multitab'
import { NewsModal } from 'view/news/NewsModal'

import { Acesso } from './Acesso'
import selecaoAcessoSortCompare from './SelecaoAcessoSorter'
import { SELECIONAR_ACESSO_MUTATION } from './SelecionarAcessoMutation'

export function SelecaoAcessoLoginView() {
  return <SelecaoAcessoView hasSairButton />
}

export interface SelecaoAcessoViewProps {
  hasSairButton?: boolean
}

export function SelecaoAcessoView(props: SelecaoAcessoViewProps) {
  const { hasSairButton } = props

  const { data } = useSession({ fetchPolicy: 'cache-only' })

  if (!data) {
    return null
  }

  return (
    <>
      <NewsModal />
      <PageHeaderSection title={`Bem vindo, ${data.profissional.nome}`} />

      <PageContent>
        <VFlow vSpacing={1.5}>
          <Heading level={2}>Escolha um acesso para continuar</Heading>
          <Grid gap={5} wrap>
            <Cell xs={6}>
              <AcessosUsuarioLogado />
            </Cell>
          </Grid>

          {hasSairButton && (
            <FormFooter>
              <ButtonLink to='/logout'>Sair</ButtonLink>
            </FormFooter>
          )}
        </VFlow>
      </PageContent>
    </>
  )
}

export function AcessosUsuarioLogado() {
  const history = useHistory()

  const { data } = useSession({ fetchPolicy: 'cache-and-network' })

  const [selecionarAcessoMutation] = useMutation<SelecionarAcessoMutation, SelecionarAcessoMutationVariables>(
    SELECIONAR_ACESSO_MUTATION
  )

  const selecionarAcesso = (acessoId: ID) =>
    selecionarAcessoMutation({
      variables: { input: { id: acessoId } },
      refetchQueries: [{ query: SessaoDocument }],
    }).then(() => {
      history.replace('/')
      notify('PERFIL_SELECIONADO')
    })

  if ((data.profissional.acessos?.length ?? 0) === 0) {
    return (
      <VFlow>
        <Text>
          Você não possui nenhum acesso ativo. Entre em contato com o administrador do sistema para liberar o seu
          acesso.
        </Text>
        <RouterLink to='/logout'>Voltar</RouterLink>
      </VFlow>
    )
  }

  const acessos = [...data.profissional.acessos].sort(selecaoAcessoSortCompare)

  return (
    <VFlow>
      {acessos.map(acesso => (
        <Acesso key={acesso.id} acesso={acesso} onClick={selecionarAcesso} />
      ))}
    </VFlow>
  )
}
