import { Icon, Text, Theme, useStyles } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { Box } from 'components/Box'
import { PopperButton } from 'components/popper'
import { Interpolation } from 'emotion'
import React, { CSSProperties } from 'react'

import LoteImunobiologicoTooltipAddForm from './LoteImunologicoTooltipAddForm'

export interface LoteImunobiologicoFormPopperProps {
  styles?: Interpolation
  reload(): Promise<any>
}

export default function LoteImunobiologicoFormPopper(props: LoteImunobiologicoFormPopperProps) {
  const { styles, reload } = props
  const { classes, css } = useStyles(createStyles)

  const renderTarget = ({ close, open }) => {
    return (
      <div className={classes.popperForm}>
        <Box>
          <LoteImunobiologicoTooltipAddForm onCancel={open} onClose={close} onSuccess={composeHandlers(reload, open)} />
        </Box>
      </div>
    )
  }
  return (
    <div className={css(styles)}>
      <PopperButton renderPopper={renderTarget} placement='bottom-end' size='small' kind='primary' zIndex={1}>
        <Icon icon='plus' style={{ marginRight: '0.25rem' }} />
        <Text color='inherit'>Cadastrar lote</Text>
      </PopperButton>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  popperForm: {
    boxShadow: theme.shadows.outer['40'],
    height: '13rem',
    width: '31rem',
    transform: 'translateX(1.1rem)',
    textAlign: 'left',
    marginTop: '0.5rem',
    backgroundColor: 'pink',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0.25rem',
    },
  } as CSSProperties,
})
