import { DefaultItemType, Omit, Select, SelectProps } from 'bold-ui'
import React from 'react'

import { UseFieldProps, usePecField } from '../useField'
import { getFieldError } from '../util'

export type SelectFieldProps<T = DefaultItemType> = UseFieldProps<T> & Omit<SelectProps<T>, 'name'>

export function SelectField<T = DefaultItemType>(props: SelectFieldProps<T>) {
  const { input, meta, ...rest } = usePecField(props)

  return <Select error={getFieldError(meta)} {...input} {...rest} multiple={props.multiple} />
}
