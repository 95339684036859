import { AtualizarMunicipioResponsavel, AtualizarMunicipioResponsavelVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const ATUALIZAR_MUNICIPIO_RESPONSAVEL = gql`
  mutation AtualizarMunicipioResponsavel($input: MunicipioResponsavelUpdateInput!) {
    atualizarMunicipioResponsavel(input: $input) {
      id
      profissional {
        id
        nome
      }
      municipio {
        id
        nome
        uf {
          id
          sigla
        }
      }
      habilitado
      ativo
      dataAdicao
      dataInativacao
    }
  }
`

export function useAtualizarMunicipioResponsavel() {
  return useMutation<AtualizarMunicipioResponsavel, AtualizarMunicipioResponsavelVariables>(
    ATUALIZAR_MUNICIPIO_RESPONSAVEL
  )
}
