import { HFlow, Text, VFlow } from 'bold-ui'
import React from 'react'

export default interface ProfissionalHeaderIdentifierDto {
  nomeProfissional: string
  lotacao: any
}

export interface ProfissionalHeaderIdentifierProps {
  profissional: ProfissionalHeaderIdentifierDto
}

export function ProfissionalHeaderIdentifier(props: ProfissionalHeaderIdentifierProps) {
  return (
    <VFlow vSpacing={0}>
      <Text fontSize={1} fontWeight='bold'>
        {props.profissional.nomeProfissional}
      </Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>Unidade de saúde</Text>
        <Text>{props.profissional.lotacao.unidadeSaude.nome}</Text>
      </HFlow>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>CBO</Text>
        <Text>
          {props.profissional.lotacao.cbo.nome} - {props.profissional.lotacao.cbo.cbo2002}
        </Text>
      </HFlow>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>Equipe</Text>
        <Text>{props.profissional.lotacao.equipe ? props.profissional.lotacao.equipe.nome + ' - ' : ''}</Text>
        <Text>{props.profissional.lotacao.equipe ? props.profissional.lotacao.equipe.ine : 'Lotação sem equipe'}</Text>
      </HFlow>
    </VFlow>
  )
}
