import { masks } from 'components/label/masks'
import React from 'react'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

import { MaskedField, MaskedFieldProps } from '../MaskedField'

export type TimeFieldProps = MaskedFieldProps

export function TimeField(props: MaskedFieldProps) {
  return <MaskedField format={format} pipe={createAutoCorrectedDatePipe('HH:MM')} {...props} mask={masks.time} />
}

const format = (value: string) => {
  // Omit seconds in case the initial field value is in format 'HH:mm:ss'
  return value ? value.slice(0, 5) : null
}
