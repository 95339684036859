import api from 'api'
import { AxiosRequestConfig } from 'axios'
import { HeadingSection, HFlow, Icon, Paper, Spinner, Text, useStyles, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { FileUploaderComponent } from 'components/upload/FileUploaderComponent'
import { useOnTransmissaoUpdatedSubscription, useTransmissaoQuery } from 'graphql/hooks.generated'
import { ImportacaoArquivo } from 'graphql/types.generated'
import moment from 'moment'
import React, { useState } from 'react'

interface TransmissaoRecebimentoImportFileBoxProps {
  refetchQuery(): Promise<any>
}

interface StatusImportacaoProps {
  importacaoArquivo: ImportacaoArquivo
  falhaImportacao: boolean
}

export function TransmissaoRecebimentoImportFileBox(props: TransmissaoRecebimentoImportFileBoxProps) {
  const { refetchQuery } = props
  const alert = useAlert()
  const [throwError, setThrowError] = useState(false)
  const { classes } = useStyles(theme => ({
    legenda: {
      padding: '0 0.5rem',
      border: `1px solid ${theme.pallete.divider}`,
      borderTop: 0,
      borderRadius: `${theme.radius.paper}px`,
    },
  }))

  useOnTransmissaoUpdatedSubscription({
    onSubscriptionData: result => {
      setThrowError(false)
      if (result.subscriptionData.data.transmissaoUpdated.importacaoArquivo.status === 'CONCLUIDO') {
        refetchQuery()
        const msg =
          result.subscriptionData.data.transmissaoUpdated.importacaoArquivo.message ||
          'Importação de arquivo concluída com sucesso.'
        alert('success', msg)
      } else if (result.subscriptionData.data.transmissaoUpdated.importacaoArquivo.status === 'ERRO') {
        const msg =
          result.subscriptionData.data.transmissaoUpdated.importacaoArquivo.message || 'Falha na importação do arquivo.'
        alert('danger', msg)
      }
    },
  })

  const {
    data: { transmissao },
  } = useTransmissaoQuery({ fetchPolicy: 'cache-first' })

  if (!transmissao) {
    return null
  }

  const upload = (formData: FormData, config?: AxiosRequestConfig) => {
    return api.transmissao.importarFichas(formData, config).catch(error => {
      setThrowError(true)
      alert('danger', error.response.data || 'Falha na importação do arquivo.')
      throw error
    })
  }

  return (
    <HeadingSection title='Importar arquivo' level={2}>
      <VFlow>
        <VFlow vSpacing={0}>
          <FileUploaderComponent
            disabled={transmissao.importacaoArquivo.status === 'IMPORTANDO'}
            uploadRequest={upload}
            text='Clique para importar ou arraste os arquivos'
          />
          <HFlow style={classes.legenda} alignItems='center'>
            <Text>Apenas arquivos no formato ZIP são permitidos</Text>
          </HFlow>
        </VFlow>
        <StatusImportacao importacaoArquivo={transmissao.importacaoArquivo} falhaImportacao={throwError} />
      </VFlow>
    </HeadingSection>
  )
}

const StatusImportacao = (props: StatusImportacaoProps) => {
  const { classes } = useStyles(theme => ({
    fileDetailsContainer: {
      alignItems: 'center',
      display: 'flex',
      padding: '1rem',
      background: theme.pallete.surface.main,
    },
    fileDetails: {
      flexGrow: 1,
      marginLeft: '1rem',
    },
    successIcon: {
      fontSize: '1rem',
      color: theme.pallete.status.success.main,
    },
    loadingIcon: {
      fontSize: '1rem',
      color: theme.pallete.primary.main,
    },
    errorIcon: {
      fontSize: '1rem',
      color: theme.pallete.status.danger.main,
    },
  }))

  const {
    importacaoArquivo: { dataEvento, status },
    falhaImportacao,
  } = props

  if (falhaImportacao) {
    return (
      <Paper>
        <div className={classes.fileDetailsContainer}>
          <div className={classes.fileDetails}>
            <HFlow hSpacing={0.2}>
              <Icon style={classes.errorIcon} icon='banOutline' />
              <Text color='danger'>
                Falha na importação do arquivo em <DateTime value={moment.now()} />.
              </Text>
            </HFlow>
          </div>
        </div>
      </Paper>
    )
  }

  if (status === 'SEM_RESULTADO') {
    return <></>
  }

  return (
    <Paper>
      <div className={classes.fileDetailsContainer}>
        <div className={classes.fileDetails}>
          {status === 'IMPORTANDO' && (
            <HFlow hSpacing={0.2}>
              <Spinner style={classes.loadingIcon} />
              <Text color='primary'>Importando arquivo</Text>
            </HFlow>
          )}
          {status === 'CONCLUIDO' && (
            <HFlow hSpacing={0.2}>
              <Icon style={classes.successIcon} icon='checkCircleOutline' />
              <Text color='success'>
                Importação de arquivo concluída com sucesso em <DateTime value={dataEvento} />.
              </Text>
            </HFlow>
          )}
          {status === 'ERRO' && (
            <HFlow hSpacing={0.2}>
              <Icon style={classes.errorIcon} icon='banOutline' />
              <Text color='danger'>
                Falha na importação do arquivo em <DateTime value={dataEvento} />.
              </Text>
            </HFlow>
          )}
        </div>
      </div>
    </Paper>
  )
}
