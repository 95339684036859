import { HeadingSection, HFlow, Icon, Theme, useStyles, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { FormApi } from 'final-form'
import { useAlterarSenhaMutation } from 'graphql/hooks.generated'
import { AlterarSenhaInput } from 'graphql/types.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { AlterarSenhaForm } from 'view/alterarSenha/AlterarSenhaForm'

export interface AlterarSenhaProps {
  pedirSenhaAtual?: boolean
}

export const AlterarSenhaView = (props: AlterarSenhaProps) => {
  const { classes } = useStyles(createStyles)
  const history = useHistory()
  const onSair = () => history.push('/logout')
  const [alterarSenha] = useAlterarSenhaMutation()

  const handleSubmit = (values: AlterarSenhaInput, formApi: FormApi) => {
    return alterarSenha({ variables: { input: values } }).then(res => {
      alert('success', 'Senha alterada com sucesso')
      setTimeout(formApi.reset)
      return res
    })
  }

  return (
    <PageContent type='filled' style={{ marginTop: '2rem' }}>
      <Breadcrumb title='Alterar Senha' />
      <VFlow>
        <HeadingSection title={props.pedirSenhaAtual ? 'Alterar senha' : 'Definir senha'} level={1} />
        <div className={classes.info}>
          <HFlow hSpacing={0.5}>
            <span className={classes.infoIcon}>
              <Icon icon='infoCircleFilled' size={2.0} />
            </span>
            <span>
              <VFlow vSpacing={0.1}>
                <p>A nova senha não pode ser igual à senha atual.</p>
                <p>Use pelo menos uma letra e um número.</p>
                <p>Mínimo de 8 e máximo de 20 caracteres.</p>
                <p>Use somente letras e números.</p>
                <p>O sistema diferencia letras maiúsculas de minúsculas.</p>
              </VFlow>
            </span>
          </HFlow>
        </div>
        <AlterarSenhaForm onSair={onSair} onSubmit={handleSubmit} pedirSenhaAtual={props.pedirSenhaAtual} />
      </VFlow>
    </PageContent>
  )
}

AlterarSenhaView.defaultProps = {
  pedirSenhaAtual: true,
} as AlterarSenhaProps

export const ForcarAlterarSenhaView = () => <AlterarSenhaView pedirSenhaAtual={false} />

const createStyles = (theme: Theme) => ({
  info: {
    backgroundColor: theme.pallete.status.info.background,
    border: '1px solid ' + theme.pallete.status.info.main,
    padding: '8px',
    width: '50%',
    fontSize: '13px',
    borderRadius: '2px',
    color: theme.pallete.status.info.main,
  },
  infoIcon: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
