import { FechamentoTableQuery, FechamentoTableQueryVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useQuery } from 'graphql/hooks'

export const FECHAMENTOS_TABLE_QUERY = gql`
  query FechamentoTableQuery($input: AgendaFechamentoQueryInput!) {
    fechamentos(input: $input) {
      id
      dataInicial
      dataFinal
      motivo
      especifique
    }
  }
`

export function useFechamentoTableQuery(lotacaoId: string) {
  return useQuery<FechamentoTableQuery, FechamentoTableQueryVariables>(FECHAMENTOS_TABLE_QUERY, {
    variables: {
      input: { idLotacao: lotacaoId },
    },
  })
}
