import { Button, Cell, Grid, HFlow, Icon, Text, Tooltip, useTheme } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { resolveValue } from 'components/form/final-form/useField'
import React, { useEffect, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { VincularEquipeFormModel } from '../CidadaoFormModel'
import { VincularCidadaoConcluded } from './VincularCidadaoConcluded'
import { VincularCidadaoEdit } from './VincularCidadaoEdit'

interface VincularCidadaoComponentRootProps {
  name: MetaPath<VincularEquipeFormModel>
  values?: any
  formProps: FormRenderProps<any>
  cpfCidadao: string
  cnsCidadao: string
  uuidFicha: string
  faleceu: boolean
}

export interface UsuarioLogadoVinculacao {
  unidadeSaude: {
    id: string
    nome?: string
  }
}

export type EstadoComponenteVinculo = 'SEM_VINCULO' | 'CADASTRAR_VINCULO' | 'VINCULO_CADASTRADO'

export const VincularCidadaoComponentRoot = (props: VincularCidadaoComponentRootProps) => {
  const { name, values, formProps } = props
  const theme = useTheme()
  const vincularValues = resolveValue(values ? values : props.values, name) || ({} as VincularEquipeFormModel)
  const [componentState, setComponentState] = useState<EstadoComponenteVinculo>(
    vincularValues?.unidadeSaude || vincularValues?.cadastroIndividualCheck ? 'VINCULO_CADASTRADO' : 'SEM_VINCULO'
  )

  const session = useSession({ fetchPolicy: 'cache-only' })

  const usuarioLogado: UsuarioLogadoVinculacao = session.data.acesso.__typename === 'Lotacao' && session.data.acesso

  const clearComponent = () => {
    formProps.form.change(name.absolutePath(), null)
  }

  useEffect(() => {
    if (props.faleceu) {
      setComponentState('SEM_VINCULO')
    }
  }, [props.faleceu])

  return (
    <Grid wrap>
      <Cell size={8}>
        <Text>
          A equipe responsável vinculada tem a responsabilidade de acompanhar este cidadão.
          <br />
          O vínculo pode ser com a equipe responsável pela área em que o cidadão reside ou com
          <br />
          outra equipe ou unidade de saúde.
        </Text>
      </Cell>
      {componentState === 'SEM_VINCULO' && (
        <Cell size={8}>
          <HFlow
            style={{
              border: `1px solid ${theme.pallete.divider}`,
              padding: '1rem',
            }}
            alignItems='center'
            justifyContent='space-between'
          >
            <Text fontStyle='italic'>Cidadão não possui equipe responsável vinculada.</Text>
            <Tooltip
              text={props.faleceu ? 'Não é possível vincular uma equipe a um cidadão que possui óbito registrado' : ''}
            >
              <Button
                kind='primary'
                size='small'
                onClick={() => setComponentState('CADASTRAR_VINCULO')}
                disabled={props.faleceu}
              >
                <HFlow hSpacing={0.5}>
                  <Icon icon='link' size={1.5} />
                  Vincular Equipe
                </HFlow>
              </Button>
            </Tooltip>
          </HFlow>
        </Cell>
      )}

      <Cell size={8}>
        {componentState === 'CADASTRAR_VINCULO' && (
          <VincularCidadaoEdit
            setComponentState={setComponentState}
            name={name}
            values={vincularValues}
            usuarioLogado={usuarioLogado}
            cnsCidadao={props.cnsCidadao}
            cpfCidadao={props.cpfCidadao}
            uuidFicha={props.uuidFicha}
          />
        )}
        {componentState === 'VINCULO_CADASTRADO' && (
          <VincularCidadaoConcluded
            clearComponent={clearComponent}
            setComponentState={setComponentState}
            values={vincularValues}
            usuarioLogado={usuarioLogado}
          />
        )}
      </Cell>
    </Grid>
  )
}
