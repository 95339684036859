import { Paper } from 'bold-ui'
import { InstalacaoHeader } from 'components/layout/InstalacaoHeader'
import { PageContent } from 'components/layout/PageContent'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { Route, Switch } from 'react-router'

import OpcaoResetView from './OpcaoResetView'
import ResetInstaladorContraChaveView from './ResetInstaladorContraChaveView'
import ResetInstaladorView from './ResetInstaladorProfissionalView'

export default function() {
  const { match } = useRouter()

  return (
    <PageContent>
      <InstalacaoHeader title='e-SUS APS PEC' />
      <Paper style={{ backgroundColor: 'white', padding: '2rem 2rem 0 2rem' }}>
        <Switch>
          <Route component={OpcaoResetView} exact path={`${match.url}`} />
          <Route component={ResetInstaladorContraChaveView} exact path={`${match.url}/chave/:isEditAdmin(\\d)`} />
          <Route
            component={ResetInstaladorView}
            path={`${match.url}/chave/:isEditAdmin(\\d+)/profissional/:cpfAdmin(\\d+)`}
          />
        </Switch>
      </Paper>
    </PageContent>
  )
}
