import {
  AlternarConexaoInternetMutationVariables,
  ConexaoQuery_conexao_agendaOnline,
  ConexaoQuery_conexao_atestadoDigital,
  ConexaoQuery_conexao_cadsus,
  ConexaoQuery_conexao_horus,
  ConexaoQuery_conexao_internet,
} from 'api/schema'
import { Alert, Cell, Grid, Heading, HFlow, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, SwitchField } from 'components/form'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

import { ConfiguracaoAgendaOnlineBox } from './agenda-online/ConfiguracaoAgendaOnlineBox'
import { ConfiguracoesAtestadoDigitalBox } from './atestado-digital/ConfiguracoesAtestadoDigitalBox'
import { ConfiguracoesCadSusBox } from './cadsus/ConfiguracoesCadSusBox'
import { ConfiguracaoInternetTesteConexao } from './ConfiguracaoInternetTesteConexao'
import { ConfiguracoesHorusBox } from './horus/ConfiguracoesHorusBox'
import { useAlternarConexaoMutation } from './useAlternarConexaoMutation'

export interface ConfiguracoesInternetBoxProps {
  internet: ConexaoQuery_conexao_internet
  cadsus: ConexaoQuery_conexao_cadsus
  horus: ConexaoQuery_conexao_horus
  atestadoDigital: ConexaoQuery_conexao_atestadoDigital
  agendaOnline: ConexaoQuery_conexao_agendaOnline
  refetch(): void
}

export function ConfiguracoesInternetBox(props: ConfiguracoesInternetBoxProps) {
  const alert = useAlert()

  const [alternarConexaoMutation] = useAlternarConexaoMutation()

  const renderForm = (formProps: FormRenderProps<AlternarConexaoInternetMutationVariables>) => {
    const label: string = props.internet.habilitado ? 'Habilitado' : 'Desabilitado'
    return (
      <VFlow>
        <HFlow justifyContent='space-between'>
          <Heading style={{ display: 'inline' }} level={3}>
            Internet
          </Heading>
          <SwitchField label={label} name='habilitado' onChange={handleChange(formProps)} />
        </HFlow>
        <Alert type='info' inline>
          Ao desabilitar a conexão com internet, o Envio Online e a conexão com CADSUS, Hórus, Agenda Online e Sistema
          VIO serão desabilitados.
        </Alert>
        {formProps.values.habilitado && (
          <ConfiguracaoInternetTesteConexao refetch={props.refetch} internet={props.internet} />
        )}
      </VFlow>
    )
  }

  const handleChange = (formProps: FormRenderProps) => e => {
    formProps.handleSubmit()
  }

  const handleSubmit = (values: AlternarConexaoInternetMutationVariables) => {
    return alternarConexaoMutation({ variables: values }).then(() => {
      const state: string = values.habilitado ? 'habilitada' : 'desabilitada'
      alert('success', 'Conexão com internet ' + state + ' com sucesso.')
      props.refetch()
    })
  }

  return (
    <Box>
      {props.internet && (
        <VFlow vSpacing={0.5}>
          <Form onSubmit={handleSubmit} initialValues={{ habilitado: props.internet.habilitado }} render={renderForm} />
          <Grid gap={2}>
            <Cell size={6}>
              <ConfiguracoesCadSusBox
                internetHabilitada={props.internet.habilitado}
                cadsus={props.cadsus}
                load={props.refetch}
              />
            </Cell>
            <Cell size={6}>
              <ConfiguracoesHorusBox
                internetHabilitada={props.internet.habilitado}
                horus={props.horus}
                load={props.refetch}
              />
            </Cell>
            <Cell size={6}>
              <ConfiguracoesAtestadoDigitalBox
                internetHabilitada={props.internet.habilitado}
                atestadoDigital={props.atestadoDigital}
                load={props.refetch}
              />
            </Cell>
          </Grid>
          <ConfiguracaoAgendaOnlineBox
            agendaOnline={props.agendaOnline}
            internetHabilitada={props.internet.habilitado}
            refetch={props.refetch}
          />
        </VFlow>
      )}
    </Box>
  )
}
