import { Alert, HeadingSection } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { analytics } from 'config/firebase'
import { useSalvarProfissionalMutation } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import qs from 'qs'
import React from 'react'
import { ProfissionalDadosForm } from 'view/profissional/components/ProfissionalDadosForm'

import ProfissionalFormModel from './components/ProfissionalFormModel'
import convertModelToInput from './convertModelToInput'

export interface ProfissionalCreateViewUrlParams {
  callbackLink: string
}

export function ProfissionalCreateView() {
  const { location, history } = useRouter<ProfissionalCreateViewUrlParams>()

  const alert = useAlert()
  const [save] = useSalvarProfissionalMutation()

  const handleSubmit = (values: ProfissionalFormModel) =>
    save({ variables: { input: convertModelToInput(values) } }).then(ret => {
      setTimeout(() => {
        analytics.logEvent('add_profissional')
        alert('success', 'Profissional foi salvo com sucesso')
        const gets = qs.parse(location.search, {
          ignoreQueryPrefix: true,
          parameterLimit: 1,
        }) as ProfissionalCreateViewUrlParams
        if (gets && gets.callbackLink) {
          const data = ret && ret.data
          history.push(
            decodeURIComponent(gets.callbackLink).concat(
              `&pId=${data.salvarProfissional.id}&pNome=${data.salvarProfissional.nome}`
            )
          )
          return ret
        }
        history.push('/profissional')
      })
      return ret
    })

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Cadastro' />
      <HeadingSection level={1} title='Cadastro de profissional'>
        <Alert type='info' inline style={{ marginBottom: '1rem', marginTop: '1rem' }}>
          Informe ao profissional que o login será o seu CPF e a senha será o seu CNS + esus, caso o profissional não
          tenha CNS cadastrado, será CPF + esus.
        </Alert>
        <ProfissionalDadosForm onSubmit={handleSubmit} />
      </HeadingSection>
    </PageContent>
  )
}
