import { Cell, Grid } from 'bold-ui'
import { CheckboxField, Form, TextField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { FormState } from 'final-form'
import { PerfisQueryInput } from 'graphql/types.generated'
import React from 'react'

export interface PerfilTableFilterProps {
  initialValues: PerfisQueryInput
  onChange(values: PerfisQueryInput): void
}

export function PerfilTableFilter(props: PerfilTableFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<PerfisQueryInput>) => onChange(formState.values)

  const renderForm = () => {
    return (
      <>
        <FormDebouncedValueSpy loadOnMount={false} onChange={handleChange} />

        <Grid alignItems='center' gap={1}>
          <Cell size={6}>
            <TextField name='query' placeholder='Pesquise por perfil' icon='zoomOutline' />
          </Cell>
          <Cell size={6}>
            <CheckboxField name='mostrarInativos' label='Mostrar perfis inativos' />
          </Cell>
        </Grid>
      </>
    )
  }

  return <Form<PerfisQueryInput> initialValues={initialValues} onSubmit={onChange} render={renderForm} />
}
