/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SessaoQuery
// ====================================================

export interface SessaoQuery_sessao_profissional_usuario {
  __typename: "Usuario";
  id: string;
  aceitouTermosUso: boolean;
  trocarSenha: boolean;
  visualizouNovidades: boolean;
  mostrarPesquisaSatisfacao: boolean;
}

export interface SessaoQuery_sessao_profissional_acessos_AdministradorGeral {
  __typename: "AdministradorGeral";
  id: string;
  tipo: TipoAcesso;
}

export interface SessaoQuery_sessao_profissional_acessos_AdministradorMunicipal_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface SessaoQuery_sessao_profissional_acessos_AdministradorMunicipal {
  __typename: "AdministradorMunicipal";
  id: string;
  tipo: TipoAcesso;
  municipio: SessaoQuery_sessao_profissional_acessos_AdministradorMunicipal_municipio;
}

export interface SessaoQuery_sessao_profissional_acessos_GestorEstadual_uf {
  __typename: "UF";
  id: string;
  nome: string;
}

export interface SessaoQuery_sessao_profissional_acessos_GestorEstadual {
  __typename: "GestorEstadual";
  id: string;
  tipo: TipoAcesso;
  uf: SessaoQuery_sessao_profissional_acessos_GestorEstadual_uf;
}

export interface SessaoQuery_sessao_profissional_acessos_GestorMunicipal_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface SessaoQuery_sessao_profissional_acessos_GestorMunicipal {
  __typename: "GestorMunicipal";
  id: string;
  tipo: TipoAcesso;
  municipio: SessaoQuery_sessao_profissional_acessos_GestorMunicipal_municipio;
}

export interface SessaoQuery_sessao_profissional_acessos_Lotacao_cbo {
  __typename: "Cbo";
  id: string;
  nome: string;
  cbo2002: string;
}

export interface SessaoQuery_sessao_profissional_acessos_Lotacao_equipe {
  __typename: "Equipe";
  id: string;
  nome: string;
  ine: string;
}

export interface SessaoQuery_sessao_profissional_acessos_Lotacao_unidadeSaude {
  __typename: "UnidadeSaude";
  id: string;
  nome: string | null;
}

export interface SessaoQuery_sessao_profissional_acessos_Lotacao {
  __typename: "Lotacao";
  id: string;
  tipo: TipoAcesso;
  cbo: SessaoQuery_sessao_profissional_acessos_Lotacao_cbo;
  equipe: SessaoQuery_sessao_profissional_acessos_Lotacao_equipe | null;
  unidadeSaude: SessaoQuery_sessao_profissional_acessos_Lotacao_unidadeSaude;
}

export type SessaoQuery_sessao_profissional_acessos = SessaoQuery_sessao_profissional_acessos_AdministradorGeral | SessaoQuery_sessao_profissional_acessos_AdministradorMunicipal | SessaoQuery_sessao_profissional_acessos_GestorEstadual | SessaoQuery_sessao_profissional_acessos_GestorMunicipal | SessaoQuery_sessao_profissional_acessos_Lotacao;

export interface SessaoQuery_sessao_profissional {
  __typename: "Profissional";
  id: string;
  cns: string | null;
  nome: string;
  usuario: SessaoQuery_sessao_profissional_usuario;
  acessos: SessaoQuery_sessao_profissional_acessos[] | null;
}

export interface SessaoQuery_sessao_acesso_Lotacao_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface SessaoQuery_sessao_acesso_Lotacao {
  __typename: "Lotacao" | "AdministradorGeral" | "GestorEstadual" | "GestorMunicipal";
  id: string;
  tipo: TipoAcesso;
  moduloInicial: string | null;
  municipio: SessaoQuery_sessao_acesso_Lotacao_municipio | null;
}

export interface SessaoQuery_sessao_acesso_AdministradorMunicipal_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface SessaoQuery_sessao_acesso_AdministradorMunicipal {
  __typename: "AdministradorMunicipal";
  id: string;
  tipo: TipoAcesso;
  moduloInicial: string | null;
  municipio: SessaoQuery_sessao_acesso_AdministradorMunicipal_municipio | null;
  autorizado: boolean;
  habilitado: boolean;
}

export type SessaoQuery_sessao_acesso = SessaoQuery_sessao_acesso_Lotacao | SessaoQuery_sessao_acesso_AdministradorMunicipal;

export interface SessaoQuery_sessao {
  __typename: "Sessao";
  id: string;
  timeout: number;
  recursos: (string | null)[] | null;
  profissional: SessaoQuery_sessao_profissional;
  acesso: SessaoQuery_sessao_acesso | null;
}

export interface SessaoQuery {
  sessao: SessaoQuery_sessao | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CategoriaProfissionalSelectQuery
// ====================================================

export interface CategoriaProfissionalSelectQuery_categoriasProfissional {
  __typename: "CategoriaProfissional";
  id: string;
  nome: string;
}

export interface CategoriaProfissionalSelectQuery {
  categoriasProfissional: (CategoriaProfissionalSelectQuery_categoriasProfissional | null)[];
}

export interface CategoriaProfissionalSelectQueryVariables {
  input: CategoriaProfissionalQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TipoEventoComboQuery
// ====================================================

export interface TipoEventoComboQuery_auditoriaTipoEvento {
  __typename: "AuditoriaTipoEvento";
  id: string;
  nome: string;
}

export interface TipoEventoComboQuery {
  auditoriaTipoEvento: (TipoEventoComboQuery_auditoriaTipoEvento | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TipoRegistroAfetadoComboQuery
// ====================================================

export interface TipoRegistroAfetadoComboQuery_auditoriaTipoRegistroAfetado {
  __typename: "AuditoriaTipoRegistroAfetado";
  id: string;
  nome: string;
}

export interface TipoRegistroAfetadoComboQuery {
  auditoriaTipoRegistroAfetado: (TipoRegistroAfetadoComboQuery_auditoriaTipoRegistroAfetado | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onAuditoriaProcessoAdded
// ====================================================

export interface onAuditoriaProcessoAdded_auditoriaProcessoAdded {
  __typename: "AuditoriaHistorico";
  id: string;
}

export interface onAuditoriaProcessoAdded {
  auditoriaProcessoAdded: onAuditoriaProcessoAdded_auditoriaProcessoAdded;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onAuditoriaProcessoUpdated
// ====================================================

export interface onAuditoriaProcessoUpdated_auditoriaProcessoUpdated {
  __typename: "AuditoriaHistorico";
  id: string;
  dataInicio: LocalDate;
  dataFim: LocalDate;
  dataSolicitacao: Instant;
  status: StatusProcessoAuditoria;
  csv: boolean | null;
}

export interface onAuditoriaProcessoUpdated {
  auditoriaProcessoUpdated: onAuditoriaProcessoUpdated_auditoriaProcessoUpdated;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProcessarAuditoriaMutation
// ====================================================

export interface ProcessarAuditoriaMutation {
  processAuditoria: boolean | null;
}

export interface ProcessarAuditoriaMutationVariables {
  input: AuditoriaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CboEditComponentQuery
// ====================================================

export interface CboEditComponentQuery_conexao_agendaOnline {
  __typename: "AgendaOnline";
  ativa: boolean;
}

export interface CboEditComponentQuery_conexao {
  __typename: "ConfiguracaoConexao";
  agendaOnline: CboEditComponentQuery_conexao_agendaOnline | null;
}

export interface CboEditComponentQuery {
  conexao: CboEditComponentQuery_conexao;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DetalhesImportacaoCnesQuery
// ====================================================

export interface DetalhesImportacaoCnesQuery_importacaoCnes_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface DetalhesImportacaoCnesQuery_importacaoCnes {
  __typename: "ImportacaoCnes";
  id: string;
  dataImportacao: Instant;
  detalhes: string | null;
  equipesNovas: number | null;
  equipesAtualizadas: number | null;
  lotacoesNovas: number | null;
  lotacoesAtualizadas: number | null;
  profissionaisNovos: number | null;
  profissionaisAtualizados: number | null;
  unidadesSaudeNovas: number | null;
  unidadesSaudeAtualizadas: number | null;
  municipio: DetalhesImportacaoCnesQuery_importacaoCnes_municipio;
}

export interface DetalhesImportacaoCnesQuery {
  importacaoCnes: DetalhesImportacaoCnesQuery_importacaoCnes;
}

export interface DetalhesImportacaoCnesQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onImportacaoCnesAdded
// ====================================================

export interface onImportacaoCnesAdded_importacaoCnesAdded {
  __typename: "ImportacaoCnes";
  id: string;
}

export interface onImportacaoCnesAdded {
  importacaoCnesAdded: onImportacaoCnesAdded_importacaoCnesAdded;
}

export interface onImportacaoCnesAddedVariables {
  municipio: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: onImportacaoCnesUpdated
// ====================================================

export interface onImportacaoCnesUpdated_importacaoCnesUpdated_processo {
  __typename: "Processo";
  id: string;
  status: StatusProcesso;
}

export interface onImportacaoCnesUpdated_importacaoCnesUpdated_profissional {
  __typename: "Profissional";
  id: string;
  nome: string;
}

export interface onImportacaoCnesUpdated_importacaoCnesUpdated {
  __typename: "ImportacaoCnes";
  id: string;
  dataImportacao: Instant;
  equipesNovas: number | null;
  equipesAtualizadas: number | null;
  lotacoesNovas: number | null;
  lotacoesAtualizadas: number | null;
  processo: onImportacaoCnesUpdated_importacaoCnesUpdated_processo;
  profissional: onImportacaoCnesUpdated_importacaoCnesUpdated_profissional;
  profissionaisNovos: number | null;
  profissionaisAtualizados: number | null;
  unidadesSaudeNovas: number | null;
  unidadesSaudeAtualizadas: number | null;
}

export interface onImportacaoCnesUpdated {
  importacaoCnesUpdated: onImportacaoCnesUpdated_importacaoCnesUpdated;
}

export interface onImportacaoCnesUpdatedVariables {
  municipio: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConexaoQuery
// ====================================================

export interface ConexaoQuery_conexao_internet {
  __typename: "ConfiguracaoInternet";
  habilitado: boolean;
  dataUltimoTeste: Instant | null;
  resultadoUltimoTeste: boolean | null;
}

export interface ConexaoQuery_conexao_cadsus {
  __typename: "ConfiguracaoCadsus";
  id: string;
  habilitado: boolean;
  desabilitadoAteData: Instant | null;
  intervalo: number | null;
}

export interface ConexaoQuery_conexao_horus {
  __typename: "ConfiguracaoHorus";
  id: string;
  habilitado: boolean;
  desabilitadoAteData: Instant | null;
  intervalo: number | null;
}

export interface ConexaoQuery_conexao_atestadoDigital {
  __typename: "ConfiguracaoAtestadoDigital";
  id: string;
  habilitado: boolean;
  desabilitadoAteData: Instant | null;
  intervalo: number | null;
}

export interface ConexaoQuery_conexao_agendaOnline_detalhesAtivacao_profissional {
  __typename: "Profissional";
  nome: string;
}

export interface ConexaoQuery_conexao_agendaOnline_detalhesAtivacao {
  __typename: "DetalhesAtivacao";
  profissional: ConexaoQuery_conexao_agendaOnline_detalhesAtivacao_profissional | null;
  dataEvento: Instant;
}

export interface ConexaoQuery_conexao_agendaOnline_testeConexao {
  __typename: "TesteConexao";
  sucesso: boolean;
  dataEvento: Instant;
}

export interface ConexaoQuery_conexao_agendaOnline {
  __typename: "AgendaOnline";
  ativa: boolean;
  detalhesAtivacao: ConexaoQuery_conexao_agendaOnline_detalhesAtivacao | null;
  testeConexao: ConexaoQuery_conexao_agendaOnline_testeConexao | null;
}

export interface ConexaoQuery_conexao {
  __typename: "ConfiguracaoConexao";
  internet: ConexaoQuery_conexao_internet;
  cadsus: ConexaoQuery_conexao_cadsus;
  horus: ConexaoQuery_conexao_horus;
  atestadoDigital: ConexaoQuery_conexao_atestadoDigital;
  agendaOnline: ConexaoQuery_conexao_agendaOnline | null;
}

export interface ConexaoQuery {
  conexao: ConexaoQuery_conexao;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CfgLotacaoAgendaOnlineQuery
// ====================================================

export interface CfgLotacaoAgendaOnlineQuery_conexao_agendaOnline {
  __typename: "AgendaOnline";
  ativa: boolean;
}

export interface CfgLotacaoAgendaOnlineQuery_conexao {
  __typename: "ConfiguracaoConexao";
  agendaOnline: CfgLotacaoAgendaOnlineQuery_conexao_agendaOnline | null;
  hasLotacoesConfiguradasOnline: boolean | null;
  lotacoesAgendaOnlineComDuracaoPadrao: number | null;
}

export interface CfgLotacaoAgendaOnlineQuery {
  conexao: CfgLotacaoAgendaOnlineQuery_conexao;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlternarConexaoInternetMutation
// ====================================================

export interface AlternarConexaoInternetMutation_alternarInternet {
  __typename: "ConfiguracaoInternet";
  habilitado: boolean;
}

export interface AlternarConexaoInternetMutation {
  alternarInternet: AlternarConexaoInternetMutation_alternarInternet | null;
}

export interface AlternarConexaoInternetMutationVariables {
  habilitado?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TestarConexaoInternetMutation
// ====================================================

export interface TestarConexaoInternetMutation_testarConexaoInternet {
  __typename: "ConfiguracaoInternet";
  habilitado: boolean;
  dataUltimoTeste: Instant | null;
  resultadoUltimoTeste: boolean | null;
}

export interface TestarConexaoInternetMutation {
  testarConexaoInternet: TestarConexaoInternetMutation_testarConexaoInternet | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InserirMunicipioResponsavel
// ====================================================

export interface InserirMunicipioResponsavel_inserirMunicipioResponsavel_profissional {
  __typename: "Profissional";
  nome: string;
}

export interface InserirMunicipioResponsavel_inserirMunicipioResponsavel_municipio {
  __typename: "Municipio";
  nome: string;
}

export interface InserirMunicipioResponsavel_inserirMunicipioResponsavel {
  __typename: "AdministradorMunicipal";
  id: string;
  profissional: InserirMunicipioResponsavel_inserirMunicipioResponsavel_profissional;
  municipio: InserirMunicipioResponsavel_inserirMunicipioResponsavel_municipio;
  habilitado: boolean;
  ativo: boolean;
  dataAdicao: Instant | null;
  dataInativacao: Instant | null;
}

export interface InserirMunicipioResponsavel {
  inserirMunicipioResponsavel: InserirMunicipioResponsavel_inserirMunicipioResponsavel;
}

export interface InserirMunicipioResponsavelVariables {
  input: MunicipioResponsavelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AtualizarMunicipioResponsavel
// ====================================================

export interface AtualizarMunicipioResponsavel_atualizarMunicipioResponsavel_profissional {
  __typename: "Profissional";
  id: string;
  nome: string;
}

export interface AtualizarMunicipioResponsavel_atualizarMunicipioResponsavel_municipio_uf {
  __typename: "UF";
  id: string;
  sigla: string;
}

export interface AtualizarMunicipioResponsavel_atualizarMunicipioResponsavel_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
  uf: AtualizarMunicipioResponsavel_atualizarMunicipioResponsavel_municipio_uf;
}

export interface AtualizarMunicipioResponsavel_atualizarMunicipioResponsavel {
  __typename: "AdministradorMunicipal";
  id: string;
  profissional: AtualizarMunicipioResponsavel_atualizarMunicipioResponsavel_profissional;
  municipio: AtualizarMunicipioResponsavel_atualizarMunicipioResponsavel_municipio;
  habilitado: boolean;
  ativo: boolean;
  dataAdicao: Instant | null;
  dataInativacao: Instant | null;
}

export interface AtualizarMunicipioResponsavel {
  atualizarMunicipioResponsavel: AtualizarMunicipioResponsavel_atualizarMunicipioResponsavel;
}

export interface AtualizarMunicipioResponsavelVariables {
  input: MunicipioResponsavelUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExcluirMunicipioResponsavel
// ====================================================

export interface ExcluirMunicipioResponsavel {
  excluirMunicipioResponsavel: string;
}

export interface ExcluirMunicipioResponsavelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterarNumeroTentativasLogin
// ====================================================

export interface AlterarNumeroTentativasLogin_alterarNumeroTentativasLogin {
  __typename: "ConfiguracaoTentativasLogin";
  tentativasLogin: number | null;
}

export interface AlterarNumeroTentativasLogin {
  alterarNumeroTentativasLogin: AlterarNumeroTentativasLogin_alterarNumeroTentativasLogin | null;
}

export interface AlterarNumeroTentativasLoginVariables {
  tentativas?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterarPeriodoInatividade
// ====================================================

export interface AlterarPeriodoInatividade_alterarPeriodoInatividade {
  __typename: "ConfiguracaoPeriodoInatividade";
  periodoInatividade: number | null;
}

export interface AlterarPeriodoInatividade {
  alterarPeriodoInatividade: AlterarPeriodoInatividade_alterarPeriodoInatividade | null;
}

export interface AlterarPeriodoInatividadeVariables {
  periodo?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterarPeriodoRedefinicaoSenha
// ====================================================

export interface AlterarPeriodoRedefinicaoSenha_alterarPeriodoRedefinicaoSenha {
  __typename: "ConfiguracaoRedefinicaoSenha";
  redefinicaoSenha: number | null;
}

export interface AlterarPeriodoRedefinicaoSenha {
  alterarPeriodoRedefinicaoSenha: AlterarPeriodoRedefinicaoSenha_alterarPeriodoRedefinicaoSenha | null;
}

export interface AlterarPeriodoRedefinicaoSenhaVariables {
  periodo?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForcarRedefinirSenhas
// ====================================================

export interface ForcarRedefinirSenhas_forcarRedefinirSenhas {
  __typename: "ConfiguracaoRedefinicaoSenha";
  redefinicaoSenha: number | null;
}

export interface ForcarRedefinirSenhas {
  forcarRedefinirSenhas: ForcarRedefinirSenhas_forcarRedefinirSenhas | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SegurancaQuery
// ====================================================

export interface SegurancaQuery_seguranca_periodoEmMesesParaRedefinir {
  __typename: "ConfiguracaoRedefinicaoSenha";
  redefinicaoSenha: number | null;
}

export interface SegurancaQuery_seguranca_periodoEmMinutosInatividade {
  __typename: "ConfiguracaoPeriodoInatividade";
  periodoInatividade: number | null;
}

export interface SegurancaQuery_seguranca_loginNumeroTentativas {
  __typename: "ConfiguracaoTentativasLogin";
  tentativasLogin: number | null;
}

export interface SegurancaQuery_seguranca {
  __typename: "ConfiguracaoSeguranca";
  periodoEmMesesParaRedefinir: SegurancaQuery_seguranca_periodoEmMesesParaRedefinir;
  periodoEmMinutosInatividade: SegurancaQuery_seguranca_periodoEmMinutosInatividade;
  loginNumeroTentativas: SegurancaQuery_seguranca_loginNumeroTentativas;
}

export interface SegurancaQuery {
  seguranca: SegurancaQuery_seguranca;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SalvarConfiguracaoHorariosMunicipioMutation
// ====================================================

export interface SalvarConfiguracaoHorariosMunicipioMutation_salvarConfiguracaoHorariosMunicipio_configuracaoHorarioAgenda_configuracaoDiasSemana_periodos {
  __typename: "ConfiguracaoPeriodo";
  periodo: PeriodoEnum;
  horarioInicial: string | null;
  horarioFinal: string | null;
}

export interface SalvarConfiguracaoHorariosMunicipioMutation_salvarConfiguracaoHorariosMunicipio_configuracaoHorarioAgenda_configuracaoDiasSemana {
  __typename: "ConfiguracaoDiaSemana";
  diaSemana: DiaSemanaEnum;
  periodos: SalvarConfiguracaoHorariosMunicipioMutation_salvarConfiguracaoHorariosMunicipio_configuracaoHorarioAgenda_configuracaoDiasSemana_periodos[] | null;
}

export interface SalvarConfiguracaoHorariosMunicipioMutation_salvarConfiguracaoHorariosMunicipio_configuracaoHorarioAgenda {
  __typename: "ConfiguracaoHorarioAgenda";
  configuracaoDiasSemana: SalvarConfiguracaoHorariosMunicipioMutation_salvarConfiguracaoHorariosMunicipio_configuracaoHorarioAgenda_configuracaoDiasSemana[] | null;
}

export interface SalvarConfiguracaoHorariosMunicipioMutation_salvarConfiguracaoHorariosMunicipio {
  __typename: "ConfiguracaoAgendaMunicipal";
  id: string;
  configuracaoHorarioAgenda: SalvarConfiguracaoHorariosMunicipioMutation_salvarConfiguracaoHorariosMunicipio_configuracaoHorarioAgenda;
}

export interface SalvarConfiguracaoHorariosMunicipioMutation {
  salvarConfiguracaoHorariosMunicipio: SalvarConfiguracaoHorariosMunicipioMutation_salvarConfiguracaoHorariosMunicipio | null;
}

export interface SalvarConfiguracaoHorariosMunicipioMutationVariables {
  configuracaoAgendaMunicipalInput?: ConfiguracaoAgendaMunicipalInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation
// ====================================================

export interface SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation_alterarDuracaoPadraoAtendimento {
  __typename: "DuracaoAtendimentoPadraoMunicipio";
  duracaoAtendimentoPadraoMunicipio: number;
}

export interface SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation {
  alterarDuracaoPadraoAtendimento: SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation_alterarDuracaoPadraoAtendimento;
}

export interface SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables {
  duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExcluirGrupoExame
// ====================================================

export interface ExcluirGrupoExame {
  excluirGrupoExame: string;
}

export interface ExcluirGrupoExameVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SalvarGrupoExame
// ====================================================

export interface SalvarGrupoExame_salvarGrupoExame_procedimentos {
  __typename: "Procedimento";
  id: string;
  descricao: string;
  codigo: string;
  descricaoReferencia: string | null;
  codigoReferencia: string | null;
}

export interface SalvarGrupoExame_salvarGrupoExame {
  __typename: "GrupoExame";
  id: string;
  nome: string;
  procedimentos: (SalvarGrupoExame_salvarGrupoExame_procedimentos | null)[] | null;
}

export interface SalvarGrupoExame {
  salvarGrupoExame: SalvarGrupoExame_salvarGrupoExame;
}

export interface SalvarGrupoExameVariables {
  input: GrupoExameInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterarTipoServico
// ====================================================

export interface AlterarTipoServico_alterarTipoServico {
  __typename: "TipoServico";
  id: string;
  nome: string;
}

export interface AlterarTipoServico {
  alterarTipoServico: AlterarTipoServico_alterarTipoServico | null;
}

export interface AlterarTipoServicoVariables {
  input: TipoServicoUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SalvarTipoServicoMutation
// ====================================================

export interface SalvarTipoServicoMutation_salvarTipoServico {
  __typename: "TipoServico";
  id: string;
  nome: string;
}

export interface SalvarTipoServicoMutation {
  salvarTipoServico: SalvarTipoServicoMutation_salvarTipoServico | null;
}

export interface SalvarTipoServicoMutationVariables {
  input: TipoServicoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExcluirTipoServico
// ====================================================

export interface ExcluirTipoServico {
  excluirTipoServico: string | null;
}

export interface ExcluirTipoServicoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EquipeDetailQuery
// ====================================================

export interface EquipeDetailQuery_equipe_tipoEquipe {
  __typename: "TipoEquipe";
  nome: string;
  sigla: string;
}

export interface EquipeDetailQuery_equipe {
  __typename: "Equipe";
  id: string;
  nome: string;
  ine: string;
  area: string;
  ativo: boolean;
  tipoEquipe: EquipeDetailQuery_equipe_tipoEquipe;
}

export interface EquipeDetailQuery {
  equipe: EquipeDetailQuery_equipe;
}

export interface EquipeDetailQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EquipeTableQuery
// ====================================================

export interface EquipeTableQuery_unidadeSaude_equipes_content_tipoEquipe {
  __typename: "TipoEquipe";
  nome: string;
  sigla: string;
}

export interface EquipeTableQuery_unidadeSaude_equipes_content {
  __typename: "Equipe";
  id: string;
  nome: string;
  ine: string;
  area: string;
  tipoEquipe: EquipeTableQuery_unidadeSaude_equipes_content_tipoEquipe;
  ativo: boolean;
}

export interface EquipeTableQuery_unidadeSaude_equipes_pageInfo {
  __typename: "PageInfo";
  first: boolean;
  number: number;
  size: number;
  sort: (string | null)[] | null;
  totalPages: number;
  totalElements: number;
  last: boolean;
  numberOfElements: number;
}

export interface EquipeTableQuery_unidadeSaude_equipes {
  __typename: "EquipePayload";
  content: (EquipeTableQuery_unidadeSaude_equipes_content | null)[];
  pageInfo: EquipeTableQuery_unidadeSaude_equipes_pageInfo;
}

export interface EquipeTableQuery_unidadeSaude {
  __typename: "UnidadeSaude";
  id: string;
  equipes: EquipeTableQuery_unidadeSaude_equipes;
}

export interface EquipeTableQuery {
  unidadeSaude: EquipeTableQuery_unidadeSaude;
}

export interface EquipeTableQueryVariables {
  unidadeSaudeId: string;
  input: EquipesConnectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DefinirModuloInicial
// ====================================================

export interface DefinirModuloInicial_definirModuloInicial {
  __typename: "Lotacao" | "AdministradorGeral" | "AdministradorMunicipal" | "GestorEstadual" | "GestorMunicipal";
  id: string;
  moduloInicial: string | null;
}

export interface DefinirModuloInicial {
  definirModuloInicial: DefinirModuloInicial_definirModuloInicial;
}

export interface DefinirModuloInicialVariables {
  input: SelecionarModuloInicialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SelecionarAcessoMutation
// ====================================================

export interface SelecionarAcessoMutation_selecionarAcesso_profissional {
  __typename: "Profissional";
  id: string;
  nome: string;
}

export interface SelecionarAcessoMutation_selecionarAcesso_acesso {
  __typename: "Lotacao" | "AdministradorGeral" | "AdministradorMunicipal" | "GestorEstadual" | "GestorMunicipal";
  id: string;
  moduloInicial: string | null;
}

export interface SelecionarAcessoMutation_selecionarAcesso {
  __typename: "Sessao";
  id: string;
  profissional: SelecionarAcessoMutation_selecionarAcesso_profissional;
  acesso: SelecionarAcessoMutation_selecionarAcesso_acesso | null;
  recursos: (string | null)[] | null;
}

export interface SelecionarAcessoMutation {
  selecionarAcesso: SelecionarAcessoMutation_selecionarAcesso | null;
}

export interface SelecionarAcessoMutationVariables {
  input: SelecionarAcessoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PerfilDetailQuery
// ====================================================

export interface PerfilDetailQuery_perfil {
  __typename: "Perfil";
  id: string;
  nome: string;
  tipoPerfil: TipoAcesso;
  recursos: (string | null)[];
  perfilPadrao: string | null;
}

export interface PerfilDetailQuery {
  perfil: PerfilDetailQuery_perfil | null;
}

export interface PerfilDetailQueryVariables {
  perfilId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DesativarConfiguracaoAgendaOnlineLotacaoMutation
// ====================================================

export interface DesativarConfiguracaoAgendaOnlineLotacaoMutation {
  desativarConfiguracaoAgendaOnlineLotacao: boolean;
}

export interface DesativarConfiguracaoAgendaOnlineLotacaoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExcluirConfiguracaoAgendaLotacaoMutation
// ====================================================

export interface ExcluirConfiguracaoAgendaLotacaoMutation {
  excluirConfiguracaoAgendaLotacao: boolean;
}

export interface ExcluirConfiguracaoAgendaLotacaoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExcluirFechamentoMutation
// ====================================================

export interface ExcluirFechamentoMutation {
  excluirFechamento: string | null;
}

export interface ExcluirFechamentoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SalvarFechamentoMutation
// ====================================================

export interface SalvarFechamentoMutation_salvarFechamento {
  __typename: "FechamentoAgenda";
  id: string | null;
}

export interface SalvarFechamentoMutation {
  salvarFechamento: SalvarFechamentoMutation_salvarFechamento | null;
}

export interface SalvarFechamentoMutationVariables {
  input: AgendaFechamentoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FechamentoTableQuery
// ====================================================

export interface FechamentoTableQuery_fechamentos {
  __typename: "FechamentoAgenda";
  id: string | null;
  dataInicial: LocalDate | null;
  dataFinal: LocalDate | null;
  motivo: string | null;
  especifique: string | null;
}

export interface FechamentoTableQuery {
  fechamentos: FechamentoTableQuery_fechamentos[];
}

export interface FechamentoTableQueryVariables {
  input: AgendaFechamentoQueryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SalvarConfiguracaoAgendaLotacaoMutation
// ====================================================

export interface SalvarConfiguracaoAgendaLotacaoMutation {
  salvarConfiguracaoAgendaLotacao: boolean | null;
}

export interface SalvarConfiguracaoAgendaLotacaoMutationVariables {
  configuracaoAgendaLotacaoInput?: ConfiguracaoAgendaLotacaoInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConfiguracaoAgendaOnlineLotacaoQuery
// ====================================================

export interface ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_cbo {
  __typename: "Cbo";
  id: string;
  nome: string;
  cbo2002: string;
}

export interface ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_equipe {
  __typename: "Equipe";
  id: string;
  ine: string;
  nome: string;
}

export interface ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_unidadeSaude {
  __typename: "UnidadeSaude";
  id: string;
  nome: string | null;
}

export interface ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_profissional {
  __typename: "Profissional";
  nome: string;
}

export interface ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_gradeConfiguracaoAgendaOnline_configuracoesSemana_configuracoes {
  __typename: "ConfiguracaoHorarioAgendaOnline";
  horario: string;
  ativo: boolean;
}

export interface ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_gradeConfiguracaoAgendaOnline_configuracoesSemana {
  __typename: "ConfiguracaoSemanaAgendaOnline";
  diaSemana: DiaSemanaEnum;
  configuracoes: (ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_gradeConfiguracaoAgendaOnline_configuracoesSemana_configuracoes | null)[];
}

export interface ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_gradeConfiguracaoAgendaOnline {
  __typename: "ConfiguracaoAgendaOnlineLotacao";
  horariosConsolidados: (string | null)[];
  configuracoesSemana: ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_gradeConfiguracaoAgendaOnline_configuracoesSemana[] | null;
}

export interface ConfiguracaoAgendaOnlineLotacaoQuery_lotacao {
  __typename: "Lotacao";
  id: string;
  hasConfiguracaoAgendaOnline: boolean | null;
  cbo: ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_cbo;
  equipe: ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_equipe | null;
  unidadeSaude: ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_unidadeSaude;
  profissional: ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_profissional;
  gradeConfiguracaoAgendaOnline: ConfiguracaoAgendaOnlineLotacaoQuery_lotacao_gradeConfiguracaoAgendaOnline | null;
}

export interface ConfiguracaoAgendaOnlineLotacaoQuery {
  lotacao: ConfiguracaoAgendaOnlineLotacaoQuery_lotacao;
}

export interface ConfiguracaoAgendaOnlineLotacaoQueryVariables {
  lotacaoId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SalvarConfiguracaoAgendaOnlineLotacaoMutation
// ====================================================

export interface SalvarConfiguracaoAgendaOnlineLotacaoMutation {
  salvarConfiguracaoAgendaOnlineLotacao: boolean | null;
}

export interface SalvarConfiguracaoAgendaOnlineLotacaoMutationVariables {
  configuracaoAgendaOnlineLotacaoInput?: ConfiguracaoAgendaOnlineLotacaoInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AcessoCreate
// ====================================================

export interface AcessoCreate_profissional {
  __typename: "Profissional";
  id: string;
  nome: string;
}

export interface AcessoCreate {
  profissional: AcessoCreate_profissional;
}

export interface AcessoCreateVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SalvarAcesso
// ====================================================

export interface SalvarAcesso_salvarOutroAcesso {
  __typename: "Lotacao" | "AdministradorGeral" | "AdministradorMunicipal" | "GestorEstadual" | "GestorMunicipal";
  id: string;
}

export interface SalvarAcesso {
  salvarOutroAcesso: SalvarAcesso_salvarOutroAcesso;
}

export interface SalvarAcessoVariables {
  input: OutroAcessoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LotacaoCreate
// ====================================================

export interface LotacaoCreate_profissional {
  __typename: "Profissional";
  id: string;
  nome: string;
}

export interface LotacaoCreate {
  profissional: LotacaoCreate_profissional;
}

export interface LotacaoCreateVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlterarAtivacaoLotacao
// ====================================================

export interface AlterarAtivacaoLotacao {
  alterarAtivacaoLotacao: boolean;
}

export interface AlterarAtivacaoLotacaoVariables {
  lotacaoId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExcluirLotacao
// ====================================================

export interface ExcluirLotacao {
  excluirLotacao: string;
}

export interface ExcluirLotacaoVariables {
  lotacaoId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProfissionaisLotacoesQuery
// ====================================================

export interface ProfissionaisLotacoesQuery_conexao_agendaOnline {
  __typename: "AgendaOnline";
  ativa: boolean;
}

export interface ProfissionaisLotacoesQuery_conexao {
  __typename: "ConfiguracaoConexao";
  agendaOnline: ProfissionaisLotacoesQuery_conexao_agendaOnline | null;
}

export interface ProfissionaisLotacoesQuery {
  conexao: ProfissionaisLotacoesQuery_conexao;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcessoExcluirMutation
// ====================================================

export interface AcessoExcluirMutation {
  excluirAcesso: string;
}

export interface AcessoExcluirMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcessoAlterarAtivacaoMutation
// ====================================================

export interface AcessoAlterarAtivacaoMutation {
  alterarAtivacaoAcesso: boolean;
}

export interface AcessoAlterarAtivacaoMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DefinirSenhaPadrao
// ====================================================

export interface DefinirSenhaPadrao {
  definirSenhaPadrao: boolean;
}

export interface DefinirSenhaPadraoVariables {
  usuarioId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExcluirProfissionalMutation
// ====================================================

export interface ExcluirProfissionalMutation {
  excluirProfissional: string;
}

export interface ExcluirProfissionalMutationVariables {
  profissionalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecuperarSenhaMutation
// ====================================================

export interface RecuperarSenhaMutation {
  recuperaSenha: boolean;
}

export interface RecuperarSenhaMutationVariables {
  input: RecuperarSenhaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SolicitacaoRecuperarSenhaMutation
// ====================================================

export interface SolicitacaoRecuperarSenhaMutation {
  solicitarRecuperarSenha: boolean;
}

export interface SolicitacaoRecuperarSenhaMutationVariables {
  input: SolicitacaoRecuperarSenhaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TiposAtendimentosQuery
// ====================================================

export interface TiposAtendimentosQuery_tiposAtendimento_tipos {
  __typename: "TipoAtendimento";
  descricao: string;
}

export interface TiposAtendimentosQuery_tiposAtendimento {
  __typename: "TipoAtendimentoQueryPayload";
  tipos: (TiposAtendimentosQuery_tiposAtendimento_tipos | null)[] | null;
}

export interface TiposAtendimentosQuery {
  tiposAtendimento: TiposAtendimentosQuery_tiposAtendimento | null;
}

export interface TiposAtendimentosQueryVariables {
  input: TipoAtendimentoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AceitarTermosUso
// ====================================================

export interface AceitarTermosUso_aceitarTermosUso {
  __typename: "Usuario";
  id: string;
  trocarSenha: boolean;
  aceitouTermosUso: boolean;
}

export interface AceitarTermosUso {
  aceitarTermosUso: AceitarTermosUso_aceitarTermosUso | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SessaoData
// ====================================================

export interface SessaoData_profissional_usuario {
  __typename: "Usuario";
  id: string;
  aceitouTermosUso: boolean;
  trocarSenha: boolean;
  visualizouNovidades: boolean;
  mostrarPesquisaSatisfacao: boolean;
}

export interface SessaoData_profissional_acessos_AdministradorGeral {
  __typename: "AdministradorGeral";
  id: string;
  tipo: TipoAcesso;
}

export interface SessaoData_profissional_acessos_AdministradorMunicipal_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface SessaoData_profissional_acessos_AdministradorMunicipal {
  __typename: "AdministradorMunicipal";
  id: string;
  tipo: TipoAcesso;
  municipio: SessaoData_profissional_acessos_AdministradorMunicipal_municipio;
}

export interface SessaoData_profissional_acessos_GestorEstadual_uf {
  __typename: "UF";
  id: string;
  nome: string;
}

export interface SessaoData_profissional_acessos_GestorEstadual {
  __typename: "GestorEstadual";
  id: string;
  tipo: TipoAcesso;
  uf: SessaoData_profissional_acessos_GestorEstadual_uf;
}

export interface SessaoData_profissional_acessos_GestorMunicipal_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface SessaoData_profissional_acessos_GestorMunicipal {
  __typename: "GestorMunicipal";
  id: string;
  tipo: TipoAcesso;
  municipio: SessaoData_profissional_acessos_GestorMunicipal_municipio;
}

export interface SessaoData_profissional_acessos_Lotacao_cbo {
  __typename: "Cbo";
  id: string;
  nome: string;
  cbo2002: string;
}

export interface SessaoData_profissional_acessos_Lotacao_equipe {
  __typename: "Equipe";
  id: string;
  nome: string;
  ine: string;
}

export interface SessaoData_profissional_acessos_Lotacao_unidadeSaude {
  __typename: "UnidadeSaude";
  id: string;
  nome: string | null;
}

export interface SessaoData_profissional_acessos_Lotacao {
  __typename: "Lotacao";
  id: string;
  tipo: TipoAcesso;
  cbo: SessaoData_profissional_acessos_Lotacao_cbo;
  equipe: SessaoData_profissional_acessos_Lotacao_equipe | null;
  unidadeSaude: SessaoData_profissional_acessos_Lotacao_unidadeSaude;
}

export type SessaoData_profissional_acessos = SessaoData_profissional_acessos_AdministradorGeral | SessaoData_profissional_acessos_AdministradorMunicipal | SessaoData_profissional_acessos_GestorEstadual | SessaoData_profissional_acessos_GestorMunicipal | SessaoData_profissional_acessos_Lotacao;

export interface SessaoData_profissional {
  __typename: "Profissional";
  id: string;
  cns: string | null;
  nome: string;
  usuario: SessaoData_profissional_usuario;
  acessos: SessaoData_profissional_acessos[] | null;
}

export interface SessaoData_acesso_Lotacao_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface SessaoData_acesso_Lotacao {
  __typename: "Lotacao" | "AdministradorGeral" | "GestorEstadual" | "GestorMunicipal";
  id: string;
  tipo: TipoAcesso;
  moduloInicial: string | null;
  municipio: SessaoData_acesso_Lotacao_municipio | null;
}

export interface SessaoData_acesso_AdministradorMunicipal_municipio {
  __typename: "Municipio";
  id: string;
  nome: string;
}

export interface SessaoData_acesso_AdministradorMunicipal {
  __typename: "AdministradorMunicipal";
  id: string;
  tipo: TipoAcesso;
  moduloInicial: string | null;
  municipio: SessaoData_acesso_AdministradorMunicipal_municipio | null;
  autorizado: boolean;
  habilitado: boolean;
}

export type SessaoData_acesso = SessaoData_acesso_Lotacao | SessaoData_acesso_AdministradorMunicipal;

export interface SessaoData {
  __typename: "Sessao";
  id: string;
  timeout: number;
  recursos: (string | null)[] | null;
  profissional: SessaoData_profissional;
  acesso: SessaoData_acesso | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DiaSemanaEnum {
  DOMINGO = "DOMINGO",
  QUARTA = "QUARTA",
  QUINTA = "QUINTA",
  SABADO = "SABADO",
  SEGUNDA = "SEGUNDA",
  SEXTA = "SEXTA",
  TERCA = "TERCA",
}

export enum PeriodoEnum {
  PRIMEIRO_PERIODO = "PRIMEIRO_PERIODO",
  QUARTO_PERIODO = "QUARTO_PERIODO",
  SEGUNDO_PERIODO = "SEGUNDO_PERIODO",
  TERCEIRO_PERIODO = "TERCEIRO_PERIODO",
}

export enum StatusProcesso {
  AGUARDANDO = "AGUARDANDO",
  CONCLUIDO = "CONCLUIDO",
  EM_EXECUCAO = "EM_EXECUCAO",
  ERRO = "ERRO",
  INTERROMPIDO = "INTERROMPIDO",
}

export enum StatusProcessoAuditoria {
  ERRO_INESPERADO = "ERRO_INESPERADO",
  ERRO_PDF_NAO_SUPORTADO = "ERRO_PDF_NAO_SUPORTADO",
  ERRO_PROCESSO_EXECUTANDO = "ERRO_PROCESSO_EXECUTANDO",
  ERRO_SEM_DADOS = "ERRO_SEM_DADOS",
  PROCESSADO = "PROCESSADO",
  PROCESSANDO = "PROCESSANDO",
}

export enum TipoAcesso {
  ADMINISTRADOR_GERAL = "ADMINISTRADOR_GERAL",
  ADMINISTRADOR_MUNICIPAL = "ADMINISTRADOR_MUNICIPAL",
  GESTOR_ESTADUAL = "GESTOR_ESTADUAL",
  GESTOR_MUNICIPAL = "GESTOR_MUNICIPAL",
  LOTACAO = "LOTACAO",
}

export enum TipoArquivoEnum {
  CSV = "CSV",
  PDF = "PDF",
}

export interface AgendaFechamentoInput {
  idLotacao: string;
  dataInicial: LocalDate;
  dataFinal: LocalDate;
  motivo: string;
  especifique?: string | null;
}

export interface AgendaFechamentoQueryInput {
  idLotacao: string;
}

export interface AuditoriaInput {
  dataInicio: LocalDate;
  dataFim: LocalDate;
  tiposEvento?: (string | null)[] | null;
  detalheEvento?: string | null;
  usuariosGerador?: (string | null)[] | null;
  tiposRegistroAfetado?: (string | null)[] | null;
  idRegistroAfetado?: string | null;
  componenteGerador?: string | null;
  tipoArquivo: TipoArquivoEnum;
}

export interface CategoriaProfissionalQueryInput {
  query?: string | null;
}

export interface ConfiguracaoAgendaLotacaoInput {
  lotacaoId: string;
  configuracaoHorarioAgenda: ConfiguracaoHorarioAgendaInput;
}

export interface ConfiguracaoAgendaMunicipalInput {
  id: string;
  configuracaoHorarioAgendaInput: ConfiguracaoHorarioAgendaInput;
}

export interface ConfiguracaoAgendaOnlineLotacaoInput {
  lotacaoId: string;
  configuracoesSemana?: ConfiguracaoSemanaAgendaOnlineInput[] | null;
}

export interface ConfiguracaoDiaSemanaInput {
  diaSemana: DiaSemanaEnum;
  periodos?: ConfiguracaoPeriodoInput[] | null;
}

export interface ConfiguracaoHorarioAgendaInput {
  configuracaoDiasSemana?: ConfiguracaoDiaSemanaInput[] | null;
}

export interface ConfiguracaoHorarioAgendaOnlineInput {
  horario: string;
  ativo: boolean;
}

export interface ConfiguracaoPeriodoInput {
  periodo: PeriodoEnum;
  horarioInicial?: string | null;
  horarioFinal?: string | null;
}

export interface ConfiguracaoSemanaAgendaOnlineInput {
  diaSemana: DiaSemanaEnum;
  configuracoes: (ConfiguracaoHorarioAgendaOnlineInput | null)[];
}

export interface DuracaoAtendimentoPadraoMunicipioInput {
  duracaoAtendimentoPadraoMunicipio: number;
}

export interface EquipesConnectionInput {
  query?: string | null;
  mostrarInativas?: boolean | null;
  pageParams?: PageParams | null;
}

export interface GrupoExameInput {
  id?: string | null;
  nome: string;
  procedimentos: (string | null)[];
}

export interface MunicipioResponsavelInput {
  localidade: string;
  profissional: string;
}

export interface MunicipioResponsavelUpdateInput {
  id: string;
  ativo: boolean;
}

export interface OutroAcessoInput {
  id?: string | null;
  profissional: string;
  tipo: TipoAcesso;
  perfis: string[];
  municipio?: string | null;
  uf?: string | null;
}

export interface PageParams {
  page?: number | null;
  size?: number | null;
  sort?: (string | null)[] | null;
}

export interface RecuperarSenhaInput {
  token: string;
  novaSenha: string;
  confirmacaoSenha: string;
}

export interface SelecionarAcessoInput {
  id: string;
}

export interface SelecionarModuloInicialInput {
  moduloInicial: string;
}

export interface SolicitacaoRecuperarSenhaInput {
  usuario?: string | null;
}

export interface TipoAtendimentoInput {
  ids?: (string | null)[] | null;
}

export interface TipoServicoInput {
  id?: string | null;
  nome: string;
}

export interface TipoServicoUpdateInput {
  id?: string | null;
  nome: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
