import { Theme, useStyles } from 'bold-ui'
import { Interpolation } from 'emotion'
import React from 'react'

import { PageContainer } from './PageContainer'

export interface PageContentProps {
  type?: 'transparent' | 'filled'
  style?: Interpolation
  children: React.ReactNode
}

export const PageContent = (props: PageContentProps) => {
  const { style, type, children } = props
  const { classes, css } = useStyles(createStyles, props)

  return (
    <div className={css(classes.container, classes[type], style)}>
      <PageContainer>{children}</PageContainer>
    </div>
  )
}

PageContent.defaultProps = {
  type: 'transparent',
} as Partial<PageContentProps>

const createStyles = (theme: Theme) => ({
  container: {
    padding: '1rem 0',
    paddingBottom: '4rem',
    flexGrow: 1,
  },
  transparent: {
    backgroundColor: theme.pallete.surface.background,
  },
  filled: {
    backgroundColor: theme.pallete.surface.main,
  },
})
