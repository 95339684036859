import { Button, Cell, FormControl, Grid } from 'bold-ui'
import { alert } from 'components/alert'
import { Form, TimeField } from 'components/form'
import { FormPrompt } from 'components/form/FormPrompt'
import { analytics } from 'config/firebase'
import { useAlterarHorarioGeracaoLotesProcessamentoFichasMutation } from 'graphql/hooks.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { isNullOrUndefined } from 'util'
import { createValidator, hora, required } from 'util/validation'

export interface TransmissaoLotesFichasFormProps {
  horario: string
}

interface TransmissaoLotesFichaForm {
  horario: string
}

const validator = createValidator<TransmissaoLotesFichaForm>({
  horario: [required, hora],
})

export const TransmissaoHorarioGeracaoLotesForm = ({ horario }: TransmissaoLotesFichasFormProps) => {
  const [alterarHorarioGeracaoLotesProcessamentoFichas] = useAlterarHorarioGeracaoLotesProcessamentoFichasMutation()

  const submitForm = values =>
    alterarHorarioGeracaoLotesProcessamentoFichas({ variables: values }).then(() => {
      analytics.logEvent('edita_hora_geracao_lote')
      alert('success', 'Horário de geração de lotes e processamento de fichas alterado com sucesso.')
    })

  const formBody = (formProps: FormRenderProps) => (
    <>
      <FormPrompt />
      <Grid gap={1}>
        <Cell size={2}>
          <TimeField name='horario' label='Horário' clearable={false} />
        </Cell>
        <Cell>
          <FormControl label={<span>&nbsp;</span>}>
            <Button kind='primary' size='small' onClick={formProps.handleSubmit}>
              Salvar
            </Button>
          </FormControl>
        </Cell>
      </Grid>
    </>
  )

  return (
    <Form
      onSubmit={submitForm}
      render={formBody}
      initialValues={{
        horario: isNullOrUndefined(horario) ? '00:00' : horario.slice(0, 5),
      }}
      validate={validator}
    />
  )
}
