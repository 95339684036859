import { Theme, useStyles } from 'bold-ui'
import { MaxWidthProperty } from 'csstype'
import { Interpolation } from 'emotion'
import React, { CSSProperties } from 'react'

export interface EllipsisProps {
  title?: string
  style?: Interpolation
  maxWidth?: MaxWidthProperty<any>
  children?: React.ReactNode
}

export function Ellipsis(props: EllipsisProps) {
  const { title, children, style } = props

  const { classes, css } = useStyles(createStyles, props)

  const actualTitle = title || (typeof children === 'string' ? children : undefined)

  return (
    <span title={actualTitle} className={css(classes.root, style)}>
      {children}
    </span>
  )
}

Ellipsis.defaultProps = {
  maxWidth: '100%',
} as EllipsisProps

const createStyles = (theme: Theme, { maxWidth }: EllipsisProps) => ({
  root: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    maxWidth,
  } as CSSProperties,
})
