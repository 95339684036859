import { focusBoxShadow, Icon, Text, Theme, useStyles } from 'bold-ui'
import { NormalizedHighlighter } from 'components/highlight/NormalizedHighlighter'
import { Location } from 'history'
import React, { CSSProperties } from 'react'
import { matchPath } from 'react-router'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { SideMenuList } from './SideMenuList'

export interface SideMenuLinkProps extends NavLinkProps {
  title: string
  icon?: React.ReactNode
  highlight?: string
  children?: React.ReactNode
  details?: boolean
  expanded?: boolean
  onNavigate?(): void
  onExpand?(): void
}

export function SideMenuLink(props: SideMenuLinkProps) {
  const { title, icon, children, details, expanded, highlight, onNavigate, onExpand, ...rest } = props

  const { classes } = useStyles(createStyles, details)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (children) {
      e.preventDefault() // não navega se o link tem filhos (sublinks)
      onExpand()
    } else {
      onNavigate && onNavigate()
    }
  }
  const isActive = (_, location: Location) => !!matchPath(location.pathname, { path: rest.to as string, exact: false })

  return (
    <li>
      <NavLink className={classes.link} onClick={handleClick} isActive={children ? isActive : undefined} {...rest}>
        <span className={classes.icon}>{icon}</span>
        <Text color='inherit' style={classes.title}>
          <NormalizedHighlighter searchWords={highlight} textToHighlight={title} />
        </Text>
        {children && <Icon style={classes.angleIcon} icon={expanded ? 'angleUp' : 'angleDown'} />}
      </NavLink>
      {children && expanded && <SideMenuList>{children}</SideMenuList>}
    </li>
  )
}

export const createStyles = (theme: Theme, details: boolean) => ({
  icon: {
    flexShrink: 0,
  } as CSSProperties,
  title: {
    flex: 1,
    marginLeft: '1.75rem',
    overflow: 'hidden',
    display: !details && 'none',
  } as CSSProperties,
  angleIcon: {
    display: !details && 'none',
  } as CSSProperties,
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.pallete.text.main,
    textDecoration: 'none',
    outline: 'none',
    padding: '0.75rem 1.5rem',
    fontWeight: 'bold',

    '&:hover': {
      background: theme.pallete.gray.c90,
    },

    '&:focus': {
      borderRadius: 4,
      boxShadow: focusBoxShadow(theme, 'primary', 'inset'),
    },

    '&.active': {
      borderRight: `4px solid ${theme.pallete.primary.main}`,
      color: theme.pallete.primary.main,
    },
  } as CSSProperties,
})
