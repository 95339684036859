import { InserirMunicipioResponsavel_inserirMunicipioResponsavel as MutationReturn } from 'api/schema'
import { alert } from 'components/alert'
import { Box } from 'components/Box'
import { analytics } from 'config/firebase'
import { FormApi } from 'final-form'
import React from 'react'

import { useInserirMunicipioResponsavel } from './InserirMunicipioResponsavelMutation'
import MunicipioResponsavelFormModel, { MunicipioResponsavelForm } from './MunicipioResponsavelForm'

export interface MunicipioResponsavelInsertProps {
  profissional: {
    id: ID
    nome: string
  }
  localidade: {
    id: ID
    nome: string
    uf: string
  }
  onInsertCompleted(): Promise<any>
}

export const MunicipioResponsavelInsert = (props: MunicipioResponsavelInsertProps) => {
  const { profissional, onInsertCompleted, localidade } = props

  const [createMunicipioResponsavel] = useInserirMunicipioResponsavel()

  const handleSubmit = (values: MunicipioResponsavelFormModel, form: FormApi) => {
    return createMunicipioResponsavel({
      variables: {
        input: {
          localidade: values.localidade.id,
          profissional: values.profissional.id,
        },
      },
    }).then(res => {
      const data: MutationReturn = res && res.data.inserirMunicipioResponsavel
      analytics.logEvent('add_municipio_responsavel', { Município: data.municipio.nome })
      alert(
        'success',
        <>
          Município {data.municipio.nome} e responsável {data.profissional.nome} salvos com sucesso.
          <br />O acesso de Administrador Municipal foi adicionado ao profissional {data.profissional.nome}
        </>
      )
      onInsertCompleted()
      setTimeout(form.reset)

      return res
    })
  }

  return (
    <Box>
      <MunicipioResponsavelForm
        onSubmit={handleSubmit}
        initialValues={
          {
            profissional: profissional.id ? profissional : null,
            localidade: localidade.id ? localidade : null,
          } as any
        }
      />
    </Box>
  )
}
