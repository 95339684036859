import { masks, onlyNumbers } from 'components/label/masks'
import React from 'react'

import { MaskedField, MaskedFieldProps } from '../final-form/MaskedField'

export interface CnsFieldProps extends MaskedFieldProps {}

export function CnsField(props: CnsFieldProps) {
  return <MaskedField mask={masks.cns} parse={onlyNumbers} {...props} />
}
