import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { BoxGroup } from 'components/BoxGroup'
import { useQuery } from 'graphql/hooks'
import React from 'react'
import Permissions from 'types/Permissions'

import { CONEXAO_QUERY } from './ConexaoQuery'
import { ConfiguracoesInternetBox } from './internet/ConfiguracoesInternetBox'

export const ConexaoView = () => {
  const { data, refetch } = useQuery(CONEXAO_QUERY)
  return (
    <>
      {data && data.conexao && (
        <VFlow vSpacing={2}>
          <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarConexaoComInternet}>
            <BoxGroup>
              <ConfiguracoesInternetBox
                internet={data.conexao.internet}
                cadsus={data.conexao.cadsus}
                horus={data.conexao.horus}
                atestadoDigital={data.conexao.atestadoDigital}
                agendaOnline={data.conexao.agendaOnline}
                refetch={refetch}
              />
            </BoxGroup>
          </CheckPermission>
        </VFlow>
      )}
    </>
  )
}
