import { Cell, FormControl, Grid, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import { Form, FormPrompt, FormRenderProps, SubmitButton } from 'components/form'
import { FormApi } from 'final-form'
import { useSalvarApoioEmapEmadMutation } from 'graphql/hooks.generated'
import React from 'react'
import { createValidator, required } from 'util/validation'

import { EquipesADSelectField, EquipesADSelectFieldModel } from './EquipeADSelectField'

export interface ApoioEmapEmadFormModel {
  idEquipePai: EquipesADSelectFieldModel
  idEquipeFilho: EquipesADSelectFieldModel
}

interface ConfiguracoesADApoioFormProps {
  reload(): Promise<any>
}

export const validate = createValidator<ApoioEmapEmadFormModel>({
  idEquipePai: [required],
  idEquipeFilho: [required],
})

export default function ConfiguracoesADApoioForm(props: ConfiguracoesADApoioFormProps) {
  const { reload } = props
  const [salvarApoioEmapEmad] = useSalvarApoioEmapEmadMutation()
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const handleSubmit = (result: ApoioEmapEmadFormModel, form: FormApi) => {
    return salvarApoioEmapEmad({
      variables: { input: { idEquipePai: result.idEquipePai.id, idEquipeFilho: result.idEquipeFilho.id } },
    })
      .then(ret => {
        alert(
          'success',
          `Relação entre ${result.idEquipePai.nome} - ${result.idEquipePai.ine} e ${result.idEquipeFilho.nome} - ${result.idEquipeFilho.ine} adicionada com sucesso.`
        )
        setTimeout(form.reset)
        reload()
      })
      .catch(handleRejection)
  }

  const renderForm = (formProps: FormRenderProps<ApoioEmapEmadFormModel>) => {
    return (
      <Grid gap={1} style={{ marginTop: '0rem', marginBottom: '0.25rem' }}>
        <Cell flexGrow={1} size={1}>
          <EquipesADSelectField name='idEquipePai' label='EMAP' dsTipoEquipe='EMAP' required />
        </Cell>
        <Cell>
          <FormPrompt />
          <FormControl label={<span>&nbsp;</span>}>
            <Box style={{ width: '40px', border: '0px', lineHeight: '2rem', padding: '0', textAlign: 'center' }}>
              <Text fontWeight='bolder'>apoia</Text>
            </Box>
          </FormControl>
        </Cell>
        <Cell flexGrow={1} size={1}>
          <EquipesADSelectField name='idEquipeFilho' label='EMAD' dsTipoEquipe='EMAD' required />
        </Cell>
        <Cell>
          <FormControl label={<span>&nbsp;</span>}>
            <SubmitButton handleSubmit={formProps.handleSubmit} size='small'>
              Adicionar
            </SubmitButton>
          </FormControl>
        </Cell>
      </Grid>
    )
  }

  return <Form<ApoioEmapEmadFormModel> onSubmit={handleSubmit} validate={validate} render={renderForm} />
}
