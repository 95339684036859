export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  LocalDate: any
  Instant: any
  LocalTime: any
  LocalDateTime: any
}

export type Acesso = {
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  municipio?: Maybe<Municipio>
  actions: BaseAcessoActions
}

export type AcessoActions = BaseAcessoActions & {
  __typename?: 'AcessoActions'
  permissaoEdicao: Action
  permissaoExclusao: Action
  permissaoAtivacaoInativacao: Action
}

export type AcessosConnectionInput = {
  mostrarInativos?: Maybe<Scalars['Boolean']>
}

export type AcompanhamentoCondicaoSaudeQueryInput = {
  pageParams?: Maybe<PageParams>
  ciaps?: Maybe<Array<Maybe<Scalars['String']>>>
  cid10?: Maybe<Array<Maybe<Scalars['String']>>>
  grupoCondicao?: Maybe<Array<Maybe<Scalars['ID']>>>
  somenteProblemasCondicoesAtivas?: Maybe<Scalars['Boolean']>
  unidadeResponsavelId?: Maybe<Scalars['ID']>
  equipeResponsavelId?: Maybe<Scalars['ID']>
  sexo?: Maybe<Array<Maybe<SexoEnum>>>
  identidadeGenero?: Maybe<Array<Maybe<IdentidadeGeneroEnum>>>
  faixaEtariaInicio?: Maybe<Scalars['LocalDate']>
  faixaEtariaFim?: Maybe<Scalars['LocalDate']>
  periodoUltimoAtendimento?: Maybe<Period>
}

export type AcompanhamentoCondicaoSaudeQueryPayload = {
  __typename?: 'AcompanhamentoCondicaoSaudeQueryPayload'
  content: Array<Maybe<Cidadao>>
  pageInfo: PageInfo
}

export type Action = {
  __typename?: 'Action'
  enabled: Scalars['Boolean']
  hint?: Maybe<Scalars['String']>
}

export type AdministradorGeral = Acesso & {
  __typename?: 'AdministradorGeral'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  municipio?: Maybe<Municipio>
  ativo: Scalars['Boolean']
  actions: AcessoActions
}

export type AdministradorMunicipal = Acesso & {
  __typename?: 'AdministradorMunicipal'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  municipio: Municipio
  dataAdicao?: Maybe<Scalars['Instant']>
  dataInativacao?: Maybe<Scalars['Instant']>
  profissional: Profissional
  habilitado: Scalars['Boolean']
  moduloInicial?: Maybe<Scalars['String']>
  ativo: Scalars['Boolean']
  autorizado: Scalars['Boolean']
  actions: AcessoActions
  chaveMunicipio: Scalars['String']
}

export type AgendaFechamentoInput = {
  idLotacao: Scalars['ID']
  dataInicial: Scalars['LocalDate']
  dataFinal: Scalars['LocalDate']
  motivo: Scalars['String']
  especifique?: Maybe<Scalars['String']>
}

export type AgendaFechamentoQueryInput = {
  idLotacao: Scalars['ID']
}

export type AgendaOnline = {
  __typename?: 'AgendaOnline'
  ativa: Scalars['Boolean']
  detalhesAtivacao?: Maybe<DetalhesAtivacao>
  testeConexao?: Maybe<TesteConexao>
}

export type AlterarAtestadoDigitalInput = {
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type AlterarCadsusInput = {
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type AlterarHorusInput = {
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type AlterarLotacaoInput = {
  id?: Maybe<Scalars['ID']>
  ativo: Scalars['Boolean']
  perfis: Array<Maybe<Scalars['ID']>>
  atualizaPerfil?: Maybe<Scalars['Boolean']>
}

export type AlterarSenhaInput = {
  novaSenha: Scalars['String']
  senhaAtual?: Maybe<Scalars['String']>
  confirmacaoSenha: Scalars['String']
}

export type AtivacaoAgendaOnlineInput = {
  chave: Scalars['String']
  contraChave: Scalars['String']
}

export type AuditoriaHistorico = {
  __typename?: 'AuditoriaHistorico'
  id: Scalars['ID']
  dataInicio: Scalars['LocalDate']
  dataFim: Scalars['LocalDate']
  dataSolicitacao: Scalars['Instant']
  status: StatusProcessoAuditoria
  descricaoErro?: Maybe<Scalars['String']>
  csv?: Maybe<Scalars['Boolean']>
}

export type AuditoriaHistoricoPayload = {
  __typename?: 'AuditoriaHistoricoPayload'
  content: Array<Maybe<AuditoriaHistorico>>
  pageInfo: PageInfo
}

export type AuditoriaHistoricoQueryInput = {
  pageParams?: Maybe<PageParams>
}

export type AuditoriaInput = {
  dataInicio: Scalars['LocalDate']
  dataFim: Scalars['LocalDate']
  tiposEvento?: Maybe<Array<Maybe<Scalars['ID']>>>
  detalheEvento?: Maybe<Scalars['String']>
  usuariosGerador?: Maybe<Array<Maybe<Scalars['ID']>>>
  tiposRegistroAfetado?: Maybe<Array<Maybe<Scalars['ID']>>>
  idRegistroAfetado?: Maybe<Scalars['String']>
  componenteGerador?: Maybe<Scalars['String']>
  tipoArquivo: TipoArquivoEnum
}

export type AuditoriaTipoEvento = {
  __typename?: 'AuditoriaTipoEvento'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type AuditoriaTipoRegistroAfetado = {
  __typename?: 'AuditoriaTipoRegistroAfetado'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type AutorizarMunicipioInput = {
  contraChave: Scalars['String']
}

export type Bairro = {
  __typename?: 'Bairro'
  id: Scalars['ID']
  nome: Scalars['String']
  dne: Scalars['String']
  municipio: Municipio
}

export type BairroInput = {
  query?: Maybe<Scalars['String']>
  municipioId?: Maybe<Scalars['ID']>
  ufId?: Maybe<Scalars['ID']>
  pageParams?: Maybe<PageParams>
}

export type BairroQueryPayload = {
  __typename?: 'BairroQueryPayload'
  content: Array<Maybe<Bairro>>
  pageInfo: PageInfo
}

export type BaseAcessoActions = {
  permissaoEdicao: Action
  permissaoExclusao: Action
  permissaoAtivacaoInativacao: Action
}

export type CampoAlteradoCadsus = {
  __typename?: 'CampoAlteradoCadsus'
  campo?: Maybe<Scalars['String']>
  conteudo?: Maybe<Scalars['String']>
}

export type CategoriaProfissional = {
  __typename?: 'CategoriaProfissional'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type CategoriaProfissionalQueryInput = {
  query?: Maybe<Scalars['String']>
}

export type Cbo = {
  __typename?: 'Cbo'
  id: Scalars['ID']
  nome: Scalars['String']
  cbo2002: Scalars['String']
}

export type CboDisponibilizarFormInput = {
  id: Scalars['ID']
  disponivelLotacao: Scalars['Boolean']
}

export type CboDuracaoFormInput = {
  id: Scalars['ID']
  duracao?: Maybe<Scalars['Int']>
}

export type CboMunicipio = {
  __typename?: 'CboMunicipio'
  cbo: Cbo
  disponivelLotacao?: Maybe<Scalars['Boolean']>
  duracao?: Maybe<Scalars['Int']>
  duracaoPadrao?: Maybe<Scalars['Int']>
  actions: CboMunicipioActions
  possuiLotacaoComAgendaOnline?: Maybe<Scalars['Boolean']>
}

export type CboMunicipioActions = {
  __typename?: 'CboMunicipioActions'
  inativar: Action
}

export type CbosMunicipioQueryInput = {
  query?: Maybe<Scalars['String']>
  mostrarIndisponivelLotacao?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type CbosMunicipioQueryPayload = {
  __typename?: 'CbosMunicipioQueryPayload'
  content: Array<Maybe<CboMunicipio>>
  pageInfo: PageInfo
}

export enum CdsOrigemEnum {
  CDS_ORIGEM_TODAS = 'CDS_ORIGEM_TODAS',
  CDS_ORIGEM_OFFLINE = 'CDS_ORIGEM_OFFLINE',
  CDS_ORIGEM_ONLINE = 'CDS_ORIGEM_ONLINE',
  CDS_ORIGEM_PEC = 'CDS_ORIGEM_PEC',
  CDS_ORIGEM_EXTERNO = 'CDS_ORIGEM_EXTERNO',
  CDS_ORIGEM_ANDROID_ACS = 'CDS_ORIGEM_ANDROID_ACS',
  CDS_ORIGEM_ANDROID_AC = 'CDS_ORIGEM_ANDROID_AC',
}

export type Ciap = {
  __typename?: 'Ciap'
  id: Scalars['ID']
  codigo: Scalars['String']
  descricao: Scalars['String']
  sexo?: Maybe<Scalars['String']>
}

export type CiapQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type CiapQueryPayLoad = {
  __typename?: 'CiapQueryPayLoad'
  content: Array<Maybe<Ciap>>
  pageInfo: PageInfo
}

export type Cid10 = {
  __typename?: 'Cid10'
  id: Scalars['ID']
  codigo: Scalars['String']
  nome: Scalars['String']
  sexo?: Maybe<Scalars['String']>
}

export type Cid10QueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type Cid10QueryPayLoad = {
  __typename?: 'Cid10QueryPayLoad'
  content: Array<Maybe<Cid10>>
  pageInfo: PageInfo
}

export type Cidadao = {
  __typename?: 'Cidadao'
  id: Scalars['ID']
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  nisPisPasep?: Maybe<Scalars['String']>
  nome: Scalars['String']
  nomeSocial?: Maybe<Scalars['String']>
  dataNascimento: Scalars['LocalDate']
  dataAtualizado?: Maybe<Scalars['Instant']>
  dataObito?: Maybe<Scalars['LocalDate']>
  numeroDocumentoObito?: Maybe<Scalars['String']>
  sexo: SexoEnum
  desconheceNomeMae?: Maybe<Scalars['Boolean']>
  nomeMae?: Maybe<Scalars['String']>
  desconheceNomePai?: Maybe<Scalars['Boolean']>
  nomePai?: Maybe<Scalars['String']>
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  endereco?: Maybe<Endereco>
  desejaInformarIdentidadeGenero?: Maybe<Scalars['Boolean']>
  identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
  etnia?: Maybe<Etnia>
  racaCor: RacaCor
  area?: Maybe<Scalars['String']>
  microArea?: Maybe<Scalars['String']>
  statusForaArea?: Maybe<Scalars['Boolean']>
  cbo?: Maybe<Cbo>
  escolaridade?: Maybe<Escolaridade>
  ativo?: Maybe<Scalars['Boolean']>
  localidadeNascimento?: Maybe<Municipio>
  dataUltimoAtendimento?: Maybe<Scalars['LocalDate']>
  faleceu?: Maybe<Scalars['Boolean']>
  prontuario?: Maybe<Prontuario>
  unificado?: Maybe<Scalars['Boolean']>
  possuiAgendamento?: Maybe<Scalars['Boolean']>
  tipoSanguineo?: Maybe<TipoSanguineoEnum>
  desejaInformarOrientacaoSexual?: Maybe<Scalars['Boolean']>
  orientacaoSexualDbEnum?: Maybe<OrientacaoSexualEnum>
  estadoCivil?: Maybe<EstadoCivil>
  paisExterior?: Maybe<Pais>
  localidadeExterior?: Maybe<Scalars['String']>
  nacionalidade?: Maybe<Nacionalidade>
  portariaNaturalizacao?: Maybe<Scalars['String']>
  dataNaturalizacao?: Maybe<Scalars['LocalDate']>
  paisNascimento?: Maybe<Pais>
  dataEntradaBrasil?: Maybe<Scalars['LocalDate']>
  stCompartilhaProntuario?: Maybe<Scalars['Boolean']>
  cidadaoVinculacaoEquipe?: Maybe<CidadaoVinculacaoEquipe>
  uuidUltimaFicha?: Maybe<Scalars['String']>
}

export type CidadaoCadsus = {
  __typename?: 'CidadaoCadsus'
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  nome: Scalars['String']
  nomeSocial?: Maybe<Scalars['String']>
  nomeMae?: Maybe<Scalars['String']>
  nomePai?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  dataObito?: Maybe<Scalars['LocalDate']>
  obito?: Maybe<Scalars['String']>
  sexo?: Maybe<SexoEnum>
  email?: Maybe<Scalars['String']>
  municipioNascimento?: Maybe<Municipio>
  paisNascimento?: Maybe<Pais>
  portariaNaturalizacao?: Maybe<Scalars['String']>
  dataEntradaBrasil?: Maybe<Scalars['LocalDate']>
  dataNaturalizacao?: Maybe<Scalars['LocalDate']>
  endereco?: Maybe<Endereco>
  paisResidencia?: Maybe<Pais>
  municipioResidenciaExterior?: Maybe<Scalars['String']>
  numeroPisPasep?: Maybe<Scalars['String']>
}

export type CidadaoDuplicadoDto = {
  __typename?: 'CidadaoDuplicadoDto'
  id?: Maybe<Scalars['ID']>
  ativo?: Maybe<Scalars['Boolean']>
  cpf?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  nome?: Maybe<Scalars['String']>
}

export type CidadaoInput = {
  id?: Maybe<Scalars['ID']>
  cns?: Maybe<Scalars['String']>
  cpf?: Maybe<Scalars['String']>
  nome: Scalars['String']
  nomeSocial?: Maybe<Scalars['String']>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  sexo?: Maybe<SexoEnum>
  racaCor?: Maybe<RacaCorDbEnum>
  etnia?: Maybe<Scalars['String']>
  nomeMae?: Maybe<Scalars['String']>
  desconheceNomeMae?: Maybe<Scalars['Boolean']>
  nomePai?: Maybe<Scalars['String']>
  desconheceNomePai?: Maybe<Scalars['Boolean']>
  nacionalidade?: Maybe<NacionalidadeEnum>
  municipioNascimento?: Maybe<Scalars['String']>
  portariaNaturalizacao?: Maybe<Scalars['String']>
  dataNaturalizacao?: Maybe<Scalars['LocalDate']>
  paisNascimento?: Maybe<Scalars['String']>
  dataEntradaPais?: Maybe<Scalars['LocalDate']>
  cidadaoFaleceu?: Maybe<Scalars['Boolean']>
  dataObito?: Maybe<Scalars['LocalDate']>
  numeroDocumentoObito?: Maybe<Scalars['String']>
  telefoneResidencial?: Maybe<Scalars['String']>
  telefoneCelular?: Maybe<Scalars['String']>
  telefoneContato?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  endereco?: Maybe<EnderecoInput>
  paisResidencia?: Maybe<Scalars['String']>
  municipioResidenciaExterior?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  microArea?: Maybe<Scalars['String']>
  statusForaArea?: Maybe<Scalars['Boolean']>
  nisPisPasep?: Maybe<Scalars['String']>
  estadoCivil?: Maybe<Scalars['String']>
  tipoSanguineo?: Maybe<TipoSanguineoEnum>
  ocupacao?: Maybe<Scalars['String']>
  escolaridade?: Maybe<Scalars['String']>
  desejaInformarOrientacaoSexual?: Maybe<Scalars['Boolean']>
  orientacaoSexualDbEnum?: Maybe<OrientacaoSexualEnum>
  desejaInformarIdentidadeGenero?: Maybe<Scalars['Boolean']>
  identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
  desativarCompartilhamentoProntuario?: Maybe<Scalars['Boolean']>
  vinculacao?: Maybe<CidadaoVinculacaoInput>
  uuidUltimaFicha?: Maybe<Scalars['String']>
}

export type CidadaoListPayload = {
  __typename?: 'CidadaoListPayload'
  content?: Maybe<Array<Maybe<Cidadao>>>
  hasMoreResults?: Maybe<Scalars['Boolean']>
}

export type CidadaoPossuiAgendamentoFuturoQueryInput = {
  id: Scalars['ID']
  dataReferencia?: Maybe<Scalars['LocalDate']>
}

export type CidadaosQueryInput = {
  pageParams?: Maybe<PageParams>
  nomeCnsCpf?: Maybe<Scalars['String']>
  dataNascimento?: Maybe<Scalars['LocalDate']>
  nomeMae?: Maybe<Scalars['String']>
  municipioNascimento?: Maybe<Scalars['String']>
}

export type CidadaoVinculacaoEquipe = {
  __typename?: 'CidadaoVinculacaoEquipe'
  id?: Maybe<Scalars['ID']>
  tpCdsOrigem?: Maybe<CdsOrigemEnum>
  dataAtualizacaoCadastro?: Maybe<Scalars['Instant']>
  equipe?: Maybe<Equipe>
  unidadeSaude?: Maybe<UnidadeSaude>
  utilizarCadastroIndividual?: Maybe<Scalars['Boolean']>
}

export type CidadaoVinculacaoFichaDto = {
  __typename?: 'CidadaoVinculacaoFichaDto'
  perguntaSaidaCadastroEnum?: Maybe<PerguntaSaidaCadastroEnum>
  unidadeSaude?: Maybe<UnidadeSaude>
  equipe?: Maybe<Equipe>
}

export type CidadaoVinculacaoFichaQueryInput = {
  cpfCidadao?: Maybe<Scalars['String']>
  cnsCidadao?: Maybe<Scalars['String']>
  uuidFicha?: Maybe<Scalars['String']>
}

export type CidadaoVinculacaoInput = {
  ine?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  utilizarFichaCadastroIndividual?: Maybe<Scalars['Boolean']>
}

export type CompartilhamentoProntuario = {
  __typename?: 'CompartilhamentoProntuario'
  id: Scalars['ID']
  profissional: Profissional
  ativo: Scalars['Boolean']
  dataUltimaAlteracao: Scalars['Instant']
}

export type CompartilhamentoProntuarioInput = {
  ativo: Scalars['Boolean']
}

export type Complexidade = {
  __typename?: 'Complexidade'
  id: Scalars['ID']
  nome: Scalars['String']
  sigla: Scalars['String']
}

export type ConfigAtendimentoDomInput = {
  idEquipePai: Scalars['ID']
  idEquipeFilho: Scalars['ID']
}

export type ConfiguracaoAgendaLotacao = {
  __typename?: 'ConfiguracaoAgendaLotacao'
  configuracaoHorarioAgenda: ConfiguracaoHorarioAgenda
}

export type ConfiguracaoAgendaLotacaoInput = {
  lotacaoId: Scalars['ID']
  configuracaoHorarioAgenda: ConfiguracaoHorarioAgendaInput
}

export type ConfiguracaoAgendaMunicipal = {
  __typename?: 'ConfiguracaoAgendaMunicipal'
  id: Scalars['ID']
  configuracaoHorarioAgenda: ConfiguracaoHorarioAgenda
}

export type ConfiguracaoAgendaMunicipalInput = {
  id: Scalars['ID']
  configuracaoHorarioAgendaInput: ConfiguracaoHorarioAgendaInput
}

export type ConfiguracaoAgendaOnlineLotacao = {
  __typename?: 'ConfiguracaoAgendaOnlineLotacao'
  horariosConsolidados: Array<Maybe<Scalars['String']>>
  configuracoesSemana?: Maybe<Array<ConfiguracaoSemanaAgendaOnline>>
}

export type ConfiguracaoAgendaOnlineLotacaoInput = {
  lotacaoId: Scalars['ID']
  configuracoesSemana?: Maybe<Array<ConfiguracaoSemanaAgendaOnlineInput>>
}

export type ConfiguracaoAtestadoDigital = {
  __typename?: 'ConfiguracaoAtestadoDigital'
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type ConfiguracaoAvancada = {
  __typename?: 'ConfiguracaoAvancada'
  performance: ConfiguracaoPerformance
}

export type ConfiguracaoCadsus = {
  __typename?: 'ConfiguracaoCadsus'
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type ConfiguracaoConexao = {
  __typename?: 'ConfiguracaoConexao'
  internet: ConfiguracaoInternet
  cadsus: ConfiguracaoCadsus
  horus: ConfiguracaoHorus
  atestadoDigital: ConfiguracaoAtestadoDigital
  agendaOnline?: Maybe<AgendaOnline>
  hasLotacoesConfiguradasOnline?: Maybe<Scalars['Boolean']>
  lotacoesAgendaOnlineComDuracaoPadrao?: Maybe<Scalars['Int']>
}

export type ConfiguracaoDadosServidorFormDto = {
  __typename?: 'ConfiguracaoDadosServidorFormDto'
  nomeInstalacao?: Maybe<Scalars['String']>
  linkInstalacao?: Maybe<Scalars['String']>
}

export type ConfiguracaoDadosServidorInput = {
  nomeInstalacao?: Maybe<Scalars['String']>
  linkInstalacao?: Maybe<Scalars['String']>
}

export type ConfiguracaoDiaSemana = {
  __typename?: 'ConfiguracaoDiaSemana'
  diaSemana: DiaSemanaEnum
  periodos?: Maybe<Array<ConfiguracaoPeriodo>>
}

export type ConfiguracaoDiaSemanaInput = {
  diaSemana: DiaSemanaEnum
  periodos?: Maybe<Array<ConfiguracaoPeriodoInput>>
}

export type ConfiguracaoHorarioAgenda = {
  __typename?: 'ConfiguracaoHorarioAgenda'
  configuracaoDiasSemana?: Maybe<Array<ConfiguracaoDiaSemana>>
}

export type ConfiguracaoHorarioAgendaInput = {
  configuracaoDiasSemana?: Maybe<Array<ConfiguracaoDiaSemanaInput>>
}

export type ConfiguracaoHorarioAgendaOnline = {
  __typename?: 'ConfiguracaoHorarioAgendaOnline'
  horario: Scalars['String']
  ativo: Scalars['Boolean']
}

export type ConfiguracaoHorarioAgendaOnlineInput = {
  horario: Scalars['String']
  ativo: Scalars['Boolean']
}

export type ConfiguracaoHorus = {
  __typename?: 'ConfiguracaoHorus'
  id: Scalars['ID']
  habilitado: Scalars['Boolean']
  intervalo?: Maybe<Scalars['Int']>
  desabilitadoAteData?: Maybe<Scalars['Instant']>
}

export type ConfiguracaoInternet = {
  __typename?: 'ConfiguracaoInternet'
  habilitado: Scalars['Boolean']
  dataUltimoTeste?: Maybe<Scalars['Instant']>
  resultadoUltimoTeste?: Maybe<Scalars['Boolean']>
}

export type ConfiguracaoPerformance = {
  __typename?: 'ConfiguracaoPerformance'
  qtdRequisicoesSimultaneas?: Maybe<Scalars['Int']>
}

export type ConfiguracaoPeriodo = {
  __typename?: 'ConfiguracaoPeriodo'
  periodo: PeriodoEnum
  horarioInicial?: Maybe<Scalars['String']>
  horarioFinal?: Maybe<Scalars['String']>
}

export type ConfiguracaoPeriodoInatividade = {
  __typename?: 'ConfiguracaoPeriodoInatividade'
  periodoInatividade?: Maybe<Scalars['Int']>
}

export type ConfiguracaoPeriodoInput = {
  periodo: PeriodoEnum
  horarioInicial?: Maybe<Scalars['String']>
  horarioFinal?: Maybe<Scalars['String']>
}

export type ConfiguracaoRedefinicaoSenha = {
  __typename?: 'ConfiguracaoRedefinicaoSenha'
  redefinicaoSenha?: Maybe<Scalars['Int']>
}

export type ConfiguracaoSeguranca = {
  __typename?: 'ConfiguracaoSeguranca'
  periodoEmMesesParaRedefinir: ConfiguracaoRedefinicaoSenha
  periodoEmMinutosInatividade: ConfiguracaoPeriodoInatividade
  loginNumeroTentativas: ConfiguracaoTentativasLogin
}

export type ConfiguracaoSemanaAgendaOnline = {
  __typename?: 'ConfiguracaoSemanaAgendaOnline'
  diaSemana: DiaSemanaEnum
  configuracoes: Array<Maybe<ConfiguracaoHorarioAgendaOnline>>
}

export type ConfiguracaoSemanaAgendaOnlineInput = {
  diaSemana: DiaSemanaEnum
  configuracoes: Array<Maybe<ConfiguracaoHorarioAgendaOnlineInput>>
}

export type ConfiguracaoServidoresFormDto = {
  __typename?: 'ConfiguracaoServidoresFormDto'
  internet: ConfiguracaoInternet
  dadosServidor?: Maybe<ConfiguracaoDadosServidorFormDto>
  servidorSMTP?: Maybe<ServidorSmtp>
}

export type ConfiguracaoSmtpInput = {
  email?: Maybe<Scalars['String']>
  senha?: Maybe<Scalars['String']>
  usuario?: Maybe<Scalars['String']>
  endereco?: Maybe<Scalars['String']>
  porta?: Maybe<Scalars['String']>
}

export type ConfiguracaoTentativasLogin = {
  __typename?: 'ConfiguracaoTentativasLogin'
  tentativasLogin?: Maybe<Scalars['Int']>
}

export type ConfiguracoesAtencaoDom = {
  __typename?: 'ConfiguracoesAtencaoDom'
  duracao: Scalars['Int']
  emapsApoiamEmads: ConfiguracoesAtendimentosDomPayload
  emadsAtendemEmads: ConfiguracoesAtendimentosDomPayload
}

export type ConfiguracoesAtencaoDomEmapsApoiamEmadsArgs = {
  input?: Maybe<ConfiguracoesAtendimentosDomInput>
}

export type ConfiguracoesAtencaoDomEmadsAtendemEmadsArgs = {
  input?: Maybe<ConfiguracoesAtendimentosDomInput>
}

export type ConfiguracoesAtendimentosDomiciliares = {
  __typename?: 'ConfiguracoesAtendimentosDomiciliares'
  id: Scalars['ID']
  identificadorNacionalEquipePai: Equipe
  identificadorNacionalEquipeFilho: Equipe
}

export type ConfiguracoesAtendimentosDomInput = {
  pageParams?: Maybe<PageParams>
}

export type ConfiguracoesAtendimentosDomPayload = {
  __typename?: 'ConfiguracoesAtendimentosDomPayload'
  content: Array<Maybe<ConfiguracoesAtendimentosDomiciliares>>
  pageInfo: PageInfo
}

export type ConselhoClasse = {
  __typename?: 'ConselhoClasse'
  id: Scalars['ID']
  sigla: Scalars['String']
  descricao?: Maybe<Scalars['String']>
}

export type ConselhoClassePayload = {
  __typename?: 'ConselhoClassePayload'
  content: Array<Maybe<ConselhoClasse>>
  pageInfo: PageInfo
}

export type ConselhoClasseQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type DetalhesAtivacao = {
  __typename?: 'DetalhesAtivacao'
  profissional?: Maybe<Profissional>
  dataEvento: Scalars['Instant']
}

export enum DiaSemanaEnum {
  SEGUNDA = 'SEGUNDA',
  TERCA = 'TERCA',
  QUARTA = 'QUARTA',
  QUINTA = 'QUINTA',
  SEXTA = 'SEXTA',
  SABADO = 'SABADO',
  DOMINGO = 'DOMINGO',
}

export type DimensaoAgrupadorFiltro = {
  __typename?: 'DimensaoAgrupadorFiltro'
  id: Scalars['ID']
  dimensaoMunicipio?: Maybe<DimensaoMunicipio>
  dimensaoEquipe?: Maybe<DimensaoEquipe>
  dimensaoUnidadeSaude?: Maybe<DimensaoUnidadeSaude>
  dimensaoProfissional?: Maybe<DimensaoProfissional>
  dimensaoCbo?: Maybe<DimensaoCbo>
}

export type DimensaoAgrupadorFiltroQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  dimensaoMunicipioId?: Maybe<Scalars['String']>
  dimensaoUnidadeSaudeId?: Maybe<Scalars['String']>
  dimensaoEquipeId?: Maybe<Scalars['String']>
}

export type DimensaoAgrupadorFiltroQueryPayload = {
  __typename?: 'DimensaoAgrupadorFiltroQueryPayload'
  content: Array<Maybe<DimensaoAgrupadorFiltro>>
  pageInfo: PageInfo
}

export type DimensaoCbo = {
  __typename?: 'DimensaoCbo'
  id: Scalars['ID']
  cbo: Scalars['String']
  nome: Scalars['String']
}

export type DimensaoEquipe = {
  __typename?: 'DimensaoEquipe'
  id: Scalars['ID']
  nome: Scalars['String']
  ine: Scalars['String']
}

export type DimensaoEquipeQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  dimensaoMunicipioId?: Maybe<Scalars['String']>
  dimensaoUnidadeSaudeId?: Maybe<Scalars['String']>
}

export type DimensaoEquipeQueryPayload = {
  __typename?: 'DimensaoEquipeQueryPayload'
  content: Array<Maybe<DimensaoEquipe>>
  pageInfo: PageInfo
}

export type DimensaoMunicipio = {
  __typename?: 'DimensaoMunicipio'
  id: Scalars['ID']
  nome: Scalars['String']
  uf?: Maybe<DimensaoUf>
}

export type DimensaoMunicipiosQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  ufId?: Maybe<Scalars['String']>
  municipioId?: Maybe<Scalars['String']>
}

export type DimensaoMunicipiosQueryPayload = {
  __typename?: 'DimensaoMunicipiosQueryPayload'
  content: Array<Maybe<DimensaoMunicipio>>
  pageInfo: PageInfo
}

export type DimensaoProfissional = {
  __typename?: 'DimensaoProfissional'
  id: Scalars['ID']
  cns?: Maybe<Scalars['String']>
  nome?: Maybe<Scalars['String']>
}

export type DimensaoUf = {
  __typename?: 'DimensaoUf'
  id: Scalars['ID']
  nome: Scalars['String']
  sigla: Scalars['String']
}

export type DimensaoUnidadeSaude = {
  __typename?: 'DimensaoUnidadeSaude'
  id: Scalars['ID']
  nome?: Maybe<Scalars['String']>
  cnes: Scalars['String']
}

export type DimensaoUnidadeSaudeQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  ufId?: Maybe<Scalars['String']>
}

export type DimensaoUnidadeSaudeQueryPayload = {
  __typename?: 'DimensaoUnidadeSaudeQueryPayload'
  content: Array<Maybe<DimensaoUnidadeSaude>>
  pageInfo: PageInfo
}

export type DuracaoAgendamentoAtencaoDomDto = {
  __typename?: 'DuracaoAgendamentoAtencaoDomDto'
  duracaoAgendamento: Scalars['Int']
}

export type DuracaoAgendamentoAtencaoDomInput = {
  duracaoAgendamento: Scalars['Int']
}

export type DuracaoAtendimentoPadraoMunicipio = {
  __typename?: 'DuracaoAtendimentoPadraoMunicipio'
  duracaoAtendimentoPadraoMunicipio: Scalars['Int']
}

export type DuracaoAtendimentoPadraoMunicipioInput = {
  duracaoAtendimentoPadraoMunicipio: Scalars['Int']
}

export type Endereco = {
  __typename?: 'Endereco'
  bairro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  complemento?: Maybe<Scalars['String']>
  logradouro?: Maybe<Scalars['String']>
  municipio?: Maybe<Municipio>
  numero?: Maybe<Scalars['String']>
  pontoReferencia?: Maybe<Scalars['String']>
  semNumero: Scalars['Boolean']
  tipoLogradouro?: Maybe<TipoLogradouro>
  uf?: Maybe<Uf>
}

export type EnderecoInput = {
  bairro?: Maybe<Scalars['String']>
  cep?: Maybe<Scalars['String']>
  complemento?: Maybe<Scalars['String']>
  logradouro?: Maybe<Scalars['String']>
  municipio?: Maybe<Scalars['String']>
  numero?: Maybe<Scalars['String']>
  pontoReferencia?: Maybe<Scalars['String']>
  semNumero?: Maybe<Scalars['Boolean']>
  tipoLogradouro?: Maybe<Scalars['String']>
  uf?: Maybe<Scalars['String']>
}

export type EnderecoQueryPayload = {
  __typename?: 'EnderecoQueryPayload'
  cep: Scalars['String']
  municipio?: Maybe<Municipio>
  uf?: Maybe<Uf>
  bairro?: Maybe<Bairro>
  logradouro?: Maybe<Scalars['String']>
  tipoLogradouro?: Maybe<TipoLogradouro>
}

export type Equipe = {
  __typename?: 'Equipe'
  id: Scalars['ID']
  ine: Scalars['String']
  unidadeSaude: UnidadeSaude
  ativo: Scalars['Boolean']
  tipoEquipe: TipoEquipe
  area: Scalars['String']
  nome: Scalars['String']
}

export type EquipeAdInput = {
  query?: Maybe<Scalars['String']>
  descricaoTipoEquipe: Scalars['String']
  pageParams?: Maybe<PageParams>
}

export type EquipeAdPayload = {
  __typename?: 'EquipeADPayload'
  content: Array<Maybe<Equipe>>
  pageInfo: PageInfo
}

export type EquipePayload = {
  __typename?: 'EquipePayload'
  content: Array<Maybe<Equipe>>
  pageInfo: PageInfo
}

export type EquipesConnectionInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativas?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type Escolaridade = {
  __typename?: 'Escolaridade'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type EstadoCivil = {
  __typename?: 'EstadoCivil'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type Etnia = {
  __typename?: 'Etnia'
  id: Scalars['ID']
  nome: Scalars['String']
  codigoCadsus: Scalars['String']
}

export type EtniaPayload = {
  __typename?: 'EtniaPayload'
  content: Array<Maybe<Etnia>>
  pageInfo: PageInfo
}

export type EtniaQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ExcluirPerfilPayload = {
  __typename?: 'ExcluirPerfilPayload'
  id: Scalars['ID']
}

export type FechamentoAgenda = {
  __typename?: 'FechamentoAgenda'
  id?: Maybe<Scalars['ID']>
  dataInicial?: Maybe<Scalars['LocalDate']>
  dataFinal?: Maybe<Scalars['LocalDate']>
  motivo?: Maybe<Scalars['String']>
  especifique?: Maybe<Scalars['String']>
}

export type GestorEstadual = Acesso & {
  __typename?: 'GestorEstadual'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  uf: Uf
  municipio?: Maybe<Municipio>
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  actions: AcessoActions
}

export type GestorMunicipal = Acesso & {
  __typename?: 'GestorMunicipal'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  municipio: Municipio
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  actions: AcessoActions
}

export enum GrauSatisfacaoUsuario {
  MUITO_SATISFEITO = 'MUITO_SATISFEITO',
  SATISFEITO = 'SATISFEITO',
  INDIFERENTE = 'INDIFERENTE',
  INSATISFEITO = 'INSATISFEITO',
  MUITO_INSATISFEITO = 'MUITO_INSATISFEITO',
}

export type GrupoCondicaoDto = {
  __typename?: 'GrupoCondicaoDto'
  id: Scalars['ID']
  grupoCondicao: GrupoCondicaoEnum
  ciaps?: Maybe<Array<Maybe<Ciap>>>
  cids?: Maybe<Array<Maybe<Cid10>>>
}

export enum GrupoCondicaoEnum {
  ASMA = 'ASMA',
  AVC = 'AVC',
  DENGUE = 'DENGUE',
  DESNUTRICAO = 'DESNUTRICAO',
  DIABETES = 'DIABETES',
  DOENCA_CARDIACA = 'DOENCA_CARDIACA',
  DPOC = 'DPOC',
  DST = 'DST',
  HANSENIASE = 'HANSENIASE',
  HIPERTENSAO_ARTERIAL = 'HIPERTENSAO_ARTERIAL',
  OBESIDADE = 'OBESIDADE',
  GRAVIDEZ = 'GRAVIDEZ',
  INFARTO = 'INFARTO',
  REABILITACAO = 'REABILITACAO',
  RINS = 'RINS',
  SAUDE_MENTAL = 'SAUDE_MENTAL',
  SAUDE_SEXUAL_E_REPRODUTIVA = 'SAUDE_SEXUAL_E_REPRODUTIVA',
  TABAGISMO = 'TABAGISMO',
  TUBERCULOSE = 'TUBERCULOSE',
  USUARIO_DE_ALCOOL = 'USUARIO_DE_ALCOOL',
  USUARIO_DE_OUTRAS_DROGAS = 'USUARIO_DE_OUTRAS_DROGAS',
}

export type GrupoCondicaoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type GrupoCondicaoQueryPayLoad = {
  __typename?: 'GrupoCondicaoQueryPayLoad'
  content: Array<Maybe<GrupoCondicaoDto>>
  pageInfo: PageInfo
}

export type GrupoExame = {
  __typename?: 'GrupoExame'
  id: Scalars['ID']
  nome: Scalars['String']
  procedimentos?: Maybe<Array<Maybe<Procedimento>>>
  quantidadeProcedimentos?: Maybe<Scalars['Int']>
}

export type GrupoExameInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
  procedimentos: Array<Maybe<Scalars['ID']>>
}

export type GrupoExameQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type GruposExameQueryPayload = {
  __typename?: 'GruposExameQueryPayload'
  content: Array<Maybe<GrupoExame>>
  pageInfo: PageInfo
}

export type HistoricoExportacaoFiltro = {
  pageParams: PageParams
}

export type HorusFormDto = {
  __typename?: 'HorusFormDto'
  unidadeSaude?: Maybe<UnidadeSaude>
  unidadePadrao?: Maybe<Scalars['Boolean']>
}

export enum IdentidadeGeneroEnum {
  HOMEM_TRANS = 'HOMEM_TRANS',
  MULHER_TRANS = 'MULHER_TRANS',
  TRAVESTI = 'TRAVESTI',
  OUTRO_IDENTIDADE_GENERO = 'OUTRO_IDENTIDADE_GENERO',
}

export type ImportacaoArquivo = {
  __typename?: 'ImportacaoArquivo'
  dataEvento?: Maybe<Scalars['Instant']>
  status: StatusImportacaoArquivo
  message?: Maybe<Scalars['String']>
}

export type ImportacaoCnes = {
  __typename?: 'ImportacaoCnes'
  id: Scalars['ID']
  dataImportacao: Scalars['Instant']
  equipesNovas?: Maybe<Scalars['Int']>
  equipesAtualizadas?: Maybe<Scalars['Int']>
  lotacoesNovas?: Maybe<Scalars['Int']>
  lotacoesAtualizadas?: Maybe<Scalars['Int']>
  processo: Processo
  profissional: Profissional
  profissionaisNovos?: Maybe<Scalars['Int']>
  profissionaisAtualizados?: Maybe<Scalars['Int']>
  unidadesSaudeNovas?: Maybe<Scalars['Int']>
  unidadesSaudeAtualizadas?: Maybe<Scalars['Int']>
  detalhes?: Maybe<Scalars['String']>
  municipio: Municipio
}

export type ImportacoesCnesQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  municipioId?: Maybe<Scalars['Int']>
}

export type ImportacoesCnesQueryPayload = {
  __typename?: 'ImportacoesCnesQueryPayload'
  content: Array<Maybe<ImportacaoCnes>>
  pageInfo: PageInfo
}

export type Imunobiologico = {
  __typename?: 'Imunobiologico'
  id: Scalars['ID']
  sigla: Scalars['String']
  nome: Scalars['String']
}

export type ImunobiologicoFabricante = {
  __typename?: 'ImunobiologicoFabricante'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type ImunobiologicoFabricanteQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ImunobiologicoFabricanteQueryPayload = {
  __typename?: 'ImunobiologicoFabricanteQueryPayload'
  content: Array<Maybe<ImunobiologicoFabricante>>
  pageInfo: PageInfo
}

export type ImunobiologicoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ImunobiologicoQueryPayload = {
  __typename?: 'ImunobiologicoQueryPayload'
  content: Array<Maybe<Imunobiologico>>
  pageInfo: PageInfo
}

export type Info = {
  __typename?: 'Info'
  ativado: Scalars['Boolean']
  commit: Scalars['String']
  competenciaSigtap: Scalars['String']
  databaseVendor: Scalars['String']
  databaseSchemaVersion: Scalars['String']
  oldPecConnected: Scalars['Boolean']
  recursosDisponiveis: Scalars['String']
  tipoInstalacao: TipoInstalacao
  versao: Scalars['String']
  internetHabilitada: Scalars['Boolean']
  smtpConfigurado: Scalars['Boolean']
  linkInstalacaoConfigurado: Scalars['Boolean']
  treinamento: Scalars['Boolean']
}

export type InstalacaoInput = {
  dadosInstalacao?: Maybe<ConfiguracaoDadosServidorInput>
  tipoInstalacao?: Maybe<TipoInstalacao>
  senha?: Maybe<Scalars['String']>
  confirmacaoSenha?: Maybe<Scalars['String']>
  profissional?: Maybe<ProfissionalInput>
}

export type LoginInput = {
  username: Scalars['String']
  password: Scalars['String']
  force?: Maybe<Scalars['Boolean']>
}

export type Logradouro = {
  __typename?: 'Logradouro'
  id: Scalars['ID']
  nome: Scalars['String']
  nomePreposicao: Scalars['String']
  nomeExibicao: Scalars['String']
  bairro: Bairro
  tipoLogradouro: TipoLogradouro
  cep: Scalars['String']
  dne: Scalars['String']
}

export type LogradouroInput = {
  municipioId?: Maybe<Scalars['ID']>
  bairroId?: Maybe<Scalars['ID']>
  tipoLogradouroId?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type LogradouroQueryPayload = {
  __typename?: 'LogradouroQueryPayload'
  content: Array<Maybe<Logradouro>>
  pageInfo: PageInfo
}

export type Lotacao = Acesso & {
  __typename?: 'Lotacao'
  id: Scalars['ID']
  perfis: Array<Maybe<Perfil>>
  tipo: TipoAcesso
  ativo: Scalars['Boolean']
  cbo: Cbo
  equipe?: Maybe<Equipe>
  unidadeSaude: UnidadeSaude
  importada: Scalars['Boolean']
  profissional: Profissional
  moduloInicial?: Maybe<Scalars['String']>
  municipio: Municipio
  configuracaoAgenda?: Maybe<ConfiguracaoAgendaLotacao>
  hasConfiguracaoAgenda?: Maybe<Scalars['Boolean']>
  gradeConfiguracaoAgendaOnline?: Maybe<ConfiguracaoAgendaOnlineLotacao>
  hasConfiguracaoAgendaOnline?: Maybe<Scalars['Boolean']>
  hasAgendamentoFuturo?: Maybe<Scalars['Boolean']>
  atualizaPerfil?: Maybe<Scalars['Boolean']>
  actions: LotacaoActions
}

export type LotacaoActions = BaseAcessoActions & {
  __typename?: 'LotacaoActions'
  permissaoEdicao: Action
  permissaoExclusao: Action
  permissaoAtivacaoInativacao: Action
  permissaoConfigurarAgenda: Action
  permissaoConfigurarAgendaOnline: Action
  permissaoFecharAgenda: Action
}

export type LotacaoInput = {
  ativo: Scalars['Boolean']
  cbo: Scalars['ID']
  equipe?: Maybe<Scalars['ID']>
  profissional?: Maybe<Scalars['ID']>
  unidadeSaude: Scalars['ID']
  perfis: Array<Maybe<Scalars['ID']>>
  atualizaPerfil?: Maybe<Scalars['Boolean']>
}

export type LotacaoQueryPayload = {
  __typename?: 'LotacaoQueryPayload'
  content: Array<Maybe<Lotacao>>
  pageInfo: PageInfo
}

export type LotacoesInput = {
  cbo?: Maybe<Scalars['String']>
  equipe?: Maybe<Scalars['String']>
  unidadeSaude?: Maybe<Scalars['String']>
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type LoteEnvio = {
  __typename?: 'LoteEnvio'
  id: Scalars['ID']
  statusEnvio: StatusEnvio
  dataCriacao: Scalars['Instant']
  quantidadeFichas: Scalars['Int']
  nodos?: Maybe<Array<Maybe<NodoLoteEnvio>>>
  historicoExportacao: LoteHistoricoExportacaoPayload
}

export type LoteEnvioHistoricoExportacaoArgs = {
  filtro: HistoricoExportacaoFiltro
}

export type LoteGeradoMessage = {
  __typename?: 'LoteGeradoMessage'
  message?: Maybe<Scalars['String']>
}

export type LoteHistoricoExportacao = {
  __typename?: 'LoteHistoricoExportacao'
  id: Scalars['ID']
  nomeProfissional: Scalars['String']
  dataExportacao: Scalars['Instant']
}

export type LoteHistoricoExportacaoPayload = {
  __typename?: 'LoteHistoricoExportacaoPayload'
  content: Array<Maybe<LoteHistoricoExportacao>>
  pageInfo: PageInfo
}

export type LoteImunobiologico = {
  __typename?: 'LoteImunobiologico'
  id: Scalars['ID']
  lote?: Maybe<Scalars['String']>
  fabricante?: Maybe<ImunobiologicoFabricante>
  imunobiologico?: Maybe<Imunobiologico>
  validade?: Maybe<Scalars['LocalDate']>
  ativo?: Maybe<Scalars['Boolean']>
}

export type LoteImunobiologicoInput = {
  id?: Maybe<Scalars['ID']>
  lote: Scalars['String']
  idFabricante?: Maybe<Scalars['ID']>
  nomeFabricante?: Maybe<Scalars['String']>
  idImunobiologico: Scalars['ID']
  validade: Scalars['LocalDate']
}

export type LoteImunobiologicoQueryInput = {
  pageParams?: Maybe<PageParams>
  filtroImunoFabricante?: Maybe<Scalars['String']>
  mostrarLotesInativos?: Maybe<Scalars['Boolean']>
}

export type LotesEnvioFiltro = {
  idLote?: Maybe<Scalars['ID']>
  dataCriacaoInicio?: Maybe<Scalars['LocalDate']>
  dataCriacaoFim?: Maybe<Scalars['LocalDate']>
  statusEnvio?: Maybe<Array<Maybe<StatusEnvio>>>
  pageParams?: Maybe<PageParams>
}

export type LotesEnvioPayload = {
  __typename?: 'LotesEnvioPayload'
  content: Array<Maybe<LoteEnvio>>
  pageInfo: PageInfo
}

export type LotesImunobiologicoQueryPayload = {
  __typename?: 'LotesImunobiologicoQueryPayload'
  content: Array<Maybe<LoteImunobiologico>>
  pageInfo: PageInfo
}

export type Municipio = {
  __typename?: 'Municipio'
  id: Scalars['ID']
  bairros: Array<Maybe<Bairro>>
  ibge: Scalars['String']
  nome: Scalars['String']
  uf: Uf
}

export type MunicipioResponsavelFiltroInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativos: Scalars['Boolean']
  pageParams?: Maybe<PageParams>
}

export type MunicipioResponsavelInput = {
  localidade: Scalars['ID']
  profissional: Scalars['ID']
}

export type MunicipioResponsavelQueryPayload = {
  __typename?: 'MunicipioResponsavelQueryPayload'
  content: Array<Maybe<AdministradorMunicipal>>
  pageInfo: PageInfo
}

export type MunicipioResponsavelUpdateInput = {
  id: Scalars['ID']
  ativo: Scalars['Boolean']
}

export type MunicipiosQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
  ufId?: Maybe<Scalars['ID']>
  ativo?: Maybe<Scalars['Boolean']>
}

export type MunicipiosQueryPayload = {
  __typename?: 'MunicipiosQueryPayload'
  content: Array<Maybe<Municipio>>
  pageInfo: PageInfo
}

export type Mutation = {
  __typename?: 'Mutation'
  aceitarTermosUso?: Maybe<Usuario>
  adicionarUnidadeConsultaHorus: UnidadeSaude
  alterarAtestadoDigital?: Maybe<ConfiguracaoAtestadoDigital>
  alterarAtivacaoAcesso: Scalars['Boolean']
  alterarAtivacaoLotacao: Scalars['Boolean']
  alterarCadsus?: Maybe<ConfiguracaoCadsus>
  alterarDadosServidor?: Maybe<ConfiguracaoDadosServidorFormDto>
  alterarDuracaoCbo?: Maybe<CboMunicipio>
  alterarDuracaoPadraoAtendimento: DuracaoAtendimentoPadraoMunicipio
  alterarHorarioGeracaoLotesProcessamentoFichas: Scalars['LocalTime']
  alterarHorus?: Maybe<ConfiguracaoHorus>
  alterarLotacao: Lotacao
  alterarNumeroTentativasLogin?: Maybe<ConfiguracaoTentativasLogin>
  alterarPeriodoInatividade?: Maybe<ConfiguracaoPeriodoInatividade>
  alterarPeriodoRedefinicaoSenha?: Maybe<ConfiguracaoRedefinicaoSenha>
  alterarQtdRequisicoes?: Maybe<ConfiguracaoPerformance>
  alterarSenha?: Maybe<Usuario>
  alterarServidorSMTP?: Maybe<ServidorSmtp>
  alterarTipoServico?: Maybe<TipoServico>
  alterarUnidadePadraoHorus: UnidadeSaude
  alternarInternet?: Maybe<ConfiguracaoInternet>
  alternarTransmissaoLink: Scalars['ID']
  ativarAgendaOnline?: Maybe<AgendaOnline>
  ativarPerfil: Perfil
  ativarTipoServico: RlUnidadeSaudeTipoServicoIds
  atualizarMunicipioResponsavel: AdministradorMunicipal
  atualizarPerfil: Perfil
  autorizarMunicipio: AdministradorMunicipal
  concluirConfiguracaoMunicipio: AdministradorMunicipal
  createCidadaoCadsus?: Maybe<SincronizacaoCadsusResultDto>
  criarLotacao: Lotacao
  criarPerfil: Perfil
  definirModuloInicial: Acesso
  definirSenhaPadrao: Scalars['Boolean']
  denifinirNovoAdministrador?: Maybe<Scalars['Boolean']>
  desabilitarConexaoSMTP: Scalars['Boolean']
  desativarAgendaOnline?: Maybe<AgendaOnline>
  desativarConfiguracaoAgendaOnlineLotacao: Scalars['Boolean']
  desativarTipoServico: RlUnidadeSaudeTipoServicoIds
  disponibilizarCbo?: Maybe<CboMunicipio>
  excluirAcesso: Scalars['ID']
  excluirCidadao: Scalars['ID']
  excluirConfigAtendimentoDom: Scalars['ID']
  excluirConfiguracaoAgendaLotacao: Scalars['Boolean']
  excluirFechamento?: Maybe<Scalars['ID']>
  excluirGrupoExame: Scalars['ID']
  excluirLotacao: Scalars['ID']
  excluirLoteImunobiologico?: Maybe<Scalars['ID']>
  excluirMunicipioResponsavel: Scalars['ID']
  excluirPerfil: ExcluirPerfilPayload
  excluirProfissional: Scalars['ID']
  excluirTipoServico?: Maybe<Scalars['ID']>
  excluirTransmissaoLink: Scalars['ID']
  forcarRedefinirSenhas?: Maybe<ConfiguracaoRedefinicaoSenha>
  gerarChaveAtivacaoAgendaOnline: Scalars['String']
  gerarLote: Scalars['Boolean']
  inativarPerfil: Perfil
  inserirMunicipioResponsavel: AdministradorMunicipal
  inserirTransmissaoLink: Scalars['ID']
  instalar: Scalars['Boolean']
  login: Scalars['Boolean']
  logout?: Maybe<Sessao>
  processAuditoria?: Maybe<Scalars['Boolean']>
  reativarCidadao: Cidadao
  recuperaSenha: Scalars['Boolean']
  removerUnidadeConsultaHorus: UnidadeSaude
  resetSenhaAdministrador: Scalars['Boolean']
  responderPesquisaSatisfacao: Usuario
  salvarCidadao: Cidadao
  salvarConfigAtendimentoDomTipoApoio: ConfiguracoesAtendimentosDomiciliares
  salvarConfigAtendimentoDomTipoPodeAtender: ConfiguracoesAtendimentosDomiciliares
  salvarConfiguracaoAgendaLotacao?: Maybe<Scalars['Boolean']>
  salvarConfiguracaoAgendaOnlineLotacao?: Maybe<Scalars['Boolean']>
  salvarConfiguracaoHorariosMunicipio?: Maybe<ConfiguracaoAgendaMunicipal>
  salvarDuracaoAgendamentoAtencaoDom: DuracaoAgendamentoAtencaoDomDto
  salvarFechamento?: Maybe<FechamentoAgenda>
  salvarGrupoExame: GrupoExame
  salvarLoteImunobiologico?: Maybe<LoteImunobiologico>
  salvarOutroAcesso: Acesso
  salvarProfissional: Profissional
  salvarProfissionalContraChave: Profissional
  salvarTipoServico?: Maybe<TipoServico>
  saveCompartilhamentoProntuario?: Maybe<CompartilhamentoProntuario>
  selecionarAcesso?: Maybe<Sessao>
  setAtivadoLoteImunobiologico?: Maybe<Scalars['ID']>
  solicitarRecuperarSenha: Scalars['Boolean']
  testarConexaoAgendaOnline: TesteConexao
  testarConexaoInternet?: Maybe<ConfiguracaoInternet>
  testarConexaoLinkTransmissao: TransmissaoLink
  unificaCadastros: Scalars['Boolean']
  updateTransmissaoLink: Scalars['ID']
  validaContraChave: Scalars['Boolean']
  visualizarNovidades: Scalars['Boolean']
}

export type MutationAdicionarUnidadeConsultaHorusArgs = {
  input: UnidadeConsultaHorusIds
}

export type MutationAlterarAtestadoDigitalArgs = {
  alterarAtestadoDigitalInput: AlterarAtestadoDigitalInput
}

export type MutationAlterarAtivacaoAcessoArgs = {
  id: Scalars['ID']
}

export type MutationAlterarAtivacaoLotacaoArgs = {
  lotacaoId: Scalars['ID']
}

export type MutationAlterarCadsusArgs = {
  alterarCadsusInput: AlterarCadsusInput
}

export type MutationAlterarDadosServidorArgs = {
  input: ConfiguracaoDadosServidorInput
}

export type MutationAlterarDuracaoCboArgs = {
  input?: Maybe<CboDuracaoFormInput>
}

export type MutationAlterarDuracaoPadraoAtendimentoArgs = {
  duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput
}

export type MutationAlterarHorarioGeracaoLotesProcessamentoFichasArgs = {
  horario: Scalars['LocalTime']
}

export type MutationAlterarHorusArgs = {
  alterarHorusInput: AlterarHorusInput
}

export type MutationAlterarLotacaoArgs = {
  input: AlterarLotacaoInput
}

export type MutationAlterarNumeroTentativasLoginArgs = {
  tentativas?: Maybe<Scalars['Int']>
}

export type MutationAlterarPeriodoInatividadeArgs = {
  periodo?: Maybe<Scalars['Int']>
}

export type MutationAlterarPeriodoRedefinicaoSenhaArgs = {
  periodo?: Maybe<Scalars['Int']>
}

export type MutationAlterarQtdRequisicoesArgs = {
  qtdRequisicoesSimultaneas?: Maybe<Scalars['Int']>
}

export type MutationAlterarSenhaArgs = {
  input: AlterarSenhaInput
}

export type MutationAlterarServidorSmtpArgs = {
  input: ConfiguracaoSmtpInput
}

export type MutationAlterarTipoServicoArgs = {
  input: TipoServicoUpdateInput
}

export type MutationAlterarUnidadePadraoHorusArgs = {
  input: UnidadeConsultaHorusIds
}

export type MutationAlternarInternetArgs = {
  habilitado?: Maybe<Scalars['Boolean']>
}

export type MutationAlternarTransmissaoLinkArgs = {
  id: Scalars['ID']
}

export type MutationAtivarAgendaOnlineArgs = {
  ativacaoAgendaOnlineInput: AtivacaoAgendaOnlineInput
}

export type MutationAtivarPerfilArgs = {
  id: Scalars['ID']
}

export type MutationAtivarTipoServicoArgs = {
  unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput
}

export type MutationAtualizarMunicipioResponsavelArgs = {
  input: MunicipioResponsavelUpdateInput
}

export type MutationAtualizarPerfilArgs = {
  input: PerfilInput
}

export type MutationAutorizarMunicipioArgs = {
  input?: Maybe<AutorizarMunicipioInput>
}

export type MutationCreateCidadaoCadsusArgs = {
  cidadaoId: Scalars['ID']
}

export type MutationCriarLotacaoArgs = {
  input: LotacaoInput
}

export type MutationCriarPerfilArgs = {
  input: PerfilInput
}

export type MutationDefinirModuloInicialArgs = {
  input: SelecionarModuloInicialInput
}

export type MutationDefinirSenhaPadraoArgs = {
  usuarioId: Scalars['ID']
}

export type MutationDenifinirNovoAdministradorArgs = {
  input: ValidateNovoAdminInput
}

export type MutationDesativarConfiguracaoAgendaOnlineLotacaoArgs = {
  id: Scalars['ID']
}

export type MutationDesativarTipoServicoArgs = {
  unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput
}

export type MutationDisponibilizarCboArgs = {
  input?: Maybe<CboDisponibilizarFormInput>
}

export type MutationExcluirAcessoArgs = {
  id: Scalars['ID']
}

export type MutationExcluirCidadaoArgs = {
  cidadaoId: Scalars['ID']
}

export type MutationExcluirConfigAtendimentoDomArgs = {
  idConfig: Scalars['ID']
}

export type MutationExcluirConfiguracaoAgendaLotacaoArgs = {
  id: Scalars['ID']
}

export type MutationExcluirFechamentoArgs = {
  id: Scalars['ID']
}

export type MutationExcluirGrupoExameArgs = {
  id: Scalars['ID']
}

export type MutationExcluirLotacaoArgs = {
  lotacaoId: Scalars['ID']
}

export type MutationExcluirLoteImunobiologicoArgs = {
  idLote: Scalars['ID']
}

export type MutationExcluirMunicipioResponsavelArgs = {
  id: Scalars['ID']
}

export type MutationExcluirPerfilArgs = {
  id: Scalars['ID']
}

export type MutationExcluirProfissionalArgs = {
  profissionalId: Scalars['ID']
}

export type MutationExcluirTipoServicoArgs = {
  id: Scalars['ID']
}

export type MutationExcluirTransmissaoLinkArgs = {
  id: Scalars['ID']
}

export type MutationInativarPerfilArgs = {
  id: Scalars['ID']
}

export type MutationInserirMunicipioResponsavelArgs = {
  input: MunicipioResponsavelInput
}

export type MutationInserirTransmissaoLinkArgs = {
  form: TransmissaoLinkInsertDto
}

export type MutationInstalarArgs = {
  input: InstalacaoInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationProcessAuditoriaArgs = {
  input: AuditoriaInput
}

export type MutationReativarCidadaoArgs = {
  cidadaoId: Scalars['ID']
}

export type MutationRecuperaSenhaArgs = {
  input: RecuperarSenhaInput
}

export type MutationRemoverUnidadeConsultaHorusArgs = {
  input: UnidadeConsultaHorusIds
}

export type MutationResetSenhaAdministradorArgs = {
  input: ResetSenhaAdminInput
}

export type MutationResponderPesquisaSatisfacaoArgs = {
  input: RespostaPesquisa
}

export type MutationSalvarCidadaoArgs = {
  input: CidadaoInput
}

export type MutationSalvarConfigAtendimentoDomTipoApoioArgs = {
  input: ConfigAtendimentoDomInput
}

export type MutationSalvarConfigAtendimentoDomTipoPodeAtenderArgs = {
  input: ConfigAtendimentoDomInput
}

export type MutationSalvarConfiguracaoAgendaLotacaoArgs = {
  configuracaoAgendaLotacaoInput?: Maybe<ConfiguracaoAgendaLotacaoInput>
}

export type MutationSalvarConfiguracaoAgendaOnlineLotacaoArgs = {
  configuracaoAgendaOnlineLotacaoInput?: Maybe<ConfiguracaoAgendaOnlineLotacaoInput>
}

export type MutationSalvarConfiguracaoHorariosMunicipioArgs = {
  configuracaoAgendaMunicipalInput?: Maybe<ConfiguracaoAgendaMunicipalInput>
}

export type MutationSalvarDuracaoAgendamentoAtencaoDomArgs = {
  input: DuracaoAgendamentoAtencaoDomInput
}

export type MutationSalvarFechamentoArgs = {
  input: AgendaFechamentoInput
}

export type MutationSalvarGrupoExameArgs = {
  input: GrupoExameInput
}

export type MutationSalvarLoteImunobiologicoArgs = {
  input: LoteImunobiologicoInput
}

export type MutationSalvarOutroAcessoArgs = {
  input: OutroAcessoInput
}

export type MutationSalvarProfissionalArgs = {
  input: ProfissionalInput
}

export type MutationSalvarProfissionalContraChaveArgs = {
  input: ProfissionalContraChaveInput
}

export type MutationSalvarTipoServicoArgs = {
  input: TipoServicoInput
}

export type MutationSaveCompartilhamentoProntuarioArgs = {
  compartilhamentoProntuarioInput: CompartilhamentoProntuarioInput
}

export type MutationSelecionarAcessoArgs = {
  input: SelecionarAcessoInput
}

export type MutationSetAtivadoLoteImunobiologicoArgs = {
  idLote: Scalars['ID']
  ativado?: Maybe<Scalars['Boolean']>
}

export type MutationSolicitarRecuperarSenhaArgs = {
  input: SolicitacaoRecuperarSenhaInput
}

export type MutationTestarConexaoLinkTransmissaoArgs = {
  id: Scalars['ID']
}

export type MutationUnificaCadastrosArgs = {
  input: UnificacaoCadastroInput
}

export type MutationUpdateTransmissaoLinkArgs = {
  form: TransmissaoLinkUpdateDto
}

export type MutationValidaContraChaveArgs = {
  input?: Maybe<AutorizarMunicipioInput>
}

export type Nacionalidade = {
  __typename?: 'Nacionalidade'
  id: Scalars['ID']
  nome: Scalars['String']
  nacionalidadeDbEnum: NacionalidadeEnum
}

export enum NacionalidadeEnum {
  BRASILEIRA = 'BRASILEIRA',
  NATURALIZADA = 'NATURALIZADA',
  ESTRANGEIRA = 'ESTRANGEIRA',
}

export type NodoLoteEnvio = {
  __typename?: 'NodoLoteEnvio'
  id?: Maybe<Scalars['ID']>
  nodeNome: Scalars['String']
  statusEnvio: StatusEnvio
  dataEnvio?: Maybe<Scalars['Instant']>
}

export enum OrientacaoSexualEnum {
  HETEROSSEXUAL = 'HETEROSSEXUAL',
  HOMOSSEXUAL = 'HOMOSSEXUAL',
  BISSEXUAL = 'BISSEXUAL',
  OUTRO_ORIENTACAO_SEXUAL = 'OUTRO_ORIENTACAO_SEXUAL',
}

export type OutroAcessoInput = {
  id?: Maybe<Scalars['ID']>
  profissional: Scalars['ID']
  tipo: TipoAcesso
  perfis: Array<Scalars['ID']>
  municipio?: Maybe<Scalars['ID']>
  uf?: Maybe<Scalars['ID']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  first: Scalars['Boolean']
  last: Scalars['Boolean']
  number: Scalars['Int']
  numberOfElements: Scalars['Int']
  size: Scalars['Int']
  totalPages: Scalars['Int']
  totalElements: Scalars['Int']
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PageParams = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Pais = {
  __typename?: 'Pais'
  id: Scalars['ID']
  nome: Scalars['String']
}

export type PaisQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type PaisQueryPayload = {
  __typename?: 'PaisQueryPayload'
  content: Array<Maybe<Pais>>
  pageInfo: PageInfo
}

export type Perfil = {
  __typename?: 'Perfil'
  id: Scalars['ID']
  nome: Scalars['String']
  ativo: Scalars['Boolean']
  tipoPerfil: TipoAcesso
  recursos: Array<Maybe<Scalars['String']>>
  perfilPadrao?: Maybe<Scalars['String']>
  actions: PerfilActions
}

export type PerfilActions = {
  __typename?: 'PerfilActions'
  excluir: Action
}

export type PerfilInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
  tipoPerfil: TipoAcesso
  recursos?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PerfisQueryInput = {
  tipoAcesso?: Maybe<TipoAcesso>
  query?: Maybe<Scalars['String']>
  mostrarInativos?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type PerfisQueryPayload = {
  __typename?: 'PerfisQueryPayload'
  content: Array<Maybe<Perfil>>
  pageInfo: PageInfo
}

export enum PerguntaSaidaCadastroEnum {
  SAIDA_CIDADAO_MUDANCA_TERRITORIO = 'SAIDA_CIDADAO_MUDANCA_TERRITORIO',
  SAIDA_CIDADAO_OBITO = 'SAIDA_CIDADAO_OBITO',
}

export type Period = {
  startDate?: Maybe<Scalars['LocalDate']>
  endDate?: Maybe<Scalars['LocalDate']>
}

export enum PeriodoEnum {
  PRIMEIRO_PERIODO = 'PRIMEIRO_PERIODO',
  SEGUNDO_PERIODO = 'SEGUNDO_PERIODO',
  TERCEIRO_PERIODO = 'TERCEIRO_PERIODO',
  QUARTO_PERIODO = 'QUARTO_PERIODO',
}

export type Procedimento = {
  __typename?: 'Procedimento'
  id: Scalars['ID']
  descricao: Scalars['String']
  codigo: Scalars['String']
  descricaoReferencia?: Maybe<Scalars['String']>
  codigoReferencia?: Maybe<Scalars['String']>
}

export type ProcedimentoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type ProcedimentosQueryPayload = {
  __typename?: 'ProcedimentosQueryPayload'
  content: Array<Maybe<Procedimento>>
  pageInfo: PageInfo
}

export type Processo = {
  __typename?: 'Processo'
  id: Scalars['ID']
  status: StatusProcesso
}

export type ProfissionaisInconsistenciaQueryPayload = {
  __typename?: 'ProfissionaisInconsistenciaQueryPayload'
  content: Array<Maybe<TransmissaoRecebimentoLoteDto>>
  pageInfo: PageInfo
}

export type ProfissionaisQueryInput = {
  query?: Maybe<Scalars['String']>
  mostrarSemLotacaoAtiva?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
  unidadeSaudeId?: Maybe<Scalars['String']>
  equipeId?: Maybe<Scalars['String']>
}

export type ProfissionaisQueryPayload = {
  __typename?: 'ProfissionaisQueryPayload'
  content: Array<Maybe<Profissional>>
  pageInfo: PageInfo
}

export type ProfissionaisRelatorioInconsistenciaQueryInput = {
  dataInicio: Scalars['LocalDate']
  dataFim: Scalars['LocalDate']
  nomeProfissional?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type Profissional = {
  __typename?: 'Profissional'
  cns?: Maybe<Scalars['String']>
  conselhoClasse?: Maybe<ConselhoClasse>
  cpf: Scalars['String']
  dataNascimento?: Maybe<Scalars['LocalDate']>
  email?: Maybe<Scalars['String']>
  endereco?: Maybe<Endereco>
  id: Scalars['ID']
  nome: Scalars['String']
  numeroConselhoClasse?: Maybe<Scalars['String']>
  sexo?: Maybe<Scalars['String']>
  telefone?: Maybe<Scalars['String']>
  ufEmissoraConselhoClasse?: Maybe<Uf>
  usuario: Usuario
  lotacoes?: Maybe<Array<Lotacao>>
  acessos?: Maybe<Array<Acesso>>
  actions: ProfissionalActions
}

export type ProfissionalAcessosArgs = {
  input?: Maybe<AcessosConnectionInput>
}

export type ProfissionalActions = {
  __typename?: 'ProfissionalActions'
  redefinirSenha: Action
}

export type ProfissionalContraChaveInput = {
  profissional: ProfissionalInput
  contraChave: Scalars['String']
}

export type ProfissionalInput = {
  conselhoClasse?: Maybe<Scalars['String']>
  cns?: Maybe<Scalars['String']>
  cpf: Scalars['String']
  dataNascimento?: Maybe<Scalars['LocalDate']>
  email?: Maybe<Scalars['String']>
  endereco?: Maybe<EnderecoInput>
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
  numeroConselhoClasse?: Maybe<Scalars['String']>
  sexo?: Maybe<Scalars['String']>
  telefone?: Maybe<Scalars['String']>
  ufEmissoraConselhoClasse?: Maybe<Scalars['String']>
}

export type Prontuario = {
  __typename?: 'Prontuario'
  id?: Maybe<Scalars['ID']>
}

export type Query = {
  __typename?: 'Query'
  acesso: Acesso
  acompanhamentoCondicaoSaude: AcompanhamentoCondicaoSaudeQueryPayload
  administradoresGerais: Array<AdministradorGeral>
  administradoresMunicipais: Array<AdministradorMunicipal>
  agendaEmConflito: Scalars['Boolean']
  auditoriaHistorico: AuditoriaHistoricoPayload
  auditoriaTipoEvento: Array<Maybe<AuditoriaTipoEvento>>
  auditoriaTipoRegistroAfetado: Array<Maybe<AuditoriaTipoRegistroAfetado>>
  avancada: ConfiguracaoAvancada
  bairros: BairroQueryPayload
  categoriasProfissional: Array<Maybe<CategoriaProfissional>>
  cbos: CbosMunicipioQueryPayload
  chaveByCpf: Scalars['String']
  ciaps: CiapQueryPayLoad
  cidadao?: Maybe<Cidadao>
  cidadaos?: Maybe<CidadaoListPayload>
  cidadaosCadsus?: Maybe<Array<Maybe<CidadaoCadsus>>>
  cids: Cid10QueryPayLoad
  compartilhamentoProntuario?: Maybe<CompartilhamentoProntuario>
  conexao: ConfiguracaoConexao
  configuracaoHorariosMunicipio: ConfiguracaoAgendaMunicipal
  configuracoesAtencaoDom: ConfiguracoesAtencaoDom
  conselhosClasse: ConselhoClassePayload
  dimensaoAgrupadorFiltro: DimensaoAgrupadorFiltroQueryPayload
  dimensaoEquipe: DimensaoEquipeQueryPayload
  dimensaoMunicipios: DimensaoMunicipiosQueryPayload
  dimensaoUnidadeSaude: DimensaoUnidadeSaudeQueryPayload
  duracaoAtendimentoPadraoMunicipio?: Maybe<DuracaoAtendimentoPadraoMunicipio>
  endereco?: Maybe<EnderecoQueryPayload>
  equipe: Equipe
  equipesAD: EquipeAdPayload
  etnias: EtniaPayload
  existemFichasParaProcessar: Scalars['Boolean']
  fabricantesImunobiologico: ImunobiologicoFabricanteQueryPayload
  fechamentos: Array<FechamentoAgenda>
  gestoresEstaduais: Array<GestorEstadual>
  gestoresMunicipais: Array<GestorMunicipal>
  grupoExame: GrupoExame
  grupos: GrupoCondicaoQueryPayLoad
  gruposExame: GruposExameQueryPayload
  hasAgendamentoFuturo?: Maybe<Scalars['Boolean']>
  importacaoCnes: ImportacaoCnes
  importacoesCnes: ImportacoesCnesQueryPayload
  imunobiologicos: ImunobiologicoQueryPayload
  info: Info
  loadHorarioGeracaoLotesProcessamentoFichas?: Maybe<Scalars['LocalTime']>
  logradouros: LogradouroQueryPayload
  lotacao: Lotacao
  lotacoes?: Maybe<LotacaoQueryPayload>
  loteEnvio: LoteEnvio
  lotesEnvio: LotesEnvioPayload
  lotesImunobiologico: LotesImunobiologicoQueryPayload
  municipios: MunicipiosQueryPayload
  municipiosResponsaveis: MunicipioResponsavelQueryPayload
  paises: PaisQueryPayload
  perfil?: Maybe<Perfil>
  perfis: PerfisQueryPayload
  procedimentosGrupoExame: ProcedimentosQueryPayload
  profissionais: ProfissionaisQueryPayload
  profissionaisRelatorioInconsistencia: ProfissionaisInconsistenciaQueryPayload
  profissional: Profissional
  profissionalByCpf?: Maybe<Profissional>
  recursos: Array<Scalars['String']>
  seguranca: ConfiguracaoSeguranca
  servidores: ConfiguracaoServidoresFormDto
  sessao?: Maybe<Sessao>
  tiposAtendimento?: Maybe<TipoAtendimentoQueryPayload>
  tiposLogradouro: TipoLogradouroQueryPayload
  tiposServico: TipoServicoQueryPayload
  transmissao: Transmissao
  /** @deprecated use transmissao.links */
  transmissaoLink: TransmissaoLinksPayload
  transmissaoRecebimentoCnes: TransmissaoRecebimentoCnesPayload
  transmissaoRecebimentoCnesDetailItems: Array<Maybe<TransmissaoRecebimentoCnesDetailItem>>
  transmissaoRecebimentoLotes: TransmissaoRecebimentoQueryPayload
  unidadeSaude: UnidadeSaude
  unidadeSaudeTiposServico: UnidadeSaudeTiposServicoPayload
  unidadesConsultaHorus: Array<Maybe<HorusFormDto>>
  unidadesSaude: UnidadesSaudeQueryPayload
  unificacoesCidadao?: Maybe<Array<Maybe<UnificacoesCidadao>>>
  validatePermissaoNovoAdmin: Scalars['Boolean']
  validateResetAdmin: ValidateResetSenhaAdminPayload
  vinculacaoFichaCadastroIndividual?: Maybe<CidadaoVinculacaoFichaDto>
}

export type QueryAcessoArgs = {
  id: Scalars['ID']
}

export type QueryAcompanhamentoCondicaoSaudeArgs = {
  input: AcompanhamentoCondicaoSaudeQueryInput
}

export type QueryAgendaEmConflitoArgs = {
  idLotacao: Scalars['ID']
}

export type QueryAuditoriaHistoricoArgs = {
  input: AuditoriaHistoricoQueryInput
}

export type QueryBairrosArgs = {
  input: BairroInput
}

export type QueryCategoriasProfissionalArgs = {
  input?: Maybe<CategoriaProfissionalQueryInput>
}

export type QueryCbosArgs = {
  input: CbosMunicipioQueryInput
}

export type QueryChaveByCpfArgs = {
  cpf: Scalars['String']
}

export type QueryCiapsArgs = {
  input: CiapQueryInput
}

export type QueryCidadaoArgs = {
  cidadaoId: Scalars['ID']
}

export type QueryCidadaosArgs = {
  filtro: CidadaosQueryInput
}

export type QueryCidadaosCadsusArgs = {
  filtro: CidadaosQueryInput
}

export type QueryCidsArgs = {
  input: Cid10QueryInput
}

export type QueryConselhosClasseArgs = {
  input: ConselhoClasseQueryInput
}

export type QueryDimensaoAgrupadorFiltroArgs = {
  input: DimensaoAgrupadorFiltroQueryInput
}

export type QueryDimensaoEquipeArgs = {
  input: DimensaoEquipeQueryInput
}

export type QueryDimensaoMunicipiosArgs = {
  input: DimensaoMunicipiosQueryInput
}

export type QueryDimensaoUnidadeSaudeArgs = {
  input: DimensaoUnidadeSaudeQueryInput
}

export type QueryEnderecoArgs = {
  cep: Scalars['String']
}

export type QueryEquipeArgs = {
  id: Scalars['ID']
}

export type QueryEquipesAdArgs = {
  input?: Maybe<EquipeAdInput>
}

export type QueryEtniasArgs = {
  input: EtniaQueryInput
}

export type QueryFabricantesImunobiologicoArgs = {
  input: ImunobiologicoFabricanteQueryInput
}

export type QueryFechamentosArgs = {
  input?: Maybe<AgendaFechamentoQueryInput>
}

export type QueryGrupoExameArgs = {
  id: Scalars['ID']
}

export type QueryGruposArgs = {
  input: GrupoCondicaoQueryInput
}

export type QueryGruposExameArgs = {
  input: GrupoExameQueryInput
}

export type QueryHasAgendamentoFuturoArgs = {
  input: CidadaoPossuiAgendamentoFuturoQueryInput
}

export type QueryImportacaoCnesArgs = {
  id: Scalars['ID']
}

export type QueryImportacoesCnesArgs = {
  input: ImportacoesCnesQueryInput
}

export type QueryImunobiologicosArgs = {
  input: ImunobiologicoQueryInput
}

export type QueryLogradourosArgs = {
  input: LogradouroInput
}

export type QueryLotacaoArgs = {
  id: Scalars['ID']
}

export type QueryLotacoesArgs = {
  input?: Maybe<LotacoesInput>
}

export type QueryLoteEnvioArgs = {
  idLote: Scalars['ID']
}

export type QueryLotesEnvioArgs = {
  input: LotesEnvioFiltro
}

export type QueryLotesImunobiologicoArgs = {
  input?: Maybe<LoteImunobiologicoQueryInput>
}

export type QueryMunicipiosArgs = {
  input: MunicipiosQueryInput
}

export type QueryMunicipiosResponsaveisArgs = {
  input: MunicipioResponsavelFiltroInput
}

export type QueryPaisesArgs = {
  input: PaisQueryInput
}

export type QueryPerfilArgs = {
  id: Scalars['ID']
}

export type QueryPerfisArgs = {
  input: PerfisQueryInput
}

export type QueryProcedimentosGrupoExameArgs = {
  input: ProcedimentoQueryInput
}

export type QueryProfissionaisArgs = {
  input: ProfissionaisQueryInput
}

export type QueryProfissionaisRelatorioInconsistenciaArgs = {
  filtro: ProfissionaisRelatorioInconsistenciaQueryInput
}

export type QueryProfissionalArgs = {
  id: Scalars['ID']
}

export type QueryProfissionalByCpfArgs = {
  cpf: Scalars['String']
}

export type QueryRecursosArgs = {
  tipoAcesso?: Maybe<TipoAcesso>
}

export type QueryTiposAtendimentoArgs = {
  input: TipoAtendimentoInput
}

export type QueryTiposLogradouroArgs = {
  input: TipoLogradouroInput
}

export type QueryTiposServicoArgs = {
  input: TipoServicoQueryInput
}

export type QueryTransmissaoLinkArgs = {
  input: TransmissaoLinkPagingQueryFiltroDto
}

export type QueryTransmissaoRecebimentoCnesArgs = {
  input: TransmissaoRecebimentoCnesFiltro
}

export type QueryTransmissaoRecebimentoCnesDetailItemsArgs = {
  input: TransmissaoRecebimentoCnesDetailFiltro
}

export type QueryTransmissaoRecebimentoLotesArgs = {
  input: TransmissaoRecebimentoLoteFiltro
}

export type QueryUnidadeSaudeArgs = {
  id: Scalars['ID']
}

export type QueryUnidadeSaudeTiposServicoArgs = {
  input: UnidadeSaudeTipoServicoQueryInput
}

export type QueryUnidadesConsultaHorusArgs = {
  id: Scalars['ID']
}

export type QueryUnidadesSaudeArgs = {
  input: UnidadesSaudeQueryInput
}

export type QueryUnificacoesCidadaoArgs = {
  cidadaoId: Scalars['ID']
}

export type QueryValidatePermissaoNovoAdminArgs = {
  input: ValidateNovoAdminInput
}

export type QueryValidateResetAdminArgs = {
  input: ResetSenhaAdminInput
}

export type QueryVinculacaoFichaCadastroIndividualArgs = {
  input: CidadaoVinculacaoFichaQueryInput
}

export type RacaCor = {
  __typename?: 'RacaCor'
  id: Scalars['ID']
  nome: Scalars['String']
  codigoMS: Scalars['String']
  codigoCadsus: Scalars['String']
  racaCorDbEnum: RacaCorDbEnum
}

export enum RacaCorDbEnum {
  BRANCA = 'BRANCA',
  PRETA = 'PRETA',
  PARDA = 'PARDA',
  AMARELA = 'AMARELA',
  INDIGENA = 'INDIGENA',
  SEM_INFORMACAO = 'SEM_INFORMACAO',
}

export type RecuperarSenhaInput = {
  token: Scalars['String']
  novaSenha: Scalars['String']
  confirmacaoSenha: Scalars['String']
}

export type ReferenceMonth = {
  month: Scalars['Int']
  year: Scalars['Int']
}

export type ResetSenhaAdminInput = {
  cpf: Scalars['String']
  contraChave: Scalars['String']
}

export type RespostaPesquisa = {
  grauSatisfacao?: Maybe<GrauSatisfacaoUsuario>
  observacao?: Maybe<Scalars['String']>
  tipoResposta?: Maybe<TipoRespostaPesquisa>
}

export type RlUnidadeSaudeTipoServicoIds = {
  __typename?: 'RlUnidadeSaudeTipoServicoIds'
  unidadeSaudeId: Scalars['ID']
  tipoServicoId: Scalars['ID']
}

export type SelecionarAcessoInput = {
  id: Scalars['ID']
}

export type SelecionarModuloInicialInput = {
  moduloInicial: Scalars['String']
}

export type ServidorSmtp = {
  __typename?: 'ServidorSMTP'
  id: Scalars['ID']
  email?: Maybe<Scalars['String']>
  senha?: Maybe<Scalars['String']>
  usuario?: Maybe<Scalars['String']>
  endereco?: Maybe<Scalars['String']>
  porta?: Maybe<Scalars['String']>
  ativo?: Maybe<Scalars['Boolean']>
  dataRegistro?: Maybe<Scalars['Instant']>
}

export type Sessao = {
  __typename?: 'Sessao'
  id: Scalars['ID']
  profissional: Profissional
  acesso?: Maybe<Acesso>
  recursos?: Maybe<Array<Maybe<Scalars['String']>>>
  timeout: Scalars['Int']
}

export enum SessionEventType {
  ACESSO_SELECIONADO = 'ACESSO_SELECIONADO',
  EXPIRED = 'EXPIRED',
  LOGGED_IN_ANOTHER_SESSION = 'LOGGED_IN_ANOTHER_SESSION',
  LOGGED_OUT = 'LOGGED_OUT',
}

export enum SexoEnum {
  MASCULINO = 'MASCULINO',
  FEMININO = 'FEMININO',
  AMBOS = 'AMBOS',
  NAO_INFORMADO = 'NAO_INFORMADO',
  IGNORADO = 'IGNORADO',
}

export type SincronizacaoCadsusResultDto = {
  __typename?: 'SincronizacaoCadsusResultDto'
  cidadaoLocal?: Maybe<Cidadao>
  cidadaoCadsus?: Maybe<CidadaoCadsus>
  camposAlterados?: Maybe<Array<Maybe<CampoAlteradoCadsus>>>
  errors?: Maybe<Array<Maybe<Scalars['String']>>>
  cidadaosDuplicados?: Maybe<Array<Maybe<CidadaoDuplicadoDto>>>
  cpfAntigo?: Maybe<Scalars['String']>
  result?: Maybe<SincronizacaoCadsusResultEnum>
}

export enum SincronizacaoCadsusResultEnum {
  SUCESSO = 'SUCESSO',
  ERRO = 'ERRO',
  ERRO_SEM_CONEXAO = 'ERRO_SEM_CONEXAO',
  ERRO_CADSUS_DESABILITADO = 'ERRO_CADSUS_DESABILITADO',
  VALIDACAO_COM_CNS = 'VALIDACAO_COM_CNS',
  VALIDACAO_SEM_CNS = 'VALIDACAO_SEM_CNS',
  DUPLICIDADE_DADOS_ATIVOS = 'DUPLICIDADE_DADOS_ATIVOS',
  DUPLICIDADE_DADOS_INATIVOS = 'DUPLICIDADE_DADOS_INATIVOS',
}

export type SolicitacaoRecuperarSenhaInput = {
  usuario?: Maybe<Scalars['String']>
}

export enum StatusEnvio {
  NAO_ENVIADO = 'NAO_ENVIADO',
  ENVIANDO = 'ENVIANDO',
  PARCIALMENTE_ENVIADO = 'PARCIALMENTE_ENVIADO',
  TOTALMENTE_ENVIADO = 'TOTALMENTE_ENVIADO',
}

export enum StatusImportacaoArquivo {
  SEM_RESULTADO = 'SEM_RESULTADO',
  IMPORTANDO = 'IMPORTANDO',
  CONCLUIDO = 'CONCLUIDO',
  ERRO = 'ERRO',
}

export enum StatusProcesso {
  AGUARDANDO = 'AGUARDANDO',
  CONCLUIDO = 'CONCLUIDO',
  EM_EXECUCAO = 'EM_EXECUCAO',
  INTERROMPIDO = 'INTERROMPIDO',
  ERRO = 'ERRO',
}

export enum StatusProcessoAuditoria {
  PROCESSANDO = 'PROCESSANDO',
  PROCESSADO = 'PROCESSADO',
  ERRO_SEM_DADOS = 'ERRO_SEM_DADOS',
  ERRO_PDF_NAO_SUPORTADO = 'ERRO_PDF_NAO_SUPORTADO',
  ERRO_PROCESSO_EXECUTANDO = 'ERRO_PROCESSO_EXECUTANDO',
  ERRO_INESPERADO = 'ERRO_INESPERADO',
}

export type Subscription = {
  __typename?: 'Subscription'
  auditoriaProcessoAdded: AuditoriaHistorico
  auditoriaProcessoUpdated: AuditoriaHistorico
  importacaoCnesAdded: ImportacaoCnes
  importacaoCnesUpdated: ImportacaoCnes
  onLoteGerado?: Maybe<LoteGeradoMessage>
  sessionEvent: SessionEventType
  transmissaoUpdated: Transmissao
}

export type SubscriptionImportacaoCnesAddedArgs = {
  municipio: Scalars['Int']
}

export type SubscriptionImportacaoCnesUpdatedArgs = {
  municipio: Scalars['Int']
}

export type TesteConexao = {
  __typename?: 'TesteConexao'
  sucesso: Scalars['Boolean']
  dataEvento: Scalars['Instant']
}

export enum TipoAcesso {
  ADMINISTRADOR_GERAL = 'ADMINISTRADOR_GERAL',
  ADMINISTRADOR_MUNICIPAL = 'ADMINISTRADOR_MUNICIPAL',
  GESTOR_MUNICIPAL = 'GESTOR_MUNICIPAL',
  GESTOR_ESTADUAL = 'GESTOR_ESTADUAL',
  LOTACAO = 'LOTACAO',
}

export enum TipoArquivoEnum {
  PDF = 'PDF',
  CSV = 'CSV',
}

export type TipoAtendimento = {
  __typename?: 'TipoAtendimento'
  id: Scalars['ID']
  descricao: Scalars['String']
}

export type TipoAtendimentoInput = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type TipoAtendimentoQueryPayload = {
  __typename?: 'TipoAtendimentoQueryPayload'
  tipos?: Maybe<Array<Maybe<TipoAtendimento>>>
}

export type TipoEquipe = {
  __typename?: 'TipoEquipe'
  id: Scalars['ID']
  sigla: Scalars['String']
  nome: Scalars['String']
  codigoMs?: Maybe<Scalars['String']>
  descricao?: Maybe<Scalars['String']>
}

export enum TipoInstalacao {
  PRONTUARIO = 'PRONTUARIO',
  CENTRALIZADORA = 'CENTRALIZADORA',
}

export type TipoLogradouro = {
  __typename?: 'TipoLogradouro'
  id: Scalars['ID']
  nome: Scalars['String']
  numeroDne: Scalars['String']
}

export type TipoLogradouroInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type TipoLogradouroQueryPayload = {
  __typename?: 'TipoLogradouroQueryPayload'
  content: Array<Maybe<TipoLogradouro>>
  pageInfo: PageInfo
}

export enum TipoRespostaPesquisa {
  FECHADO = 'FECHADO',
  NAO_RESPONDIDO = 'NAO_RESPONDIDO',
  ENVIADO = 'ENVIADO',
}

export enum TipoSanguineoEnum {
  A_POSITIVO = 'A_POSITIVO',
  A_NEGATIVO = 'A_NEGATIVO',
  B_POSITIVO = 'B_POSITIVO',
  B_NEGATIVO = 'B_NEGATIVO',
  AB_POSITIVO = 'AB_POSITIVO',
  AB_NEGATIVO = 'AB_NEGATIVO',
  O_POSITIVO = 'O_POSITIVO',
  O_NEGATIVO = 'O_NEGATIVO',
}

export type TipoServico = {
  __typename?: 'TipoServico'
  id: Scalars['ID']
  nome: Scalars['String']
  municipio?: Maybe<Municipio>
}

export type TipoServicoInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
}

export type TipoServicoQueryInput = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type TipoServicoQueryPayload = {
  __typename?: 'TipoServicoQueryPayload'
  content: Array<Maybe<TipoServico>>
  pageInfo: PageInfo
}

export type TipoServicoUpdateInput = {
  id?: Maybe<Scalars['ID']>
  nome: Scalars['String']
}

export type TipoUnidadeSaude = {
  __typename?: 'TipoUnidadeSaude'
  id: Scalars['ID']
  descricao: Scalars['String']
}

export type Transmissao = {
  __typename?: 'Transmissao'
  id: Scalars['ID']
  importacaoArquivo: ImportacaoArquivo
  links?: Maybe<TransmissaoLinksPayload>
}

export type TransmissaoLinksArgs = {
  input: TransmissaoLinksConnectionInput
}

export type TransmissaoLink = {
  __typename?: 'TransmissaoLink'
  id: Scalars['ID']
  hostname: Scalars['String']
  name: Scalars['String']
  ativo: Scalars['Boolean']
  statusConexao?: Maybe<Scalars['Boolean']>
  dataTesteConexao?: Maybe<Scalars['Instant']>
  actions: TransmissaoLinkActions
}

export type TransmissaoLinkActions = {
  __typename?: 'TransmissaoLinkActions'
  alternarAtivacao: Action
  excluir: Action
  editar: Action
}

export type TransmissaoLinkInsertDto = {
  hostname?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type TransmissaoLinkPagingQueryFiltroDto = {
  query?: Maybe<Scalars['String']>
  mostrarInativos?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type TransmissaoLinksConnectionInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativos?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
}

export type TransmissaoLinksPayload = {
  __typename?: 'TransmissaoLinksPayload'
  content: Array<Maybe<TransmissaoLink>>
  pageInfo: PageInfo
}

export type TransmissaoLinkUpdateDto = {
  id: Scalars['ID']
  hostname?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type TransmissaoRecebimentoCnesDetailFiltro = {
  numCnes: Scalars['String']
  mesRecebimento: Scalars['Int']
  anoRecebimento: Scalars['Int']
}

export type TransmissaoRecebimentoCnesDetailItem = {
  __typename?: 'TransmissaoRecebimentoCnesDetailItem'
  nomeEstabelecimento?: Maybe<Scalars['String']>
  tipoFicha: Scalars['String']
  quantidade: Scalars['Int']
}

export type TransmissaoRecebimentoCnesDto = {
  __typename?: 'TransmissaoRecebimentoCnesDto'
  numCnes: Scalars['String']
  mesRecebimento: Scalars['Int']
  anoRecebimento: Scalars['Int']
  fichasProcessadas: Scalars['Int']
  estabelecimentoOrigem?: Maybe<Scalars['String']>
}

export type TransmissaoRecebimentoCnesFiltro = {
  query?: Maybe<Scalars['String']>
  estabelecimentoOrigem?: Maybe<Scalars['String']>
  competencia?: Maybe<ReferenceMonth>
  pageParams?: Maybe<PageParams>
}

export type TransmissaoRecebimentoCnesPayload = {
  __typename?: 'TransmissaoRecebimentoCnesPayload'
  content: Array<Maybe<TransmissaoRecebimentoCnesDto>>
  pageInfo: PageInfo
}

export type TransmissaoRecebimentoLoteCountsDto = {
  __typename?: 'TransmissaoRecebimentoLoteCountsDto'
  fichasValidas: Scalars['Int']
  fichasInvalidas: Scalars['Int']
  totalFichas: Scalars['Int']
  fichasRepetidas: Scalars['Int']
}

export type TransmissaoRecebimentoLoteDto = {
  __typename?: 'TransmissaoRecebimentoLoteDto'
  id: Scalars['ID']
  numeroLote?: Maybe<Scalars['String']>
  responsavel: Scalars['String']
  cpfResponsavel: Scalars['String']
  processado?: Maybe<Scalars['Boolean']>
  data: Scalars['Instant']
  counts: TransmissaoRecebimentoLoteCountsDto
  possuiInconsistencia: Scalars['Boolean']
}

export type TransmissaoRecebimentoLoteFiltro = {
  query?: Maybe<Scalars['String']>
  pageParams?: Maybe<PageParams>
}

export type TransmissaoRecebimentoQueryPayload = {
  __typename?: 'TransmissaoRecebimentoQueryPayload'
  content: Array<Maybe<TransmissaoRecebimentoLoteDto>>
  pageInfo: PageInfo
}

export type Uf = {
  __typename?: 'UF'
  id: Scalars['ID']
  nome: Scalars['String']
  sigla: Scalars['String']
}

export type UnidadeConsultaHorusIds = {
  unidadeSaudeId: Scalars['ID']
  unidadeConsultaId: Scalars['ID']
}

export type UnidadeSaude = {
  __typename?: 'UnidadeSaude'
  id: Scalars['ID']
  nome?: Maybe<Scalars['String']>
  cnes: Scalars['String']
  cnpj?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  telefone1?: Maybe<Scalars['String']>
  telefone2?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  tipo?: Maybe<TipoUnidadeSaude>
  complexidades?: Maybe<Array<Maybe<Complexidade>>>
  ativo: Scalars['Boolean']
  endereco?: Maybe<Endereco>
  equipes: EquipePayload
  actions: UnidadeSaudeActions
}

export type UnidadeSaudeEquipesArgs = {
  input: EquipesConnectionInput
}

export type UnidadeSaudeActions = {
  __typename?: 'UnidadeSaudeActions'
  permissaoConexaoHorus: Action
  permissaoAtivarInativarTipoServico: Action
}

export type UnidadeSaudeTipoServico = {
  __typename?: 'UnidadeSaudeTipoServico'
  tipoServico: TipoServico
  ativo: Scalars['Boolean']
}

export type UnidadeSaudeTipoServicoFormInput = {
  unidadeSaudeId: Scalars['ID']
  tipoServicoId: Scalars['ID']
}

export type UnidadeSaudeTipoServicoQueryInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativos?: Maybe<Scalars['Boolean']>
  unidadeSaudeId: Scalars['ID']
  pageParams?: Maybe<PageParams>
}

export type UnidadeSaudeTiposServicoPayload = {
  __typename?: 'UnidadeSaudeTiposServicoPayload'
  content: Array<Maybe<UnidadeSaudeTipoServico>>
  pageInfo: PageInfo
}

export type UnidadesSaudeQueryInput = {
  query?: Maybe<Scalars['String']>
  mostrarInativas?: Maybe<Scalars['Boolean']>
  pageParams?: Maybe<PageParams>
  somenteUnidadesPermitidas?: Maybe<Scalars['Boolean']>
}

export type UnidadesSaudeQueryPayload = {
  __typename?: 'UnidadesSaudeQueryPayload'
  content: Array<Maybe<UnidadeSaude>>
  pageInfo: PageInfo
}

export type UnificacaoCadastroInput = {
  idCidadaoMaster: Scalars['ID']
  idCidadaos: Array<Maybe<Scalars['ID']>>
}

export type UnificacoesCidadao = {
  __typename?: 'UnificacoesCidadao'
  id: Scalars['ID']
  nomeProfissional?: Maybe<Scalars['String']>
  dataUnificacao?: Maybe<Scalars['Instant']>
  cidadaos?: Maybe<Array<Maybe<Cidadao>>>
}

export type Usuario = {
  __typename?: 'Usuario'
  id: Scalars['ID']
  bloqueado: Scalars['Boolean']
  aceitouTermosUso: Scalars['Boolean']
  trocarSenha: Scalars['Boolean']
  visualizouNovidades: Scalars['Boolean']
  mostrarPesquisaSatisfacao: Scalars['Boolean']
  hashId: Scalars['String']
}

export type ValidateNovoAdminInput = {
  id?: Maybe<Scalars['ID']>
  cpf: Scalars['String']
  contraChave: Scalars['String']
}

export type ValidateResetSenhaAdminPayload = {
  __typename?: 'ValidateResetSenhaAdminPayload'
  possuiCns?: Maybe<Scalars['Boolean']>
}

export type LogoutMutationVariables = {}

export type LogoutMutation = { __typename?: 'Mutation'; logout?: Maybe<{ __typename?: 'Sessao' } & SessaoDataFragment> }

export type SessaoQueryVariables = {}

export type SessaoQuery = { __typename?: 'Query'; sessao?: Maybe<{ __typename?: 'Sessao' } & SessaoDataFragment> }

export type SessaoDataFragment = {
  __typename?: 'Sessao'
  id: string
  timeout: number
  recursos?: Maybe<Array<Maybe<string>>>
  acesso?: Maybe<
    | {
        __typename?: 'Lotacao'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
        cbo: { __typename?: 'Cbo'; id: string; nome: string }
        municipio: { __typename?: 'Municipio'; id: string; nome: string }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
    | {
        __typename?: 'AdministradorGeral'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        municipio?: Maybe<{ __typename?: 'Municipio'; id: string; nome: string }>
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
    | {
        __typename?: 'AdministradorMunicipal'
        autorizado: boolean
        habilitado: boolean
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        municipio: { __typename?: 'Municipio'; id: string; nome: string }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
    | {
        __typename?: 'GestorEstadual'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        municipio?: Maybe<{ __typename?: 'Municipio'; id: string; nome: string }>
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
    | {
        __typename?: 'GestorMunicipal'
        id: string
        tipo: TipoAcesso
        moduloInicial?: Maybe<string>
        municipio: { __typename?: 'Municipio'; id: string; nome: string }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string }>>
      }
  >
  profissional: {
    __typename?: 'Profissional'
    id: string
    cns?: Maybe<string>
    nome: string
    usuario: {
      __typename?: 'Usuario'
      id: string
      aceitouTermosUso: boolean
      trocarSenha: boolean
      visualizouNovidades: boolean
      mostrarPesquisaSatisfacao: boolean
      hashId: string
    }
    acessos?: Maybe<
      Array<
        | ({ __typename?: 'Lotacao'; id: string; tipo: TipoAcesso } & AcessoLotacaoFragment)
        | { __typename?: 'AdministradorGeral'; id: string; tipo: TipoAcesso }
        | ({
            __typename?: 'AdministradorMunicipal'
            id: string
            tipo: TipoAcesso
          } & AcessoAdministradorMunicipalFragment)
        | ({ __typename?: 'GestorEstadual'; id: string; tipo: TipoAcesso } & AcessoGestorEstadualFragment)
        | ({ __typename?: 'GestorMunicipal'; id: string; tipo: TipoAcesso } & AcessoGestorMunicipalFragment)
      >
    >
  }
}

export type AcessoAdministradorMunicipalFragment = {
  __typename?: 'AdministradorMunicipal'
  municipio: { __typename?: 'Municipio'; id: string; nome: string }
}

export type AcessoGestorEstadualFragment = {
  __typename?: 'GestorEstadual'
  uf: { __typename?: 'UF'; id: string; nome: string }
}

export type AcessoGestorMunicipalFragment = {
  __typename?: 'GestorMunicipal'
  municipio: { __typename?: 'Municipio'; id: string; nome: string }
}

export type AcessoLotacaoFragment = {
  __typename?: 'Lotacao'
  cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
  equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
  unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
}

export type EsusViewMountTargetQueryVariables = {}

export type EsusViewMountTargetQuery = { __typename?: 'Query'; info: { __typename?: 'Info'; oldPecConnected: boolean } }

export type EnderecoQueryVariables = {
  cep: Scalars['String']
}

export type EnderecoQuery = {
  __typename?: 'Query'
  endereco?: Maybe<{
    __typename?: 'EnderecoQueryPayload'
    logradouro?: Maybe<string>
    municipio?: Maybe<{ __typename?: 'Municipio'; id: string; nome: string }>
    uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string }>
    bairro?: Maybe<{ __typename?: 'Bairro'; id: string; nome: string }>
  }>
}

export type BairroSelectFieldQueryVariables = {
  input: BairroInput
}

export type BairroSelectFieldQuery = {
  __typename?: 'Query'
  bairros: {
    __typename?: 'BairroQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Bairro'
        id: string
        nome: string
        municipio: { __typename?: 'Municipio'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CboSelectFieldQueryVariables = {
  input: CbosMunicipioQueryInput
}

export type CboSelectFieldQuery = {
  __typename?: 'Query'
  cbos: {
    __typename?: 'CbosMunicipioQueryPayload'
    content: Array<
      Maybe<{ __typename?: 'CboMunicipio'; cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string } }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CiapCid10SelectFieldQueryVariables = {
  inputCiap: CiapQueryInput
  inputCid10: Cid10QueryInput
}

export type CiapCid10SelectFieldQuery = {
  __typename?: 'Query'
  ciaps: {
    __typename?: 'CiapQueryPayLoad'
    content: Array<Maybe<{ __typename?: 'Ciap'; id: string; codigo: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
  cids: {
    __typename?: 'Cid10QueryPayLoad'
    content: Array<Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ConselhoClasseSelectQueryVariables = {
  input: ConselhoClasseQueryInput
}

export type ConselhoClasseSelectQuery = {
  __typename?: 'Query'
  conselhosClasse: {
    __typename?: 'ConselhoClassePayload'
    content: Array<Maybe<{ __typename?: 'ConselhoClasse'; id: string; descricao?: Maybe<string> }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type EquipeSelectFieldQueryVariables = {
  unidadeSaudeId: Scalars['ID']
  input: EquipesConnectionInput
}

export type EquipeSelectFieldQuery = {
  __typename?: 'Query'
  unidadeSaude: {
    __typename?: 'UnidadeSaude'
    id: string
    equipes: {
      __typename?: 'EquipePayload'
      content: Array<
        Maybe<{
          __typename?: 'Equipe'
          id: string
          nome: string
          ine: string
          tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
        }>
      >
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
    }
  }
}

export type EtniaSelectFieldQueryVariables = {
  input: EtniaQueryInput
}

export type EtniaSelectFieldQuery = {
  __typename?: 'Query'
  etnias: {
    __typename?: 'EtniaPayload'
    content: Array<Maybe<{ __typename?: 'Etnia'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type GrupoCondicaoSelectFieldQueryVariables = {
  inputGrupo: GrupoCondicaoQueryInput
}

export type GrupoCondicaoSelectFieldQuery = {
  __typename?: 'Query'
  grupos: {
    __typename?: 'GrupoCondicaoQueryPayLoad'
    content: Array<
      Maybe<{
        __typename?: 'GrupoCondicaoDto'
        id: string
        grupoCondicao: GrupoCondicaoEnum
        ciaps?: Maybe<Array<Maybe<{ __typename?: 'Ciap'; id: string; codigo: string; nome: string }>>>
        cids?: Maybe<Array<Maybe<{ __typename?: 'Cid10'; id: string; codigo: string; nome: string }>>>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type LogradouroSelectFieldQueryVariables = {
  input: LogradouroInput
}

export type LogradouroSelectFieldQuery = {
  __typename?: 'Query'
  logradouros: {
    __typename?: 'LogradouroQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Logradouro'
        id: string
        nome: string
        nomeExibicao: string
        cep: string
        bairro: {
          __typename?: 'Bairro'
          id: string
          nome: string
          municipio: {
            __typename?: 'Municipio'
            id: string
            nome: string
            uf: { __typename?: 'UF'; id: string; sigla: string }
          }
        }
        tipoLogradouro: { __typename?: 'TipoLogradouro'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type LotacaoProfissionalSelectQueryVariables = {
  input: LotacoesInput
}

export type LotacaoProfissionalSelectQuery = {
  __typename?: 'Query'
  lotacoes?: Maybe<{
    __typename?: 'LotacaoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Lotacao'
        id: string
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cns?: Maybe<string> }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }>
}

export type MunicipioSelectFieldQueryVariables = {
  input: MunicipiosQueryInput
}

export type MunicipioSelectFieldQuery = {
  __typename?: 'Query'
  municipios: {
    __typename?: 'MunicipiosQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Municipio'
        id: string
        nome: string
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type PaisSelectFieldQueryVariables = {
  input: PaisQueryInput
}

export type PaisSelectFieldQuery = {
  __typename?: 'Query'
  paises: {
    __typename?: 'PaisQueryPayload'
    content: Array<Maybe<{ __typename?: 'Pais'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type PerfilSelectFieldQueryVariables = {
  input: PerfisQueryInput
}

export type PerfilSelectFieldQuery = {
  __typename?: 'Query'
  perfis: {
    __typename?: 'PerfisQueryPayload'
    content: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ProfissionaisRelatorioInconsistenciaQueryVariables = {
  filtro: ProfissionaisRelatorioInconsistenciaQueryInput
}

export type ProfissionaisRelatorioInconsistenciaQuery = {
  __typename?: 'Query'
  profissionaisRelatorioInconsistencia: {
    __typename?: 'ProfissionaisInconsistenciaQueryPayload'
    content: Array<Maybe<{ __typename?: 'TransmissaoRecebimentoLoteDto'; responsavel: string; cpfResponsavel: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ProfissionalSelectQueryVariables = {
  input: ProfissionaisQueryInput
}

export type ProfissionalSelectQuery = {
  __typename?: 'Query'
  profissionais: {
    __typename?: 'ProfissionaisQueryPayload'
    content: Array<Maybe<{ __typename?: 'Profissional'; id: string; nome: string; cpf: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TipoLogradouroSelectFieldQueryVariables = {
  input: TipoLogradouroInput
}

export type TipoLogradouroSelectFieldQuery = {
  __typename?: 'Query'
  tiposLogradouro: {
    __typename?: 'TipoLogradouroQueryPayload'
    content: Array<Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type UnidadesSaudeSelectFieldQueryVariables = {
  input: UnidadesSaudeQueryInput
}

export type UnidadesSaudeSelectFieldQuery = {
  __typename?: 'Query'
  unidadesSaude: {
    __typename?: 'UnidadesSaudeQueryPayload'
    content: Array<Maybe<{ __typename?: 'UnidadeSaude'; id: string; cnes: string; nome?: Maybe<string> }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type DimensaoEquipeSelectQueryVariables = {
  input: DimensaoEquipeQueryInput
}

export type DimensaoEquipeSelectQuery = {
  __typename?: 'Query'
  dimensaoEquipe: {
    __typename?: 'DimensaoEquipeQueryPayload'
    content: Array<Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type DimensaoLotacaoProfissionalSelectQueryVariables = {
  input: DimensaoAgrupadorFiltroQueryInput
}

export type DimensaoLotacaoProfissionalSelectQuery = {
  __typename?: 'Query'
  dimensaoAgrupadorFiltro: {
    __typename?: 'DimensaoAgrupadorFiltroQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'DimensaoAgrupadorFiltro'
        id: string
        dimensaoCbo?: Maybe<{ __typename?: 'DimensaoCbo'; id: string; nome: string; cbo: string }>
        dimensaoEquipe?: Maybe<{ __typename?: 'DimensaoEquipe'; id: string; nome: string; ine: string }>
        dimensaoUnidadeSaude?: Maybe<{
          __typename?: 'DimensaoUnidadeSaude'
          id: string
          nome?: Maybe<string>
          cnes: string
        }>
        dimensaoProfissional?: Maybe<{
          __typename?: 'DimensaoProfissional'
          id: string
          nome?: Maybe<string>
          cns?: Maybe<string>
        }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type DimensaoMunicipioSelectQueryVariables = {
  input: DimensaoMunicipiosQueryInput
}

export type DimensaoMunicipioSelectQuery = {
  __typename?: 'Query'
  dimensaoMunicipios: {
    __typename?: 'DimensaoMunicipiosQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'DimensaoMunicipio'
        id: string
        nome: string
        uf?: Maybe<{ __typename?: 'DimensaoUf'; id: string; nome: string; sigla: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type DimensaoUnidadeSaudeSelectQueryVariables = {
  input: DimensaoUnidadeSaudeQueryInput
}

export type DimensaoUnidadeSaudeSelectQuery = {
  __typename?: 'Query'
  dimensaoUnidadeSaude: {
    __typename?: 'DimensaoUnidadeSaudeQueryPayload'
    content: Array<Maybe<{ __typename?: 'DimensaoUnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type InstalacaoHeaderQueryVariables = {}

export type InstalacaoHeaderQuery = {
  __typename?: 'Query'
  info: { __typename?: 'Info'; treinamento: boolean; versao: string }
}

export type PageInfoFragment = {
  __typename?: 'PageInfo'
  number: number
  size: number
  totalPages: number
  totalElements: number
  sort?: Maybe<Array<Maybe<string>>>
  first: boolean
  last: boolean
  numberOfElements: number
}

export type InitialViewQueryVariables = {}

export type InitialViewQuery = { __typename?: 'Query'; info: { __typename?: 'Info'; ativado: boolean } }

export type BuscaCidadaoListagemQueryVariables = {
  input: AcompanhamentoCondicaoSaudeQueryInput
}

export type BuscaCidadaoListagemQuery = {
  __typename?: 'Query'
  acompanhamentoCondicaoSaude: {
    __typename?: 'AcompanhamentoCondicaoSaudeQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Cidadao'
        id: string
        nome: string
        nomeSocial?: Maybe<string>
        cpf?: Maybe<string>
        telefoneResidencial?: Maybe<string>
        telefoneCelular?: Maybe<string>
        telefoneContato?: Maybe<string>
        dataUltimoAtendimento?: Maybe<any>
        endereco?: Maybe<{
          __typename?: 'Endereco'
          logradouro?: Maybe<string>
          numero?: Maybe<string>
          complemento?: Maybe<string>
          bairro?: Maybe<string>
          cep?: Maybe<string>
          municipio?: Maybe<{ __typename?: 'Municipio'; nome: string }>
          tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; nome: string }>
          uf?: Maybe<{ __typename?: 'UF'; sigla: string }>
        }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type BuscaCidadaoRelatoriosOperacionaisQueryVariables = {
  input: AcompanhamentoCondicaoSaudeQueryInput
}

export type BuscaCidadaoRelatoriosOperacionaisQuery = {
  __typename?: 'Query'
  acompanhamentoCondicaoSaude: {
    __typename?: 'AcompanhamentoCondicaoSaudeQueryPayload'
    content: Array<Maybe<{ __typename?: 'Cidadao'; id: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type SalvarApoioEmapEmadMutationVariables = {
  input: ConfigAtendimentoDomInput
}

export type SalvarApoioEmapEmadMutation = {
  __typename?: 'Mutation'
  salvarConfigAtendimentoDomTipoApoio: { __typename?: 'ConfiguracoesAtendimentosDomiciliares'; id: string }
}

export type SalvarPodeAtenderEmadMutationVariables = {
  input: ConfigAtendimentoDomInput
}

export type SalvarPodeAtenderEmadMutation = {
  __typename?: 'Mutation'
  salvarConfigAtendimentoDomTipoPodeAtender: { __typename?: 'ConfiguracoesAtendimentosDomiciliares'; id: string }
}

export type ConfiguracoesAtencaoDomQueryVariables = {
  input?: Maybe<ConfiguracoesAtendimentosDomInput>
}

export type ConfiguracoesAtencaoDomQuery = {
  __typename?: 'Query'
  configuracoesAtencaoDom: {
    __typename?: 'ConfiguracoesAtencaoDom'
    duracao: number
    emadsAtendemEmads: {
      __typename?: 'ConfiguracoesAtendimentosDomPayload'
      content: Array<
        Maybe<{
          __typename?: 'ConfiguracoesAtendimentosDomiciliares'
          id: string
          identificadorNacionalEquipePai: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
          identificadorNacionalEquipeFilho: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
        }>
      >
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
    }
    emapsApoiamEmads: {
      __typename?: 'ConfiguracoesAtendimentosDomPayload'
      content: Array<
        Maybe<{
          __typename?: 'ConfiguracoesAtendimentosDomiciliares'
          id: string
          identificadorNacionalEquipePai: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
          identificadorNacionalEquipeFilho: { __typename?: 'Equipe'; id: string; nome: string; ine: string }
        }>
      >
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
    }
  }
}

export type EquipesAdQueryVariables = {
  input?: Maybe<EquipeAdInput>
}

export type EquipesAdQuery = {
  __typename?: 'Query'
  equipesAD: {
    __typename?: 'EquipeADPayload'
    content: Array<
      Maybe<{
        __typename?: 'Equipe'
        id: string
        nome: string
        ine: string
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ExcluirConfigAtendimentoDomMutationVariables = {
  idConfig: Scalars['ID']
}

export type ExcluirConfigAtendimentoDomMutation = { __typename?: 'Mutation'; excluirConfigAtendimentoDom: string }

export type SalvarDuracaoAgendamentoAdMutationVariables = {
  input: DuracaoAgendamentoAtencaoDomInput
}

export type SalvarDuracaoAgendamentoAdMutation = {
  __typename?: 'Mutation'
  salvarDuracaoAgendamentoAtencaoDom: { __typename?: 'DuracaoAgendamentoAtencaoDomDto'; duracaoAgendamento: number }
}

export type AlterarSenhaMutationVariables = {
  input: AlterarSenhaInput
}

export type AlterarSenhaMutation = {
  __typename?: 'Mutation'
  alterarSenha?: Maybe<{ __typename?: 'Usuario'; id: string; trocarSenha: boolean }>
}

export type AuditoriaHistoricoTableQueryVariables = {
  input: AuditoriaHistoricoQueryInput
}

export type AuditoriaHistoricoTableQuery = {
  __typename?: 'Query'
  auditoriaHistorico: {
    __typename?: 'AuditoriaHistoricoPayload'
    content: Array<
      Maybe<{
        __typename?: 'AuditoriaHistorico'
        id: string
        dataInicio: any
        dataFim: any
        dataSolicitacao: any
        status: StatusProcessoAuditoria
        csv?: Maybe<boolean>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type OnSessionEventSubscriptionVariables = {}

export type OnSessionEventSubscription = { __typename?: 'Subscription'; sessionEvent: SessionEventType }

export type AutorizarMunicipioConfigAgendaPadraoQueryVariables = {}

export type AutorizarMunicipioConfigAgendaPadraoQuery = {
  __typename?: 'Query'
  duracaoAtendimentoPadraoMunicipio?: Maybe<{
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }>
  configuracaoHorariosMunicipio: {
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }
}

export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutationVariables = {
  duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput
  configuracaoAgendaMunicipalInput?: Maybe<ConfiguracaoAgendaMunicipalInput>
}

export type SalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation = {
  __typename?: 'Mutation'
  alterarDuracaoPadraoAtendimento: {
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }
  salvarConfiguracaoHorariosMunicipio?: Maybe<{
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }>
}

export type SaveAutorizarMunicipioMutationVariables = {
  input: AutorizarMunicipioInput
}

export type SaveAutorizarMunicipioMutation = {
  __typename?: 'Mutation'
  autorizarMunicipio: { __typename?: 'AdministradorMunicipal'; id: string; autorizado: boolean }
}

export type AutorizarMunicipioQueryVariables = {
  acessoId: Scalars['ID']
}

export type AutorizarMunicipioQuery = {
  __typename?: 'Query'
  info: { __typename?: 'Info'; treinamento: boolean; tipoInstalacao: TipoInstalacao }
  acesso:
    | {
        __typename: 'Lotacao'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
    | {
        __typename: 'AdministradorGeral'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
      }
    | {
        __typename: 'AdministradorMunicipal'
        autorizado: boolean
        chaveMunicipio: string
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
    | {
        __typename: 'GestorEstadual'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
      }
    | {
        __typename: 'GestorMunicipal'
        id: string
        profissional: { __typename?: 'Profissional'; id: string; nome: string; cpf: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
      }
}

export type ConcluirConfiguracaoMunicipioMutationVariables = {}

export type ConcluirConfiguracaoMunicipioMutation = {
  __typename?: 'Mutation'
  concluirConfiguracaoMunicipio: { __typename?: 'AdministradorMunicipal'; id: string; habilitado: boolean }
}

export type AlterarDuracaoCboMutationVariables = {
  cboDuracaoForm: CboDuracaoFormInput
}

export type AlterarDuracaoCboMutation = {
  __typename?: 'Mutation'
  alterarDuracaoCbo?: Maybe<{ __typename?: 'CboMunicipio'; cbo: { __typename?: 'Cbo'; id: string } }>
}

export type DisponibilizarCboMutationVariables = {
  cboDisponibilizarForm: CboDisponibilizarFormInput
}

export type DisponibilizarCboMutation = {
  __typename?: 'Mutation'
  disponibilizarCbo?: Maybe<{ __typename?: 'CboMunicipio'; cbo: { __typename?: 'Cbo'; id: string } }>
}

export type CboTableQueryVariables = {
  input: CbosMunicipioQueryInput
}

export type CboTableQuery = {
  __typename?: 'Query'
  cbos: {
    __typename?: 'CbosMunicipioQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'CboMunicipio'
        disponivelLotacao?: Maybe<boolean>
        duracao?: Maybe<number>
        duracaoPadrao?: Maybe<number>
        possuiLotacaoComAgendaOnline?: Maybe<boolean>
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        actions: {
          __typename?: 'CboMunicipioActions'
          inativar: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CidadaoListingQueryVariables = {
  filtro: CidadaosQueryInput
}

export type CidadaoListingQuery = {
  __typename?: 'Query'
  cidadaos?: Maybe<{
    __typename?: 'CidadaoListPayload'
    hasMoreResults?: Maybe<boolean>
    content?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Cidadao'
          id: string
          nome: string
          nomeSocial?: Maybe<string>
          cpf?: Maybe<string>
          cns?: Maybe<string>
          nomeMae?: Maybe<string>
          dataNascimento: any
          telefoneCelular?: Maybe<string>
          telefoneResidencial?: Maybe<string>
          sexo: SexoEnum
          identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
          dataAtualizado?: Maybe<any>
          ativo?: Maybe<boolean>
          unificado?: Maybe<boolean>
          faleceu?: Maybe<boolean>
          possuiAgendamento?: Maybe<boolean>
          uuidUltimaFicha?: Maybe<string>
          prontuario?: Maybe<{ __typename?: 'Prontuario'; id?: Maybe<string> }>
          localidadeNascimento?: Maybe<{
            __typename?: 'Municipio'
            id: string
            nome: string
            uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
          }>
          cidadaoVinculacaoEquipe?: Maybe<{
            __typename?: 'CidadaoVinculacaoEquipe'
            id?: Maybe<string>
            dataAtualizacaoCadastro?: Maybe<any>
            unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
            equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
          }>
        }>
      >
    >
  }>
}

export type ExcluirCidadaoMutationVariables = {
  cidadaoId: Scalars['ID']
}

export type ExcluirCidadaoMutation = { __typename?: 'Mutation'; excluirCidadao: string }

export type ReativarCidadaoMutationVariables = {
  cidadaoId: Scalars['ID']
}

export type ReativarCidadaoMutation = {
  __typename?: 'Mutation'
  reativarCidadao: { __typename?: 'Cidadao'; id: string }
}

export type VinculacaoFichaCadastroIndividualQueryVariables = {
  input: CidadaoVinculacaoFichaQueryInput
}

export type VinculacaoFichaCadastroIndividualQuery = {
  __typename?: 'Query'
  vinculacaoFichaCadastroIndividual?: Maybe<{
    __typename?: 'CidadaoVinculacaoFichaDto'
    perguntaSaidaCadastroEnum?: Maybe<PerguntaSaidaCadastroEnum>
    unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
    equipe?: Maybe<{
      __typename?: 'Equipe'
      id: string
      ine: string
      nome: string
      tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
    }>
  }>
}

export type BuscaCidadaoCadsusQueryVariables = {
  filter: CidadaosQueryInput
}

export type BuscaCidadaoCadsusQuery = {
  __typename?: 'Query'
  cidadaosCadsus?: Maybe<
    Array<
      Maybe<{
        __typename?: 'CidadaoCadsus'
        nome: string
        nomeSocial?: Maybe<string>
        cpf?: Maybe<string>
        cns?: Maybe<string>
        nomeMae?: Maybe<string>
        dataNascimento?: Maybe<any>
        telefoneCelular?: Maybe<string>
        telefoneResidencial?: Maybe<string>
        sexo?: Maybe<SexoEnum>
        municipioNascimento?: Maybe<{
          __typename?: 'Municipio'
          id: string
          nome: string
          ibge: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }>
      }>
    >
  >
}

export type ConfiguracaoCadsusQueryVariables = {}

export type ConfiguracaoCadsusQuery = {
  __typename?: 'Query'
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
    cadsus: { __typename?: 'ConfiguracaoCadsus'; habilitado: boolean }
  }
}

export type CreateCidadaoCadsusMutationVariables = {
  cidadaoId: Scalars['ID']
}

export type CreateCidadaoCadsusMutation = {
  __typename?: 'Mutation'
  createCidadaoCadsus?: Maybe<{
    __typename?: 'SincronizacaoCadsusResultDto'
    errors?: Maybe<Array<Maybe<string>>>
    result?: Maybe<SincronizacaoCadsusResultEnum>
    camposAlterados?: Maybe<
      Array<Maybe<{ __typename?: 'CampoAlteradoCadsus'; campo?: Maybe<string>; conteudo?: Maybe<string> }>>
    >
    cidadaoLocal?: Maybe<{ __typename?: 'Cidadao'; id: string; cpf?: Maybe<string>; cns?: Maybe<string> }>
    cidadaosDuplicados?: Maybe<
      Array<
        Maybe<{
          __typename?: 'CidadaoDuplicadoDto'
          id?: Maybe<string>
          nome?: Maybe<string>
          cpf?: Maybe<string>
          cns?: Maybe<string>
        }>
      >
    >
  }>
}

export type BuscaDetailCidadaoQueryVariables = {
  id: Scalars['ID']
}

export type BuscaDetailCidadaoQuery = {
  __typename?: 'Query'
  cidadao?: Maybe<{
    __typename?: 'Cidadao'
    id: string
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nisPisPasep?: Maybe<string>
    nome: string
    nomeSocial?: Maybe<string>
    dataNascimento: any
    dataAtualizado?: Maybe<any>
    dataObito?: Maybe<any>
    numeroDocumentoObito?: Maybe<string>
    sexo: SexoEnum
    nomeMae?: Maybe<string>
    nomePai?: Maybe<string>
    telefoneResidencial?: Maybe<string>
    telefoneCelular?: Maybe<string>
    telefoneContato?: Maybe<string>
    email?: Maybe<string>
    area?: Maybe<string>
    microArea?: Maybe<string>
    localidadeExterior?: Maybe<string>
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    ativo?: Maybe<boolean>
    faleceu?: Maybe<boolean>
    possuiAgendamento?: Maybe<boolean>
    unificado?: Maybe<boolean>
    tipoSanguineo?: Maybe<TipoSanguineoEnum>
    orientacaoSexualDbEnum?: Maybe<OrientacaoSexualEnum>
    portariaNaturalizacao?: Maybe<string>
    dataNaturalizacao?: Maybe<any>
    dataEntradaBrasil?: Maybe<any>
    stCompartilhaProntuario?: Maybe<boolean>
    endereco?: Maybe<{
      __typename?: 'Endereco'
      cep?: Maybe<string>
      bairro?: Maybe<string>
      logradouro?: Maybe<string>
      numero?: Maybe<string>
      semNumero: boolean
      complemento?: Maybe<string>
      pontoReferencia?: Maybe<string>
      uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string }>
      municipio?: Maybe<{ __typename?: 'Municipio'; id: string; nome: string }>
      tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string }>
    }>
    etnia?: Maybe<{ __typename?: 'Etnia'; id: string; nome: string }>
    racaCor: { __typename?: 'RacaCor'; id: string; nome: string }
    cbo?: Maybe<{ __typename?: 'Cbo'; id: string; nome: string }>
    escolaridade?: Maybe<{ __typename?: 'Escolaridade'; id: string; nome: string }>
    localidadeNascimento?: Maybe<{
      __typename?: 'Municipio'
      id: string
      nome: string
      uf: { __typename?: 'UF'; id: string; sigla: string }
    }>
    prontuario?: Maybe<{ __typename?: 'Prontuario'; id?: Maybe<string> }>
    cidadaoVinculacaoEquipe?: Maybe<{
      __typename?: 'CidadaoVinculacaoEquipe'
      id?: Maybe<string>
      tpCdsOrigem?: Maybe<CdsOrigemEnum>
      unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
      equipe?: Maybe<{ __typename?: 'Equipe'; id: string; nome: string; ine: string }>
    }>
    estadoCivil?: Maybe<{ __typename?: 'EstadoCivil'; id: string; nome: string }>
    paisExterior?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
    nacionalidade?: Maybe<{ __typename?: 'Nacionalidade'; id: string; nacionalidadeDbEnum: NacionalidadeEnum }>
    paisNascimento?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
  }>
}

export type CidadaoCompartilhamentoProntuarioQueryVariables = {}

export type CidadaoCompartilhamentoProntuarioQuery = {
  __typename?: 'Query'
  compartilhamentoProntuario?: Maybe<{ __typename?: 'CompartilhamentoProntuario'; id: string; ativo: boolean }>
}

export type CidadaoEditQueryVariables = {
  id: Scalars['ID']
}

export type CidadaoEditQuery = {
  __typename?: 'Query'
  cidadao?: Maybe<{
    __typename?: 'Cidadao'
    id: string
    cpf?: Maybe<string>
    cns?: Maybe<string>
    nisPisPasep?: Maybe<string>
    nome: string
    nomeSocial?: Maybe<string>
    dataNascimento: any
    dataObito?: Maybe<any>
    numeroDocumentoObito?: Maybe<string>
    sexo: SexoEnum
    desconheceNomeMae?: Maybe<boolean>
    nomeMae?: Maybe<string>
    desconheceNomePai?: Maybe<boolean>
    nomePai?: Maybe<string>
    telefoneResidencial?: Maybe<string>
    telefoneCelular?: Maybe<string>
    telefoneContato?: Maybe<string>
    email?: Maybe<string>
    area?: Maybe<string>
    microArea?: Maybe<string>
    statusForaArea?: Maybe<boolean>
    faleceu?: Maybe<boolean>
    tipoSanguineo?: Maybe<TipoSanguineoEnum>
    desejaInformarIdentidadeGenero?: Maybe<boolean>
    identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
    desejaInformarOrientacaoSexual?: Maybe<boolean>
    orientacaoSexualDbEnum?: Maybe<OrientacaoSexualEnum>
    localidadeExterior?: Maybe<string>
    portariaNaturalizacao?: Maybe<string>
    dataNaturalizacao?: Maybe<any>
    dataEntradaBrasil?: Maybe<any>
    stCompartilhaProntuario?: Maybe<boolean>
    uuidUltimaFicha?: Maybe<string>
    endereco?: Maybe<{
      __typename?: 'Endereco'
      bairro?: Maybe<string>
      cep?: Maybe<string>
      complemento?: Maybe<string>
      logradouro?: Maybe<string>
      numero?: Maybe<string>
      pontoReferencia?: Maybe<string>
      semNumero: boolean
      municipio?: Maybe<{
        __typename?: 'Municipio'
        id: string
        ibge: string
        nome: string
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
      }>
      tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
      uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    }>
    etnia?: Maybe<{ __typename?: 'Etnia'; id: string; nome: string }>
    racaCor: { __typename?: 'RacaCor'; id: string; nome: string; racaCorDbEnum: RacaCorDbEnum }
    cbo?: Maybe<{ __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }>
    escolaridade?: Maybe<{ __typename?: 'Escolaridade'; id: string; nome: string }>
    localidadeNascimento?: Maybe<{
      __typename?: 'Municipio'
      id: string
      nome: string
      uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
    }>
    estadoCivil?: Maybe<{ __typename?: 'EstadoCivil'; id: string; nome: string }>
    paisExterior?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
    nacionalidade?: Maybe<{ __typename?: 'Nacionalidade'; id: string; nacionalidadeDbEnum: NacionalidadeEnum }>
    paisNascimento?: Maybe<{ __typename?: 'Pais'; id: string; nome: string }>
    cidadaoVinculacaoEquipe?: Maybe<{
      __typename?: 'CidadaoVinculacaoEquipe'
      id?: Maybe<string>
      utilizarCadastroIndividual?: Maybe<boolean>
      unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }>
      equipe?: Maybe<{
        __typename?: 'Equipe'
        id: string
        nome: string
        ine: string
        tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
      }>
    }>
  }>
}

export type HasCidadaoAgendamentoFuturoQueryVariables = {
  input: CidadaoPossuiAgendamentoFuturoQueryInput
}

export type HasCidadaoAgendamentoFuturoQuery = { __typename?: 'Query'; hasAgendamentoFuturo?: Maybe<boolean> }

export type SalvarCidadaoMutationVariables = {
  input: CidadaoInput
}

export type SalvarCidadaoMutation = {
  __typename?: 'Mutation'
  salvarCidadao: { __typename?: 'Cidadao'; id: string; nome: string }
}

export type UnificacoesCidadaoQueryVariables = {
  id: Scalars['ID']
}

export type UnificacoesCidadaoQuery = {
  __typename?: 'Query'
  unificacoesCidadao?: Maybe<
    Array<
      Maybe<{
        __typename?: 'UnificacoesCidadao'
        id: string
        nomeProfissional?: Maybe<string>
        dataUnificacao?: Maybe<any>
        cidadaos?: Maybe<
          Array<
            Maybe<{
              __typename?: 'Cidadao'
              id: string
              cpf?: Maybe<string>
              cns?: Maybe<string>
              nome: string
              nomeSocial?: Maybe<string>
              dataNascimento: any
              identidadeGeneroDbEnum?: Maybe<IdentidadeGeneroEnum>
              sexo: SexoEnum
              nomeMae?: Maybe<string>
              localidadeNascimento?: Maybe<{
                __typename?: 'Municipio'
                id: string
                nome: string
                uf: { __typename?: 'UF'; id: string; sigla: string }
              }>
            }>
          >
        >
      }>
    >
  >
}

export type ImportacoesCnesQueryVariables = {
  input: ImportacoesCnesQueryInput
}

export type ImportacoesCnesQuery = {
  __typename?: 'Query'
  importacoesCnes: {
    __typename?: 'ImportacoesCnesQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'ImportacaoCnes'
        id: string
        dataImportacao: any
        equipesNovas?: Maybe<number>
        equipesAtualizadas?: Maybe<number>
        lotacoesNovas?: Maybe<number>
        lotacoesAtualizadas?: Maybe<number>
        profissionaisNovos?: Maybe<number>
        profissionaisAtualizados?: Maybe<number>
        unidadesSaudeNovas?: Maybe<number>
        unidadesSaudeAtualizadas?: Maybe<number>
        processo: { __typename?: 'Processo'; id: string; status: StatusProcesso }
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AlterarQtdRequisicoesMutationVariables = {
  qtdRequisicoesSimultaneas?: Maybe<Scalars['Int']>
}

export type AlterarQtdRequisicoesMutation = {
  __typename?: 'Mutation'
  alterarQtdRequisicoes?: Maybe<{ __typename?: 'ConfiguracaoPerformance'; qtdRequisicoesSimultaneas?: Maybe<number> }>
}

export type QuantidadeRequisicoesQueryVariables = {}

export type QuantidadeRequisicoesQuery = {
  __typename?: 'Query'
  avancada: {
    __typename?: 'ConfiguracaoAvancada'
    performance: { __typename?: 'ConfiguracaoPerformance'; qtdRequisicoesSimultaneas?: Maybe<number> }
  }
}

export type GerarChaveAtivacaoAgendaOnlineMutationVariables = {}

export type GerarChaveAtivacaoAgendaOnlineMutation = { __typename?: 'Mutation'; gerarChaveAtivacaoAgendaOnline: string }

export type AtivarAgendaOnlineMutationVariables = {
  ativacaoAgendaOnlineInput: AtivacaoAgendaOnlineInput
}

export type AtivarAgendaOnlineMutation = {
  __typename?: 'Mutation'
  ativarAgendaOnline?: Maybe<{
    __typename?: 'AgendaOnline'
    ativa: boolean
    detalhesAtivacao?: Maybe<{
      __typename?: 'DetalhesAtivacao'
      dataEvento: any
      profissional?: Maybe<{ __typename?: 'Profissional'; nome: string }>
    }>
  }>
}

export type TestarConexaoAgendaOnlineMutationVariables = {}

export type TestarConexaoAgendaOnlineMutation = {
  __typename?: 'Mutation'
  testarConexaoAgendaOnline: { __typename?: 'TesteConexao'; sucesso: boolean; dataEvento: any }
}

export type DesativarAgendaOnlineMutationVariables = {}

export type DesativarAgendaOnlineMutation = {
  __typename?: 'Mutation'
  desativarAgendaOnline?: Maybe<{
    __typename?: 'AgendaOnline'
    ativa: boolean
    detalhesAtivacao?: Maybe<{
      __typename?: 'DetalhesAtivacao'
      dataEvento: any
      profissional?: Maybe<{ __typename?: 'Profissional'; nome: string }>
    }>
  }>
}

export type AlterarAtestadoDigitalMutationVariables = {
  alterarAtestadoDigitalInput: AlterarAtestadoDigitalInput
}

export type AlterarAtestadoDigitalMutation = {
  __typename?: 'Mutation'
  alterarAtestadoDigital?: Maybe<{
    __typename?: 'ConfiguracaoAtestadoDigital'
    id: string
    habilitado: boolean
    intervalo?: Maybe<number>
    desabilitadoAteData?: Maybe<any>
  }>
}

export type AlterarCadsusMutationVariables = {
  alterarCadsusInput: AlterarCadsusInput
}

export type AlterarCadsusMutation = {
  __typename?: 'Mutation'
  alterarCadsus?: Maybe<{
    __typename?: 'ConfiguracaoCadsus'
    id: string
    habilitado: boolean
    intervalo?: Maybe<number>
    desabilitadoAteData?: Maybe<any>
  }>
}

export type AlterarHorusMutationVariables = {
  alterarHorusInput: AlterarHorusInput
}

export type AlterarHorusMutation = {
  __typename?: 'Mutation'
  alterarHorus?: Maybe<{
    __typename?: 'ConfiguracaoHorus'
    id: string
    habilitado: boolean
    intervalo?: Maybe<number>
    desabilitadoAteData?: Maybe<any>
  }>
}

export type MunicipiosResponsaveisQueryVariables = {
  input: MunicipioResponsavelFiltroInput
}

export type MunicipiosResponsaveisQuery = {
  __typename?: 'Query'
  municipiosResponsaveis: {
    __typename?: 'MunicipioResponsavelQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'AdministradorMunicipal'
        id: string
        habilitado: boolean
        ativo: boolean
        dataAdicao?: Maybe<any>
        dataInativacao?: Maybe<any>
        profissional: { __typename?: 'Profissional'; id: string; nome: string }
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; sigla: string }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ForcarRedefinirSenhasMutationVariables = {}

export type ForcarRedefinirSenhasMutation = {
  __typename?: 'Mutation'
  forcarRedefinirSenhas?: Maybe<{ __typename?: 'ConfiguracaoRedefinicaoSenha'; redefinicaoSenha?: Maybe<number> }>
}

export type AlterarDadosServidorInstalacaoMutationVariables = {
  input: ConfiguracaoDadosServidorInput
}

export type AlterarDadosServidorInstalacaoMutation = {
  __typename?: 'Mutation'
  alterarDadosServidor?: Maybe<{
    __typename?: 'ConfiguracaoDadosServidorFormDto'
    nomeInstalacao?: Maybe<string>
    linkInstalacao?: Maybe<string>
  }>
}

export type AlterarServidorSmtpMutationVariables = {
  input: ConfiguracaoSmtpInput
}

export type AlterarServidorSmtpMutation = {
  __typename?: 'Mutation'
  alterarServidorSMTP?: Maybe<{
    __typename?: 'ServidorSMTP'
    email?: Maybe<string>
    senha?: Maybe<string>
    usuario?: Maybe<string>
    endereco?: Maybe<string>
    porta?: Maybe<string>
  }>
}

export type DesabilitarConexaoSmtpMutationVariables = {}

export type DesabilitarConexaoSmtpMutation = { __typename?: 'Mutation'; desabilitarConexaoSMTP: boolean }

export type DadosServidoresQueryVariables = {}

export type DadosServidoresQuery = {
  __typename?: 'Query'
  servidores: {
    __typename?: 'ConfiguracaoServidoresFormDto'
    internet: { __typename?: 'ConfiguracaoInternet'; habilitado: boolean }
    dadosServidor?: Maybe<{
      __typename?: 'ConfiguracaoDadosServidorFormDto'
      nomeInstalacao?: Maybe<string>
      linkInstalacao?: Maybe<string>
    }>
    servidorSMTP?: Maybe<{
      __typename?: 'ServidorSMTP'
      email?: Maybe<string>
      senha?: Maybe<string>
      usuario?: Maybe<string>
      endereco?: Maybe<string>
      porta?: Maybe<string>
      ativo?: Maybe<boolean>
      dataRegistro?: Maybe<any>
    }>
  }
}

export type ConfiguracaoAgendaMunicipioQueryVariables = {}

export type ConfiguracaoAgendaMunicipioQuery = {
  __typename?: 'Query'
  duracaoAtendimentoPadraoMunicipio?: Maybe<{
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }>
  configuracaoHorariosMunicipio: {
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }
}

export type SalvarConfiguracaoHorariosMunicipioMutationVariables = {
  configuracaoAgendaMunicipalInput?: Maybe<ConfiguracaoAgendaMunicipalInput>
}

export type SalvarConfiguracaoHorariosMunicipioMutation = {
  __typename?: 'Mutation'
  salvarConfiguracaoHorariosMunicipio?: Maybe<{
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }>
}

export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutationVariables = {
  duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput
}

export type SalvarConfiguracaoDuracaoAtendimentoMunicipioMutation = {
  __typename?: 'Mutation'
  alterarDuracaoPadraoAtendimento: {
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }
}

export type GrupoExamesQueryVariables = {
  id: Scalars['ID']
}

export type GrupoExamesQuery = {
  __typename?: 'Query'
  grupoExame: {
    __typename?: 'GrupoExame'
    id: string
    nome: string
    procedimentos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Procedimento'
          id: string
          descricao: string
          codigo: string
          descricaoReferencia?: Maybe<string>
          codigoReferencia?: Maybe<string>
        }>
      >
    >
  }
}

export type GrupoExameSelectTableQueryVariables = {
  input: ProcedimentoQueryInput
}

export type GrupoExameSelectTableQuery = {
  __typename?: 'Query'
  procedimentosGrupoExame: {
    __typename?: 'ProcedimentosQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Procedimento'
        id: string
        descricao: string
        codigo: string
        descricaoReferencia?: Maybe<string>
        codigoReferencia?: Maybe<string>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type GrupoExameTableQueryVariables = {
  input: GrupoExameQueryInput
}

export type GrupoExameTableQuery = {
  __typename?: 'Query'
  gruposExame: {
    __typename?: 'GruposExameQueryPayload'
    content: Array<
      Maybe<{ __typename?: 'GrupoExame'; id: string; nome: string; quantidadeProcedimentos?: Maybe<number> }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CompartilhamentoProntuarioQueryVariables = {}

export type CompartilhamentoProntuarioQuery = {
  __typename?: 'Query'
  compartilhamentoProntuario?: Maybe<{
    __typename?: 'CompartilhamentoProntuario'
    id: string
    ativo: boolean
    dataUltimaAlteracao: any
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
  }>
}

export type SaveCompartilhamentoProntuarioMutationVariables = {
  compartilhamentoProntuarioInput: CompartilhamentoProntuarioInput
}

export type SaveCompartilhamentoProntuarioMutation = {
  __typename?: 'Mutation'
  saveCompartilhamentoProntuario?: Maybe<{
    __typename?: 'CompartilhamentoProntuario'
    id: string
    ativo: boolean
    dataUltimaAlteracao: any
    profissional: { __typename?: 'Profissional'; id: string; nome: string }
  }>
}

export type TipoServicoTableQueryVariables = {
  input: TipoServicoQueryInput
}

export type TipoServicoTableQuery = {
  __typename?: 'Query'
  tiposServico: {
    __typename?: 'TipoServicoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'TipoServico'
        id: string
        nome: string
        municipio?: Maybe<{ __typename?: 'Municipio'; id: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AlterarUnidadePadraoHorusMutationVariables = {
  input: UnidadeConsultaHorusIds
}

export type AlterarUnidadePadraoHorusMutation = {
  __typename?: 'Mutation'
  alterarUnidadePadraoHorus: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
}

export type CadastroUnidadeConsultaHorusMutationVariables = {
  input: UnidadeConsultaHorusIds
}

export type CadastroUnidadeConsultaHorusMutation = {
  __typename?: 'Mutation'
  adicionarUnidadeConsultaHorus: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
}

export type HorusListViewQueryVariables = {
  unidadeSaudeId: Scalars['ID']
}

export type HorusListViewQuery = {
  __typename?: 'Query'
  unidadesConsultaHorus: Array<
    Maybe<{
      __typename?: 'HorusFormDto'
      unidadePadrao?: Maybe<boolean>
      unidadeSaude?: Maybe<{ __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }>
    }>
  >
  unidadeSaude: {
    __typename?: 'UnidadeSaude'
    id: string
    actions: {
      __typename?: 'UnidadeSaudeActions'
      permissaoConexaoHorus: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
    }
  }
  conexao: {
    __typename?: 'ConfiguracaoConexao'
    horus: { __typename?: 'ConfiguracaoHorus'; id: string; habilitado: boolean }
  }
}

export type RemoverUnidadeConsultaHorusMutationVariables = {
  input: UnidadeConsultaHorusIds
}

export type RemoverUnidadeConsultaHorusMutation = {
  __typename?: 'Mutation'
  removerUnidadeConsultaHorus: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
}

export type InstalarMutationVariables = {
  input: InstalacaoInput
}

export type InstalarMutation = { __typename?: 'Mutation'; instalar: boolean }

export type LoginMutationVariables = {
  input: LoginInput
}

export type LoginMutation = { __typename?: 'Mutation'; login: boolean }

export type ImunobiologicoComboQueryVariables = {
  input: ImunobiologicoQueryInput
}

export type ImunobiologicoComboQuery = {
  __typename?: 'Query'
  imunobiologicos: {
    __typename?: 'ImunobiologicoQueryPayload'
    content: Array<Maybe<{ __typename?: 'Imunobiologico'; id: string; sigla: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ExcluirLoteImunobiologicoMutationVariables = {
  idLote: Scalars['ID']
}

export type ExcluirLoteImunobiologicoMutation = { __typename?: 'Mutation'; excluirLoteImunobiologico?: Maybe<string> }

export type LoteImunobiologicoFabricanteQueryVariables = {
  input: ImunobiologicoFabricanteQueryInput
}

export type LoteImunobiologicoFabricanteQuery = {
  __typename?: 'Query'
  fabricantesImunobiologico: {
    __typename?: 'ImunobiologicoFabricanteQueryPayload'
    content: Array<Maybe<{ __typename?: 'ImunobiologicoFabricante'; id: string; nome: string }>>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type LoteImunobiologicoQueryVariables = {
  input: LoteImunobiologicoQueryInput
}

export type LoteImunobiologicoQuery = {
  __typename?: 'Query'
  lotesImunobiologico: {
    __typename?: 'LotesImunobiologicoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'LoteImunobiologico'
        id: string
        lote?: Maybe<string>
        validade?: Maybe<any>
        ativo?: Maybe<boolean>
        fabricante?: Maybe<{ __typename?: 'ImunobiologicoFabricante'; id: string; nome: string }>
        imunobiologico?: Maybe<{ __typename?: 'Imunobiologico'; id: string; nome: string; sigla: string }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type SetAtivadoLoteImunobiologicoMutationVariables = {
  idLote: Scalars['ID']
  ativado?: Maybe<Scalars['Boolean']>
}

export type SetAtivadoLoteImunobiologicoMutation = {
  __typename?: 'Mutation'
  setAtivadoLoteImunobiologico?: Maybe<string>
}

export type SalvarLoteImunobiologicoMutationVariables = {
  input: LoteImunobiologicoInput
}

export type SalvarLoteImunobiologicoMutation = {
  __typename?: 'Mutation'
  salvarLoteImunobiologico?: Maybe<{ __typename?: 'LoteImunobiologico'; id: string }>
}

export type VisualizouNovidadesMutationVariables = {}

export type VisualizouNovidadesMutation = { __typename?: 'Mutation'; visualizarNovidades: boolean }

export type VisualizarNovidadesQueryVariables = {}

export type VisualizarNovidadesQuery = {
  __typename?: 'Query'
  sessao?: Maybe<{
    __typename?: 'Sessao'
    id: string
    profissional: {
      __typename?: 'Profissional'
      id: string
      usuario: { __typename?: 'Usuario'; id: string; visualizouNovidades: boolean }
    }
  }>
}

export type AtivarPerfilMutationVariables = {
  id: Scalars['ID']
}

export type AtivarPerfilMutation = {
  __typename?: 'Mutation'
  ativarPerfil: { __typename?: 'Perfil'; id: string; ativo: boolean }
}

export type CriarPerfilMutationVariables = {
  input: PerfilInput
}

export type CriarPerfilMutation = {
  __typename?: 'Mutation'
  criarPerfil: { __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }
}

export type ExcluirPerfilMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirPerfilMutation = {
  __typename?: 'Mutation'
  excluirPerfil: { __typename?: 'ExcluirPerfilPayload'; id: string }
}

export type InativarPerfilMutationVariables = {
  id: Scalars['ID']
}

export type InativarPerfilMutation = {
  __typename?: 'Mutation'
  inativarPerfil: { __typename?: 'Perfil'; id: string; ativo: boolean }
}

export type RecursosQueryVariables = {
  tipoAcesso: TipoAcesso
}

export type RecursosQuery = { __typename?: 'Query'; recursos: Array<string> }

export type AtualizarPerfilMutationVariables = {
  input: PerfilInput
}

export type AtualizarPerfilMutation = {
  __typename?: 'Mutation'
  atualizarPerfil: { __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }
}

export type PerfilEditQueryVariables = {
  perfilId: Scalars['ID']
}

export type PerfilEditQuery = {
  __typename?: 'Query'
  perfil?: Maybe<{
    __typename?: 'Perfil'
    id: string
    nome: string
    tipoPerfil: TipoAcesso
    recursos: Array<Maybe<string>>
    perfilPadrao?: Maybe<string>
  }>
}

export type PerfilTableQueryVariables = {
  input: PerfisQueryInput
}

export type PerfilTableQuery = {
  __typename?: 'Query'
  perfis: {
    __typename?: 'PerfisQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Perfil'
        id: string
        nome: string
        ativo: boolean
        tipoPerfil: TipoAcesso
        perfilPadrao?: Maybe<string>
        actions: {
          __typename?: 'PerfilActions'
          excluir: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ResponderPesquisaSatisfacaoMutationVariables = {
  input: RespostaPesquisa
}

export type ResponderPesquisaSatisfacaoMutation = {
  __typename?: 'Mutation'
  responderPesquisaSatisfacao: { __typename?: 'Usuario'; id: string; mostrarPesquisaSatisfacao: boolean }
}

export type ProfissionalEditQueryVariables = {
  id: Scalars['ID']
}

export type ProfissionalEditQuery = {
  __typename?: 'Query'
  profissional: {
    __typename?: 'Profissional'
    id: string
    nome: string
    numeroConselhoClasse?: Maybe<string>
    sexo?: Maybe<string>
    telefone?: Maybe<string>
    cns?: Maybe<string>
    cpf: string
    dataNascimento?: Maybe<any>
    email?: Maybe<string>
    conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; descricao?: Maybe<string>; sigla: string }>
    ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    endereco?: Maybe<{
      __typename?: 'Endereco'
      bairro?: Maybe<string>
      cep?: Maybe<string>
      complemento?: Maybe<string>
      logradouro?: Maybe<string>
      numero?: Maybe<string>
      pontoReferencia?: Maybe<string>
      semNumero: boolean
      municipio?: Maybe<{
        __typename?: 'Municipio'
        id: string
        ibge: string
        nome: string
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
      }>
      tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
      uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
    }>
    lotacoes?: Maybe<Array<{ __typename?: 'Lotacao'; id: string; ativo: boolean }>>
  }
}

export type SalvarProfissionalMutationVariables = {
  input: ProfissionalInput
}

export type SalvarProfissionalMutation = {
  __typename?: 'Mutation'
  salvarProfissional: { __typename?: 'Profissional'; id: string; nome: string }
}

export type ConfiguracaoAgendaLotacaoQueryVariables = {
  lotacaoId: Scalars['ID']
}

export type ConfiguracaoAgendaLotacaoQuery = {
  __typename?: 'Query'
  lotacao: {
    __typename?: 'Lotacao'
    id: string
    hasConfiguracaoAgenda?: Maybe<boolean>
    hasConfiguracaoAgendaOnline?: Maybe<boolean>
    hasAgendamentoFuturo?: Maybe<boolean>
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    equipe?: Maybe<{ __typename?: 'Equipe'; id: string; ine: string; nome: string }>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
    profissional: { __typename?: 'Profissional'; nome: string }
    configuracaoAgenda?: Maybe<{
      __typename?: 'ConfiguracaoAgendaLotacao'
      configuracaoHorarioAgenda: {
        __typename?: 'ConfiguracaoHorarioAgenda'
        configuracaoDiasSemana?: Maybe<
          Array<{
            __typename?: 'ConfiguracaoDiaSemana'
            diaSemana: DiaSemanaEnum
            periodos?: Maybe<
              Array<{
                __typename?: 'ConfiguracaoPeriodo'
                periodo: PeriodoEnum
                horarioInicial?: Maybe<string>
                horarioFinal?: Maybe<string>
              }>
            >
          }>
        >
      }
    }>
  }
  conexao: { __typename?: 'ConfiguracaoConexao'; agendaOnline?: Maybe<{ __typename?: 'AgendaOnline'; ativa: boolean }> }
  duracaoAtendimentoPadraoMunicipio?: Maybe<{
    __typename?: 'DuracaoAtendimentoPadraoMunicipio'
    duracaoAtendimentoPadraoMunicipio: number
  }>
  configuracaoHorariosMunicipio: {
    __typename?: 'ConfiguracaoAgendaMunicipal'
    id: string
    configuracaoHorarioAgenda: {
      __typename?: 'ConfiguracaoHorarioAgenda'
      configuracaoDiasSemana?: Maybe<
        Array<{
          __typename?: 'ConfiguracaoDiaSemana'
          diaSemana: DiaSemanaEnum
          periodos?: Maybe<
            Array<{
              __typename?: 'ConfiguracaoPeriodo'
              periodo: PeriodoEnum
              horarioInicial?: Maybe<string>
              horarioFinal?: Maybe<string>
            }>
          >
        }>
      >
    }
  }
}

export type ProfissionalAcessosQueryVariables = {
  profissionalId: Scalars['ID']
}

export type ProfissionalAcessosQuery = {
  __typename?: 'Query'
  profissional: {
    __typename?: 'Profissional'
    id: string
    cns?: Maybe<string>
    acessos?: Maybe<
      Array<
        | {
            __typename?: 'Lotacao'
            importada: boolean
            hasConfiguracaoAgenda?: Maybe<boolean>
            hasConfiguracaoAgendaOnline?: Maybe<boolean>
            hasAgendamentoFuturo?: Maybe<boolean>
            id: string
            tipo: TipoAcesso
            ativo: boolean
            cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
            equipe?: Maybe<{
              __typename?: 'Equipe'
              id: string
              nome: string
              ine: string
              tipoEquipe: { __typename?: 'TipoEquipe'; id: string; descricao?: Maybe<string> }
            }>
            unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string> }
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'LotacaoActions'
              permissaoConfigurarAgenda: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoConfigurarAgendaOnline: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoFecharAgenda: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'AdministradorGeral'
            id: string
            tipo: TipoAcesso
            ativo: boolean
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'AdministradorMunicipal'
            id: string
            tipo: TipoAcesso
            ativo: boolean
            municipio: { __typename?: 'Municipio'; id: string; nome: string }
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'GestorEstadual'
            id: string
            tipo: TipoAcesso
            ativo?: Maybe<boolean>
            uf: { __typename?: 'UF'; id: string; nome: string }
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
        | {
            __typename?: 'GestorMunicipal'
            id: string
            tipo: TipoAcesso
            ativo?: Maybe<boolean>
            municipio: { __typename?: 'Municipio'; id: string; nome: string }
            perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
            actions: {
              __typename?: 'AcessoActions'
              permissaoEdicao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoExclusao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
              permissaoAtivacaoInativacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
            }
          }
      >
    >
  }
}

export type ProfissionalDetailFragment = {
  __typename?: 'Profissional'
  cns?: Maybe<string>
  cpf: string
  dataNascimento?: Maybe<any>
  email?: Maybe<string>
  id: string
  nome: string
  numeroConselhoClasse?: Maybe<string>
  sexo?: Maybe<string>
  telefone?: Maybe<string>
  conselhoClasse?: Maybe<{ __typename?: 'ConselhoClasse'; id: string; descricao?: Maybe<string>; sigla: string }>
  endereco?: Maybe<{
    __typename?: 'Endereco'
    bairro?: Maybe<string>
    cep?: Maybe<string>
    complemento?: Maybe<string>
    logradouro?: Maybe<string>
    numero?: Maybe<string>
    pontoReferencia?: Maybe<string>
    semNumero: boolean
    municipio?: Maybe<{
      __typename?: 'Municipio'
      id: string
      ibge: string
      nome: string
      uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
    }>
    tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; id: string; nome: string; numeroDne: string }>
    uf?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
  }>
  ufEmissoraConselhoClasse?: Maybe<{ __typename?: 'UF'; id: string; nome: string; sigla: string }>
}

export type ProfissionalDetailQueryVariables = {
  id: Scalars['ID']
}

export type ProfissionalDetailQuery = {
  __typename?: 'Query'
  profissional: {
    __typename?: 'Profissional'
    lotacoes?: Maybe<
      Array<{
        __typename?: 'Lotacao'
        ativo: boolean
        id: string
        cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
        equipe?: Maybe<{
          __typename?: 'Equipe'
          id: string
          ine: string
          nome: string
          tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
        }>
        unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
      }>
    >
  } & ProfissionalDetailFragment
}

export type AgendaEmConflitoQueryVariables = {
  id: Scalars['ID']
}

export type AgendaEmConflitoQuery = { __typename?: 'Query'; agendaEmConflito: boolean }

export type AcessoEditQueryVariables = {
  acessoId: Scalars['ID']
  profissionalId: Scalars['ID']
}

export type AcessoEditQuery = {
  __typename?: 'Query'
  acesso:
    | {
        __typename?: 'Lotacao'
        tipo: TipoAcesso
        actions: { __typename?: 'LotacaoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
    | {
        __typename?: 'AdministradorGeral'
        tipo: TipoAcesso
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
    | {
        __typename?: 'AdministradorMunicipal'
        tipo: TipoAcesso
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
    | {
        __typename?: 'GestorEstadual'
        tipo: TipoAcesso
        uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
    | {
        __typename?: 'GestorMunicipal'
        tipo: TipoAcesso
        municipio: {
          __typename?: 'Municipio'
          id: string
          nome: string
          uf: { __typename?: 'UF'; id: string; nome: string; sigla: string }
        }
        actions: { __typename?: 'AcessoActions'; permissaoEdicao: { __typename?: 'Action'; enabled: boolean } }
        perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
      }
  profissional: { __typename?: 'Profissional'; id: string; nome: string }
}

export type AlterarLotacaoMutationVariables = {
  input: AlterarLotacaoInput
}

export type AlterarLotacaoMutation = { __typename?: 'Mutation'; alterarLotacao: { __typename?: 'Lotacao'; id: string } }

export type CriarLotacaoMutationVariables = {
  input: LotacaoInput
}

export type CriarLotacaoMutation = { __typename?: 'Mutation'; criarLotacao: { __typename?: 'Lotacao'; id: string } }

export type LotacaoEditViewQueryVariables = {
  id: Scalars['ID']
}

export type LotacaoEditViewQuery = {
  __typename?: 'Query'
  lotacao: {
    __typename?: 'Lotacao'
    id: string
    importada: boolean
    hasConfiguracaoAgenda?: Maybe<boolean>
    atualizaPerfil?: Maybe<boolean>
    ativo: boolean
    profissional: { __typename?: 'Profissional'; nome: string }
    cbo: { __typename?: 'Cbo'; id: string; nome: string; cbo2002: string }
    equipe?: Maybe<{
      __typename?: 'Equipe'
      id: string
      nome: string
      ine: string
      tipoEquipe: { __typename?: 'TipoEquipe'; id: string; nome: string; sigla: string }
    }>
    perfis: Array<Maybe<{ __typename?: 'Perfil'; id: string; nome: string; ativo: boolean }>>
    unidadeSaude: { __typename?: 'UnidadeSaude'; id: string; nome?: Maybe<string>; cnes: string }
  }
}

export type ProfissionalTableQueryVariables = {
  input: ProfissionaisQueryInput
}

export type ProfissionalTableQuery = {
  __typename?: 'Query'
  profissionais: {
    __typename?: 'ProfissionaisQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'Profissional'
        id: string
        nome: string
        cpf: string
        cns?: Maybe<string>
        lotacoes?: Maybe<
          Array<{
            __typename?: 'Lotacao'
            id: string
            ativo: boolean
            cbo: { __typename?: 'Cbo'; id: string; nome: string }
          }>
        >
        usuario: { __typename?: 'Usuario'; id: string; bloqueado: boolean }
        actions: {
          __typename?: 'ProfissionalActions'
          redefinirSenha: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type InfoProfissionalCpfQueryVariables = {
  cpf: Scalars['String']
}

export type InfoProfissionalCpfQuery = {
  __typename?: 'Query'
  profissionalByCpf?: Maybe<{ __typename?: 'Profissional'; id: string }>
}

export type ResetSenhaAdminMutationVariables = {
  input: ResetSenhaAdminInput
}

export type ResetSenhaAdminMutation = { __typename?: 'Mutation'; resetSenhaAdministrador: boolean }

export type SaveAdminProfissionalMutationVariables = {
  input: ProfissionalContraChaveInput
}

export type SaveAdminProfissionalMutation = {
  __typename?: 'Mutation'
  salvarProfissionalContraChave: { __typename?: 'Profissional'; id: string }
}

export type ValidateNovoAdminQueryVariables = {
  input: ValidateNovoAdminInput
}

export type ValidateNovoAdminQuery = { __typename?: 'Query'; validatePermissaoNovoAdmin: boolean }

export type ValidateResetAdminQueryVariables = {
  input: ResetSenhaAdminInput
}

export type ValidateResetAdminQuery = {
  __typename?: 'Query'
  validateResetAdmin: { __typename?: 'ValidateResetSenhaAdminPayload'; possuiCns?: Maybe<boolean> }
}

export type AlteraAdminMutationVariables = {
  input: ValidateNovoAdminInput
}

export type AlteraAdminMutation = { __typename?: 'Mutation'; denifinirNovoAdministrador?: Maybe<boolean> }

export type ChaveByCpfQueryVariables = {
  cpf: Scalars['String']
}

export type ChaveByCpfQuery = { __typename?: 'Query'; chaveByCpf: string }

export type ProfissionalAdminDetailQueryVariables = {
  cpf: Scalars['String']
}

export type ProfissionalAdminDetailQuery = {
  __typename?: 'Query'
  profissionalByCpf?: Maybe<
    { __typename?: 'Profissional'; usuario: { __typename?: 'Usuario'; id: string } } & ProfissionalDetailFragment
  >
}

export type SobreQueryVariables = {}

export type SobreQuery = {
  __typename?: 'Query'
  info: {
    __typename?: 'Info'
    commit: string
    competenciaSigtap: string
    databaseVendor: string
    databaseSchemaVersion: string
    recursosDisponiveis: string
    versao: string
    internetHabilitada: boolean
    smtpConfigurado: boolean
    linkInstalacaoConfigurado: boolean
  }
}

export type AlternarTransmissaoLinkMutationVariables = {
  id: Scalars['ID']
}

export type AlternarTransmissaoLinkMutation = { __typename?: 'Mutation'; alternarTransmissaoLink: string }

export type ExcluirTransmissaoLinkMutationVariables = {
  id: Scalars['ID']
}

export type ExcluirTransmissaoLinkMutation = { __typename?: 'Mutation'; excluirTransmissaoLink: string }

export type InserirTransmissaoLinkMutationVariables = {
  form: TransmissaoLinkInsertDto
}

export type InserirTransmissaoLinkMutation = { __typename?: 'Mutation'; inserirTransmissaoLink: string }

export type TransmissaoLinkQueryVariables = {
  input: TransmissaoLinkPagingQueryFiltroDto
}

export type TransmissaoLinkQuery = {
  __typename?: 'Query'
  transmissaoLink: {
    __typename?: 'TransmissaoLinksPayload'
    content: Array<
      Maybe<{
        __typename?: 'TransmissaoLink'
        id: string
        hostname: string
        name: string
        ativo: boolean
        statusConexao?: Maybe<boolean>
        dataTesteConexao?: Maybe<any>
        actions: {
          __typename?: 'TransmissaoLinkActions'
          alternarAtivacao: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
          excluir: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
          editar: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TestarConexaoLinkTransmissaoMutationVariables = {
  id: Scalars['ID']
}

export type TestarConexaoLinkTransmissaoMutation = {
  __typename?: 'Mutation'
  testarConexaoLinkTransmissao: {
    __typename?: 'TransmissaoLink'
    id: string
    hostname: string
    name: string
    ativo: boolean
    statusConexao?: Maybe<boolean>
    dataTesteConexao?: Maybe<any>
  }
}

export type UpdateTransmissaoLinkMutationVariables = {
  form: TransmissaoLinkUpdateDto
}

export type UpdateTransmissaoLinkMutation = { __typename?: 'Mutation'; updateTransmissaoLink: string }

export type TransmissaoRecebimentoLotesQueryVariables = {
  input: TransmissaoRecebimentoLoteFiltro
}

export type TransmissaoRecebimentoLotesQuery = {
  __typename?: 'Query'
  transmissaoRecebimentoLotes: {
    __typename?: 'TransmissaoRecebimentoQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'TransmissaoRecebimentoLoteDto'
        id: string
        numeroLote?: Maybe<string>
        responsavel: string
        processado?: Maybe<boolean>
        data: any
        possuiInconsistencia: boolean
        counts: {
          __typename?: 'TransmissaoRecebimentoLoteCountsDto'
          fichasValidas: number
          fichasInvalidas: number
          totalFichas: number
          fichasRepetidas: number
        }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type AlterarHorarioGeracaoLotesProcessamentoFichasMutationVariables = {
  horario: Scalars['LocalTime']
}

export type AlterarHorarioGeracaoLotesProcessamentoFichasMutation = {
  __typename?: 'Mutation'
  alterarHorarioGeracaoLotesProcessamentoFichas: any
}

export type LoadHorarioGeracaoLotesProcessamentoFichasQueryVariables = {}

export type LoadHorarioGeracaoLotesProcessamentoFichasQuery = {
  __typename?: 'Query'
  loadHorarioGeracaoLotesProcessamentoFichas?: Maybe<any>
}

export type LoteEnvioQueryVariables = {
  idLote: Scalars['ID']
  filtroHistorico: HistoricoExportacaoFiltro
}

export type LoteEnvioQuery = {
  __typename?: 'Query'
  loteEnvio: {
    __typename?: 'LoteEnvio'
    id: string
    dataCriacao: any
    quantidadeFichas: number
    nodos?: Maybe<
      Array<
        Maybe<{
          __typename?: 'NodoLoteEnvio'
          id?: Maybe<string>
          dataEnvio?: Maybe<any>
          nodeNome: string
          statusEnvio: StatusEnvio
        }>
      >
    >
    historicoExportacao: {
      __typename?: 'LoteHistoricoExportacaoPayload'
      content: Array<
        Maybe<{ __typename?: 'LoteHistoricoExportacao'; id: string; nomeProfissional: string; dataExportacao: any }>
      >
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
    }
  }
}

export type GerarLotesMutationVariables = {}

export type GerarLotesMutation = { __typename?: 'Mutation'; gerarLote: boolean }

export type OnLoteGeradoSubscriptionVariables = {}

export type OnLoteGeradoSubscription = {
  __typename?: 'Subscription'
  onLoteGerado?: Maybe<{ __typename?: 'LoteGeradoMessage'; message?: Maybe<string> }>
}

export type LotesEnvioQueryVariables = {
  input: LotesEnvioFiltro
}

export type LotesEnvioQuery = {
  __typename?: 'Query'
  lotesEnvio: {
    __typename?: 'LotesEnvioPayload'
    content: Array<
      Maybe<{
        __typename?: 'LoteEnvio'
        id: string
        statusEnvio: StatusEnvio
        dataCriacao: any
        quantidadeFichas: number
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TransmissaoRecebimentoCnesDetailItemsQueryVariables = {
  input: TransmissaoRecebimentoCnesDetailFiltro
}

export type TransmissaoRecebimentoCnesDetailItemsQuery = {
  __typename?: 'Query'
  transmissaoRecebimentoCnesDetailItems: Array<
    Maybe<{
      __typename?: 'TransmissaoRecebimentoCnesDetailItem'
      nomeEstabelecimento?: Maybe<string>
      tipoFicha: string
      quantidade: number
    }>
  >
}

export type TransmissaoRecebimentoCnesQueryVariables = {
  input: TransmissaoRecebimentoCnesFiltro
}

export type TransmissaoRecebimentoCnesQuery = {
  __typename?: 'Query'
  transmissaoRecebimentoCnes: {
    __typename?: 'TransmissaoRecebimentoCnesPayload'
    content: Array<
      Maybe<{
        __typename?: 'TransmissaoRecebimentoCnesDto'
        numCnes: string
        mesRecebimento: number
        anoRecebimento: number
        fichasProcessadas: number
        estabelecimentoOrigem?: Maybe<string>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type ExistemFichasParaProcessarQueryVariables = {}

export type ExistemFichasParaProcessarQuery = { __typename?: 'Query'; existemFichasParaProcessar: boolean }

export type OnTransmissaoUpdatedSubscriptionVariables = {}

export type OnTransmissaoUpdatedSubscription = {
  __typename?: 'Subscription'
  transmissaoUpdated: {
    __typename?: 'Transmissao'
    id: string
    importacaoArquivo: {
      __typename?: 'ImportacaoArquivo'
      dataEvento?: Maybe<any>
      status: StatusImportacaoArquivo
      message?: Maybe<string>
    }
  }
}

export type TransmissaoQueryVariables = {}

export type TransmissaoQuery = {
  __typename?: 'Query'
  transmissao: {
    __typename?: 'Transmissao'
    id: string
    importacaoArquivo: {
      __typename?: 'ImportacaoArquivo'
      dataEvento?: Maybe<any>
      status: StatusImportacaoArquivo
      message?: Maybe<string>
    }
  }
}

export type AtivarTipoServicoMutationVariables = {
  unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput
}

export type AtivarTipoServicoMutation = {
  __typename?: 'Mutation'
  ativarTipoServico: { __typename?: 'RlUnidadeSaudeTipoServicoIds'; unidadeSaudeId: string; tipoServicoId: string }
}

export type DesativarTipoServicoMutationVariables = {
  unidadeSaudeTipoServicoFormInput: UnidadeSaudeTipoServicoFormInput
}

export type DesativarTipoServicoMutation = {
  __typename?: 'Mutation'
  desativarTipoServico: { __typename?: 'RlUnidadeSaudeTipoServicoIds'; unidadeSaudeId: string; tipoServicoId: string }
}

export type UnidadeSaudeDetailQueryVariables = {
  id: Scalars['ID']
}

export type UnidadeSaudeDetailQuery = {
  __typename?: 'Query'
  unidadeSaude: {
    __typename?: 'UnidadeSaude'
    id: string
    nome?: Maybe<string>
    cnes: string
    cnpj?: Maybe<string>
    email?: Maybe<string>
    telefone1?: Maybe<string>
    telefone2?: Maybe<string>
    fax?: Maybe<string>
    ativo: boolean
    tipo?: Maybe<{ __typename?: 'TipoUnidadeSaude'; id: string; descricao: string }>
    complexidades?: Maybe<Array<Maybe<{ __typename?: 'Complexidade'; id: string; nome: string }>>>
    endereco?: Maybe<{
      __typename?: 'Endereco'
      cep?: Maybe<string>
      logradouro?: Maybe<string>
      numero?: Maybe<string>
      semNumero: boolean
      complemento?: Maybe<string>
      pontoReferencia?: Maybe<string>
      bairro?: Maybe<string>
      municipio?: Maybe<{ __typename?: 'Municipio'; nome: string }>
      uf?: Maybe<{ __typename?: 'UF'; sigla: string }>
      tipoLogradouro?: Maybe<{ __typename?: 'TipoLogradouro'; nome: string }>
    }>
    actions: {
      __typename?: 'UnidadeSaudeActions'
      permissaoAtivarInativarTipoServico: { __typename?: 'Action'; enabled: boolean; hint?: Maybe<string> }
    }
  }
}

export type UnidadeSaudeTipoServicoQueryVariables = {
  input: UnidadeSaudeTipoServicoQueryInput
}

export type UnidadeSaudeTipoServicoQuery = {
  __typename?: 'Query'
  unidadeSaudeTiposServico: {
    __typename?: 'UnidadeSaudeTiposServicoPayload'
    content: Array<
      Maybe<{
        __typename?: 'UnidadeSaudeTipoServico'
        ativo: boolean
        tipoServico: { __typename?: 'TipoServico'; id: string; nome: string }
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type UnidadeSaudeTableQueryVariables = {
  input: UnidadesSaudeQueryInput
}

export type UnidadeSaudeTableQuery = {
  __typename?: 'Query'
  unidadesSaude: {
    __typename?: 'UnidadesSaudeQueryPayload'
    content: Array<
      Maybe<{
        __typename?: 'UnidadeSaude'
        id: string
        cnes: string
        nome?: Maybe<string>
        telefone1?: Maybe<string>
        telefone2?: Maybe<string>
        ativo: boolean
        endereco?: Maybe<{ __typename?: 'Endereco'; bairro?: Maybe<string> }>
      }>
    >
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type UnificaCadastrosMutationVariables = {
  input: UnificacaoCadastroInput
}

export type UnificaCadastrosMutation = { __typename?: 'Mutation'; unificaCadastros: boolean }
