import { Alert, Heading, HFlow, Link, Text, VFlow } from 'bold-ui'
import { ReactComponent as Modulos } from 'images/novidades/module.svg'
import { ReactComponent as Acompanhamento } from 'images/side-menu/acompanhamento-condicoes-saude.svg'
import { ReactComponent as LotesImunobiologico } from 'images/side-menu/cadastro-de-lote.svg'
import { ReactComponent as ConfiguracoesAD } from 'images/side-menu/configuracoes-ad.svg'
import { ReactComponent as Configuracoes } from 'images/side-menu/configuracoes.svg'
import { ReactComponent as ImportacaoCnes } from 'images/side-menu/importacao-de-cnes.svg'
import { ReactComponent as Perfis } from 'images/side-menu/perfis.svg'
import { ReactComponent as Profissionais } from 'images/side-menu/profissionais.svg'
import { ReactComponent as TransmissaoDados } from 'images/side-menu/transmissao-de-dados.svg'
import { ReactComponent as UnidadeSaude } from 'images/side-menu/unidade-de-saude.svg'
import { useState } from 'react'
import React from 'react'

import imgTipoServico from '../../images/novidades/gestao-tipo-servico.png'
import gifAcompanhamento from '../../images/novidades/gif_acompanhamento.gif'
import gifMenu from '../../images/novidades/gif_menu.gif'
import imgMenuNovidades from '../../images/novidades/menu-novidades-versao.png'
import imgUnidadeSaude from '../../images/novidades/ubs-profissionais.png'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsVersionTitle } from './NewsVersionTitle'

export function NewsInternalPanelV4_10() {
  const [isVisible, setVisible] = useState(true)

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setVisible(visible => !visible)
  }

  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow>
      <VFlow>
        <NewsVersionTitle isVisible={isVisible} handleLinkClick={handleLinkClick} version='Versão 4.1.0' />
        {isVisible ? (
          <VFlow vSpacing={1}>
            <Text fontSize={1} fontWeight='bold'>
              Novidades da versão
            </Text>
            <HFlow hSpacing={5}>
              <VFlow vSpacing={0.2}>
                <Text component='li'> Acesso aos módulos </Text>
                <Text component='li'> Acompanhamento de condições de saúde </Text>
                <Text component='li'> Ativação da instalação </Text>
                <Text component='li'> Configurações </Text>
                <Text component='li'> Importação do CNES </Text>
                <Text component='li'> Lotes de Imunobiológico </Text>
              </VFlow>
              <VFlow vSpacing={0.2}>
                <Text component='li'> Perfis </Text>
                <Text component='li'> Pesquisa de satisfação </Text>
                <Text component='li'> Profissionais </Text>
                <Text component='li'> Reset do instalador </Text>
                <Text component='li'> Transmissão de dados </Text>
                <Text component='li'> Unidades de Saúde </Text>
              </VFlow>
            </HFlow>
            <Text>As novidades da versão podem ser acessadas no menu do cabeçalho do sistema.</Text>
            <img
              src={imgMenuNovidades}
              style={{ width: '100%' }}
              alt='Imagem do menu para acesso das Novidades da versão'
            />

            <NewsHeadingSection
              title='Acesso aos módulos'
              icon={<Modulos style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
            >
              <VFlow vSpacing={0.5}>
                <Text component='li'>Os módulos do sistema são acessados no menu, na lateral esquerda da tela.</Text>
                <Text component='li'>
                  É possível definir o módulo inicial, que será apresentado após o login. Ele pode ser alterado no menu
                  do cabeçalho do sistema.
                </Text>
                <img src={gifMenu} style={{ width: '100%' }} alt='Gif menu principal' />
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection
              title='Acompanhamento de condições de saúde'
              icon={<Acompanhamento style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
            >
              <VFlow vSpacing={0.5}>
                <Text component='p'>
                  Este módulo permite ao profissional listar cidadãos que possuem uma determinada condição de saúde,
                  faixa de idade, sexo e/ou outros filtros disponíveis. A lista apresenta os dados necessários para o
                  profissional entrar em contato com o cidadão, como por exemplo telefone e endereço.
                </Text>

                <img src={gifAcompanhamento} style={{ width: '100%' }} alt='Gif Acompanhamento de condições de saúde' />
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection title='Ativação da instalação'>
              <VFlow vSpacing={0.5}>
                <Text component='li'>
                  As instalações de centralizadora precisarão ser ativadas por um profissional com acesso de
                  Administração municipal. Para ativar a instalação no município será necessário gerar uma contra-chave
                  no{' '}
                  <Link target='_blank' href='http://sisab.saude.gov.br'>
                    sistema de controle de uso do e-SUS APS.
                  </Link>
                </Text>
                <Text component='li'>
                  Instalações centralizadoras podem ter apenas um profissional com acesso de Administrador do município.
                </Text>
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection title='Configurações' icon={<Configuracoes style={{ marginRight: '0.25rem' }} />}>
              <VFlow vSpacing={0.5}>
                <Text component='p'>
                  As configurações foram separadas em <b>Configurações da instalação</b> (Conexão, Segurança,
                  Servidores, Municípios e responsáveis, Configurações avançadas) e <b>Gestão municipal </b>
                  (compartilhamento de prontuário, configuração de agenda padrão, tipos de serviço).
                </Text>
                <Heading level={4}>Tipos de serviço</Heading>
                <Text component='li'>
                  Este módulo permite adicionar novos tipos de serviço que são oferecidos nas Unidades de saúde do
                  município. Os novos tipos de serviço serão apresentados no módulo de Lista de atendimento.
                </Text>
                <img
                  src={imgTipoServico}
                  style={{ width: '100%' }}
                  alt='Imagem da lista de tipos de serviço do módulo de Gestão municipal'
                />
                <Heading level={4}>Municípios e responsáveis</Heading>
                <Text component='li'>
                  A partir desta versão, todas as instalações podem ser configuradas para serem utilizadas por mais de
                  um município.
                </Text>
                <Text component='li'>
                  Os recursos do Instalador e do Administrador municipal estão disponíveis em acessos separados e podem
                  ser atribuídos a profissionais diferentes.
                </Text>
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection title='Configurações AD' icon={<ConfiguracoesAD style={{ marginRight: '0.25rem' }} />}>
              <Text component='p'>
                As configurações de AD estão com um novo visual, mas as funcionalidades permanecem as mesmas.
              </Text>
            </NewsHeadingSection>

            <NewsHeadingSection title='Importação do CNES' icon={<ImportacaoCnes style={{ marginRight: '0.25rem' }} />}>
              <Text component='p'>
                A importação está muito mais rápida, os arquivos podem ser arrastados para a tela, a quantidade de
                registros novos/atualizados são informados na tela e o relatório de importação está mais detalhado.
              </Text>
            </NewsHeadingSection>

            <NewsHeadingSection
              title='Lotes de imunobiológico'
              icon={<LotesImunobiologico style={{ marginRight: '0.25rem' }} />}
            >
              <Text component='p'>
                O cadastro de lotes imunobiológico está com um novo visual, mas as funcionalidades permanecem as mesmas.
              </Text>
            </NewsHeadingSection>

            <NewsHeadingSection title='Perfis' icon={<Perfis style={{ marginRight: '0.25rem' }} />}>
              <VFlow vSpacing={0.5}>
                <Text component='li'>
                  Os perfis com tipo Coordenação e Atendimento foram unificados no novo tipo, “Lotação”. Profissionais
                  poderão, por exemplo, ter na mesma lotação os perfis de “Coordenador da UBS” e “Enfermagem”.
                </Text>
                <Text component='li'>
                  Foram disponibilizados os novos tipos de perfil: “Gestor municipal” e “Gestor estadual”.
                </Text>
                <Text component='li'>
                  Nova forma de criar e alterar perfis usando uma lista de recursos simplificada.
                </Text>
                <Text component='li'>
                  Os recursos foram atualizados e renomeados. Recomenda-se a revisão dos perfis padrões e criados pelo
                  município.
                </Text>
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection title='Pesquisa de satisfação do usuário'>
              <VFlow vSpacing={0.5}>
                <Text component='p'>
                  A pesquisa de satisfação do usuário tem como objetivo coletar a avaliação dos usuários do sistema e
                  estará disponível para todo profissional após 15 dias ao primeiro login.
                </Text>
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection
              title='Lotações e acessos dos profissionais'
              icon={<Profissionais style={{ marginRight: '0.25rem' }} />}
            >
              <VFlow vSpacing={0.5}>
                <Text component='li'>
                  Os acessos e lotações de um profissional podem ser visualizados na tela de informações do cadastro do
                  profissional.
                </Text>
                <Text component='li'>
                  Lotações e acessos contêm Perfis, que definem quais funcionalidades o profissional poderá utilizar no
                  sistema.
                </Text>
                <Text component='li'>
                  Uma lotação contém informações sobre o estabelecimento de saúde, equipe e CBO do profissional. Elas
                  podem ser cadastradas por profissionais que possuem o recurso "Cadastrar lotação".
                </Text>
                <Text component='li'>
                  Um acesso pode ser criado para profissionais que precisam utilizar o sistema mas, não possuem lotação
                  em um estabelecimento de saúde. Os acessos possuem informação de estado ou município do profissional.
                  Eles podem ser cadastrados por "Administradores da instalação" ou "Administradores municipais".
                </Text>
                <Text component='li'>
                  Os acessos podem ser do tipo Administrador da instalação, Administrador municipal, Gestão estadual e
                  Gestão municipal.
                </Text>
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection title='Reset do instalador'>
              <VFlow vSpacing={0.5}>
                <Text component='p'>
                  O administrador da instalação pode realizar as funcionalidades relacionadas ao reset do instalador:
                </Text>
                <Text component='li'>Redefinir senha do instalador;</Text>
                <Text component='li'>Autenticar novo instalador;</Text>
                <Text component='li'>Cadastrar novo instalador.</Text>
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection
              title='Transmissão de dados'
              icon={<TransmissaoDados style={{ marginRight: '0.25rem' }} />}
            >
              <VFlow vSpacing={0.5}>
                <Text component='li'>
                  As abas de Envio e Recebimento apresentam informações sobre quando ocorrerá a próxima geração
                  automática de lotes e se existem fichas aguardando processamento.
                </Text>
                <Text component='li'>A geração de relatório de inconsistências é realizada na aba Recebimento.</Text>
                <Text component='li'>
                  É possível definir o horário de geração de lotes e processamento de fichas na aba Configurações.
                </Text>
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection title='Unidades de saúde' icon={<UnidadeSaude style={{ marginRight: '0.25rem' }} />}>
              <VFlow vSpacing={0.5}>
                <Text component='p'>
                  As unidades de saúde apresentam a lista dos seus profissionais e das suas equipes.
                </Text>
                <img
                  src={imgUnidadeSaude}
                  style={{ width: '100%' }}
                  alt='Imagem da lista de profissionais do módulo Unidades de saúde'
                />
              </VFlow>
            </NewsHeadingSection>

            <NewsHeadingSection title='Outras melhorias'>
              <Text component='p'>
                Os módulos de Cadastro de imunobiológico, Configuração AD e Atenção domiciliar podem ser acessados
                diretamente no menu de módulos na lateral esquerda da tela.
              </Text>
            </NewsHeadingSection>
          </VFlow>
        ) : null}
      </VFlow>
    </VFlow>
  )
}
