import { DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { confirm } from 'components/confirm'
import { DropdownButton } from 'components/dropdown/DropdownButton'
import { DropdownLink } from 'components/dropdown/DropdownLink'
import { useErrorHandler } from 'components/error'
import React from 'react'
import Permissions from 'types/Permissions'

import { useDefinirSenhaPadrao } from './DefinirSenhaPadraoMutation'
import { useExcluirProfissional } from './ExcluirProfissionalMutation'
import { ItemType } from './ProfissionalTable'

export interface ProfissionalTableDropdownProps {
  profissional: ItemType
  refetch(): any
}

export function ProfissionalTableDropdown(props: ProfissionalTableDropdownProps) {
  const { profissional, refetch } = props

  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const [definirSenhaPadrao] = useDefinirSenhaPadrao()
  const [excluirProfissional] = useExcluirProfissional()
  const profissionalBloqueado = profissional.usuario && profissional.usuario.bloqueado

  const handleRedefinirSenha = () => {
    const novaSenha: string = profissional.cns ? profissional.cns + 'esus' : profissional.cpf + 'esus'
    const appendTitulo = profissionalBloqueado ? 'Deseja desbloquear e ' : 'Deseja '

    confirm({
      confirmLabel: profissionalBloqueado ? 'Desbloquear e redefinir' : 'Redefinir senha',
      title: `${appendTitulo} redefinir a senha do profissional ${profissional.nome} para ${novaSenha} ?`,
      type: 'primary',
      onConfirm: () => {
        return definirSenhaPadrao({ variables: { usuarioId: profissional.usuario.id } })
          .then(data => {
            alert(
              'success',
              profissionalBloqueado
                ? 'Login desbloqueado e senha redefinida com sucesso.'
                : 'Senha redefinida com sucesso'
            )
            refetch()
          })
          .catch(handleRejection)
      },
    })()
  }

  const handleExcluir = () => {
    confirm({
      title: `Deseja excluir o profissional?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluirProfissional({ variables: { profissionalId: profissional.id } })
          .then(() => {
            alert('success', 'Profissional excluído')
            refetch()
          })
          .catch(handleRejection)
      },
    })()
  }

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton>
        <CheckPermission permission={Permissions.visualizarProfissionais.redefinirSenha}>
          <Tooltip text={profissional.actions.redefinirSenha.hint}>
            <DropdownItem onClick={handleRedefinirSenha} disabled={!profissional.actions.redefinirSenha.enabled}>
              {profissionalBloqueado ? 'Desbloquear e redefinir senha' : 'Redefinir senha'}
            </DropdownItem>
          </Tooltip>
        </CheckPermission>

        <CheckPermission permission={Permissions.visualizarProfissionais.cadastrarEditarEExcluir}>
          <DropdownLink to={`/profissional/${profissional.id}/edit`}>Editar</DropdownLink>
        </CheckPermission>

        <CheckPermission permission={Permissions.visualizarProfissionais.cadastrarEditarEExcluir}>
          <DropdownItem type='danger' onClick={handleExcluir}>
            Excluir
          </DropdownItem>
        </CheckPermission>
      </DropdownButton>
    </Tooltip>
  )
}
