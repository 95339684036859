import { Button, ButtonProps, Dropdown, DropdownProps, Icon, IconProps } from 'bold-ui'
import React, { useRef, useState } from 'react'

export interface DropdownButtonProps extends ButtonProps {
  icon?: IconProps['icon']
  children: DropdownProps['children']
}

export function DropdownButton(props: DropdownButtonProps) {
  const { icon, children, ...rest } = props

  const buttonRef = useRef<HTMLButtonElement>()

  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    buttonRef.current && buttonRef.current.focus()
  }

  return (
    <>
      <Button innerRef={buttonRef} size='small' skin='ghost' onClick={handleClick} {...rest}>
        <Icon icon={icon} />
      </Button>

      <Dropdown anchorRef={buttonRef} open={open} onClose={handleClose}>
        {children}
      </Dropdown>
    </>
  )
}

DropdownButton.defaultProps = {
  icon: 'dots',
} as Partial<DropdownButtonProps>
