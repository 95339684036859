import { HeadingSection } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { useCreateCidadaoCadsusMutation } from 'graphql/hooks.generated'
import { NacionalidadeEnum } from 'graphql/types.generated'
import React, { useState } from 'react'

import { CidadaoDadosForm } from '../CidadaoDadosForm'
import CidadaoFormModel from './CidadaoFormModel'
import { ModalStatusSincronizacaoCadsus } from './ModalStatusSincronizacaoCadsus'

export function CidadaoCreateView() {
  const [saveCadsus, { loading, data: resultCadsus }] = useCreateCidadaoCadsusMutation()
  const [sincCadsusState, setSincCadsusState] = useState<boolean>(false)

  const syncCadsus = (cidadaoId: string) => {
    saveCadsus({ variables: { cidadaoId: cidadaoId } })
    setSincCadsusState(true)
  }

  const initialValues = {
    nacionalidade: NacionalidadeEnum.BRASILEIRA,
    endereco: { pais: { id: '31', nome: 'BRASIL' } },
  } as CidadaoFormModel

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Cadastro do cidadão' />
      <HeadingSection level={1} title='Cadastro do cidadão'>
        <CidadaoDadosForm initialValues={initialValues} sincronizarCadsus={syncCadsus} />
        <ModalStatusSincronizacaoCadsus
          loading={loading}
          result={resultCadsus?.createCidadaoCadsus}
          isOpen={sincCadsusState}
          isEdit={false}
          sincronizarCadsus={syncCadsus}
        />
      </HeadingSection>
    </PageContent>
  )
}
