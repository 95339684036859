import { TipoEventoComboQuery, TipoEventoComboQuery_auditoriaTipoEvento } from 'api/schema'
import { Omit, Text } from 'bold-ui'
import gql from 'graphql-tag'
import { useQuery } from 'graphql/hooks'
import React from 'react'

import { SelectField, SelectFieldProps } from '../../final-form'

export type TipoEventoSelectModel = Omit<TipoEventoComboQuery_auditoriaTipoEvento, '__typename'>

export interface TipoEventoProps extends Omit<SelectFieldProps<TipoEventoSelectModel>, 'items' | 'itemToString'> {}

export interface TipoEventoVariables {}

export function TipoEventoSelectField(props: TipoEventoProps) {
  const { data } = useQuery<TipoEventoComboQuery>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  if (!data) {
    return null
  }

  const itemToString = (item: TipoEventoSelectModel) => item && item.nome

  const renderItem = (item: TipoEventoSelectModel) => {
    return <Text>{item.nome}</Text>
  }
  return (
    <SelectField<TipoEventoSelectModel>
      items={data.auditoriaTipoEvento}
      itemToString={itemToString}
      renderItem={renderItem}
      {...props}
    />
  )
}
const QUERY = gql`
  query TipoEventoComboQuery {
    auditoriaTipoEvento {
      id
      nome
    }
  }
`
