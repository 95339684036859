import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { MunicipioSelectFieldDocument } from 'graphql/hooks.generated'
import { MunicipioSelectFieldQuery, MunicipioSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE } from '../AsyncQuerySelectField'
import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type MunicipioSelectModel = MunicipioSelectFieldQuery['municipios']['content'][0]

export interface MunicipioSelectFieldProps
  extends Omit<SelectFieldProps<MunicipioSelectModel>, 'items' | 'itemToString'> {
  /**
   * Caso especificado, apresenta apenas municípios desta UF.
   */
  ufId?: ID

  ativo?: boolean
}

export function MunicipioSelectField(props: MunicipioSelectFieldProps) {
  const { ufId, ativo, ...rest } = props

  const asyncProps = useAsyncQuerySelect<
    MunicipioSelectModel,
    MunicipioSelectFieldQuery,
    MunicipioSelectFieldQueryVariables
  >({
    query: MunicipioSelectFieldDocument,
    extractItems: data => data && data.municipios && data.municipios.content,
    variables: (inputQuery: string) => ({
      input: {
        ufId,
        ativo,
        query: inputQuery,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: MunicipioSelectModel) =>
    item && item.nome && item.uf.sigla && `${item.nome} - ${item.uf.sigla}`

  return <SelectField<MunicipioSelectModel> itemToString={itemToString} {...asyncProps} {...rest} />
}
