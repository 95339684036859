import { Icon, IconProps, Theme, Tooltip, TooltipProps, useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

export interface InfoIconProps extends IconProps, Pick<TooltipProps, 'text' | 'placement'> {}

export function InfoIcon(props: InfoIconProps) {
  const { text, placement, style, ...rest } = props
  const { css, classes } = useStyles(createStyles)

  return (
    <Tooltip text={text} placement={placement}>
      <Icon style={css(classes.icon, style)} size={1} {...rest} />
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  icon: {
    '&:hover': {
      color: theme.pallete.primary.main,
    },
  } as CSSProperties,
})
