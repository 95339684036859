import { ExcluirLotacao, ExcluirLotacaoVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const EXCLUIR_LOTACAO_MUTATION = gql`
  mutation ExcluirLotacao($lotacaoId: ID!) {
    excluirLotacao(lotacaoId: $lotacaoId)
  }
`

export function useExcluirLotacao() {
  return useMutation<ExcluirLotacao, ExcluirLotacaoVariables>(EXCLUIR_LOTACAO_MUTATION)
}
