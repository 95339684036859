import { PagedTable } from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useConfiguracoesAtencaoDomQuery } from 'graphql/hooks.generated'
import { ConfiguracoesAtencaoDomQuery, ConfiguracoesAtendimentosDomInput } from 'graphql/types.generated'
import React, { useState } from 'react'

import ConfiguracoesADButtonExcluir from './ConfiguracoesADButtonExcluir'
import ConfiguracoesADPodeAtenderForm from './ConfiguracoesADPodeAtenderForm'

export type ConfiguracaoAdAtendimentoItemType = ConfiguracoesAtencaoDomQuery['configuracoesAtencaoDom']['emadsAtendemEmads']['content'][0]

export default function ConfiguracoesADPodeAtenderTable() {
  const [pageParams, setPageParams] = useState<ConfiguracoesAtendimentosDomInput>()

  const { data, refetch, loading } = useConfiguracoesAtencaoDomQuery({
    variables: { input: pageParams },
  })

  const emadsAtendemEmads = data?.configuracoesAtencaoDom?.emadsAtendemEmads

  const tableProps = usePagedTableProps({
    loading,
    result: emadsAtendemEmads,
    onChange: setPageParams,
  })

  const renderEmadPai = (row: ConfiguracaoAdAtendimentoItemType) =>
    `${row.identificadorNacionalEquipePai.nome} - ${row.identificadorNacionalEquipePai.ine}`

  const renderEmadFilho = (row: ConfiguracaoAdAtendimentoItemType) =>
    `${row.identificadorNacionalEquipeFilho.nome} - ${row.identificadorNacionalEquipeFilho.ine}`

  const renderButtons = (row: ConfiguracaoAdAtendimentoItemType) => (
    <ConfiguracoesADButtonExcluir configItem={row} reload={refetch} />
  )

  return (
    <TableBox header={<ConfiguracoesADPodeAtenderForm reload={refetch} />}>
      <PagedTable<ConfiguracaoAdAtendimentoItemType>
        {...tableProps}
        columns={[
          {
            name: 'emap',
            header: 'EMAD',
            render: renderEmadPai,
            style: { whiteSpace: 'nowrap' },
          },
          {
            name: 'emad',
            header: 'EMAD',
            render: renderEmadFilho,
            style: { whiteSpace: 'nowrap' },
          },
          {
            name: 'buttons',
            render: renderButtons,
            style: { textAlign: 'right', whiteSpace: 'nowrap' },
          },
        ]}
      />
    </TableBox>
  )
}
