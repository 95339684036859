import { PagedTable } from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useConfiguracoesAtencaoDomQuery } from 'graphql/hooks.generated'
import { ConfiguracoesAtencaoDomQuery, ConfiguracoesAtendimentosDomInput } from 'graphql/types.generated'
import React, { useState } from 'react'

import ConfiguracoesADApoioForm from './ConfiguracoesADApoioForm'
import ConfiguracoesADButtonExcluir from './ConfiguracoesADButtonExcluir'

export type ConfiguracaoAdApoioItemType = ConfiguracoesAtencaoDomQuery['configuracoesAtencaoDom']['emapsApoiamEmads']['content'][0]

export default function ConfiguracoesADApoioTable() {
  const [pageParams, setPageParams] = useState<ConfiguracoesAtendimentosDomInput>()

  const { data, refetch, loading } = useConfiguracoesAtencaoDomQuery({
    variables: { input: pageParams },
  })

  const emapsApoiamEmads = data?.configuracoesAtencaoDom?.emapsApoiamEmads

  const tableProps = usePagedTableProps({
    loading,
    result: emapsApoiamEmads,
    onChange: setPageParams,
  })

  const renderEmad = (row: ConfiguracaoAdApoioItemType) =>
    `${row.identificadorNacionalEquipeFilho.nome} - ${row.identificadorNacionalEquipeFilho.ine}`

  const renderEmap = (row: ConfiguracaoAdApoioItemType) =>
    `${row.identificadorNacionalEquipePai.nome} - ${row.identificadorNacionalEquipePai.ine}`

  const renderButtons = (row: ConfiguracaoAdApoioItemType) => (
    <ConfiguracoesADButtonExcluir configItem={row} reload={refetch} />
  )

  return (
    <TableBox header={<ConfiguracoesADApoioForm reload={refetch} />}>
      <PagedTable<ConfiguracaoAdApoioItemType>
        {...tableProps}
        columns={[
          {
            name: 'emap',
            header: 'EMAP',
            render: renderEmap,
            style: { whiteSpace: 'nowrap' },
          },
          {
            name: 'emad',
            header: 'EMAD',
            render: renderEmad,
            style: { whiteSpace: 'nowrap' },
          },
          {
            name: 'buttons',
            render: renderButtons,
            style: { textAlign: 'right', whiteSpace: 'nowrap' },
          },
        ]}
      />
    </TableBox>
  )
}
