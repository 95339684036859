import { SideMenuItemConfig, SideMenuItemConfigChildren, SideMenuSubItem } from './SideMenu'

export function filterSideMenuItems(items: SideMenuItemConfig[], filter: string) {
  let selectedParents: SideMenuItemConfig[] = []
  items.forEach(parent => {
    if (matches(filter, parent.title)) {
      selectedParents = [...selectedParents, parent]
      return
    }

    let selectedChilds: SideMenuItemConfigChildren = []
    parent.children &&
      parent.children.forEach(child => {
        if (child.group && matches(filter, child.group)) {
          selectedChilds = [...selectedChilds, child]
          return
        }

        const selectedItems: SideMenuSubItem[] = child.items.filter(item => matches(filter, item.title))
        if (selectedItems.length > 0) {
          selectedChilds = [...selectedChilds, { ...child, items: selectedItems }]
        }
      })
    if (selectedChilds.length > 0) {
      selectedParents = [...selectedParents, { ...parent, children: selectedChilds }]
    }
  })
  return selectedParents
}

const matches = (filter: string, text: string) => {
  if (filter && filter.trim() !== '') {
    const searchWords = filter
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .split(/\s/)
      .filter(word => word)

    const regex = new RegExp(`^(?=.*${searchWords.join(')(?=.*')})`, 'gi')

    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .match(regex)
  }
  return true
}
