import { FieldMetaState } from 'react-final-form'

/**
 * Return the current field's active error.
 *
 * @param meta The react-final-form field meta state.
 */
export const getFieldError = (meta: FieldMetaState<any>): any => {
  return (meta.touched && meta.error) || (!meta.dirtySinceLastSubmit && meta.submitError)
}
