import { FormError, FormErrorProps } from 'bold-ui'
import React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { resolveName } from '../useField'
import { getFieldError } from '../util'

export interface ErrorFieldProps extends FormErrorProps {
  name: string | MetaPath<any>
  ignoreObjectError?: boolean
}

export function ErrorField(props: ErrorFieldProps) {
  const { name, ignoreObjectError, ...rest } = props

  const renderField = (renderProps: FieldRenderProps<any, HTMLElement>) => {
    const { meta } = renderProps

    const error = getFieldError(meta)

    const ignore = ignoreObjectError && typeof error !== 'string'

    if (error && !ignore) {
      return <FormError {...rest}>{error}</FormError>
    }

    return null
  }

  return <Field name={resolveName(name)} render={renderField} />
}
