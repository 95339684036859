import { Breadcrumb } from 'components/breadcrumb'
import { PageContainer } from 'components/layout/PageContainer'
import { useBuscaDetailCidadaoQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useRouteMatch } from 'react-router'

import { CidadaoCabecalhoSection } from './CidadaoCabecalhoSection'
import { CidadaoTabSection } from './CidadaoTabSection'

interface UrlParams {
  cidadaoId: string
}

export function CidadaoDadosView() {
  const match = useRouteMatch<UrlParams>()
  const { data } = useBuscaDetailCidadaoQuery({ variables: { id: match.params.cidadaoId } })

  return (
    <>
      <Breadcrumb title='Visualização do cidadão' />
      {data?.cidadao && (
        <>
          <PageContainer>
            <CidadaoCabecalhoSection cidadao={data.cidadao} />
          </PageContainer>
          <CidadaoTabSection cidadao={data.cidadao} />
        </>
      )}
    </>
  )
}
