import gql from 'graphql-tag'

export default gql`
  mutation AceitarTermosUso {
    aceitarTermosUso {
      id
      trocarSenha
      aceitouTermosUso
    }
  }
`
