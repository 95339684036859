import { Alert, Button, HeadingSection, HFlow, Icon, Text, useStyles, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Form, FormRenderProps, SubmitButton } from 'components/form'
import { TextAreaField } from 'components/form/final-form/TextAreaField/TextAreaField'
import { useSobreQuery } from 'graphql/hooks.generated'
import { GrauSatisfacaoUsuario, TipoRespostaPesquisa } from 'graphql/types.generated'
import React, { CSSProperties } from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength, required } from 'util/validation'

import { ButtonContainer } from './PesquisaSatisfacaoButtonContainer'

interface PesquisaSatisfacaoBoxProps {
  onClose(value: TipoRespostaPesquisa): void
  handleOnSubmit(values: PesquisaSatisfacaoBoxFormModel): void
}

export interface PesquisaSatisfacaoBoxFormModel {
  grauSatisfacao?: GrauSatisfacaoUsuario
  observacao?: string
  tipoResposta?: TipoRespostaPesquisa
}

export const validate = createValidator<PesquisaSatisfacaoBoxFormModel>({
  grauSatisfacao: [required],
  observacao: [maxLength(500)],
})

const path = metaPath<PesquisaSatisfacaoBoxFormModel>()

export const PesquisaSatisfacaoBox = (props: PesquisaSatisfacaoBoxProps) => {
  const { classes } = useStyles(createStyles)

  const handleOnCloseClicked = () => {
    props.onClose(TipoRespostaPesquisa.FECHADO)
  }

  const handleOnNaoResponderClicked = () => {
    props.onClose(TipoRespostaPesquisa.NAO_RESPONDIDO)
  }

  const {
    data: { info },
  } = useSobreQuery()

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <Box style={classes.container}>
        <HFlow hSpacing={3} alignItems='flex-start'>
          <div>
            <HeadingSection title='Ajude-nos a melhorar o e-SUS AB PEC' level={1} />
          </div>
          <Button skin='ghost' size='small' onClick={handleOnCloseClicked}>
            <Icon icon='timesDefault' />
          </Button>
        </HFlow>
        <VFlow>
          <Text fontWeight='bold'>Quão satisfeito você está com a nova versão {info?.versao}?</Text>
          <HFlow hSpacing={1.8}>
            <ButtonContainer name={path.grauSatisfacao} />
          </HFlow>
          <HFlow justifyContent='space-between' style={{ width: 340 }}>
            <Text>Muito insatisfeito</Text>
            <Text>Muito satisfeito</Text>
          </HFlow>
          <TextAreaField
            label='O que você considera mais importante para a sua avaliação?'
            name={path.observacao}
            maxLength={500}
            disabled={!formProps.values.grauSatisfacao}
            style={classes.textArea}
          />
          <Alert type='info' inline>
            Para suporte ou esclarecimento de dúvidas entre em contato com o Disque Saúde através do telefone 136.
          </Alert>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' skin='outline' size='small' onClick={handleOnNaoResponderClicked}>
              Não responder
            </Button>
            <SubmitButton
              kind='primary'
              skin='default'
              size='small'
              disabled={!formProps.values.grauSatisfacao}
              handleSubmit={formProps.handleSubmit}
            >
              Enviar
            </SubmitButton>
          </HFlow>
        </VFlow>
      </Box>
    )
  }
  return (
    <Form<PesquisaSatisfacaoBoxFormModel> render={renderForm} onSubmit={props.handleOnSubmit} validate={validate} />
  )
}

const createStyles = () => ({
  container: {
    width: 402,
    height: 495,
    position: 'fixed',
    left: 109,
    bottom: 20,
    zIndex: 9,
  } as CSSProperties,
  textArea: {
    resize: 'none',
  } as CSSProperties,
})
