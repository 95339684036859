import gql from 'graphql-tag'

export const SEGURANCA_QUERY = gql`
  query SegurancaQuery {
    seguranca {
      periodoEmMesesParaRedefinir {
        redefinicaoSenha
      }
      periodoEmMinutosInatividade {
        periodoInatividade
      }
      loginNumeroTentativas {
        tentativasLogin
      }
    }
  }
`
