import {
  Button,
  colors,
  Dropdown,
  DropdownItem,
  ExternalStyles,
  focusBoxShadow,
  Icon,
  InputProps,
  Omit,
  Text,
  TextInput,
  Theme,
  useStyles,
} from 'bold-ui'
import React, { CSSProperties, useRef, useState } from 'react'

export interface AgeRangeValue {
  periodo: AgeRangeType
  firstValue: number
  secondValue: number
}
export interface AgeRangeProps
  extends Omit<InputProps, 'onChange' | 'value' | 'maxLength' | 'style' | 'placeholder' | 'onBlur'> {
  value?: AgeRangeValue

  invalid?: boolean

  maxLength?: number

  firstInputPlaceholder?: string

  secondInputPlaceholder?: string

  style?: ExternalStyles

  onChange?(item: AgeRangeValue): void
}

export enum AgeRangeType {
  ANOS = 'ANOS',
  MESES = 'MESES',
  DIAS = 'DIAS',
}

export const stringToEnumAgeRangeTypeMap: Record<string, AgeRangeType> = {
  Anos: AgeRangeType.ANOS,
  Meses: AgeRangeType.MESES,
  Dias: AgeRangeType.DIAS,
}

export const enumToStringAgeRangeTypeMap: Record<AgeRangeType, string> = {
  [AgeRangeType.ANOS]: 'Anos',
  [AgeRangeType.MESES]: 'Meses',
  [AgeRangeType.DIAS]: 'Dias',
}

export function AgeRange(props: AgeRangeProps) {
  const { onChange, value, invalid, firstInputPlaceholder, secondInputPlaceholder, ...rest } = props
  const firstInputRef = useRef<HTMLInputElement>()
  const secondInputRef = useRef<HTMLInputElement>()
  const buttonRef = useRef<HTMLButtonElement>()

  const [open, setOpen] = useState(false)
  const [rangeType, setRangeType] = useState<AgeRangeType>(value?.periodo ? value.periodo : AgeRangeType.ANOS)

  const options = ['Anos', 'Meses', 'Dias']
  const { classes, css } = useStyles(createStyles, props.disabled)
  const className = css(classes.mainDiv, invalid && classes.invalid, props.style)

  const handleChangePeriodo = selectedOption => {
    const ageRangeValues = {
      periodo: stringToEnumAgeRangeTypeMap[selectedOption],
      firstValue: value?.firstValue,
      secondValue: value?.secondValue,
    } as AgeRangeValue
    return () => {
      setRangeType(stringToEnumAgeRangeTypeMap[selectedOption])
      onChange && onChange(ageRangeValues)
    }
  }

  const handleChangeFirstValue = firstAgeRange => {
    const firstValue: number = parseInt(firstAgeRange.currentTarget?.value)
    const ageRangeValues = {
      periodo: rangeType,
      firstValue: firstValue ? firstValue : undefined,
      secondValue: value?.secondValue,
    } as AgeRangeValue
    onChange && onChange(ageRangeValues)
  }

  const handleChangeSecondValue = secondAgeRange => {
    const secondValue: number = parseInt(secondAgeRange.currentTarget?.value)
    const ageRangeValues = {
      periodo: rangeType,
      firstValue: value?.firstValue,
      secondValue: secondValue ? secondValue : undefined,
    } as AgeRangeValue
    onChange && onChange(ageRangeValues)
  }

  const handleClearFirstValue = () => {
    const ageRangeValues = {
      periodo: rangeType,
      firstValue: undefined,
      secondValue: value?.secondValue,
    } as AgeRangeValue
    onChange && onChange(ageRangeValues)
    firstInputRef.current.focus()
  }

  const handleClearSecondValue = () => {
    const ageRangeValues = {
      periodo: rangeType,
      firstValue: value?.firstValue,
      secondValue: undefined,
    } as AgeRangeValue
    onChange && onChange(ageRangeValues)
    secondInputRef.current.focus()
  }

  const handleClick = () => setOpen(state => !state)

  const handleClose = () => {
    setOpen(false)
    buttonRef.current.focus()
  }

  const maskTextInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(e.charCode >= 48 && e.charCode <= 57)) {
      e.preventDefault()
    }
  }

  const handleBlur = () => {
    if (value.firstValue && value.secondValue && value.firstValue > value.secondValue) {
      const ageRangeValues = {
        periodo: rangeType,
        firstValue: value.secondValue,
        secondValue: value.firstValue,
      } as AgeRangeValue
      onChange && onChange(ageRangeValues)
    }
  }

  return (
    <div className={className}>
      <div className={classes.internalWrapper}>
        <div className={classes.inputwrapper}>
          <TextInput
            clearable
            inputRef={firstInputRef}
            name='firstInput'
            style={classes.textField}
            {...rest}
            value={value?.firstValue ? value.firstValue : ''}
            onChange={handleChangeFirstValue}
            onClear={handleClearFirstValue}
            onKeyPress={event => maskTextInput(event)}
            placeholder={firstInputPlaceholder}
            onBlur={handleBlur}
          />
        </div>
        <span className={classes.arrowIconWrapper}>
          <strong>até</strong>
        </span>
        <div className={classes.inputwrapper}>
          <TextInput
            clearable
            inputRef={secondInputRef}
            name='secondInput'
            style={classes.textField}
            {...rest}
            value={value?.secondValue ? value.secondValue : ''}
            onChange={handleChangeSecondValue}
            onClear={handleClearSecondValue}
            onKeyPress={event => maskTextInput(event)}
            placeholder={secondInputPlaceholder}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <Button
        skin='ghost'
        size='small'
        tabIndex={-1}
        disabled={props.disabled}
        innerRef={buttonRef}
        onClick={handleClick}
        style={classes.button}
      >
        <Text style={classes.buttonText}>{enumToStringAgeRangeTypeMap[rangeType]}</Text>
        <Icon style={{ marginLeft: '0.5rem' }} icon={open ? 'angleUp' : 'angleDown'} />
        <Dropdown anchorRef={buttonRef} open={open} onClose={handleClose} style={classes.dropdown}>
          {options.map(op => (
            <DropdownItem key={op} onClick={handleChangePeriodo(op)}>
              {op}
            </DropdownItem>
          ))}
        </Dropdown>
      </Button>
    </div>
  )
}

AgeRange.defaultProps = {
  firstInputPlaceholder: 'Idade mínima',
  secondInputPlaceholder: 'Idade máxima',
  maxLength: 3,
} as Partial<AgeRangeProps>

const createStyles = (theme: Theme, disabled: boolean) => {
  const mainDivStyle = createMainDivStyle(theme)

  return {
    mainDiv: {
      ...mainDivStyle.base,
      ':not(:disabled):hover': mainDivStyle.hover,
      ':not(:disabled):active': mainDivStyle.active,
      ':focus-within': {
        ':not(:disabled)': mainDivStyle.focus,
      },
      background: disabled && theme.pallete.surface.background,
    } as CSSProperties,
    invalid: mainDivStyle.invalid,
    button: {
      borderRadius: 0,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: '5.8rem',
      padding: '0.25rem',
      backgroundColor: colors.gray.c90,
      '&:focus': {
        boxShadow: 'none !important',
      },
    } as CSSProperties,
    buttonText: {
      marginLeft: '0.5rem',
      width: '2.5rem',
      marginBottom: '0.15rem',
    },
    dropdown: {
      '&:focus': {
        transition: 'box-shadow .2s ease',
      },
    } as CSSProperties,
    internalWrapper: {
      flex: 1,
      alignItems: 'center',
      marginRight: '0.2rem',
      display: 'flex',
    } as CSSProperties,
    arrowIconWrapper: {
      background: 'transparent',
      color: disabled && theme.pallete.text.disabled,
      cursor: 'default',
      display: 'flex',
    } as CSSProperties,
    inputwrapper: {
      flex: 1,
    } as CSSProperties,
    textField: {
      padding: 0,
      textAlign: 'center',
      border: 'none',
      '::placeholder': {
        color: theme.pallete.text.disabled,
      },
      '&:focus': {
        boxShadow: 'none !important',
      },
    } as CSSProperties,
  }
}

const createMainDivStyle = (theme: Theme) => ({
  base: {
    display: 'flex',
    height: '2rem',
    border: '1px solid' + theme.pallete.gray.c70,
    borderRadius: theme.radius.input,
    position: 'relative',
    transition: 'box-shadow .2s ease',
    '&:required': {
      boxShadow: 'none',
    },
  } as CSSProperties,
  active: {
    borderColor: theme.pallete.primary.main,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
  } as CSSProperties,
  focus: {
    boxShadow: focusBoxShadow(theme),
    outline: 'none',
  } as CSSProperties,
  hover: {
    borderColor: theme.pallete.gray.c60,
  } as CSSProperties,
  invalid: {
    border: 'solid 1px ' + theme.pallete.status.danger.main,
    '&:focus-within': {
      ':not(:disabled)': {
        border: 'solid 1px ' + theme.pallete.gray.c80,
        boxShadow: focusBoxShadow(theme, 'danger'),
      },
    },
  } as CSSProperties,
})
