import { CidadaoEditQuery, CidadaoInput } from 'graphql/types.generated'
import { identidadeGenero, orientacaoSexual, tipoSanguineo } from 'types/enums'

import CidadaoFormModel from './CidadaoFormModel'
import { SimNaoEnum } from './InformacoesComplementaresFieldGroup'

export default (model: CidadaoFormModel): CidadaoInput => {
  const { contato, endereco, informacoesComplementares, vinculacao } = model

  return {
    id: model.id,
    cns: model.cns,
    cpf: model.cpf,
    nome: model.nome,
    nomeSocial: model.nomeSocial,
    dataNascimento: model.dataNascimento,
    sexo: model.sexo,
    racaCor: model.racaCor?.value,
    etnia: model.etnia?.id,
    nomeMae: model.nomeMae,
    desconheceNomeMae: model.desconheceNomeMae,
    nomePai: model.nomePai,
    desconheceNomePai: model.desconheceNomePai,
    nacionalidade: model.nacionalidade,
    municipioNascimento: model.municipioNascimento?.id,
    portariaNaturalizacao: model.portariaNaturalizacao,
    dataNaturalizacao: model.dataNaturalizacao,
    paisNascimento: model.paisNascimento?.id,
    dataEntradaPais: model.dataEntradaPais,
    cidadaoFaleceu: model.cidadaoFaleceu,
    dataObito: model.dataObito,
    numeroDocumentoObito: model.numeroDeclaracaoObito,
    telefoneResidencial: contato?.telefoneResidencial,
    telefoneCelular: contato?.telefoneCelular,
    telefoneContato: contato?.telefoneContato,
    email: contato?.email,
    endereco: endereco && {
      bairro: endereco.bairro?.nome,
      cep: endereco.cep,
      complemento: endereco.complemento,
      logradouro: endereco.logradouro?.nome,
      municipio: endereco.municipio?.id,
      numero: endereco.numero,
      pontoReferencia: endereco.pontoReferencia,
      semNumero: endereco.semNumero,
      tipoLogradouro: endereco.tipoLogradouro?.id,
      uf: endereco.uf?.id,
    },
    paisResidencia: endereco?.pais?.id,
    municipioResidenciaExterior: endereco.municipioResidenciaExterior,
    area: endereco?.area,
    microArea: endereco?.microArea,
    statusForaArea: endereco?.foraArea,
    nisPisPasep: informacoesComplementares?.nis,
    estadoCivil: informacoesComplementares?.estadoCivil?.id,
    tipoSanguineo: informacoesComplementares?.tipoSanguineo?.identificador,
    ocupacao: informacoesComplementares?.ocupacao?.id,
    escolaridade: informacoesComplementares?.escolaridade?.id,
    orientacaoSexualDbEnum: informacoesComplementares?.orientacaoSexual?.identificador,
    desejaInformarOrientacaoSexual:
      informacoesComplementares?.desejaInformarOrientacaoSexual != null
        ? informacoesComplementares.desejaInformarOrientacaoSexual === SimNaoEnum.SIM
          ? true
          : false
        : null,
    desejaInformarIdentidadeGenero:
      informacoesComplementares?.desejaInformarIdentidadeGenero != null
        ? informacoesComplementares.desejaInformarIdentidadeGenero === SimNaoEnum.SIM
          ? true
          : false
        : null,
    identidadeGeneroDbEnum: informacoesComplementares?.identidadeGenero?.identificador,
    desativarCompartilhamentoProntuario: model.desativarCompartilhamentoProntuario,
    vinculacao: vinculacao && {
      ine: vinculacao?.equipe?.ine,
      utilizarFichaCadastroIndividual: vinculacao?.cadastroIndividualCheck,
    },
    uuidUltimaFicha: model.uuidFicha,
  } as CidadaoInput
}
export function convertToForm(input: CidadaoEditQuery['cidadao']): CidadaoFormModel {
  return {
    id: input.id,
    cpf: input.cpf,
    cns: input.cns,
    nome: input.nome,
    nomeSocial: input.nomeSocial,
    dataNascimento: input.dataNascimento,
    sexo: input.sexo,
    racaCor: input.racaCor && {
      id: input.racaCor?.id,
      nome: input.racaCor?.nome,
      value: input.racaCor?.racaCorDbEnum,
    },
    etnia: input.etnia && {
      id: input.etnia?.id,
      nome: input.etnia?.nome,
    },
    nomeMae: input.nomeMae,
    desconheceNomeMae: input.desconheceNomeMae,
    nomePai: input.nomePai,
    desconheceNomePai: input.desconheceNomePai,
    municipioNascimento: input.localidadeNascimento && {
      id: input.localidadeNascimento?.id,
      nome: input.localidadeNascimento?.nome,
      uf: input.localidadeNascimento?.uf && {
        id: input.localidadeNascimento?.uf?.id,
        nome: input.localidadeNascimento?.uf?.nome,
        sigla: input.localidadeNascimento?.uf?.sigla,
      },
    },
    portariaNaturalizacao: input.portariaNaturalizacao,
    dataNaturalizacao: input.dataNaturalizacao,
    nacionalidade: input.nacionalidade?.nacionalidadeDbEnum,
    paisNascimento: input.paisNascimento && {
      id: input.paisNascimento?.id,
      nome: input.paisNascimento?.nome,
    },
    dataEntradaPais: input.dataEntradaBrasil,
    cidadaoFaleceu: input.faleceu,
    dataObito: input.dataObito,
    numeroDeclaracaoObito: input.numeroDocumentoObito,
    contato: {
      telefoneResidencial: input.telefoneResidencial,
      telefoneCelular: input.telefoneCelular,
      telefoneContato: input.telefoneContato,
      email: input.email,
    },
    endereco: input.endereco && {
      ...input?.endereco,
      bairro: input?.endereco?.bairro && { nome: input?.endereco?.bairro },
      logradouro: input?.endereco?.logradouro && { nome: input?.endereco?.logradouro },
      pais: {
        id: input.paisExterior?.id ?? '31',
        nome: input.paisExterior?.nome ?? 'BRASIL',
      },
      municipioResidenciaExterior: input.localidadeExterior,
      area: input.area,
      microArea: input.microArea,
      foraArea: input.statusForaArea,
    },
    informacoesComplementares: {
      nis: input.nisPisPasep,
      estadoCivil: input.estadoCivil && {
        id: input.estadoCivil?.id,
        nome: input.estadoCivil?.nome,
      },
      tipoSanguineo: input.tipoSanguineo && {
        nome: tipoSanguineo[input.tipoSanguineo],
        identificador: input.tipoSanguineo,
      },
      ocupacao: input.cbo && {
        id: input.cbo?.id,
        nome: input.cbo?.nome,
        cbo2002: input.cbo?.cbo2002,
      },
      escolaridade: input.escolaridade && {
        id: input.escolaridade?.id,
        nome: input.escolaridade?.nome,
      },
      desejaInformarOrientacaoSexual:
        input.desejaInformarOrientacaoSexual != null
          ? input.desejaInformarOrientacaoSexual
            ? SimNaoEnum.SIM
            : SimNaoEnum.NAO
          : null,
      orientacaoSexual: input.orientacaoSexualDbEnum && {
        identificador: input.orientacaoSexualDbEnum,
        nome: orientacaoSexual[input.orientacaoSexualDbEnum],
      },
      identidadeGenero: input.identidadeGeneroDbEnum && {
        identificador: input.identidadeGeneroDbEnum,
        nome: identidadeGenero[input.identidadeGeneroDbEnum],
      },
      desejaInformarIdentidadeGenero:
        input.desejaInformarIdentidadeGenero != null
          ? input.desejaInformarIdentidadeGenero
            ? SimNaoEnum.SIM
            : SimNaoEnum.NAO
          : null,
    },
    desativarCompartilhamentoProntuario: !input.stCompartilhaProntuario,
    uuidFicha: input.uuidUltimaFicha,
    vinculacao: input.cidadaoVinculacaoEquipe && {
      cadastroIndividualCheck: input.cidadaoVinculacaoEquipe?.utilizarCadastroIndividual,
      unidadeSaude: input.cidadaoVinculacaoEquipe?.unidadeSaude?.nome,
      equipe: input.cidadaoVinculacaoEquipe.equipe && {
        id: input.cidadaoVinculacaoEquipe?.equipe?.id,
        nome: input.cidadaoVinculacaoEquipe?.equipe?.nome,
        ine: input.cidadaoVinculacaoEquipe?.equipe?.ine,
        tipoEquipe: input.cidadaoVinculacaoEquipe?.equipe?.tipoEquipe,
      },
    },
  }
}
