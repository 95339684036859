import { Heading, HFlow } from 'bold-ui'
import React from 'react'

export interface NewsHeadingSectionProps {
  icon?: React.ReactNode
  title: string
  children: any
}

export function NewsHeadingSection(props: NewsHeadingSectionProps) {
  const { title, icon, children } = props

  return (
    <>
      <HFlow alignItems='flex-start' hSpacing={0.5}>
        {icon}
        <Heading level={3} style={{ marginTop: '0.25rem', marginBottom: '1rem' }}>
          {title}
        </Heading>
      </HFlow>
      {children}
    </>
  )
}
