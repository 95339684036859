import {
  ConfiguracaoAgendaOnlineLotacaoInput,
  ConfiguracaoAgendaOnlineLotacaoQuery,
  SalvarConfiguracaoAgendaOnlineLotacaoMutation,
  SalvarConfiguracaoAgendaOnlineLotacaoMutationVariables,
} from 'api/schema'
import { Button, Heading, HFlow, isEqual, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { Form, FormPrompt } from 'components/form'
import clearTypename from 'graphql/clearTypename'
import { useMutation, useQuery } from 'graphql/hooks'
import { useRouter } from 'hooks/useRouter'
import React, { useState } from 'react'
import { FormRenderProps, FormSpy } from 'react-final-form'

import { ProfissionalHeaderIdentifier } from '../components/ProfissionalHeaderIdentifier'
import { ConfiguracaoAgendaOnlineLotacaoPanel } from './ConfiguracaoAgendaOnlineLotacaoPanel'
import { CONFIGURACAO_AGENDA_ONLINE_LOTACAO_QUERY } from './ConfiguracaoAgendaOnlineQuery'
import { SAVE_CONFIGURACAO_AGENDA_ONLINE_LOTACAO_MUTATION } from './SalvarConfiguracaoAgendaOnlineLotacaoMutation'

interface UrlParams {
  lotacaoId: string
  profissionalId: string
}

export function ConfiguracaoAgendaOnlineLotacaoView() {
  const { history, match } = useRouter<UrlParams>()

  const cfgAgendaOnlineQuery = useQuery<ConfiguracaoAgendaOnlineLotacaoQuery>(
    CONFIGURACAO_AGENDA_ONLINE_LOTACAO_QUERY,
    {
      variables: { lotacaoId: match.params.lotacaoId },
      fetchPolicy: 'network-only',
    }
  )

  const [saveMutation] = useMutation<
    SalvarConfiguracaoAgendaOnlineLotacaoMutation,
    SalvarConfiguracaoAgendaOnlineLotacaoMutationVariables
  >(SAVE_CONFIGURACAO_AGENDA_ONLINE_LOTACAO_MUTATION)

  const [submitFailedStatus, setSubmitFailedStatus] = useState(false)

  if (
    !cfgAgendaOnlineQuery.data ||
    !cfgAgendaOnlineQuery.data.lotacao ||
    !cfgAgendaOnlineQuery.data.lotacao.gradeConfiguracaoAgendaOnline
  ) {
    return null
  }

  const { lotacao } = cfgAgendaOnlineQuery.data

  const nomeProfissional = lotacao.profissional.nome

  const handleCancelar = () => {
    history.goBack()
  }

  const handleSubmit = (model: ConfiguracaoAgendaOnlineLotacaoInput) => {
    const variables = {
      configuracaoAgendaOnlineLotacaoInput: {
        lotacaoId: model.lotacaoId,
        configuracoesSemana: model.configuracoesSemana,
      },
    }

    return saveMutation({ variables: clearTypename(variables) }).then(ret => {
      alert('success', 'Configuração de agenda online salva com sucesso')
      setTimeout(() => {
        history.goBack()
      })

      return ret
    })
  }

  const submitFailed = () => {
    setSubmitFailedStatus(true)
  }

  let valueControl
  const WarningController = () => (
    <FormSpy
      subscription={{ values: true }}
      onChange={state => {
        if (!valueControl) {
          valueControl = state
        }

        if (!isEqual(valueControl, state)) {
          setSubmitFailedStatus(false)
        }

        valueControl = state
      }}
    />
  )

  const renderField = (formRenderProps: FormRenderProps) => {
    return (
      <>
        <WarningController />
        <Breadcrumb title='Configuração de agenda online' />
        <Modal open={true} onClose={handleCancelar} size='large' closeOnBackdropClick={false}>
          <FormPrompt />
          <ModalBody>
            <VFlow>
              <Heading level={1}>
                <div>
                  {lotacao.hasConfiguracaoAgendaOnline
                    ? 'Editar configuração de agenda online'
                    : 'Criar configuração de agenda online'}
                </div>
              </Heading>
              <ProfissionalHeaderIdentifier profissional={{ nomeProfissional, lotacao }} />
              <ConfiguracaoAgendaOnlineLotacaoPanel
                submitFailedStatus={submitFailedStatus}
                cfgHorarioAgendaOnline={cfgAgendaOnlineQuery.data}
                formProps={formRenderProps}
              />
            </VFlow>
          </ModalBody>

          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button kind='normal' onClick={handleCancelar}>
                Cancelar
              </Button>
              <Button kind='primary' onClick={formRenderProps.handleSubmit}>
                Salvar
              </Button>
            </HFlow>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  const intialValues = {
    lotacaoId: cfgAgendaOnlineQuery.data.lotacao.id,
    configuracoesSemana: cfgAgendaOnlineQuery.data.lotacao.gradeConfiguracaoAgendaOnline.configuracoesSemana,
  }

  return (
    <Form render={renderField} onSubmit={handleSubmit} initialValues={intialValues} onSubmitFailed={submitFailed} />
  )
}
