import { ApolloError } from 'apollo-client'
import { Alert } from 'bold-ui'
import { useAlert } from 'components/alert'
import { handleValidationError } from 'components/error'
import { useAlteraAdminMutation, useSaveAdminProfissionalMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'
import ProfissionalFormModel from 'view/profissional/components/ProfissionalFormModel'
import convertModelToInput from 'view/profissional/convertModelToInput'

import { ResetInstaladorDadosForm } from './ResetInstaladorDadosForm'

interface ResetNovoProfissionalProps {
  contraChave: string
  cpf: string
}

export function ResetInstaladorNovoProfissionalComponent(props: ResetNovoProfissionalProps) {
  const [saveProfissionalMutation] = useSaveAdminProfissionalMutation()
  const [alteraAdminMutation] = useAlteraAdminMutation()
  const history = useHistory()
  const alert = useAlert()

  const validationInput = {
    cpf: props.cpf,
    contraChave: props.contraChave,
  }

  const submit = (values: ProfissionalFormModel) => {
    const { ...formValues } = values
    const input = convertModelToInput(formValues)

    return saveProfissionalMutation({
      variables: { input: { profissional: input, contraChave: props.contraChave } },
    })
      .then(() => {
        return null
      })
      .catch((error: ApolloError) => {
        const formErrors = handleValidationError(error)
        return formErrors?.profissional
      })
  }

  const initialValues = { cpf: props.cpf, dataNascimento: null, nome: null }

  const onSubmitSucceeded = () => {
    return alteraAdminMutation({ variables: { input: { ...validationInput } } }).then(() => {
      alert('success', 'Administrador da instalação salvo com sucesso')
      history.push('/')
    })
  }

  return (
    <>
      <Alert inline type='info' style={{ marginBottom: '1rem' }}>
        Ao finalizar o cadastro, seu login será o seu CPF e a senha será o seu CNS + esus, caso o profissional não tenha
        CNS cadastrado, será CPF + esus.
      </Alert>

      <ResetInstaladorDadosForm
        onSubmit={submit}
        initialValues={initialValues}
        onSubmitSucceeded={onSubmitSucceeded}
        transformResult={res => res}
      />
    </>
  )
}
