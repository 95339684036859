import { focusBoxShadow, HFlow, Radio, RadioProps, Theme, useStyles } from 'bold-ui'
import React, { CSSProperties, useRef } from 'react'

import { UseFieldProps, usePecField } from '../final-form/useField'

type RadioWithDescField = UseFieldProps<any> & Omit<RadioProps, 'name'>

export interface RadioFieldContainerProps extends Omit<RadioWithDescField, 'label'> {
  children: React.ReactNode
}

export const RadioFieldContainer = (props: RadioFieldContainerProps) => {
  const { input, meta, style, children, ...rest } = usePecField({ type: 'radio', ...props })
  const { classes, css } = useStyles(createStyles)
  const radio = useRef<HTMLInputElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    radio.current.click()
    wrapper.current.focus()
  }

  return (
    <div onClick={handleClick} tabIndex={0} ref={wrapper} className={css(classes.wrapper, style as any)}>
      <HFlow hSpacing={0.5}>
        <Radio {...input} {...rest} label='' inputRef={radio} />
        {children}
      </HFlow>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: {
    cursor: 'pointer',
    padding: '1rem',
    border: `1px solid ${theme.pallete.gray.c60}`,
    borderRadius: 2,
    transition: 'all .2s ease',
    '&:focus': {
      outline: 'none',
      boxShadow: focusBoxShadow(theme),
    },
  } as CSSProperties,
})
