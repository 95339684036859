import { Icon, TextInput, Theme, useStyles } from 'bold-ui'
import { useRovingTabIndex } from 'bold-ui/lib/hooks'
import { composeRefs } from 'bold-ui/lib/util/react'
import CheckPermission from 'components/auth/CheckPermission'
import useSession from 'components/auth/useSession'
import { NormalizedHighlighter } from 'components/highlight/NormalizedHighlighter'
import { analytics } from 'config/firebase'
import React, { CSSProperties, Ref, useEffect, useMemo, useRef, useState } from 'react'
import { NavLinkProps } from 'react-router-dom'
import { Permission } from 'types/Permissions'

import { filterSideMenuItems } from './SideMenuFilterItems'
import { SideMenuGroupTitle } from './SideMenuGroupTitle'
import { SideMenuItem } from './SideMenuItem'
import { SideMenuLink } from './SideMenuLink'
import { SideMenuList } from './SideMenuList'
import { SideMenuSubLink } from './SideMenuSubLink'

export type SideMenuItemConfigChildren = Array<{
  items: SideMenuSubItem[]
  group?: string
}>
export interface SideMenuItemConfig {
  title: string
  icon: React.ReactNode
  permission: Permission
  to?: NavLinkProps['to']
  children?: SideMenuItemConfigChildren
}

export type SideMenuSubItem = Pick<SideMenuItemConfig, 'title' | 'to' | 'permission'>

export interface SideMenuProps {
  items: SideMenuItemConfig[]
  expanded: boolean
  searchInputRef?: Ref<HTMLInputElement>
  onNavigate(): void
}

export function SideMenu(props: SideMenuProps) {
  const { expanded, items, searchInputRef, onNavigate } = props

  const { hasAuthorization } = useSession()
  const [filter, setFilter] = useState('')
  const [expandedItems, setExpandedItems] = useState<Array<NavLinkProps['to']>>([])
  const inputRef = useRef<HTMLInputElement>()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e ? e.target.value : '')
    setExpandedItems(items.map(i => i.to))
  }

  const handleClear = () => {
    setFilter('')
    inputRef.current.focus()
  }

  useEffect(() => {
    if (!expanded) {
      setFilter('')
      setExpandedItems([])
    }
  }, [expanded])

  const filteredItems = useMemo<SideMenuItemConfig[]>(
    () =>
      filterSideMenuItems(items, filter).filter(item =>
        item.children
          ? item.children.filter(childs => childs.items.filter(child => hasAuthorization(child.permission)).length > 0)
              .length > 0
          : true
      ),
    [filter, items, hasAuthorization]
  )
  const handleNavigate = () => {
    const activeElement = document.activeElement as HTMLElement
    activeElement.blur()
    analytics.logEvent('side_menu_click')
    onNavigate()
  }

  const handleExpandItem = (item: SideMenuItemConfig) => () => {
    if (!expandedItems.includes(item.to)) {
      setExpandedItems(currItems => [...currItems, item.to])
    } else {
      setExpandedItems(currItems => currItems.filter(i => i !== item.to))
    }
  }

  const rootRef = useRovingTabIndex({
    getItems: root => Array.from(root.querySelectorAll('a')),
  })
  const { classes } = useStyles(createStyles, expanded)

  return (
    <nav ref={rootRef} className={classes.nav}>
      <SideMenuList>
        <SideMenuItem data-testid='SideMenu.campoPesquisa'>
          {expanded && (
            <TextInput
              inputRef={composeRefs(inputRef, searchInputRef) as any}
              type='search'
              placeholder='Pesquise por módulos'
              icon='zoomOutline'
              value={filter}
              onChange={handleInputChange}
              onClear={handleClear}
            />
          )}
          {!expanded && (
            <span className={classes.searchIcon}>
              <Icon icon='zoomOutline' />
            </span>
          )}
        </SideMenuItem>

        {filteredItems.length === 0 && (
          <SideMenuItem className={classes.noResult}>Nenhum resultado encontrado</SideMenuItem>
        )}

        {filteredItems.map(item => (
          <CheckPermission key={item.title} permission={item.permission}>
            <SideMenuLink
              title={item.title}
              to={item.to}
              icon={item.icon}
              highlight={filter}
              details={expanded}
              onNavigate={handleNavigate}
              expanded={expandedItems.includes(item.to)}
              onExpand={handleExpandItem(item)}
              data-testid={'SideMenu.' + item.title}
            >
              {item.children &&
                item.children.map(
                  (group, groupIndex) =>
                    !!group.items.find(i => hasAuthorization(i.permission)) && (
                      <React.Fragment key={groupIndex}>
                        {group.group && (
                          <SideMenuGroupTitle>
                            <NormalizedHighlighter searchWords={filter} textToHighlight={group.group} />
                          </SideMenuGroupTitle>
                        )}
                        {group.items.map(subitem => (
                          <CheckPermission key={subitem.title} permission={subitem.permission}>
                            <SideMenuSubLink
                              key={subitem.title}
                              title={subitem.title}
                              to={subitem.to}
                              highlight={filter}
                              onNavigate={handleNavigate}
                              data-testid={'SideMenu.' + subitem.title}
                            />
                          </CheckPermission>
                        ))}
                      </React.Fragment>
                    )
                )}
            </SideMenuLink>
          </CheckPermission>
        ))}
      </SideMenuList>
    </nav>
  )
}

export const createStyles = (theme: Theme, expanded: boolean) => ({
  nav: {
    background: theme.pallete.surface.main,
    borderRight: `1px solid ${theme.pallete.divider}`,
    transition: 'width .3s ease',
    padding: '1rem 0',
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.shadows.outer[40],
    overflowX: 'auto',
    overflowY: 'auto',
    height: '100%',

    '&::-webkit-scrollbar': {
      display: !expanded && 'none',
      width: '0.25rem',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.pallete.gray.c90,
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '1rem',
      backgroundColor: theme.pallete.gray.c70,
    },
  } as CSSProperties,
  searchIcon: {
    background: theme.pallete.gray.c90,
    border: `1px solid ${theme.pallete.divider}`,
    display: 'inline-flex',
    alignItems: 'center',
    padding: 'calc(0.25rem - 1px) calc(0.5rem - 1px)',
    marginLeft: '-5px', // consideramos a borda direita dos items ativos, para centralizar o ícone de pesquisa
    borderRadius: theme.radius.input,
  } as CSSProperties,
  noResult: {
    marginTop: '0.75rem',
    textAlign: 'center',
  } as CSSProperties,
})
