import {
  Button,
  HFlow,
  Icon,
  isEqual,
  Omit,
  Select,
  SelectMenuItem,
  SelectProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  useStyles,
} from 'bold-ui'
import { SelectDownshiftMenuProps } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import React from 'react'

import { UseFieldProps, usePecField } from '../final-form/useField'
import { getFieldError } from '../final-form/util'

type BaseField<T> = Omit<SelectProps<T>, 'name'> & UseFieldProps<T>

export interface SelectDataTableFieldProps<T> extends BaseField<T> {
  /**
   * Para adicionar mais botões ao lado da lixeira
   */
  adicionalButtons?: Function

  /**
   * Máximo de items na lista
   */
  maxItems?: number
}

export function SelectDataTableField<T = any>(props: SelectDataTableFieldProps<T>) {
  const {
    adicionalButtons,
    maxItems,
    itemToString,
    renderItem,
    input: { name, value, onChange, onBlur, onFocus },
    meta,
    ...rest
  } = usePecField(props)

  const { classes } = useStyles(createStyles)

  const hasItem = o1 => {
    return (value || []).find(o2 => isEqual(o1, o2))
  }

  const handleSelectItem = (item: T) => {
    if (item && !hasItem(item)) {
      onChange([...value, item])
    }
  }

  const handleExcluirItem = (item: T) => () => {
    onChange(value.filter(i => i !== item))
  }

  const emptyItemToString = () => null

  const Item = (itemProps: any) => {
    return <SelectDataTableItem hasItem={hasItem} {...itemProps} />
  }

  const tooltipText = maxItems && value.length >= maxItems ? 'Número máximo de itens atingido.' : ''

  return (
    <div className={classes.container}>
      <div className={classes.select}>
        <Tooltip text={tooltipText} placement='auto'>
          <Select<T>
            name={name}
            onChange={handleSelectItem}
            clearable={false}
            error={getFieldError(meta)}
            components={{ Item }}
            disabled={value.length >= maxItems}
            renderItem={renderItem || itemToString}
            itemToString={emptyItemToString}
            onBlur={onBlur}
            onFocus={onFocus}
            {...rest}
            required={props.required && !(value.length >= maxItems)}
          />
        </Tooltip>
      </div>

      {value && (
        <Table style={classes.table}>
          <TableBody>
            {value.map(
              (item: T, index: number) =>
                item && (
                  <TableRow key={index}>
                    <TableCell>{renderItem ? renderItem(item) : itemToString(item)}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      {adicionalButtons && adicionalButtons(item)}
                      <Tooltip text='Excluir'>
                        <Button size='small' skin='ghost' onClick={handleExcluirItem(item)}>
                          <Icon icon='trashOutline' />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    border: `1px solid ${theme.pallete.divider}`,
    borderRadius: 2,
  },
  select: {
    margin: '1rem',
  },
  table: {
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    'tr:last-child td': {
      borderBottom: 'none',
    },
    td: {
      padding: '0.5rem 1rem',
    },
  },
})

const SelectDataTableItem = (p: SelectDownshiftMenuProps<any> & { item: any; index: number; hasItem: Function }) => {
  const {
    renderItem,
    item,
    index,
    hasItem,
    downshift: { itemToString, highlightedIndex, getItemProps },
  } = p

  const defaultMenuItemProps = {
    selected: highlightedIndex === index,
  }

  const defaultDisabledMenuItemProps = {
    style: {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
    ...defaultMenuItemProps,
  }

  const menuItemProps = hasItem(item) ? defaultDisabledMenuItemProps : defaultMenuItemProps
  const setOpacity = hasItem(item) ? { style: { opacity: 0.5 } } : { style: {} }

  return (
    <SelectMenuItem {...menuItemProps} {...getItemProps({ item })}>
      <HFlow alignItems='center' justifyContent='space-between'>
        <div {...setOpacity}>{renderItem ? renderItem(item) : itemToString(item)}</div>

        {hasItem(item) && (
          <HFlow alignItems='center' hSpacing={0.2} style={{ color: '#217b00' }}>
            <Icon icon='checkCircleOutline' size={1} style={{ display: 'block' }} />
            Adicionado
          </HFlow>
        )}
      </HFlow>
    </SelectMenuItem>
  )
}
