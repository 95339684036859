import { InserirMunicipioResponsavel, InserirMunicipioResponsavelVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const INSERIR_MUNICIPIO_RESPONSAVEL = gql`
  mutation InserirMunicipioResponsavel($input: MunicipioResponsavelInput!) {
    inserirMunicipioResponsavel(input: $input) {
      id
      profissional {
        nome
      }
      municipio {
        nome
      }
      habilitado
      ativo
      dataAdicao
      dataInativacao
    }
  }
`

export function useInserirMunicipioResponsavel() {
  return useMutation<InserirMunicipioResponsavel, InserirMunicipioResponsavelVariables>(INSERIR_MUNICIPIO_RESPONSAVEL)
}
