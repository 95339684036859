import { DropdownItem, Text, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { confirm } from 'components/confirm'
import { DropdownButton } from 'components/dropdown/DropdownButton'
import { DropdownLink } from 'components/dropdown/DropdownLink'
import { useErrorHandler } from 'components/error'
import { useApolloClient } from 'graphql/hooks'
import { AgendaEmConflitoDocument } from 'graphql/hooks.generated'
import { AgendaEmConflitoQuery, AgendaEmConflitoQueryVariables } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'
import { useDesativarConfiguracaoAgendaOnlineLotacao } from 'view/profissional/agenda/DesativarConfiguracaoAgendaOnlineLotacao'
import { useExcluirConfiguracaoAgendaLotacao } from 'view/profissional/agenda/ExcluirConfiguracaoAgendaLotacaoMutation'

import { LotacaoProfissional } from '../ProfissionalAcessosView'
import { useAlterarAtivacaoLotacao } from './AlterarAtivacaoLotacaoMutation'
import { useExcluirLotacao } from './ExcluirLotacaoMutation'
import { getInconsistenciasLotacaoAgendaOnline } from './LotacaoAgendaOnlineStatus'

export interface LotacaoAcessoCardDropdownProps {
  agendaOnlineAtiva: boolean
  lotacao: LotacaoProfissional
  possuiCns: boolean
  refetch(): any
}

export function LotacaoAcessoCardDropdown(props: LotacaoAcessoCardDropdownProps) {
  const { match } = useRouter()
  const alert = useAlert()
  const { hasAuthorization } = useSession({ fetchPolicy: 'cache-only' })

  const handleRejection = useErrorHandler()
  const [excluirLotacao] = useExcluirLotacao()
  const [alterarAtivacaoLotacao] = useAlterarAtivacaoLotacao()
  const [excluirCfgAgendaMutation] = useExcluirConfiguracaoAgendaLotacao()
  const [desativarAgendaOnlineMutation] = useDesativarConfiguracaoAgendaOnlineLotacao()
  const apollo = useApolloClient()

  const { agendaOnlineAtiva, lotacao, possuiCns, refetch } = props
  const inconsistenciasLotacaoAgendaOnline = getInconsistenciasLotacaoAgendaOnline(lotacao)
  const lotacaoElegivelAgendaOnline = !inconsistenciasLotacaoAgendaOnline
  const permissaoExclusao = lotacao.actions.permissaoExclusao
  const permissaoAtivacaoInativacao = lotacao.actions.permissaoAtivacaoInativacao
  const permissaoConfigurarAgenda = lotacao.actions.permissaoConfigurarAgenda
  const permissaoConfigurarAgendaOnline = lotacao.actions.permissaoConfigurarAgendaOnline
  const permissaoFecharAgenda = lotacao.actions.permissaoFecharAgenda

  const handleAlternarAtivacaoLotacao = () => {
    if (lotacao.ativo) {
      alternarAtivacaoLotacao()
    } else {
      ativarLotacaoAgendaSemConflito()
    }
  }

  const alternarAtivacaoLotacao = () => {
    return alterarAtivacaoLotacao({ variables: { lotacaoId: lotacao.id } })
      .then(ret => {
        const data = ret && ret.data
        lotacao.hasAgendamentoFuturo && !data.alterarAtivacaoLotacao
          ? alert('warning', 'Lotação foi inativada, mas possuía agendamentos futuros. Verifique no módulo Agenda.')
          : alert('success', `Lotação ${!data.alterarAtivacaoLotacao ? 'inativada' : 'ativada'} com sucesso`)

        refetch()
      })
      .finally(refetch())
      .catch(handleRejection)
  }

  const ativarLotacaoAgendaSemConflito = () => {
    apollo
      .query<AgendaEmConflitoQuery, AgendaEmConflitoQueryVariables>({
        query: AgendaEmConflitoDocument,
        variables: { id: lotacao.id },
        fetchPolicy: 'network-only',
      })
      .then(res => {
        if (res.data.agendaEmConflito) {
          confirm({
            title: 'Deseja ativar a lotação e excluir a agenda?',
            body: 'Esta lotação possui uma agenda com conflitos de horário.',
            confirmLabel: 'Ativar e excluir agenda',
            type: 'danger',
            onConfirm: async () => {
              await excluirAgendaSemConfirmacao()
              await alternarAtivacaoLotacao()
            },
          })()
        } else {
          alternarAtivacaoLotacao()
        }
      })
      .catch(handleRejection)
  }

  const excluirAgendaSemConfirmacao = () => {
    return excluirCfgAgendaMutation({ variables: { id: lotacao.id } })
      .then(() => {
        alert('success', 'A agenda da lotação foi excluída com sucesso')
        props.refetch()
      })
      .catch(handleRejection)
  }

  const handleExcluirLotacao = confirm({
    title: 'Deseja excluir a lotação?',
    confirmLabel: 'Excluir',
    type: 'danger',
    onConfirm: () => {
      return excluirLotacao({ variables: { lotacaoId: lotacao.id } })
        .then(ret => {
          alert('success', 'Lotação excluída com sucesso')
          refetch()
        })
        .catch(handleRejection)
    },
  })

  const renderConfirmBody = () => {
    if (lotacao.hasAgendamentoFuturo && lotacao.hasConfiguracaoAgendaOnline) {
      return (
        <>
          <Text component='p' style={{ marginBottom: '1rem' }}>
            Existem agendamentos futuros para esta lotação. Após a exclusão da configuração ainda será possível
            visualizá-los na agenda da lotação do profissional.
          </Text>
          <Text component='p'>A configuração de agenda online deste profissional também será excluída.</Text>
        </>
      )
    }

    if (lotacao.hasAgendamentoFuturo) {
      return 'Existem agendamentos futuros para esta lotação. Após a exclusão da configuração ainda será possível visualizá-los na agenda da lotação do profissional.'
    }
    if (lotacao.hasConfiguracaoAgendaOnline) {
      return 'A configuração de agenda online deste profissional também será excluída.'
    }
    return ''
  }

  const handleExcluirAgenda = () => {
    confirm({
      title: 'Deseja excluir a agenda desta lotação?',
      body: renderConfirmBody(),
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: excluirAgendaSemConfirmacao,
    })()
  }

  const handleDesativarAgendaOnline = () => {
    confirm({
      title: 'Deseja excluir a agenda online desta lotação?',
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return desativarAgendaOnlineMutation({ variables: { id: lotacao.id } })
          .then(() => {
            alert('success', 'A agenda online da lotação foi excluída com sucesso')
            props.refetch()
          })
          .catch(handleRejection)
      },
    })()
  }

  const txtAtivarInativar = lotacao.ativo ? 'Inativar' : 'Ativar'
  const txtProfSemCNS = !lotacao.ativo && !possuiCns && 'Profissional sem CNS não pode ter lotação'

  const items: React.ReactNode[] = []
  if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao)) {
    items.push(
      <Tooltip key='ativar/inativar' text={permissaoAtivacaoInativacao.hint ?? txtProfSemCNS}>
        <DropdownItem
          key='ativar/inativar'
          onClick={handleAlternarAtivacaoLotacao}
          disabled={!permissaoAtivacaoInativacao.enabled || (!lotacao.ativo && !possuiCns)}
        >
          {txtAtivarInativar}
        </DropdownItem>
      </Tooltip>
    )
  }
  if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.configurarAgenda)) {
    if (lotacao.ativo) {
      items.push(
        <Tooltip key='criarAgenda/editarAgenda' text={permissaoConfigurarAgenda.hint}>
          <DropdownLink
            key='criarAgenda/editarAgenda'
            to={`${match.url}/configuracaoAgenda/${lotacao.id}`}
            disabled={!permissaoConfigurarAgenda.enabled}
          >
            {lotacao.hasConfiguracaoAgenda ? 'Editar configuração de agenda' : 'Criar configuração de agenda'}
          </DropdownLink>
        </Tooltip>
      )
    } else {
      items.push(
        <Tooltip
          key='criarAgenda/editarAgenda'
          text={
            !lotacao.ativo
              ? 'Não é possível configurar agenda em lotação inativa'
              : permissaoConfigurarAgenda.hint || ''
          }
        >
          <DropdownItem disabled={!lotacao.ativo || permissaoConfigurarAgenda.enabled}>
            {lotacao.hasConfiguracaoAgenda ? 'Editar configuração de agenda' : 'Criar configuração de agenda'}{' '}
          </DropdownItem>
        </Tooltip>
      )
    }
    if (lotacao.hasConfiguracaoAgenda) {
      items.push(
        <Tooltip key='excluirAgenda' text={permissaoConfigurarAgenda.hint}>
          <DropdownItem
            key='excluirAgenda'
            type='danger'
            onClick={handleExcluirAgenda}
            disabled={!permissaoConfigurarAgenda.enabled}
          >
            Excluir agenda
          </DropdownItem>
        </Tooltip>
      )
    }
  }
  if (agendaOnlineAtiva) {
    if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.configurarAgendaOnline)) {
      items.push(
        <Tooltip
          key='criarAgendaOnline/editarAgendaOnline'
          text={
            lotacaoElegivelAgendaOnline
              ? ''
              : inconsistenciasLotacaoAgendaOnline || permissaoConfigurarAgendaOnline.hint
          }
        >
          <DropdownLink
            to={`${match.url}/configuracaoAgendaOnline/${lotacao.id}`}
            disabled={!lotacaoElegivelAgendaOnline || !permissaoConfigurarAgendaOnline.enabled}
          >
            {lotacao.hasConfiguracaoAgendaOnline
              ? 'Editar configuração de agenda online'
              : 'Criar configuração de agenda online'}
          </DropdownLink>
        </Tooltip>
      )
      if (lotacao.hasConfiguracaoAgendaOnline) {
        items.push(
          <Tooltip key='excluirAgendaOnline' text={permissaoConfigurarAgendaOnline.hint}>
            <DropdownItem
              key='excluirAgendaOnline'
              type='danger'
              onClick={handleDesativarAgendaOnline}
              disabled={!permissaoConfigurarAgendaOnline.enabled}
            >
              Excluir agenda online
            </DropdownItem>
          </Tooltip>
        )
      }
    }
  }
  if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.fecharAgenda)) {
    if (lotacao.ativo && lotacao.hasConfiguracaoAgenda) {
      items.push(
        <Tooltip key='excluir' text={permissaoFecharAgenda.hint}>
          <DropdownLink
            key='visualizarFechamentoAgenda'
            to={`${match.url}/acessos/fechamento/${lotacao.id}`}
            disabled={!permissaoFecharAgenda.enabled}
          >
            Visualizar fechamentos da agenda
          </DropdownLink>
        </Tooltip>
      )
    }
  }
  if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao)) {
    items.push(
      <Tooltip key='excluir' text={permissaoExclusao.hint}>
        <DropdownItem type='danger' onClick={handleExcluirLotacao} disabled={!permissaoExclusao.enabled}>
          Excluir
        </DropdownItem>
      </Tooltip>
    )
  }
  if (items.length === 0) {
    return null
  }

  return <DropdownButton>{items}</DropdownButton>
}
