import { SolicitacaoRecuperarSenhaMutation, SolicitacaoRecuperarSenhaMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const useSolicitarRedefinirSenhaMutation = () => {
  return useMutation<SolicitacaoRecuperarSenhaMutation, SolicitacaoRecuperarSenhaMutationVariables>(
    SOLICITAR_RECUPERAR_SENHA_MUTATION,
    {}
  )
}

export const SOLICITAR_RECUPERAR_SENHA_MUTATION = gql`
  mutation SolicitacaoRecuperarSenhaMutation($input: SolicitacaoRecuperarSenhaInput!) {
    solicitarRecuperarSenha(input: $input)
  }
`
