import { Modal, ModalBody } from 'bold-ui'
import {
  useVisualizarNovidadesQuery,
  useVisualizouNovidadesMutation,
  VisualizarNovidadesDocument,
} from 'graphql/hooks.generated'
import React, { useState } from 'react'

import { NewsInternalPanelV4_10 as NewsInternalPanelVQuatroUm } from './NewsInternalPanelV4_10'

export const NewsModal = () => {
  const { data } = useVisualizarNovidadesQuery({ fetchPolicy: 'cache-first' })
  const visualizarNovidades = data?.sessao?.profissional?.usuario?.visualizouNovidades

  const [isOpen, setIsOpen] = useState(true)

  const [visualizouNews] = useVisualizouNovidadesMutation({
    refetchQueries: [{ query: VisualizarNovidadesDocument }],
  })

  const handleModalClose = () => {
    setIsOpen(false)
    return visualizouNews()
  }

  return (
    <>
      {visualizarNovidades === false && (
        <Modal onClose={handleModalClose} open={isOpen} size='large'>
          <ModalBody style={{ width: '100%' }}>
            <NewsInternalPanelVQuatroUm />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}
