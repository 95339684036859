import { msg } from '..'

const ONLY_LETTERS = /^([a-zA-ZáéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜ '\\s])+$/

export function nome(inputValue: string) {
  if (!inputValue) {
    return null
  }

  if (!ONLY_LETTERS.test(inputValue)) {
    return msg('nomeInvalido', inputValue)
  }

  // Deve possuir ao menos 5 caracteres
  if (inputValue.length < 5) {
    return msg('minLength', inputValue, 5)
  }

  if (inputValue.length > 100) {
    return msg('maxLength', inputValue, 100)
  }

  const nomeEspacos = inputValue && inputValue.trim().replace(/\s+/g, ' ')
  const nomes: string[] = nomeEspacos && nomeEspacos.split(' ')

  if (nomes.length < 2) {
    return msg('nome', inputValue)
  }

  if (nomes[0].length === 1 && nomes[1].length === 1) {
    return msg('nomeOneLetter', inputValue)
  }

  if (nomes.length >= 1) {
    if (nomes[0].length < 2 || nomes[1].length < 2) {
      return msg('nomeOneLetter', inputValue)
    }

    if (nomes.length === 2 && nomes[0].length === 2 && nomes[1].length === 2) {
      return msg('nomeTwoLetters', inputValue)
    }
  }

  return null
}
