import { Button, Icon, Tooltip } from 'bold-ui'
import { alert } from 'components/alert'
import { confirm } from 'components/confirm'
import { useErrorHandler } from 'components/error'
import React from 'react'

import { TipoServicoItem } from '../TipoServicoTable'
import { useExcluirTipoServico } from './ExcluirTipoServicoMutation'

export interface TipoServicoRemoveComponentProps {
  tipoServico: TipoServicoItem
  onRemove(): void
}

export function TipoServicoRemoveComponent(props: TipoServicoRemoveComponentProps) {
  const { tipoServico, onRemove } = props

  const [excluir] = useExcluirTipoServico()
  const errorHandler = useErrorHandler()

  const handleClick = confirm({
    title: `Deseja excluir o tipo de serviço?`,
    type: 'danger',
    confirmLabel: 'Excluir',
    onConfirm: () =>
      excluir({ variables: { id: tipoServico.id } })
        .then(success => {
          alert('success', 'Tipo de serviço excluído com sucesso.')
          onRemove()
        })
        .catch(errorHandler),
  })

  return (
    <Tooltip text='Excluir'>
      <Button size='small' skin='ghost' onClick={handleClick}>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )
}
