import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Box } from 'components/Box'
import React from 'react'
import Permissions from 'types/Permissions'

import { CompartilhamentoProntuarioEditComponent } from './CompartilhamentoProntuarioEditComponent'

export function CompartilhamentoProntuarioViewBox() {
  return (
    <VFlow vSpacing={2}>
      <CheckPermission permission={Permissions.configuracaoMunicipal.configurarCompartilhamentoDeProntuarios}>
        <Box>
          <CompartilhamentoProntuarioEditComponent />
        </Box>
      </CheckPermission>
    </VFlow>
  )
}
