import { Button, Heading, HeadingSection, HFlow, Icon, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { confirm } from 'components/confirm'
import { CheckboxField, ErrorField, Form } from 'components/form'
import { FormPrompt } from 'components/form/FormPrompt'
import clearTypename from 'graphql/clearTypename'
import { useSalvarConfiguracaoHorariosMunicipioMutation } from 'graphql/hooks.generated'
import { ConfiguracaoAgendaMunicipioQuery } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import {
  ConfiguracaoAgendaFormType,
  ConfiguracaoAgendaPanel,
} from 'view/configuracaoAgenda/component/ConfiguracaoAgendaPanel'
import {
  buildPeriodoInconsistenteMsg,
  clearHorariosFinalDeSemana,
  hasFinalDeSemanaPreenchidoChecker,
} from 'view/configuracaoAgenda/validator/base/ConfiguracaoHorarioAgendaHelper'
import { cfgHorarioAgendaValidator } from 'view/configuracaoAgenda/validator/base/ConfiguracaoHorarioAgendaValidator'

export interface ConfiguracaoAgendaMunicipioPanelProps {
  cfgAgendaMunicipio: ConfiguracaoAgendaMunicipioQuery['configuracaoHorariosMunicipio']
}

export function ConfiguracaoAgendaMunicipioPanel(props: ConfiguracaoAgendaMunicipioPanelProps) {
  const [saveCfgHorarioMutation] = useSalvarConfiguracaoHorariosMunicipioMutation()
  const alert = useAlert()

  const handleReset = (formRenderProps: FormRenderProps<ConfiguracaoAgendaFormType>) => () => {
    if (!formRenderProps.pristine) {
      confirm({
        title: 'Deseja cancelar a edição?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não',
        confirmLabel: 'Sim, cancelar edição',
        onConfirm: () => formRenderProps.form.reset(),
      })()
    }
  }

  const handleCfgHorariosSubmit = (form: ConfiguracaoAgendaFormType) => {
    const { titleMsg, bodyMsg } = buildPeriodoInconsistenteMsg(form.configuracaoHorarioAgenda)

    if (titleMsg || bodyMsg) {
      confirm({
        title: titleMsg,
        body: bodyMsg,
        cancelLabel: 'Cancelar',
        confirmLabel: 'Salvar configuração',
        onConfirm: () => {
          return doSaveCfgAgenda(form)
        },
      })()
    } else {
      return doSaveCfgAgenda(form)
    }
  }

  const doSaveCfgAgenda = (model: ConfiguracaoAgendaFormType) => {
    const variables = {
      configuracaoAgendaMunicipalInput: {
        id: model.id,
        configuracaoHorarioAgendaInput: model.configuracaoHorarioAgenda,
      },
    }

    return saveCfgHorarioMutation({
      variables: clearTypename(variables),
    }).then(() => {
      alert('success', 'Configuração da agenda padrão salva com sucesso')
    })
  }

  const handleMostrarFinalDeSemanaChanged = (formRenderProps: FormRenderProps<ConfiguracaoAgendaFormType>) => e => {
    clearHorariosFinalDeSemana(formRenderProps, e.target.checked)
  }

  const renderCfgAgenda = (formRenderProps: FormRenderProps<ConfiguracaoAgendaFormType>) => {
    return (
      <VFlow vSpacing={0}>
        <FormPrompt />
        <HeadingSection
          level={2}
          title={
            <>
              Configuração da agenda padrão dos profissionais
              <Tooltip text='A configuração da Agenda Padrão é aplicada às agendas criadas durante a importação do CNES.'>
                <Icon icon='infoCircleFilled' size={1} style={{ marginLeft: '4px' }} />
              </Tooltip>
            </>
          }
        >
          <Box style={{ borderBottom: 'none' }}>
            <HFlow>
              <Heading level={3}>Períodos de trabalho</Heading>
              <CheckboxField
                name='mostrarFinalDeSemana'
                style={{ display: 'inline' }}
                label='Mostrar fim de semana'
                onChange={handleMostrarFinalDeSemanaChanged(formRenderProps)}
              />
            </HFlow>
            <ErrorField name='configuracaoHorarioAgendaTitleErrorField' />
          </Box>
          <Box style={{ border: 'none', padding: '0rem' }}>
            <ConfiguracaoAgendaPanel configuracaoSemana={formRenderProps.values} formProps={formRenderProps} />
          </Box>
        </HeadingSection>
        <Box style={{ borderTop: 'none', paddingTop: '0.5rem', paddingBottom: '0.5rem;' }}>
          <HFlow justifyContent='flex-end' alignItems='center'>
            <Button size='small' kind='normal' skin='outline' onClick={handleReset(formRenderProps)}>
              Cancelar
            </Button>
            <Button size='small' kind='primary' onClick={formRenderProps.handleSubmit}>
              Salvar
            </Button>
          </HFlow>
        </Box>
      </VFlow>
    )
  }

  const configuracaoMunicipio: ConfiguracaoAgendaFormType = {
    id: props.cfgAgendaMunicipio.id,
    configuracaoHorarioAgenda: props.cfgAgendaMunicipio.configuracaoHorarioAgenda,
    mostrarFinalDeSemana: hasFinalDeSemanaPreenchidoChecker(props.cfgAgendaMunicipio.configuracaoHorarioAgenda),
  }

  return (
    <Form<ConfiguracaoAgendaFormType>
      render={renderCfgAgenda}
      initialValues={configuracaoMunicipio}
      validate={cfgHorarioAgendaValidator}
      onSubmit={handleCfgHorariosSubmit}
    />
  )
}
