import { Button, Icon, Text, Theme, useStyles } from 'bold-ui'
import React from 'react'

export interface NewsVersionTitleProps {
  children?: React.ReactNode
  isVisible: boolean
  handleLinkClick: any
  version: string
}

export function NewsVersionTitle(props: NewsVersionTitleProps) {
  const createStyles = (theme: Theme) => ({
    buttonCollapse: {
      position: 'relative',
      border: 'none',
      borderRadius: 2,
      padding: 0,
      transition: 'background 0.2s ease',
      backgroundColor: '#f0f0f5',
      fontWeight: 'inherit',
      width: '100%',
      height: 40,
      '&:focus': {
        boxShadow: 'none',
      },
      justifyContent: 'flex-start',
    } as React.CSSProperties,

    versionInfo: {
      paddingLeft: '12px',
      fontSize: '20px',
      lineHeight: '30px',
    } as React.CSSProperties,

    angleIcon: {
      position: 'absolute',
      right: '1%',
    } as React.CSSProperties,
  })
  const { classes } = useStyles(createStyles)
  const { isVisible, handleLinkClick, version } = props

  return (
    <Button style={classes.buttonCollapse} onClick={handleLinkClick}>
      <Text style={classes.versionInfo}>
        <b>{version}</b>
      </Text>
      <Icon icon={isVisible ? 'angleUp' : 'angleDown'} style={classes.angleIcon} />
    </Button>
  )
}
