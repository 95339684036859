import { TipoAcesso } from 'api/schema'
import {
  GrupoCondicaoEnum,
  IdentidadeGeneroEnum,
  NacionalidadeEnum,
  OrientacaoSexualEnum,
  SexoEnum,
  TipoSanguineoEnum,
} from 'graphql/types.generated'

export const tipoAcesso: Record<TipoAcesso, string> = {
  [TipoAcesso.ADMINISTRADOR_GERAL]: 'Instalador',
  [TipoAcesso.ADMINISTRADOR_MUNICIPAL]: 'Administrador municipal',
  [TipoAcesso.GESTOR_ESTADUAL]: 'Gestor estadual',
  [TipoAcesso.GESTOR_MUNICIPAL]: 'Gestor municipal',
  [TipoAcesso.LOTACAO]: 'Lotação',
}

export const identidadeGenero: Record<IdentidadeGeneroEnum, string> = {
  [IdentidadeGeneroEnum.HOMEM_TRANS]: 'Homem transsexual',
  [IdentidadeGeneroEnum.MULHER_TRANS]: 'Mulher transsexual',
  [IdentidadeGeneroEnum.TRAVESTI]: 'Travesti',
  [IdentidadeGeneroEnum.OUTRO_IDENTIDADE_GENERO]: 'Outro',
}

export const sexo: Record<SexoEnum, string> = {
  [SexoEnum.MASCULINO]: 'Masculino',
  [SexoEnum.FEMININO]: 'Feminino',
  [SexoEnum.IGNORADO]: 'Ignorado',
  [SexoEnum.AMBOS]: 'Ambos',
  [SexoEnum.NAO_INFORMADO]: 'Não informado',
}

export const tipoSanguineo: Record<TipoSanguineoEnum, string> = {
  [TipoSanguineoEnum.A_POSITIVO]: 'A+',
  [TipoSanguineoEnum.A_NEGATIVO]: 'A-',
  [TipoSanguineoEnum.B_POSITIVO]: 'B+',
  [TipoSanguineoEnum.B_NEGATIVO]: 'B-',
  [TipoSanguineoEnum.AB_POSITIVO]: 'AB+',
  [TipoSanguineoEnum.AB_NEGATIVO]: 'AB-',
  [TipoSanguineoEnum.O_POSITIVO]: 'O+',
  [TipoSanguineoEnum.O_NEGATIVO]: 'O-',
}

export const orientacaoSexual: Record<OrientacaoSexualEnum, string> = {
  [OrientacaoSexualEnum.HETEROSSEXUAL]: 'Heterossexual',
  [OrientacaoSexualEnum.HOMOSSEXUAL]: 'Homossexual (gay / lésbica)',
  [OrientacaoSexualEnum.BISSEXUAL]: 'Bissexual',
  [OrientacaoSexualEnum.OUTRO_ORIENTACAO_SEXUAL]: 'Outro',
}

export const nacionalidade: Record<NacionalidadeEnum, string> = {
  [NacionalidadeEnum.BRASILEIRA]: 'Brasileira',
  [NacionalidadeEnum.NATURALIZADA]: 'Naturalizado',
  [NacionalidadeEnum.ESTRANGEIRA]: 'Estrangeiro',
}

export const grupoCondicao: Record<GrupoCondicaoEnum, string> = {
  [GrupoCondicaoEnum.ASMA]: 'Asma',
  [GrupoCondicaoEnum.AVC]: 'AVC',
  [GrupoCondicaoEnum.DENGUE]: 'Dengue',
  [GrupoCondicaoEnum.DESNUTRICAO]: 'Desnutrição',
  [GrupoCondicaoEnum.DIABETES]: 'Diabetes',
  [GrupoCondicaoEnum.DOENCA_CARDIACA]: 'Doença cardíaca',
  [GrupoCondicaoEnum.DPOC]: 'DPOC',
  [GrupoCondicaoEnum.DST]: 'DST',
  [GrupoCondicaoEnum.HANSENIASE]: 'Hanseníase',
  [GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL]: 'Hipertensão arterial',
  [GrupoCondicaoEnum.OBESIDADE]: 'Obesidade',
  [GrupoCondicaoEnum.GRAVIDEZ]: 'Gravidez',
  [GrupoCondicaoEnum.INFARTO]: 'Infarto',
  [GrupoCondicaoEnum.REABILITACAO]: 'Reabilitação',
  [GrupoCondicaoEnum.RINS]: 'Rins',
  [GrupoCondicaoEnum.SAUDE_MENTAL]: 'Saúde mental',
  [GrupoCondicaoEnum.SAUDE_SEXUAL_E_REPRODUTIVA]: 'Saúde sexual e reprodutiva',
  [GrupoCondicaoEnum.TABAGISMO]: 'Tabagismo',
  [GrupoCondicaoEnum.TUBERCULOSE]: 'Tuberculose',
  [GrupoCondicaoEnum.USUARIO_DE_ALCOOL]: 'Usuário de álcool',
  [GrupoCondicaoEnum.USUARIO_DE_OUTRAS_DROGAS]: 'Usuário de outras drogas',
}

export const idGrupoCondicao: Record<GrupoCondicaoEnum, ID> = {
  [GrupoCondicaoEnum.ASMA]: 1,
  [GrupoCondicaoEnum.AVC]: 2,
  [GrupoCondicaoEnum.DENGUE]: 3,
  [GrupoCondicaoEnum.DESNUTRICAO]: 4,
  [GrupoCondicaoEnum.DIABETES]: 5,
  [GrupoCondicaoEnum.DOENCA_CARDIACA]: 6,
  [GrupoCondicaoEnum.DPOC]: 7,
  [GrupoCondicaoEnum.DST]: 8,
  [GrupoCondicaoEnum.HANSENIASE]: 9,
  [GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL]: 10,
  [GrupoCondicaoEnum.OBESIDADE]: 11,
  [GrupoCondicaoEnum.GRAVIDEZ]: 12,
  [GrupoCondicaoEnum.INFARTO]: 13,
  [GrupoCondicaoEnum.REABILITACAO]: 14,
  [GrupoCondicaoEnum.RINS]: 15,
  [GrupoCondicaoEnum.SAUDE_MENTAL]: 16,
  [GrupoCondicaoEnum.SAUDE_SEXUAL_E_REPRODUTIVA]: 17,
  [GrupoCondicaoEnum.TABAGISMO]: 18,
  [GrupoCondicaoEnum.TUBERCULOSE]: 19,
  [GrupoCondicaoEnum.USUARIO_DE_ALCOOL]: 20,
  [GrupoCondicaoEnum.USUARIO_DE_OUTRAS_DROGAS]: 21,
}
