import { Alert, Heading, useStyles } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContainer } from 'components/layout/PageContainer'
import { useEsusViewMountTargetQuery } from 'graphql/hooks.generated'
import React, { useEffect, useRef } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { useEsusViewState } from './EsusViewContext'

export default function EsusViewMountTarget() {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const { data: infoData } = useEsusViewMountTargetQuery({ fetchPolicy: 'cache-first' })

  const { show, uri: esusPath } = useEsusViewState()

  const { classes } = useStyles(theme => ({
    root: {
      backgroundColor: theme.pallete.surface.background,
      display: show ? 'flex' : 'none',
      flexDirection: 'column',
      flexGrow: 1,
      paddingTop: '1rem',
    },
    iframe: {
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      display: show ? 'inherit' : 'none',
      flexGrow: 1,
    },
  }))

  useEffect(() => {
    const messageListener = (e: MessageEvent) => {
      setTimeout(() => {
        const messageData = parseMessage(e)
        if (messageData.type && messageData.type !== 'resetTimer') {
          window.postMessage({ type: 'resetTimer' }, '*')
        }
        if (messageData.type === 'height' && iframeRef.current) {
          iframeRef.current.height = messageData.height
        } else if (messageData.type === 'sendToIframe' && iframeRef.current) {
          iframeRef.current.contentWindow.postMessage(JSON.stringify(messageData.message), '*')
        }
      })
    }
    window.addEventListener('message', messageListener)

    return () => window.removeEventListener('message', messageListener)
  }, [])

  if (!infoData || !infoData.info) {
    return null
  }

  const url = `/esus/#${esusPath}`

  return (
    <div className={classes.root}>
      {!infoData.info.oldPecConnected && show && (
        <Alert type='danger' inline>
          Não foi possível realizar conexão com o e-SUS APS. Entre em contato com o administrador.
        </Alert>
      )}

      {infoData.info.oldPecConnected && (
        <>
          {show && <EsusBreadcrumbs iframeUrl={esusPath} />}
          {show && <EsusTitle iframeUrl={esusPath} />}

          <iframe
            ref={iframeRef}
            title='e-sus'
            frameBorder='0'
            scrolling='no'
            src={url}
            className={classes.iframe}
            width='100%'
          />
        </>
      )}
    </div>
  )
}

const breadcrumbTitles = {
  // Agenda
  '/pec/user/agenda': 'Agenda',
  '/pec/user/agenda/listaagendamentos': 'Agendamentos',
  '/pec/user/agenda/prontuario': 'Prontuário',
  '/pec/user/agenda/detail/cidadao': 'Cidadãos',
  '/pec/user/agenda/detail/cidadao/detail': 'Informações do cidadão',
  '/pec/user/agenda/detail/cidadao/agendamento': 'Agendamentos',
  '/pec/user/agenda/detail/cidadao/historicoUnificacao': 'Histórico de unificações',
  '/pec/user/agenda/detail/cidadao/prontuario': 'Prontuário',
  '/pec/user/agenda/detail/cidadao/prontuario/dadoscadastrais': 'Visualizar dados cadastrais',
  '/pec/user/agenda/agendado/dadoscadastrais': 'Visualizar dados cadastrais',

  // Atencao domiciliar
  '/pec/user/ad/agenda': 'Agenda de atenção domiciliar',
  '/pec/user/ad/cidadaoListing': 'Lista de atenção domiciliar',
  '/pec/user/ad/cidadaoListing/detail': 'Informações',
  '/pec/user/ad/relatorio': 'Relatório de atenção domiciliar',
  '/pec/user/ad/cidadaoListing/prontuario': 'Prontuário',
  '/pec/user/ad/cidadaoListing/prontuario/dadoscadastrais': 'Visualizar dados cadastrais',

  // Atendimentos
  '/pec/user/filaAtendimento': 'Atendimentos',
  '/pec/user/filaAtendimento/detalhe': 'Adicionar atendimento',
  '/pec/user/filaAtendimento/detalhe/cidadao': 'Cidadãos',
  '/pec/user/filaAtendimento/detalhe/cidadao/detail': 'Informações do cidadão',
  '/pec/user/filaAtendimento/escutaInicial': 'Escuta inicial',
  '/pec/user/filaAtendimento/escutaInicial/dadoscadastrais': 'Visualizar dados cadastrais',
  '/pec/user/filaAtendimento/escutaInicial/acompanhamento': 'Acompanhamento',
  '/pec/user/filaAtendimento/atendimento': 'Prontuário',
  '/pec/user/filaAtendimento/atendimento/dadoscadastrais': 'Visualizar dados cadastrais',
  '/pec/user/filaAtendimento/historicoatendimento': 'Atendimentos no dia',
  '/pec/user/filaAtendimento/atendimentoVacinacao/vacinacaoListing': 'Vacinação',

  // CDS
  '/pec/user/cds/atendimentoDomiciliar': 'Ficha de atendimento domiciliar',
  '/pec/user/cds/atendimentoDomiciliar/detail': 'Informações',
  '/pec/user/cds/atendimentoDomiciliar/detail/atendimentoDomiciliarDetailChild': 'Registro',

  '/pec/user/cds/atendimentoIndividual': 'Ficha de atendimento individual',
  '/pec/user/cds/atendimentoIndividual/detail': 'Informações',
  '/pec/user/cds/atendimentoIndividual/detail/atendimentoDetailChild': 'Registro',

  '/pec/user/cds/atendimentoOdontologico': 'Ficha de atendimento odontológico individual',
  '/pec/user/cds/atendimentoOdontologico/detail': 'Informações',
  '/pec/user/cds/atendimentoOdontologico/detail/atendiOdontoChild': 'Registro',

  '/pec/user/cds/atividadeColetiva': 'Ficha de atividade coletiva',
  '/pec/user/cds/atividadeColetiva/detail': 'Informações',

  '/pec/user/cds/avaliacaoElegibilidadeAdmissao': 'Ficha de avaliação de elegibilidade e admissão',
  '/pec/user/cds/avaliacaoElegibilidadeAdmissao/detail': 'Informações',

  '/pec/user/cds/cadastroDomiciliar': 'Ficha de cadastro domiciliar e territorial',
  '/pec/user/cds/cadastroDomiciliar/detail': 'Informações',
  '/pec/user/cds/cadastroDomiciliar/familias': 'Cadastro individual',
  '/pec/user/cds/cadastroDomiciliar/familias/detail': 'Informações',
  '/pec/user/cds/cadastroDomiciliar/detail/historico': 'Histórico',

  '/pec/user/cds/cadastroIndividual': 'Ficha de cadastro individual',
  '/pec/user/cds/cadastroIndividual/detail': 'Informações',
  '/pec/user/cds/cadastroIndividual/detail/historico': 'Histórico',

  '/pec/user/cds/consumoAlimentar': 'Ficha de marcadores de consumo alimentar',
  '/pec/user/cds/consumoAlimentar/detail': 'Informações',

  '/pec/user/cds/procedimentos': 'Ficha de procedimentos',
  '/pec/user/cds/procedimentos/detail': 'Informações',
  '/pec/user/cds/procedimentos/detail/procedimentosChild': 'Registro',

  '/pec/user/cds/fichaComplementar': 'Ficha de síndrome neurológica por Zika/microcefalia',
  '/pec/user/cds/fichaComplementar/detail': 'Informações',

  '/pec/user/cds/fichaVacinacao': 'Ficha de vacinação',
  '/pec/user/cds/fichaVacinacao/detail': 'Informações',
  '/pec/user/cds/fichaVacinacao/detail/vacinacaoDetailChild': 'Registro',

  '/pec/user/cds/visitaDomiciliar': 'Ficha de visita domiciliar e territorial',
  '/pec/user/cds/visitaDomiciliar/detail': 'Informações',
  '/pec/user/cds/visitaDomiciliar/detail/visitaDomiciliarChild': 'Registro',

  // Cidadaos
  '/pec/user/cidadao': 'Cidadãos',
  '/pec/user/cidadao/detail': 'Informações do cidadão',
  '/pec/user/cidadao/agendamento': 'Agendamentos',
  '/pec/user/cidadao/historicoUnificacao': 'Histórico de unificações',
  '/pec/user/cidadao/prontuario': 'Prontuário',
  '/pec/user/cidadao/prontuario/dadoscadastrais': 'Visualizar dados cadastrais',

  // Lotes imunobiologico
  '/pec/user/loteimunobiologico': 'Lotes de imunobiológico',
  '/pec/user/loteimunobiologico/detail': 'Informações',

  // Unificacao cadastro
  '/pec/user/unificacaocadastro': 'Unificação de cadastros de cidadão',

  // Registro tardio de atendimento
  '/pec/user/registrotardiolisting': 'Registro tardio de atendimento',
  '/pec/user/registrotardiolisting/atendimento': 'Prontuário',
  '/pec/user/registrotardiolisting/atendimento/dadoscadastrais': 'Visualizar dados cadastrais',
  '/pec/user/registrotardiolisting/registrotardiodetail/cidadao': 'Cidadãos',
  '/pec/user/registrotardiolisting/registrotardiodetail/cidadao/detail': 'Adicionar cidadão',

  // Relatorios
  '/pec/user/relatorios/administracao/configuracaoimpressao': 'Configuração de impressão',

  '/pec/user/relatorios/administracao/processamento': 'Reprocessamento de relatórios',

  '/pec/user/relatorios/operacionais/crianca': 'Relatório de criança menores de 5 anos',
  '/pec/user/relatorios/operacionais/gestante': 'Relatório de gestante/puérpera',
  '/pec/user/relatorios/operacionais/riscoCardiovascular': 'Relatório de risco cardiovascular',
  '/pec/user/relatorios/operacionais/cadastro': 'Relatório de cadastro territorial',

  '/pec/user/relatorios/consolidados/cadastroDomiciliar': 'Relatório de cadastro domiciliar e territorial',
  '/pec/user/relatorios/consolidados/cadastroIndividual': 'Relatório de cadastro individual',
  '/pec/user/relatorios/consolidados/situacaoTerritorio': 'Relatório de situação do território',

  '/pec/user/relatorios/producao/analiticoAtendimentoDomiciliar': 'Relatório de atendimento domiciliar',
  '/pec/user/relatorios/producao/historicaAtendimentoDomiciliar': 'Relatório de atendimento domiciliar',
  '/pec/user/relatorios/producao/analiticoAtendimentoIndividual': 'Relatório de atendimento individual',
  '/pec/user/relatorios/producao/historicaAtendimentoIndividual': 'Relatório de atendimento individual',
  '/pec/user/relatorios/producao/analiticoAtendimentoOdontologico': 'Relatório de atendimento odontológico individual',
  '/pec/user/relatorios/producao/historicaAtendimentoOdontologico': 'Relatório de atendimento odontológico individual',
  '/pec/user/relatorios/producao/analiticoAtividadeColetiva': 'Relatório de atividade coletiva',
  '/pec/user/relatorios/producao/historicaAtividadeColetiva': 'Relatório de atividade coletiva',
  '/pec/user/relatorios/producao/analiticoAvaliacaoElegibilidade': 'Relatório de avaliação de elegibilidade e admissão',
  '/pec/user/relatorios/producao/historicaAvaliacaoElegibilidade': 'Relatório de avaliação de elegibilidade e admissão',
  '/pec/user/relatorios/producao/analiticoMarcadoresConsumo': 'Relatório de marcadores de consumo alimentar',
  '/pec/user/relatorios/producao/historicaMarcadoresConsumo': 'Relatório de marcadores de consumo alimentar',
  '/pec/user/relatorios/producao/analiticoProcedimentosConsolidados': 'Relatório de procedimentos consolidados',
  '/pec/user/relatorios/producao/historicaProcedimentosConsolidados': 'Relatório de procedimentos consolidados',
  '/pec/user/relatorios/producao/analiticoProcedimentosIndividualizados': 'Relatório de procedimentos individualizados',
  '/pec/user/relatorios/producao/historicaProcedimentosIndividualizados': 'Relatório de procedimentos individualizados',
  '/pec/user/relatorios/producao/analiticoResumoProducao': 'Relatório de resumo de produção',
  '/pec/user/relatorios/producao/historicaResumoProducao': 'Relatório de resumo de produção',
  '/pec/user/relatorios/producao/analiticoSindromeZikaMicro': 'Relatório de síndrome neurológica por Zika/microcefalia',
  '/pec/user/relatorios/producao/historicaSindromeZikaMicro': 'Relatório de síndrome neurológica por Zika/microcefalia',
  '/pec/user/relatorios/producao/analiticoVacinacao': 'Relatório de vacinação',
  '/pec/user/relatorios/producao/historicaVacinacao': 'Relatório de vacinação',
  '/pec/user/relatorios/producao/analiticoVisitaDomiciliar': 'Relatório de visita domiciliar e territorial',
  '/pec/user/relatorios/producao/historicaVisitaDomiciliar': 'Relatório de visita domiciliar e territorial',

  // Importar cidadao
  '/pec/user/importarcidadao': 'Importar cidadão',

  // Congiguracao AD
  '/pec/user/configuracoesAd': 'Configurações de AD',
}

const onlyTitles = {
  // CDS
  '/pec/user/cds/atendimentoDomiciliar/detail': 'Ficha de atendimento domiciliar',
  '/pec/user/cds/atendimentoDomiciliar/detail/atendimentoDomiciliarDetailChild': 'Ficha de atendimento domiciliar',

  '/pec/user/cds/atendimentoIndividual/detail': 'Ficha de atendimento individual',
  '/pec/user/cds/atendimentoIndividual/detail/atendimentoDetailChild': 'Ficha de atendimento individual',

  '/pec/user/cds/atendimentoOdontologico/detail': 'Ficha de atendimento odontológico individual',
  '/pec/user/cds/atendimentoOdontologico/detail/atendiOdontoChild': 'Ficha de atendimento odontológico individual',

  '/pec/user/cds/atividadeColetiva/detail': 'Ficha de atividade coletiva',

  '/pec/user/cds/avaliacaoElegibilidadeAdmissao/detail': 'Ficha de avaliação de elegibilidade e admissão',

  '/pec/user/cds/cadastroDomiciliar/detail': 'Ficha de cadastro domiciliar e territorial',
  '/pec/user/cds/cadastroDomiciliar/detail/familias': 'Cadastro Individual',
  '/pec/user/cds/cadastroDomiciliar/detail/historico': 'Histórico',

  '/pec/user/cds/cadastroIndividual/detail': 'Ficha de cadastro individual',
  '/pec/user/cds/cadastroIndividual/historico': 'Histórico',

  '/pec/user/cds/consumoAlimentar/detail': 'Ficha de marcadores de consumo alimentar',

  '/pec/user/cds/procedimentos/detail': 'Ficha de procedimentos',
  '/pec/user/cds/procedimentos/detail/procedimentosChild': 'Ficha de procedimentos',

  '/pec/user/cds/fichaComplementar/detail': 'Ficha de síndrome neurológica por Zika/microcefalia',

  '/pec/user/cds/fichaVacinacao/detail': 'Ficha de vacinação',
  '/pec/user/cds/fichaVacinacao/detail/vacinacaoDetailChild': 'Ficha de vacinação',

  '/pec/user/cds/visitaDomiciliar/detail': 'Ficha de visita domiciliar e territorial',
  '/pec/user/cds/visitaDomiciliar/detail/visitaDomiciliarChild': 'Ficha de visita domiciliar e territorial',

  // Lotes imunobiologico
  '/pec/user/loteimunobiologico/detail': 'Lotes de imunobiológico',

  // Cidadaos
  '/pec/user/cidadao/prontuario/folharosto': 'Prontuário',
  '/pec/user/cidadao/prontuario/soap': 'Prontuário',
  '/pec/user/cidadao/prontuario/problemas': 'Prontuário',
  '/pec/user/cidadao/prontuario/acompanhamento': 'Prontuário',
  '/pec/user/cidadao/prontuario/antecedente': 'Prontuário',
  '/pec/user/cidadao/prontuario/historico': 'Prontuário',

  // Atendimentos
  '/pec/user/filaAtendimento/atendimento/folharosto': 'Prontuário',
  '/pec/user/filaAtendimento/atendimento/soap': 'Prontuário',
  '/pec/user/filaAtendimento/atendimento/problemas': 'Prontuário',
  '/pec/user/filaAtendimento/atendimento/acompanhamento': 'Prontuário',
  '/pec/user/filaAtendimento/atendimento/antecedente': 'Prontuário',
  '/pec/user/filaAtendimento/atendimento/historico': 'Prontuário',
  '/pec/user/filaAtendimento/atendimento/finalizar': 'Prontuário',
  '/pec/user/filaAtendimento/escutaInicial/escuta': 'Escuta inicial',
  '/pec/user/filaAtendimento/escutaInicial/folharosto': 'Folha de rosto',

  // AD
  '/pec/user/ad/cidadaoListing/prontuario/folharosto': 'Prontuário',
  '/pec/user/ad/cidadaoListing/prontuario/soap': 'Prontuário',
  '/pec/user/ad/cidadaoListing/prontuario/problemas': 'Prontuário',
  '/pec/user/ad/cidadaoListing/prontuario/acompanhamento': 'Prontuário',
  '/pec/user/ad/cidadaoListing/prontuario/antecedente': 'Prontuário',
  '/pec/user/ad/cidadaoListing/prontuario/historico': 'Prontuário',

  // Registro tardio
  '/pec/user/registrotardiolisting/atendimento/folharosto': 'Prontuário',
  '/pec/user/registrotardiolisting/atendimento/soap': 'Prontuário',
  '/pec/user/registrotardiolisting/atendimento/problemas': 'Prontuário',
  '/pec/user/registrotardiolisting/atendimento/acompanhamento': 'Prontuário',
  '/pec/user/registrotardiolisting/atendimento/antecedente': 'Prontuário',
  '/pec/user/registrotardiolisting/atendimento/historico': 'Prontuário',
}

interface EsusBreadcrumbsProps extends RouteComponentProps {
  iframeUrl: string
}

const EsusBreadcrumbs = withRouter((props: EsusBreadcrumbsProps) => {
  const { iframeUrl, location } = props
  if (iframeUrl) {
    const urlNodes = iframeUrl.split('?')
    return (
      <>
        {urlNodes[0]
          .split('/')
          .reduce((curr, next) => {
            return [...curr, curr.length !== 0 ? curr[curr.length - 1] + '/' + next : next]
          }, [])
          .filter(item => !!breadcrumbTitles[item])
          .map(item => {
            const linkIframeUrl = item + (urlNodes[1] ? '?' + urlNodes[1] : '')
            return (
              <Breadcrumb
                key={linkIframeUrl}
                title={breadcrumbTitles[item]}
                to={`${location.pathname}?iframeUrl=${encodeURI(linkIframeUrl)}`}
              />
            )
          })}
      </>
    )
  }
  return null
})

interface EsusTitleProps {
  iframeUrl: string
}

const EsusTitle = (props: EsusTitleProps) => {
  const { classes } = useStyles(theme => ({
    heading: {
      paddingLeft: '5rem',
      minHeight: '2.25rem',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '0rem',
      },
    },
  }))
  const path = props.iframeUrl.split('?')[0]
  const onlyTitle = onlyTitles[path]
  if (props.iframeUrl) {
    return (
      <PageContainer>
        <Heading level={1} style={classes.heading}>
          {onlyTitle ? onlyTitle : breadcrumbTitles[path]}
        </Heading>
      </PageContainer>
    )
  }
  return null
}

export function parseMessage(e: MessageEvent): any {
  if (typeof e.data === 'string') return JSON.parse(e.data?.match(/\{.*\}/) ? e.data : '{}')
  return e.data
}
