import { Button, HeadingSection, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { EnderecoSection } from 'components/endereco/EnderecoSection'
import { CheckboxField, Form, FormFooter, FormRenderProps, SubmitButton } from 'components/form'
import { useAlteraAdminMutation, useProfissionalAdminDetailQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { useDefinirSenhaPadrao } from 'view/profissional/list/DefinirSenhaPadraoMutation'

import { ProfissionalAdminInformacoesSection } from './ProfissionalAdminInformacoesSection'

interface ProfissionalExistenteProps {
  contraChave: string
  cpf: string
}

interface ProfissionalExistenteFormModel {
  resetSenha?: boolean
}

const path = metaPath<ProfissionalExistenteFormModel>()

export default function ResetInstaladorProfissionalExistenteComponent(props: ProfissionalExistenteProps) {
  const [alteraAdminMutation] = useAlteraAdminMutation()
  const [senhaPadraoMutation] = useDefinirSenhaPadrao()
  const history = useHistory()
  const { data } = useProfissionalAdminDetailQuery({ variables: { cpf: props.cpf } })
  const alert = useAlert()
  const validationInput = {
    cpf: props.cpf,
    contraChave: props.contraChave,
  }

  const onSalvarClicked = async (values: ProfissionalExistenteFormModel) => {
    await alteraAdminMutation({ variables: { input: { ...validationInput } } })
    if (values.resetSenha) {
      await senhaPadraoMutation({ variables: { usuarioId: data.profissionalByCpf.usuario.id } })
    }
    alert('success', 'Administrador da instalação salvo com sucesso')
  }

  const navigateHome = () => history.push('/')

  const onVoltarClicked = () => history.push('/reset/chave/0')

  if (!data || !data.profissionalByCpf) {
    return null
  }

  const renderForm = (formProps: FormRenderProps<ProfissionalExistenteFormModel>) => {
    return (
      <form noValidate>
        <VFlow vSpacing={2}>
          <ProfissionalAdminInformacoesSection profissional={data.profissionalByCpf} />
          <EnderecoSection endereco={data.profissionalByCpf.endereco} title='Endereço residencial' />

          <HeadingSection level={2} title='Redefinir senha'>
            <CheckboxField name={path.resetSenha} label='Redefinir a senha atual para a padrão' />
          </HeadingSection>

          <FormFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button onClick={onVoltarClicked} data-testid='FormFooter.voltar'>
                Voltar
              </Button>
            </div>
            <div>
              <Button onClick={navigateHome} style={{ marginRight: '1rem' }} data-testid='FormFooter.cancelar'>
                Cancelar
              </Button>
              <SubmitButton handleSubmit={formProps.handleSubmit} data-testid='FormFooter.salvar'>
                Salvar
              </SubmitButton>
            </div>
          </FormFooter>
        </VFlow>
      </form>
    )
  }

  return (
    <Form<ProfissionalExistenteFormModel>
      render={renderForm}
      onSubmit={onSalvarClicked}
      onSubmitSucceeded={navigateHome}
    />
  )
}
