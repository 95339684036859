import api from 'api'

import { TipoRelatorio } from './components/AcompanhamentoSaudeRelatorioModal'

export const downloadRelatorio = (tipoRelatorio: TipoRelatorio, idCidadaos: string[]) => {
  switch (tipoRelatorio) {
    case TipoRelatorio.CRIANCA:
      return downloadRelatorioCrianca(idCidadaos)
    case TipoRelatorio.GESTANTE:
      return downloadRelatorioGestante(idCidadaos)
    default:
      return downloadRelatorioRiscoCardioVascular(idCidadaos)
  }
}

const downloadRelatorioCrianca = (idCidadaos: string[]) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalCrianca(idCidadaos)
    .then(response => {
      generatePdf(
        new Blob([response.data], { type: 'application/pdf' }),
        'Relatório operacional de Crianças menores de 5 anos'
      )
    })
    .catch(error => {
      return handleErrorDownloadRelatorio(error)
    })
}

const downloadRelatorioGestante = (idCidadaos: string[]) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalGestante(idCidadaos)
    .then(response => {
      generatePdf(new Blob([response.data], { type: 'application/pdf' }), 'Relatório operacional de Gestante/Puérpera')
    })
    .catch(error => {
      return handleErrorDownloadRelatorio(error)
    })
}

const downloadRelatorioRiscoCardioVascular = (idCidadaos: string[]) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalRiscoCardiovascular(idCidadaos)
    .then(response => {
      generatePdf(
        new Blob([response.data], { type: 'application/pdf' }),
        'Relatório operacional de Risco cardiovascular'
      )
    })
    .catch(error => {
      return handleErrorDownloadRelatorio(error)
    })
}

const generatePdf = (pdf: Blob, title: string) => {
  const pdfWindow: any = window.open(
    '',
    '',
    'width=' + window.screen.width * 0.8 + ', height=' + window.screen.height * 0.8
  )
  const url = pdfWindow.URL.createObjectURL(pdf, { type: 'application/pdf' })
  pdfWindow.document.write("<iframe width='100%' height='100%' frameborder='0' src='" + url + "'></iframe>")
  pdfWindow.document.title = title
}

const handleErrorDownloadRelatorio = error => {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', e => {
      reject((e.srcElement as any).result)
    })
    reader.readAsText(error.response.data)
  })
}
