import { TipoServicoInput } from 'api/schema'
import { Button, HFlow, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Form, SubmitButton, TextField } from 'components/form'
import { analytics } from 'config/firebase'
import { FormApi } from 'final-form'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, maxLength, minLength, required } from 'util/validation'

import { useSalvarTipoServico } from './SalvarTipoServicoMutation'

export const validate = createValidator<TipoServicoInput>({
  nome: [required, minLength(3), maxLength(30)],
})

export interface TipoServicoFormProps {
  onCancel(): void
  onSuccess(): void
}

export function TipoServicoForm(props: TipoServicoFormProps) {
  const { onSuccess, onCancel } = props

  const [salvarTipoServico] = useSalvarTipoServico()

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <VFlow>
        <TextField name='nome' label='Nome do tipo de serviço' required />

        <HFlow justifyContent='flex-end'>
          <Button size='small' onClick={handleCancel(formProps)}>
            Cancelar
          </Button>
          <SubmitButton size='small' kind='primary' handleSubmit={formProps.handleSubmit}>
            Adicionar
          </SubmitButton>
        </HFlow>
      </VFlow>
    )
  }

  const handleCancel = (formProps: FormRenderProps) => e => {
    onCancel()
    setTimeout(formProps.form.reset)
  }

  const handleSubmit = (values: TipoServicoInput, formApi: FormApi) => {
    return salvarTipoServico({ variables: { input: values } }).then(success => {
      const newName: string = values.nome
      analytics.logEvent('add_tipo_servico', { 'Tipo de serviço adicionado': newName })
      alert('success', 'Tipo de serviço ' + newName + ' adicionado com sucesso.')
      setTimeout(formApi.reset)
      onSuccess()
    })
  }

  return <Form render={renderForm} onSubmit={handleSubmit} validate={validate} />
}
