import { ProcessarAuditoriaMutation, ProcessarAuditoriaMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const PROCESSAR_AUDITORIA_MUTATION = gql`
  mutation ProcessarAuditoriaMutation($input: AuditoriaInput!) {
    processAuditoria(input: $input)
  }
`
export function useProcessarAuditoria() {
  return useMutation<ProcessarAuditoriaMutation, ProcessarAuditoriaMutationVariables>(PROCESSAR_AUDITORIA_MUTATION)
}
