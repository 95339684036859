import { ExcluirProfissionalMutation, ExcluirProfissionalMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const EXCLUIR_PROFISSIONAL_MUTATION = gql`
  mutation ExcluirProfissionalMutation($profissionalId: ID!) {
    excluirProfissional(profissionalId: $profissionalId)
  }
`

export function useExcluirProfissional() {
  return useMutation<ExcluirProfissionalMutation, ExcluirProfissionalMutationVariables>(EXCLUIR_PROFISSIONAL_MUTATION)
}
