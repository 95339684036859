import { Alert, HeadingSection, Text, VFlow } from 'bold-ui'
import { confirm } from 'components/confirm'
import {
  DefaultFormFooter,
  EnderecoFieldGroup,
  Form,
  FormPrompt,
  FormRenderProps,
  validateEnderecoComPais as validateEndereco,
} from 'components/form'
import { PaisSelectFieldModel } from 'components/form/field/select/PaisSelectField'
import { RacaCorSelectModel } from 'components/form/field/select/RacaCorSelectField'
import { resolveValue } from 'components/form/final-form/useField'
import createCalculator from 'final-form-calculate'
import {
  useCidadaoCompartilhamentoProntuarioQuery,
  useHasCidadaoAgendamentoFuturoQuery,
  useSalvarCidadaoMutation,
} from 'graphql/hooks.generated'
import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { FormSpy } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, required, ValidateFunction } from 'util/validation'

import { CidadaoDadosFieldGroup, validator as validateCidadao } from './create/CidadaoDadosFieldGroup'
import CidadaoFormModel, { ContatosFormModel } from './create/CidadaoFormModel'
import { CompartilhamentoProntuarioFieldGroup } from './create/CompartilhamentoProntuarioFieldGroup'
import { ContatosFieldGroup, validate as validateContatos } from './create/ContatosFieldGroup'
import convertModelToInput from './create/convertModelToInput'
import { enderecoCalculator } from './create/EnderecoCalculator'
import {
  InformacoesComplementaresFieldGroup,
  SimNaoEnum,
  validate as validateInformacoesComplementares,
} from './create/InformacoesComplementaresFieldGroup'
import { VincularCidadaoComponentRoot } from './create/vinculacao/VincularCidadaoComponentRoot'

export interface CidadaoFormProps {
  initialValues: CidadaoFormModel
  sincronizarCadsus(cidadaoId: string): void
}

export const validator = createValidator<CidadaoFormModel>(
  {
    contato: validateContatos,
    endereco: validateEndereco,
    informacoesComplementares: validateInformacoesComplementares,
  },
  (values: CidadaoFormModel, errors: ErrorObject<CidadaoFormModel>) => {
    const errorsCidadao: ValidateFunction<CidadaoFormModel> = validateCidadao
    const errorsValidate = errorsCidadao(values, errors)

    if (
      NacionalidadeEnum.ESTRANGEIRA !== values.nacionalidade &&
      !Boolean(
        values.contato?.telefoneResidencial || values.contato?.telefoneCelular || values.contato?.telefoneContato
      )
    ) {
      const contatoError = errors.contato as ErrorObject<ContatosFormModel>
      errors.contato = {
        ...contatoError,
        telefones: required(
          values?.contato?.telefoneResidencial || values.contato?.telefoneCelular || values.contato?.telefoneContato
        ),
      }
    }
    return errorsValidate
  }
)

const path = metaPath<CidadaoFormModel>()

const cidadaoCalculator = createCalculator(
  {
    field: path.racaCor.absolutePath(),
    updates: {
      [path.etnia.absolutePath()]: (value: RacaCorSelectModel, values: CidadaoFormModel) => {
        return value?.value !== RacaCorDbEnum.INDIGENA ? null : values.etnia
      },
    },
  },
  {
    field: path.nacionalidade.absolutePath(),
    updates: {
      [path.municipioNascimento.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.BRASILEIRA ? null : values.municipioNascimento
      },
      [path.portariaNaturalizacao.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.NATURALIZADA ? null : values.portariaNaturalizacao
      },
      [path.dataNaturalizacao.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.NATURALIZADA ? null : values.dataNaturalizacao
      },
      [path.paisNascimento.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        if (value === NacionalidadeEnum.BRASILEIRA) {
          return { id: '31', nome: 'BRASIL' } as PaisSelectFieldModel
        } else if (value === NacionalidadeEnum.ESTRANGEIRA) {
          return values.paisNascimento
        } else {
          return null
        }
      },
      [path.dataEntradaPais.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.ESTRANGEIRA ? null : values.dataEntradaPais
      },
    },
  },
  {
    field: path.cidadaoFaleceu.absolutePath(),
    updates: {
      [path.dataObito.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.dataObito
      },
      [path.numeroDeclaracaoObito.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.numeroDeclaracaoObito
      },
      [path.vinculacao.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return value ? null : values.vinculacao
      },
    },
  }
)

const infoComplementaresCalculator = createCalculator(
  {
    field: path.informacoesComplementares.desejaInformarOrientacaoSexual.absolutePath(),
    updates: {
      [path.informacoesComplementares.orientacaoSexual.absolutePath()]: (
        value: SimNaoEnum,
        values: CidadaoFormModel
      ) => {
        const infoComplementares = resolveValue(values, path.informacoesComplementares)
        return SimNaoEnum.NAO === value ? null : infoComplementares?.orientacaoSexual
      },
    },
  },
  {
    field: path.informacoesComplementares.desejaInformarIdentidadeGenero.absolutePath(),
    updates: {
      [path.informacoesComplementares.identidadeGenero.absolutePath()]: (
        value: SimNaoEnum,
        values: CidadaoFormModel
      ) => {
        const infoComplementares = resolveValue(values, path.informacoesComplementares)
        return SimNaoEnum.NAO === value ? null : infoComplementares?.identidadeGenero
      },
    },
  }
)

export function CidadaoDadosForm(props: CidadaoFormProps) {
  const { data } = useCidadaoCompartilhamentoProntuarioQuery()
  const [save] = useSalvarCidadaoMutation()
  const enderecoDecorator = useMemo(() => enderecoCalculator(path.endereco), [])

  const { refetch } = useHasCidadaoAgendamentoFuturoQuery({ skip: true })

  function buildMessage(
    values: CidadaoFormModel,
    listItem: string[],
    hasCidadaoObitoAgendamentoFuturo: boolean = false
  ) {
    if (!values.vinculacao) {
      listItem.push(
        'O cidadão não está vinculado a nenhuma equipe. O cadastro permanecerá sem vínculo com uma equipe responsável.'
      )
    }
    if (!values.cpf && !values.cns) {
      listItem.push(
        'Cidadão sem CPF ou CNS registrado. Este cadastro não será considerado elegível para contabilização nos indicadores de saúde do município.'
      )
    }
    if (values.cidadaoFaleceu && values.vinculacao) {
      listItem.push('Ao informar óbito do cidadão, ele perderá o vínculo com a sua equipe responsável.')
    }
    if (hasCidadaoObitoAgendamentoFuturo) {
      listItem.push('Ao informar óbito do cidadão, os agendamentos futuros serão cancelados.')
    }
    return listItem
  }

  const handleSubmit = (values: CidadaoFormModel) => {
    let listItem: string[] = []

    const innerSubmit = () =>
      save({ variables: { input: convertModelToInput(values) } }).then(result =>
        props.sincronizarCadsus(result.data.salvarCidadao.id)
      )

    const innerCatch = e => {
      if (e !== 'ModalError') {
        throw e
      }
    }

    if (values.id && values.cidadaoFaleceu) {
      return refetch({
        input: { id: values.id, dataReferencia: values.dataObito },
      })
        .then(({ data }) => dialog(buildMessage(values, listItem, data.hasAgendamentoFuturo)))
        .then(innerSubmit)
        .catch(innerCatch)
    } else {
      buildMessage(values, listItem)
      if (listItem.length > 0) {
        return dialog(listItem)
          .then(innerSubmit)
          .catch(innerCatch)
      } else {
        return innerSubmit().catch(innerCatch)
      }
    }
  }
  const dialog = (listItem: string[]) => {
    return new Promise<void>((resolve, reject) => {
      confirm({
        title: 'Deseja salvar o cadastro do cidadão?',
        body: (
          <Text>
            <ul>
              {listItem.map((value, idx) => {
                return <li key={idx}>{value}</li>
              })}
            </ul>
          </Text>
        ),
        cancelLabel: 'Cancelar',
        confirmLabel: 'Salvar',
        onConfirm: () => {
          resolve()
        },
        onCancel: () => {
          reject('ModalError')
        },
        onClose: () => {
          reject('ModalError')
        },
      })()
    })
  }

  const renderForm = (formProps: FormRenderProps<CidadaoFormModel>) => {
    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <FormPrompt />
        <VFlow vSpacing={2}>
          <Alert type='info' inline>
            O módulo tem como objetivo atualizar ou registrar os dados cadastrais do cidadão no sistema. Esses dados
            também podem ser provenientes das informações da Ficha de Cadastro Individual ou Ficha de Avaliação de
            Elegibilidade após seu processamento.
          </Alert>
          <HeadingSection level={2} title='Dados pessoais'>
            <CidadaoDadosFieldGroup name={path} formProps={formProps} />
          </HeadingSection>
          <HeadingSection level={2} title='Contatos'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                const contatoObrigatorio = values.nacionalidade !== NacionalidadeEnum.ESTRANGEIRA
                return <ContatosFieldGroup name={path.contato} formProps={formProps} required={contatoObrigatorio} />
              }}
            </FormSpy>
          </HeadingSection>
          <HeadingSection level={2} title='Equipe responsável pelo cidadão'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <VincularCidadaoComponentRoot
                  name={path.vinculacao}
                  values={values}
                  formProps={formProps}
                  cnsCidadao={values.cns}
                  cpfCidadao={values.cpf}
                  uuidFicha={values.uuidFicha}
                  faleceu={values.cidadaoFaleceu}
                />
              )}
            </FormSpy>
          </HeadingSection>
          <HeadingSection level={2} title='Endereço'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <EnderecoFieldGroup
                  name={path.endereco}
                  formProps={formProps}
                  formModelValue={values}
                  renderPaisAreaMicroArea
                />
              )}
            </FormSpy>
          </HeadingSection>
          <HeadingSection level={2} title='Informações complementares'>
            <InformacoesComplementaresFieldGroup name={path.informacoesComplementares} formProps={formProps} />
          </HeadingSection>

          {data.compartilhamentoProntuario?.ativo && (
            <HeadingSection level={2} title='Compartilhamento de prontuário'>
              <CompartilhamentoProntuarioFieldGroup name={path} />
            </HeadingSection>
          )}
          <DefaultFormFooter handleSubmit={formProps.handleSubmit} />
        </VFlow>
      </form>
    )
  }
  return (
    <Form<CidadaoFormModel>
      decorators={[cidadaoCalculator, enderecoDecorator, infoComplementaresCalculator]}
      validate={validator}
      render={renderForm}
      onSubmit={handleSubmit}
      {...props}
    />
  )
}
