import { useAlert } from 'components/alert'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import {
  useBuscaCidadaoCadsusLazyQuery,
  useCidadaoListingLazyQuery,
  useConfiguracaoCadsusQuery,
} from 'graphql/hooks.generated'
import { BuscaCidadaoCadsusQuery, CidadaoListingQuery } from 'graphql/types.generated'
import React, { useState } from 'react'

import { CidadaoListItemView } from './CidadaoListItemView'
import { CidadaoPesquisarForm } from './CidadaoPesquisarForm'
import CidadaoPesquisarFormModel from './CidadaoPesquisarFormModel'

export type CidadaoCadsusItemType = BuscaCidadaoCadsusQuery['cidadaosCadsus'][0]
export type CidadaoItemType = CidadaoListingQuery['cidadaos']['content'][0]

export function CidadaoListView() {
  const alert = useAlert()
  const [hasCadsusCalled, setHasCadsusCalled] = useState(false)
  const { data: configCadsus } = useConfiguracaoCadsusQuery()
  const [buscaCadsusQuery, { loading }] = useBuscaCidadaoCadsusLazyQuery({
    onCompleted: data => {
      setCadsusDataState(data.cidadaosCadsus)
      if (!data?.cidadaosCadsus?.length) {
        alert(
          'warning',
          'Não foram encontrados resultados com os filtros selecionados. Refine a busca incluindo mais informações.'
        )
      } else if (data?.cidadaosCadsus?.length === 20) {
        alert(
          'info',
          'Foram encontrados muitos cadastros com os dados informados. Refine a busca incluindo mais informações.'
        )
      }
      setHasCadsusCalled(true)
    },
    onError() {
      setHasCadsusCalled(true)
    },
  })

  const [pesquisaCadsusState, setPesquisacadsusState] = useState<CidadaoPesquisarFormModel>({})
  const [cadsusDataState, setCadsusDataState] = useState<CidadaoCadsusItemType[]>()

  const buscaCidadaoCadsus = () => {
    if (configCadsus.conexao.internet) {
      if (configCadsus.conexao.cadsus) {
        buscaCadsusQuery({
          variables: {
            filter: {
              pageParams: {},
              nomeCnsCpf: pesquisaCadsusState.nomeCpfCns,
              dataNascimento: pesquisaCadsusState.dataNascimento,
              nomeMae: pesquisaCadsusState.nomeMae,
              municipioNascimento: pesquisaCadsusState.municipio?.id,
            },
          },
        })
      } else {
        alert(
          'warning',
          'Não foi possível buscar na base nacional, pois a conexão com o serviço está desabilitada.' +
            ' Entre em contato com o responsável pela instalação para mais informações.'
        )
      }
    } else {
      alert(
        'warning',
        'Não foi possível buscar na base nacional, pois a instalação está offline.' +
          ' Entre em contato com o responsável pela instalação para mais informações.'
      )
    }
  }

  const [executeQuery, { data, refetch }] = useCidadaoListingLazyQuery({
    onCompleted: dataCidadaos => {
      if (!dataCidadaos?.cidadaos?.content?.length && !dataCidadaos?.cidadaos?.hasMoreResults) {
        alert('warning', 'Nenhum resultado encontrado')
        buscaCidadaoCadsus()
      }
      if (dataCidadaos.cidadaos.hasMoreResults) {
        alert(
          'info',
          'Foram encontrados muitos cadastros com os dados informados. Refine a busca incluindo mais informações.'
        )
      }
    },
  })

  const handleSubmitPesquisa = (form: CidadaoPesquisarFormModel) => {
    if (!form.nomeCpfCns && !form.dataNascimento && !form.nomeMae && !form.municipio) {
      alert('info', 'Insira algum filtro para realizar a busca')
    } else {
      setCadsusDataState(null)
      setPesquisacadsusState({
        nomeCpfCns: form.nomeCpfCns,
        dataNascimento: form.dataNascimento,
        municipio: form.municipio,
        nomeMae: form.nomeMae,
      })
      setHasCadsusCalled(false)
      executeQuery({
        variables: {
          filtro: {
            pageParams: {},
            nomeCnsCpf: form.nomeCpfCns,
            dataNascimento: form.dataNascimento,
            nomeMae: form.nomeMae,
            municipioNascimento: form.municipio?.id,
          },
        },
      })
    }
  }

  const refetchLocal = () => {
    refetch()
  }

  return (
    <>
      <PageHeaderSection title='Cidadão' />
      <PageContainer>
        <CidadaoPesquisarForm executeQuery={executeQuery} handleSubmitPesquisa={handleSubmitPesquisa} />
      </PageContainer>
      <PageContent type='transparent' style={{ textAlign: 'center' }}>
        <CidadaoListItemView
          result={data?.cidadaos?.content}
          resultCadsus={cadsusDataState}
          loadingCadsus={loading}
          onBuscarClicked={buscaCidadaoCadsus}
          refetchLocal={refetchLocal}
          hasMoreResults={data?.cidadaos?.hasMoreResults}
          hasCadsusCalled={hasCadsusCalled}
          hasCadsusConnection={configCadsus.conexao?.cadsus?.habilitado}
          hasInternetConnection={configCadsus.conexao?.internet?.habilitado}
        />
      </PageContent>
    </>
  )
}
