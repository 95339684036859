import { ForcarRedefinirSenhas } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const FORCAR_REDEFINIR_SENHAS = gql`
  mutation ForcarRedefinirSenhas {
    forcarRedefinirSenhas {
      redefinicaoSenha
    }
  }
`

export function useForcarRedefinirSenhas() {
  return useMutation<ForcarRedefinirSenhas>(FORCAR_REDEFINIR_SENHAS)
}
