import { HeadingSection, HFlow, Spinner, Text, VFlow } from 'bold-ui'
import { useProfissionalAcessosQuery } from 'graphql/hooks.generated'
import React from 'react'

import { ProfissionalLotacoesSection } from './ProfissionalLotacoesSection'
import { ProfissionalOutrosAcessosSection } from './ProfissionalOutrosAcessosSection'

export interface ProfissionalAcessosViewProps {
  profissionalId: ID
}

export interface LotacaoProfissional {
  id: string
  ativo: boolean
  importada: boolean
  hasConfiguracaoAgenda: boolean
  hasConfiguracaoAgendaOnline: boolean
  hasAgendamentoFuturo: boolean
  perfis: {
    id: string
    nome: string
    ativo: boolean
  }[]
  actions: {
    permissaoEdicao: {
      enabled: boolean
      hint: string
    }
    permissaoExclusao: {
      enabled: boolean
      hint: string
    }
    permissaoAtivacaoInativacao: {
      enabled: boolean
      hint: string
    }
    permissaoConfigurarAgenda: {
      enabled: boolean
      hint: string
    }
    permissaoConfigurarAgendaOnline: {
      enabled: boolean
      hint: string
    }
    permissaoFecharAgenda: {
      enabled: boolean
      hint: string
    }
  }
  cbo: {
    id: string
    nome: string
    cbo2002: string
  }
  equipe: {
    id: string
    nome: string
    ine: string
    tipoEquipe: {
      descricao: string
    }
  }
  unidadeSaude: {
    id: string
    nome: string
  }
}

export function ProfissionalAcessosView(props: ProfissionalAcessosViewProps) {
  const { profissionalId } = props

  const {
    data: { profissional },
    loading,
    refetch,
  } = useProfissionalAcessosQuery({
    variables: { profissionalId },
  })

  const acessos = (profissional && profissional.acessos) || []

  const outrosAcessos = acessos.filter(acesso => acesso.__typename !== 'Lotacao')
  const lotacoes: LotacaoProfissional[] = acessos.filter(
    acesso => acesso.__typename === 'Lotacao'
  ) as LotacaoProfissional[]

  return (
    <HeadingSection level={2} title='Lotações e acessos'>
      {loading && (
        <HFlow hSpacing={0.5} alignItems='center'>
          <Spinner />
          <Text>Carregando</Text>
        </HFlow>
      )}
      {!loading && (
        <VFlow vSpacing={2}>
          <ProfissionalLotacoesSection lotacoes={lotacoes} possuiCns={!!profissional.cns} refetch={refetch} />
          <ProfissionalOutrosAcessosSection acessos={outrosAcessos} refetch={refetch} />
        </VFlow>
      )}
    </HeadingSection>
  )
}

export default ProfissionalAcessosView
