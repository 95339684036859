import { SelectField, SelectFieldProps } from 'components/form'
import { PaisSelectFieldDocument } from 'graphql/hooks.generated'
import { PaisSelectFieldQuery, PaisSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type PaisSelectFieldModel = Partial<PaisSelectFieldQuery['paises']['content'][0]>

export function PaisSelectField(props: Omit<SelectFieldProps<PaisSelectFieldModel>, 'items' | 'itemToString'>) {
  const asyncProps = useAsyncQuerySelect<PaisSelectFieldModel, PaisSelectFieldQuery, PaisSelectFieldQueryVariables>({
    query: PaisSelectFieldDocument,
    extractItems: data => data && data.paises && data.paises.content,
    variables: inputQuery => ({
      input: {
        query: inputQuery,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: PaisSelectFieldModel) => item && item.nome

  return <SelectField<PaisSelectFieldModel> itemToString={itemToString} {...asyncProps} {...props} />
}
