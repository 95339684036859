import 'firebase/analytics'
import 'firebase/performance'

import * as firebase from 'firebase/app'

import { METRICS_ENABLED } from './util'

var firebaseConfig = null

if (METRICS_ENABLED) {
  firebaseConfig = {
    // envia dados para projeto esusab-pec-producao
    apiKey: 'AIzaSyCNyZ7P83KYCJ-6HiPK2oV_2v-qbSS_dPs',
    authDomain: 'esusab-pec-producao.firebaseapp.com',
    databaseURL: 'https://esusab-pec-producao.firebaseio.com',
    projectId: 'esusab-pec-producao',
    storageBucket: 'esusab-pec-producao.appspot.com',
    messagingSenderId: '1034535321855',
    appId: '1:1034535321855:web:ba511378ae275dbca88cca',
    measurementId: 'G-RXTX8LJCDF',
  }
} else {
  // envia dados para projeto esusab-pec-dev
  firebaseConfig = {
    apiKey: 'AIzaSyAX0Gkg14cQ3uAlIODVLBbUHba8BgzeYAM',
    authDomain: 'esusab-pec.firebaseapp.com',
    databaseURL: 'https://esusab-pec.firebaseio.com',
    projectId: 'esusab-pec',
    storageBucket: 'esusab-pec.appspot.com',
    messagingSenderId: '53798463657',
    appId: '1:53798463657:web:0c6d8e0d7b7a3c1d',
    measurementId: 'G-R9W606Z49Z',
  }
}
export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const performance = firebaseApp.performance()
export const analytics = firebaseApp.analytics()
