import { Icon, Tooltip } from 'bold-ui'
import { ButtonLink } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React, { SyntheticEvent } from 'react'

import { GrupoExameRowItem } from './GrupoExameTable'

interface GrupoExameEditActionProps {
  grupoExame: GrupoExameRowItem
}

export function GrupoExameEditAction(props: GrupoExameEditActionProps) {
  const { grupoExame } = props
  const { match } = useRouter()
  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation()
  }

  return (
    <Tooltip text='Editar'>
      <ButtonLink onClick={onClick} to={`${match.url}/edit/${grupoExame.id}`} size='small' skin='ghost'>
        <Icon icon='penOutline' />
      </ButtonLink>
    </Tooltip>
  )
}
