import { HFlow } from 'bold-ui'
import { Form, TextField } from 'components/form'
import { FormDebouncedValueSpy } from 'components/form/FormDebouncedValueSpy'
import { FormState } from 'final-form'
import { TipoServicoQueryInput } from 'graphql/types.generated'
import React from 'react'

export interface TipoServicoTableFilterProps {
  initialValues?: TipoServicoQueryInput
  onChange(values: TipoServicoQueryInput): void
}

export function TipoServicoTableFilter(props: TipoServicoTableFilterProps) {
  const handleChange = (formState: FormState<TipoServicoQueryInput>) => {
    props.onChange(formState.values)
  }

  const renderForm = () => (
    <HFlow justifyContent='space-between'>
      <FormDebouncedValueSpy loadOnMount={false} onChange={handleChange} />
      <TextField
        name='query'
        icon='zoomOutline'
        placeholder='Pesquise por tipo de serviço'
        style={{ width: '19rem' }}
      />
    </HFlow>
  )

  return (
    <Form<TipoServicoQueryInput> onSubmit={props.onChange} render={renderForm} initialValues={props.initialValues} />
  )
}
