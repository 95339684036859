import { CboEditComponentQuery } from 'api/schema'
import { Button, Heading, HFlow, Icon, InfoLabel, Modal, ModalBody, ModalFooter, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { confirm } from 'components/confirm'
import { useErrorHandler } from 'components/error'
import { CheckboxField, Form, FormRenderProps, NumberField, SubmitButton } from 'components/form/final-form'
import { Interpolation } from 'emotion'
import { FormApi } from 'final-form'
import gql from 'graphql-tag'
import { useQuery } from 'graphql/hooks'
import { useAlterarDuracaoCboMutation } from 'graphql/hooks.generated'
import { CboDuracaoFormInput } from 'graphql/types.generated'
import React, { useState } from 'react'
import { metaPath } from 'util/metaPath'
import { composeRules, createValidator, ErrorObject, range, required } from 'util/validation'

import { CboTableItemDto } from './CboTable'

export interface CboEditComponentProps {
  item: CboTableItemDto
  style?: Interpolation

  reload(): Promise<any>
}

const validate = createValidator<CboEditModel>({}, (values: CboEditModel) => {
  const errors: ErrorObject<CboEditModel> = {}

  if (!values.usarDuracaoPadrao) {
    errors.duracao = composeRules([required, range(5, 90)])(values.duracao)
  }

  return errors
})

interface CboEditModel {
  id: ID
  duracao: number
  duracaoPadrao: number
  usarDuracaoPadrao: boolean
}

const path = metaPath<CboEditModel>()

export function CboEditComponent(props: CboEditComponentProps) {
  const { item, reload } = props

  const { data } = useQuery<CboEditComponentQuery>(CBO_EDIT_COMPONENT_QUERY)
  const possuiLotacaoComAgendaOnline = props.item.possuiLotacaoComAgendaOnline
  const [open, setOpen] = useState(false)
  const [alterarDuracaoCbo] = useAlterarDuracaoCboMutation()
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const renderForm = (formProps: FormRenderProps) => {
    const { usarDuracaoPadrao } = formProps.values

    return (
      <Modal open={open} onClose={() => setOpen(false)} size='small'>
        <ModalBody>
          <VFlow vSpacing={1} style={{ width: 300 }}>
            <Heading level={1}>Editar CBO</Heading>
            <InfoLabel title='Nome' placeholder='-'>
              {item.cbo.nome}
            </InfoLabel>
            <InfoLabel title='Código' placeholder='-'>
              {item.cbo.cbo2002}
            </InfoLabel>
            <NumberField
              name={!usarDuracaoPadrao ? path.duracao.absolutePath() : path.duracaoPadrao.absolutePath()}
              label='Duração do agendamento (min)'
              required={!usarDuracaoPadrao}
              disabled={usarDuracaoPadrao}
              maxLength={2}
            />
            <CheckboxField name={path.usarDuracaoPadrao} label='Usar duração padrão do município' />
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleCancel(formProps)} data-testid='CancelarButton'>
              Cancelar
            </Button>
            <SubmitButton kind='primary' handleSubmit={formProps.handleSubmit} data-testid='SubmitButton'>
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const handleCancel = (formProps: FormRenderProps) => e => {
    formProps.form.reset()
    setOpen(false)
  }

  const handleSubmit = (values: CboEditModel, formApi: FormApi) => {
    const { usarDuracaoPadrao, duracaoPadrao, ...rest } = values
    const { duracao: oldDuracao } = formApi.getState().initialValues as CboEditModel
    const cboDuracaoForm: CboDuracaoFormInput = {
      ...rest,
      duracao: usarDuracaoPadrao ? null : +rest.duracao,
    }
    if (oldDuracao === cboDuracaoForm.duracao) {
      onEditSuccess(formApi)
      return
    }
    if (data.conexao.agendaOnline.ativa) {
      checkCBOAgendaOnline(cboDuracaoForm, formApi)
    } else {
      doAlterarCbo(cboDuracaoForm, formApi)
    }
  }

  const checkCBOAgendaOnline = (cboDuracaoForm: CboDuracaoFormInput, formApi: FormApi) => {
    if (possuiLotacaoComAgendaOnline) {
      confirm({
        title: `Deseja confirmar a exclusão das configurações de agenda online?`,
        body:
          `Alterar a duração do agendamento pode causar inconsistências nas configurações ` +
          `de horários disponibilizados para agenda online. Todas as lotações que possuem esta ocupação ` +
          `terão sua configuração de agenda online excluída.`,
        confirmLabel: 'Sim',
        cancelLabel: 'Não',
        type: 'primary',
        onConfirm: () => {
          doAlterarCbo(cboDuracaoForm, formApi)
        },
      })()
    } else {
      doAlterarCbo(cboDuracaoForm, formApi)
    }
  }

  const onEditSuccess = (formApi: FormApi) => {
    alert('success', 'CBO editado com sucesso.')
    setTimeout(formApi.reset)
    setOpen(false)
    reload()
  }

  const doAlterarCbo = (cboDuracaoForm: CboDuracaoFormInput, formApi: FormApi) => {
    return alterarDuracaoCbo({ variables: { cboDuracaoForm } })
      .then(success => {
        onEditSuccess(formApi)
      })
      .catch(handleRejection)
  }

  return (
    <>
      <Tooltip text='Editar'>
        <Button size='small' skin='ghost' onClick={() => setOpen(true)} style={props.style} data-testid='EditarButton'>
          <Icon icon='penOutline' />
        </Button>
      </Tooltip>
      <Form
        render={renderForm}
        onSubmit={handleSubmit}
        initialValues={
          {
            id: item.cbo.id,
            duracao: item.duracao,
            duracaoPadrao: item.duracaoPadrao,
            usarDuracaoPadrao: !item.duracao,
          } as CboEditModel
        }
        validate={validate}
        onSubmitFailed={null}
      />
    </>
  )
}

const CBO_EDIT_COMPONENT_QUERY = gql`
  query CboEditComponentQuery {
    conexao {
      agendaOnline {
        ativa
      }
    }
  }
`
