import gql from 'graphql-tag'

export default gql`
  subscription onAuditoriaProcessoUpdated {
    auditoriaProcessoUpdated {
      id
      dataInicio
      dataFim
      dataSolicitacao
      status
      csv
    }
  }
`
