import { HeadingSection, HFlow, Icon, Theme, useStyles, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

import { RecuperarSenhaForm } from './RecuperarSenhaForm'
import { useRedefinirSenhaMutation } from './useRecuperarSenhaMutation'

interface RecuperarSenhaURL {
  token: string
}

export const RecuperarSenhaView = () => {
  const { classes } = useStyles(createStyles)

  const { match, history } = useRouter<RecuperarSenhaURL>()

  const [recuperarSenha] = useRedefinirSenhaMutation()

  const doRedefinirSenha = async (form: RecuperarSenhaForm) => {
    const reply = await recuperarSenha({ variables: { input: { ...form, token: match.params.token } } })
    return reply
  }

  const handleSair = () => {
    history.push('/')
  }

  return (
    <PageContent type='filled' style={{ marginTop: '2rem' }}>
      <VFlow>
        <HeadingSection title='Recuperar senha' level={1} />
        <div className={classes.info}>
          <HFlow hSpacing={0.5}>
            <span className={classes.infoIcon}>
              <Icon icon='infoCircleFilled' size={2.0} />
            </span>
            <span>
              <VFlow vSpacing={0.1}>
                <p>A nova senha não pode ser igual à senha atual.</p>
                <p>Use pelo menos uma letra e um número.</p>
                <p>Mínimo de 8 e máximo de 20 caracteres.</p>
                <p>Use somente letras e números.</p>
                <p>O sistema diferencia letras maiúsculas de minúsculas.</p>
              </VFlow>
            </span>
          </HFlow>
        </div>
        <RecuperarSenhaForm onSair={handleSair} onSubmit={doRedefinirSenha} />
      </VFlow>
    </PageContent>
  )
}

const createStyles = (theme: Theme) => ({
  info: {
    backgroundColor: theme.pallete.status.info.background,
    border: '1px solid ' + theme.pallete.status.info.main,
    padding: '8px',
    width: '50%',
    fontSize: '13px',
    borderRadius: '2px',
    color: theme.pallete.status.info.main,
  },
  infoIcon: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
