import moment from 'moment'

const asFormat = val => moment(val).format('YYYY-MM-DD')

export function afterToday(dt: Date) {
  if (!dt) {
    return null
  }

  if (asFormat(dt) < asFormat(moment().toDate())) {
    return 'Não pode ser anterior à data atual.'
  }
}
