import { Omit, VFlow } from 'bold-ui'
import { DEFAULT_SELECT_SIZE } from 'components/form'
import { useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectDataTableField, SelectDataTableFieldProps } from 'components/form/field/SelectDataTableField'
import { GrupoExameSelectTableDocument } from 'graphql/hooks.generated'
import {
  GrupoExameSelectTableQuery,
  GrupoExameSelectTableQueryVariables,
  GrupoExamesQuery,
} from 'graphql/types.generated'
import React from 'react'

export type GrupoExameSelectModel = GrupoExamesQuery['grupoExame']['procedimentos'][0]

interface GrupoExameSelectTableProps
  extends Omit<SelectDataTableFieldProps<GrupoExameSelectModel>, 'items' | 'itemToString'> {}

export function GrupoExameSelectTable(props: GrupoExameSelectTableProps) {
  const asyncProps = useAsyncQuerySelect<
    GrupoExameSelectModel,
    GrupoExameSelectTableQuery,
    GrupoExameSelectTableQueryVariables
  >({
    query: GrupoExameSelectTableDocument,
    extractItems: data => data && data.procedimentosGrupoExame.content,
    variables: inputString => ({
      input: {
        query: inputString,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
        },
      },
    }),
  })

  const itemToString = (item: GrupoExameSelectModel) => {
    return ''
  }

  const renderItem = (item: GrupoExameSelectModel) => {
    return (
      <>
        <VFlow vSpacing={0}>
          <b>{item.descricaoReferencia || item.descricao}</b>
          <>
            <b>Código </b> {item.codigoReferencia || item.codigo}
          </>
          {item.codigoReferencia && (
            <>
              <b>CDS </b> {item.descricao}
            </>
          )}
        </VFlow>
      </>
    )
  }

  return (
    <SelectDataTableField<GrupoExameSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...asyncProps}
      {...props}
    />
  )
}
