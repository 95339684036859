import { HFlow, Text } from 'bold-ui'
import { AsyncQuerySelectField, AsyncSelectQueryFieldProps } from 'components/form/field'
import { ProfissionaisRelatorioInconsistenciaDocument } from 'graphql/hooks.generated'
import {
  ProfissionaisRelatorioInconsistenciaQuery,
  ProfissionaisRelatorioInconsistenciaQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { Cpf } from '../../../../label'

export type ProfissionaisRelatorioInconsistenciaSelectModel = ProfissionaisRelatorioInconsistenciaQuery['profissionaisRelatorioInconsistencia']['content'][0]

export interface ProfissionalSelectFieldProps
  extends AsyncSelectQueryFieldProps<
    ProfissionaisRelatorioInconsistenciaSelectModel,
    ProfissionaisRelatorioInconsistenciaQuery,
    ProfissionaisRelatorioInconsistenciaQueryVariables
  > {
  dataInicio: string
  dataFim: string
}
export function ProfissionaisRelatorioInconsistenciaSelectField(props: ProfissionalSelectFieldProps) {
  const { dataInicio, dataFim, ...rest } = props

  const variables = (inputQuery: string): ProfissionaisRelatorioInconsistenciaQueryVariables => ({
    filtro: {
      dataInicio,
      dataFim,
      nomeProfissional: inputQuery,
      pageParams: {
        sort: ['nome'],
      },
    },
  })
  const extractItems = (data: ProfissionaisRelatorioInconsistenciaQuery) =>
    data && data.profissionaisRelatorioInconsistencia && data.profissionaisRelatorioInconsistencia.content
  const itemToString = (item: ProfissionaisRelatorioInconsistenciaSelectModel) => item && item.responsavel
  const renderItem = (item: ProfissionaisRelatorioInconsistenciaSelectModel) => (
    <>
      <Text component='p' fontWeight='bold'>
        {item.responsavel}
      </Text>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>CPF</Text>
        <Text>
          <Cpf value={item.cpfResponsavel} />
        </Text>
      </HFlow>
    </>
  )
  return (
    <AsyncQuerySelectField<
      ProfissionaisRelatorioInconsistenciaSelectModel,
      ProfissionaisRelatorioInconsistenciaQuery,
      ProfissionaisRelatorioInconsistenciaQueryVariables
    >
      query={ProfissionaisRelatorioInconsistenciaDocument}
      variables={variables}
      extractItems={extractItems}
      itemToString={itemToString}
      renderItem={renderItem}
      {...rest}
    />
  )
}
