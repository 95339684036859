export function consistentPeriod(
  dtInicial: LocalDate,
  dtFinal: LocalDate,
  lbDataInicial: string = 'Data inicial',
  lbDataFinal: string = 'Data final'
) {
  if (!(dtInicial && dtFinal)) {
    return null
  }

  if (dtInicial.valueOf() > dtFinal.valueOf()) {
    return 'A ' + lbDataInicial + ' não pode ser posterior à ' + lbDataFinal + '.'
  }
}
