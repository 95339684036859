import { msg } from '../messages'

const REGEX = '^[a-zA-Z0-9áéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜàÀ. /-]+$'

export function loteImuno(value: string) {
  if (!new RegExp(REGEX).test(value)) {
    return msg('match', value)
  }
  return null
}
