import { DropdownItem, DropdownItemProps } from 'bold-ui'
import * as React from 'react'
import { Link, LinkProps } from 'react-router-dom'

export interface DropdownLinkProps extends DropdownItemProps, Pick<LinkProps, 'to' | 'replace' | 'innerRef'> {}

export const DropdownLink = (props: DropdownLinkProps) => {
  const { disabled, to, ...rest } = props

  return <DropdownItem component={Link} disabled={disabled} to={!disabled ? to : undefined} {...rest} />
}
