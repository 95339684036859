import { Button, HFlow, Icon, PagedTable, Text, Tooltip } from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useTipoServicoTableQuery } from 'graphql/hooks.generated'
import { TipoServicoQueryInput, TipoServicoTableQuery } from 'graphql/types.generated'
import React, { useState } from 'react'

import { TipoServicoEditComponent } from './alterar/TipoServicoEditComponent'
import { TipoServicoRemoveComponent } from './excluir/TipoServicoRemoveComponent'
import { TipoServicoTableHeader } from './TipoServicoTableHeader'

export type TipoServicoItem = TipoServicoTableQuery['tiposServico']['content'][0]

export interface TipoServicoTableProps {
  initialFilter: TipoServicoQueryInput
  reload(): Promise<any>
}

export default function TipoServicoTable() {
  const [filter, setFilter] = useState<TipoServicoQueryInput>({
    pageParams: {
      sort: ['nome'],
    },
  })

  const {
    data: { tiposServico },
    refetch,
    loading,
  } = useTipoServicoTableQuery({ variables: { input: filter } })

  const renderNome = (row: TipoServicoItem) => {
    return (
      <HFlow hSpacing={0.5} alignItems='flex-end'>
        <Text>{row.nome}</Text>
      </HFlow>
    )
  }

  const renderButtons = (row: TipoServicoItem) => {
    if (row.municipio) {
      return (
        <>
          <TipoServicoEditComponent tipoServico={row} onEdit={refetch} />
          <TipoServicoRemoveComponent tipoServico={row} onRemove={refetch} />
        </>
      )
    } else {
      return (
        <>
          <Tooltip text='Tipo de serviço padrão não pode ser editado'>
            <Button size='small' skin='ghost' disabled>
              <Icon icon='penOutline' />
            </Button>
          </Tooltip>

          <Tooltip text='Tipo de serviço padrão não pode ser excluído'>
            <Button size='small' skin='ghost' disabled>
              <Icon icon='trashOutline' />
            </Button>
          </Tooltip>
        </>
      )
    }
  }

  const tableProps = usePagedTableProps({
    result: tiposServico,
    loading,
    onChange: setFilter,
  })

  return (
    <TableBox header={<TipoServicoTableHeader reload={refetch} onChange={setFilter} initialValues={filter} />}>
      <PagedTable<TipoServicoItem>
        {...tableProps}
        columns={[
          { name: 'nome', header: 'Tipo de serviço', render: renderNome, sortable: true },
          { name: 'buttons', render: renderButtons, style: { textAlign: 'right' } },
        ]}
      />
    </TableBox>
  )
}
