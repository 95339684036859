import api from 'api'
import { Button, HFlow, Icon, PagedTable, Text, TextColor } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { useErrorHandler } from 'components/error'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useLotesEnvioQuery, useOnLoteGeradoSubscription } from 'graphql/hooks.generated'
import { LoteEnvio, LotesEnvioFiltro, StatusEnvio } from 'graphql/types.generated'
import moment from 'moment'
import React, { ReactNode, useState } from 'react'
import { downloadFile } from 'util/downloadFile'
import { TransmissaoEnvioTableFilter } from 'view/transmissao/components/envio/table/TransmissaoEnvioTableFilter'

type Item = LoteEnvio
type Filter = LotesEnvioFiltro

export interface TransmissaoEnvioTableProps {}
export const TransmissaoEnvioTable = (props: TransmissaoEnvioTableProps) => {
  const [filter, setFilter] = useState<Filter>({
    pageParams: {
      sort: ['dataCriacao'],
    },
    statusEnvio: [],
  })

  const {
    data: { lotesEnvio },
    loading,
    refetch,
  } = useLotesEnvioQuery({ variables: { input: { ...filter } } })

  const alert = useAlert()
  const handleRejection = useErrorHandler()

  useOnLoteGeradoSubscription({
    onSubscriptionData: response => {
      alert('success', response.subscriptionData.data.onLoteGerado.message)
      refetch()
    },
  })

  const downloadZip = (row: Item) => () => {
    const formData = new FormData()
    row.id && formData.append('id', row.id)
    api.transmissao
      .baixarZip(formData)
      .then(response => {
        alert('success', `Arquivo Zip do Lote ${row.id} gerado com sucesso.`)
        downloadFile(new Blob([response.data]), `RAS_Lote${row.id}_${moment().format('DD-MM-YYYY--HH-mm')}.zip`)
        return response
      })
      .catch(handleRejection)
  }

  const renderLoteNum: (row: Item) => ReactNode = row => row.id
  const renderDataCriacao: (row: Item) => ReactNode = row => <DateTime value={row.dataCriacao} />
  const renderFichas: (row: Item) => ReactNode = row => row.quantidadeFichas
  const renderStatus: (row: Item) => ReactNode = row => getIcon(row.statusEnvio)
  const renderButtons: (row: Item) => ReactNode = row => (
    <HFlow justifyContent='flex-end'>
      <ButtonLink title='Visualizar' size='small' skin='ghost' to={`/transmissao/detail/envio/${row.id}`}>
        <Icon icon='zoomOutline' />
      </ButtonLink>
      <Button size='small' skin='ghost' onClick={downloadZip(row)}>
        <Icon icon='download' />
      </Button>
    </HFlow>
  )

  const tableProps = usePagedTableProps({
    loading,
    onChange: setFilter,
    result: lotesEnvio,
  })

  return (
    <TableBox header={<TransmissaoEnvioTableFilter onChange={setFilter} filter={filter} />}>
      <PagedTable<Item>
        {...tableProps}
        columns={[
          { name: 'id', header: 'Lote', sortable: false, render: renderLoteNum },
          { name: 'descricao', header: 'Situação de envio', sortable: false, render: renderStatus },
          { name: 'dataCriacao', header: 'Data de criação', sortable: true, render: renderDataCriacao },
          { name: 'qtdFichas', header: 'Fichas', sortable: false, render: renderFichas },
          { name: 'buttons', render: renderButtons },
        ]}
      />
    </TableBox>
  )
}

const getIcon = (status: StatusEnvio): ReactNode => {
  let icon: ReactNode
  let color: TextColor
  let text: string

  if (status === 'TOTALMENTE_ENVIADO') {
    icon = <Icon size={1} icon='checkCircleOutline' />
    color = 'success'
    text = 'Enviado'
  } else {
    icon = <Icon size={1} icon='banOutline' />
    color = 'danger'
    text = 'Não enviado'
  }

  return (
    <Text color={color}>
      <HFlow alignItems='center'>
        {icon}
        <Text>{text}</Text>
      </HFlow>
    </Text>
  )
}
