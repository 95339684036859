import { FormControl, TextInput, TextInputProps } from 'bold-ui'
import { useFormControl, UseFormControlProps } from 'bold-ui/lib/hooks/useFormControl'
import React, { useRef } from 'react'

import { UseFieldProps, usePecField } from '../useField'
import { getFieldError } from '../util'

export type TransformCaseTextFieldProps = UseFieldProps<string> &
  Omit<TextInputProps, 'name'> &
  UseFormControlProps & {
    case: 'upper' | 'lower'
  }

export function TransformCaseTextField(props: TransformCaseTextFieldProps) {
  const { input, meta, ...rest } = usePecField(props)

  return <TextField error={getFieldError(meta)} {...input} {...rest} />
}

interface TextFieldProps extends TextInputProps, UseFormControlProps {
  case: 'upper' | 'lower'
}

function TextField(props: TextFieldProps) {
  const { label, error, onChange, ...rest } = props

  const ref = useRef<HTMLInputElement>()

  const onFieldChange = async e => {
    const current = e.target.selectionStart
    const value = props.case === 'lower' ? e.target.value.toLowerCase() : e.target.value.toUpperCase()

    await new Promise(resolve => {
      onChange(value)
      resolve()
    })
    if (ref.current) {
      ref.current.selectionStart = current
      ref.current.selectionEnd = current
    }
  }

  const { getFormControlProps, getInputProps } = useFormControl(props)
  const inputProps = getInputProps()

  return (
    <FormControl {...getFormControlProps()}>
      <TextInput
        invalid={inputProps['aria-invalid']}
        onChange={onFieldChange}
        {...inputProps}
        {...rest}
        inputRef={ref}
      />
    </FormControl>
  )
}
