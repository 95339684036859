import gql from 'graphql-tag'

export const LOTACAO_CREATE_QUERY = gql`
  query LotacaoCreate($profissionalId: ID!) {
    profissional(id: $profissionalId) {
      id
      nome
    }
  }
`
