import { Button, Heading, HFlow, Text, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { Cpf, Telefone } from 'components/label'
import React from 'react'
import { sexo } from 'types/enums'
import Permissions from 'types/Permissions'

import { CidadaoCadsusItemType } from './CidadaoListView'

interface CidadaoCadsusListingItemProps {
  data: CidadaoCadsusItemType
}

export const CidadaoCadsusListingItem = (props: CidadaoCadsusListingItemProps) => {
  const { data } = props
  const telefone = data.telefoneCelular
    ? data.telefoneCelular
    : data.telefoneResidencial
    ? data.telefoneResidencial
    : null

  return (
    <>
      <Box style={{ textAlign: 'left' }}>
        <HFlow justifyContent='space-between'>
          <VFlow vSpacing={0}>
            <Heading level={3}>{data.nome}</Heading>
            <HFlow hSpacing={4.5}>
              <VFlow vSpacing={0} style={{ width: 350 }}>
                <HFlow>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Text fontWeight='bold'>CPF</Text>
                    {data.cpf ? <Cpf value={data.cpf} /> : '-'}
                  </HFlow>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Text fontWeight='bold'>CNS</Text>
                    {data.cns ? <Text>{data.cns}</Text> : '-'}
                  </HFlow>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Data de nascimento</Text>
                  <DateTime format='DD/MM/YYYY' value={data.dataNascimento} />
                  (<Age dateOfBirth={data.dataNascimento} />)
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Sexo</Text>
                  <Text>{sexo[data.sexo]}</Text>
                </HFlow>
              </VFlow>
              <VFlow vSpacing={0}>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Nome da mãe</Text>
                  <Text>{data.nomeMae}</Text>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Telefone</Text>
                  {telefone ? <Telefone value={telefone}></Telefone> : <Text>-</Text>}
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Município de nascimento</Text>
                  <Text>
                    {data.municipioNascimento?.nome
                      ? data.municipioNascimento.nome + ' - ' + data.municipioNascimento.uf.sigla
                      : '-'}
                  </Text>
                </HFlow>
              </VFlow>
            </HFlow>
          </VFlow>
          <CheckPermission permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}>
            <HFlow alignItems='center' hSpacing={0.5}>
              <Button kind='normal' skin='outline' size='small'>
                Cadastrar na base local
              </Button>
            </HFlow>
          </CheckPermission>
        </HFlow>
      </Box>
    </>
  )
}
