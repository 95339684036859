import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { ConselhoClasseSelectDocument } from 'graphql/hooks.generated'
import { ConselhoClasseSelectQuery, ConselhoClasseSelectQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE } from '../AsyncQuerySelectField'
import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type ConselhoClasseSelectModel = ConselhoClasseSelectQuery['conselhosClasse']['content'][0]

export interface ConselhoClasseSelectFieldProps
  extends Omit<SelectFieldProps<ConselhoClasseSelectModel>, 'items' | 'itemToString'> {}

export const ConselhoClasseSelectField = (props: ConselhoClasseSelectFieldProps) => {
  const asyncProps = useAsyncQuerySelect<
    ConselhoClasseSelectModel,
    ConselhoClasseSelectQuery,
    ConselhoClasseSelectQueryVariables
  >({
    query: ConselhoClasseSelectDocument,
    extractItems: data => data && data.conselhosClasse && data.conselhosClasse.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (option: ConselhoClasseSelectModel) => option && option.descricao

  return <SelectField<ConselhoClasseSelectModel> itemToString={itemToString} {...asyncProps} {...props} />
}
