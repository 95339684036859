import { SalvarAcesso, SalvarAcessoVariables } from 'api/schema'
import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { useMutation } from 'graphql/hooks'
import { useAcessoEditQuery } from 'graphql/hooks.generated'
import * as React from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router'

import { AcessoForm, AcessoFormModel } from './AcessoForm'
import convertModelToInput from './convertModelToInput'
import { SALVAR_ACESSO_MUTATION } from './SalvarAcessoMutation'

interface UrlParams {
  profissionalId: ID
  acessoId: ID
}
export const AcessoEditView = props => {
  const alert = useAlert()
  const match = useRouteMatch<UrlParams>()
  const history = useHistory()
  const {
    data: { acesso, profissional },
  } = useAcessoEditQuery({
    variables: { acessoId: match.params.acessoId, profissionalId: match.params.profissionalId },
  })
  const [salvar] = useMutation<SalvarAcesso, SalvarAcessoVariables>(SALVAR_ACESSO_MUTATION)

  if (!profissional) {
    return null
  }

  const handleSubmit = (values: AcessoFormModel) => {
    const input = convertModelToInput(values)
    return salvar({ variables: { input } })
  }

  const handleSubmitSuccess = () => {
    alert('success', 'Acesso editado com sucesso.')
    history.push(`/profissional/${match.params.profissionalId}/dados`)
  }

  const initialValues: any = acesso && {
    id: match.params.acessoId,
    profissional: match.params.profissionalId,
    municipio: (acesso as any).municipio,
    perfis: acesso.perfis,
    tipo: acesso.tipo,
    uf: (acesso as any).uf,
  }

  return acesso.actions.permissaoEdicao.enabled ? (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${match.params.profissionalId}/dados`} />
      <Breadcrumb title='Editar acesso' />

      <VFlow vSpacing={1.5}>
        <PageHeader title='Editar acesso' subtitle={profissional && profissional.nome} />
        <AcessoForm initialValues={initialValues} onSubmit={handleSubmit} onSubmitSucceeded={handleSubmitSuccess} />
      </VFlow>
    </PageContent>
  ) : (
    <Redirect to='/403' />
  )
}
