import { SalvarGrupoExame, SalvarGrupoExameVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export function useSalvarGrupoExame() {
  return useMutation<SalvarGrupoExame, SalvarGrupoExameVariables>(SALVAR_GRUPO_EXAME_MUTATION)
}

const SALVAR_GRUPO_EXAME_MUTATION = gql`
  mutation SalvarGrupoExame($input: GrupoExameInput!) {
    salvarGrupoExame(input: $input) {
      id
      nome
      procedimentos {
        id
        descricao
        codigo
        descricaoReferencia
        codigoReferencia
      }
    }
  }
`
