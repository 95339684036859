import { ExcluirFechamentoMutation, ExcluirFechamentoMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

const AGENDA_FECHAMENTO_DELETE_MUTATION = gql`
  mutation ExcluirFechamentoMutation($id: ID!) {
    excluirFechamento(id: $id)
  }
`

export function useFechamentoDeleteMutation() {
  return useMutation<ExcluirFechamentoMutation, ExcluirFechamentoMutationVariables>(AGENDA_FECHAMENTO_DELETE_MUTATION)
}
