import gql from 'graphql-tag'

export const CFG_LOTACAO_AGENDA_ONLINE_QUERY = gql`
  query CfgLotacaoAgendaOnlineQuery {
    conexao {
      agendaOnline {
        ativa
      }
      hasLotacoesConfiguradasOnline
      lotacoesAgendaOnlineComDuracaoPadrao
    }
  }
`
