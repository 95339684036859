import { RecuperarSenhaMutation, RecuperarSenhaMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

export const useRedefinirSenhaMutation = () => {
  return useMutation<RecuperarSenhaMutation, RecuperarSenhaMutationVariables>(RECUPERAR_SENHA_MUTATION, {})
}

export const RECUPERAR_SENHA_MUTATION = gql`
  mutation RecuperarSenhaMutation($input: RecuperarSenhaInput!) {
    recuperaSenha(input: $input)
  }
`
