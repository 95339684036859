import { HFlow, Omit, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { CboSelectFieldDocument } from 'graphql/hooks.generated'
import { CboSelectFieldQuery, CboSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE } from '../AsyncQuerySelectField'
import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type CboSelectModel = CboSelectFieldQuery['cbos']['content'][0]['cbo']

export interface CboSelectFieldProps extends Omit<SelectFieldProps<CboSelectModel>, 'items' | 'itemToString'> {
  mostrarIndisponivelLotacao?: boolean
}

export function CboSelectField(props: CboSelectFieldProps) {
  const { mostrarIndisponivelLotacao, ...rest } = props

  const asyncProps = useAsyncQuerySelect<CboSelectModel, CboSelectFieldQuery, CboSelectFieldQueryVariables>({
    query: CboSelectFieldDocument,
    extractItems: data => data && data.cbos && data.cbos.content.map(item => item.cbo),
    variables: inputQuery => ({
      input: {
        query: inputQuery,
        mostrarIndisponivelLotacao,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (op: CboSelectModel) => op && op.nome
  const renderItem = (item: CboSelectModel) => (
    <>
      <Text fontWeight='bold'>{item.nome}</Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>Código:</Text>
        {item.cbo2002}
      </HFlow>
    </>
  )

  return <SelectField<CboSelectModel> itemToString={itemToString} renderItem={renderItem} {...asyncProps} {...rest} />
}
