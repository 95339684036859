import { SalvarFechamentoMutation, SalvarFechamentoMutationVariables } from 'api/schema'
import gql from 'graphql-tag'
import { useMutation } from 'graphql/hooks'

const AGENDA_FECHAMENTO_MUTATION = gql`
  mutation SalvarFechamentoMutation($input: AgendaFechamentoInput!) {
    salvarFechamento(input: $input) {
      id
    }
  }
`

export function useSalvarFechamentoMutation() {
  return useMutation<SalvarFechamentoMutation, SalvarFechamentoMutationVariables>(AGENDA_FECHAMENTO_MUTATION)
}
