import { SelectField, SelectFieldProps } from 'components/form'
import { useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { EquipesAdQuery, EquipesAdQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { EquipesAdDocument } from '../../../graphql/hooks.generated'

export type EquipesADSelectFieldModel = Partial<EquipesAdQuery['equipesAD']['content'][0]>

export interface EquipesEmadSelectFieldProps
  extends Omit<SelectFieldProps<EquipesADSelectFieldModel>, 'items' | 'itemToString'> {
  dsTipoEquipe: string
}

export function EquipesADSelectField(props: EquipesEmadSelectFieldProps) {
  const { dsTipoEquipe, ...rest } = props

  const { loading, ...asyncProps } = useAsyncQuerySelect<
    EquipesADSelectFieldModel,
    EquipesAdQuery,
    EquipesAdQueryVariables
  >({
    query: EquipesAdDocument,
    extractItems: data => data?.equipesAD?.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        descricaoTipoEquipe: dsTipoEquipe,
        pageParams: {
          size: 5,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: EquipesADSelectFieldModel) => (item ? `${item?.nome} - ${item.ine}` : '')

  return <SelectField<EquipesADSelectFieldModel> itemToString={itemToString} {...asyncProps} {...rest} />
}
