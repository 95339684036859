import { HeadingSection, HFlow, VFlow } from 'bold-ui'
import { useTransmissaoRecebimentoLotesQuery } from 'graphql/hooks.generated'
import { TransmissaoRecebimentoLoteFiltro } from 'graphql/types.generated'
import * as React from 'react'
import { useState } from 'react'

import { TransmissaoBtnGerarRelatorio } from '../components/TransmissaoBtnGerarRelatorio'
import { TransmissaoRecebimentoImportFileBox } from './TransmissaoRecebimentoImportFileBox'
import { TransmissaoRecebimentoLoteTable } from './TransmissaoRecebimentoLoteTable'

const initialFilter = {
  pageParams: {
    sort: ['dataRecebimento'],
  },
}

export const TransmissaoRecebimentoLoteBox = () => {
  const [filter, setFilter] = useState<TransmissaoRecebimentoLoteFiltro>(initialFilter)
  const result = useTransmissaoRecebimentoLotesQuery({
    variables: { input: filter },
  })

  return (
    <VFlow>
      <HeadingSection title='Lotes recebidos' level={2}>
        <TransmissaoRecebimentoLoteTable queryResult={result} filter={filter} setFilter={setFilter} />
      </HeadingSection>
      <HFlow justifyContent='flex-end'>
        <TransmissaoBtnGerarRelatorio />
      </HFlow>
      <TransmissaoRecebimentoImportFileBox refetchQuery={result.refetch} />
    </VFlow>
  )
}
