import api from 'api'
import { downloadFile } from 'util/downloadFile'

export const downloadRelatorioInconsistencia = (formData: FormData) => {
  return api.transmissao
    .gerarRelatorioInconsistencia(formData)
    .then(response => {
      downloadFile(new Blob([response.data]), 'RelatorioInconsistencia_' + new Date().getTime() + '.csv')
      return response
    })
    .catch(error => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', e => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
